import { useEffect } from 'react';
import { useAppSelector } from '../../../app/store/helpers/functions';
import { selectUuid } from '../../user/userSelectors';
import { getSubscriptionObservable } from '../subscriptionSlice';
import { ParsedEvent } from '../types/event';

export default function useSubscription(next: (event: ParsedEvent) => void) {
  const userUuid = useAppSelector(selectUuid);

  useEffect(() => {
    if (!userUuid) {
      return;
    }

    const subscribe = async () => {
      const observable = await getSubscriptionObservable(userUuid);

      return observable.subscribe({
        next,
        error: () => {
          void subscribe();
        },
      });
    };
    const subscriptionPromise = subscribe();

    return () => {
      void subscriptionPromise.then((subscription) =>
        subscription.unsubscribe(),
      );
    };
  }, [userUuid, next]);
}
