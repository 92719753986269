import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';

import ComboBox, { Option } from '../../../../../components/ComboBox';
import Button from '../../../../../components/Button';
import AssetToExportSelect from '../AssetToExportSelect';
import { getComboBoxOptionSelected } from '../../helpers/functions/ui';
import { APPLICATION_PRODUCT_TYPE_UNITS_MAPPER } from '../../helpers/constants/workPlanUnits';
import { selectFieldUuid } from '../../../../field/fieldSelectors';
import { TransformedEquationMap } from '../../../../../helpers/types/equationMap';
import { TransformedVectorAnalysisMap } from '../../../../../helpers/types/vectorAnalysisMap';
import { getAssetUnit, getWorkPlanUint } from '../../helpers/functions/unit';
import ApplicationProductSelector from '../ApplicationProductSelector';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../app/store/helpers/functions';
import {
  selectApplication,
  selectJDWorkPlanType,
} from '../../jdWorkPlanSelectors';
import {
  setApplicationSettings,
  removeApplication,
} from '../../jdWorkPlanSlice';
import { ApplicationProductType } from '../../types/workPlan';
import { AreaUnit } from '../../../../user/helpers/constants/user';
import { selectAreaUnit } from '../../../../user/userSelectors';

import './index.scss';

const ApplicationCard = ({
  applicationId,
  typeOptions,
}: {
  applicationId: string;
  typeOptions: { value: ApplicationProductType; title: string }[];
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const workPlanType = useAppSelector(selectJDWorkPlanType);
  const { productType, unit, selectedAsset } =
    useAppSelector((state) => selectApplication(state, applicationId)) ?? {};
  const fieldUuid = useAppSelector(selectFieldUuid);
  const areaUnit: AreaUnit = useAppSelector(selectAreaUnit);

  const applicationUnits = useMemo(
    () =>
      productType
        ? (APPLICATION_PRODUCT_TYPE_UNITS_MAPPER[productType][areaUnit] ?? [])
        : [],
    [productType, areaUnit],
  );

  const selectedProductTypeValue = useMemo(
    () => typeOptions.find((option) => option.value === productType) ?? null,
    [typeOptions, productType],
  );

  const unitOptions = useMemo(
    () =>
      applicationUnits.map((value) => ({
        value,
        title: t(`general.popups.export-work-plan.units.${value}`),
      })),
    [applicationUnits, t],
  );

  const selectedUnitValue = useMemo(
    () => unitOptions.find((option) => option.value === unit) ?? null,
    [unitOptions, unit],
  );

  const handleProductTypeChange = (
    _e: React.ChangeEvent<unknown>,
    item: Option<ApplicationProductType> | null,
  ) => {
    const selectedProductType = item?.value ?? null;
    dispatch(
      setApplicationSettings({
        id: applicationId,
        application: {
          productType: selectedProductType,
          productId: null,
          unit: selectedProductType
            ? getWorkPlanUint(
                APPLICATION_PRODUCT_TYPE_UNITS_MAPPER[selectedProductType][
                  areaUnit
                ],
                selectedAsset?.unit,
              )
            : null,
        },
        workPlanType,
      }),
    );
  };

  const handleUnitChange = (
    _e: React.ChangeEvent<unknown>,
    item: Option<string> | null,
  ) => {
    dispatch(
      setApplicationSettings({
        id: applicationId,
        application: {
          unit: item?.value ?? null,
        },
        workPlanType,
      }),
    );
  };

  const handleSelectedAssetChange = (
    asset: TransformedEquationMap | TransformedVectorAnalysisMap | null,
  ) => {
    const newSelectedAsset = asset
      ? {
          uuid: asset.uuid,
          name: asset.name ?? '',
          resourceType: asset.assetType,
          type: asset.type,
          fieldUuid: fieldUuid ?? '',
          unit: getAssetUnit(asset),
        }
      : null;

    const assetUnit = productType
      ? getWorkPlanUint(applicationUnits, newSelectedAsset?.unit)
      : null;

    dispatch(
      setApplicationSettings({
        id: applicationId,
        application: {
          selectedAsset: newSelectedAsset,
          unit: assetUnit ?? unit,
        },
        workPlanType,
      }),
    );
  };

  const handleApplicationDelete = () => {
    dispatch(
      removeApplication({
        id: applicationId,
        workPlanType,
      }),
    );
  };

  return (
    <div className="application-card">
      <div className="application-card__controls">
        <AssetToExportSelect
          selectedAssetUuid={selectedAsset?.uuid}
          onAssetSelect={handleSelectedAssetChange}
        />
        <ComboBox
          title={t(
            'general.popups.export-work-plan.application-work-plan.type',
          )}
          placeholder={t(
            'general.popups.export-work-plan.application-work-plan.select-type',
          )}
          value={selectedProductTypeValue}
          disableCloseOnSelect={false}
          options={typeOptions}
          getOptionSelected={getComboBoxOptionSelected}
          onChange={handleProductTypeChange}
        />
        <div className="application-card__row">
          <ApplicationProductSelector applicationId={applicationId} />
          <ComboBox
            classes={{
              root: 'application-card__unit-select',
              endAdornment: 'application-card__select-end-adornment',
              outline: 'application-card__select-outline',
            }}
            noOptionsText={
              !productType
                ? t(
                    'general.popups.export-work-plan.application-work-plan.select-application-type-first',
                  )
                : ''
            }
            title={t('general.labels.unit')}
            placeholder={t('general.controls.select')}
            options={unitOptions}
            value={selectedUnitValue}
            getOptionSelected={getComboBoxOptionSelected}
            disableCloseOnSelect={false}
            onChange={handleUnitChange}
          />
        </div>
      </div>
      <Button
        classes={{
          root: 'application-card__remove-button',
          startIcon: 'application-card__remove-button-icon',
        }}
        variant="text"
        startIcon={<DeleteIcon />}
        onClick={handleApplicationDelete}
      >
        {t(
          'general.popups.export-work-plan.application-work-plan.remove-application',
        )}
      </Button>
    </div>
  );
};

export default ApplicationCard;
