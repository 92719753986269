import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import DialogContent from '@material-ui/core/DialogContent';
import { DialogContentText } from '@material-ui/core';

import type { CleaningType } from '../../../../../helpers/constants/ui';
import PopupHeader from '../../../../../../../../components/Popups/PopupHeader';
import { selectCleaningType } from '../../../../../cleanCalibrateSelectors';
import { changeCleaningType } from '../../../../../cleanCalibrateSlice';
import { useAppSelector } from '../../../../../../../../app/store/helpers/functions';
import CleaningTypeSelector from '../../../../../components/CleaningTypeSelector';
import CONFIG from './config';

import './index.scss';

export default function CleanTabContent({
  onCancel,
}: {
  onCancel: () => void;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const cleaningType = useAppSelector(selectCleaningType);

  const CleaningTypeContent = CONFIG[cleaningType];

  const handleCleaningTypeChange = (value: CleaningType) => {
    dispatch(changeCleaningType(value));
  };

  return (
    <>
      <PopupHeader
        classes={{
          root: 'clean-tab-content__header',
        }}
        title={t('clean-calibrate.yield-popup.clean-title')}
        onCancel={onCancel}
      />
      <DialogContent className="clean-tab-content__content">
        <DialogContentText className="clean-tab-content__content-description">
          {t('clean-calibrate.yield-popup.tabs.clean.configure.description')}
        </DialogContentText>
        <CleaningTypeSelector
          value={cleaningType}
          onTypeChange={handleCleaningTypeChange}
        />
        <CleaningTypeContent />
      </DialogContent>
    </>
  );
}
