import { useEffect, useState } from 'react';

const preloadImage = (src) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    const errorHandler = () => {
      reject(src);
    };

    img.onload = () => {
      resolve(img);
    };
    img.onerror = errorHandler;
    img.onabort = errorHandler;
    img.src = src;
  });

export default function useImagePreloader(imageList) {
  const [imagesPreloaded, setImagesPreloaded] = useState(false);

  useEffect(() => {
    let isCancelled = false;
    if (!imageList?.length) {
      setImagesPreloaded(true);
      return () => {};
    }

    async function effect() {
      if (isCancelled) {
        return;
      }
      setImagesPreloaded(false);
      const imagesPromiseList = imageList.reduce((acc, curr) => {
        if (!curr.isLoaded) {
          acc.push(preloadImage(curr.url));
        }

        return acc;
      }, []);

      await Promise.allSettled(imagesPromiseList);

      if (isCancelled) {
        return;
      }

      setImagesPreloaded(true);
    }

    void effect();

    return () => {
      isCancelled = true;
    };
  }, [imageList]);

  return { imagesPreloaded };
}
