import { CalibrationType } from '../../../../../helpers/constants/ui';
import CalibrateTabAverageTotalContent from './Types/AverageTotal';
import CalibrateTabConditionalContent from './Types/Conditional';
import CalibrateTabPathwiseContent from './Types/Pathwise';

const CONFIG = {
  [CalibrationType.pathwise]: CalibrateTabPathwiseContent,
  [CalibrationType.averageTotal]: CalibrateTabAverageTotalContent,
  [CalibrationType.conditional]: CalibrateTabConditionalContent,
} as const;

export default CONFIG;
