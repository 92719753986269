import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { prepareConnectionLinkToOrganization } from '../../../../jdOrganizations/helpers/functions';
import {
  fetchOrganizationsJohnDeereWithNewPage,
  fetchOrganizationsJohnDeereWithNewPageSize,
} from '../../../../jdOrganizations/jdOrganizationsSlice';
import {
  selectOrganizationsList,
  selectOrganizationsTotalCount,
  selectOrganizationsPage,
  selectOrganizationsPageSize,
} from '../../../../jdOrganizations/jdOrganizationsSelectors';
import Table from '../../../tableView/components/Table';
import AmplitudeAnalytics from '../../../../../helpers/classes/amplitudeAnalytics';

const getTableColumns = ({ t, typeSelection }) => {
  const addons = [];
  if (typeSelection.type === 'multiple') {
    addons.push({
      id: 'rowSelect',
      labelElement: 'checkbox',
      handler: typeSelection.onHeadCheckboxClick,
      value: typeSelection.allRowsSelection,
      element: 'checkbox',
      verticalAlign: 'middle',
      onCheckboxClick: typeSelection.onRowCheckboxClick,
    });
  }

  return [
    ...addons,
    {
      id: 'name',
      label: t('john-deere.organizations-table.organization-row'),
      element: 'default',
      verticalAlign: 'middle',
    },
    {
      id: 'connect',
      label: '',
      element: 'click-operation',
      verticalAlign: 'middle',
    },
  ];
};

const getEmptyOrganizations = (length, offset = 0) =>
  Array.from(Array(length), (_e, i) => ({
    id: i + offset,
    rowType: 'default',
    disabled: true,
  }));

const getTableRows = ({
  t,
  organizations,
  totalCount,
  page,
  pageSize,
  typeSelection,
  onConnectionLinkClick,
}) => {
  const firstOffset = (page - 1) * pageSize;
  const endOffset = firstOffset + organizations.length;

  return [
    ...getEmptyOrganizations(firstOffset),
    ...organizations.map((organization) => {
      const connectionLink = prepareConnectionLinkToOrganization(organization);

      return {
        id: organization.id,
        name: organization.name,
        rowType: 'default',
        connect: {
          text: t('john-deere.organizations-table.connect-link'),
          link: connectionLink,
          type: 'navLink',
          onClick: onConnectionLinkClick,
        },
        selected: !!(
          typeSelection.type === 'multiple' &&
          typeSelection.selectedOrganizations.find(
            (selectedOrg) => selectedOrg.id === organization.id,
          )
        ),
        disabled: !!connectionLink,
      };
    }),
    ...getEmptyOrganizations(totalCount - endOffset, endOffset),
  ];
};

const OrganizationsJohnDeereTable = ({ typeSelection }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const organizations = useSelector(selectOrganizationsList);
  const totalCount = useSelector(selectOrganizationsTotalCount);
  const page = useSelector(selectOrganizationsPage);
  const pageSize = useSelector(selectOrganizationsPageSize);

  const onPageChange = (newPage) => {
    dispatch(fetchOrganizationsJohnDeereWithNewPage({ page: newPage + 1 }));
  };

  const onPageSizeChange = (newPageSize) => {
    dispatch(
      fetchOrganizationsJohnDeereWithNewPageSize({ pageSize: newPageSize }),
    );
  };

  const onConnectionLinkClick = () => {
    AmplitudeAnalytics.trackConnectToJDOrganizationLinkClicked({
      page,
    });
  };

  return (
    <Table
      columns={getTableColumns({ t, typeSelection })}
      rows={getTableRows({
        t,
        organizations,
        totalCount,
        page,
        pageSize,
        typeSelection,
        onConnectionLinkClick,
      })}
      showPagination
      hasNext={page * pageSize < totalCount}
      selectedRowId={
        typeSelection.type === 'single' ? typeSelection.selectedOrganization : 0
      }
      page={page - 1}
      pageSize={pageSize}
      totalCount={totalCount}
      getDisplayedRowsLabel={({ to, from, count }) =>
        t('general.controls.pagination.displayed-rows-with-count', {
          to,
          from,
          count,
          entity: t('general.controls.pagination.entities.organizations'),
        })
      }
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      onRowClick={(row) => {
        if (typeSelection.type === 'single') {
          typeSelection.onOrganizationChange({ id: row.id, name: row.name });
        }
      }}
      classes={{
        row: 'select-organization-table__list-row',
      }}
    />
  );
};

export default OrganizationsJohnDeereTable;
