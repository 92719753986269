import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';

import ComboBox from '../../../../../../../../components/ComboBox';
import ItemsPicker from '../../../../../../../../components/ItemsPicker';
import PopupHeader from '../../../../../../../../components/Popups/PopupHeader';
import { selectYieldDataset } from '../../../../../../../field/fieldSelectors';
import {
  selectCalibrateCalibrationAttributes,
  selectCalibratePathwiseCalibrationBasis,
  selectCalibratePathwiseSyntheticMachinePath,
  selectCleanTargetAttribute,
  selectDatasetUuid,
} from '../../../../../cleanCalibrateSelectors';
import {
  setCalibrateCalibrationAttributes,
  setCalibratePathwiseCalibrationBasis,
  toggleCalibratePathwiseSyntheticMachinePath,
  setCleanTargetAttribute,
} from '../../../../../cleanCalibrateSlice';
import type { FullAttributeItem } from '../../../../../types/ui';
import {
  createFullAttributeItem,
  getComboBoxOptionSelected,
} from '../../../../../helpers/functions/ui';
import { useAppSelector } from '../../../../../../../../app/store/helpers/functions';
import CalibrationBasis from '../../../../../components/CalibrationBasis';
import WarningPanel from '../../../../../components/WarningPanel';

import './index.scss';

export default function FastTabContent({ onCancel }: { onCancel: () => void }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const datasetUuid = useAppSelector(selectDatasetUuid);
  const dataset = useAppSelector((state) =>
    selectYieldDataset(state, datasetUuid),
  );
  const calibrateBasis = useAppSelector(
    selectCalibratePathwiseCalibrationBasis,
  );
  const calibrateAttributes = useAppSelector(
    selectCalibrateCalibrationAttributes,
  );
  const cleanAttribute = useAppSelector(selectCleanTargetAttribute);
  const syntheticMachinePath = useAppSelector(
    selectCalibratePathwiseSyntheticMachinePath,
  );

  const handleYieldAttributesChange = (itemsValues: Set<string>) => {
    dispatch(setCalibrateCalibrationAttributes([...itemsValues]));
  };

  const handleCleanAttributeChange = (_e: unknown, item: FullAttributeItem) => {
    dispatch(setCleanTargetAttribute(item.value));
  };

  const handleCalibrationBasisChange = (item: FullAttributeItem) => {
    dispatch(setCalibratePathwiseCalibrationBasis(item.value));
  };

  const handleIsSyntheticBasisUsedChange = (value: boolean) => {
    dispatch(
      toggleCalibratePathwiseSyntheticMachinePath({
        calibrationAttributes: value ? calibrateAttributes : [],
      }),
    );
  };

  const allAttributesItems = useMemo<FullAttributeItem[]>(
    () =>
      (dataset?.fullAttributes || []).map((fullAttribute) =>
        createFullAttributeItem(fullAttribute),
      ),
    [dataset],
  );

  const calibrateBasisAttributeItem = useMemo<FullAttributeItem | undefined>(
    () => allAttributesItems.find((item) => item.value === calibrateBasis),
    [allAttributesItems, calibrateBasis],
  );

  const yieldAttributes = useMemo<Set<string>>(
    () => new Set(calibrateAttributes),
    [calibrateAttributes],
  );

  const yieldAttributesItems = useMemo<FullAttributeItem[]>(
    () =>
      (dataset?.fullAttributes || []).reduce<FullAttributeItem[]>(
        (acc, fullAttribute) => {
          const item = createFullAttributeItem(fullAttribute);

          if (yieldAttributes.has(item.value)) {
            acc.push(item);
          }

          return acc;
        },
        [],
      ),
    [dataset, yieldAttributes],
  );

  const cleanAttributeItem = useMemo<FullAttributeItem | null>(
    () =>
      allAttributesItems.find((item) => item.value === cleanAttribute) || null,
    [allAttributesItems, cleanAttribute],
  );

  return (
    <>
      <PopupHeader
        classes={{
          root: 'fast-tab-content__header',
        }}
        title={t('clean-calibrate.yield-popup.clean-calibrate-title')}
        onCancel={onCancel}
      />
      <DialogContent className="fast-tab-content__content">
        <DialogContentText className="fast-tab-content__content-description">
          {t('clean-calibrate.yield-popup.tabs.fast.configure.description')}
        </DialogContentText>
        <ItemsPicker
          availableItemsTitle={t('clean-calibrate.yield-popup.attributes')}
          addedItemsTitle={t(
            'clean-calibrate.yield-popup.tabs.fast.configure.selected-attributes',
          )}
          items={allAttributesItems}
          addedItemsValues={yieldAttributes}
          required
          emptyAddedItemsLabel={t(
            'clean-calibrate.yield-popup.no-added-attributes',
          )}
          onAddedItemsChange={handleYieldAttributesChange}
        />
        {!calibrateAttributes?.length && (
          <WarningPanel
            text={t(
              'clean-calibrate.yield-popup.tabs.calibrate.configure.no-calibration-attributes-warning',
            )}
          />
        )}
        <Divider className="fast-tab-content__content-divider" />
        <DialogContentText>
          {t(
            'clean-calibrate.yield-popup.tabs.fast.configure.cleaning-attribute-instruction',
          )}
        </DialogContentText>
        <ComboBox
          title={t(
            'clean-calibrate.yield-popup.tabs.fast.configure.cleaning-attribute',
          )}
          placeholder={t('general.controls.select')}
          options={yieldAttributesItems}
          value={cleanAttributeItem!}
          getOptionSelected={getComboBoxOptionSelected}
          disableCloseOnSelect={false}
          disableClearable
          required
          onChange={handleCleanAttributeChange}
        />
        {!cleanAttribute && (
          <WarningPanel
            text={t(
              'clean-calibrate.yield-popup.tabs.clean.configure.no-cleaning-attribute-warning',
            )}
          />
        )}
        <Divider className="fast-tab-content__content-divider" />
        <CalibrationBasis
          syntheticUsed={syntheticMachinePath}
          syntheticUsedDisabled={!calibrateAttributes?.length}
          options={allAttributesItems}
          basisValue={calibrateBasisAttributeItem}
          basisDisabled={syntheticMachinePath}
          label={t(
            'clean-calibrate.yield-popup.tabs.fast.configure.machine-id',
          )}
          onBasisChange={handleCalibrationBasisChange}
          onSyntheticUsedChange={handleIsSyntheticBasisUsedChange}
        />
        {!syntheticMachinePath && !calibrateBasis && (
          <WarningPanel
            text={t(
              'clean-calibrate.yield-popup.tabs.calibrate.configure.no-machine-id-warning',
            )}
          />
        )}
      </DialogContent>
    </>
  );
}
