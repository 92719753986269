import { createSelector } from '@reduxjs/toolkit';

export const selectDataVariables = ({ createEquationBasedAnalysis }) =>
  createEquationBasedAnalysis.dataVariables;

export const selectActiveVariable = ({ createEquationBasedAnalysis }) =>
  createEquationBasedAnalysis.activeVariable;

export const selectActiveDataVariable = createSelector(
  selectDataVariables,
  selectActiveVariable,
  (dataVariables, activeVariable) =>
    dataVariables.find(
      (dataVariable) => dataVariable.variable === activeVariable,
    ),
);

export const selectGridXSize = ({ createEquationBasedAnalysis }) =>
  createEquationBasedAnalysis.gridSize.x;

export const selectGridYSize = ({ createEquationBasedAnalysis }) =>
  createEquationBasedAnalysis.gridSize.y;

export const selectType = ({ createEquationBasedAnalysis }) =>
  createEquationBasedAnalysis.type;

export const selectName = ({ createEquationBasedAnalysis }) =>
  createEquationBasedAnalysis.name;

export const selectChanged = ({ createEquationBasedAnalysis }) =>
  createEquationBasedAnalysis.changed;
