import React from 'react';
import clsx from 'clsx';

import ZonesMapTable from '../../../../../components/Views/common/ZonesMapTable';
import ZonesMapResultsPlaceholder from '../../../../../components/ZonesMapResultsPlaceholder';
import { getZonesHexColorCodes } from '../../../../../helpers/functions/entities/vectorAnalysisMap';

import './index.scss';

const SettingsPanel = ({ className, zonesMapGeojson, geoMaps, areaUnit }) => (
  <div className={clsx('settings-panel', className)}>
    {zonesMapGeojson && geoMaps && (
      <ZonesMapTable
        areaUnit={areaUnit}
        attributes={zonesMapGeojson}
        colors={getZonesHexColorCodes(geoMaps)}
      />
    )}
    {!zonesMapGeojson && !geoMaps && <ZonesMapResultsPlaceholder />}
  </div>
);

export default SettingsPanel;
