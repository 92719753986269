import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';

import Radio from '../../../../../components/Radio';
import Select from '../../../../../components/Select';
import PopupHeader from '../../../../../components/Popups/PopupHeader';
import Button from '../../../../../components/Button';
import { openPopup } from '../../../popups/popupsSlice';
import { selectOrganizations } from '../../../../user/userSelectors';

import './index.scss';

const SHARING_OPTIONS = {
  orgUsers: 'org-users',
  externalUsers: 'external-users',
};

const getOrganizationsOptions = (organizations) =>
  organizations.map(({ uuid, name }) => ({
    value: uuid,
    title: name,
  }));

const ShareFarms = ({ farmUuids, onConfirm, onCancel }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organizations = useSelector(selectOrganizations);
  const hasOrganizations = organizations.length !== 0;
  const [sharingOption, setSharingOption] = useState(
    hasOrganizations ? SHARING_OPTIONS.orgUsers : SHARING_OPTIONS.externalUsers,
  );
  const [selectedOrganizationUuid, setSelectedOrganizationUuid] = useState(
    organizations?.[0]?.uuid,
  );

  const handleSharingOptionChange = (_e, option) => {
    setSharingOption(option);
  };

  const onNext = () => {
    if (sharingOption === SHARING_OPTIONS.orgUsers) {
      dispatch(
        openPopup({
          type: 'share-farms-org',
          organizationUuid: selectedOrganizationUuid,
          farmUuids,
          onConfirm,
        }),
      );
    } else if (sharingOption === SHARING_OPTIONS.externalUsers) {
      dispatch(
        openPopup({
          type: 'share-farms-external',
          farmUuids,
          onConfirm,
        }),
      );
    }
  };

  useEffect(() => {
    if (!hasOrganizations) {
      onNext();
    }
  }, [hasOrganizations]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!hasOrganizations) {
    return null;
  }

  return (
    <Dialog
      open
      classes={{
        paper: 'share-farms-popup',
      }}
    >
      <PopupHeader
        title={t('general.popups.share-farms.title')}
        onCancel={onCancel}
      />
      <DialogContent>
        <DialogContentText
          classes={{
            root: 'share-farms-popup__content-text',
          }}
        >
          {t('general.popups.share-farms.description')}
        </DialogContentText>
        <RadioGroup value={sharingOption} onChange={handleSharingOptionChange}>
          <Radio
            value={SHARING_OPTIONS.orgUsers}
            label={t('general.popups.share-farms.from-org')}
          />
          <Select
            classes={{
              root: 'share-farms-popup__radio-select',
            }}
            options={getOrganizationsOptions(organizations)}
            value={selectedOrganizationUuid}
            onChange={setSelectedOrganizationUuid}
          />
          <Radio
            value={SHARING_OPTIONS.externalUsers}
            label={t('general.popups.share-farms.external-users')}
          />
          <Typography
            classes={{
              root: 'share-farms-popup__radio-description',
            }}
          >
            {t('general.popups.share-farms.external-users-description')}
          </Typography>
        </RadioGroup>
      </DialogContent>
      <DialogActions
        classes={{
          root: 'share-farms-popup__actions',
        }}
      >
        <Button variant="outlined" onClick={onCancel}>
          {t('general.controls.cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={onNext}>
          {t('general.stepper.next')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareFarms;
