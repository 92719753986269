import type {
  AvgTotalCalibrateCondition,
  CalibrateDatasetActionParams,
} from '../types/actions';
import { useAppSelector } from '../../../../app/store/helpers/functions';
import { selectYieldDataset } from '../../../field/fieldSelectors';
import {
  selectCalibrationType,
  selectCalibrateCalibrationAttributes,
  selectCalibratePathwiseCalibrationBasis,
  selectCalibratePathwiseSyntheticMachinePath,
  selectCalibrateAvgTotalConditions,
  selectCalibrateMinMaxConditions,
  selectDatasetUuid,
  selectCalibrateSmoothWindowSize,
} from '../cleanCalibrateSelectors';
import { CalibrationType } from '../helpers/constants/ui';
import {
  isCalibrateAvgTotalParamsValid,
  isCalibrateMinMaxParamsValid,
  isCalibratePathwiseParamsValid,
} from '../helpers/functions/actions';

export default function useCalibrateParams(): CalibrateDatasetActionParams | null {
  const datasetUuid = useAppSelector(selectDatasetUuid);
  const dataset = useAppSelector((state) =>
    selectYieldDataset(state, datasetUuid),
  );
  const calibrationType = useAppSelector(selectCalibrationType);
  const smoothWindowSize = useAppSelector(selectCalibrateSmoothWindowSize);
  const calibrationAttributes = useAppSelector(
    selectCalibrateCalibrationAttributes,
  );
  const pathwiseCalibrationBasis = useAppSelector(
    selectCalibratePathwiseCalibrationBasis,
  );
  const pathwiseSyntheticMachinePath = useAppSelector(
    selectCalibratePathwiseSyntheticMachinePath,
  );
  const avgTotalConditions = useAppSelector(selectCalibrateAvgTotalConditions);
  const minMaxConditions = useAppSelector(selectCalibrateMinMaxConditions);

  let params: CalibrateDatasetActionParams | null = null;
  const requiredParams = {
    calibrationAttributes,
    conditionPathwiseCalibration: {
      calibrationBasis: pathwiseCalibrationBasis,
    },
    conditionAvgTotalCalibration: avgTotalConditions,
    conditionMinMaxCalibration: minMaxConditions,
  };

  if (
    calibrationType === CalibrationType.pathwise &&
    isCalibratePathwiseParamsValid(requiredParams)
  ) {
    params = {
      smoothWindowSize,
      calibrationAttributes: requiredParams.calibrationAttributes,
      conditionPathwiseCalibration: {
        calibrationBasis:
          requiredParams.conditionPathwiseCalibration.calibrationBasis,
        syntheticMachinePath: pathwiseSyntheticMachinePath,
        maxHomogeneityRegion: true,
      },
      conditionAvgTotalCalibration:
        requiredParams.conditionAvgTotalCalibration.map(
          ({ type, ...condition }) => condition as AvgTotalCalibrateCondition,
        ),
    };
  } else if (
    calibrationType === CalibrationType.averageTotal &&
    isCalibrateAvgTotalParamsValid(requiredParams)
  ) {
    params = {
      smoothWindowSize,
      calibrationAttributes: requiredParams.conditionAvgTotalCalibration.map(
        (condition) => condition.calibrationAttribute!,
      ),
      conditionAvgTotalCalibration:
        requiredParams.conditionAvgTotalCalibration.map(
          ({ type, ...condition }) => condition as AvgTotalCalibrateCondition,
        ),
    };
  } else if (
    calibrationType === CalibrationType.conditional &&
    isCalibrateMinMaxParamsValid(requiredParams, dataset?.statistics || [])
  ) {
    params = {
      smoothWindowSize,
      calibrationAttributes: requiredParams.conditionMinMaxCalibration.map(
        (condition) => condition.calibrationAttribute!,
      ),
      conditionMinMaxCalibration: requiredParams.conditionMinMaxCalibration,
    };
  }

  return params;
}
