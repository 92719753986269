const isAssetJDExportDisabled = ({
  jdProfileIsHealth,
  jdProfileIsAuthorized,
  isSynchronizedJohnDeereField,
}: {
  jdProfileIsHealth: boolean;
  jdProfileIsAuthorized: boolean;
  isSynchronizedJohnDeereField: boolean;
}) =>
  !jdProfileIsHealth || !jdProfileIsAuthorized || !isSynchronizedJohnDeereField;

export default isAssetJDExportDisabled;
