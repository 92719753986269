import React, { ReactElement, useMemo } from 'react';
import { TableVirtuoso, TableProps, ItemProps } from 'react-virtuoso';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from '@material-ui/core/CircularProgress';

import type { TableRow } from '../../types/row';
import { isDefaultTableRow } from '../../helpers/functions/rows';

import './index.scss';

const DEFAULT_COLUMNS_AMOUNT = 1;

interface TableClasses {
  root?: string;
}

interface TableRowClasses {
  selected?: string;
}

const TableComponent = (
  { style, ...props }: TableProps,
  classes?: TableClasses,
) => <Table {...props} classes={classes} style={style} />;

function TableRowComponent<T extends TableRow>(
  { children, ...props }: ItemProps<T | null>,
  onClick?: (item: T | null) => void,
  getClasses?: (item: T | null) => TableRowClasses,
) {
  let classes: TableRowClasses = {};
  const rowClickable = !!onClick && props.item && !props.item.disabled;

  if (getClasses) {
    classes = getClasses(props.item);
  }

  const handleClick = (row: T | null) => {
    if (row && !row.disabled) {
      onClick?.(row);
    }
  };

  return (
    <tr
      {...props}
      data-test={
        props.item?.rowType === 'nested'
          ? 'infinite-table-nested-row'
          : 'infinite-table-row'
      }
      className={clsx('infinite-table__row', classes.selected, {
        'infinite-table__row_disabled':
          isDefaultTableRow(props.item) && props.item?.disabled,
        'infinite-table__row_clickable': rowClickable,
        'infinite-table__row_nested': props.item?.rowType === 'nested',
      })}
      onClick={() => handleClick(props.item)}
    >
      {children}
    </tr>
  );
}

export default function InfiniteTable<T extends TableRow>({
  data = [],
  loadComplete = false,
  useWindowScroll = false,
  columnsAmount = DEFAULT_COLUMNS_AMOUNT,
  tableClasses,
  getRowClasses,
  rowRenderer,
  headerContentRenderer = () => null,
  onLoadMoreRows,
  onRowClick,
}: {
  data: T[];
  useWindowScroll?: boolean;
  loadComplete: boolean;
  columnsAmount?: number;
  tableClasses?: TableClasses;
  getRowClasses?: (item: T | null) => TableRowClasses;
  rowRenderer: (rowData: T) => ReactElement;
  headerContentRenderer?: () => ReactElement | null;
  onLoadMoreRows?: (index: number) => void;
  onRowClick?: (item: T | null) => void;
}) {
  const itemRenderer = (_index: number, itemData: T | null) => {
    if (itemData) {
      return rowRenderer(itemData);
    }

    return (
      <TableCell
        className="infinite-table__loader-wrapper"
        colSpan={columnsAmount}
      >
        <CircularProgress
          data-test="infinite-table-loading"
          classes={{
            root: 'infinite-table__loader',
          }}
        />
      </TableCell>
    );
  };

  const tableData = useMemo(
    () => (loadComplete ? data : [...data, null]),
    [data, loadComplete],
  );

  return (
    <TableVirtuoso
      data-test="infinite-table"
      data={tableData}
      useWindowScroll={useWindowScroll}
      components={{
        Table: (props) => TableComponent(props, tableClasses),
        TableRow: (props) =>
          TableRowComponent(props, onRowClick, getRowClasses),
      }}
      endReached={onLoadMoreRows}
      fixedHeaderContent={headerContentRenderer}
      itemContent={itemRenderer}
    />
  );
}
