import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import EditIcon from '@material-ui/icons/Edit';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';

import ExportIcon from '../../Icons/export';
import PopupHeader from '../PopupHeader';
import Button from '../../Button';
import {
  getRatesData,
  calculateTotalProductVolumes,
  calculateCostPerProduct,
} from '../../../helpers/analysis';
import { getZonesHexColorCodes } from '../../../helpers/functions/entities/vectorAnalysisMap';
import { getZonesMapLink } from '../../../helpers/navigation';
import {
  convertNumberToFormattedString,
  prettifyNumber,
} from '../../../helpers/markup';
import { getAreaUnitLabel } from '../../../helpers';
import { PRODUCT_UNIT_TO_I18N_LABEL } from '../../../helpers/constants/units/productUnit';
import useCurrentPage from '../../../hooks/useCurrentPage';
import AmplitudeAnalytics from '../../../helpers/classes/amplitudeAnalytics';

import './index.scss';

const mapToCells = (entry, entryIndex) => (
  <TableCell
    key={entryIndex}
    align="right"
    classes={{
      root: 'cell footer-cell',
    }}
  >
    {prettifyNumber(entry)}
  </TableCell>
);

const RatesPreviewPopup = ({
  zonesMapName,
  attributes,
  geoMaps,
  farmUuid,
  fieldUuid,
  uuid,
  totalArea,
  areaUnit,
  onCancel = () => {},
  onExportZonesMap = () => {},
}) => {
  const { t } = useTranslation();
  const page = useCurrentPage();
  const ratesHeaders = getRatesData(attributes);
  const hexColorCodes = getZonesHexColorCodes(geoMaps);
  const { ratesPrices, ratesUnits } =
    attributes.features[0].properties.attributes;
  const totalProductVolumes = calculateTotalProductVolumes(attributes);
  const costsPerProduct = calculateCostPerProduct(
    ratesPrices,
    totalProductVolumes,
  );
  const totalProductCost = costsPerProduct.reduce((acc, cost) => acc + cost, 0);

  useEffect(() => {
    if (page) {
      AmplitudeAnalytics.trackRatesPreviewPopupOpened({ page });
    }
  }, [page]);

  const handelEditRatesClick = () => {
    onCancel();
    AmplitudeAnalytics.trackNavigatedToZonesMap({
      view: 'rates',
      page,
    });
  };

  return (
    <Dialog
      open
      classes={{
        root: 'rates-preview-popup',
        paper: 'rates-preview-popup__paper',
      }}
    >
      <PopupHeader
        title={
          <div className="rates-preview-popup__header">
            <span>{t('zones-map.tabs.rates')}</span>
            <span className="rates-preview-popup__header_secondary">
              {zonesMapName}
            </span>
          </div>
        }
        onCancel={onCancel}
      />
      <DialogContent>
        <Table
          classes={{
            root: 'rates-preview-table',
          }}
        >
          <TableHead>
            <TableRow className="header-row">
              <TableCell align="left" className="header-cell">
                {t('zones-ops.multi-layer.steps.4.results-table.color')}
              </TableCell>
              <TableCell align="right" className="header-cell">
                {getAreaUnitLabel(areaUnit)}
              </TableCell>
              {ratesHeaders.map((rateHeader, rateHeaderIndex) => (
                <TableCell
                  key={rateHeaderIndex}
                  align="right"
                  className="header-cell"
                >
                  {rateHeader}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {attributes.features.map((feature, featureIndex) => {
              const {
                zone,
                attributes: { area, rates = [] },
              } = feature.properties;

              return (
                <TableRow key={featureIndex} className="row">
                  <TableCell align="left" className="cell color-cell">
                    <div
                      style={{ backgroundColor: hexColorCodes[zone - 1] }}
                      className="color"
                    ></div>
                  </TableCell>
                  <TableCell align="right" className="cell">
                    {`${prettifyNumber(area)} (${prettifyNumber((100 * area) / totalArea)}%)`}
                  </TableCell>
                  {ratesHeaders.map((_rateHeader, rateHeaderIndex) => (
                    <TableCell
                      key={rateHeaderIndex}
                      align="right"
                      className="cell"
                    >
                      {convertNumberToFormattedString(
                        rates[rateHeaderIndex].toString(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow
              classes={{
                root: 'row footer-row',
              }}
            >
              <TableCell
                colSpan={2}
                classes={{
                  root: 'cell footer-cell',
                }}
              >
                {t('zones-map.rates.total-product-volume')}
              </TableCell>
              {totalProductVolumes.map(mapToCells)}
            </TableRow>
            <TableRow
              classes={{
                root: 'row footer-row',
              }}
            >
              <TableCell
                colSpan={2}
                classes={{
                  root: 'cell footer-cell',
                }}
              >
                {t('general.labels.unit')}
              </TableCell>
              {ratesUnits.map((unit, ind) => (
                <TableCell
                  key={ind}
                  align="right"
                  classes={{
                    root: 'cell footer-cell',
                  }}
                >
                  {t(PRODUCT_UNIT_TO_I18N_LABEL[unit])}
                </TableCell>
              ))}
            </TableRow>
            <TableRow
              classes={{
                root: 'row footer-row',
              }}
            >
              <TableCell
                colSpan={2}
                classes={{
                  root: 'cell footer-cell',
                }}
              >
                {t('zones-map.rates.cost-per-product')}
              </TableCell>
              {costsPerProduct.map(mapToCells)}
            </TableRow>
            <TableRow
              classes={{
                root: 'row sum-row',
              }}
            >
              <TableCell
                colSpan={ratesHeaders.length <= 1 ? 1 : ratesHeaders.length + 1}
                align={ratesHeaders.length <= 1 ? 'left' : 'right'}
                classes={{
                  root: 'cell footer-cell',
                }}
              >
                {t('zones-map.rates.total-product-cost')}
              </TableCell>
              {
                <TableCell
                  align="right"
                  colSpan={ratesHeaders.length <= 1 ? 2 : 1}
                  classes={{
                    root: 'cell footer-cell',
                  }}
                >
                  {prettifyNumber(totalProductCost)}
                </TableCell>
              }
            </TableRow>
          </TableFooter>
        </Table>
      </DialogContent>
      <DialogActions
        classes={{
          root: 'rates-preview-popup__actions',
        }}
      >
        <Button
          variant="outlined"
          startIcon={<EditIcon />}
          component={Link}
          to={getZonesMapLink(farmUuid, fieldUuid, uuid)}
          onClick={handelEditRatesClick}
          classes={{
            startIcon: 'rates-preview-actions__button-icon',
          }}
        >
          {t('general.popups.rates-preview.edit-rates')}
        </Button>
        <Button
          variant="outlined"
          startIcon={<ExportIcon />}
          onClick={onExportZonesMap}
          classes={{
            startIcon: 'rates-preview-actions__button-icon',
          }}
        >
          {t('export.export')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RatesPreviewPopup;
