import {
  useAppDispatch,
  useAppSelector,
} from '../../../../app/store/helpers/functions';
import { applyRatesToZonesMapGeojson } from '../helpers/functions/updates';
import { selectVectorAnalysisMap } from '../../../field/fieldSelectors';
import { saveVamapWithGeoMaps } from '../../../field/fieldSlice';
import {
  selectUpdates,
  selectRatesPerProduct,
  selectVamapType,
  selectProducts,
} from '../zonesMapSelectors';

export default function useSaveVamapUpdates({
  fieldUuid,
  uuid,
}: {
  fieldUuid: string;
  uuid: string;
}) {
  const dispatch = useAppDispatch();
  const updates = useAppSelector(selectUpdates);
  const vamapType = useAppSelector(selectVamapType);
  const vamap = useAppSelector((state) => selectVectorAnalysisMap(state, uuid));
  const ratesPerProduct = useAppSelector(selectRatesPerProduct);
  const products = useAppSelector(selectProducts);

  const saveUpdates = () => {
    const updatedZonesMapGeojson = applyRatesToZonesMapGeojson(
      updates.zonesMapGeojson || vamap?.zonesMapGeojson,
      ratesPerProduct,
      products,
    );

    void dispatch(
      saveVamapWithGeoMaps({
        fieldUuid,
        uuid,
        name: vamap?.name || '',
        zonesMapGeojson: updatedZonesMapGeojson,
        type: vamapType,
        colors: updates.colors,
      }),
    );
  };

  return {
    saveUpdates,
  };
}
