import { captureException as captureExceptionToSentry } from '@sentry/react';

export class CustomError extends Error {
  constructor(message: string, options?: ErrorOptions) {
    super(message, options);
    this.name = message; // Set error name to display the issue title in Sentry
  }
}

const hasCause = (error: unknown): error is { cause: unknown } =>
  typeof error === 'object' && error != null && 'cause' in error;

export const hasErrors = <T = unknown>(
  error: unknown,
): error is { errors: T } =>
  typeof error === 'object' && error != null && 'errors' in error;

const hasMessage = (error: unknown): error is { message: string } =>
  typeof error === 'object' && error != null && 'message' in error;

const getErrorMessage = (error: unknown): string =>
  hasMessage(error) ? error.message : '';

export const captureException = ({
  message = '',
  error,
}: {
  message?: string;
  error: unknown;
}): void => {
  // eslint-disable-next-line no-console
  console.error(message || getErrorMessage(error), error);

  if (hasCause(error)) {
    // eslint-disable-next-line no-console
    console.error('Cause:', error.cause);
  }

  captureExceptionToSentry(error, (scope) => {
    let errorData;

    if (hasErrors(error)) {
      errorData = JSON.stringify(error.errors);
    } else if (hasCause(error)) {
      errorData = JSON.stringify(error.cause);
    } else {
      errorData = error;
    }

    return scope.setExtra('Error Object', errorData);
  });
};
