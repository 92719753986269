import i18n from 'i18next';

import { featureCollection, bbox, bboxPolygon, combine } from '@turf/turf';
import type { Feature, Polygon } from 'geojson';
import { PLANET_PROVIDER } from '../../../features/satelliteImages/helpers/constants';
import { GeoMapTypeOption } from '../../../features/satelliteImages/helpers/constants/geoMapType';
import { TransformedSatelliteImage } from '../../../features/satelliteImages/types/satelliteImage';
import { DatasetViewType } from '../../constants/entities/dataset';
import { DEFAULT_GEOMAP } from '../../constants/entities/equationMap';
import { TileSize } from '../../constants/map';
import {
  isPublishedStatus,
  getDatasetGeoMapName,
  getDatasetViewProps,
} from '../entities/dataset';
import { convertSourcesToMapBoxFormat, encode } from '../../mapbox/source';
import { TransformedAsset } from '../../types';
import {
  isAsAppliedDataset,
  isDataset,
  isEquationMap,
  isField,
  isSatelliteImage,
  isTopographyMap,
  isVectorAnalysis,
  isYieldDataset,
} from '../entities/assets';
import getDatasetTileSize from '../entities/getDatasetTileSize';
import { getSatelliteImageViewProps } from '../entities/satelliteImage';
import {
  convertAttributeToGeoMapName,
  getTopographyMapViewProps,
} from '../entities/topographyMap';
import {
  TransformedDataset,
  TransformedTopographyMap,
} from '../../types/dataset';
import { TransformedVectorAnalysisMap } from '../../types/vectorAnalysisMap';
import { TransformedEquationMap } from '../../types/equationMap';
import {
  DatasetMapParams,
  AssetLayerConfig,
  SatelliteImageMapParams,
  FieldLayerConfig,
} from '../../types/map';
import { TransformedField } from '../../../features/field/types/field';
import getDatasetMapAttributeAndViewType from './getDatasetMapAttributeAndViewType';

export const DEFAULT_MAP_ZOOM = 12;

const PADDING_BOUNDS = {
  xl: { farm: 80, field: 80 },
  lg: { farm: 80, field: 80 },
  md: { farm: 60, field: 60 },
  sm: { farm: 40, field: 40 },
  es: { farm: 20, field: 20 },
};

export const getPaddingBounds = (
  type: 'field' | 'farm' = 'field',
  mapWidth = window.innerWidth,
) => {
  let paddingBounds = PADDING_BOUNDS.md;

  if (mapWidth >= 1200) {
    paddingBounds = PADDING_BOUNDS.xl;
  } else if (mapWidth >= 992) {
    paddingBounds = PADDING_BOUNDS.lg;
  } else if (mapWidth >= 768) {
    paddingBounds = PADDING_BOUNDS.md;
  } else if (mapWidth >= 576) {
    paddingBounds = PADDING_BOUNDS.sm;
  } else if (mapWidth < 576) {
    paddingBounds = PADDING_BOUNDS.es;
  }

  return paddingBounds[type];
};

export const getDefaultFieldLayerConfig = (
  type?: 'field' | 'farm',
  mapWidth?: number,
): FieldLayerConfig => ({
  center: [-50.07432111635774, 42.76133233705622],
  zoom: 0.8,
  maxPitch: 85,
  map: {
    fitBounds: [
      [-128, -46],
      [152, 82],
    ],
    fitBoundsOptions: {
      padding: getPaddingBounds(type, mapWidth),
    },
  },
  rasterSources: [],
  tileSize: TileSize.large,
});

export const prepareFarmLayerConfig = (
  fields: Pick<
    TransformedField,
    'status' | 'centroid' | 'boundingBox' | 'geoMaps' | 'uuid'
  >[] = [],
) => {
  const config = getDefaultFieldLayerConfig('farm');
  const bboxPolygons: Feature<Polygon>[] = [];

  fields.forEach((field) => {
    if (field.status === 'INVALID') {
      return;
    }

    if (field.uuid && field.boundingBox) {
      bboxPolygons.push(bboxPolygon(field.boundingBox));
    }
  });

  if (bboxPolygons.length) {
    const boundingBox = bbox(combine(featureCollection(bboxPolygons)));
    config.map.fitBounds = [
      [boundingBox[0], boundingBox[1]],
      [boundingBox[2], boundingBox[3]],
    ];
  }

  return config;
};

export const prepareFarmLayerSources = (
  fields: Pick<
    TransformedField,
    'status' | 'centroid' | 'boundingBox' | 'geoMaps' | 'uuid'
  >[] = [],
) => {
  let rasterSources: { url: string }[] = [];
  const rasterSourcesUuids: string[] = [];

  fields.forEach((field) => {
    if (field.status === 'INVALID') {
      return;
    }

    if (field.uuid && field.boundingBox) {
      rasterSourcesUuids.push(field.uuid);
    }
  });

  if (rasterSourcesUuids.length > 0) {
    const uuidsList = rasterSourcesUuids.map((uuid) => `'${uuid}'`);
    const cqlFilter = encode(
      [`CQL_FILTER=uuid IN (${uuidsList.join(', ')})`],
      'CQL_FILTER',
    );
    const rasterSource =
      'https://api.geopard.tech/geo/map?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&FORMAT=image/png&TRANSPARENT=true' +
      `&${cqlFilter}&LAYERS=agri:field&exceptions=application/vnd.ogc.se_inimage` +
      `&SRS=EPSG:3857&STYLES=&WIDTH=${TileSize.large}&HEIGHT=${TileSize.large}&BBOX=template`;

    rasterSources = convertSourcesToMapBoxFormat(
      [rasterSource],
      TileSize.large,
    ).map((url) => ({ url }));
  }

  return rasterSources;
};

export const prepareToDisplayFieldLayer = (
  field?: Pick<
    TransformedField,
    'status' | 'centroid' | 'boundingBox' | 'geoMaps'
  > | null,
  useBounds?: boolean,
  mapWidth?: number,
) => {
  const config = getDefaultFieldLayerConfig('field', mapWidth);

  if (field && field.status !== 'INVALID') {
    const { centroid, boundingBox = [], geoMaps = [] } = field;

    if (centroid?.longitude && centroid?.latitude) {
      config.center = [centroid.longitude, centroid.latitude];
      config.zoom = DEFAULT_MAP_ZOOM;
    }

    if (boundingBox.length === 4) {
      config.map.fitBounds = [
        [boundingBox[0], boundingBox[1]],
        [boundingBox[2], boundingBox[3]],
      ];
      config.map.fitBoundsOptions.padding = getPaddingBounds();

      if (useBounds) {
        config.bounds = boundingBox;
      }
    }

    const geoMapData = geoMaps.find((geoMap) => geoMap.shortName === 'default');

    if (geoMapData?.url) {
      config.rasterSources = convertSourcesToMapBoxFormat(
        [geoMapData.url],
        TileSize.large,
      ).map((url) => ({ url }));
    }
  }

  return config;
};

export const getSatelliteMapLayerConfig = (
  image?: TransformedSatelliteImage | null,
  { geoMapType, viewType, isRawType }: SatelliteImageMapParams = {},
): AssetLayerConfig => {
  if (!image) {
    return {
      rasterSources: [],
    };
  }

  const { viewValue } = getSatelliteImageViewProps({
    image,
    geoMapType,
    viewType,
    isRawType,
  });

  const attribution =
    image.satelliteImage.provider === PLANET_PROVIDER
      ? i18n.t('general.planet-license.powered-by-planet')
      : '';

  const selectedGeoMap = image.geoMaps?.find(
    (geoMap) => geoMap.shortName === viewValue,
  );

  return selectedGeoMap
    ? {
        tileSize: TileSize.large,
        rasterSources: convertSourcesToMapBoxFormat(
          [selectedGeoMap?.url],
          TileSize.large,
        ),
        attribution,
      }
    : {
        rasterSources: [],
        attribution,
      };
};

export const getDatasetMapLayerConfig = (
  dataset?: TransformedDataset | null,
  { attribute, viewType }: DatasetMapParams = {},
): AssetLayerConfig => {
  if (!dataset) {
    return {
      rasterSources: [],
    };
  }

  const { attribute: processedAttribute, view: processedView } =
    getDatasetViewProps(dataset, attribute, viewType);

  const geoMapName = getDatasetGeoMapName({
    view: processedView,
    attribute: processedAttribute,
    useGeoMapVersionTwo: isYieldDataset(dataset) || isAsAppliedDataset(dataset),
  });

  let selectedGeoMap = null;

  if (processedAttribute) {
    selectedGeoMap = dataset.geoMaps?.find(
      (geoMap) => geoMap.shortName === geoMapName,
    );
  } else if (isPublishedStatus(dataset.status)) {
    selectedGeoMap = dataset.geoMaps?.find(
      (source) => source.shortName === 'default',
    );
  }

  const tileSize = getDatasetTileSize(dataset);

  return {
    tileSize,
    rasterSources: selectedGeoMap
      ? convertSourcesToMapBoxFormat([selectedGeoMap.url], tileSize)
      : [],
  };
};

export const getTopographyMapLayerConfig = (
  topographyMap?: TransformedTopographyMap | null,
  {
    viewType,
    isCreationView,
  }: {
    viewType?: string;
    isCreationView?: boolean;
  } = {},
): AssetLayerConfig => {
  if (!topographyMap) {
    return {
      rasterSources: [],
    };
  }

  const { attribute } = getTopographyMapViewProps(
    topographyMap,
    viewType,
    isCreationView,
  );
  const geoMapName = convertAttributeToGeoMapName(attribute);
  const selectedGeoMap = topographyMap.geoMaps?.find(
    (geoMap) => geoMap.shortName === geoMapName,
  );

  return {
    tileSize: TileSize.large,
    attribution: topographyMap.source,
    rasterSources: selectedGeoMap
      ? convertSourcesToMapBoxFormat([selectedGeoMap.url], TileSize.large)
      : [],
  };
};

export const getZonesMapLayerConfig = (
  zonesMap?: TransformedVectorAnalysisMap | null,
): AssetLayerConfig => {
  if (!zonesMap || !zonesMap.geoMaps) {
    return { rasterSources: [] };
  }

  const selectedGeoMap = zonesMap.geoMaps?.find(
    (geoMap) => geoMap.shortName === 'zones',
  );

  return {
    tileSize: TileSize.large,
    rasterSources: selectedGeoMap
      ? convertSourcesToMapBoxFormat([selectedGeoMap?.url], TileSize.large)
      : [],
  };
};

export const getEquationMapLayerConfig = (
  equationMap?: TransformedEquationMap | null,
  { activeGeoMap }: { activeGeoMap?: string } = {},
): AssetLayerConfig => {
  if (!equationMap || !equationMap.geoMaps) {
    return { rasterSources: [] };
  }

  const selectedGeoMap = activeGeoMap || DEFAULT_GEOMAP;
  const equationMapRasterSources = equationMap.geoMaps?.find(
    (geoMap) => geoMap.shortName === selectedGeoMap,
  );

  return {
    tileSize: TileSize.small,
    rasterSources: equationMapRasterSources
      ? convertSourcesToMapBoxFormat(
          [equationMapRasterSources.url],
          TileSize.small,
        )
      : [],
  };
};

export const getMapLayerProps = (
  item?: TransformedAsset | null,
  {
    soilViewType = null,
    soilAttribute,
    yieldViewType = null,
    yieldAttribute,
    asAppliedViewType = null,
    asAppliedAttribute,
    satelliteGeoMapType,
    isRawSatelliteType,
    satelliteViewType,
    equationMapGeoMap,
    topographyMapViewType,
    isCreationView,
  }: {
    soilViewType?: DatasetViewType | null;
    soilAttribute?: string;
    yieldViewType?: DatasetViewType | null;
    yieldAttribute?: string;
    asAppliedViewType?: DatasetViewType | null;
    asAppliedAttribute?: string;
    satelliteGeoMapType?: GeoMapTypeOption;
    isRawSatelliteType?: boolean;
    satelliteViewType?: string;
    topographyMapViewType?: string;
    equationMapGeoMap?: string;
    isCreationView?: boolean;
  } = {},
): AssetLayerConfig => {
  const result = {
    rasterSources: [],
  };

  if (!item || isField(item)) {
    return result;
  }

  if (isSatelliteImage(item)) {
    return getSatelliteMapLayerConfig(item, {
      geoMapType: satelliteGeoMapType,
      viewType: satelliteViewType,
      isRawType: isRawSatelliteType,
    });
  }
  if (isVectorAnalysis(item)) {
    return getZonesMapLayerConfig(item);
  }
  if (isEquationMap(item)) {
    return getEquationMapLayerConfig(item, {
      activeGeoMap: equationMapGeoMap,
    });
  }
  if (isDataset(item)) {
    const { attribute, viewType } = getDatasetMapAttributeAndViewType(item, {
      soilAttribute,
      yieldAttribute,
      asAppliedAttribute,
      soilViewType,
      yieldViewType,
      asAppliedViewType,
    });

    return getDatasetMapLayerConfig(item, {
      attribute,
      viewType,
    });
  }
  if (isTopographyMap(item)) {
    return getTopographyMapLayerConfig(item, {
      viewType: topographyMapViewType,
      isCreationView,
    });
  }

  return result;
};
