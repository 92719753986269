import { compareLayersDataLayersTreeMode } from '../../../compareLayers/helpers/constants';
import { exportPdfDataLayersTreeMode } from '../constants/export';
import DataLayersTreeViewType from '../constants/dataLayersTreeViewType';

const getDataLayersTreeMode = (viewType: DataLayersTreeViewType) => {
  if (viewType === DataLayersTreeViewType.exportPdf) {
    return exportPdfDataLayersTreeMode;
  }

  if (viewType === DataLayersTreeViewType.compareLayers) {
    return compareLayersDataLayersTreeMode;
  }

  return DataLayersTreeViewType.default;
};

export default getDataLayersTreeMode;
