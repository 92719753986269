import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import PopupHeader from '../../../../../../components/Popups/PopupHeader';
import Button from '../../../../../../components/Button';
import { getEquationMapLink } from '../../../../../../helpers/navigation';
import useCreateAnotherAnalysis from '../../../../zonesOps/hooks/useCreateAnotherAnalysis';
import { selectSelectedField } from '../../../../zonesOps/zonesOpsSelectors';
import { selectGeneratedEquationMap } from '../../../../../createAnalysis/createAnalysisSelectors';
import { selectName } from '../../../createEquationBasedAnalysisSelectors';

import './index.scss';

const SaveEquationMapPopup = ({ onCancel = () => {} }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const createAnotherAnalysis = useCreateAnotherAnalysis();
  const { farmUuid, fieldUuid } = useSelector(selectSelectedField);
  const name = useSelector(selectName);
  const generatedEquationMap = useSelector(selectGeneratedEquationMap);

  const handleCancel = () => {
    onCancel();
  };

  const handleConfirm = (action) => {
    if (action === 'create-another') {
      createAnotherAnalysis();
    } else if (action === 'go-equation-map') {
      history.push(
        getEquationMapLink(farmUuid, fieldUuid, generatedEquationMap.uuid),
      );
    }
    handleCancel();
  };

  return (
    <Dialog
      open
      classes={{
        root: 'save-equation-map-popup',
        paper: 'save-equation-map-popup__paper',
      }}
    >
      <PopupHeader
        title={t('general.popups.save-equation-map.title')}
        onCancel={handleCancel}
      />
      <DialogContent
        classes={{
          root: 'save-equation-map-popup__content',
        }}
      >
        <DialogContentText component="span" color="textPrimary" variant="body2">
          <Trans
            i18nKey="general.popups.save-equation-map.content-text"
            name={name}
          >
            text{' '}
            <span className="save-equation-map-popup__text_highlighted">
              {{ name }}
            </span>{' '}
            text
          </Trans>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="save-equation-map-popup__actions">
        <Button
          onClick={() => handleConfirm('create-another')}
          variant="outlined"
        >
          {t('general.popups.create-another-one')}
        </Button>
        <Button
          onClick={() => handleConfirm('go-equation-map')}
          variant="contained"
          color="primary"
        >
          {t('general.popups.save-equation-map.actions.go-to-equation-map')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveEquationMapPopup;
