import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AvgTotalCalibrationType,
  CalibrationType,
  CleaningType,
  ProcessingType,
  Sequence,
} from './helpers/constants/ui';
import type {
  NullableAvgTotalCalibrateCondition,
  NullableMinMaxCalibrateCondition,
  NullableMinMaxCleanCondition,
  NullableMinMaxCleanUSDACondition,
  NullablePathwiseCalibrateCondition,
} from './types/actions';
import { DEFAULT_SMOOTH_WINDOW_SIZE } from './helpers/constants/actions';

export interface CleanCalibrateState {
  datasetUuid: string | null;
  tab: ProcessingType;
  step: number;
  isProcessing: boolean;
  sequence: Sequence;
  calibrate: {
    type: CalibrationType;
    calibrationAttributes: string[] | null;
    smoothWindowSize: number;
    minMaxConditions: NullableMinMaxCalibrateCondition[];
    avgTotalConditions: NullableAvgTotalCalibrateCondition[];
    pathwiseCondition: Omit<
      NullablePathwiseCalibrateCondition,
      'maxHomogeneityRegion'
    >;
  };
  clean: {
    type: CleaningType;
    targetAttribute: string | null;
    excludedAttributes: string[] | null;
    minMaxConditions: NullableMinMaxCleanCondition[];
    minMaxUSDAConditions: NullableMinMaxCleanUSDACondition[];
  };
}

export const initialState: CleanCalibrateState = {
  datasetUuid: null,
  tab: ProcessingType.fast,
  step: 0,
  isProcessing: false,
  sequence: Sequence.cleanFirst,
  calibrate: {
    type: CalibrationType.pathwise,
    calibrationAttributes: null,
    smoothWindowSize: DEFAULT_SMOOTH_WINDOW_SIZE,
    minMaxConditions: [],
    avgTotalConditions: [],
    pathwiseCondition: {
      calibrationBasis: null,
      syntheticMachinePath: false,
    },
  },
  clean: {
    type: CleaningType.smart,
    targetAttribute: null,
    excludedAttributes: null,
    minMaxConditions: [],
    minMaxUSDAConditions: [],
  },
};

export const cleanCalibrateSlice = createSlice({
  name: 'cleanCalibrate',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    resetUserInput(state) {
      state.calibrate.minMaxConditions =
        initialState.calibrate.minMaxConditions;
      state.calibrate.avgTotalConditions =
        initialState.calibrate.avgTotalConditions;
      state.calibrate.type = initialState.calibrate.type;
      state.calibrate.smoothWindowSize =
        initialState.calibrate.smoothWindowSize;

      state.clean.excludedAttributes = initialState.clean.excludedAttributes;
      state.clean.minMaxConditions = initialState.clean.minMaxConditions;
    },
    setDatasetUuid(state, action: PayloadAction<string>) {
      state.datasetUuid = action.payload;
    },
    setTab(state, action: PayloadAction<ProcessingType>) {
      state.tab = action.payload;
    },
    setStep(state, action: PayloadAction<number>) {
      state.step = action.payload;
    },
    setIsProcessing(state, action: PayloadAction<boolean>) {
      state.isProcessing = action.payload;
    },
    changeSequence(state, action: PayloadAction<Sequence>) {
      state.sequence = action.payload;

      state.calibrate.minMaxConditions =
        initialState.calibrate.minMaxConditions;
      state.calibrate.avgTotalConditions =
        initialState.calibrate.avgTotalConditions;
      state.calibrate.type = initialState.calibrate.type;
      state.calibrate.smoothWindowSize =
        initialState.calibrate.smoothWindowSize;

      state.clean.excludedAttributes = initialState.clean.excludedAttributes;
      state.clean.minMaxConditions = initialState.clean.minMaxConditions;
    },
    changeCalibrationType(state, action: PayloadAction<CalibrationType>) {
      state.calibrate.type = action.payload;
      state.calibrate.avgTotalConditions = [];
      state.calibrate.minMaxConditions = [];
    },
    setCalibrateSmoothWindowSize(state, action: PayloadAction<number>) {
      state.calibrate.smoothWindowSize = action.payload;
    },
    setCalibrateCalibrationAttributes(state, action: PayloadAction<string[]>) {
      state.calibrate.calibrationAttributes = action.payload;
    },
    toggleCalibratePathwiseSyntheticMachinePath(
      state,
      action: PayloadAction<{
        calibrationAttributes: string[] | null;
      }>,
    ) {
      const syntheticPathEnabled =
        action.payload.calibrationAttributes?.length !== 0;
      state.calibrate.pathwiseCondition.syntheticMachinePath =
        syntheticPathEnabled;

      if (syntheticPathEnabled && action.payload.calibrationAttributes) {
        const [firstAttribute] = action.payload.calibrationAttributes;
        state.calibrate.pathwiseCondition.calibrationBasis = firstAttribute;
      } else {
        state.calibrate.pathwiseCondition.calibrationBasis = null;
      }
    },
    setCalibratePathwiseCalibrationBasis(state, action: PayloadAction<string>) {
      state.calibrate.pathwiseCondition.calibrationBasis = action.payload;
    },
    initCalibrateAvgTotalConditions(state) {
      state.calibrate.avgTotalConditions = [
        {
          calibrationAttribute: null,
          average: null,
          total: null,
          type: AvgTotalCalibrationType.avg,
        },
      ];
    },
    resetCalibrateAvgTotalConditions(state) {
      state.calibrate.avgTotalConditions = [];
    },
    addEmptyCalibrateAvgTotalCondition(state) {
      state.calibrate.avgTotalConditions.push({
        calibrationAttribute: null,
        average: null,
        total: null,
        type: AvgTotalCalibrationType.avg,
      });
    },
    updateCalibrateAvgTotalCondition(
      state,
      action: PayloadAction<{
        index: number;
        update: Partial<NullableAvgTotalCalibrateCondition>;
      }>,
    ) {
      state.calibrate.avgTotalConditions =
        state.calibrate.avgTotalConditions.map((condition, index) => {
          if (index !== action.payload.index) {
            return condition;
          }

          let result = condition;

          if (action.payload.update.calibrationAttribute !== undefined) {
            result = {
              ...result,
              calibrationAttribute: action.payload.update.calibrationAttribute,
            };
          }

          if (action.payload.update.type !== undefined) {
            result = {
              ...result,
              type: action.payload.update.type,
            };
          }

          if (action.payload.update.average !== undefined) {
            result = {
              ...result,
              average: action.payload.update.average,
            };
          }

          if (action.payload.update.total !== undefined) {
            result = {
              ...result,
              total: action.payload.update.total,
            };
          }

          return result;
        });
    },
    removeCalibrateAvgTotalCondition(state, action: PayloadAction<number>) {
      state.calibrate.avgTotalConditions =
        state.calibrate.avgTotalConditions.filter(
          (_c, index) => index !== action.payload,
        );
    },
    initCalibrateMinMaxConditions(state) {
      state.calibrate.minMaxConditions = [
        {
          calibrationAttribute: null,
          min: null,
          minIncluded: false,
          max: null,
          maxIncluded: false,
        },
      ];
    },
    addEmptyCalibrateMinMaxConditions(state) {
      state.calibrate.minMaxConditions.push({
        calibrationAttribute: null,
        min: null,
        minIncluded: false,
        max: null,
        maxIncluded: false,
      });
    },
    updateCalibrateMinMaxCondition(
      state,
      action: PayloadAction<{
        index: number;
        update: Partial<NullableMinMaxCalibrateCondition>;
      }>,
    ) {
      state.calibrate.minMaxConditions = state.calibrate.minMaxConditions.map(
        (condition, index) => {
          if (index !== action.payload.index) {
            return condition;
          }

          let result = condition;

          if (action.payload.update.calibrationAttribute !== undefined) {
            result = {
              ...result,
              calibrationAttribute: action.payload.update.calibrationAttribute,
            };
          }

          if (action.payload.update.min !== undefined) {
            result = {
              ...result,
              min: action.payload.update.min,
            };
          }

          if (action.payload.update.max !== undefined) {
            result = {
              ...result,
              max: action.payload.update.max,
            };
          }

          if (action.payload.update.minIncluded !== undefined) {
            result = {
              ...result,
              minIncluded: action.payload.update.minIncluded,
            };
          }

          if (action.payload.update.maxIncluded !== undefined) {
            result = {
              ...result,
              maxIncluded: action.payload.update.maxIncluded,
            };
          }

          return result;
        },
      );
    },
    removeCalibrateMinMaxCondition(state, action: PayloadAction<number>) {
      state.calibrate.minMaxConditions =
        state.calibrate.minMaxConditions.filter(
          (_c, index) => index !== action.payload,
        );
    },
    changeCleaningType(state, action: PayloadAction<CleaningType>) {
      state.clean.type = action.payload;
      state.clean.minMaxConditions = [];
      state.clean.minMaxUSDAConditions = [];
    },
    setCleanTargetAttribute(state, action: PayloadAction<string>) {
      state.clean.targetAttribute = action.payload;
    },
    setCleanExcludedAttributes(state, action: PayloadAction<string[]>) {
      state.clean.excludedAttributes = action.payload;
    },
    initCleanMinMaxConditions(state) {
      state.clean.minMaxConditions = [
        {
          cleanAttribute: null,
          min: null,
          max: null,
        },
      ];
    },
    resetCleanMinMaxConditions(state) {
      state.clean.minMaxConditions = [];
    },
    addEmptyCleanMinMaxCondition(state) {
      state.clean.minMaxConditions.push({
        cleanAttribute: null,
        min: null,
        max: null,
      });
    },
    updateCleanMinMaxCondition(
      state,
      action: PayloadAction<{
        condition: Partial<NullableMinMaxCleanCondition>;
        index: number;
      }>,
    ) {
      if (action.payload.condition.cleanAttribute !== undefined) {
        state.clean.minMaxConditions[action.payload.index].cleanAttribute =
          action.payload.condition.cleanAttribute;
      }

      if (action.payload.condition.min !== undefined) {
        state.clean.minMaxConditions[action.payload.index].min =
          action.payload.condition.min;
      }

      if (action.payload.condition.max !== undefined) {
        state.clean.minMaxConditions[action.payload.index].max =
          action.payload.condition.max;
      }
    },
    removeCleanMinMaxCondition(state, action: PayloadAction<number>) {
      state.clean.minMaxConditions = state.clean.minMaxConditions.filter(
        (_c, index) => index !== action.payload,
      );
    },
    setCleanMinMaxUSDAConditions(
      state,
      action: PayloadAction<NullableMinMaxCleanUSDACondition[]>,
    ) {
      state.clean.minMaxUSDAConditions = action.payload;
    },
    updateCleanMinMaxUSDACondition(
      state,
      action: PayloadAction<{
        condition: Partial<NullableMinMaxCleanUSDACondition>;
        index: number;
      }>,
    ) {
      if (action.payload.condition.cleanAttribute !== undefined) {
        state.clean.minMaxUSDAConditions[action.payload.index].cleanAttribute =
          action.payload.condition.cleanAttribute;
      }

      if (action.payload.condition.min !== undefined) {
        state.clean.minMaxUSDAConditions[action.payload.index].min =
          action.payload.condition.min;
      }

      if (action.payload.condition.max !== undefined) {
        state.clean.minMaxUSDAConditions[action.payload.index].max =
          action.payload.condition.max;
      }

      if (action.payload.condition.stdNumber !== undefined) {
        state.clean.minMaxUSDAConditions[action.payload.index].stdNumber =
          action.payload.condition.stdNumber;
      }

      if (action.payload.condition.selected !== undefined) {
        state.clean.minMaxUSDAConditions[action.payload.index].selected =
          action.payload.condition.selected;
      }
    },
    resetCleanUSDAConditionStdNumber(state, action: PayloadAction<number>) {
      const { stdNumber, ...condition } =
        state.clean.minMaxUSDAConditions[action.payload];
      state.clean.minMaxUSDAConditions[action.payload] = condition;
    },
  },
});

export const {
  reset,
  resetUserInput,
  setDatasetUuid,
  setTab,
  setStep,
  setIsProcessing,
  changeSequence,
  changeCalibrationType,
  setCalibrateSmoothWindowSize,
  setCalibrateCalibrationAttributes,
  toggleCalibratePathwiseSyntheticMachinePath,
  setCalibratePathwiseCalibrationBasis,
  initCalibrateAvgTotalConditions,
  resetCalibrateAvgTotalConditions,
  addEmptyCalibrateAvgTotalCondition,
  updateCalibrateAvgTotalCondition,
  removeCalibrateAvgTotalCondition,
  initCalibrateMinMaxConditions,
  addEmptyCalibrateMinMaxConditions,
  updateCalibrateMinMaxCondition,
  removeCalibrateMinMaxCondition,
  changeCleaningType,
  setCleanTargetAttribute,
  setCleanExcludedAttributes,
  initCleanMinMaxConditions,
  resetCleanMinMaxConditions,
  addEmptyCleanMinMaxCondition,
  updateCleanMinMaxCondition,
  removeCleanMinMaxCondition,
  setCleanMinMaxUSDAConditions,
  updateCleanMinMaxUSDACondition,
  resetCleanUSDAConditionStdNumber,
} = cleanCalibrateSlice.actions;

export default cleanCalibrateSlice.reducer;
