subscription Subscription($userUuid: ID!) {
  followPlatformEvent(userUuid: $userUuid) {
    userUuid
    action
    nodePath {
      entity
      uuid
    }
  }
}
