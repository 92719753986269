import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
  isCleanedStatus,
  isInvalidStatus,
  isProcessedStatus,
} from '../../../../../../helpers/functions/entities/dataset';
import { getComponent } from '../../../../../../helpers/markup';
import type {
  DatasetStatus,
  TransformedDataset,
} from '../../../../../../helpers/types/dataset';
import GroupParentIcon from '../../Icons/groupParent.svg';
import CoveredAreaTooltip from '../../../../../../components/Tooltip/Tooltips/CoveredAreaTooltip';
import NumberTooltip from '../../../../../../components/Tooltip/Tooltips/NumberTooltip';
import DatasetItemCorrections from './Corrections';
import getFieldCoverageIcon from '../../../../../../helpers/functions/components/getFieldCoverageIcon';

import './index.scss';

const getStatusI18nKey = (status?: DatasetStatus) => {
  let message = 'field.not-processed-status';

  if (isInvalidStatus(status)) {
    message = 'field.invalid-status';
  } else if (isProcessedStatus(status)) {
    message = 'field.not-cleaned-status';
  } else if (isCleanedStatus(status)) {
    message = '';
  }

  return message;
};

export default function DatasetItem({
  children,
  dataset,
  isGroupParent,
}: {
  children?: ReactElement<Record<string, string>>;
  dataset: TransformedDataset;
  isGroupParent?: boolean;
}) {
  const { t } = useTranslation();

  const message = getStatusI18nKey(dataset.status);
  let AreaIcon = null;

  if (dataset.fieldCoverage) {
    AreaIcon = getFieldCoverageIcon(dataset.fieldCoverage);
  }

  return (
    <div className="dataset-item">
      <div className="dataset-item__first-line">
        {isGroupParent && (
          <GroupParentIcon className="dataset-item__group-parent-icon" />
        )}
        <span className="dataset-item__name">{dataset.name}</span>
        {message ? (
          <span className="dataset-item__message">{t(message)}</span>
        ) : (
          <div className="dataset-item__info">
            <NumberTooltip>
              <span className="dataset-item__number">{dataset.number}</span>
            </NumberTooltip>
            {AreaIcon && dataset.fieldCoverage != null && (
              <CoveredAreaTooltip fieldCoverage={dataset.fieldCoverage}>
                <AreaIcon className="dataset-item__icon" />
              </CoveredAreaTooltip>
            )}
          </div>
        )}
        {getComponent('actions', children || [])}
      </div>
      {!!dataset.appliedCorrections?.length &&
        dataset.appliedCorrectionsVersion != null && (
          <div className="dataset-item__second-line">
            <DatasetItemCorrections
              corrections={dataset.appliedCorrections}
              version={dataset.appliedCorrectionsVersion}
            />
          </div>
        )}
    </div>
  );
}
