import { RootState } from '../../../app/store/helpers/types';

export const selectFilter = ({ userActivityLog }: RootState) =>
  userActivityLog.filter;

export const selectIsLoaded = ({ userActivityLog }: RootState) =>
  userActivityLog.isLoaded;

export const selectActionsData = ({ userActivityLog }: RootState) =>
  userActivityLog.actionsData;
