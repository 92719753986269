import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import clsx from 'clsx';

import type { AttributeStatistics } from '../../../../../helpers/types/dataset';
import type {
  FullAttributeItem,
  NullableMinMaxCondition,
} from '../../types/ui';
import TextField from '../../../../../components/TextField';
import ComboBox from '../../../../../components/ComboBox';
import Checkbox from '../../../../../components/Checkbox';
import { convertNumberToFormattedString } from '../../../../../helpers/markup';
import { getComboBoxOptionSelected } from '../../helpers/functions/ui';

import './index.scss';

const getMinValueLimits = (
  condition: NullableMinMaxCondition,
  attributeStatistics?: AttributeStatistics,
) => {
  const result: {
    min: number | null;
    max: number | null;
  } = {
    min: null,
    max: null,
  };

  if (attributeStatistics?.min != null) {
    result.min = attributeStatistics.min;
  }

  if (condition.max != null) {
    result.max = condition.max;
  } else if (attributeStatistics?.max != null) {
    result.max = attributeStatistics.max;
  }

  return result;
};

const getMaxValueLimits = (
  condition: NullableMinMaxCondition,
  attributeStatistics?: AttributeStatistics,
) => {
  const result: {
    min: number | null;
    max: number | null;
  } = {
    min: null,
    max: null,
  };

  if (attributeStatistics?.max != null) {
    result.max = attributeStatistics.max;
  }

  if (condition.min != null) {
    result.min = condition.min;
  } else if (attributeStatistics?.min != null) {
    result.min = attributeStatistics.min;
  }

  return result;
};

export default function MinMaxConditionsBaseItem({
  condition,
  attributesItems,
  attributeStatistics,
  conditionMinInfoTooltip,
  conditionMaxInfoTooltip,
  disabled = false,
  title,
  classes,
  onConditionAttributeChange,
  onConditionMinChange,
  onConditionMinIncludedChange,
  onConditionMaxChange,
  onConditionMaxIncludedChange,
  getConditionAttributeOptionDisabled,
}: {
  condition: NullableMinMaxCondition;
  attributesItems: FullAttributeItem[];
  attributeStatistics?: AttributeStatistics;
  conditionMinInfoTooltip?: string;
  conditionMaxInfoTooltip?: string;
  title: string;
  disabled?: boolean;
  classes?: {
    label?: string;
  };
  onConditionAttributeChange: (i: FullAttributeItem) => void;
  onConditionMinChange: (i: string) => void;
  onConditionMinIncludedChange?: (i: boolean) => void;
  onConditionMaxChange: (i: string) => void;
  onConditionMaxIncludedChange?: (i: boolean) => void;
  getConditionAttributeOptionDisabled: (
    attribute: FullAttributeItem,
    selectedAttribute?: FullAttributeItem,
  ) => boolean;
}) {
  const { t } = useTranslation();

  const conditionAttributeItem = attributesItems.find(
    (attributeItem) => attributeItem.value === condition.attribute,
  );

  const { min: minValueMinLimit, max: minValueMaxLimit } = getMinValueLimits(
    condition,
    attributeStatistics,
  );
  const { min: maxValueMinLimit, max: maxValueMaxLimit } = getMaxValueLimits(
    condition,
    attributeStatistics,
  );

  const isValueInvalid = (
    minLimit: number | null,
    maxLimit: number | null,
    value?: number | null,
  ) => {
    const parsedValue = parseFloat(value != null ? value.toString() : '');
    const parsedMinLimit = parseFloat(
      minLimit != null ? minLimit.toString() : '',
    );
    const parsedMaxLimit = parseFloat(
      maxLimit != null ? maxLimit.toString() : '',
    );

    return parsedMinLimit > parsedValue || parsedValue > parsedMaxLimit;
  };

  return (
    <div className="min-max-conditions-base-item">
      <ComboBox
        classes={{
          root: 'min-max-conditions-base-item__attribute-selector',
          label: classes?.label,
        }}
        title={title}
        disabled={disabled}
        disableClearable
        placeholder={t('general.controls.select')}
        options={attributesItems}
        value={conditionAttributeItem || (null as unknown as FullAttributeItem)}
        getOptionSelected={getComboBoxOptionSelected}
        getOptionDisabled={getConditionAttributeOptionDisabled}
        disableCloseOnSelect={false}
        onChange={(_e, item) => onConditionAttributeChange(item)}
      />
      <div className="min-max-conditions-base-item__value-controls">
        <TextField
          classes={{
            title: clsx(
              'min-max-conditions-base-item__value-input-label',
              classes?.label,
            ),
          }}
          type="number"
          title={t('general.controls.map-legend.statistics.min')}
          tooltip={conditionMinInfoTooltip}
          value={condition.min != null ? condition.min : ''}
          disabled={disabled || !attributeStatistics}
          helperText={
            <>
              <span className="min-max-conditions-base-item__value-input-helper-text-line">
                {minValueMinLimit != null
                  ? t('clean-calibrate.yield-popup.min-value', {
                      value: convertNumberToFormattedString(
                        minValueMinLimit.toString(),
                      ),
                    })
                  : ''}
              </span>
              <span className="min-max-conditions-base-item__value-input-helper-text-line">
                {minValueMaxLimit != null
                  ? t('clean-calibrate.yield-popup.max-value', {
                      value: convertNumberToFormattedString(
                        minValueMaxLimit.toString(),
                      ),
                    })
                  : ''}
              </span>
            </>
          }
          error={isValueInvalid(
            minValueMinLimit,
            minValueMaxLimit,
            condition.min,
          )}
          onChange={(e: ChangeEvent<{ value: string }>) =>
            onConditionMinChange(e.target.value)
          }
        />
        {onConditionMinIncludedChange && (
          <FormControlLabel
            className="min-max-conditions-base-item__value-checkbox"
            disabled={disabled || !attributeStatistics}
            label={t(
              'clean-calibrate.yield-popup.tabs.calibrate.configure.min-max-conditions.included-min',
            )}
            control={
              <Checkbox
                value={condition.minIncluded ? 2 : 0}
                name={t(
                  'clean-calibrate.yield-popup.tabs.calibrate.configure.min-max-conditions.included-max',
                )}
                onChange={(_e: unknown, value: boolean) =>
                  onConditionMinIncludedChange(value)
                }
              />
            }
          />
        )}
      </div>
      <div className="min-max-conditions-base-item__value-controls">
        <TextField
          classes={{
            title: clsx(
              'min-max-conditions-base-item__value-input-label',
              classes?.label,
            ),
          }}
          type="number"
          title={t('general.controls.map-legend.statistics.max')}
          tooltip={conditionMaxInfoTooltip}
          value={condition.max != null ? condition.max : ''}
          disabled={disabled || !attributeStatistics}
          helperText={
            <>
              <span className="min-max-conditions-base-item__value-input-helper-text-line">
                {maxValueMinLimit != null
                  ? t('clean-calibrate.yield-popup.min-value', {
                      value: convertNumberToFormattedString(
                        maxValueMinLimit.toString(),
                      ),
                    })
                  : ''}
              </span>
              <span className="min-max-conditions-base-item__value-input-helper-text-line">
                {maxValueMaxLimit != null
                  ? t('clean-calibrate.yield-popup.max-value', {
                      value: convertNumberToFormattedString(
                        maxValueMaxLimit.toString(),
                      ),
                    })
                  : ''}
              </span>
            </>
          }
          error={isValueInvalid(
            maxValueMinLimit,
            maxValueMaxLimit,
            condition.max,
          )}
          onChange={(e: ChangeEvent<{ value: string }>) =>
            onConditionMaxChange(e.target.value)
          }
        />
        {onConditionMaxIncludedChange && (
          <FormControlLabel
            className="min-max-conditions-base-item__value-checkbox"
            disabled={disabled || !attributeStatistics}
            label={t(
              'clean-calibrate.yield-popup.tabs.calibrate.configure.min-max-conditions.included-max',
            )}
            control={
              <Checkbox
                value={condition.maxIncluded ? 2 : 0}
                name={t(
                  'clean-calibrate.yield-popup.tabs.calibrate.configure.min-max-conditions.included-max',
                )}
                onChange={(_e: unknown, value: boolean) =>
                  onConditionMaxIncludedChange(value)
                }
              />
            }
          />
        )}
      </div>
    </div>
  );
}
