import TreeNodeEntity from '../../../../../helpers/constants/entities/treeNodeEntity';
import { AssetGroupType } from '../../../../../helpers/constants/entities/asset';
import {
  getFieldAssetItemLink,
  getRootLink,
  PAGES_ROOTS,
} from '../../../../../helpers/navigation';
import type { SubOperation } from '../../types/operation';
import { OperationType } from '../constants/operation';

export const getOperationTypeLink = (
  operationType: OperationType,
  search = '',
) => `${getRootLink(PAGES_ROOTS.operations)}/${operationType}${search}`;

const ENTITY_TO_ASSET_GROUP = {
  [TreeNodeEntity.fieldSatelliteImage]: AssetGroupType.satelliteImages,
  [TreeNodeEntity.vectorAnalysisMap]: AssetGroupType.vectorAnalysisMaps,
  [TreeNodeEntity.soilDataset]: AssetGroupType.soilDatasets,
  [TreeNodeEntity.yieldDataset]: AssetGroupType.yieldDatasets,
  [TreeNodeEntity.asAppliedDataset]: AssetGroupType.asAppliedDatasets,
  [TreeNodeEntity.elevation]: AssetGroupType.topographyMaps,
  [TreeNodeEntity.equationMap]: AssetGroupType.equationMaps,
};

const ENTITY_TO_I18N_KEY = {
  [TreeNodeEntity.fieldSatelliteImage]:
    'operations.operation-item.assets-links.satellite-image',
  [TreeNodeEntity.vectorAnalysisMap]:
    'operations.operation-item.assets-links.vector-analysis-map',
  [TreeNodeEntity.soilDataset]:
    'operations.operation-item.assets-links.soil-dataset',
  [TreeNodeEntity.yieldDataset]:
    'operations.operation-item.assets-links.yield-dataset',
  [TreeNodeEntity.asAppliedDataset]:
    'operations.operation-item.assets-links.as-applied-dataset',
  [TreeNodeEntity.elevation]:
    'operations.operation-item.assets-links.elevation',
  [TreeNodeEntity.equationMap]:
    'operations.operation-item.assets-links.equation-map',
};

export const getEntityLink = (subOperation: SubOperation) => ({
  url: getFieldAssetItemLink(
    subOperation.farmUuid,
    subOperation.fieldUuid,
    ENTITY_TO_ASSET_GROUP[
      subOperation.assetType as keyof typeof ENTITY_TO_ASSET_GROUP
    ],
    subOperation.assetUuid,
  ),
  i18nKey:
    ENTITY_TO_I18N_KEY[
      subOperation.assetType as keyof typeof ENTITY_TO_ASSET_GROUP
    ],
});
