import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import VectorAnalysisMap from '../../../../../components/Views/VectorAnalysisMap';
import FieldBoundary from '../../../../../components/Views/FieldBoundary';
import PinsView from '../../../../../components/Views/PinsView';
import ExportDetails from '../../../../../components/Views/common/ExportDetails';
import ZonesMapTable from '../../../../../components/Views/common/ZonesMapTable';
import ScrollContainer from '../../../../../components/ScrollContainer';
import { isField } from '../../../../../helpers/functions/entities/assets';
import {
  exportAsset,
  fetchZonesMapAttributesJson,
  openExportZonesMapPopup,
  setupLegend,
} from '../../../../exportData/exportDataSlice';
import { unselectAsset } from '../../downloadFilesSlice';
import { EXPORT_ASSET_TYPE } from '../../helpers/constants/assets';
import {
  getSelectedAssets,
  prepareSelectedAssets,
} from '../../helpers/functions/assets';
import {
  selectFarmsEntities,
  selectFieldsEntities,
  selectPinsGroupsEntities,
  selectVectorAnalysisMapsEntities,
} from '../../../../exportData/exportDataSelectors';
import { selectSelectedAssets } from '../../downloadFilesSelectors';
import { selectAreaUnit, selectApiKey } from '../../../../user/userSelectors';

import './index.scss';

const ZonesMapTableWithRates = (props) => (
  <ZonesMapTable includeRates {...props} />
);

const DownloadFilesView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const areaUnit = useSelector(selectAreaUnit);
  const apiKey = useSelector(selectApiKey);
  const selectedAssetsUuids = useSelector(selectSelectedAssets);
  const farmsEntities = useSelector(selectFarmsEntities);
  const fieldsEntities = useSelector(selectFieldsEntities);
  const vectorAnalysisMapsEntities = useSelector(
    selectVectorAnalysisMapsEntities,
  );
  const pinsGroupsEntities = useSelector(selectPinsGroupsEntities);
  const selectedAssets = getSelectedAssets(selectedAssetsUuids, {
    fieldsEntities,
    vectorAnalysisMapsEntities,
    pinsGroupsEntities,
  });

  const handleVamapFetchAttributes = ({ farmUuid, fieldUuid, uuid }) => {
    dispatch(
      fetchZonesMapAttributesJson({
        farmUuid,
        fieldUuid,
        uuid,
      }),
    );
  };

  const handleAssetCardClose = ({ uuid, type }) => {
    dispatch(
      unselectAsset({
        uuid,
        assetType: type,
      }),
    );
  };

  const onAssetExportClick = (asset) => {
    const { vectorAnalysisMaps, fieldBoundaries, pinsGroups } =
      prepareSelectedAssets([asset]);

    if (vectorAnalysisMaps) {
      dispatch(
        openExportZonesMapPopup({
          zonesMaps: vectorAnalysisMaps,
        }),
      );
    } else {
      dispatch(
        exportAsset({
          pinsGroups,
          fieldBoundaries,
        }),
      );
    }
  };

  const handleGetLegend = (asset) => {
    dispatch(
      setupLegend({
        farmUuid: asset.farmUuid,
        fieldUuid: isField(asset) ? asset.uuid : asset.fieldUuid,
        uuid: asset.uuid,
        type: asset.assetType,
      }),
    );
  };

  return (
    <ScrollContainer classes={{ root: 'export-view' }}>
      {selectedAssets.length === 0 ? (
        <div className="empty-message">{t('export.explanation')}</div>
      ) : (
        selectedAssets.map((asset) => {
          let Comp = null;
          const farmName = farmsEntities[asset.value.farmUuid].name;
          const fieldName = fieldsEntities[asset.value.fieldUuid]?.name;

          if (asset.type === EXPORT_ASSET_TYPE.zonesMap) {
            Comp = (
              <VectorAnalysisMap
                key={asset.value.uuid}
                details={
                  <ExportDetails
                    farmName={farmName}
                    fieldName={fieldName}
                    area={asset.value.area}
                    areaUnit={areaUnit}
                    exportButtonDisabled={!asset.value.attributes}
                    onExportClick={() => onAssetExportClick(asset)}
                  />
                }
                TableComponent={ZonesMapTableWithRates}
                apiKey={apiKey}
                areaUnit={areaUnit}
                vamap={asset.value}
                onFetchAttributes={() =>
                  handleVamapFetchAttributes(asset.value)
                }
                onClose={() => handleAssetCardClose(asset)}
                onGetDetails={() => handleGetLegend(asset.value)}
              />
            );
          } else if (asset.type === EXPORT_ASSET_TYPE.pinsGroup) {
            Comp = (
              <PinsView
                key={`${asset.uuid}-pinsGroup`}
                pinsGroup={asset.value}
                details={
                  <ExportDetails
                    farmName={farmName}
                    fieldName={fieldName}
                    onExportClick={() => onAssetExportClick(asset)}
                  />
                }
                onClose={() => handleAssetCardClose(asset)}
              />
            );
          } else if (asset.type === EXPORT_ASSET_TYPE.fieldBoundary) {
            Comp = (
              <FieldBoundary
                key={asset.uuid}
                apiKey={apiKey}
                field={asset.value}
                details={
                  <ExportDetails
                    farmName={farmName}
                    fieldName={asset.value.name}
                    area={asset.value.area}
                    areaUnit={areaUnit}
                    onExportClick={() => onAssetExportClick(asset)}
                  />
                }
                onClose={() => handleAssetCardClose(asset)}
                onGetDetails={() => handleGetLegend(asset.value)}
              />
            );
          }

          return Comp;
        })
      )}
    </ScrollContainer>
  );
};

export default DownloadFilesView;
