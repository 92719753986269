query getHomepageData {
  getZonesMaps(filter: { pageSize: 5 }) {
    zonesMaps {
      uuid
      fieldUuid
      name
      updatedDate
      geoMaps {
        hexColorCodes
        shortName
        url
        classes
      }
    }
  }
}
