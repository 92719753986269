import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import { toggleEditMode } from '../../fieldProfilerSlice';
import Button from '../../../../../components/Button';
import ToolsPanel from '../../../../../components/ToolsPanel';
import Tabs from '../../../../../components/Tabs';
import FieldsListFilter from '../../../fieldsList/containers/FieldsListFilter';
import { getViewType, isMapView } from '../../helpers/functions/view';
import { FieldsListMode, MapView } from '../../helpers/constants/view';
import {
  PAGES_ROOTS,
  getRootLink,
  updateSearchParam,
} from '../../../../../helpers/navigation';
import { openPopup } from '../../../popups/popupsSlice';
import { selectEditMode } from '../../fieldProfilerSelectors';
import POPUPS from '../../../popups/helpers/constants/popups';
import AmplitudeAnalytics from '../../../../../helpers/classes/amplitudeAnalytics';

import './index.scss';

const getTabs = (t) => [
  {
    label: {
      primary: t('field-profiler.tools-panel.map-view'),
    },
    value: MapView.map,
  },
  {
    label: {
      primary: t('field-profiler.tools-panel.table-view'),
    },
    value: MapView.table,
  },
];

const Panel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const editMode = useSelector(selectEditMode);
  const viewType = getViewType(location.search);
  const isMap = isMapView(viewType);

  const handleEditFieldsClick = () => {
    const newMode = editMode
      ? FieldsListMode.defaultMode
      : FieldsListMode.editMode;

    AmplitudeAnalytics.trackFieldsListModeSet({
      mode: newMode,
    });
    dispatch(toggleEditMode());
  };

  const handleCreateFarmClick = () => {
    dispatch(
      openPopup({
        type: 'create-farm',
      }),
    );
  };

  const handleTabChange = (_event, newValue) => {
    updateSearchParam(location, history, [{ name: 'view', value: newValue }]);

    AmplitudeAnalytics.trackFieldsListViewSet({
      view: newValue,
    });
  };

  const handleCreateFieldClick = () => {
    dispatch(
      openPopup({
        type: POPUPS.createFieldPopup,
      }),
    );
  };

  return (
    <ToolsPanel
      data-test="tools-panel"
      classes={{
        root: 'field-profiler-tools-panel',
        filtersRight: 'field-profiler-tools-panel__filters-right',
      }}
      actionsLeft={
        <Fragment>
          <Button
            to={getRootLink(PAGES_ROOTS.createField)}
            startIcon={<AddIcon />}
            onClick={handleCreateFieldClick}
          >
            {t('field-profiler.tools-panel.create-field')}
          </Button>
          <Button startIcon={<AddIcon />} onClick={handleCreateFarmClick}>
            {t('field-profiler.tools-panel.create-farm')}
          </Button>
          {!isMap && (
            <Button
              startIcon={<EditIcon />}
              pressed={editMode}
              onClick={handleEditFieldsClick}
            >
              {t('field-profiler.tools-panel.edit-fields')}
            </Button>
          )}
        </Fragment>
      }
      filtersLeft={
        isMap ? (
          <></>
        ) : (
          <FieldsListFilter
            withLabelsExpandSwitcher={!isMap}
            withBookmarkableUrl
            withCreateFarm
          />
        )
      }
      filtersRight={
        <Tabs
          value={viewType}
          tabs={getTabs(t)}
          onTabChange={handleTabChange}
        />
      }
    />
  );
};

export default Panel;
