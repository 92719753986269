import React from 'react';
import { useTranslation } from 'react-i18next';
import TableCell from '@material-ui/core/TableCell';

import getCellClasses from '../../../helpers/functions/getCellClasses';

import './index.scss';

const ImageCell = ({ row = {}, headCell = {} }) => {
  const { t } = useTranslation();

  return (
    <TableCell
      align={headCell.horizontalAlign || 'left'}
      className={getCellClasses('image-cell', headCell, row)}
    >
      <img
        src={row[headCell.id]}
        alt={t('general.shared.preview')}
        className="image-cell__preview"
      />
    </TableCell>
  );
};

export default ImageCell;
