mutation saveNewVectorAnalysisMap(
  $input: SaveVectorAnalysisMapInput!
  $areaUnit: AreaUnit
) {
  saveVectorAnalysisMap(input: $input) {
    uuid
    fieldUuid
    name
    type
    analysisType
    area(unit: $areaUnit)
    createdDate
    status
    attributesJson(unit: $areaUnit)
    satelliteImages {
      satelliteImage {
        uuid
      }
    }
    soilDataset {
      uuid
    }
    yieldDataset {
      uuid
    }
    asAppliedDataset {
      uuid
    }
    topographyMap {
      uuid
    }
    dataLayers {
      factor
      index
      satelliteImages {
        satelliteImage {
          uuid
          acquisitionDate
        }
      }
      soilDataset {
        uuid
      }
      soilAttribute
      yieldDataset {
        uuid
      }
      yieldAttribute
      asAppliedDataset {
        uuid
      }
      asAppliedAttribute
      topographyMap {
        uuid
      }
      topographyAttribute
    }
    zonesOperationMaps {
      map {
        uuid
      }
      zoneNumbers
    }
    parameters {
      index
      numberOfZones
      soilAttribute
      yieldAttribute
      asAppliedAttribute
      topographyAttribute
      zonesOperation
      polygonMinArea
      dataClassificationType
    }
    variabilityFactor
  }
}
