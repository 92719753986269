import React, { useMemo } from 'react';

import { DataLayersListNode, Mode } from '../../../../types/dataLayersList';
import CheckboxItem from '../../../ListView/CheckboxItem';
import SatelliteItem from '../../../Items/SatelliteItem';
import { TransformedSatelliteImage } from '../../../../../../satelliteImages/types/satelliteImage';
import SimpleItem from '../../../ListView/SimpleItem';

interface BaseProps {
  listNode: DataLayersListNode;
  image: TransformedSatelliteImage;
  selectedItemUuid: string;
  mode: Mode;
  onClick: () => void;
}

interface DefaultProps extends BaseProps {
  mode: 'default';
}

interface MultiSelectProps extends BaseProps {
  mode: 'multiSelect';
  checkedItemUuids: string[];
  onCheckboxClick: (v: boolean, item: DataLayersListNode) => void;
}

type SatelliteImageNodeProps = DefaultProps | MultiSelectProps;

export default function SatelliteImageNode(props: SatelliteImageNodeProps) {
  const { listNode, image, selectedItemUuid, mode, onClick } = props;
  const multiSelectMode = mode === 'multiSelect';
  let result;
  const item = <SatelliteItem image={image} />;
  const isItemSelected = listNode.uuid === selectedItemUuid;
  const isItemChecked = useMemo(() => {
    if (!multiSelectMode) {
      return false;
    }

    return props.checkedItemUuids.includes(listNode.uuid);
  }, [props, listNode.uuid, multiSelectMode]);

  if (mode === 'multiSelect') {
    result = (
      <CheckboxItem
        itemNode={listNode}
        isSelected={isItemSelected}
        isChecked={isItemChecked}
        onClick={onClick}
        onCheckboxClick={props.onCheckboxClick}
      >
        {item}
      </CheckboxItem>
    );
  } else {
    result = (
      <SimpleItem isSelected={isItemSelected} onClick={onClick}>
        {item}
      </SimpleItem>
    );
  }

  return result;
}
