import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import ScrollContainer from '../../../../../../../components/ScrollContainer';
import Stepper from '../../../../../../../components/Stepper';
import ZonesMapItem from '../../../../../dataLayersView/components/Items/ZonesMapItem';
import Tree from '../../../../../../../components/Tree';
import VectorAnalysisMap from '../../../../../../../components/Views/VectorAnalysisMap';
import ExportDetails from '../../../../../../../components/Views/common/ExportDetails';
import BackdropLoading from '../../../../../../../components/Loading/BackdropLoading';
import ToolsPanel from '../../../ExportDataToolsPanel';
import ZonesMapTable from '../../../../../../../components/Views/common/ZonesMapTable';
import {
  setStepExportToJohnDeere,
  exportToJohnDeereAsFiles,
  openLegendExportToJohnDeere,
  fetchMoreZonesMapsExportFlow,
  toggleZoneMapSelectionExportToJohnDeere,
} from '../../../../jdExportSlice';
import {
  selectExportZonesMaps,
  selectExportSelectedZonesMaps,
  selectLastEvaluatedKey,
  selectExportIsExecutingFetchingZonesMaps,
  selectExportIsExecutingExport,
  selectExportSelectedOrganization,
} from '../../../../jdExportSelectors';
import {
  selectAreaUnit,
  selectApiKey,
} from '../../../../../../user/userSelectors';
import AmplitudeAnalytics from '../../../../../../../helpers/classes/amplitudeAnalytics';
import getSteps from '../getSteps';
import { PAGES_ROOTS } from '../../../../../../../helpers/navigation';

import './index.scss';

const ZonesMapTableWithRates = (props) => (
  <ZonesMapTable includeRates {...props} />
);

const StepperSelectZonesMaps = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentOrganization = useSelector(selectExportSelectedOrganization);
  const selectedZonesMaps = useSelector(selectExportSelectedZonesMaps);
  const selectedZonesMapsUuids = selectedZonesMaps.map(
    (zoneMap) => zoneMap.uuid,
  );
  const zonesMaps = useSelector(selectExportZonesMaps).map((zonesMap) => ({
    hasCheckbox: true,
    content: <ZonesMapItem zonesMap={zonesMap} />,
    uuid: zonesMap.uuid,
    fieldUuid: zonesMap.fieldUuid,
    value: selectedZonesMapsUuids.includes(zonesMap.uuid) ? 2 : 0,
  }));
  const selectedZonesMapsCount = selectedZonesMaps.length;
  const hasMoreZonesMaps = !!useSelector(selectLastEvaluatedKey);
  const isExecutingFetchingZonesMaps = useSelector(
    selectExportIsExecutingFetchingZonesMaps,
  );
  const isExecutingExport = useSelector(selectExportIsExecutingExport);
  const nextDisabled = selectedZonesMapsCount === 0 || isExecutingExport;
  const areaUnit = useSelector(selectAreaUnit);
  const apiKey = useSelector(selectApiKey);
  const steps = getSteps(currentOrganization.name);

  const onExportClick = (selectedZoneMap) => {
    dispatch(
      exportToJohnDeereAsFiles({
        orgId: currentOrganization.id,
        vectorAnalysisMaps: [
          {
            uuid: selectedZoneMap.uuid,
            name: selectedZoneMap.name,
            fieldUuid: selectedZoneMap.fieldUuid,
          },
        ],
      }),
    );

    AmplitudeAnalytics.trackEntityExportedToJD({
      page: PAGES_ROOTS.exportDataJohnDeere,
      entityType: 'zonesMap',
      exportType: 'asFiles',
    });
  };

  const onExportAllClick = () => {
    dispatch(
      exportToJohnDeereAsFiles({
        orgId: currentOrganization.id,
        vectorAnalysisMaps: selectedZonesMaps.map((selectedZoneMap) => ({
          uuid: selectedZoneMap.uuid,
          name: selectedZoneMap.name,
          fieldUuid: selectedZoneMap.fieldUuid,
        })),
      }),
    );

    AmplitudeAnalytics.trackBatchEntitiesExportedToJD({
      page: PAGES_ROOTS.exportDataJohnDeere,
      entityType: 'zonesMap',
      exportType: 'asFiles',
      entitiesAmount: selectedZonesMaps.length,
    });
  };

  const onClickBack = () => {
    dispatch(setStepExportToJohnDeere({ step: 'selectOrganization' }));
  };

  const onScroll = (e) => {
    const isBottomScroll =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (isBottomScroll && hasMoreZonesMaps && !isExecutingFetchingZonesMaps) {
      dispatch(fetchMoreZonesMapsExportFlow());
    }
  };

  const onRowCheckboxClick = (id) => {
    dispatch(toggleZoneMapSelectionExportToJohnDeere(id));
  };

  const onLegendClick = (id) => {
    dispatch(openLegendExportToJohnDeere(id));
  };

  return (
    <Stepper
      activeStep={1}
      steps={steps}
      backDisabled={isExecutingExport}
      nextDisabled={nextDisabled}
      nextLabel={
        selectedZonesMapsCount === 0
          ? t('export-data-john-deere.steps.selectZonesMaps.export')
          : t('export-data-john-deere.steps.selectZonesMaps.export-all', {
              count: selectedZonesMapsCount,
            })
      }
      onClickNext={onExportAllClick}
      onClickBack={onClickBack}
    >
      <ToolsPanel key="tools-panel" />
      <div className="select-zones-maps-panel" key="panel">
        <ScrollContainer
          classes={{ root: 'select-zones-maps-panel__list' }}
          onScroll={onScroll}
        >
          <Tree
            items={zonesMaps}
            onClick={(zonesMap) => onRowCheckboxClick(zonesMap.uuid)}
          />
          {zonesMaps.length === 0 && !isExecutingFetchingZonesMaps && (
            <li
              key="noResultsZonesMaps"
              className="select-zones-maps-panel__list-progress"
            >
              {t('general.controls.no-results')}
            </li>
          )}
          {(hasMoreZonesMaps || isExecutingFetchingZonesMaps) && (
            <li
              key="hasMoreZonesMaps"
              className="select-zones-maps-panel__list-progress"
            >
              <CircularProgress size={30} />
            </li>
          )}
        </ScrollContainer>
        <ScrollContainer
          classes={{ root: 'select-zones-maps-panel__list-selected' }}
        >
          {selectedZonesMapsCount === 0 && (
            <span className="select-zones-maps-panel__list-empty">
              {t('export-data-john-deere.steps.selectZonesMaps.no-zones-maps')}
            </span>
          )}
          {selectedZonesMapsCount !== 0 &&
            selectedZonesMaps.map((selectedZoneMap) => (
              <VectorAnalysisMap
                key={selectedZoneMap.uuid}
                details={
                  <ExportDetails
                    farmName={selectedZoneMap.farmName}
                    fieldName={selectedZoneMap.fieldName}
                    area={selectedZoneMap.area}
                    areaUnit={areaUnit}
                    exportButtonLabel={t('export.export')}
                    onExportClick={() => onExportClick(selectedZoneMap)}
                  />
                }
                TableComponent={ZonesMapTableWithRates}
                apiKey={apiKey}
                areaUnit={areaUnit}
                vamap={selectedZoneMap}
                onClose={onRowCheckboxClick}
                onGetDetails={() => onLegendClick(selectedZoneMap.uuid)}
              />
            ))}
        </ScrollContainer>
        {isExecutingExport && <BackdropLoading />}
      </div>
    </Stepper>
  );
};

export default StepperSelectZonesMaps;
