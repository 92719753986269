import React, { useMemo, useState, ChangeEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import LanguageIcon from '@material-ui/icons/Language';
import WorkIcon from '@material-ui/icons/Work';
import PhoneAndroidSharpIcon from '@material-ui/icons/PhoneAndroidSharp';
import AccountIcon from '@material-ui/icons/AccountCircle';
import EmailSharpIcon from '@material-ui/icons/EmailSharp';
import clsx from 'clsx';

import Button from '../../../../../components/Button';
import TextField from '../../../../../components/TextField';
import type {
  AdditionalUserDataFields,
  AdditionalUserDataField,
} from '../../types/ui';
import ComboBox, { Option } from '../../../../../components/ComboBox';
import { getCompanyTypeOptions } from '../../helpers/constants/companyType';
import getCountryHelperTexts from '../../helpers/functions/getCountryHelperTexts';
import {
  getMultilineHelperText,
  getNotReachableEmailProvider,
  hasErrors,
  isNotReachableEmail,
} from '../../helpers/functions/authentication';
import { PROVIDER_TO_I18N_KEY_MAP } from '../../helpers/constants/authentication';
import { HelperTextType } from '../../helpers/constants/helperTexts';
import Checkbox from '../../../../../components/Checkbox';
import {
  getBrandName,
  getDemoFormLink,
  getPolicyLink,
  getTermsLink,
} from '../../../../../helpers/functions/utils/appConfig';
import { USER_DATA_ERRORS } from '../../helpers/constants/errors';
import { Country } from '../../../../countries/types/country';
import AmplitudeAnalytics from '../../../../../helpers/classes/amplitudeAnalytics';
import getDebouncer from '../../../../../helpers/functions/utils/getDebouncer';

import '../common/index.scss';
import './index.scss';

type CountryOption = Option<string>;

const formHelperInitialTexts = {
  country: [],
  companyType: [],
  acceptedTermsAndConditions: [],
  phoneNumber: [],
  email: [],
};

const countriesCompareFn = (
  countryA: CountryOption,
  countryB: CountryOption,
) => {
  if (countryA.title < countryB.title) {
    return -1;
  }
  if (countryA.title > countryB.title) {
    return 1;
  }
  return 0;
};

const amplitudeDebouncer = getDebouncer(1000);

const AdditionalUserDataForm = ({
  userData,
  countries,
  onSave,
  onSaveUnknownError,
  onLogOutClick,
}: {
  userData: AdditionalUserDataFields;
  countries: Country[];
  onSave: (formState: AdditionalUserDataFields) => Promise<Error[] | null>;
  onSaveUnknownError: (error: Error[]) => void;
  onLogOutClick: () => void;
}) => {
  const { t } = useTranslation();

  const [formState, setFormState] = useState({
    ...userData,
    email: isNotReachableEmail(userData.email) ? '' : userData.email,
  });
  const [formHelperTexts, setFormHelperTexts] = useState(
    formHelperInitialTexts,
  );

  const countryOptions = useMemo(
    () =>
      countries
        .map((country) => ({
          value: country.alpha3,
          title: country.name,
        }))
        .sort(countriesCompareFn),
    [countries],
  );

  const freeTrialCounties = useMemo(
    () =>
      countries.reduce<Record<string, boolean>>((acc, country) => {
        acc[country.alpha3] = country.trialAvailable;
        return acc;
      }, {}),
    [countries],
  );

  const phonePlaceholders = useMemo(
    () =>
      countries.reduce<Record<string, string>>((acc, country) => {
        acc[country.alpha3] = country.phoneExample;
        return acc;
      }, {}),
    [countries],
  );

  const selectedCountryOption =
    useMemo(
      () => countryOptions.find(({ value }) => value === formState.country),
      [countryOptions, formState.country],
    ) ?? null;

  const companyTypeOptions = useMemo(() => getCompanyTypeOptions(), []);

  const selectedCompanyTypeOption = useMemo(
    () =>
      companyTypeOptions.find(({ value }) => value === formState.companyType) ??
      null,
    [companyTypeOptions, formState.companyType],
  );

  const proceedButtonDisabled = useMemo(() => {
    const hasRequiredFields = Object.values(formState).every(
      (value) => !!value,
    );

    return !hasRequiredFields || hasErrors(formHelperTexts);
  }, [formHelperTexts, formState]);

  const brandName = getBrandName();

  const phonePlaceholder = formState.country
    ? phonePlaceholders[formState.country]
    : t('authentication.additional-user-data.fields.phone-number');

  const handleFieldChange = (
    field: AdditionalUserDataField,
    value: AdditionalUserDataFields[AdditionalUserDataField],
  ) => {
    const fieldHelperTexts =
      field === 'country'
        ? getCountryHelperTexts(freeTrialCounties, value as string)
        : [];

    setFormState((prevFormState) => ({
      ...prevFormState,
      [field]: value,
    }));
    setFormHelperTexts((prevFormErrors) => ({
      ...prevFormErrors,
      [field]: fieldHelperTexts,
    }));

    amplitudeDebouncer(() => {
      AmplitudeAnalytics.trackAdditionalUserDataChanged({ field });
    });
  };

  const handleProceedClick = async () => {
    setFormHelperTexts(formHelperInitialTexts);
    AmplitudeAnalytics.trackAdditionalUserDataProceeded();

    const errors = await onSave(formState);

    if (!errors) {
      return;
    }

    const formErrors = errors
      .map((error) =>
        USER_DATA_ERRORS.find((userDataError) =>
          userDataError.regexp.test(error.message),
        ),
      )
      .reduce<Record<string, { label: string; type: HelperTextType }[]>>(
        (acc, error) =>
          error
            ? {
                ...acc,
                [error.field]: [
                  {
                    label: t(error.helperTextI18NKey),
                    type: HelperTextType.error,
                  },
                ],
              }
            : acc,
        {},
      );

    if (Object.values(formErrors).length) {
      const formErrorsArray = Object.values(formErrors).flatMap((helperTexts) =>
        helperTexts.map((helperText) => helperText.label),
      );

      AmplitudeAnalytics.trackAdditionalUserDataProceedFailed({
        errors: formErrorsArray,
      });
      setFormHelperTexts((prevFormErrors) => ({
        ...prevFormErrors,
        ...formErrors,
      }));
    } else {
      onSaveUnknownError(errors);
    }
  };

  const getFieldErrorState = (field: AdditionalUserDataField) =>
    formHelperTexts[field].some(({ type }) => type === HelperTextType.error);

  const notReachableEmailProvider = getNotReachableEmailProvider(
    userData.email,
  );
  const notReachableEmailProviderI18nKey = notReachableEmailProvider
    ? PROVIDER_TO_I18N_KEY_MAP[notReachableEmailProvider]
    : '';

  return (
    <div className="authentication-panel">
      <Typography className="authentication-panel__description" variant="body2">
        {t('authentication.additional-user-data.description')}
      </Typography>
      <div className="additional-user-data__account">
        <AccountIcon className="additional-user-data__account-icon" />
        <Typography
          classes={{
            root: 'additional-user-data__account-email',
          }}
          variant="body2"
        >
          {userData.email}
        </Typography>
        <Button
          variant="text"
          color="primary"
          className="additional-user-data__log-out-button"
          onClick={onLogOutClick}
        >
          {t('general.navigation.log-out')}
        </Button>
      </div>
      <form
        name="userData"
        className="authentication-panel__form additional-user-data-form"
      >
        <div>
          <ComboBox
            classes={{
              inputRoot:
                'authentication-panel__input additional-user-data-form__input',
              outline: clsx({
                'additional-user-data-form__input_error':
                  getFieldErrorState('country'),
              }),
            }}
            error={getFieldErrorState('country')}
            helperText={getMultilineHelperText(formHelperTexts.country)}
            placeholder={t(
              'authentication.additional-user-data.fields.country',
            )}
            options={countryOptions}
            value={selectedCountryOption}
            disableCloseOnSelect={false}
            getOptionSelected={(option, value) => option.value === value.value}
            onChange={(_event: React.ChangeEvent<unknown>, selectedOption) => {
              handleFieldChange('country', selectedOption?.value ?? '');
            }}
            FormHelperTextProps={{
              classes: {
                root: 'authentication-panel__input-helper-text',
              },
            }}
            startAdornment={
              <LanguageIcon
                fontSize="small"
                className="authentication-panel__input-icon authentication-panel__input-icon_start"
              />
            }
          />
          <Typography
            variant="body2"
            className="authentication-panel__description additional-user-data-form__text"
          >
            <Trans i18nKey="authentication.additional-user-data.validation.request-to-add-your-country">
              If your country is not listed, please
              <a
                className="link"
                href={getDemoFormLink()}
                target="_blank"
                rel="noreferrer"
              >
                drop us a request
              </a>
              to add it.
            </Trans>
          </Typography>
        </div>
        <ComboBox
          classes={{
            inputRoot:
              'authentication-panel__input additional-user-data-form__input',
            outline: clsx({
              'additional-user-data-form__input_error':
                getFieldErrorState('companyType'),
            }),
          }}
          error={getFieldErrorState('companyType')}
          helperText={getMultilineHelperText(formHelperTexts.companyType)}
          placeholder={t(
            'authentication.additional-user-data.fields.company-type',
          )}
          options={companyTypeOptions}
          value={selectedCompanyTypeOption}
          disableCloseOnSelect={false}
          getOptionSelected={(option, value) => option.value === value.value}
          onChange={(_event: React.ChangeEvent<unknown>, selectedOption) => {
            handleFieldChange('companyType', selectedOption?.value ?? '');
          }}
          FormHelperTextProps={{
            classes: {
              root: 'authentication-panel__input-helper-text',
            },
          }}
          startAdornment={
            <WorkIcon
              fontSize="small"
              className="authentication-panel__input-icon authentication-panel__input-icon_start"
            />
          }
        />
        <div>
          <TextField
            type="tel"
            value={formState.phoneNumber}
            placeholder={phonePlaceholder}
            error={getFieldErrorState('phoneNumber')}
            helperText={getMultilineHelperText(formHelperTexts.phoneNumber)}
            InputProps={{
              classes: {
                root: 'authentication-panel__input',
              },
              startAdornment: (
                <PhoneAndroidSharpIcon
                  fontSize="small"
                  className="authentication-panel__input-icon authentication-panel__input-icon_start"
                />
              ),
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleFieldChange('phoneNumber', event.target.value);
            }}
            FormHelperTextProps={{
              classes: {
                root: 'authentication-panel__input-helper-text',
              },
            }}
          />
          <Typography
            variant="body2"
            className="authentication-panel__description additional-user-data-form__text"
          >
            {t('authentication.additional-user-data.provide-phone-number', {
              phoneExample: formState.country
                ? t(
                    'authentication.additional-user-data.phone-number-example',
                    { phonePlaceholder },
                  )
                : '',
            })}
          </Typography>
        </div>
        {isNotReachableEmail(userData.email) && (
          <div>
            <TextField
              value={formState.email}
              error={getFieldErrorState('email')}
              helperText={getMultilineHelperText(formHelperTexts.email)}
              placeholder="Email"
              InputProps={{
                classes: {
                  root: 'authentication-panel__input',
                  error: 'textfield__input_error',
                },
                startAdornment: (
                  <EmailSharpIcon
                    fontSize="small"
                    className="authentication-panel__input-icon authentication-panel__input-icon_start"
                  />
                ),
              }}
              FormHelperTextProps={{
                classes: {
                  root: 'authentication-panel__input-helper-text',
                },
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleFieldChange('email', e.target.value);
              }}
            />
            <Typography
              variant="body2"
              className="authentication-panel__description additional-user-data-form__text"
            >
              <Trans
                i18nKey="authentication.additional-user-data.provide-email"
                values={{
                  currentEmail: userData.email,
                  provider: t(notReachableEmailProviderI18nKey),
                }}
              >
                Text
                <span className="additional-user-data-form__text_highlighted">
                  currentEmail
                </span>
                text
              </Trans>
            </Typography>
          </div>
        )}
        <div className="additional-user-data-form__terms-and-conditions">
          <Checkbox
            classes={{
              root: 'additional-user-data-form__terms-and-conditions-checkbox',
            }}
            value={formState.acceptedTermsAndConditions ? 2 : 0}
            onClick={() => {
              handleFieldChange(
                'acceptedTermsAndConditions',
                !formState.acceptedTermsAndConditions,
              );
            }}
          />
          <Trans
            parent="div"
            className="additional-user-data-form__terms-and-conditions-statement"
            i18nKey="authentication.additional-user-data.terms-and-conditions"
          >
            I agree with the
            <a
              className="link"
              href={getTermsLink()}
              target="_blank"
              rel="noreferrer"
            >
              Terms & Conditions
            </a>
            and
            <a
              className="link"
              href={getPolicyLink()}
              target="_blank"
              rel="noreferrer"
            >
              Privacy policy
            </a>
            to start working with {{ brandName }}.
          </Trans>
        </div>
        <Button
          className="authentication-panel__button"
          variant="contained"
          color="primary"
          disabled={proceedButtonDisabled}
          onClick={handleProceedClick}
        >
          {t('general.controls.proceed')}
        </Button>
      </form>
    </div>
  );
};

export default AdditionalUserDataForm;
