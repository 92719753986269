import {
  useAppDispatch,
  useAppSelector,
} from '../../../../app/store/helpers/functions';
import { AssetType } from '../../../../helpers/constants/entities/asset';
import { generateEquationMaps } from '../../../assets/assetsSlice';
import { GenerateEquationMapArg } from '../../../createAnalysis/types/api';
import { selectCurrentEquation } from '../../../equations/equationsSelectors';
import { InputDataVariable } from '../../dataVariable/types/inputDataVariable';
import {
  selectEquationMapsData,
  selectGridSize,
  selectType,
} from '../createBatchEquationBasedAnalysisSelectors';
import { AssetVariableData } from '../types/variables';
import { updateEquationMapsData } from '../createBatchEquationBasedAnalysisSlice';
import { getAssetVariableDataAttributeId } from '../helpers/functions/variables';
import LoadStatus from '../../../../helpers/constants/utils/loadStatus';

const convertAssetVariableData = (
  variable: string,
  assetVariableData?: AssetVariableData,
): InputDataVariable | null => {
  let result: InputDataVariable | null = null;

  if (assetVariableData?.type === AssetType.soilDataset) {
    result = {
      soilDatasetUuid: assetVariableData.uuid,
      soilAttribute: getAssetVariableDataAttributeId(
        assetVariableData.attribute,
      ),
      variable,
    };
  } else if (assetVariableData?.type === AssetType.yieldDataset) {
    result = {
      yieldDatasetUuid: assetVariableData.uuid,
      yieldAttribute: getAssetVariableDataAttributeId(
        assetVariableData.attribute,
      ),
      variable,
    };
  } else if (assetVariableData?.type === AssetType.asAppliedDataset) {
    result = {
      asAppliedDatasetUuid: assetVariableData.uuid,
      asAppliedAttribute: getAssetVariableDataAttributeId(
        assetVariableData.attribute,
      ),
      variable,
    };
  } else if (assetVariableData?.type === AssetType.topographyMap) {
    result = {
      topographyMapUuid: assetVariableData.uuid,
      topographyAttribute: getAssetVariableDataAttributeId(
        assetVariableData.attribute,
      ),
      variable,
    };
  } else if (assetVariableData?.type === AssetType.satelliteImage) {
    result = {
      satelliteImageUuids: assetVariableData.satelliteImageUuids,
      index: getAssetVariableDataAttributeId(assetVariableData.attribute),
      variable,
    };
  } else if (assetVariableData?.type === AssetType.vectorAnalysisMap) {
    result = {
      vectorAnalysisMapUuid: assetVariableData.uuid,
      vectorAnalysisMapZoneTypeAttribute: assetVariableData.attribute,
      variable,
    };
  } else if (assetVariableData?.type === AssetType.equationMap) {
    result = {
      equationMapUuid: assetVariableData.uuid,
      variable,
    };
  }

  if (result) {
    result.useInterpolatedData =
      assetVariableData?.useInterpolatedData ?? false;
  }

  return result;
};

export default function useGenerateEquationMaps() {
  const dispatch = useAppDispatch();

  const equationMapsData = useAppSelector(selectEquationMapsData);
  const {
    equationAsText,
    equationResultVariable,
    dataVariables,
    productUnit,
    uuid: equationUuid,
    useNumpy,
  } = useAppSelector(selectCurrentEquation);
  const { x: defaultGridSizeX, y: defaultGridSizeY } =
    useAppSelector(selectGridSize);
  const type = useAppSelector(selectType);

  return async (fieldUuids: string[]) => {
    const equationMapsArgs = fieldUuids.map<GenerateEquationMapArg>(
      (fieldUuid) => {
        const equationMapData = equationMapsData[fieldUuid];

        const { assignedVariables, title, gridSize } = equationMapData;

        const inputDataVariables = dataVariables.reduce<InputDataVariable[]>(
          (acc, dataVariable) => {
            const assignedVariable = assignedVariables[dataVariable];
            const inputDataVariable = convertAssetVariableData(
              dataVariable,
              assignedVariable,
            );

            if (inputDataVariable) {
              acc.push(inputDataVariable);
            }

            return acc;
          },
          [],
        );

        return {
          uuid: equationMapData.uuid,
          fieldUuid,
          name: title,
          gridXSize: gridSize?.x || defaultGridSizeX,
          gridYSize: gridSize?.y || defaultGridSizeY,
          type,
          equation: equationAsText,
          equationUuid,
          resultVariable: equationResultVariable,
          dataVariables: inputDataVariables,
          productUnit: productUnit || null,
          useNumpy,
        };
      },
    );

    const generationResults = await dispatch(
      generateEquationMaps(equationMapsArgs),
    ).unwrap();

    const equationMapsDataUpdate = generationResults.reduce(
      (acc, generationResult) => {
        acc[generationResult.fieldUuid] = {
          uuid: generationResult.uuid,
          generationStatus: LoadStatus.loading,
        };

        return acc;
      },
      {} as Record<string, { uuid: string; generationStatus: LoadStatus }>,
    );

    dispatch(updateEquationMapsData(equationMapsDataUpdate));
  };
}
