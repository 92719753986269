import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';

import BackdropLoading from '../../../../../components/Loading/BackdropLoading';

import './index.scss';

const LOADER_SIZE = 16;

type LastOperationsSummaryItemType =
  | 'total'
  | 'in-progress'
  | 'success'
  | 'ignored'
  | 'error';

export default function LastOperationsSummaryItem({
  type,
  value,
  isFetching,
}: {
  type: LastOperationsSummaryItemType;
  value: number;
  isFetching: boolean;
}) {
  const { t } = useTranslation();

  return (
    <div className={'last-operations-summary-item'}>
      <Typography
        className={clsx('last-operations-summary-item__header', {
          [`last-operations-summary-item__header-${type}`]: value,
        })}
      >
        {t(`operations.statuses.${type}`)}
      </Typography>
      {isFetching ? (
        <div className="last-operations-summary-item__loader-wrapper">
          <BackdropLoading size={LOADER_SIZE} />
        </div>
      ) : (
        <Typography
          className={clsx('last-operations-summary-item__value', {
            [`last-operations-summary-item__value-${type}`]: value,
          })}
        >
          {value}
        </Typography>
      )}
    </div>
  );
}
