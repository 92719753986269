import { ComponentType } from 'react';
import { View } from '../../helpers/constants/authentication';

import LogIn from './Views/LogIn';
import SignUp from './Views/SignUp';
import SignUpSentPanel from './Views/SignUpSent';
import SignUpConfirm from './Views/SignUpConfirm';
import ForgotPassword from './Views/ForgotPassword';
import ForgotPasswordSent from './Views/ForgotPasswordSent';
import RecoverPassword from './Views/RecoverPassword';
import RecoverPasswordConfirm from '../../components/RecoverPasswordConfirm';
import NewPasswordPanel from './Views/NewPassword';

const views: Record<
  string,
  ComponentType<{
    onLoading: (isLoading: boolean) => void;
  }>
> = {
  [View.logIn]: LogIn,
  [View.signUp]: SignUp,
  [View.signUpSent]: SignUpSentPanel,
  [View.signUpConfirm]: SignUpConfirm,
  [View.forgotPassword]: ForgotPassword,
  [View.forgotPasswordSent]: ForgotPasswordSent,
  [View.recoverPassword]: RecoverPassword,
  [View.recoverPasswordConfirm]: RecoverPasswordConfirm,
  [View.newPassword]: NewPasswordPanel,
};

export default views;
