import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Button from '../../../../../components/Button';
import PopupHeader from '../../../../../components/Popups/PopupHeader';
import { exportToJohnDeereAsFiles } from '../../jdExportSlice';
import type { Label } from '../../../../field/types/field';
import SelectOrganization from '../SelectOrganization';
import SelectClientFarmField from '../../components/SelectClientFarmField';
import AmplitudeAnalytics from '../../../../../helpers/classes/amplitudeAnalytics';
import useCurrentPage from '../../../../../hooks/useCurrentPage';

import '../../components/common/index.scss';

enum Steps {
  selectOrg = 'selectOrg',
  selectClientFarmField = 'selectClientFarmField',
}

interface BoundaryData {
  name: string;
  farmUuid: string;
  fieldUuid: string;
}

export default function ExportBoundaryToJohnDeerePopup({
  boundary,
  labels,
  farmName,
  fieldName,
  onCancel,
  onConfirm,
}: {
  labels: Label[];
  farmName: string;
  fieldName: string;
  boundary: BoundaryData;
  onCancel: () => void;
  onConfirm: () => void;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const page = useCurrentPage();
  const [orgId, setOrgId] = useState<number | null>(null);
  const [client, setClient] = useState('');
  const [farm, setFarm] = useState('');
  const [field, setField] = useState('');
  const [step, setStep] = useState(Steps.selectOrg);

  useEffect(() => {
    if (page) {
      AmplitudeAnalytics.trackExportToJDPopupOpened({
        page,
        type: 'asFiles',
      });
    }
  }, [page]);

  const goToSelectClientFarmField = () => {
    setStep(Steps.selectClientFarmField);
  };
  const goToSelectOrg = () => {
    setStep(Steps.selectOrg);
  };
  const handleOrgIdChange = (newOrgId: number) => {
    setOrgId(newOrgId);
  };
  const handleFarmChange = (newFarm: string) => {
    setFarm(newFarm);
  };
  const handleClientChange = (newClient: string) => {
    setClient(newClient);
  };
  const handleFieldChange = (newField: string) => {
    setField(newField);
  };
  const handleConfirm = () => {
    dispatch(
      // @ts-expect-error
      exportToJohnDeereAsFiles({
        orgId,
        client,
        farm,
        field,
        boundaries: [boundary],
      }),
    );
    AmplitudeAnalytics.trackEntityExportedToJD({
      page,
      entityType: 'field',
      exportType: 'asFiles',
    });
    onConfirm();
  };

  let content = (
    <SelectOrganization
      organizationId={orgId}
      onOrganizationChange={handleOrgIdChange}
    />
  );
  let actions = (
    <>
      <Button variant="outlined" onClick={onCancel}>
        {t('general.controls.cancel')}
      </Button>
      <Button
        disabled={!orgId}
        variant="contained"
        color="primary"
        onClick={goToSelectClientFarmField}
      >
        {t('general.stepper.next')}
      </Button>
    </>
  );

  if (step === Steps.selectClientFarmField) {
    content = (
      <SelectClientFarmField
        fieldLabels={labels}
        fieldName={fieldName}
        farmName={farmName}
        farm={farm}
        field={field}
        client={client}
        onClientChangeHandler={handleClientChange}
        onFarmChangeHandler={handleFarmChange}
        onFieldChangeHandler={handleFieldChange}
      />
    );
    actions = (
      <>
        <Button variant="outlined" onClick={goToSelectOrg}>
          {t('general.stepper.back')}
        </Button>
        <Button
          disabled={!(client && farm && field)}
          variant="contained"
          color="primary"
          onClick={handleConfirm}
        >
          {t('general.popups.done')}
        </Button>
      </>
    );
  }

  return (
    <Dialog open>
      <PopupHeader
        title={t('general.popups.export-to-john-deere.title')}
        onCancel={onCancel}
      />
      <DialogContent className="export-to-john-deere-popup__body">
        {content}
      </DialogContent>
      <DialogActions classes={{ root: 'export-to-john-deere-popup__actions' }}>
        {actions}
      </DialogActions>
    </Dialog>
  );
}
