import React, { useState } from 'react';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';

const EditMode = ({ value, onFinish }) => {
  const [editActive, setEditActive] = useState(false);
  const [innerValue, setInnerValue] = useState(value);

  const handleInputBlur = (e) => {
    onFinish(e.target.value);
    setInnerValue(e.target.value || value.title);
    setEditActive(false);
  };

  const handleInputChange = (e) => {
    setInnerValue(e.target.value);
  };

  const handleTextClick = () => {
    setEditActive(true);
  };

  return editActive ? (
    <InputBase
      className="editable-cell__input"
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
      fullWidth
      value={innerValue}
      onChange={handleInputChange}
      onBlur={handleInputBlur}
    />
  ) : (
    <Typography
      variant="body2"
      className="editable-cell__text"
      onClick={handleTextClick}
    >
      {innerValue}
    </Typography>
  );
};

export default EditMode;
