import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AutocompleteItem } from '../autocomplete/types/autocomplete';

export interface FieldsListState {
  filter: {
    farmUuid: string;
    autocompleteValue: AutocompleteItem[];
  };
  labelsExpanded: boolean;
}

const initialState: FieldsListState = {
  filter: {
    farmUuid: '',
    autocompleteValue: [],
  },
  labelsExpanded: false,
};

export const fieldsListSlice = createSlice({
  name: 'fieldsList',
  initialState,
  reducers: {
    updateFieldsListParams(
      state,
      action: PayloadAction<{
        filter?: {
          farmUuid?: string;
          autocompleteValue?: AutocompleteItem[];
        };
        labelsExpanded?: boolean;
      }>,
    ) {
      state.filter.farmUuid =
        action.payload.filter?.farmUuid ?? state.filter.farmUuid;
      state.filter.autocompleteValue =
        action.payload.filter?.autocompleteValue ??
        state.filter.autocompleteValue;
      state.labelsExpanded =
        action.payload.labelsExpanded ?? state.labelsExpanded;
    },
    setLabelsExpanded(state, action: PayloadAction<boolean>) {
      state.labelsExpanded = action.payload;
    },
    resetFieldsListParams() {
      return initialState;
    },
  },
});

export const {
  updateFieldsListParams,
  setLabelsExpanded,
  resetFieldsListParams,
} = fieldsListSlice.actions;

export default fieldsListSlice.reducer;
