import React, { Suspense, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import PopupHeader from '../../../../../components/Popups/PopupHeader';
import Button from '../../../../../components/Button';
import Loading from '../../../../../components/Loading';
import TextField from '../../../../../components/TextField';
import COLOR_SCHEMAS_AMOUNT from '../../helpers/constants/colorSchemasAmount';

import './index.scss';

const ColorSchemaPanel = React.lazy(() => import('../ColorSchemaPanel'));

const calculateConfirmDisabled = (title = '', colors = {}) => {
  const colorsArray = Object.values(colors);

  return (
    !title?.trim() ||
    colorsArray.length !== COLOR_SCHEMAS_AMOUNT ||
    colorsArray.some((color) => !color)
  );
};

const ColorSchema = ({
  colorSchema,
  onCancel = () => {},
  onConfirm = () => {},
}) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState(colorSchema.title);
  const [colors, setColors] = useState(colorSchema.colors);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleColorsChange = (color, index) => {
    setColors((prevColors) => ({
      ...prevColors,
      [index]: color,
    }));
  };

  return (
    <Dialog open>
      <PopupHeader
        title={t('general.popups.color-schema.title')}
        onCancel={onCancel}
      />
      <DialogContent className="color-schema-popup">
        <TextField
          classes={{
            wrapper: 'color-schema-popup__name-input',
          }}
          title={t('general.popups.color-schema.name-label')}
          value={title}
          onChange={handleTitleChange}
        />
        <DialogContentText
          classes={{
            root: 'color-schema-popup__content-text',
          }}
        >
          <Trans i18nKey="general.popups.color-schema.description">
            Text
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://colorbrewer2.org/"
              className="link"
            >
              Link
            </a>
            text
          </Trans>
        </DialogContentText>
        <Suspense fallback={<Loading />}>
          <ColorSchemaPanel
            colors={colors}
            onColorsChange={handleColorsChange}
          />
        </Suspense>
      </DialogContent>
      <DialogActions
        classes={{
          root: 'color-schema-popup__actions',
        }}
      >
        <Button variant="outlined" onClick={onCancel}>
          {t('general.controls.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={calculateConfirmDisabled(title, colors)}
          onClick={() => onConfirm(title, colors)}
        >
          {t('general.controls.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ColorSchema;
