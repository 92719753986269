import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import './index.scss';

const PinPhoto = ({ photo, className = '', onPhotoDeleteClick = () => {} }) => {
  const handlePhotoDeleteClick = () => {
    onPhotoDeleteClick(photo);
  };

  return (
    <div className={`pin-photo__container ${className}`}>
      <img className="pin-photo__image" src={photo.url} alt={photo.name} />
      <IconButton
        className="pin-photo__delete-button"
        onClick={handlePhotoDeleteClick}
      >
        <CloseIcon className="pin-photo__delete-icon" />
      </IconButton>
    </div>
  );
};

export default PinPhoto;
