import React, { useState, ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover } from '@material-ui/core';

import FiltersButton from '../ExpandFiltersButton';
import Button from '../../../../../components/Button';
import ResetFiltersButton from '../ResetFiltersButton';

import './index.scss';

const FiltersPopover = ({
  children,
  onFiltersClear,
}: {
  children?: ReactNode;
  onFiltersClear: () => void;
}) => {
  const { t } = useTranslation();
  const buttonRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleShowHideFilters = () => {
    setOpen((prev) => !prev);
  };

  const handlePopoverClose = (event: React.MouseEvent) => {
    event?.stopPropagation();
    handleShowHideFilters();
  };

  const handlePopoverClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <>
      <FiltersButton
        ref={buttonRef}
        isExpanded={open}
        onClick={handleShowHideFilters}
      />
      <Popover
        anchorEl={buttonRef.current}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{
          paper: 'filters-popover__paper',
        }}
        onClick={handlePopoverClick}
        onClose={handlePopoverClose}
      >
        <>
          {children}
          <div className="filters-popover__footer">
            <ResetFiltersButton onClick={onFiltersClear} />
            <Button
              className="filters-popover__close-button"
              color="primary"
              variant="text"
              onClick={handlePopoverClose}
            >
              {t('general.controls.close')}
            </Button>
          </div>
        </>
      </Popover>
    </>
  );
};

export default FiltersPopover;
