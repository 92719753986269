import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import Label from '../Label';
import { TYPE_I18N_KEY_LABEL_MAP } from '../../helpers/analysis';
import { AnalysisMapType } from '../../helpers/constants/entities/vectorAnalysisMap';

import './index.scss';

const TYPE_CLASS_NAME_MAP = {
  [AnalysisMapType.satellite]: 'vamap-type-label_satellite',
  [AnalysisMapType.soil]: 'vamap-type-label_soil',
  [AnalysisMapType.yield]: 'vamap-type-label_yield',
  [AnalysisMapType.asApplied]: 'vamap-type-label_as-applied',
  [AnalysisMapType.topography]: 'vamap-type-label_topography',
  [AnalysisMapType.multilayers]: 'vamap-type-label_multilayer',
  [AnalysisMapType.zoneOperations]: 'vamap-type-label_intersect',
  [AnalysisMapType.drawn]: 'vamap-type-label_drawn',
  [AnalysisMapType.ramap]: '',
};

export default function VamapTypeLabel({
  entity,
}: {
  entity: { analysisType?: AnalysisMapType };
}) {
  const { t } = useTranslation();
  const className =
    entity.analysisType && TYPE_CLASS_NAME_MAP[entity.analysisType];
  const i18nKey =
    entity.analysisType && TYPE_I18N_KEY_LABEL_MAP[entity.analysisType];

  if (!className || !i18nKey) {
    return null;
  }

  return (
    <Label
      classes={{
        label: clsx(className, 'vamap-type-label'),
      }}
      text={t(i18nKey)}
    />
  );
}
