import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ItemPlaceholder() {
  const { t } = useTranslation();

  return (
    <span className="text_disabled">{t('general.controls.data-removed')}</span>
  );
}
