import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from '../..';

import './index.scss';

export default function NumberTooltip({
  children,
}: {
  children: ReactElement;
}) {
  const { t } = useTranslation();

  return (
    <Tooltip
      classes={{
        label: 'number-tooltip__label',
        tooltip: 'number-tooltip__tooltip',
      }}
      placement="right"
      tooltip={
        <p className="number-tooltip__tooltip-line">
          {t('field.assets.dataset-items.number-tooltip.geometries-number')}
        </p>
      }
    >
      {children}
    </Tooltip>
  );
}
