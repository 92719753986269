import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../../../components/Button';
import ToolsPanel from '../../../../../components/ToolsPanel';
import { EXPORT_ASSET_TYPE } from '../../helpers/constants/assets';
import {
  prepareSelectedAssets,
  getSelectedAssets,
} from '../../helpers/functions/assets';
import {
  exportAsset,
  openExportZonesMapPopup,
} from '../../../../exportData/exportDataSlice';
import {
  selectFieldsEntities,
  selectPinsGroupsEntities,
  selectVectorAnalysisMapsEntities,
} from '../../../../exportData/exportDataSelectors';
import { selectSelectedAssets } from '../../downloadFilesSelectors';

import './index.scss';

const DownloadFilesToolsPanel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedAssetsUuids = useSelector(selectSelectedAssets);
  const fieldsEntities = useSelector(selectFieldsEntities);
  const vectorAnalysisMapsEntities = useSelector(
    selectVectorAnalysisMapsEntities,
  );
  const pinsGroupsEntities = useSelector(selectPinsGroupsEntities);
  const selectedAssets = getSelectedAssets(selectedAssetsUuids, {
    fieldsEntities,
    vectorAnalysisMapsEntities,
    pinsGroupsEntities,
  });
  const zonesMapHasNoAttributes = selectedAssets.some(
    (asset) =>
      asset.type === EXPORT_ASSET_TYPE.zonesMap && !asset.value.attributes,
  );

  const handleExportAllClick = () => {
    const { vectorAnalysisMaps, fieldBoundaries, pinsGroups } =
      prepareSelectedAssets(selectedAssets);

    if (vectorAnalysisMaps) {
      dispatch(
        openExportZonesMapPopup({
          zonesMaps: vectorAnalysisMaps,
          fieldBoundariesShpAssets: fieldBoundaries,
          pinsGroups,
        }),
      );
    } else {
      dispatch(
        exportAsset({
          fieldBoundaries,
          pinsGroups,
        }),
      );
    }
  };

  return (
    <ToolsPanel
      classes={{
        root: 'export-tools-panel',
        actions: 'export-tools-panel__actions',
        actionsLeft: 'export-tools-panel__actions-left',
        filters: 'export-tools-panel__filters',
      }}
      actionsLeft={t('export.description')}
      actionsRight={
        selectedAssets.length > 0 ? (
          <Button
            variant="contained"
            color="primary"
            disabled={zonesMapHasNoAttributes}
            onClick={handleExportAllClick}
          >
            {t('export.download-selected', {
              count: selectedAssets.length,
            })}
          </Button>
        ) : null
      }
    />
  );
};

export default DownloadFilesToolsPanel;
