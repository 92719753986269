import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './index.scss';

const getInitialExpandedState = ({ items, multiple, expandFirstItem }) => {
  if (multiple) {
    return items.map((_, index) => index);
  }

  return expandFirstItem ? [0] : [];
};

const CollapsibleList = ({
  items,
  multiple = false,
  expandFirstItem = false,
  panelContentRenderer = () => null,
  summaryOptionsRenderer = () => null,
  onPanelChange = () => {},
}) => {
  const [expandedPanels, setExpandedPanels] = useState(
    getInitialExpandedState({
      items,
      multiple,
      expandFirstItem,
    }),
  );

  const handlePanelChange = (item, index) => {
    onPanelChange(item, index);

    if (multiple) {
      if (expandedPanels.includes(index)) {
        setExpandedPanels(
          expandedPanels.filter((panelIndex) => panelIndex !== index),
        );
      } else {
        setExpandedPanels([...expandedPanels, index]);
      }
    } else {
      setExpandedPanels(expandedPanels[0] === index ? [] : [index]);
    }
  };

  return items.map((item, index) => (
    <Accordion
      key={index}
      classes={{
        root: 'collapsible-list__item',
      }}
      TransitionProps={{
        timeout: 500,
        unmountOnExit: true,
      }}
      elevation={0}
      expanded={expandedPanels.includes(index)}
      onChange={() => handlePanelChange(item, index)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{
          root: 'collapsible-list__item-header-root',
          content: 'collapsible-list__item-header-content',
        }}
      >
        <Typography variant="h6">{item.name}</Typography>
        {summaryOptionsRenderer(item)}
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: 'collapsible-list__item-content',
        }}
      >
        {panelContentRenderer(item)}
      </AccordionDetails>
    </Accordion>
  ));
};

export default CollapsibleList;
