import React, { useRef, useState } from 'react';
import i18n from 'i18next';
import AddIcon from '@material-ui/icons/Add';
import { Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { AssetType } from '../../../../../helpers/constants/entities/asset';
import { DataLayer } from '../../types/ui';
import Tabs from '../../../../../components/Tabs';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../app/store/helpers/functions';
import {
  selectActiveDataLayerIndex,
  selectDataLayers,
} from '../../createMultiLayerAnalysisSelectors';
import {
  addDataLayer,
  changeActiveDataLayer,
  deleteDataLayer,
} from '../../createMultiLayerAnalysisSlice';
import Button from '../../../../../components/Button';
import { selectCreateAnalysisAssets } from '../../../../field/fieldSelectors';
import {
  initDataLayer,
  getDataLayersOptions,
} from '../../helpers/functions/dataLayer';

import './index.scss';

const getDatasetLayerPostfix = (layer: DataLayer) =>
  layer.attribute && layer.datasetUuid ? 1 : null;

const getDataLayerTabs = (dataLayers: DataLayer[]) =>
  dataLayers.map((layer, index) => {
    const { type } = layer;
    let label;

    if (type === AssetType.satelliteImage) {
      label = {
        primary: i18n.t('general.shared.satellite-monitoring'),
        secondary: layer.index,
        primaryPostfix: layer.satelliteImageUuids?.length || null,
      };
    } else if (type === AssetType.soilDataset) {
      label = {
        primary: i18n.t('general.shared.soil-data'),
        secondary: layer.attribute,
        primaryPostfix: getDatasetLayerPostfix(layer),
      };
    } else if (type === AssetType.yieldDataset) {
      label = {
        primary: i18n.t('general.shared.yield-data'),
        secondary: layer.attribute,
        primaryPostfix: getDatasetLayerPostfix(layer),
      };
    } else if (type === AssetType.asAppliedDataset) {
      label = {
        primary: i18n.t('general.shared.as-applied-data'),
        secondary: layer.attribute,
        primaryPostfix: getDatasetLayerPostfix(layer),
      };
    } else if (type === AssetType.topographyMap) {
      label = {
        primary: i18n.t('general.shared.topography'),
        secondary: layer.attribute,
        primaryPostfix: getDatasetLayerPostfix(layer),
      };
    }

    return {
      value: index,
      label,
      deletable: true,
    };
  });

export default function DataLayersTabs() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const dataLayers = useAppSelector(selectDataLayers);
  const activeDataLayerIndex = useAppSelector(selectActiveDataLayerIndex);
  const assets = useAppSelector(selectCreateAnalysisAssets);
  const dataLayersOptions = getDataLayersOptions(assets);
  const addDataLayerButtonRef = useRef(null);

  const [menuOpen, setMenuOpen] = useState(false);

  const handleActiveDataLayerIndexChange = (index: number) => {
    dispatch(changeActiveDataLayer(index));
  };

  const handleDataLayerTabDelete = (index: number) => {
    dispatch(deleteDataLayer(index));
  };

  const handleAddDataLayerClick = (option: {
    value: AssetType;
    title: string;
  }) => {
    const dataLayer = initDataLayer(option.value);

    dispatch(addDataLayer(dataLayer));
    setMenuOpen(false);
  };

  return (
    <div className="data-layers-tabs">
      {!!dataLayers.length && (
        <Tabs
          withDeleteButtons
          tabs={getDataLayerTabs(dataLayers)}
          value={activeDataLayerIndex}
          tabClasses={{
            root: 'data-layers-tabs__tab',
          }}
          onTabChange={(_event: unknown, v: number) =>
            handleActiveDataLayerIndexChange(v)
          }
          onTabRemove={(_event: unknown, i: number) =>
            handleDataLayerTabDelete(i)
          }
        />
      )}
      <Button
        ref={addDataLayerButtonRef}
        startIcon={<AddIcon />}
        classes={{
          root: 'data-layers-tabs__add-tab-btn',
        }}
        onClick={() => setMenuOpen(true)}
      >
        {t('zones-ops.multi-layer.steps.3.add-data-layer')}
      </Button>
      <Menu
        anchorEl={addDataLayerButtonRef.current}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        {dataLayersOptions.map((option) => (
          <MenuItem
            key={option.value}
            className="data-layers-tabs__menu-item"
            onClick={() => handleAddDataLayerClick(option)}
          >
            {option.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
