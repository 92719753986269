import { API, graphqlOperation } from '@aws-amplify/api';

import {
  JOHN_DEERE_FIRST_PAGE_PAGINATION,
  JOHN_DEERE_DEFAULT_PAGE_SIZE_PAGINATION,
} from './helpers/constants';
import getOrganizationsQuery from './graphql/queries/getOrganizations.gql';
import {
  GetOrganizationsByIdsResponse,
  GetOrganizationsPageResponse,
} from './types/api';
import { JDOrganization } from './types/jdOrganization';

export const getOrganizationsPage = async (
  page = JOHN_DEERE_FIRST_PAGE_PAGINATION,
  pageSize = JOHN_DEERE_DEFAULT_PAGE_SIZE_PAGINATION,
  orgName = '',
): Promise<{
  orgs: JDOrganization[];
  totalCount: number;
  page: number;
  pageSize: number;
  orgName: string;
}> => {
  const { data } = (await API.graphql(
    graphqlOperation(getOrganizationsQuery, {
      orgFilter: {
        page,
        pageSize,
        orgName,
      },
    }),
  )) as GetOrganizationsPageResponse;

  return {
    ...data!.getOrganizationsJohnDeere,
    page,
    pageSize,
    orgName,
  };
};

export const getOrginazationsById = async (
  ids: string[],
): Promise<JDOrganization[]> => {
  const { data } = (await API.graphql(
    graphqlOperation(getOrganizationsQuery, {
      orgFilter: {
        organizationsIds: ids,
      },
    }),
  )) as GetOrganizationsByIdsResponse;

  return data!.getOrganizationsJohnDeere.orgs;
};
