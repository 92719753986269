import { RootState } from '../../../app/store/helpers/types';

export const selectSelectedField = ({ zonesOps }: RootState) =>
  zonesOps.selectedField;

export const selectWorkflow = ({ zonesOps }: RootState) => zonesOps.workflow;

export const selectStepIndex = ({ zonesOps }: RootState) => zonesOps.stepIndex;

export const selectPreviewExpanded = ({ zonesOps }: RootState) =>
  zonesOps.previewExpanded;
