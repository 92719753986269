mutation AddPinComment($input: AddNoteCommentInput!) {
  addNoteComment(input: $input) {
    uuid
    fieldUuid
    noteUuid
    text
    createdDate
    updatedDate
  }
}
