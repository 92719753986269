import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from '@material-ui/core/Slider';
import clsx from 'clsx';

import getDebouncer from '../../../../../helpers/functions/utils/getDebouncer';
import { isNumber } from '../../../../../helpers/functions/utils/number';
import TextField from '../../../../../components/TextField';
import { prettifyNumber } from '../../../../../helpers/markup';

import './index.scss';

const MIN = -1;
const MAX = 1;
const STEP = 0.01;
const MARKS_STEP = 0.2;
const TIMEOUT = 15; // ms
const inputDebouncer = getDebouncer(TIMEOUT);

const generateArray = (min: number, max: number, step: number) => {
  const result = [];
  for (let i = min; i <= max; i += step) {
    result.push(parseFloat(i.toFixed(1)));
  }

  return result;
};

const marks = generateArray(MIN, MAX, MARKS_STEP).map((value) => ({
  value,
  label: prettifyNumber(value, 1),
}));

const isMinValueValid = (min: string | number, max: string | number) =>
  isNumber(min) && !(isNumber(max) && min > max);

const isMaxValueValid = (min: string | number, max: string | number) =>
  isNumber(max) && !(isNumber(min) && max < min);

const NDVISlider = ({
  value,
  onChange,
  classes = {},
}: {
  value: number[];
  onChange: (v: number[]) => void;
  classes?: {
    label?: string;
  };
}) => {
  const { t } = useTranslation();
  const [min, setMin] = useState<string | number>(value[0]);
  const [max, setMax] = useState<string | number>(value[1]);
  const [isChanging, setIsChanging] = useState(false);
  const [errors, setErrors] = useState<{ min?: boolean; max?: boolean }>({});

  const sliderValue = useMemo(() => {
    const minValue = errors.min || !isNumber(min) ? value[0] : min;
    const maxValue = errors.max || !isNumber(max) ? value[1] : max;

    return [minValue, maxValue];
  }, [value, min, max, errors]);

  useEffect(() => {
    if (isChanging) {
      return;
    }

    const [nextMin, nextMax] = value;

    if (nextMin !== min || nextMax !== max) {
      setMin(nextMin);
      setMax(nextMax);
    }
  }, [value, min, max, isChanging]);

  const handleIntervalChange = (
    {
      minValue,
      maxValue,
    }: {
      minValue: string | number;
      maxValue: string | number;
    },
    debouncer: ReturnType<typeof getDebouncer>,
  ) => {
    setIsChanging(true);
    debouncer(() => {
      if (isNumber(minValue) && isNumber(maxValue) && minValue <= maxValue) {
        onChange([minValue, maxValue]);
        setIsChanging(false);
      }
    });
  };

  const handleMinValueChange = (e: ChangeEvent<{ value: string }>) => {
    const numberValue = parseFloat(e.target.value);
    const minValue = isNumber(numberValue) ? numberValue : '';

    setMin(minValue);
    setErrors({ min: !isMinValueValid(minValue, max) });
    handleIntervalChange({ minValue, maxValue: max }, inputDebouncer);
  };

  const handleMaxValueChange = (e: ChangeEvent<{ value: string }>) => {
    const numberValue = parseFloat(e.target.value);
    const maxValue = isNumber(numberValue) ? numberValue : '';

    setMax(maxValue);
    setErrors({ max: !isMaxValueValid(min, maxValue) });
    handleIntervalChange({ minValue: min, maxValue }, inputDebouncer);
  };

  const handleMinInputBlur = () => {
    if (errors.min) {
      setMin(value[0]);
      setErrors({});
    }
  };

  const handleMaxInputBlur = () => {
    if (errors.max) {
      setMax(value[1]);
      setErrors({});
    }
  };

  const handleSliderValueChange = (
    _e: ChangeEvent<unknown>,
    v: number | number[],
  ) => {
    const values = Array.isArray(v) ? v : [v, v];
    setErrors({});
    setMin(values[0]);
    setMax(values[1]);
    setIsChanging(true);
  };

  const handleSliderValueCommitted = (
    _e: ChangeEvent<unknown>,
    v: number | number[],
  ) => {
    const values = Array.isArray(v) ? v : [v];
    setIsChanging(false);
    setMin(values[0]);
    setMax(values[1]);
    onChange(values);
  };

  return (
    <div className="ndvi-slider">
      <span className={clsx('ndvi-slider__label', classes.label)}>
        {t('general.controls.satellite-filters.ndvi')}
      </span>
      <div className="ndvi-slider__inputs">
        <TextField
          variant="small"
          type="number"
          InputProps={{
            inputProps: {
              min: MIN,
              max: MAX,
              step: STEP,
            },
            classes: {
              input: 'ndvi-slider__input',
            },
          }}
          value={min}
          onChange={handleMinValueChange}
          onBlur={handleMinInputBlur}
        />
        <TextField
          variant="small"
          type="number"
          InputProps={{
            inputProps: {
              min: MIN,
              max: MAX,
              step: STEP,
            },
            classes: {
              input: 'ndvi-slider__input',
            },
          }}
          value={max}
          onChange={handleMaxValueChange}
          onBlur={handleMaxInputBlur}
        />
      </div>
      <div className="ndvi-slider__slider-wrapper">
        <Slider
          min={MIN}
          max={MAX}
          step={STEP}
          classes={{
            root: 'ndvi-slider__slider',
            mark: 'mark',
            markLabel: 'mark-label',
            thumb: 'thumb',
            valueLabel: 'value-label',
          }}
          marks={marks}
          value={sliderValue}
          onChange={handleSliderValueChange}
          onChangeCommitted={handleSliderValueCommitted}
        />
      </div>
    </div>
  );
};

export default NDVISlider;
