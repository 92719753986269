import React from 'react';
import TableCell from '@material-ui/core/TableCell';

import VamapTypeLabel from '../../../../../../components/VamapTypeLabel';
import getCellClasses from '../../../helpers/functions/getCellClasses';

import './index.scss';

const ZoneTypeCell = ({ row = {}, headCell = {} }) => (
  <TableCell
    align={headCell.horizontalAlign || 'left'}
    className={getCellClasses('zone-type-cell', headCell, row)}
  >
    <VamapTypeLabel entity={row.data} />
  </TableCell>
);

export default ZoneTypeCell;
