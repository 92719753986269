import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Link from '@material-ui/core/Link';

import { getPricingPageLink } from '../../helpers/navigation';
import { prettifyNumber } from '../../helpers/markup';
import { getI18nAreaAmount } from '../../features/user/helpers/functions/user';
import {
  selectMaxArea,
  selectAreaUnit,
  selectLastPaidOrder,
} from '../../features/user/userSelectors';
import { useAppSelector } from '../../app/store/helpers/functions';
import { isSoftLimitPlan } from '../../features/user/helpers/functions/order';
import RouterLink from '../../components/Link';
import useReportIssuePopup from '../../hooks/useReportIssuePopup';

import './index.scss';

export default function AreaLimitMessage() {
  const { t } = useTranslation();
  const { openReportIssuePopup } = useReportIssuePopup();

  const maxArea = useAppSelector(selectMaxArea);
  const areaUnit = useAppSelector(selectAreaUnit);
  // @ts-expect-error
  const lastPaidOrder = useAppSelector(selectLastPaidOrder);
  const areaUnitLabel = getI18nAreaAmount(areaUnit).toLowerCase();
  const maxAreaLabel = `${prettifyNumber(maxArea)}`;
  let actionLabel;
  let action;

  const handleSubmitRequestButtonClick = () => {
    openReportIssuePopup({
      successMessage: t('general.notifications.submit-request-success'),
    });
  };

  if (isSoftLimitPlan(lastPaidOrder)) {
    actionLabel = t('general.notifications.max-area-reached.submit-request');
    action = (
      <Link
        className="area-limit-message__link link"
        component="button"
        onClick={handleSubmitRequestButtonClick}
      >
        {t('general.notifications.max-area-reached.submit-request')}
      </Link>
    );
  } else {
    actionLabel = t('general.notifications.max-area-reached.upgrade-plan');
    action = (
      <RouterLink target="_blank" to={getPricingPageLink()}>
        {t('general.notifications.max-area-reached.upgrade-plan')}
      </RouterLink>
    );
  }

  return (
    <Trans
      i18nKey="general.notifications.max-area-reached.notification"
      components={{ 5: action }}
      values={{
        area: maxAreaLabel,
        areaUnit: areaUnitLabel,
        actionLabel,
      }}
    ></Trans>
  );
}
