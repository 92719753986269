import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import PopupHeader from '../../../../../../../../../../components/Popups/PopupHeader';
import RadioGroup from '../../../../../../../../../../components/RadioGroup';
import { Sequence } from '../../../../../../../helpers/constants/ui';
import { selectSequence } from '../../../../../../../cleanCalibrateSelectors';
import { changeSequence } from '../../../../../../../cleanCalibrateSlice';

import './index.scss';

export default function CalibrateCleanTabSequenceStepContent({
  onCancel,
}: {
  onCancel: () => void;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const sequence = useSelector(selectSequence);

  const handleSequenceChange = (value: Sequence) => {
    dispatch(changeSequence(value));
  };

  return (
    <>
      <PopupHeader
        classes={{
          root: 'calibrate-clean-tab-sequence-step-content__header',
        }}
        title={t('clean-calibrate.yield-popup.clean-calibrate-title')}
        onCancel={onCancel}
      />
      <DialogContent className="calibrate-clean-tab-sequence-step-content__content">
        <DialogContentText className="calibrate-clean-tab-sequence-step-content__content-description">
          {t(
            'clean-calibrate.yield-popup.tabs.calibrate-clean.sequence.description',
          )}
        </DialogContentText>
        <RadioGroup
          options={[
            {
              value: Sequence.cleanFirst,
              title: t(
                'clean-calibrate.yield-popup.tabs.calibrate-clean.sequence.clean-calibrate',
              ),
            },
            {
              value: Sequence.calibrateFirst,
              title: t(
                'clean-calibrate.yield-popup.tabs.calibrate-clean.sequence.calibrate-clean',
              ),
            },
          ]}
          value={sequence}
          onChange={handleSequenceChange}
        />
      </DialogContent>
    </>
  );
}
