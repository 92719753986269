import React, { Suspense, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import AuthenticationRedirector from '../router/redirectors/Authentication';
import UserDataRedirector from '../router/redirectors/UserData';
import PricingRedirector from '../router/redirectors/Pricing';
import BackdropLoading from '../components/Loading/BackdropLoading';
import Loading from '../components/Loading';
import NotFound from '../components/NotFound';
import Link from '../components/Link';
import FieldTitle from '../features/field/containers/FieldTitle';
import ToolsPanel from '../features/ui/zonesMap/containers/ToolsPanel';
import Panel from '../features/ui/zonesMap/containers/Panel';
import { reset, subscription } from '../features/ui/zonesMap/zonesMapSlice';
import { getDocumentTitle } from '../helpers';
import { getFieldLink, getRootLink, PAGES_ROOTS } from '../helpers/navigation';
import BaseTemplate from './BaseTemplate';
import { selectArea, selectName } from '../features/field/fieldSelectors';
import { fetchAllAssets } from '../features/field/fieldSlice';
import {
  selectBackTo,
  selectLoadStatus,
} from '../features/ui/zonesMap/zonesMapSelectors';
import { resetOverlayLayers } from '../features/ui/overlayLayers/overlayLayersSlice';
import useVamap from '../features/field/hooks/useVamap';
import useSubscription from '../features/subscription/hooks/useSubscription';
import { selectIsLoaded } from '../features/user/userSelectors';
import LoadStatus from '../helpers/constants/utils/loadStatus';

const Map = React.lazy(() => import('../features/ui/zonesMap/containers/Map'));

const ZonesMap = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { farmUuid, fieldUuid, uuid } = useParams();
  const history = useHistory();
  const userLoaded = useSelector(selectIsLoaded);
  const fieldName = useSelector(selectName);
  const fieldArea = useSelector(selectArea);
  const backTo = useSelector(selectBackTo);
  const loadStatus = useSelector(selectLoadStatus);
  const { vamap } = useVamap(farmUuid, fieldUuid, uuid);
  const vamapName = vamap?.name;

  useEffect(() => {
    document.title = getDocumentTitle(vamapName);
  }, [vamapName]);

  useEffect(() => {
    if (!userLoaded) {
      return () => {};
    }

    dispatch(
      fetchAllAssets({
        farmUuid,
        fieldUuid,
      }),
    );

    return () => {
      dispatch(reset());
      dispatch(resetOverlayLayers());
    };
  }, [dispatch, farmUuid, fieldUuid, userLoaded]);

  const useSubscriptionCallback = useCallback(
    (parsedEvent) => {
      dispatch(subscription(parsedEvent, uuid));
    },
    [dispatch, uuid],
  );
  useSubscription(useSubscriptionCallback);

  const onBackButtonClick = () => {
    if (backTo === 'field') {
      history.push(getFieldLink(farmUuid, fieldUuid));
    } else if (backTo === 'zonesMaps') {
      history.push(getRootLink(PAGES_ROOTS.zonesMaps));
    }
  };

  let content = [
    <ToolsPanel key="tools-panel" />,
    <Panel key="panel" />,
    <Suspense key="map" fallback={<Loading />}>
      <Map />
    </Suspense>,
  ];
  let headerProps = {
    text: vamapName,
    secondaryText: (
      <FieldTitle
        fieldArea={fieldArea}
        fieldName={
          <Link to={getFieldLink(farmUuid, fieldUuid)}>{fieldName}</Link>
        }
      />
    ),
    onBackButtonClick,
  };

  if (loadStatus === LoadStatus.error) {
    headerProps = {};
    content = [
      <NotFound
        key="panel"
        description={t('zones-map.not-found-description')}
        link={
          <Link to={getRootLink(PAGES_ROOTS.zonesMaps)}>
            {t('zones-map.not-found-link')}
          </Link>
        }
      />,
    ];
  }

  return (
    <AuthenticationRedirector>
      <UserDataRedirector>
        <PricingRedirector>
          <BaseTemplate headerProps={headerProps}>
            {content}
            {loadStatus === LoadStatus.loading && (
              <BackdropLoading key="loading" />
            )}
          </BaseTemplate>
        </PricingRedirector>
      </UserDataRedirector>
    </AuthenticationRedirector>
  );
};

export default ZonesMap;
