import i18n from 'i18next';

import ZonesOpsWorkflow from '../../../zonesOps/helpers/constants/zonesOpsWorkflow';
import ZonesMap from '../../containers/Stepper/Steps/ZonesMap';
import getCommonSteps from '../../../zonesOps/helpers/functions/getCommonSteps';
import getCreateSingleLayerAnalysisSteps from '../../../createSingleLayerAnalysis/helpers/functions/getSteps';
import getCreateFieldPotentialZonesSteps from '../../../createFieldPotentialZones/helpers/functions/getSteps';
import getCreateStabilityZonesSteps from '../../../createStabilityZones/helpers/functions/getSteps';
import getCrossLayerSteps from '../../../crossLayer/helpers/functions/getSteps';
import getCreateMultiLayerAnalysisSteps from '../../../createMultiLayerAnalysis/helpers/functions/getSteps';
import getDrawManuallySteps from '../../../drawManuallyWorkflow/helpers/functions/getSteps';
import { Step } from '../../../../../components/Stepper';
import { AssetType } from '../../../../../helpers/constants/entities/asset';

const getSteps =
  (workflow: ZonesOpsWorkflow, singleLayerAssetType: AssetType) =>
  (withFieldSelection = false) => {
    let firstSteps: Step[] = [];

    if (withFieldSelection) {
      firstSteps = getCommonSteps();
    }
    if (withFieldSelection) {
      firstSteps = getCommonSteps();
    }

    const baseSteps = [
      ...firstSteps,
      {
        name: i18n.t('general.shared.zones-map'),
        description: i18n.t('zones-ops.clone-zones-map.steps.3.description'),
        id: 'zonesMap',
        component: ZonesMap,
      },
    ];

    if (workflow === ZonesOpsWorkflow.createSingleLayerAnalysis) {
      return [
        ...baseSteps,
        ...getCreateSingleLayerAnalysisSteps(singleLayerAssetType)(),
      ];
    }

    if (workflow === ZonesOpsWorkflow.createFieldPotentialZones) {
      return [...baseSteps, ...getCreateFieldPotentialZonesSteps()];
    }

    if (workflow === ZonesOpsWorkflow.createStabilityZones) {
      return [...baseSteps, ...getCreateStabilityZonesSteps()];
    }

    if (workflow === ZonesOpsWorkflow.crossLayer) {
      return [...baseSteps, ...getCrossLayerSteps()];
    }

    if (workflow === ZonesOpsWorkflow.createMultiLayerAnalysis) {
      return [...baseSteps, ...getCreateMultiLayerAnalysisSteps()];
    }

    if (workflow === ZonesOpsWorkflow.drawManually) {
      return [...baseSteps, ...getDrawManuallySteps()];
    }

    return baseSteps;
  };

export default getSteps;
