mutation exportToJohnDeereAsApplicationWorkPlan(
  $input: ExportToJohnDeereAsApplicationWorkPlanInput!
) {
  exportToJohnDeereAsApplicationWorkPlan(input: $input) {
    details {
      id
      name
    }
    status
  }
}
