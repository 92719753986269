import React, { MouseEvent, useMemo } from 'react';

import CheckboxItem from '../../../TreeView/CheckboxItem';
import SatelliteItem from '../../../Items/SatelliteItem';
import SimpleItem from '../../../TreeView/SimpleItem';
import ItemActions from '../../../ItemActions';
import { getSatelliteImageActions } from '../../../../helpers/functions/dataLayersTreeNodesActions';
import type {
  Mode,
  DataLayersTreeNode,
} from '../../../../types/dataLayersTree';
import { AssetGroupType } from '../../../../../../../helpers/constants/entities/asset';
import { TransformedSatelliteImage } from '../../../../../../satelliteImages/types/satelliteImage';

export default function SatelliteImageNode({
  id,
  image,
  mode,
  checked,
  expanded,
  treeNodeGetter,
  jdProfileIsHealth,
  jdProfileIsAuthorized,
  isSynchronizedJohnDeereField,
  showItemMenu,
  selectedItemUuid,
  selectedItemGroupType,
  withAttributes,
  offset,
  onCheckboxClick,
  onMenuItemClick,
  onExpandMoreClick,
  onClick,
}: {
  id: string;
  image: TransformedSatelliteImage;
  mode: Mode;
  checked: Record<string, number>;
  expanded: Record<string, boolean>;
  treeNodeGetter: () => DataLayersTreeNode | null;
  jdProfileIsHealth: boolean;
  jdProfileIsAuthorized: boolean;
  isSynchronizedJohnDeereField: boolean;
  showItemMenu: boolean;
  offset: number;
  selectedItemUuid?: string;
  withAttributes?: boolean;
  selectedItemGroupType?: string | null;
  onCheckboxClick: (
    v: Record<string, number>,
    item: DataLayersTreeNode,
  ) => void;
  onMenuItemClick: (v: string) => void;
  onExpandMoreClick?: (e: MouseEvent) => void;
  onClick: () => void;
}) {
  let result;
  const menuItems = useMemo(
    () =>
      getSatelliteImageActions(image, {
        jdProfileIsHealth,
        jdProfileIsAuthorized,
        isSynchronizedJohnDeereField,
      }),
    [
      image,
      jdProfileIsHealth,
      jdProfileIsAuthorized,
      isSynchronizedJohnDeereField,
    ],
  );
  const item = (
    <>
      <SatelliteItem image={image} />
      {showItemMenu && (
        <ItemActions menuItems={menuItems} onMenuItemClick={onMenuItemClick} />
      )}
    </>
  );
  const isItemSelected =
    image.uuid === selectedItemUuid &&
    selectedItemGroupType === AssetGroupType.satelliteImages;

  if (mode === 'multiSelect') {
    result = (
      <CheckboxItem
        itemId={id}
        treeNodeGetter={treeNodeGetter}
        checked={checked}
        isExpanded={expanded[id]}
        isSelected={isItemSelected}
        isParent={withAttributes}
        offset={offset}
        onCheckboxClick={onCheckboxClick}
        onExpandMoreClick={onExpandMoreClick}
        onClick={onClick}
      >
        {item}
      </CheckboxItem>
    );
  } else {
    result = (
      <SimpleItem isSelected={isItemSelected} offset={offset} onClick={onClick}>
        {item}
      </SimpleItem>
    );
  }

  return result;
}
