import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';

import Button from '../../../../../components/Button';
import VariablesListItem from '../VariablesListItem';
import VariableMenuAction from '../../helpers/constants/variableMenuAction';
import { VariableItem } from '../../types/variable';
import { ButtonMenuItem } from '../../../../../components/ButtonMenu/types';

import './index.scss';

const VariablesList = ({
  title = '',
  items,
  deleteVariable,
  manageVariable,
  onUseInterpolatedDataChange,
}: {
  title: string;
  items: VariableItem[];
  deleteVariable: (variable: string) => void;
  manageVariable: (variable?: string) => void;
  onUseInterpolatedDataChange: (variable: string, value: boolean) => void;
}) => {
  const { t } = useTranslation();

  const onMenuItemClick = (type: string, variable: string) => {
    if (type === VariableMenuAction.changeVariable) {
      manageVariable(variable);
    } else if (type === VariableMenuAction.deleteVariable) {
      deleteVariable(variable);
    }
  };

  return (
    <div className="variables-list">
      <Typography className="variables-list__title" variant="body2">
        {title}
      </Typography>
      <div className="variables-list__wrapper">
        <Button
          variant="text"
          color="primary"
          className="variables-list__add-button"
          onClick={() => manageVariable()}
        >
          <AddOutlinedIcon />
          {t(
            'zones-ops.equation-based.steps.4.panel.variables-list.add-new-variable',
          )}
        </Button>
        <div className="variables-list__items">
          {items.map((item, index) => (
            <VariablesListItem
              key={index}
              item={item}
              onMenuItemClick={(menuItem: ButtonMenuItem) =>
                onMenuItemClick(menuItem.id, item.name)
              }
              manageVariable={manageVariable}
              onUseInterpolatedDataChange={(value: boolean) =>
                onUseInterpolatedDataChange(item.name, value)
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default VariablesList;
