import React, { useMemo, useState } from 'react';

import useDataLayersTree from '../../../../dataLayersView/hooks/useDataLayersTree';
import { AssignableAsset, AssignableAssets } from '../../../types/variables';
import { TransformedAsset } from '../../../../../../helpers/types';
import { AssetGroupType } from '../../../../../../helpers/constants/entities/asset';
import SelectAttributePanel from '../../../../dataLayersView/components/DataLayersTree/Panels/SelectAttributePanel';
import { getSortedValueOptionsArray } from '../../../../../../helpers/functions/components/uiList';
import {
  extractDatasetGeoMapAttributes,
  getDatasetAttributeNameHandler,
} from '../../../../../../helpers/functions/entities/dataset';
import {
  isDataset,
  isTopographyMap,
  isVectorAnalysis,
} from '../../../../../../helpers/functions/entities/assets';
import { extractTopographyMapGeoMapAttributes } from '../../../../../../helpers/functions/entities/topographyMap';
import { DataLayersTreeNode } from '../../../../dataLayersView/types/dataLayersTree';
import {
  getAssetGroupCheckedLayers,
  getSelectedAsset,
} from '../../../../createEquationBasedAnalysis/helpers/functions/variables';
import { getVamapVariableZoneTypeAttributes } from '../../../../dataVariable/helpers/functions/dataVariableAttribute';
import { EQUATION_BASED_ANALYSIS_SATELLITE_INDEXES } from '../../../../dataVariable/helpers/constants/dataVariableAttribute';

import './index.scss';

export default function AssignVariablePopupPanel({
  assets,
  selectedAsset,
  selectedGroupType,
  selectedSatelliteImageUuids,
  satellitesLoading,
  onAssetItemClick,
  onSatelliteImageCheck,
  selectedAttribute = '',
  onAttributeChange,
}: {
  assets: AssignableAssets;
  selectedAsset: AssignableAsset | null;
  selectedGroupType: AssetGroupType | null;
  selectedSatelliteImageUuids: string[] | null;
  satellitesLoading: boolean;
  onAssetItemClick: ({
    groupType,
    asset,
  }: {
    groupType: AssetGroupType;
    asset: AssignableAsset;
  }) => void;
  onSatelliteImageCheck: ({
    checkedImages,
    asset,
    groupType,
  }: {
    checkedImages: string[];
    asset: AssignableAsset | null;
    groupType: AssetGroupType;
  }) => void;
  selectedAttribute: string;
  onAttributeChange: (a: string) => void;
}) {
  const [checkedLayers, setCheckedLayers] = useState(
    getAssetGroupCheckedLayers({
      checkedUuids: selectedSatelliteImageUuids,
      groupType: AssetGroupType.satelliteImages,
    }),
  );

  const attributesOptions = useMemo(() => {
    let result: { value: string; title: string }[] = [];

    if (selectedAsset && isDataset(selectedAsset)) {
      result = getSortedValueOptionsArray(
        extractDatasetGeoMapAttributes(selectedAsset),
        getDatasetAttributeNameHandler(selectedAsset.fullAttributes),
      );
    } else if (selectedAsset && isTopographyMap(selectedAsset)) {
      result = getSortedValueOptionsArray(
        extractTopographyMapGeoMapAttributes(selectedAsset),
        getDatasetAttributeNameHandler(selectedAsset.fullAttributes),
      );
    } else if (selectedAsset && isVectorAnalysis(selectedAsset)) {
      result = getVamapVariableZoneTypeAttributes();
    } else if (selectedSatelliteImageUuids) {
      result = EQUATION_BASED_ANALYSIS_SATELLITE_INDEXES;
    }

    return result;
  }, [selectedAsset, selectedSatelliteImageUuids]);

  const handleAssetNodeClick = (
    groupType: AssetGroupType,
    item: TransformedAsset,
  ) => {
    if (groupType !== AssetGroupType.satelliteImages) {
      setCheckedLayers({});
    }

    onAssetItemClick({
      groupType,
      asset: item as AssignableAsset,
    });
  };

  const handleCheckedLayersChange = (
    checked: Record<string, number>,
    item?: DataLayersTreeNode,
  ) => {
    const satelliteImagesGroupNode = dataLayersTree.find(
      ({ id }) => id === AssetGroupType.satelliteImages,
    );
    const satelliteImagesNodes = satelliteImagesGroupNode?.children ?? [];
    const checkedLayersUuids = satelliteImagesNodes.reduce<string[]>(
      (acc, node) =>
        node.uuid && checked[node.id] === 2 ? [...acc, node.uuid] : acc,
      [],
    );

    let asset = null;

    if (item?.uuid && checkedLayersUuids.includes(item.uuid)) {
      asset = getSelectedAsset(assets.satelliteImages ?? [], item.uuid);
    }

    setCheckedLayers(checked);
    onSatelliteImageCheck({
      asset: asset as AssignableAsset | null,
      checkedImages: checkedLayersUuids,
      groupType: AssetGroupType.satelliteImages,
    });
  };

  const dataLayersTreeChildren = [];

  if (selectedGroupType !== AssetGroupType.equationMaps) {
    dataLayersTreeChildren.push(
      <SelectAttributePanel
        key="bottom-panel"
        attributes={attributesOptions}
        selectedAttribute={selectedAttribute}
        onAttributeChange={onAttributeChange}
      />,
    );
  }

  const { dataLayersTreeComponent, dataLayersTree } = useDataLayersTree({
    assets,
    satelliteImagesLoading: satellitesLoading,
    mode: {
      [AssetGroupType.satelliteImages]: 'multiSelect',
    },
    collapsible: true,
    checked: checkedLayers,
    hasGroupPlaceholderAction: false,
    showSatelliteRecommendations: true,
    selectedAssetUuid: selectedAsset?.uuid,
    selectedAssetGroupType: selectedGroupType || undefined,
    onAssetNodeClick: handleAssetNodeClick,
    onCheckedChange: handleCheckedLayersChange,
    children: dataLayersTreeChildren,
  });

  return (
    <div className="assign-variable-popup-panel">{dataLayersTreeComponent}</div>
  );
}
