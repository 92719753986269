import React from 'react';

import './index.scss';

function TablePanel({ elementsLeft = [], elementsRight = [], classes = {} }) {
  const rootClass = classes.root || '';

  return (
    <div className={`table-panel ${rootClass}`}>
      <div className="table-panel__elements-left">{elementsLeft}</div>
      <div className="table-panel__elements-right">{elementsRight}</div>
    </div>
  );
}

export default TablePanel;
