import { Product, ZoneData } from '../../types/product';

/**
 * Calculates progressive values with a specified difference between zones
 */
const calculateProgressiveValues = (
  zoneCount: number,
  targetAvg: number,
  zoneDifference: number,
  shouldReverse: boolean,
): number[] => {
  const progressionFactor = 1 + zoneDifference / 100;
  const firstValue = (2 * targetAvg) / (1 + progressionFactor);

  // Create an array of values with progressive increase
  const values: number[] = [];
  for (let i = 0; i < zoneCount; i += 1) {
    const increaseFactor = 1 + (zoneDifference / 100) * i;
    values.push(parseFloat((firstValue * increaseFactor).toFixed(1)));
  }

  // Apply reverse order if specified
  return shouldReverse ? [...values].reverse() : values;
};

/**
 * Adjusts calculated values to precisely match the target average or total
 */
const adjustValuesToMatchTarget = (
  values: number[],
  zonesData: ZoneData[],
  targetValue: number,
  distributionMethod: 'total' | 'average',
  totalAmount: number,
): number[] => {
  // Calculate current total application amount
  const calculatedTotal = values.reduce(
    (sum, value, idx) => sum + value * zonesData[idx].area,
    0,
  );

  const totalArea = zonesData.reduce((sum, zone) => sum + zone.area, 0);

  // For total method, compare calculated total to target total amount
  // For average method, compare calculated average to target average
  const comparisonValue =
    distributionMethod === 'total'
      ? calculatedTotal
      : calculatedTotal / totalArea;

  const targetComparisonValue =
    distributionMethod === 'total' ? totalAmount : targetValue;

  // If values don't match target, apply correction
  if (Math.abs(comparisonValue - targetComparisonValue) > 0.01) {
    const correctionFactor = targetComparisonValue / comparisonValue;
    return values.map((value) =>
      parseFloat((value * correctionFactor).toFixed(1)),
    );
  }

  return values;
};

/**
 * Applies min/max constraints to values and zeroes values where threshold is reached
 */
const applyConstraints = (
  values: number[],
  minValue: number,
  maxValue: number,
  threshold?: number,
): number[] =>
  values.map((val) => {
    // If threshold exists and is exceeded, return 0
    if (threshold && threshold > 0 && val >= threshold) {
      return 0;
    }

    let constrainedVal = val;

    // Apply min constraint if provided
    if (minValue > 0) {
      constrainedVal = Math.max(minValue, constrainedVal);
    }

    // Apply max constraint if provided
    if (maxValue > 0) {
      constrainedVal = Math.min(maxValue, constrainedVal);
    }

    return constrainedVal;
  });

// eslint-disable-next-line import/prefer-default-export
export const calculateDistribution = (
  zonesData: ZoneData[],
  product: Product,
  ratesPerProduct: Record<number, string[]>,
  productIndex: number,
): string[] => {
  const {
    distributionMethod,
    totalAmount: totalAmountStr,
    averageRate: averageRateStr,
    zoneDifference,
    relationshipType,
    minValue: minValueStr,
    maxValue: maxValueStr,
    threshold: thresholdStr,
  } = product;

  if (zonesData.length === 0) {
    return [];
  }

  if (
    (distributionMethod === 'total' && totalAmountStr === '') ||
    (distributionMethod === 'average' && averageRateStr === '')
  ) {
    return Array(zonesData.length).fill('');
  }

  // Convert string values to numbers
  const totalAmount = totalAmountStr ? parseFloat(totalAmountStr) : 0;
  const averageRate = averageRateStr ? parseFloat(averageRateStr) : 0;
  const minValue = minValueStr ? parseFloat(minValueStr) : 0;
  const maxValue = maxValueStr ? parseFloat(maxValueStr) : 0;
  const threshold = thresholdStr ? parseFloat(thresholdStr) : 0;

  // Calculate total zones area
  const totalArea = zonesData.reduce((sum, zone) => sum + zone.area, 0);

  // Initialize new values array
  let newValues: number[] = Array(zonesData.length).fill(0);

  // Get existing values if available and convert to numbers
  const existingValues: number[] = zonesData.map((_zone, index) => {
    const existingRate = ratesPerProduct[productIndex]?.[index] || '';
    return existingRate ? parseFloat(existingRate) : 0;
  });

  // Calculate distribution based on method
  if (
    (distributionMethod === 'total' && totalAmount > 0) ||
    (distributionMethod === 'average' && averageRate > 0)
  ) {
    // Determine target value based on distribution method
    const targetValue =
      distributionMethod === 'total'
        ? totalAmount / totalArea // Target average for 'total' method
        : averageRate; // Direct target average for 'average' method

    // Calculate progression values
    newValues = calculateProgressiveValues(
      zonesData.length,
      targetValue,
      zoneDifference,
      relationshipType === 'reverse',
    );

    // Adjust values to match target precisely
    newValues = adjustValuesToMatchTarget(
      newValues,
      zonesData,
      targetValue,
      distributionMethod,
      totalAmount,
    );
  }

  // If no base calculation done (empty strings or zero values), use existing values
  else if (existingValues.some((val) => val > 0)) {
    newValues = [...existingValues];
  }
  // If nothing is set yet, use a default distribution
  else {
    newValues = zonesData.map(() => 1);
  }

  // Apply min/max constraints and threshold to all values if provided
  if (minValue > 0 || maxValue > 0 || threshold > 0) {
    newValues = applyConstraints(newValues, minValue, maxValue, threshold);
  }

  // Convert the numeric values to strings
  return newValues.map((value) => value.toString());
};
