import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { errorNotify } from '../notifications/helpers/functions/notify';
import { getAll, add, update, remove } from './colorSchemasAPI';
import { prepareColors, transform } from './helpers/functions/colorSchemas';
import { CustomError } from '../../helpers/functions/utils/errorHandling';

const initialState = {
  isLoaded: false,
  schemas: [],
};

export const getColorSchemas = createAsyncThunk(
  'colorSchemas/getColorSchemas',
  () => getAll(),
);

export const addSchema = createAsyncThunk(
  'colorSchemas/addSchema',
  async ({ title, colors }, { dispatch }) => {
    const schemaToAdd = {
      title,
      colors: prepareColors(colors),
    };

    let result = null;

    try {
      const { uuid } = await add(schemaToAdd);

      result = {
        uuid,
        title,
        colors,
      };
    } catch (error) {
      errorNotify({
        error: new CustomError('Unable to add color schema.', {
          cause: error,
        }),
        dispatch,
      });
    }

    return result;
  },
);

export const updateSchema = createAsyncThunk(
  'colorSchemas/updateSchema',
  async (schema, { dispatch }) => {
    const schemaToUpdate = {
      title: schema.title,
      uuid: schema.uuid,
      colors: prepareColors(schema.colors),
    };

    let result = schema;

    try {
      await update(schemaToUpdate);
    } catch (error) {
      result = null;
      errorNotify({
        error: new CustomError('Unable to save color schema.', {
          cause: error,
        }),
        dispatch,
      });
    }

    return result;
  },
);

export const deleteSchema = createAsyncThunk(
  'colorSchemas/deleteSchema',
  async (uuid, { dispatch }) => {
    let result = uuid;

    try {
      await remove(uuid);
    } catch (error) {
      result = null;
      errorNotify({
        error: new CustomError('Unable to delete color schema.', {
          cause: error,
        }),
        dispatch,
      });
    }

    return result;
  },
);

export const colorSchemasSlice = createSlice({
  name: 'colorSchemas',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getColorSchemas.pending, (state) => {
        state.isLoaded = false;
      })
      .addCase(getColorSchemas.fulfilled, (state, action) => {
        state.isLoaded = true;
        state.schemas = transform(action.payload);
      })
      .addCase(addSchema.pending, (state) => {
        state.isLoaded = false;
      })
      .addCase(addSchema.fulfilled, (state, action) => {
        if (!action.payload) {
          return state;
        }

        state.isLoaded = true;
        state.schemas.push(action.payload);
      })
      .addCase(updateSchema.pending, (state) => {
        state.isLoaded = false;
      })
      .addCase(updateSchema.fulfilled, (state, action) => {
        if (!action.payload) {
          return state;
        }

        state.isLoaded = true;
        state.schemas = state.schemas.map((schema) => {
          if (schema.uuid === action.payload.uuid) {
            return {
              ...schema,
              title: action.payload.title,
              colors: action.payload.colors,
            };
          }

          return schema;
        });
      })
      .addCase(deleteSchema.pending, (state) => {
        state.isLoaded = false;
      })
      .addCase(deleteSchema.fulfilled, (state, action) => {
        if (!action.payload) {
          return state;
        }

        state.isLoaded = true;
        state.schemas = state.schemas.filter(
          (schema) => schema.uuid !== action.payload,
        );
      });
  },
});

export default colorSchemasSlice.reducer;
