import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Auth } from '@aws-amplify/auth';
import { setUser as setSentryUser } from '@sentry/react';
import i18n from 'i18next';

import { AreaUnit, Language } from './helpers/constants/user';
import {
  saveUserData,
  getCombinedUser,
  saveOrganization as saveOrganizationAPI,
  getUserTotalArea as getUserTotalAreaAPI,
  saveStripeCustomer,
  getOrdersAndAdditionalUserData as getOrdersAndAdditionalUserDataAPI,
} from './userAPI';
import { selectIdentity } from './userSelectors';
import Amplitude from '../../helpers/classes/amplitude';
import { CustomError } from '../../helpers/functions/utils/errorHandling';
import { errorNotify } from '../notifications/helpers/functions/notify';

const initialState = {
  isLoaded: false,
  data: {
    uuid: '',
    email: '',
    language: Language.English,
    areaUnit: AreaUnit.acres,
    totalArea: 0,
    maxArea: 0,
    orders: [],
    identity: null,
    country: null,
    companyType: null,
    // TODO: replace with 'simpler' data for application bootstrap, all data move to separate reducer
    organizations: [],
    acceptedTermsAndConditions: false,
    apiKey: '',
    reachedAreaLimit: false,
    phoneNumber: '',
    stripeCustomerId: '',
  },
  cognitoGroups: [],
};

export const signOut = createAction('user/signOut');

// `identity` needed when user uploads files
export const setupUserIdentity = createAsyncThunk(
  'user/setupUserIdentity',
  async () => {
    // TODO in case of error need to configure application to prevent upload until `identity` setted up correctly
    const userInfo = await Auth.currentUserInfo();

    return saveUserData({
      identity: userInfo.id,
    }).then(({ identity }) => identity);
  },
  {
    condition: (_, { getState }) => {
      if (selectIdentity(getState())) {
        return false;
      }
    },
  },
);

export const getUserData = createAsyncThunk('user/getData', async () => {
  const [userData, { signInUserSession }] = await Promise.all([
    getCombinedUser(),
    Auth.currentAuthenticatedUser(),
  ]);
  const cognitoGroups = signInUserSession.accessToken.payload['cognito:groups'];
  const userProfileData = {
    id: userData.getUserData.uuid,
    email: userData.getUserData.email,
  };

  setSentryUser(userProfileData);
  Amplitude.initUser(userProfileData);

  return {
    cognitoGroups,
    ...userData.getUserData,
    phoneNumber: userData.getStripeCustomer?.phone ?? '',
    stripeCustomerId: userData.getStripeCustomer?.id ?? '',
  };
});

export const getOrdersAndAdditionalUserData = createAsyncThunk(
  'user/getOrdersAndAdditionalUserData',
  async ({ stripeCustomerId }, { dispatch }) => {
    try {
      const result = await getOrdersAndAdditionalUserDataAPI({
        stripeCustomerId,
      });

      return {
        orders: result.getUserData?.orders,
        packages: result.getUserData?.packages,
        acceptedTermsAndConditions:
          result.getUserData?.acceptedTermsAndConditions,
        country: result.getUserData?.country,
        companyType: result.getUserData?.companyType,
        maxArea: result.getUserData?.maxArea,
        areaUnit: result.getUserData?.areaUnit,
        reachedAreaLimit: result.getUserData?.reachedAreaLimit,
        stripeCustomerId: result.getStripeCustomer?.id,
        phoneNumber: result.getStripeCustomer?.phone,
      };
    } catch (error) {
      errorNotify({
        error: new CustomError('[User] getOrdersAndAdditionalUserData', {
          cause: error,
        }),
        message: i18n.t('welcome-screen.notifications.error'),
        dispatch,
      });

      throw error;
    }
  },
);

export const updateData = createAsyncThunk('user/updateData', (updates) =>
  saveUserData(updates),
);

export const updateAdditionalUserData = createAsyncThunk(
  'user/updateAdditionalUserData',
  async (updates, { rejectWithValue }) => {
    const { phoneNumber } = updates;

    try {
      return await Promise.all([
        saveUserData(updates),
        saveStripeCustomer({ phoneNumber }),
      ]);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const saveOrganization = createAsyncThunk(
  'user/saveOrganization',
  ({ uuid, name, surname, givenName, phone, email }) =>
    saveOrganizationAPI({
      uuid,
      name,
      surname,
      givenName,
      phone,
      email,
    }),
);

export const getUserTotalArea = createAsyncThunk(
  'user/getUserTotalArea',
  async () => {
    const userData = await getUserTotalAreaAPI();

    return userData.totalArea;
  },
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateOrganizationUser(state, action) {
      state.data.organizations = state.data.organizations.map(
        (organization) => {
          if (organization.uuid === action.payload.organizationUuid) {
            return {
              ...organization,
              users: organization.users.map((user) => {
                if (user.userUuid === action.payload.userUuid) {
                  return {
                    ...user,
                    allFarms: action.payload.allFarms,
                  };
                }

                return user;
              }),
            };
          }

          return organization;
        },
      );
    },
    addUsersToOrganization(state, action) {
      state.data.organizations = state.data.organizations.map(
        (organization) => {
          if (organization.uuid === action.payload.organizationUuid) {
            return {
              ...organization,
              users: [...organization.users, ...action.payload.users],
            };
          }

          return organization;
        },
      );
    },
    deleteUsersFromOrganization(state, action) {
      state.data.organizations = state.data.organizations.map(
        (organization) => {
          if (organization.uuid === action.payload.organizationUuid) {
            return {
              ...organization,
              users: organization.users.filter(
                ({ userUuid }) => !action.payload.usersUuids.includes(userUuid),
              ),
            };
          }

          return organization;
        },
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setupUserIdentity.fulfilled, (state, action) => {
        state.data.identity = action.payload;
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        state.isLoaded = true;
        state.cognitoGroups = action.payload.cognitoGroups;
        state.data = {
          uuid: action.payload.uuid,
          email: action.payload.email,
          language: action.payload.language,
          areaUnit: action.payload.areaUnit,
          identity: action.payload.identity,
          totalArea: action.payload.totalArea,
          maxArea: action.payload.maxArea,
          organizations: action.payload.organizations,
          acceptedTermsAndConditions: action.payload.acceptedTermsAndConditions,
          apiKey: action.payload.apiKey,
          reachedAreaLimit: action.payload.reachedAreaLimit,
          country: action.payload.country,
          companyType: action.payload.companyType,
        };
      })
      .addCase(getOrdersAndAdditionalUserData.fulfilled, (state, action) => {
        state.data.acceptedTermsAndConditions =
          action.payload.acceptedTermsAndConditions;
        state.data.country = action.payload.country;
        state.data.companyType = action.payload.companyType;
        state.data.maxArea = action.payload.maxArea;
        state.data.areaUnit = action.payload.areaUnit;
        state.data.reachedAreaLimit = action.payload.reachedAreaLimit;
        state.data.stripeCustomerId = action.payload.stripeCustomerId;
        state.data.phoneNumber = action.payload.phoneNumber;
      })
      .addCase(saveOrganization.fulfilled, (state, action) => {
        const index = state.data.organizations.findIndex(
          ({ uuid }) => action.payload.uuid === uuid,
        );

        if (index === -1) {
          state.data.organizations = [
            {
              uuid: action.payload.uuid,
              name: action.payload.name,
              surname: action.payload.surname,
              givenName: action.payload.givenName,
              phone: action.payload.phone,
              email: action.payload.email,
              users: [],
            },
            ...state.data.organizations,
          ];
        } else {
          state.data.organizations[index] = {
            ...state.data.organizations[index],
            uuid: action.payload.uuid,
            name: action.payload.name,
            surname: action.payload.surname,
            givenName: action.payload.givenName,
            phone: action.payload.phone,
            email: action.payload.email,
          };
        }
      })
      .addCase(getUserTotalArea.fulfilled, (state, action) => {
        state.data.totalArea = action.payload;
      })
      .addCase(updateData.fulfilled, (state, action) => {
        const { language, areaUnit, totalArea, maxArea } = action.payload;

        state.data.language = language;
        state.data.areaUnit = areaUnit;
        state.data.totalArea = totalArea;
        state.data.maxArea = maxArea;
      })
      .addCase(updateAdditionalUserData.fulfilled, (state, action) => {
        const { country, companyType, email, acceptedTermsAndConditions } =
          action.payload[0];
        const { id, phone, currency } = action.payload[1];

        state.data.country = country;
        state.data.companyType = companyType;
        state.data.acceptedTermsAndConditions = acceptedTermsAndConditions;
        state.data.phoneNumber = phone;
        state.data.email = email;
        state.data.stripeCustomerId = id;
        state.data.currency = currency;
      })
      .addMatcher(
        ({ type }) =>
          [
            getUserData.fulfilled.type,
            getOrdersAndAdditionalUserData.fulfilled.type,
          ].includes(type),
        (state, action) => {
          const packagesByOrderUuid = new Map(
            action.payload.packages.map((pckg) => [pckg.orderUuid, pckg]),
          );

          const ordersWithPackageInfo = action.payload.orders.map((order) => ({
            ...order,
            packageInfo: packagesByOrderUuid.get(order.uuid),
          }));

          state.data.orders = ordersWithPackageInfo;
          state.data.stripeCustomerId = action.payload.stripeCustomerId;
          state.data.phoneNumber = action.payload.phoneNumber;
        },
      );
  },
});

export const {
  updateOrganizationUser,
  addUsersToOrganization,
  deleteUsersFromOrganization,
} = userSlice.actions;

export default userSlice.reducer;
