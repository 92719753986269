import React from 'react';

import ProductSettings from './ProductSettings';
import ZonesTable from './ZonesTable';

import './index.scss';

export default function ProductTab() {
  return (
    <div className="zones-map-product-tab">
      <ProductSettings />
      <ZonesTable />
    </div>
  );
}
