import React from 'react';
import { connect } from 'react-redux';

import ScrollContainer from '../../../../../../components/ScrollContainer';
import SelectList from '../../../../../../components/SelectList';
import ZonesMapItem from '../../../../dataLayersView/components/Items/ZonesMapItem';
import { setZonesMapUuid } from '../../../createThreeDMapSlice';
import { selectZonesMapUuid } from '../../../createThreeDMapSelectors';
import { selectVectorAnalysisMaps } from '../../../../../field/fieldSelectors';

import './index.scss';

const ZonesMapPanel = ({ items, selectedUuids, onItemClick }) => (
  <ScrollContainer classes={{ root: 'zones-map-panel-create-3d' }}>
    <SelectList
      multiValue={false}
      items={items}
      selectedUuids={selectedUuids}
      itemRenderer={(item) => <ZonesMapItem zonesMap={item} />}
      onClick={onItemClick}
    />
  </ScrollContainer>
);

const mapStateToProps = (state) => {
  const vectorAnalysisMaps = selectVectorAnalysisMaps(state);
  const zonesMap = selectZonesMapUuid(state);

  return {
    items: vectorAnalysisMaps,
    selectedUuids: [zonesMap],
  };
};

const mapDispatchToProps = (dispatch) => ({
  onItemClick: ({ uuid }) => {
    dispatch(setZonesMapUuid(uuid));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ZonesMapPanel);
