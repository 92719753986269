import { Product, ZoneData, SavingsComparison } from '../../types/product';

/**
 * Calculate savings compared to uniform application
 */
// eslint-disable-next-line import/prefer-default-export
export const calculateSavings = (
  zones: ZoneData[],
  product: Product,
  ratesPerProduct: Record<number, string[]>,
  productIndex: number,
  savingsComparison: SavingsComparison,
) => {
  if (zones.length === 0) {
    return { amountSaved: 0, percentageSaved: 0, moneySaved: 0 };
  }

  const totalArea = zones.reduce((sum, zone) => sum + zone.area, 0);
  const variableRateTotal = zones.reduce((sum, zone, index) => {
    const rateStr = ratesPerProduct[productIndex]?.[index] || '';
    const rate = rateStr ? parseFloat(rateStr) : 0;
    return sum + rate * zone.area;
  }, 0);

  let uniformRate = 0;

  if (savingsComparison === 'highest') {
    // Use highest zone rate for comparison
    uniformRate = Math.max(
      ...(ratesPerProduct[productIndex] || []).map((rateStr) =>
        rateStr ? parseFloat(rateStr) : 0,
      ),
    );
  } else {
    // Use custom flat rate
    uniformRate = product.customRate ? parseFloat(product.customRate) : 0;
  }

  const uniformTotal = uniformRate * totalArea;
  const amountSaved = Math.max(0, uniformTotal - variableRateTotal);
  const percentageSaved =
    uniformTotal > 0 ? (amountSaved / uniformTotal) * 100 : 0;
  const moneySaved =
    amountSaved * (product.price ? parseFloat(product.price) : 0);

  return {
    amountSaved,
    percentageSaved,
    moneySaved,
  };
};
