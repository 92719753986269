import React, { Fragment } from 'react';

import ZonesMapTable from '../../Controls/ZonesMapTable';
import Overview from '../../Controls/Overview';
import RatesTable from '../../Controls/RatesTable';

const DrawnManuallyZonesMapLegendGroup = ({
  TableComponent = ZonesMapTable,
  zonesMapTable,
  ratesTable,
  overview,
}) => (
  <Fragment>
    <TableComponent {...zonesMapTable} />
    {ratesTable && <RatesTable {...ratesTable} />}
    <Overview {...overview} />
  </Fragment>
);

export default DrawnManuallyZonesMapLegendGroup;
