import { ZonesMapFeature } from '../../../../../helpers/types/vectorAnalysisMap/geojson';
import { Product, ZoneData } from '../../types/product';

/**
 * Calculates statistical values for each product based on zone data
 */
const calculateProductStatistics = (
  features: ZonesMapFeature[],
  productPerName: Map<string, Product>,
): void => {
  productPerName.forEach((product, productName) => {
    let totalProductAmount = 0;
    let totalAreaWithProduct = 0;

    // Iterate through all zones to calculate totals
    features.forEach((feature) => {
      if (!feature.properties) {
        return;
      }

      const { attributes } = feature.properties;
      const { area, rates, ratesHeaders } = attributes;

      if (rates && ratesHeaders) {
        const productIndex = ratesHeaders.indexOf(productName);

        if (productIndex !== -1) {
          const productZoneRate = rates[productIndex];
          const productZoneAmount = productZoneRate * area;

          totalProductAmount += productZoneAmount;
          totalAreaWithProduct += area;
        }
      }
    });

    // Update product statistics
    product.totalAmount = parseFloat(totalProductAmount.toFixed(1)).toString();

    // Calculate average rate across all zones (weighted by area)
    product.averageRate =
      totalAreaWithProduct > 0
        ? parseFloat(
            (totalProductAmount / totalAreaWithProduct).toFixed(1),
          ).toString()
        : '0';
  });
};

/**
 * Collects unique products from the features and initializes their data
 */
const collectUniqueProducts = (
  features: ZonesMapFeature[],
  productPerName: Map<string, Product>,
): void => {
  features.forEach((feature) => {
    if (!feature.properties?.attributes?.ratesHeaders) {
      return;
    }

    const { ratesHeaders, ratesPrices, ratesUnits } =
      feature.properties.attributes;

    ratesHeaders.forEach((rateHeader, index) => {
      // Skip if this product has already been added
      if (productPerName.has(rateHeader)) {
        return;
      }

      // Initialize product with default values
      productPerName.set(rateHeader, {
        name: rateHeader,
        unit: ratesUnits[index],
        price: ratesPrices[index]?.toString() || '',
        customRate: '',
        totalAmount: '0',
        averageRate: '0',
        minValue: '',
        maxValue: '',
        threshold: '',
        zoneDifference: 10,
        relationshipType: 'normal',
        distributionMethod: 'average',
      });
    });
  });
};

/**
 * Calculates the total area across all zones
 */
const calculateTotalArea = (features: ZonesMapFeature[]): number =>
  features.reduce((totalArea, feature) => {
    if (!feature.properties) {
      return totalArea;
    }

    return totalArea + feature.properties.attributes.area;
  }, 0);

/**
 * Collects zone data and rates for each product per zone
 */
const collectZoneDataAndRates = (
  features: ZonesMapFeature[],
  zoneColors: string[],
  totalFieldArea: number,
  zoneData: ZoneData[],
  ratesPerProduct: Record<number, string[]>,
  productPerName: Map<string, Product>,
): void => {
  features.forEach((feature) => {
    if (!feature.properties) {
      return;
    }

    const { zone, attributes } = feature.properties;
    const { area, rates, ratesHeaders } = attributes;

    // Calculate what percentage of the total field this zone represents
    const percentage = (area / totalFieldArea) * 100;

    // Add zone data with its color, area and percentage
    zoneData.push({
      id: zone,
      color: zoneColors[zone - 1] || null,
      area,
      percentage,
    });

    // Map each product to its corresponding rate value for this zone
    if (rates && ratesHeaders) {
      ratesHeaders.forEach((productName, index) => {
        // Get the product index in our array
        const productIndex = Array.from(productPerName.keys()).findIndex(
          (name) => name === productName,
        );

        if (productIndex === -1) return;

        // Initialize the array for this product if it doesn't exist yet
        if (!ratesPerProduct[productIndex]) {
          ratesPerProduct[productIndex] = [];
        }

        // Ensure array is large enough (fill with empty strings if needed)
        // This handles non-sequential zone IDs by filling gaps with empty strings
        if (ratesPerProduct[productIndex].length < zone) {
          ratesPerProduct[productIndex] = [
            ...ratesPerProduct[productIndex],
            ...Array(zone - ratesPerProduct[productIndex].length).fill(''),
          ];
        }

        // Store rate at the zone's index position (zone IDs are 1-based, array indices are 0-based)
        ratesPerProduct[productIndex][zone - 1] = rates[index].toString();
      });
    }
  });
};

/**
 * Processes raw zones map features into structured distribution data
 */
// eslint-disable-next-line import/prefer-default-export
export const processDistributionData = (
  features: ZonesMapFeature[],
  zoneColors: string[],
): {
  products: Product[];
  zones: ZoneData[];
  ratesPerProduct: Record<number, string[]>;
} => {
  const productPerName = new Map<string, Product>();
  const zoneData: ZoneData[] = [];
  const ratesPerProduct: Record<number, string[]> = {};
  let totalFieldArea = 0;

  // Step 1: Initialize unique products from all features
  collectUniqueProducts(features, productPerName);

  // Step 2: Calculate total area covered by all zones
  totalFieldArea = calculateTotalArea(features);

  // Step 3: Collect zone data and rates per product
  collectZoneDataAndRates(
    features,
    zoneColors,
    totalFieldArea,
    zoneData,
    ratesPerProduct,
    productPerName,
  );

  // Step 4: Calculate product statistics
  calculateProductStatistics(features, productPerName);

  // Sort zones by ID for consistent ordering
  zoneData.sort((a, b) => a.id - b.id);

  return {
    products: Array.from(productPerName.values()),
    zones: zoneData,
    ratesPerProduct,
  };
};
