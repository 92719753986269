import i18n from 'i18next';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import LayersIcon from '@material-ui/icons/Layers';

import ZonesOpsWorkflow from '../constants/zonesOpsWorkflow';
import { ButtonMenuItem } from '../../../../../components/ButtonMenu/types';
import { getZonesOpsLink } from '../../../../../helpers/navigation';
import CloneIcon from '../../../../../components/Icons/clone';
import EquationIcon from '../../../../../components/Icons/equation';
import D3Icon from '../../../../../components/Icons/3d';
import SingleLayerIcon from '../../../../../components/Icons/singleLayer';
import PotentialIcon from '../../../../../components/Icons/potential';
import BalanceIcon from '../../../../../components/Icons/balance';
import CrossLayerIcon from '../../../../../components/Icons/crossLayer';

export const getWorkflowOptions = (params?: {
  farmUuid?: string;
  fieldUuid?: string;
  options?: {
    fromField?: boolean;
    omit?: ZonesOpsWorkflow[];
  };
}): ButtonMenuItem<Link>[] => {
  const { farmUuid, fieldUuid, options } = params || {};
  const baseItems: ButtonMenuItem<Link>[] = [
    {
      label: i18n.t('zones-ops.common.steps.select-field.custom-title'),
      type: 'subheader',
      id: 'custom-subheader',
    },
    {
      id: ZonesOpsWorkflow.drawManually,
      label: i18n.t(
        'zones-ops.common.steps.select-field.options.draw-manually',
      ),
      description: i18n.t(
        'zones-ops.common.steps.select-field.options.draw-manually-description',
      ),
      props: {
        component: Link,
        to: getZonesOpsLink(
          farmUuid,
          fieldUuid,
          ZonesOpsWorkflow.drawManually,
          options?.fromField,
        ),
      },
      IconComponent: EditIcon,
    },
    {
      id: ZonesOpsWorkflow.cloneZonesMap,
      label: i18n.t(
        'zones-ops.common.steps.select-field.options.clone-zones-map',
      ),
      description: i18n.t(
        'zones-ops.common.steps.select-field.options.clone-zones-map-description',
      ),
      props: {
        component: Link,
        to: getZonesOpsLink(
          farmUuid,
          fieldUuid,
          ZonesOpsWorkflow.cloneZonesMap,
          options?.fromField,
        ),
      },
      IconComponent: CloneIcon,
    },
    {
      id: ZonesOpsWorkflow.equationBased,
      label: i18n.t(
        'zones-ops.common.steps.select-field.options.equation-based',
      ),
      description: i18n.t(
        'zones-ops.common.steps.select-field.options.equation-based-description',
      ),
      props: {
        component: Link,
        to: getZonesOpsLink(
          farmUuid,
          fieldUuid,
          ZonesOpsWorkflow.equationBased,
          options?.fromField,
        ),
      },
      IconComponent: EquationIcon,
    },
    {
      id: ZonesOpsWorkflow.threeDMap,
      label: i18n.t('zones-ops.common.steps.select-field.options.3d-map'),
      description: i18n.t(
        'zones-ops.common.steps.select-field.options.3d-map-description',
      ),
      props: {
        component: Link,
        to: getZonesOpsLink(
          farmUuid,
          fieldUuid,
          ZonesOpsWorkflow.threeDMap,
          options?.fromField,
        ),
      },
      IconComponent: D3Icon,
    },
    {
      label: i18n.t('zones-ops.common.steps.select-field.smart-analysis-title'),
      type: 'subheader',
      id: 'smart-analysis-subheader',
    },
    {
      id: ZonesOpsWorkflow.createSingleLayerAnalysis,
      label: i18n.t('zones-ops.common.steps.select-field.options.single-layer'),
      description: i18n.t(
        'zones-ops.common.steps.select-field.options.single-layer-description',
      ),
      props: {
        component: Link,
        to: getZonesOpsLink(
          farmUuid,
          fieldUuid,
          ZonesOpsWorkflow.createSingleLayerAnalysis,
          options?.fromField,
        ),
      },
      IconComponent: SingleLayerIcon,
    },
    {
      id: ZonesOpsWorkflow.createMultiLayerAnalysis,
      label: i18n.t('zones-ops.common.steps.select-field.options.multi-layer'),
      description: i18n.t(
        'zones-ops.common.steps.select-field.options.multi-layer-description',
      ),
      props: {
        component: Link,
        to: getZonesOpsLink(
          farmUuid,
          fieldUuid,
          ZonesOpsWorkflow.createMultiLayerAnalysis,
          options?.fromField,
        ),
      },
      IconComponent: LayersIcon,
    },
    {
      id: ZonesOpsWorkflow.createFieldPotentialZones,
      label: i18n.t(
        'zones-ops.common.steps.select-field.options.field-potential',
      ),
      description: i18n.t(
        'zones-ops.common.steps.select-field.options.field-potential-description',
      ),
      props: {
        component: Link,
        to: getZonesOpsLink(
          farmUuid,
          fieldUuid,
          ZonesOpsWorkflow.createFieldPotentialZones,
          options?.fromField,
        ),
      },
      IconComponent: PotentialIcon,
    },
    {
      id: ZonesOpsWorkflow.createStabilityZones,
      label: i18n.t(
        'zones-ops.common.steps.select-field.options.field-stability',
      ),
      description: i18n.t(
        'zones-ops.common.steps.select-field.options.field-stability-description',
      ),
      props: {
        component: Link,
        to: getZonesOpsLink(
          farmUuid,
          fieldUuid,
          ZonesOpsWorkflow.createStabilityZones,
          options?.fromField,
        ),
      },
      IconComponent: BalanceIcon,
    },
    {
      id: ZonesOpsWorkflow.crossLayer,
      label: i18n.t('zones-ops.common.steps.select-field.options.cross-layer'),
      description: i18n.t(
        'zones-ops.common.steps.select-field.options.cross-layer-description',
      ),
      props: {
        component: Link,
        to: getZonesOpsLink(
          farmUuid,
          fieldUuid,
          ZonesOpsWorkflow.crossLayer,
          options?.fromField,
        ),
      },
      IconComponent: CrossLayerIcon,
    },
  ];

  return baseItems.filter(
    (item) => !options?.omit?.includes(item.id as ZonesOpsWorkflow),
  );
};

export const isZonesOpsWorkflow = (
  workflow: unknown,
): workflow is ZonesOpsWorkflow =>
  !!workflow &&
  typeof workflow === 'string' &&
  !!Object.values(ZonesOpsWorkflow).find(
    (zonesOpsWorkflowOption) => zonesOpsWorkflowOption === workflow,
  );
