import { useTranslation } from 'react-i18next';

import {
  useAppDispatch,
  useAppSelector,
} from '../../../../app/store/helpers/functions';
import { saveNewVectorAnalysisMap } from '../../../createAnalysis/createAnalysisSlice';
import { selectColors, selectFeature } from '../drawManuallyWorkflowSelectors';
import { convertRatesToNumbers } from '../../../../helpers/functions/entities/geojson';
import { selectSelectedField } from '../../zonesOps/zonesOpsSelectors';
import { openPopup } from '../../popups/popupsSlice';
import { isTimeoutError } from '../../../field/helpers/functions/api';
import {
  errorNotify,
  warningNotify,
} from '../../../notifications/helpers/functions/notify';
import { CustomError } from '../../../../helpers/functions/utils/errorHandling';

export default function useSaveNewVamap() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const feature = useAppSelector(selectFeature);
  const colors = useAppSelector(selectColors);
  const { fieldUuid } = useAppSelector(selectSelectedField);

  return async (name: string) => {
    try {
      const vectorAnalysisMap = await dispatch(
        saveNewVectorAnalysisMap({
          name,
          zonesMapGeojson: convertRatesToNumbers(feature),
          hexColorCodes: colors,
        }),
      ).unwrap();

      dispatch(
        openPopup({
          type: 'save-zones-map',
          name,
          vamapUuid: vectorAnalysisMap.uuid,
        }),
      );
    } catch (error) {
      if (isTimeoutError(error)) {
        warningNotify({
          key: fieldUuid,
          message: t('zones-map.notifications.save-zones-map-timeout'),
        });
      } else {
        errorNotify({
          error: new CustomError(
            '[Create Analysis] Unable to save new vector analysis map',
            {
              cause: error,
            },
          ),
          dispatch,
        });
      }
    }
  };
}
