mutation SaveColorSchema($input: ColorSchemaInput!) {
  saveColorSchema(input: $input) {
    title
    uuid
    colors {
      index
      hexCode
    }
  }
}
