import React from 'react';
import { Trans } from 'react-i18next';

import './index.scss';

const Message = ({ messageTextLocation }) => (
  <div className="message">
    <Trans i18nKey={messageTextLocation} />
  </div>
);

export default Message;
