import i18n from 'i18next';

import { ButtonMenuItem } from '../../../../../components/ButtonMenu/types';
import {
  isAsAppliedDataset,
  isEquationMap,
  isSatelliteImage,
  isSoilDataset,
  isTopographyMap,
  isVectorAnalysis,
  isYieldDataset,
} from '../../../../../helpers/functions/entities/assets';
import {
  getValueOptionsArray,
  getSortedValueOptionsArray,
} from '../../../../../helpers/functions/components/uiList';
import {
  getDatasetAttributeNameHandler,
  getDatasetViewProps,
  getDatasetAttributeFullName,
  addAttributesAndSortDatasets,
} from '../../../../../helpers/functions/entities/dataset';
import {
  addAttributesAndSortTopographyMaps,
  getTopographyMapAttributeName,
  getTopographyMapAttributeNameHandler,
  getTopographyMapViewProps,
} from '../../../../../helpers/functions/entities/topographyMap';
import { convertToGenerateMapIndex } from '../../../../../helpers/satellite';
import {
  VariableItem,
  VariableAttributes,
  AssignableAsset,
} from '../../types/variable';
import VariableMenuAction from '../constants/variableMenuAction';
import {
  ASSET_TYPE_TO_ASSET_GROUP,
  AssetGroupType,
  AssetType,
} from '../../../../../helpers/constants/entities/asset';
import { generateAssetNodeId } from '../../../dataLayersView/helpers/functions/dataLayersTree';
import type { TransformedSatelliteImage } from '../../../../satelliteImages/types/satelliteImage';
import type {
  TransformedAsAppliedDataset,
  TransformedSoilDataset,
  TransformedTopographyMap,
  TransformedYieldDataset,
} from '../../../../../helpers/types/dataset';
import type { InputDataVariable } from '../../../dataVariable/types/inputDataVariable';
import { TransformedVectorAnalysisMap } from '../../../../../helpers/types/vectorAnalysisMap';
import { TransformedEquationMap } from '../../../../../helpers/types/equationMap';
import {
  DEFAULT_VAMAP_ZONE_TYPE,
  EQUATION_BASED_ANALYSIS_SATELLITE_INDEXES,
} from '../../../dataVariable/helpers/constants/dataVariableAttribute';
import { VectorAnalysisMapZoneType } from '../../../dataVariable/types/dataVariableAttribute';
import {
  getAttributeOptionValue,
  getVamapVariableZoneTypeAttributeName,
  getVamapVariableZoneTypeAttributes,
} from '../../../dataVariable/helpers/functions/dataVariableAttribute';
import {
  getSatelliteImagesVariableName,
  isAsAppliedDatasetVariable,
  isEquationMapVariable,
  isSatelliteImagesVariable,
  isSoilDatasetVariable,
  isTopographyMapVariable,
  isVectorAnalysisMapVariable,
  isYieldDatasetVariable,
} from '../../../dataVariable/helpers/functions/dataVariable';

export const getVariablesActions = (): ButtonMenuItem[] => [
  {
    id: VariableMenuAction.changeVariable,
    label: i18n.t('general.controls.change'),
  },
  {
    id: VariableMenuAction.deleteVariable,
    label: i18n.t('general.controls.delete'),
  },
];

export const getDataVariableItems = ({
  dataVariables,
  soilDatasets,
  yieldDatasets,
  asAppliedDatasets,
  topographyMaps,
  satelliteImages,
  vectorAnalysisMaps,
  equationMaps,
}: {
  dataVariables: InputDataVariable[];
  soilDatasets: TransformedSoilDataset[];
  yieldDatasets: TransformedYieldDataset[];
  asAppliedDatasets: TransformedAsAppliedDataset[];
  topographyMaps: TransformedTopographyMap[];
  satelliteImages: TransformedSatelliteImage[];
  vectorAnalysisMaps: TransformedVectorAnalysisMap[];
  equationMaps: TransformedEquationMap[];
}): VariableItem[] =>
  dataVariables.map((dataVariable) => {
    let label;
    let name;
    let selectedAttribute;

    if (isSoilDatasetVariable(dataVariable)) {
      const soilDataset = soilDatasets.find(
        (dataset) => dataset.uuid === dataVariable.soilDatasetUuid,
      );

      selectedAttribute = getDatasetAttributeFullName(
        dataVariable.soilAttribute,
        soilDataset?.fullAttributes,
      );
      name = soilDataset?.name;
    } else if (isYieldDatasetVariable(dataVariable)) {
      const yieldDataset = yieldDatasets.find(
        (dataset) => dataset.uuid === dataVariable.yieldDatasetUuid,
      );

      selectedAttribute = getDatasetAttributeFullName(
        dataVariable.yieldAttribute,
        yieldDataset?.fullAttributes,
      );
      name = yieldDataset?.name;
    } else if (isAsAppliedDatasetVariable(dataVariable)) {
      const asAppliedDataset = asAppliedDatasets.find(
        (dataset) => dataset.uuid === dataVariable.asAppliedDatasetUuid,
      );

      selectedAttribute = getDatasetAttributeFullName(
        dataVariable.asAppliedAttribute,
        asAppliedDataset?.fullAttributes,
      );
      name = asAppliedDataset?.name;
    } else if (isTopographyMapVariable(dataVariable)) {
      const topographyMap = topographyMaps.find(
        (dataset) => dataset.uuid === dataVariable.topographyMapUuid,
      );

      selectedAttribute = getTopographyMapAttributeName(
        dataVariable.topographyAttribute,
        topographyMap?.fullAttributes,
      );
      name = topographyMap?.name;
    } else if (isSatelliteImagesVariable(dataVariable)) {
      label = i18n.t(
        'zones-ops.equation-based.steps.4.panel.variables-list.satelliteImagery',
      );
      selectedAttribute = dataVariable.index;
      name = getSatelliteImagesVariableName(
        satelliteImages,
        dataVariable.satelliteImageUuids,
      );
    } else if (isVectorAnalysisMapVariable(dataVariable)) {
      const vectorAnalysisMap = vectorAnalysisMaps.find(
        (dataset) => dataset.uuid === dataVariable.vectorAnalysisMapUuid,
      );

      name = vectorAnalysisMap?.name;
      selectedAttribute = getVamapVariableZoneTypeAttributeName(
        dataVariable.vectorAnalysisMapZoneTypeAttribute,
      );
    } else if (isEquationMapVariable(dataVariable)) {
      const equationMap = equationMaps.find(
        (dataset) => dataset.uuid === dataVariable.equationMapUuid,
      );

      name = equationMap?.name;
    }

    return {
      name: dataVariable.variable || '',
      dataset: {
        name,
        label,
        selectedAttribute,
      },
      useInterpolatedData: dataVariable.useInterpolatedData,
    };
  });

export const prepareVariableAttributes = ({
  dataVariable,
  soilDatasets,
  yieldDatasets,
  asAppliedDatasets,
  topographyMaps,
}: {
  dataVariable: InputDataVariable;
  soilDatasets: TransformedSoilDataset[];
  yieldDatasets: TransformedYieldDataset[];
  asAppliedDatasets: TransformedAsAppliedDataset[];
  topographyMaps: TransformedTopographyMap[];
}): VariableAttributes => {
  let attributes;
  let selectedAttribute;

  if (isSoilDatasetVariable(dataVariable)) {
    const dataset = getSelectedAsset(
      soilDatasets,
      dataVariable.soilDatasetUuid,
    );
    const [selectedData] = dataset
      ? addAttributesAndSortDatasets([dataset])
      : [];

    attributes = getSortedValueOptionsArray(
      selectedData.attributes,
      getDatasetAttributeNameHandler(selectedData.fullAttributes),
    );
    selectedAttribute = dataVariable.soilAttribute;
  } else if (isYieldDatasetVariable(dataVariable)) {
    const dataset = getSelectedAsset(
      yieldDatasets,
      dataVariable.yieldDatasetUuid,
    );
    const [selectedData] = dataset
      ? addAttributesAndSortDatasets([dataset])
      : [];

    attributes = getSortedValueOptionsArray(
      selectedData.attributes,
      getDatasetAttributeNameHandler(selectedData.fullAttributes),
    );
    selectedAttribute = dataVariable.yieldAttribute;
  } else if (isAsAppliedDatasetVariable(dataVariable)) {
    const dataset = getSelectedAsset(
      asAppliedDatasets,
      dataVariable.asAppliedDatasetUuid,
    );
    const [selectedData] = dataset
      ? addAttributesAndSortDatasets([dataset])
      : [];

    attributes = getSortedValueOptionsArray(
      selectedData.attributes,
      getDatasetAttributeNameHandler(selectedData.fullAttributes),
    );
    selectedAttribute = dataVariable.asAppliedAttribute;
  } else if (isTopographyMapVariable(dataVariable)) {
    const topographyMap = getSelectedAsset(
      topographyMaps,
      dataVariable.topographyMapUuid,
    );
    const [selectedData] = topographyMap
      ? addAttributesAndSortTopographyMaps([topographyMap])
      : [];

    attributes = getValueOptionsArray(
      selectedData.attributes,
      getTopographyMapAttributeNameHandler(selectedData.fullAttributes),
    );
    selectedAttribute = dataVariable.topographyAttribute;
  } else if (
    isSatelliteImagesVariable(dataVariable) &&
    dataVariable.satelliteImageUuids.length
  ) {
    attributes = EQUATION_BASED_ANALYSIS_SATELLITE_INDEXES;
    selectedAttribute = dataVariable.index;
  } else if (isVectorAnalysisMapVariable(dataVariable)) {
    attributes = getVamapVariableZoneTypeAttributes();
    selectedAttribute = dataVariable.vectorAnalysisMapZoneTypeAttribute;
  }

  return {
    datasetAttributes: attributes ?? [],
    selectedAttribute: selectedAttribute ?? '',
  };
};

export const updateDataVariableDataset = (
  asset: AssignableAsset,
  dataVariable: InputDataVariable,
): InputDataVariable | null => {
  let result: InputDataVariable | null = null;

  if (isSoilDataset(asset)) {
    const dataVariableAttribute = isSoilDatasetVariable(dataVariable)
      ? dataVariable.soilAttribute
      : null;

    result = {
      soilDatasetUuid: asset.uuid,
      soilAttribute: getDatasetViewProps(asset, dataVariableAttribute)
        .attribute,
    };
  } else if (isYieldDataset(asset)) {
    const dataVariableAttribute = isYieldDatasetVariable(dataVariable)
      ? dataVariable.yieldAttribute
      : null;

    result = {
      yieldDatasetUuid: asset.uuid,
      yieldAttribute: getDatasetViewProps(asset, dataVariableAttribute)
        .attribute,
    };
  } else if (isAsAppliedDataset(asset)) {
    const dataVariableAttribute = isAsAppliedDatasetVariable(dataVariable)
      ? dataVariable.asAppliedAttribute
      : null;

    result = {
      asAppliedDatasetUuid: asset.uuid,
      asAppliedAttribute: getDatasetViewProps(asset, dataVariableAttribute)
        .attribute,
    };
  } else if (isTopographyMap(asset)) {
    const dataVariableAttribute = isTopographyMapVariable(dataVariable)
      ? dataVariable.topographyAttribute
      : null;

    result = {
      topographyMapUuid: asset.uuid,
      topographyAttribute: getTopographyMapViewProps(
        asset,
        dataVariableAttribute,
        true,
      ).attribute,
    };
  } else if (isSatelliteImage(asset)) {
    result = isSatelliteImagesVariable(dataVariable)
      ? {
          satelliteImageUuids: dataVariable.satelliteImageUuids,
          index: dataVariable.index,
        }
      : {
          satelliteImageUuids: [],
          index: '',
        };
  } else if (isVectorAnalysis(asset)) {
    result = {
      vectorAnalysisMapUuid: asset.uuid,
      vectorAnalysisMapZoneTypeAttribute: isVectorAnalysisMapVariable(
        dataVariable,
      )
        ? dataVariable.vectorAnalysisMapZoneTypeAttribute
        : DEFAULT_VAMAP_ZONE_TYPE,
    };
  } else if (isEquationMap(asset)) {
    result = {
      equationMapUuid: asset.uuid,
    };
  }

  if (result) {
    result.variable = dataVariable.variable;
    result.useInterpolatedData = dataVariable.useInterpolatedData;
  }

  return result;
};

export const updateDataVariableAttribute = (
  attribute: string,
  dataVariable: InputDataVariable,
  datasetAttributes: { title: string; value: string }[],
): InputDataVariable => {
  const updatedDataVariable: InputDataVariable = { ...dataVariable };

  if (isSoilDatasetVariable(updatedDataVariable)) {
    updatedDataVariable.soilAttribute = attribute;
  } else if (isYieldDatasetVariable(updatedDataVariable)) {
    updatedDataVariable.yieldAttribute = attribute;
  } else if (isAsAppliedDatasetVariable(updatedDataVariable)) {
    updatedDataVariable.asAppliedAttribute = attribute;
  } else if (isTopographyMapVariable(updatedDataVariable)) {
    updatedDataVariable.topographyAttribute = attribute;
  } else if (isVectorAnalysisMapVariable(updatedDataVariable)) {
    updatedDataVariable.vectorAnalysisMapZoneTypeAttribute =
      attribute as VectorAnalysisMapZoneType;
  } else if (isSatelliteImagesVariable(updatedDataVariable)) {
    const index = convertToGenerateMapIndex(attribute);

    if (getAttributeOptionValue(datasetAttributes, index)) {
      updatedDataVariable.index = index;
    }
  }

  return updatedDataVariable;
};

export const isVariableDataSelected = (
  dataVariable: Partial<InputDataVariable>,
): boolean =>
  (isSoilDatasetVariable(dataVariable) &&
    !!dataVariable.soilDatasetUuid &&
    !!dataVariable.soilAttribute) ||
  (isYieldDatasetVariable(dataVariable) &&
    !!dataVariable.yieldDatasetUuid &&
    !!dataVariable.yieldAttribute) ||
  (isAsAppliedDatasetVariable(dataVariable) &&
    !!dataVariable.asAppliedDatasetUuid &&
    !!dataVariable.asAppliedAttribute) ||
  (isTopographyMapVariable(dataVariable) &&
    !!dataVariable.topographyMapUuid &&
    !!dataVariable.topographyAttribute) ||
  (isSatelliteImagesVariable(dataVariable) &&
    !!dataVariable.satelliteImageUuids.length &&
    !!dataVariable.index) ||
  (isVectorAnalysisMapVariable(dataVariable) &&
    !!dataVariable.vectorAnalysisMapUuid &&
    !!dataVariable.vectorAnalysisMapZoneTypeAttribute) ||
  (isEquationMapVariable(dataVariable) && !!dataVariable.equationMapUuid);

export const isVariableDataChanged = (
  activeDataVariable: InputDataVariable,
  currentDataVariable: InputDataVariable,
): boolean => {
  const sortedActiveDataVariable = JSON.stringify(
    activeDataVariable,
    Object.keys(activeDataVariable).sort(),
  );
  const sortedCurrentDataVariable = JSON.stringify(
    currentDataVariable,
    Object.keys(currentDataVariable).sort(),
  );

  return sortedActiveDataVariable !== sortedCurrentDataVariable;
};

export const getSelectedAsset = <T extends AssignableAsset>(
  datasets: T[],
  dataVariableUuid?: string,
) => datasets.find((dataset) => dataset.uuid === dataVariableUuid);

export const getAssetGroupCheckedLayers = ({
  checkedUuids,
  groupType,
}: {
  checkedUuids?: string[] | null;
  groupType: AssetGroupType;
}): Record<string, number> =>
  (checkedUuids ?? []).reduce(
    (acc, imageUuid) => ({
      ...acc,
      [generateAssetNodeId(imageUuid, groupType)]: 2,
    }),
    {},
  );

export const getDataVariableCheckedLayers = (
  dataVariable: InputDataVariable,
): Record<string, number> =>
  isSatelliteImagesVariable(dataVariable)
    ? getAssetGroupCheckedLayers({
        checkedUuids: dataVariable.satelliteImageUuids,
        groupType: AssetGroupType.satelliteImages,
      })
    : {};

const getDataVariableAssetTypeAndUuid = (dataVariable: InputDataVariable) => {
  let type = null;
  let assetUuid = null;

  if (isSatelliteImagesVariable(dataVariable)) {
    type = AssetType.satelliteImage;
    assetUuid = dataVariable.satelliteImageUuids;
  }
  if (isSoilDatasetVariable(dataVariable)) {
    type = AssetType.soilDataset;
    assetUuid = dataVariable.soilDatasetUuid;
  }
  if (isYieldDatasetVariable(dataVariable)) {
    type = AssetType.yieldDataset;
    assetUuid = dataVariable.yieldDatasetUuid;
  }
  if (isAsAppliedDatasetVariable(dataVariable)) {
    type = AssetType.asAppliedDataset;
    assetUuid = dataVariable.asAppliedDatasetUuid;
  }
  if (isTopographyMapVariable(dataVariable)) {
    type = AssetType.topographyMap;
    assetUuid = dataVariable.topographyMapUuid;
  }
  if (isVectorAnalysisMapVariable(dataVariable)) {
    type = AssetType.vectorAnalysisMap;
    assetUuid = dataVariable.vectorAnalysisMapUuid;
  }
  if (isEquationMapVariable(dataVariable)) {
    type = AssetType.equationMap;
    assetUuid = dataVariable.equationMapUuid;
  }

  return {
    type,
    assetUuid,
  };
};

export const populateDataVariableWithAssetAndGeometryType = (
  dataVariable: InputDataVariable,
  assets: {
    yieldDatasets?: TransformedYieldDataset[];
    asAppliedDatasets?: TransformedAsAppliedDataset[];
  },
) => {
  const { type, assetUuid } = getDataVariableAssetTypeAndUuid(dataVariable);

  if (type === AssetType.yieldDataset || type === AssetType.asAppliedDataset) {
    const key = ASSET_TYPE_TO_ASSET_GROUP[type];
    const dataset = assets[key]?.find((asset) => asset.uuid === assetUuid);

    return {
      ...dataVariable,
      type,
      geometryType: dataset?.geometryType,
    };
  }

  return {
    ...dataVariable,
    type,
  };
};
