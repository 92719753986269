import { createSelector } from '@reduxjs/toolkit';

import { CognitoGroup } from './helpers/constants/user';
import { findLastPaidOrder } from './helpers/functions/order';
import { isNotReachableEmail } from '../ui/authentication/helpers/functions/authentication';

export const selectIsLoaded = ({ user }) => user.isLoaded;

export const selectUuid = ({ user }) => user.data.uuid;

export const selectReachedAreaLimit = ({ user }) => user.data.reachedAreaLimit;

export const selectApiKey = ({ user }) => user.data.apiKey;

export const selectEmail = ({ user }) => user.data.email;

export const selectAreaUnit = ({ user }) => user.data.areaUnit;

export const selectTotalArea = ({ user }) => user.data.totalArea;

export const selectMaxArea = ({ user }) => user.data.maxArea;

export const selectAvailableArea = createSelector(
  selectTotalArea,
  selectMaxArea,
  (totalArea, maxArea) => {
    const availableArea = maxArea - totalArea;
    return availableArea > 0 ? availableArea : 0;
  },
);

export const selectLanguage = ({ user }) => user.data.language;

export const selectIdentity = ({ user }) => user.data.identity;

export const selectOrders = ({ user }) => user.data.orders;

export const selectAdditionalUserData = ({ user }) => {
  const {
    country,
    companyType,
    acceptedTermsAndConditions,
    phoneNumber,
    email,
  } = user.data;

  return {
    country,
    companyType,
    acceptedTermsAndConditions,
    phoneNumber,
    email,
  };
};

export const selectAdditionalUserDataProvided = ({ user }) => {
  const {
    country,
    companyType,
    acceptedTermsAndConditions,
    phoneNumber,
    email,
  } = user.data;
  return (
    country &&
    companyType &&
    acceptedTermsAndConditions &&
    phoneNumber &&
    !isNotReachableEmail(email)
  );
};

export const selectPricingData = ({ user }) => {
  const { currency, stripeCustomerId } = user.data;

  return { currency, stripeCustomerId };
};

export const selectLastOrder = (state) => selectOrders(state)[0];

export const selectLastPaidOrder = createSelector(selectOrders, (orders) =>
  findLastPaidOrder(orders),
);

export const selectCognitoGroups = ({ user }) => user.cognitoGroups;

export const selectPermissionedOrganizationAdmin = ({ user }) =>
  user.cognitoGroups.includes(CognitoGroup.Organization);

export const selectPermissionedSmartSampling = ({ user }) =>
  user.cognitoGroups.includes(CognitoGroup.PfeiferLangen);

export const selectPermissionedOrigin = ({ user }) =>
  user.cognitoGroups.includes(CognitoGroup.Origin);

export const selectPermissionedPlanet = ({ user }) =>
  user.cognitoGroups.includes(CognitoGroup.Planet);

export const selectOrganizations = ({ user }) => user.data.organizations;

export const selectDebitedOrganization = createSelector(
  selectOrganizations,
  (organizations) => organizations[0],
);

export const selectOwnedOrganizations = createSelector(
  selectUuid,
  selectOrganizations,
  (uuid, orgs) => orgs.filter(({ ownerUuid }) => uuid === ownerUuid),
);

export const selectIsUserOrganizationsAccessible = (state) => {
  const isAdmin = selectPermissionedOrganizationAdmin(state);
  const orgs = selectOrganizations(state);

  return isAdmin || orgs.length > 0;
};

const selectUserAdvisor = createSelector(
  selectOrganizations,
  selectUuid,
  (organizations, uuid) =>
    organizations.some(({ users }) =>
      users.find(({ userUuid, allFarms }) => allFarms && userUuid === uuid),
    ),
);

export const selectUserAdminOrAdvisor = (state) =>
  selectPermissionedOrganizationAdmin(state) || selectUserAdvisor(state);
