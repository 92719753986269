import React from 'react';
import Typography from '@material-ui/core/Typography';

import GeoMapPreviewTooltip from '../../../../Tooltip/Tooltips/GeoMapPreviewTooltip';
import Link from '../../../../Link';
import getFieldCoverageIcon from '../../../../../helpers/functions/components/getFieldCoverageIcon';
import { FullAttribute } from '../../../../../helpers/types/dataset';
import { GeoMap } from '../../../../../helpers/types/api';
import NumberTooltip from '../../../../Tooltip/Tooltips/NumberTooltip';
import ItemPlaceholder from '../ItemPlaceholder';
import CoveredAreaTooltip from '../../../../Tooltip/Tooltips/CoveredAreaTooltip';

import './index.scss';

export interface AssetItemDataset {
  link: string;
  number?: number;
  fieldCoverage?: number;
  geoMap?: GeoMap;
  fullAttribute?: FullAttribute;
}

export default function DatasetItem({
  label,
  value,
  dataset,
  apiKey,
}: {
  label: string;
  value: string;
  dataset: AssetItemDataset;
  apiKey: string;
}) {
  let AreaIcon;

  if (dataset.fieldCoverage) {
    AreaIcon = getFieldCoverageIcon(dataset.fieldCoverage);
  }

  return (
    <div className="source-assets-dataset-item">
      <Typography variant="body2" className="source-assets-dataset-item__label">
        {label}
      </Typography>
      <div className="source-assets-dataset-item__value">
        {value ? (
          <div className="source-assets-dataset-item__value-dataset">
            {dataset.geoMap ? (
              <GeoMapPreviewTooltip
                apiKey={apiKey}
                geoMap={dataset.geoMap}
                fullAttribute={dataset.fullAttribute}
              >
                <Link to={dataset.link} target="_blank">
                  {value}
                </Link>
              </GeoMapPreviewTooltip>
            ) : (
              <Link to={dataset.link} target="_blank">
                {value}
              </Link>
            )}
            <div className="source-assets-dataset-item__value-dataset-info">
              {dataset.number != null && (
                <NumberTooltip>
                  <span className="source-assets-dataset-item__value-dataset-info-text">
                    {dataset.number}
                  </span>
                </NumberTooltip>
              )}
              {AreaIcon && dataset.fieldCoverage != null && (
                <CoveredAreaTooltip fieldCoverage={dataset.fieldCoverage}>
                  <AreaIcon className="dataset-item__icon" />
                </CoveredAreaTooltip>
              )}
            </div>
          </div>
        ) : (
          <ItemPlaceholder />
        )}
      </div>
    </div>
  );
}
