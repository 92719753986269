import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function ExportAdmFileIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7336 14.8778V13.6569C15.1542 13.6903 15.5361 13.8627 15.8333 14.1281C16.2188 14.4723 16.4617 14.9729 16.4617 15.5294C16.4617 15.846 16.3299 16.2446 16.1898 16.5031L16.8954 17.2087C17.1737 16.7699 17.4385 16.09 17.4385 15.5294C17.4385 14.4003 16.7839 13.4247 15.8333 12.9614C15.4983 12.7981 15.1265 12.6984 14.7336 12.6781V11.4473L14.4444 11.7365L13.0183 13.1626L14.4444 14.5887L14.7336 14.8778Z"
        fill="#6E6E6E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.433 19.6114V18.3807C13.6801 18.3417 13.0045 18.0113 12.5168 17.5C12.1613 17.1273 11.9056 16.6585 11.7927 16.1364C11.7504 15.9408 11.7281 15.7377 11.7281 15.5294C11.7281 15.2671 11.7641 15.0134 11.8309 14.7727C11.9068 14.499 12.0227 14.2421 12.1707 14.0085L12.2712 13.8501L12.9769 14.5557C12.9559 14.5943 12.8829 14.7324 12.8648 14.7727C12.7616 15.0027 12.7049 15.26 12.7049 15.5294C12.7049 15.7417 12.7403 15.9459 12.8054 16.1364C13.0427 16.83 13.6751 17.3416 14.433 17.4018V16.1809L15.7521 17.5L16.1483 17.8962L14.433 19.6114Z"
        fill="#6E6E6E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8333 3.86364H11.5416C11.25 3.07273 10.4861 2.5 9.58331 2.5C8.68054 2.5 7.91665 3.07273 7.62498 3.86364H3.33331V17.5H10.4997C10.2845 17.079 10.1325 16.6202 10.0562 16.1364C10.0192 15.9019 9.99998 15.6615 9.99998 15.4167C9.99998 15.1981 10.0153 14.9831 10.0449 14.7727H8.88887V13.4091H10.4619C10.7139 12.8927 11.0601 12.4307 11.4781 12.0455H8.88887V10.6818H13.0555V11.0941C13.4919 10.9399 13.9587 10.8499 14.4444 10.8354C14.4906 10.834 14.5369 10.8333 14.5833 10.8333C15.0167 10.8333 15.436 10.8935 15.8333 11.0059V3.86364ZM9.58331 3.86364C9.96526 3.86364 10.2778 4.17045 10.2778 4.54545C10.2778 4.92045 9.96526 5.22727 9.58331 5.22727C9.20137 5.22727 8.88887 4.92045 8.88887 4.54545C8.88887 4.17045 9.20137 3.86364 9.58331 3.86364ZM7.49998 7.95455H6.11109V9.31818H7.49998V7.95455ZM13.0555 7.95455H8.88887V9.31818H13.0555V7.95455ZM7.49998 10.6818H6.11109V12.0455H7.49998V10.6818ZM7.49998 13.4091H6.11109V14.7727H7.49998V13.4091Z"
        fill="#6E6E6E"
      />
    </SvgIcon>
  );
}
