import { escapeMultilineText } from '../../../../helpers/functions/utils/string';
import { Pin } from '../../types';

const transformPinToInput = (pin: Pin) => {
  const {
    vectorAnalysisMap,
    soilDataset,
    updatedDate,
    photos,
    comments,
    comment,
    ...pinProps
  } = pin;
  let dataUuid = {};

  if (vectorAnalysisMap) {
    dataUuid = {
      vectorAnalysisMapUuid: vectorAnalysisMap.uuid,
    };
  } else if (soilDataset) {
    dataUuid = {
      soilDatasetUuid: soilDataset.uuid,
    };
  }

  return {
    ...pinProps,
    ...dataUuid,
    comment: escapeMultilineText(comment),
  };
};

export default transformPinToInput;
