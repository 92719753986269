import React, { Fragment, MouseEvent, useMemo } from 'react';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';

import CheckboxItem from '../../../TreeView/CheckboxItem';
import DatasetItem from '../../../Items/DatasetItem';
import SimpleItem from '../../../TreeView/SimpleItem';
import ItemActions from '../../../ItemActions';
import CleanCalibrateIcon from '../../../Icons/cleanCalibrate.svg';
import { getDatasetActions } from '../../../../helpers/functions/dataLayersTreeNodesActions';
import type {
  Mode,
  DataLayersTreeNode,
} from '../../../../types/dataLayersTree';
import GroupParentItem from '../../Items/GroupParentItem';
import CleanCalibrateTooltip from './CleanCalibrateTooltip';
import isDatasetItemDisabled from '../../../../helpers/functions/isDatasetItemDisabled';
import {
  AssetGroupType,
  AssetType,
} from '../../../../../../../helpers/constants/entities/asset';
import type { TransformedDataset } from '../../../../../../../helpers/types/dataset';

import './index.scss';

export default function DatasetNode({
  id,
  dataset,
  mode,
  checked,
  expanded,
  treeNodeGetter,
  jdProfileIsHealth,
  jdProfileIsAuthorized,
  isSynchronizedJohnDeereField,
  withAttributes,
  grouping,
  showItemMenu,
  showCleanCalibrate,
  assetGroupType,
  selectedItemUuid,
  selectedItemGroupType,
  publishedClickable,
  offset,
  onCheckboxClick,
  onMenuItemClick,
  onClick,
  onExpandMoreClick,
  onGroupToggleClick,
  onCleanCalibrateClick,
}: {
  id: string;
  dataset: TransformedDataset;
  mode: Mode;
  checked: Record<string, number>;
  expanded: Record<string, boolean>;
  treeNodeGetter: () => DataLayersTreeNode | null;
  jdProfileIsHealth: boolean;
  jdProfileIsAuthorized: boolean;
  isSynchronizedJohnDeereField: boolean;
  withAttributes: boolean;
  grouping?: {
    memberType: 'parent' | 'child';
    groupAmount?: number;
  };
  showItemMenu: boolean;
  showCleanCalibrate: boolean;
  assetGroupType: AssetGroupType;
  selectedItemUuid?: string;
  selectedItemGroupType?: string | null;
  publishedClickable: boolean;
  offset: number;
  onCheckboxClick: (
    v: Record<string, number>,
    item: DataLayersTreeNode,
  ) => void;
  onMenuItemClick: (v: string) => void;
  onClick: () => void;
  onExpandMoreClick: (e: MouseEvent) => void;
  onGroupToggleClick: () => void;
  onCleanCalibrateClick: () => void;
}) {
  let result;
  const menuItems = useMemo(
    () =>
      getDatasetActions(dataset, {
        jdProfileIsHealth,
        jdProfileIsAuthorized,
        isSynchronizedJohnDeereField,
      }),
    [
      dataset,
      jdProfileIsHealth,
      jdProfileIsAuthorized,
      isSynchronizedJohnDeereField,
    ],
  );
  const isItemSelected =
    dataset.uuid === selectedItemUuid &&
    selectedItemGroupType === assetGroupType;
  const disabled = isDatasetItemDisabled(dataset, publishedClickable);
  const isCheckboxItemDisabled = isDatasetItemDisabled(dataset);
  const cleanCalibrateAvailable =
    !disabled &&
    showCleanCalibrate &&
    dataset.assetType === AssetType.yieldDataset;

  const item = (
    <div className="dataset-node__item">
      <DatasetItem
        dataset={dataset}
        isGroupParent={grouping?.memberType === 'parent'}
      >
        <Fragment key="actions">
          {cleanCalibrateAvailable && (
            <CleanCalibrateTooltip>
              <IconButton size="small" onClick={onCleanCalibrateClick}>
                <CleanCalibrateIcon />
              </IconButton>
            </CleanCalibrateTooltip>
          )}
          {showItemMenu && (
            <ItemActions
              menuItems={menuItems}
              onMenuItemClick={onMenuItemClick}
            />
          )}
        </Fragment>
      </DatasetItem>
    </div>
  );
  const wrappedItem =
    grouping?.memberType === 'parent' ? (
      <GroupParentItem
        groupAmount={grouping.groupAmount}
        isExpanded={expanded[id]}
        onMoreButtonClick={onGroupToggleClick}
      >
        {item}
      </GroupParentItem>
    ) : (
      item
    );
  const shouldApplyExpandedGroupClass =
    grouping?.memberType === 'child' ||
    (grouping?.memberType === 'parent' && expanded[id]);

  const handleCheckboxClick = (
    value: Record<string, number>,
    itemNode: DataLayersTreeNode,
  ) => {
    const node = treeNodeGetter();
    let updatedValue = value;

    // Group parents should be selected independently of children
    if (node?.children && grouping?.memberType === 'parent') {
      const childrenSelection = node.children.reduce<Record<string, number>>(
        (acc, childItem) => {
          acc[childItem.id] = 0;

          return acc;
        },
        {},
      );

      updatedValue = {
        ...value,
        ...childrenSelection,
      };
    }

    onCheckboxClick(updatedValue, itemNode);
  };

  // Group children are not available for multi select operations
  if (mode === 'multiSelect' && grouping?.memberType !== 'child') {
    result = (
      <CheckboxItem
        itemId={id}
        treeNodeGetter={treeNodeGetter}
        checked={checked}
        isParent={withAttributes}
        isExpanded={expanded[id]}
        disabled={isCheckboxItemDisabled}
        isSelected={isItemSelected}
        offset={offset}
        classes={{
          wrapper: clsx({
            'dataset-node__wrapper_expanded-group':
              shouldApplyExpandedGroupClass,
          }),
        }}
        onCheckboxClick={handleCheckboxClick}
        onClick={onClick}
        onExpandMoreClick={onExpandMoreClick}
      >
        {wrappedItem}
      </CheckboxItem>
    );
  } else {
    result = (
      <SimpleItem
        disabled={disabled}
        isSelected={isItemSelected}
        isExpanded={expanded[id]}
        offset={offset}
        classes={{
          wrapper: clsx({
            'dataset-node__wrapper_expanded-group':
              shouldApplyExpandedGroupClass,
          }),
        }}
        onClick={onClick}
      >
        {wrappedItem}
      </SimpleItem>
    );
  }

  return result;
}
