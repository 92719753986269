import { formatAcquisitionDate } from '../../../../../helpers/satellite';
import { TransformedSatelliteImage } from '../../../../satelliteImages/types/satelliteImage';
import {
  AsAppliedDatasetVariable,
  EquationMapVariable,
  InputDataVariable,
  SatelliteImagesVariable,
  SoilDatasetVariable,
  TopographyMapVariable,
  VectorAnalysisMapVariable,
  YieldDatasetVariable,
} from '../../types/inputDataVariable';

export const isSatelliteImagesVariable = (
  dataVariable: Partial<InputDataVariable>,
): dataVariable is SatelliteImagesVariable =>
  'satelliteImageUuids' in dataVariable;

export const isSoilDatasetVariable = (
  dataVariable: Partial<InputDataVariable>,
): dataVariable is SoilDatasetVariable => 'soilDatasetUuid' in dataVariable;

export const isYieldDatasetVariable = (
  dataVariable: Partial<InputDataVariable>,
): dataVariable is YieldDatasetVariable => 'yieldDatasetUuid' in dataVariable;

export const isAsAppliedDatasetVariable = (
  dataVariable: Partial<InputDataVariable>,
): dataVariable is AsAppliedDatasetVariable =>
  'asAppliedDatasetUuid' in dataVariable;

export const isTopographyMapVariable = (
  dataVariable: Partial<InputDataVariable>,
): dataVariable is TopographyMapVariable => 'topographyMapUuid' in dataVariable;

export const isVectorAnalysisMapVariable = (
  dataVariable: Partial<InputDataVariable>,
): dataVariable is VectorAnalysisMapVariable =>
  'vectorAnalysisMapUuid' in dataVariable;

export const isEquationMapVariable = (
  dataVariable: Partial<InputDataVariable>,
): dataVariable is EquationMapVariable => 'equationMapUuid' in dataVariable;

export const getSatelliteImagesVariableName = (
  satelliteImages?: TransformedSatelliteImage[],
  selectedSatelliteImageUuids?: string[] | null,
) => {
  const images =
    (satelliteImages ?? []).filter(
      (image) => selectedSatelliteImageUuids?.includes(image.uuid),
      [],
    ) ?? [];

  return images
    .map((image) => formatAcquisitionDate(image.satelliteImage.acquisitionDate))
    .join(', ');
};
