import { Action } from '../../types/action';
import { ParsedEvent } from '../../types/event';
import { SubscriptionEvent } from '../../types/api';
import PlatformEventAction from '../constants/action';
import TreeNodeEntity from '../../../../helpers/constants/entities/treeNodeEntity';
import { captureException } from '../../../../helpers/functions/utils/errorHandling';

export const aggregateActions = (actions: Action[] = []) => {
  const uuidsSet = new Set<string>();

  return actions.reduceRight<{
    insert: Action[];
    modify: Action[];
    remove: Action[];
  }>(
    (acc, action) => {
      if (!uuidsSet.has(action.uuid)) {
        uuidsSet.add(action.uuid);

        if (action.action === PlatformEventAction.remove) {
          acc.remove.push(action);
        } else if (action.action === PlatformEventAction.insert) {
          acc.insert.push(action);
        } else if (action.action === PlatformEventAction.modify) {
          acc.modify.push(action);
        }
      }

      return acc;
    },
    {
      insert: [],
      modify: [],
      remove: [],
    },
  );
};

export const processSubscriptionActions = <T extends { uuid: string }>(
  actions: Action[],
  data: T[],
  fetcher: (uuids: string[]) => Promise<T[]>,
  updater: (newData: T[]) => void,
  cleaner: (actions: Action[]) => void,
) => {
  const { insert, modify, remove } = aggregateActions(actions);

  cleaner(actions);

  const fetchUuids = [...modify, ...insert].map(({ uuid }) => uuid);
  const removeUuids = remove.map(({ uuid }) => uuid);
  const newData = [...data].filter((item) => !removeUuids.includes(item.uuid));
  let dataPromise = Promise.resolve();

  if (fetchUuids.length > 0) {
    dataPromise = fetcher(fetchUuids)
      .then((fetchedData = []) => {
        const dataUuidsSet = new Set(newData.map((item) => item.uuid));

        fetchedData.forEach((item) => {
          if (dataUuidsSet.has(item.uuid)) {
            const ind = newData.findIndex((d) => d.uuid === item.uuid);

            newData[ind] = {
              ...newData[ind],
              ...item,
            };
          } else {
            newData.push(item);
          }
        });
      })
      .catch((error) => {
        captureException({
          message: 'Failed to fetch subscription data.',
          error,
        });
      });
  }

  void dataPromise.then(() => {
    updater(newData);
  });
};

export const parseEvent = (event: SubscriptionEvent): ParsedEvent => {
  const { action, nodePath = [] } = event.value.data.followPlatformEvent;
  let farmUuid = '';
  let fieldUuid = '';
  let satelliteImageUuid = '';
  let vectorAnalysisMapUuid = '';
  let soilDatasetUuid = '';
  let yieldDatasetUuid = '';
  let asAppliedDatasetUuid = '';
  let elevationUuid = '';
  let equationMapUuid = '';
  let reportUuid = '';

  nodePath.forEach((node) => {
    if (node.entity === TreeNodeEntity.farm) {
      farmUuid = node.uuid;
    } else if (node.entity === TreeNodeEntity.field) {
      fieldUuid = node.uuid;
    } else if (node.entity === TreeNodeEntity.fieldSatelliteImage) {
      satelliteImageUuid = node.uuid;
    } else if (node.entity === TreeNodeEntity.vectorAnalysisMap) {
      vectorAnalysisMapUuid = node.uuid;
    } else if (node.entity === TreeNodeEntity.soilDataset) {
      soilDatasetUuid = node.uuid;
    } else if (node.entity === TreeNodeEntity.yieldDataset) {
      yieldDatasetUuid = node.uuid;
    } else if (node.entity === TreeNodeEntity.asAppliedDataset) {
      asAppliedDatasetUuid = node.uuid;
    } else if (node.entity === TreeNodeEntity.elevation) {
      elevationUuid = node.uuid;
    } else if (node.entity === TreeNodeEntity.equationMap) {
      equationMapUuid = node.uuid;
    } else if (node.entity === TreeNodeEntity.report) {
      reportUuid = node.uuid;
    }
  });

  return {
    action,
    pathLength: nodePath.length,
    farmUuid,
    fieldUuid,
    satelliteImageUuid,
    vectorAnalysisMapUuid,
    soilDatasetUuid,
    yieldDatasetUuid,
    asAppliedDatasetUuid,
    elevationUuid,
    equationMapUuid,
    reportUuid,
  };
};
