import { AssetType } from '../../../../../helpers/constants/entities/asset';
import { DataLayer } from '../../types/ui';

const getIsValidSetup = (dataLayers: DataLayer[] = []) =>
  dataLayers.length >= 1 &&
  dataLayers.every((layer) => {
    const { type } = layer;

    if (type === AssetType.satelliteImage) {
      return !!layer.satelliteImageUuids?.length;
    }

    if (
      type === AssetType.soilDataset ||
      type === AssetType.yieldDataset ||
      type === AssetType.asAppliedDataset ||
      type === AssetType.topographyMap
    ) {
      return layer.datasetUuid && layer.attribute;
    }

    return false;
  });

export default getIsValidSetup;
