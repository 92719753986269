import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import getZonesMapsTableRows from '../../helpers/functions/getZonesMapsTableRows';
import {
  deleteZonesMap,
  loadMoreZonesMaps,
  renameZonesMap,
  tryTogglePageRowsSelection,
  tryToggleRowSelection,
  updateFilters,
} from '../../zonesMapsSlice';
import { openPopup } from '../../../popups/popupsSlice';
import { prepareCloneWorkflow } from '../../../cloneZonesMap/cloneZonesMapSlice';
import { openExportZonesMapPopup } from '../../../../exportData/exportDataSlice';
import ZonesOpsWorkflow from '../../../zonesOps/helpers/constants/zonesOpsWorkflow';
import { getZonesOpsLink } from '../../../../../helpers/navigation';
import { getRowsSelection } from '../../../tableView/helpers/functions/rows';
import { useGetFarmsQuery } from '../../../../farms/farmsAPI';
import {
  selectIsLoaded,
  selectLabelsExpanded,
  selectLastEvaluatedKey,
  selectSelectionMode,
  selectZonesMaps,
} from '../../zonesMapsSelectors';
import { selectAreaUnit, selectApiKey } from '../../../../user/userSelectors';
import useDidMount from '../../../../../hooks/useDidMount';
import ZonesMapsTableComponent from '../../components/Table';
import getTableColumns from '../../helpers/functions/tableColumns';
import { DefaultZonesMapTableRow } from '../../types/tableRow';
import { RowMenuItem } from '../../../tableView/types/row';
import AmplitudeAnalytics from '../../../../../helpers/classes/amplitudeAnalytics';
import useCurrentPage from '../../../../../hooks/useCurrentPage';
import ExportType from '../../../../exportData/helpers/constants/exportType';

export default function ZonesMapsTable() {
  const dispatch = useDispatch();
  const history = useHistory();
  const page = useCurrentPage();

  const zonesMaps = useSelector(selectZonesMaps);
  const apiKey = useSelector(selectApiKey);
  const areaUnit = useSelector(selectAreaUnit);
  const isZonesMapsLoaded = useSelector(selectIsLoaded);
  const lastEvaluatedKey = useSelector(selectLastEvaluatedKey);
  const selectionMode = useSelector(selectSelectionMode);
  const labelsExpanded = useSelector(selectLabelsExpanded);

  const { isFetching: isFarmsFetching } = useGetFarmsQuery();

  const handleLoadMoreRows = () => {
    dispatch(loadMoreZonesMaps());
  };

  const handleMenuItemClick = (
    { id }: RowMenuItem,
    row: DefaultZonesMapTableRow,
  ) => {
    if (id === 'clone') {
      dispatch(
        prepareCloneWorkflow({
          vamap: row.data,
          farmUuid: row.farmUuid,
          fieldUuid: row.fieldUuid,
        }),
      );
      history.push(
        getZonesOpsLink(
          row.farmUuid,
          row.fieldUuid,
          ZonesOpsWorkflow.cloneZonesMap,
          true,
        ),
      );
      AmplitudeAnalytics.trackCreateAnalysisMapWorkflowSelected({
        workflow: ZonesOpsWorkflow.cloneZonesMap,
        page,
      });
    } else if (id === 'delete') {
      dispatch(
        openPopup({
          type: 'delete-zones-map',
          entityName: row.name.title,
          shouldDisplayPinsWarning: row.shouldDisplayPinsWarning,
          onConfirm: () => {
            dispatch(
              // @ts-expect-error
              deleteZonesMap({
                asset: row.data,
                fieldUuid: row.fieldUuid,
              }),
            );
          },
        }),
      );
    } else if (id === 'rename') {
      dispatch(
        openPopup({
          type: 'rename-zones-map',
          entityName: row.name.title,
          onConfirm: (name: string) => {
            dispatch(
              // @ts-expect-error
              renameZonesMap({
                asset: row.data,
                fieldUuid: row.fieldUuid,
                name,
              }),
            );
          },
        }),
      );
    }
  };

  const handleHeadCheckboxClick = (checked: boolean) => {
    dispatch(tryTogglePageRowsSelection(checked));
  };

  const handleRowCheckboxClick = (row: DefaultZonesMapTableRow) => {
    dispatch(tryToggleRowSelection(row.uuid));
  };

  const handleExportButtonClick = (row: DefaultZonesMapTableRow) => {
    dispatch(
      openExportZonesMapPopup({
        zonesMaps: [
          {
            uuid: row.uuid,
            name: row.name.title,
            fieldUuid: row.fieldUuid,
            attributes: row.attributes,
            type: row.type,
          },
        ],
        archiveName: row.name.title,
        // @ts-expect-error
        onExport: (type: ExportType, ratesOnly = false) => {
          if (type === ExportType.exportToJDAsFiles) {
            AmplitudeAnalytics.trackEntityExportedToJD({
              page,
              entityType: 'zonesMap',
              exportType: 'asFiles',
            });
          } else {
            AmplitudeAnalytics.trackEntityDownloaded({
              page,
              entityType: 'zonesMap',
              downloadType: type,
              ratesOnly,
            });
          }
        },
      }),
    );
  };

  const handleZonesMapLinkClick = () => {
    AmplitudeAnalytics.trackNavigatedToZonesMap({
      page,
      view: 'description',
    });
  };

  const handleFieldLinkClick = () => {
    AmplitudeAnalytics.trackNavigatedToField({
      page,
      view: 'description',
    });
  };

  const handleRatesLineClick = (
    row: DefaultZonesMapTableRow,
    lineIndex: number,
  ) => {
    // TODO: When rates content length > 1 first line is 'Preview' (to be refactored)
    if (row.rates.length > 1 && lineIndex === 0) {
      dispatch(
        openPopup({
          type: 'rates-preview',
          zonesMapName: row.name.title,
          attributes: row.attributes,
          geoMaps: row.geoMaps,
          farmUuid: row.farmUuid,
          fieldUuid: row.fieldUuid,
          uuid: row.uuid,
          totalArea: row.area,
          areaUnit,
          onExportZonesMap: () => {
            dispatch(
              openExportZonesMapPopup({
                zonesMaps: [
                  {
                    uuid: row.uuid,
                    fieldUuid: row.fieldUuid,
                    attributes: row.attributes,
                    type: row.type,
                  },
                ],
                archiveName: row.name.title,
                // @ts-expect-error
                onExport: (type: ExportType, ratesOnly = false) => {
                  if (type === ExportType.exportToJDAsFiles) {
                    AmplitudeAnalytics.trackEntityExportedToJD({
                      page,
                      entityType: 'zonesMap',
                      exportType: 'asFiles',
                    });
                  } else {
                    AmplitudeAnalytics.trackEntityDownloaded({
                      page,
                      entityType: 'zonesMap',
                      downloadType: type,
                      ratesOnly,
                    });
                  }
                },
              }),
            );
          },
        }),
      );
    } else {
      AmplitudeAnalytics.trackNavigatedToZonesMap({
        view: 'rates',
        page,
      });
    }
  };

  useDidMount(() => {
    dispatch(updateFilters());
  });

  const rows = getZonesMapsTableRows({
    vamaps: zonesMaps,
    apiKey,
  });
  const rowsSelection = getRowsSelection(rows);
  const columns = getTableColumns({
    selectionMode,
    rowsSelection,
    onHeadCheckboxClick: handleHeadCheckboxClick,
    onRowCheckboxClick: handleRowCheckboxClick,
    onRatesLineClick: handleRatesLineClick,
    onFieldLinkClick: handleFieldLinkClick,
    onZonesMapLinkClick: handleZonesMapLinkClick,
    onExportButtonClick: handleExportButtonClick,
    onMenuItemClick: handleMenuItemClick,
  });

  return (
    <ZonesMapsTableComponent
      loading={!isZonesMapsLoaded || isFarmsFetching}
      rows={rows}
      columns={columns}
      loadComplete={!lastEvaluatedKey}
      labelsExpanded={labelsExpanded}
      onLoadMoreRows={handleLoadMoreRows}
    />
  );
}
