import React, { ReactNode } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import type { TableColumn } from '../../types/column';
import Checkbox from '../../../../../components/Checkbox';

import './index.scss';

function getHeadCellContent<T>(column: TableColumn<T>) {
  let result: ReactNode;

  if ('label' in column) {
    result = column.label;
  } else if (column.labelElement === 'checkbox') {
    result = (
      <Checkbox
        value={column.value}
        onChange={(_e, checked) => column.handler(checked)}
      />
    );
  }

  return result;
}

export default function TableHeadContent<T>({
  columns,
}: {
  columns: TableColumn<T>[];
}) {
  return (
    <TableRow className="table-head-content__row">
      {columns.map((column) => (
        <TableCell
          key={column.id}
          classes={{
            root:
              column.element === 'checkbox'
                ? 'table-head-content__cell_checkbox'
                : 'table-head-content__cell',
            alignRight: 'table-head-content__cell_align-right',
          }}
          padding={column.element === 'checkbox' ? 'checkbox' : 'default'}
        >
          {getHeadCellContent(column)}
        </TableCell>
      ))}
    </TableRow>
  );
}
