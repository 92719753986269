import React, { useMemo } from 'react';

import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../app/store/helpers/functions';
import {
  selectActiveDataLayer,
  selectActiveDataLayerIndex,
  selectActiveImageUuid,
} from '../../../createMultiLayerAnalysisSelectors';
import useDataLayersList from '../../../../dataLayersView/hooks/useDataLayersList';
import { AssetType } from '../../../../../../helpers/constants/entities/asset';
import { SATELLITE_INDEXES } from '../../../../../../helpers/satellite';
import CreateAnalysisDatasetsPanel from '../../../../dataLayersView/components/DataLayersList/Panels/CreateAnalysisDatasetsPanel';
import CreateAnalysisSatelliteImagesPanel from '../../../../dataLayersView/components/DataLayersList/Panels/CreateAnalysisSatelliteImagesPanel';
import CreateAnalysisTopographyMapsPanel from '../../../../dataLayersView/components/DataLayersList/Panels/CreateAnalysisTopographyMapsPanel';
import { AssignableAsset } from '../../../../dataLayersView/types/dataLayersList';
import {
  activateImage,
  replaceDataLayer,
  updateDataLayer,
} from '../../../createMultiLayerAnalysisSlice';
import { selectCreateAnalysisAssets } from '../../../../../field/fieldSelectors';
import { DataLayer } from '../../../types/ui';
import { findAttributeToPreselect } from '../../../../../../helpers/functions/entities/attribute';
import CreateAnalysisRecommendedImagesPicker from '../../../../dataLayersView/containers/DataLayersList/CreateAnalysisRecommendedImagesPicker';
import {
  initDataLayer,
  getDataLayersOptions,
} from '../../../helpers/functions/dataLayer';

import './index.scss';

export default function DataLayersPanel() {
  const dispatch = useAppDispatch();

  const activeDataLayer = useAppSelector(selectActiveDataLayer) || {};
  const activeDataLayerIndex = useAppSelector(selectActiveDataLayerIndex);
  const selectedSatelliteImageUuid = useAppSelector(selectActiveImageUuid);
  const createAnalysisAssets = useAppSelector(selectCreateAnalysisAssets);
  const {
    satelliteImages,
    soilDatasets,
    yieldDatasets,
    asAppliedDatasets,
    topographyMaps,
  } = createAnalysisAssets;
  const dataLayersOptions = getDataLayersOptions(createAnalysisAssets);

  const handleAssetClick = (asset: AssignableAsset) => {
    if (activeDataLayer.type === AssetType.satelliteImage) {
      dispatch(activateImage(asset.uuid));
    } else {
      const attribute = findAttributeToPreselect(asset);
      let updates: Partial<DataLayer> = {
        datasetUuid: asset.uuid,
      };

      if (attribute) {
        updates = {
          ...updates,
          attribute,
        };
      }

      dispatch(
        updateDataLayer({
          dataLayerIndex: activeDataLayerIndex,
          updates,
        }),
      );
    }
  };

  const handleImageSelectionToggle = (value: boolean, uuidToToggle: string) => {
    const {
      satelliteImageUuids = [],
    }: {
      satelliteImageUuids: string[];
    } = activeDataLayer;
    const updatedUuids = value
      ? [...satelliteImageUuids, uuidToToggle]
      : satelliteImageUuids.filter((uuid) => uuid !== uuidToToggle);

    dispatch(
      updateDataLayer({
        dataLayerIndex: activeDataLayerIndex,
        updates: {
          satelliteImageUuids: updatedUuids,
        },
      }),
    );
  };

  const handleAllSelectionToggle = (value: boolean, uuids: string[]) => {
    const updatedUuids = value ? uuids : [];

    dispatch(
      updateDataLayer({
        dataLayerIndex: activeDataLayerIndex,
        updates: {
          satelliteImageUuids: updatedUuids,
        },
      }),
    );
  };

  const handleRecommendedSelect = (uuids: string[]) => {
    dispatch(
      updateDataLayer({
        dataLayerIndex: activeDataLayerIndex,
        updates: {
          satelliteImageUuids: uuids,
        },
      }),
    );
  };

  const handleSatelliteIndexChange = (index: string | null) => {
    if (!index) {
      return;
    }

    dispatch(
      updateDataLayer({
        dataLayerIndex: activeDataLayerIndex,
        updates: { index },
      }),
    );
  };

  const handleDatasetAttributeChange = (attribute: string | null) => {
    if (!attribute) {
      return;
    }

    dispatch(
      updateDataLayer({
        dataLayerIndex: activeDataLayerIndex,
        updates: { attribute },
      }),
    );
  };

  const handleDataLayerTypeChange = (type: AssetType | null) => {
    if (!type) {
      return;
    }

    const dataLayer = initDataLayer(type);
    dispatch(
      replaceDataLayer({
        dataLayerIndex: activeDataLayerIndex,
        dataLayer,
      }),
    );
  };

  const { assets, selectedUuid, checkedUuids } = useMemo(() => {
    let assignedAssets: AssignableAsset[] = [];
    let activeLayerSelectedUuid = '';
    let selectedLayerCheckedUuids: string[] = [];

    if (activeDataLayer.type === AssetType.satelliteImage) {
      assignedAssets = satelliteImages;
      activeLayerSelectedUuid = selectedSatelliteImageUuid;
      selectedLayerCheckedUuids = activeDataLayer.satelliteImageUuids;
    } else if (activeDataLayer.type === AssetType.soilDataset) {
      assignedAssets = soilDatasets;
      activeLayerSelectedUuid = activeDataLayer.datasetUuid;
    } else if (activeDataLayer.type === AssetType.yieldDataset) {
      assignedAssets = yieldDatasets;
      activeLayerSelectedUuid = activeDataLayer.datasetUuid;
    } else if (activeDataLayer.type === AssetType.asAppliedDataset) {
      assignedAssets = asAppliedDatasets;
      activeLayerSelectedUuid = activeDataLayer.datasetUuid;
    } else if (activeDataLayer.type === AssetType.topographyMap) {
      assignedAssets = topographyMaps;
      activeLayerSelectedUuid = activeDataLayer.datasetUuid;
    }

    return {
      assets: assignedAssets,
      selectedUuid: activeLayerSelectedUuid,
      checkedUuids: selectedLayerCheckedUuids,
    };
  }, [
    activeDataLayer.type,
    activeDataLayer.datasetUuid,
    activeDataLayer.satelliteImageUuids,
    satelliteImages,
    soilDatasets,
    yieldDatasets,
    asAppliedDatasets,
    topographyMaps,
    selectedSatelliteImageUuid,
  ]);

  let panels;

  if (activeDataLayer.type === AssetType.satelliteImage) {
    panels = [
      <CreateAnalysisSatelliteImagesPanel
        key="top-panel"
        selectedDataLayerType={activeDataLayer.type}
        dataLayersOptions={dataLayersOptions}
        selectedIndex={activeDataLayer.index}
        indexes={SATELLITE_INDEXES}
        onIndexChange={handleSatelliteIndexChange}
        onDataLayerTypeChange={handleDataLayerTypeChange}
      />,
    ];
  } else if (
    activeDataLayer.type === AssetType.soilDataset ||
    activeDataLayer.type === AssetType.yieldDataset ||
    activeDataLayer.type === AssetType.asAppliedDataset
  ) {
    panels = [
      <CreateAnalysisDatasetsPanel
        key="top-panel"
        selectedDataLayerType={activeDataLayer.type}
        dataLayersOptions={dataLayersOptions}
        selectedAttribute={activeDataLayer.attribute}
        onAttributeChange={handleDatasetAttributeChange}
        onDataLayerTypeChange={handleDataLayerTypeChange}
      />,
    ];
  } else if (activeDataLayer.type === AssetType.topographyMap) {
    panels = [
      <CreateAnalysisTopographyMapsPanel
        key="top-panel"
        selectedDataLayerType={activeDataLayer.type}
        dataLayersOptions={dataLayersOptions}
        selectedAttribute={activeDataLayer.attribute}
        onAttributeChange={handleDatasetAttributeChange}
        onDataLayerTypeChange={handleDataLayerTypeChange}
      />,
    ];
  }

  const recommendedImagesPicker = (
    <CreateAnalysisRecommendedImagesPicker
      onRecommendedSelect={handleRecommendedSelect}
    />
  );

  const { dataLayersListComponent } = useDataLayersList({
    mode:
      activeDataLayer.type === AssetType.satelliteImage
        ? 'multiSelect'
        : 'default',
    assets,
    selectedUuid,
    checkedUuids,
    panels,
    recommendedImagesPicker,
    onAssetClick: handleAssetClick,
    onAssetCheckboxClick: handleImageSelectionToggle,
    onAllCheckboxClick: handleAllSelectionToggle,
  });

  return (
    <div className="create-multi-year-analysis-data-layers-panel">
      <div className="create-multi-year-analysis-data-layers-panel__content">
        {dataLayersListComponent}
      </div>
    </div>
  );
}
