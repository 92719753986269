export const transform = (colorSchemas) =>
  colorSchemas.map((colorSchema) => ({
    title: colorSchema.title,
    uuid: colorSchema.uuid,
    colors: colorSchema.colors.reduce((acc, color) => {
      acc[color.index] = color.hexCode;

      return acc;
    }, {}),
  }));

export const prepareColors = (colors) =>
  Object.entries(colors).reduce((acc, [key, value]) => {
    acc.push({ index: key, hexCode: value });

    return acc;
  }, []);
