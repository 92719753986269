import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { parseFieldsListSearchParams } from '../helpers/functions/url';
import {
  selectLoaded as selectLabelsLoaded,
  selectLabelsMap,
} from '../../../labels/labelsSelectors';
import transformToFilterUpdates from '../helpers/functions/transformToFilterUpdates';
import { Label } from '../../../field/types/field';
import { selectFilter, selectLabelsExpanded } from '../fieldsListSelectors';
import { AutocompleteItem } from '../../autocomplete/types/autocomplete';
import { fetchLabels } from '../../../labels/labelsSlice';
import useDidMount from '../../../../hooks/useDidMount';
import { updateFieldsListParams } from '../fieldsListSlice';

export default function useFieldsListParams() {
  const location = useLocation();
  const labelsMap = useSelector(selectLabelsMap);
  const labelsLoaded = useSelector(selectLabelsLoaded);
  const filter = useSelector(selectFilter);
  const labelsExpanded = useSelector(selectLabelsExpanded);

  const dispatch = useDispatch();

  const [fieldsListParams, setFieldsListParams] = useState<{
    farmUuid: string;
    fieldName?: string;
    labels?: Label[];
    labelsExpanded: boolean;
    autocompleteValue: AutocompleteItem[];
  } | null>(null);

  useEffect(() => {
    const searchParamsValues = parseFieldsListSearchParams(location.search);

    if (searchParamsValues?.autocomplete && !labelsLoaded) {
      return;
    }

    const { labels, name } = searchParamsValues
      ? transformToFilterUpdates(
          searchParamsValues?.autocomplete ?? [],
          labelsMap,
        )
      : transformToFilterUpdates(filter.autocompleteValue, labelsMap);

    const params = {
      farmUuid: searchParamsValues?.farm ?? filter.farmUuid,
      fieldName: name,
      labels,
      labelsExpanded: searchParamsValues?.labelsExpanded ?? labelsExpanded,
      autocompleteValue:
        searchParamsValues?.autocomplete ?? filter.autocompleteValue,
    };

    setFieldsListParams(params);
  }, [location.search, labelsLoaded, labelsMap, filter, labelsExpanded]);

  useEffect(() => {
    dispatch(fetchLabels());
  }, [dispatch]);

  useDidMount(() => {
    const searchParamsValues = parseFieldsListSearchParams(location.search);

    dispatch(
      updateFieldsListParams({
        filter: {
          ...(searchParamsValues?.farm
            ? { farmUuid: searchParamsValues.farm }
            : null),
          ...(searchParamsValues?.autocomplete
            ? { autocompleteValue: searchParamsValues.autocomplete }
            : null),
        },
        labelsExpanded: searchParamsValues?.labelsExpanded,
      }),
    );
  });

  return fieldsListParams;
}
