import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { FullAttributeItem } from '../../../../../../../types/ui';
import MinMaxConditions from '../../../../../../../components/MinMaxConditions';
import { useAppSelector } from '../../../../../../../../../../app/store/helpers/functions';
import { selectYieldDataset } from '../../../../../../../../../field/fieldSelectors';
import {
  selectCalibrateMinMaxConditions,
  selectDatasetUuid,
} from '../../../../../../../cleanCalibrateSelectors';
import {
  createFullAttributeItem,
  transformCalibrateMinMaxConditions,
} from '../../../../../../../helpers/functions/ui';
import useDidMount from '../../../../../../../../../../hooks/useDidMount';
import {
  addEmptyCalibrateMinMaxConditions,
  initCalibrateMinMaxConditions,
  removeCalibrateMinMaxCondition,
  updateCalibrateMinMaxCondition,
} from '../../../../../../../cleanCalibrateSlice';
import { populateMinMaxCalibrateConditions } from '../../../../../../../helpers/functions/conditions';

export default function CalibrateTabConditionalContent() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const datasetUuid = useAppSelector(selectDatasetUuid);
  const dataset = useAppSelector((state) =>
    selectYieldDataset(state, datasetUuid),
  );
  const minMaxConditions = useAppSelector(selectCalibrateMinMaxConditions);

  const datasetAttributesItems = useMemo(
    () =>
      (dataset?.fullAttributes || []).map((fullAttribute) =>
        createFullAttributeItem(fullAttribute),
      ),
    [dataset],
  );

  const handleAddCondition = () => {
    dispatch(addEmptyCalibrateMinMaxConditions());
  };

  const handleConditionAttributeChange = (
    attributeItem: FullAttributeItem,
    index: number,
  ) => {
    const update = populateMinMaxCalibrateConditions(
      { calibrationAttribute: attributeItem?.value ?? null },
      dataset?.statistics,
    );

    dispatch(
      updateCalibrateMinMaxCondition({
        index,
        update,
      }),
    );
  };

  const handleConditionMinChange = (min: number | null, index: number) => {
    dispatch(
      updateCalibrateMinMaxCondition({
        index,
        update: { min },
      }),
    );
  };

  const handleConditionMinIncludedChange = (
    minIncluded: boolean,
    index: number,
  ) => {
    dispatch(
      updateCalibrateMinMaxCondition({
        index,
        update: { minIncluded },
      }),
    );
  };

  const handleConditionMaxChange = (max: number | null, index: number) => {
    dispatch(
      updateCalibrateMinMaxCondition({
        index,
        update: { max },
      }),
    );
  };

  const handleConditionMaxIncludedChange = (
    maxIncluded: boolean,
    index: number,
  ) => {
    dispatch(
      updateCalibrateMinMaxCondition({
        index,
        update: { maxIncluded },
      }),
    );
  };

  const handleConditionDelete = (index: number) => {
    dispatch(removeCalibrateMinMaxCondition(index));
  };

  useDidMount(() => {
    if (minMaxConditions.length === 0) {
      dispatch(initCalibrateMinMaxConditions());
    }
  });

  return (
    <MinMaxConditions
      title={t('clean-calibrate.yield-popup.attributes')}
      attributesItems={datasetAttributesItems}
      conditions={transformCalibrateMinMaxConditions(minMaxConditions)}
      statistics={dataset?.statistics || []}
      conditionMinInfoTooltip={t(
        'clean-calibrate.yield-popup.tabs.calibrate.configure.min-max-conditions.min-info',
      )}
      conditionMaxInfoTooltip={t(
        'clean-calibrate.yield-popup.tabs.calibrate.configure.min-max-conditions.max-info',
      )}
      onAddConditionClick={handleAddCondition}
      onConditionAttributeChange={handleConditionAttributeChange}
      onConditionMinChange={handleConditionMinChange}
      onConditionMinIncludedChange={handleConditionMinIncludedChange}
      onConditionMaxChange={handleConditionMaxChange}
      onConditionMaxIncludedChange={handleConditionMaxIncludedChange}
      onConditionDelete={handleConditionDelete}
    />
  );
}
