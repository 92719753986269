query getGeneratedVectorAnalysisMaps(
  $farmUuids: [ID]
  $fieldUuids: [ID]
  $vectorAnalysisMapUuids: [ID]
  $areaUnit: AreaUnit!
) {
  getFarms(farmUuids: $farmUuids) {
    uuid
    fields(fieldUuids: $fieldUuids) {
      uuid
      vectorAnalysisMaps(vectorAnalysisMapUuids: $vectorAnalysisMapUuids) {
        uuid
        fieldUuid
        name
        area(unit: $areaUnit)
        status
        zonesMapGeojson(unit: $areaUnit)
        geoMaps {
          shortName
          url
          classes
          hexColorCodes
        }
      }
    }
  }
}
