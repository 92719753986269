import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Panel from './Panel';
import {
  AssetVariableData,
  AssignableAsset,
  AssignableAssets,
} from '../../types/variables';
import GeneralPopup from '../../../../../components/Popups/GeneralPopup';
import {
  ASSET_TYPE_TO_ASSET_GROUP,
  AssetGroupType,
} from '../../../../../helpers/constants/entities/asset';
import Button from '../../../../../components/Button';
import {
  findVariableAttributeToPreselect,
  getAssetVariableDataAttributeId,
  getSatelliteImageVariableUuids,
} from '../../helpers/functions/variables';
import { isSatelliteImage } from '../../../../../helpers/functions/entities/assets';
import { FieldEntity } from '../../../../assets/types/state';
import { AreaUnit } from '../../../../user/helpers/constants/user';
import { EQUATION_BASED_ANALYSIS_SATELLITE_INDEXES } from '../../../dataVariable/helpers/constants/dataVariableAttribute';
import { getAttributeOptionValue } from '../../../dataVariable/helpers/functions/dataVariableAttribute';
import { getSatelliteImagesVariableName } from '../../../dataVariable/helpers/functions/dataVariable';

import './index.scss';

const Map = React.lazy(() => import('./Map'));

const getAssignedAsset = ({
  assignedAsset,
  assignedSatelliteImageUuids,
  assets,
}: {
  assignedAsset?: AssignableAsset;
  assignedSatelliteImageUuids?: string[] | null;
  assets: AssignableAssets;
}): AssignableAsset | null => {
  const assignedSatelliteImage = assets.satelliteImages?.find(
    ({ uuid }) => uuid === assignedSatelliteImageUuids?.[0],
  );
  return assignedAsset ?? assignedSatelliteImage ?? null;
};

export default function AssignVariablePopup({
  field,
  assets,
  assignedAsset,
  assignedVariable,
  satellitesLoading,
  areaUnit,
  apiKey,
  onCancel,
  onConfirm,
}: {
  field?: FieldEntity;
  assignedAsset?: AssignableAsset;
  assignedVariable?: AssetVariableData;
  assets: AssignableAssets;
  satellitesLoading: boolean;
  areaUnit: AreaUnit;
  apiKey: string;
  onCancel: () => void;
  onConfirm: ({
    asset,
    attribute,
    satelliteImageUuids,
    variableName,
  }: {
    asset: AssignableAsset | null;
    attribute: string;
    satelliteImageUuids: string[] | null;
    variableName?: string;
  }) => void;
}) {
  const { t } = useTranslation();

  const assignedSatelliteImageUuids = useMemo(
    () => getSatelliteImageVariableUuids(assignedVariable),
    [assignedVariable],
  );

  const getInitialSelectedAttribute = (
    assignedVariableData?: AssetVariableData,
  ) =>
    assignedVariableData && 'attribute' in assignedVariableData
      ? getAssetVariableDataAttributeId(assignedVariableData.attribute)
      : '';

  const [selectedAsset, setSelectedAsset] = useState<AssignableAsset | null>(
    getAssignedAsset({
      assets,
      assignedAsset,
      assignedSatelliteImageUuids,
    }),
  );
  const [selectedGroupType, setSelectedGroupType] =
    useState<AssetGroupType | null>(
      selectedAsset?.assetType
        ? ASSET_TYPE_TO_ASSET_GROUP[selectedAsset.assetType]
        : null,
    );
  const [selectedSatelliteImageUuids, setSelectedSatelliteImageUuids] =
    useState(assignedSatelliteImageUuids || null);
  const [selectedAttribute, setSelectedAttribute] = useState<string>(
    getInitialSelectedAttribute(assignedVariable),
  );

  const handleAssetItemClick = ({
    asset,
    groupType,
  }: {
    asset: AssignableAsset;
    groupType: AssetGroupType;
  }) => {
    setSelectedAsset(asset);
    setSelectedGroupType(groupType);

    if (groupType !== AssetGroupType.satelliteImages) {
      const attribute = asset ? findVariableAttributeToPreselect(asset) : '';

      setSelectedAttribute(attribute);
      setSelectedSatelliteImageUuids(null);
    }
  };

  const handleAttributeChange = (attribute: string) => {
    const attributeValue =
      selectedGroupType === AssetGroupType.satelliteImages
        ? getAttributeOptionValue(
            EQUATION_BASED_ANALYSIS_SATELLITE_INDEXES,
            attribute,
          )
        : attribute;

    if (attributeValue) {
      setSelectedAttribute(attributeValue);
    }
  };

  const handleSatelliteImageCheck = ({
    checkedImages,
    asset,
    groupType,
  }: {
    checkedImages: string[];
    asset: AssignableAsset | null;
    groupType: AssetGroupType;
  }) => {
    setSelectedGroupType(groupType);
    setSelectedSatelliteImageUuids(checkedImages);
    setSelectedAsset(asset);

    if (!selectedSatelliteImageUuids) {
      setSelectedAttribute('');
    }
  };

  const handleConfirm = () => {
    onConfirm({
      asset: selectedAsset,
      attribute: selectedAttribute,
      satelliteImageUuids: selectedSatelliteImageUuids,
      variableName:
        selectedAsset && !isSatelliteImage(selectedAsset)
          ? selectedAsset.name
          : getSatelliteImagesVariableName(
              assets.satelliteImages,
              selectedSatelliteImageUuids,
            ),
    });
  };

  const confirmDisabled = useMemo(() => {
    let result: boolean;

    if (selectedGroupType === AssetGroupType.satelliteImages) {
      result = !selectedSatelliteImageUuids?.length || !selectedAttribute;
    } else if (selectedGroupType === AssetGroupType.equationMaps) {
      result = !selectedAsset;
    } else {
      result = !selectedAsset || !selectedAttribute;
    }

    return result;
  }, [
    selectedGroupType,
    selectedSatelliteImageUuids,
    selectedAsset,
    selectedAttribute,
  ]);

  return (
    <GeneralPopup
      classes={{
        root: 'assign-variable-popup',
        actions: 'assign-variable-popup__actions-wrapper',
      }}
      title={t(
        'batch-equation-based.steps.preview.settings-view.choose-dataset',
      )}
      onCancel={onCancel}
    >
      <Panel
        key="panel"
        assets={assets}
        selectedAsset={selectedAsset}
        selectedGroupType={selectedGroupType}
        selectedAttribute={selectedAttribute}
        satellitesLoading={satellitesLoading}
        selectedSatelliteImageUuids={selectedSatelliteImageUuids}
        onAssetItemClick={handleAssetItemClick}
        onSatelliteImageCheck={handleSatelliteImageCheck}
        onAttributeChange={handleAttributeChange}
      />
      <Map
        key="map"
        field={field}
        selectedAsset={selectedAsset}
        selectedAttribute={selectedAttribute}
        areaUnit={areaUnit}
        apiKey={apiKey}
        onAttributeChange={handleAttributeChange}
      />
      <div key="actions" className="assign-variable-popup__actions-buttons">
        <Button onClick={onCancel} variant="outlined" color="primary">
          {t('general.controls.cancel')}
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          color="primary"
          disabled={confirmDisabled}
        >
          {t('general.controls.save')}
        </Button>
      </div>
    </GeneralPopup>
  );
}
