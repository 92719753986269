import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  resetFieldsListParams,
  updateFieldsListParams,
} from '../fieldsList/fieldsListSlice';

export interface FieldProfilerState {
  editMode: boolean;
  selectedFields: string[];
}

const initialState: FieldProfilerState = {
  editMode: false,
  selectedFields: [],
};

export const fieldProfilerSlice = createSlice({
  name: 'fieldProfiler',
  initialState,
  reducers: {
    toggleEditMode(state) {
      state.editMode = !state.editMode;

      if (!state.editMode) {
        state.selectedFields = [];
      }
    },
    toggleFieldSelection(state, action: PayloadAction<string>) {
      const selectedFieldsSet = new Set(state.selectedFields);

      if (selectedFieldsSet.has(action.payload)) {
        selectedFieldsSet.delete(action.payload);
      } else {
        selectedFieldsSet.add(action.payload);
      }

      state.selectedFields = Array.from(selectedFieldsSet);
    },
    setFieldsSelection(
      state,
      action: PayloadAction<{ uuids: string[]; selected: boolean }>,
    ) {
      const selectedFieldsSet = new Set(state.selectedFields);

      if (action.payload.selected) {
        action.payload.uuids.forEach((uuid) => {
          selectedFieldsSet.add(uuid);
        });
      } else {
        action.payload.uuids.forEach((uuid) => {
          selectedFieldsSet.delete(uuid);
        });
      }

      state.selectedFields = Array.from(selectedFieldsSet);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      ({ type }) =>
        type === updateFieldsListParams.type ||
        type === resetFieldsListParams.type,
      (state) => {
        state.selectedFields = initialState.selectedFields;
      },
    );
  },
});

export const { toggleEditMode, toggleFieldSelection, setFieldsSelection } =
  fieldProfilerSlice.actions;

export default fieldProfilerSlice.reducer;
