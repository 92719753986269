mutation disableSubscriptionJohnDeere(
  $input: UpdateStatusSubscriptionJohnDeereInput!
) {
  disableSubscriptionJohnDeere(input: $input) {
    id
    name
    type
    status
    orgIds
    fieldOperationTypes
  }
}
