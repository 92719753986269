import i18n from 'i18next';

import { AssetType } from '../../constants/entities/asset';
import {
  AttributeStatistics,
  AttributeUnit,
  TransformedDataset,
} from '../../types/dataset';
import { OverviewItem } from '../../../components/Map/Controls/MiniLegend/types';
import { isNumber } from '../utils/number';
import { prettifyNumber } from '../../markup';
import { AreaUnit } from '../../../features/user/helpers/constants/user';
import { convertDateToDisplayFormat } from '../utils/date';
import { getI18nAreaUnit } from '../..';
import { SatelliteImageIndexStatistics } from '../../../features/satelliteImages/types/satelliteImage';
import {
  getAttributeUnitLabel,
  convertAttributeUnitToAbsoluteUnitLabel,
} from '../units/attributeUnit';
import { ColorRange } from '../../types/map';
import { GeoMap } from '../../types/api';

// TODO: replace with ASSET_TYPE_TO_I18N_KEY
export const getAssetTypeLabel = (type: AssetType): string => {
  if (type === AssetType.satelliteImage) {
    return i18n.t('general.shared.satellite-monitoring');
  }

  if (type === AssetType.yieldDataset) {
    return i18n.t('general.shared.yield-data');
  }

  if (type === AssetType.asAppliedDataset) {
    return i18n.t('general.shared.as-applied-data');
  }

  if (type === AssetType.soilDataset) {
    return i18n.t('general.shared.soil-data');
  }

  if (type === AssetType.topographyMap) {
    return i18n.t('general.shared.topography');
  }

  if (type === AssetType.vectorAnalysisMap) {
    return i18n.t('general.shared.zones-map');
  }

  if (type === AssetType.equationMap) {
    return i18n.t('general.shared.equation-map');
  }

  return 'UNKNOWN TYPE';
};

export const hasAreaUnit = (unit?: string | null): boolean => {
  if (!unit) return false;

  const areaUnits = ['ac', 'ha', 'acres', 'hectares'];
  const pattern = new RegExp(
    `^(${areaUnits.join('|')})$|` + // "ha", "ac", "acres", "hectares"
      `^(${areaUnits.join('|')})_|` + // "ha_", "ac_", "acres_", "hectares_"
      `_((${areaUnits.join('|')}))$|` + // "_ha", "_ac", "_acres", "_hectares"
      `_(${areaUnits.join('|')})_`, // "_ha_", "_ac_", "_acres_", "_hectares_"
    'i',
  );

  return pattern.test(unit);
};

export const getDatasetStatisticsItems = ({
  viewType,
  statistics,
  attributeName,
  unit,
}: {
  viewType: string;
  statistics?: AttributeStatistics[] | null;
  attributeName: string;
  unit?: AttributeUnit | null;
}) => {
  if (!statistics) {
    return [];
  }

  const { attribute: attributeValue, ...statistic } =
    statistics.find(
      ({ attribute }) => attribute.toLowerCase() === viewType.toLowerCase(),
    ) || {};

  return Object.entries(statistic).reduce<OverviewItem[]>(
    (acc, [key, value]) => {
      if (isNumber(value)) {
        if (key === 'avg' && hasAreaUnit(unit)) {
          acc.push({
            label: `${i18n.t('general.controls.map-legend.range.avg')} ${attributeName}`,
            value: `${prettifyNumber(value)} ${getAttributeUnitLabel(unit)}`,
            key,
          });
        } else if (key === 'sum' && hasAreaUnit(unit)) {
          acc.push({
            label: `${i18n.t('general.controls.map-legend.range.sum')} ${attributeName}`,
            value: `${prettifyNumber(value)} ${convertAttributeUnitToAbsoluteUnitLabel(unit)}`,
            key,
          });
        } else {
          acc.push({
            label: i18n.t(`general.controls.map-legend.statistics.${key}`),
            value: prettifyNumber(value),
            key,
          });
        }
      }

      return acc;
    },
    [],
  );
};

export const getDatasetOverviewItems = (
  dataset: TransformedDataset,
  areaUnit?: AreaUnit,
) => {
  const overviewItems = [
    {
      label: i18n.t('general.controls.map-legend.dataset-creation-date'),
      value: dataset.operationFileDate
        ? convertDateToDisplayFormat(dataset.operationFileDate)
        : '',
    },
    {
      label: i18n.t('general.controls.map-legend.operation-start-date'),
      value: dataset.operationStartDate
        ? convertDateToDisplayFormat(dataset.operationStartDate)
        : '',
    },
    {
      label: i18n.t('general.controls.map-legend.percent-of-field-area'),
      value: isNumber(dataset.fieldCoverage)
        ? `${prettifyNumber(dataset.fieldCoverage * 100)}%`
        : '',
    },
    {
      label: i18n.t('general.controls.map-legend.completed-area'),
      value:
        dataset.fieldCoveredArea && dataset.fieldCoveredArea !== -1
          ? `${prettifyNumber(dataset.fieldCoveredArea)} ${getI18nAreaUnit(areaUnit)}`
          : '',
    },
    {
      label: i18n.t('general.controls.map-legend.amount-of-geometries'),
      value: prettifyNumber(dataset.number),
    },
  ];

  return overviewItems.filter((item) => item.value);
};

export const getSatelliteOverviewItems = (
  viewType: string,
  statistics?: SatelliteImageIndexStatistics[],
  compareToStatistics: SatelliteImageIndexStatistics[] = [],
) => {
  if (!statistics) {
    return [];
  }

  const { index: indexValue, ...statistic } =
    statistics.find(
      ({ index }) => index?.toLowerCase() === viewType.toLowerCase(),
    ) || {};
  const { index: indexCompareTo, ...compareToStatistic } =
    compareToStatistics.find(
      ({ index }) => index?.toLowerCase() === viewType.toLowerCase(),
    ) || {};

  return Object.entries(statistic).reduce<OverviewItem[]>(
    (acc, [key, value]) => {
      const compareToValue =
        compareToStatistic[
          key as keyof Omit<SatelliteImageIndexStatistics, 'index'>
        ];

      return [
        ...acc,
        {
          label: i18n.t(`general.controls.map-legend.statistics.${key}`),
          value: prettifyNumber(value, 3),
          delta: compareToValue
            ? prettifyNumber(value - compareToValue, 3)
            : null,
        },
      ];
    },
    [],
  );
};

export const getDatasetColorsRanges = ({
  geoMap,
}: {
  geoMap?: GeoMap;
}): ColorRange[] => {
  if (!geoMap?.classes?.length) return [];

  const geoMapClasses = geoMap.classes.map((classValue, index) => ({
    color: geoMap.hexColorCodes?.[index] ?? '',
    value: classValue,
  }));

  const min = geoMapClasses[0];
  const max = geoMapClasses[geoMapClasses.length - 1];
  const definedRanges = [];

  if (geoMapClasses.length > 1) {
    geoMapClasses.slice(0, -1).forEach((item, index) => {
      definedRanges.push({
        min: geoMapClasses[index].value,
        max: geoMapClasses[index + 1].value,
        color: item.color ?? null,
      });
    });
  } else {
    definedRanges.push({
      min: min.value,
      max: max.value,
      color: min.color ?? null,
    });
  }

  const sortedDefinedRanges = definedRanges.sort((a, b) => b.min - a.min); // from max range to min range

  const ranges = [
    { min: max.value, color: null },
    ...sortedDefinedRanges,
    { max: min.value, color: null },
  ];

  return ranges.map((range) => ({
    color: range.color,
    min: prettifyNumber(range.min) || null,
    max: prettifyNumber(range.max) || null,
  }));
};
