import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18n from 'i18next';
import { closeSnackbar } from 'notistack';

import { createAppAsyncThunk } from '../../../app/store/helpers/functions';
import { selectFieldUuid } from '../../field/fieldSelectors';
import {
  saveVamapWithGeoMaps,
  fetchVamapWithGeojsonAndPins,
} from '../../field/fieldSlice';
import { selectAreaUnit } from '../../user/userSelectors';
import { refreshStatistics as refreshStatisticsAPI } from './zonesMapAPI';
import { selectUpdateInProgress, selectUpdates } from './zonesMapSelectors';
import { prepareZonesMapJson } from '../../../helpers/analysis';
import { convertRatesToNumbers } from '../../../helpers/functions/entities/geojson';
import { CustomError } from '../../../helpers/functions/utils/errorHandling';
import {
  errorNotify,
  successNotify,
} from '../../notifications/helpers/functions/notify';
import { isTimeoutError } from '../../field/helpers/functions/api';
import PlatformEventAction from '../../subscription/helpers/constants/action';
import {
  ZonesMapFeature,
  ZonesMapGeoJson,
} from '../../../helpers/types/vectorAnalysisMap/geojson';
import { AppThunk } from '../../../app/store/helpers/types';
import { ParsedEvent } from '../../subscription/types/event';
import { VectorAnalysisMapType } from '../../../helpers/constants/entities/vectorAnalysisMap';
import { Product, ZoneData } from './types/product';
import { ProductUnit } from '../../../helpers/constants/units/productUnit';
import LoadStatus from '../../../helpers/constants/utils/loadStatus';
import { processDistributionData } from './helpers/functions/distributionData';

export interface ZonesMapState {
  // Track changes to the geometries
  geometriesChanged: boolean;
  // Track any changes to the zonesMap
  zonesMapChanged: boolean;
  updateInProgress: boolean;
  backTo: 'zonesMaps' | 'field';
  selectedZone: number | null;
  instrument: string | null;
  // To manage load status of vamap fetched by fieldSlice.
  // LoadStatus.error also means that vamap is not found.
  loadStatus: LoadStatus;
  vamapType: VectorAnalysisMapType | null;
  updates: {
    // Track changes to geometries
    zonesMapGeojson: ZonesMapGeoJson | null;
    colors: string[] | null;
  };
  distributionData: {
    products: Product[];
    zones: ZoneData[];
    ratesPerProduct: Record<number, string[]>; // productIndex -> rate per zone index
  };
}

const initialState: ZonesMapState = {
  geometriesChanged: false,
  zonesMapChanged: false,
  updateInProgress: false,
  backTo: 'zonesMaps',
  selectedZone: null,
  instrument: null,
  loadStatus: LoadStatus.idle,
  vamapType: null,
  updates: {
    zonesMapGeojson: null,
    colors: null,
  },
  distributionData: {
    products: [],
    zones: [],
    ratesPerProduct: {},
  },
};

export const refreshStatistics = createAppAsyncThunk(
  'zonesMap/refreshStatistics',
  async ({ uuid }: { uuid: string }, { getState, dispatch }) => {
    const state = getState();
    const updates = selectUpdates(state);
    const fieldUuid = selectFieldUuid(state);
    const areaUnit = selectAreaUnit(state);

    const zonesMapGeojson = updates.zonesMapGeojson
      ? convertRatesToNumbers(updates.zonesMapGeojson)
      : null;

    if (!zonesMapGeojson) {
      throw new CustomError(
        '[UI Zones Map] Unable to refresh statistics: zonesMapGeojson not present.',
      );
    }

    try {
      const updatedZonesMapGeojson = await refreshStatisticsAPI({
        fieldUuid: fieldUuid || '',
        uuid: uuid || '',
        zonesMapGeojson,
        areaUnit,
      });

      successNotify({
        message: i18n.t(
          'zones-map.notifications.zones-map-statistics-refreshed',
        ),
      });

      return updatedZonesMapGeojson;
    } catch (error) {
      errorNotify({
        error: new CustomError('[UI Zones Map] Unable to refresh statistics.', {
          cause: error,
        }),
        dispatch,
      });

      throw error;
    }
  },
);

export const subscription =
  (parsedEvent: ParsedEvent, uuid: string): AppThunk =>
  async (dispatch, getState) => {
    const { action, pathLength, vectorAnalysisMapUuid } = parsedEvent;

    const state = getState();
    const inProgress = selectUpdateInProgress(state);

    if (
      pathLength !== 3 ||
      action !== PlatformEventAction.modify ||
      vectorAnalysisMapUuid !== uuid
    ) {
      return;
    }

    if (inProgress) {
      dispatch(finishSaving());
      closeSnackbar(uuid);
      successNotify({
        message: i18n.t('zones-map.notifications.zones-map-saved'),
      });
    }
  };

export const zonesMapSlice = createSlice({
  name: 'zonesMap',
  initialState,
  reducers: {
    setInstrument(state, action) {
      state.instrument = action.payload;
    },
    setSelectedZone(state, action) {
      state.selectedZone = action.payload;
    },
    setBackTo(state, action: PayloadAction<'zonesMaps' | 'field'>) {
      state.backTo = action.payload;
    },
    applyInstrumentUpdates(
      state,
      action: PayloadAction<{
        zonesMapGeojson: ZonesMapGeoJson | null;
        colors: string[] | null;
        geometriesChanged: boolean;
      }>,
    ) {
      const { zonesMapGeojson, colors, geometriesChanged } = action.payload;

      state.geometriesChanged = geometriesChanged;
      state.instrument = initialState.instrument;
      state.updates.zonesMapGeojson = zonesMapGeojson;
      state.updates.colors = colors;

      if (zonesMapGeojson && colors) {
        const distributionData = processDistributionData(
          zonesMapGeojson.features,
          colors,
        );

        state.distributionData.zones = distributionData.zones;
        state.distributionData.products = distributionData.products;
        state.distributionData.ratesPerProduct =
          distributionData.ratesPerProduct;
      }

      state.zonesMapChanged = true;
    },
    finishSaving(state) {
      state.updateInProgress = false;
    },
    resetUpdates(
      state,
      action: PayloadAction<{
        features: ZonesMapFeature[];
        zoneColors: string[];
      }>,
    ) {
      state.updates = initialState.updates;
      state.geometriesChanged = false;

      const distributionData = processDistributionData(
        action.payload.features,
        action.payload.zoneColors,
      );

      state.distributionData.zones = distributionData.zones;
      state.distributionData.products = distributionData.products;
      state.distributionData.ratesPerProduct = distributionData.ratesPerProduct;

      state.zonesMapChanged = false;
    },
    resetInstrument(state) {
      state.instrument = initialState.instrument;
    },
    reset() {
      return initialState;
    },
    setVamapType(state, action: PayloadAction<VectorAnalysisMapType>) {
      state.vamapType = action.payload;
      state.zonesMapChanged = true;
    },
    addEmptyProduct(state, action: PayloadAction<{ name: string }>) {
      state.distributionData.products.push({
        name: action.payload.name,
        unit: ProductUnit.kgHa,
        price: '',
        customRate: '',
        totalAmount: '',
        averageRate: '',
        minValue: '',
        maxValue: '',
        threshold: '',
        zoneDifference: 10,
        relationshipType: 'normal',
        distributionMethod: 'total',
      });

      state.zonesMapChanged = true;
    },
    updateProduct(
      state,
      action: PayloadAction<{ productIndex: number; product: Product }>,
    ) {
      state.distributionData.products[action.payload.productIndex] =
        action.payload.product;

      state.zonesMapChanged = true;
    },
    removeProduct(state, action: PayloadAction<{ productIndex: number }>) {
      const { productIndex } = action.payload;

      state.distributionData.products.splice(productIndex, 1);

      if (productIndex != null) {
        const { [productIndex]: removedRates, ...cleanedRatesPerProduct } =
          state.distributionData.ratesPerProduct;
        state.distributionData.ratesPerProduct = cleanedRatesPerProduct;
      }

      state.zonesMapChanged = true;
    },
    setProductRate(
      state,
      action: PayloadAction<{
        productIndex: number;
        zoneId: number;
        rate: string;
      }>,
    ) {
      const { productIndex, zoneId, rate } = action.payload;

      if (productIndex == null) return;

      if (!state.distributionData.ratesPerProduct[productIndex]) {
        state.distributionData.ratesPerProduct[productIndex] = [];
      }

      // Make sure we have enough elements in the array
      if (
        state.distributionData.ratesPerProduct[productIndex].length < zoneId
      ) {
        state.distributionData.ratesPerProduct[productIndex] = [
          ...state.distributionData.ratesPerProduct[productIndex],
          ...Array(
            zoneId -
              state.distributionData.ratesPerProduct[productIndex].length,
          ).fill(''),
        ];
      }

      // Update the rate for this zone (adjusting for 0-based indexing)
      state.distributionData.ratesPerProduct[productIndex][zoneId - 1] = rate;

      state.zonesMapChanged = true;
    },
    initDistributionData(
      state,
      action: PayloadAction<{
        features: ZonesMapFeature[];
        zoneColors: string[];
      }>,
    ) {
      const distributionData = processDistributionData(
        action.payload.features,
        action.payload.zoneColors,
      );

      state.distributionData.zones = distributionData.zones;
      state.distributionData.products = distributionData.products;
      state.distributionData.ratesPerProduct = distributionData.ratesPerProduct;
    },
    updateProductRates(
      state,
      action: PayloadAction<{
        productIndex: number;
        rates: string[];
      }>,
    ) {
      const { productIndex, rates } = action.payload;
      state.distributionData.ratesPerProduct[productIndex] = rates;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refreshStatistics.pending, (state) => {
        state.updateInProgress = true;
      })
      .addCase(refreshStatistics.fulfilled, (state, action) => {
        state.updateInProgress = false;
        state.geometriesChanged = false;
        state.updates.zonesMapGeojson = prepareZonesMapJson(action.payload);
      })
      .addCase(refreshStatistics.rejected, (state) => {
        state.updateInProgress = false;
      })
      .addCase(saveVamapWithGeoMaps.pending, (state) => {
        state.updateInProgress = true;
      })
      .addCase(saveVamapWithGeoMaps.fulfilled, (state) => {
        state.updates = initialState.updates;
        state.geometriesChanged = false;
        state.zonesMapChanged = false;
        state.updateInProgress = false;
      })
      .addCase(saveVamapWithGeoMaps.rejected, (state, action) => {
        if (!isTimeoutError(action.payload)) {
          state.updateInProgress = false;
        }
      })
      .addCase(fetchVamapWithGeojsonAndPins.pending, (state) => {
        state.loadStatus = LoadStatus.loading;
      })
      .addCase(fetchVamapWithGeojsonAndPins.fulfilled, (state) => {
        state.loadStatus = LoadStatus.success;
      })
      .addCase(fetchVamapWithGeojsonAndPins.rejected, (state) => {
        state.loadStatus = LoadStatus.error;
      });
  },
});

const { finishSaving } = zonesMapSlice.actions;

export const {
  setInstrument,
  setSelectedZone,
  setBackTo,
  applyInstrumentUpdates,
  resetUpdates,
  resetInstrument,
  reset,
  setVamapType,
  addEmptyProduct,
  setProductRate,
  updateProduct,
  removeProduct,
  initDistributionData,
  updateProductRates,
} = zonesMapSlice.actions;

export default zonesMapSlice.reducer;
