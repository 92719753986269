mutation AddColorSchemas($input: ColorSchemasInput!) {
  addColorSchemas(input: $input) {
    title
    uuid
    colors {
      index
      hexCode
    }
  }
}
