query getFarmsBboxes($farmUuids: [ID]) {
  getFarms(farmUuids: $farmUuids) {
    uuid
    name
    farmOwnership
    fields(fieldStatuses: [TILES_REGISTERED, GRIDS_CREATED]) {
      uuid
      boundingBox
      status
      statusMessage
    }
  }
}
