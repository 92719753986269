import React from 'react';
import i18n from 'i18next';

import type { Option } from '../../../../../components/ComboBox';
import isAssetJDExportDisabled from '../../../jdExport/helpers/functions/isAssetJDExportDisabled';
import NoProductsToExport from '../../components/NoProductsToExport';

export const isJDExportAsWorkPlanDisabled = ({
  jdWorkPlanExportAvailable,
  ...otherJDProfileSettings
}: {
  jdProfileIsHealth: boolean;
  jdProfileIsAuthorized: boolean;
  isSynchronizedJohnDeereField: boolean;
  jdWorkPlanExportAvailable: boolean;
}) =>
  isAssetJDExportDisabled(otherJDProfileSettings) || !jdWorkPlanExportAvailable;

export const isVamapJDExportAsWorkPlanDisabled = ({
  jdWorkPlanExportAvailable,
  assetHasNoRatesAssigned,
  ...otherJDProfileSettings
}: {
  jdProfileIsHealth: boolean;
  jdProfileIsAuthorized: boolean;
  isSynchronizedJohnDeereField: boolean;
  jdWorkPlanExportAvailable: boolean;
  assetHasNoRatesAssigned: boolean;
}) =>
  isAssetJDExportDisabled(otherJDProfileSettings) ||
  !jdWorkPlanExportAvailable ||
  assetHasNoRatesAssigned;

export function getComboBoxOptionSelected<T>(
  option: Option<T>,
  value: Option<T>,
) {
  return option.value === value?.value;
}

export const getCreateProductsProps = ({
  productManagerLink,
  onProductManagerLinkClick,
}: {
  productManagerLink: string;
  onProductManagerLinkClick: () => void;
}) => ({
  actions: [
    {
      isButton: false,
    },
  ],
  renderAction: () => (
    <NoProductsToExport
      title={i18n.t('general.popups.export-work-plan.no-products.title')}
      description={i18n.t(
        'general.popups.export-work-plan.no-products.description',
      )}
      onCLick={onProductManagerLinkClick}
      productManagerLink={productManagerLink}
    />
  ),
});
