import { API, graphqlOperation } from '@aws-amplify/api';

import refreshVamapStatisticsMutation from './graphql/mutations/refreshVamapStatistics.gql';
import { ZonesMapGeoJson } from '../../../helpers/types/vectorAnalysisMap/geojson';
import { AreaUnit } from '../../user/helpers/constants/user';
import { RefreshStatisticsResponse } from './types/api';

// eslint-disable-next-line import/prefer-default-export
export const refreshStatistics = async ({
  fieldUuid,
  uuid,
  zonesMapGeojson,
  areaUnit,
}: {
  fieldUuid: string;
  uuid: string;
  zonesMapGeojson: ZonesMapGeoJson<number>;
  areaUnit: AreaUnit;
}) => {
  const { data } = (await API.graphql(
    graphqlOperation(refreshVamapStatisticsMutation, {
      input: {
        fieldUuid,
        uuid,
        zonesMapGeojson: JSON.stringify(zonesMapGeojson),
        areaUnit,
      },
    }),
  )) as RefreshStatisticsResponse;

  if (data?.refreshVectorAnalysisMapStatistics) {
    return JSON.parse(
      data.refreshVectorAnalysisMapStatistics,
    ) as ZonesMapGeoJson<number>;
  }

  return null;
};
