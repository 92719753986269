import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import './index.scss';

export default function OperationTypesPanel({
  children,
}: {
  children: ReactNode;
}) {
  const { t } = useTranslation();

  return (
    <div className="operations-type-panel-layout">
      <div className="operations-type-panel-layout__header">
        <Typography className="operations-type-panel-layout__header-text">
          {t('operations.type-panel.operation')}
        </Typography>
        <Typography className="operations-type-panel-layout__header-text">
          {t('operations.type-panel.last-update')}
        </Typography>
      </div>
      <div className="operations-type-panel-layout__types-list-container">
        <ul className="operations-type-panel-layout__types-list">{children}</ul>
      </div>
    </div>
  );
}
