import { isDateMatch } from '.';
import type { DefaultAssetGroupFilters } from '../../types/assetGroup';

export default function filterDatasets<
  T extends { name?: string; operationFileDate?: string },
>(filter: DefaultAssetGroupFilters, items: T[] = []) {
  const { nameFilterValue = '', createdFrom, createdTo } = filter || {};
  const preparedNameFilterValue = nameFilterValue
    ? nameFilterValue.toLowerCase()
    : '';

  return items.filter((item) => {
    const nameMatched =
      !nameFilterValue ||
      (item.name || '').toLowerCase().includes(preparedNameFilterValue);

    if (!item.operationFileDate) {
      return nameMatched;
    }

    return (
      nameMatched && isDateMatch(item.operationFileDate, createdFrom, createdTo)
    );
  });
}
