import type { AttributeStatistics } from '../../../../../helpers/types/dataset';
import type {
  MinMaxCalibrateCondition,
  MinMaxCleanCondition,
  NullableAvgTotalCalibrateCondition,
  NullableMinMaxCalibrateCondition,
  NullableMinMaxCleanCondition,
  NullableMinMaxCleanUSDACondition,
} from '../../types/actions';

export const isSmartCleanParamsValid = (
  params: {
    targetAttribute: string | null;
    conditionMinMaxClean: NullableMinMaxCleanCondition[];
  },
  statistics: AttributeStatistics[] = [],
): params is {
  targetAttribute: string;
  conditionMinMaxClean: MinMaxCleanCondition[];
} =>
  !!params.targetAttribute &&
  isCleanMinMaxConditionsValid(params.conditionMinMaxClean, statistics);

export const isUSDACleanParamsValid = (
  params: {
    conditionMinMaxClean: (
      | NullableMinMaxCleanCondition
      | NullableMinMaxCleanUSDACondition
    )[];
  },
  statistics: AttributeStatistics[] = [],
): params is {
  conditionMinMaxClean: MinMaxCleanCondition[];
} =>
  !!params.conditionMinMaxClean.length &&
  isCleanMinMaxConditionsValid(params.conditionMinMaxClean, statistics);

const isCleanMinMaxConditionsValid = (
  conditions: (
    | NullableMinMaxCleanCondition
    | NullableMinMaxCleanUSDACondition
  )[],
  statistics: AttributeStatistics[] = [],
): conditions is MinMaxCleanCondition[] =>
  conditions.every((condition) => {
    const attributeStatistics = statistics.find(
      (statistic) => statistic.attribute === condition.cleanAttribute,
    );

    const hasStdNumber = 'stdNumber' in condition;
    const stdNumberValid = hasStdNumber ? !!condition.stdNumber : true;

    let result =
      !!condition.cleanAttribute &&
      stdNumberValid &&
      (condition.min != null || condition.max != null || hasStdNumber);

    if (condition.min != null) {
      if (attributeStatistics?.min == null) {
        result = false;
      } else {
        const maxLimit = condition?.max || attributeStatistics.max;
        result =
          result &&
          attributeStatistics.min <= condition.min &&
          condition.min <= maxLimit;
      }
    }

    if (condition.max != null) {
      if (attributeStatistics?.max == null) {
        result = false;
      } else {
        const minLimit = condition?.min || attributeStatistics.min;
        result =
          result &&
          attributeStatistics.max >= condition.max &&
          condition.max >= minLimit;
      }
    }

    return result;
  });

export const isCalibratePathwiseParamsValid = (params: {
  calibrationAttributes: string[] | null;
  conditionPathwiseCalibration: {
    calibrationBasis: string | null;
  };
  conditionAvgTotalCalibration: NullableAvgTotalCalibrateCondition[];
}): params is {
  calibrationAttributes: string[];
  conditionPathwiseCalibration: {
    calibrationBasis: string;
  };
  conditionAvgTotalCalibration: {
    [K in keyof NullableAvgTotalCalibrateCondition]: Exclude<
      NullableAvgTotalCalibrateCondition[K],
      null
    >;
  }[];
} =>
  params.calibrationAttributes?.length !== 0 &&
  !!params.conditionPathwiseCalibration.calibrationBasis &&
  isCalibrateAvgTotalConditionsValid(params.conditionAvgTotalCalibration);

export const isCalibrateAvgTotalParamsValid = (params: {
  conditionAvgTotalCalibration: NullableAvgTotalCalibrateCondition[];
}): params is {
  conditionAvgTotalCalibration: {
    [K in keyof NullableAvgTotalCalibrateCondition]: Exclude<
      NullableAvgTotalCalibrateCondition[K],
      null
    >;
  }[];
} =>
  params.conditionAvgTotalCalibration.length !== 0 &&
  isCalibrateAvgTotalConditionsValid(params.conditionAvgTotalCalibration);

const isCalibrateAvgTotalConditionsValid = (
  conditions: NullableAvgTotalCalibrateCondition[],
) =>
  conditions.every(
    (condition) =>
      condition.calibrationAttribute &&
      (condition.average != null || condition.total != null),
  );

export const isCalibrateMinMaxParamsValid = (
  params: {
    conditionMinMaxCalibration: NullableMinMaxCalibrateCondition[];
  },
  statistics: AttributeStatistics[],
): params is {
  conditionMinMaxCalibration: MinMaxCalibrateCondition[];
} =>
  params.conditionMinMaxCalibration.length !== 0 &&
  params.conditionMinMaxCalibration.every((condition) => {
    const attributeStatistics = statistics.find(
      (statistic) => statistic.attribute === condition.calibrationAttribute,
    );
    let result =
      !!condition.calibrationAttribute &&
      (condition.min != null || condition.max != null);

    if (condition.min != null) {
      if (attributeStatistics?.min == null) {
        result = false;
      } else {
        const maxLimit = condition?.max || attributeStatistics.max;
        result =
          result &&
          attributeStatistics.min <= condition.min &&
          condition.min <= maxLimit;
      }
    }

    if (condition.max != null) {
      if (attributeStatistics?.max == null) {
        result = false;
      } else {
        const minLimit = condition?.min || attributeStatistics.min;
        result =
          result &&
          attributeStatistics.max >= condition.max &&
          condition.max >= minLimit;
      }
    }

    return result;
  });
