import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import RadioGroup from '../../../RadioGroup';
import Select from '../../../Select';
import { BufferSize, GeoFormat } from '../../../../helpers/constants/api';
import { isDataset } from '../../../../helpers/functions/entities/assets';
import { PLANET_PROVIDER } from '../../../../features/satelliteImages/helpers/constants';
import ExportData from '..';

import './index.scss';

const getI18nString = (item, key) =>
  isDataset(item)
    ? i18n.t(`general.popups.export-source-data.dataset.${key}`)
    : i18n.t(`general.popups.export-source-data.default.${key}`);

const ExportSourceData = ({ ...props }) => {
  const { t } = useTranslation();
  const typeOptions = [
    {
      value: GeoFormat.geojson,
      title: t('general.popups.export-source-data.items.geo-json.title'),
      description: getI18nString(props.item, 'items.geo-json.description'),
    },
    {
      value: GeoFormat.geotiff,
      title: t('general.popups.export-source-data.items.geo-tiff.title'),
      description: getI18nString(props.item, 'items.geo-tiff.description'),
    },
  ];
  const bufferOptions = [
    {
      value: BufferSize.NoBuffer,
      title: t('general.controls.pixels-buffer-options.no-buffer'),
    },
    {
      value: BufferSize.PlusOnePixel,
      title: t('general.controls.pixels-buffer-options.plus-one-pixel'),
    },
    {
      value: BufferSize.PlusTwoPixels,
      title: t('general.controls.pixels-buffer-options.plus-two-pixels'),
    },
    {
      value: BufferSize.MinusOnePixel,
      title: t('general.controls.pixels-buffer-options.minus-one-pixel'),
    },
    {
      value: BufferSize.MinusTwoPixels,
      title: t('general.controls.pixels-buffer-options.minus-two-pixels'),
    },
  ];
  const [type, setType] = useState(typeOptions[0].value);
  const [buffer, setBuffer] = useState(bufferOptions[0].value);

  const getTitle = () => {
    let result;

    if (props.item?.satelliteImage?.provider === PLANET_PROVIDER) {
      result = (
        <>
          {getI18nString(props.item, 'title')}
          <span className="required-asterisk">*</span>
        </>
      );
    } else {
      result = getI18nString(props.item, 'title');
    }

    return result;
  };

  const getActionsContent = () => {
    let result = null;

    if (props.item?.satelliteImage?.provider === PLANET_PROVIDER) {
      result = (
        <Typography className="export-source-data__actions-label">
          <span className="required-asterisk">*</span>
          <span className="export-source-data__actions-label-text">
            {t('general.planet-license.derivative-product')}
          </span>
        </Typography>
      );
    }

    return result;
  };

  return (
    <ExportData
      {...props}
      title={getTitle()}
      content={
        <>
          <RadioGroup
            options={typeOptions}
            value={type}
            onChange={(newType) => setType(newType)}
          />
          <Divider className="export-source-data__divider" />
          <Select
            value={buffer}
            label={t('general.controls.pixels-buffer')}
            options={bufferOptions}
            classes={{
              root: 'zones-map-editor-panel__type-select',
            }}
            onChange={(newBuffer) => setBuffer(newBuffer)}
          />
        </>
      }
      actionsContent={getActionsContent()}
      onConfirm={() =>
        props.onConfirm({
          type,
          buffer,
        })
      }
    />
  );
};

export default ExportSourceData;
