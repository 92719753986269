import React from 'react';
import OrderingIcon from '@material-ui/icons/AccessTime';

import PlanetClearPercentTooltip from './PlanetClearPercentTooltip';
import CloudinessTooltip from './CloudinessTooltip';
import { formatAcquisitionDate } from '../../../../../../helpers/satellite';
import SatelliteProviderLabel from '../../../../../satelliteImages/components/SatelliteProviderLabel';
import {
  isPlanetImageAvailable,
  isPlanetImageOrdered,
} from '../../../../../../helpers/functions/entities/satelliteImage';
import { getCloudIcon } from '../../../../../satelliteImages/helpers/functions/ui';
import type { TransformedSatelliteImage } from '../../../../../satelliteImages/types/satelliteImage';
import { prettifyNumber } from '../../../../../../helpers/markup';

import './index.scss';

export default function SatelliteItem({
  image,
}: {
  image: TransformedSatelliteImage;
}) {
  let clearPercent = '';
  let ndviComponent;
  let cloudComponent;
  let CloudIcon;

  if (image.cloudFreeLand != null) {
    clearPercent = prettifyNumber(image.cloudFreeLand * 100, 0);
    CloudIcon = getCloudIcon(image.cloudFreeLand);
  }

  if (isPlanetImageAvailable(image) && clearPercent) {
    ndviComponent = <PlanetClearPercentTooltip clearPercent={clearPercent} />;
  } else if (isPlanetImageOrdered(image)) {
    ndviComponent = (
      <>
        <OrderingIcon
          fontSize="small"
          className="satellite-item__planet-ordering"
        />
        {clearPercent && (
          <span className="satellite-item__clear-percent">{clearPercent}%</span>
        )}
      </>
    );
  } else {
    if (image.ndviAverage) {
      ndviComponent = (
        <span className="satellite-item__ndvi-label">
          {prettifyNumber(image.ndviAverage, 3)} ndvi
        </span>
      );
    }

    if (CloudIcon && image.cloudFreeLand != null) {
      cloudComponent = (
        <CloudinessTooltip
          hasNdvi={!!image.ndviAverage}
          cloudFreeLand={image.cloudFreeLand}
        >
          <CloudIcon className="satellite-item__cloud-icon" />
        </CloudinessTooltip>
      );
    }
  }

  return (
    <div className="satellite-item">
      <span className="satellite-item__name">
        {formatAcquisitionDate(image.satelliteImage.acquisitionDate)}
      </span>
      <div className="satellite-item__spacer"></div>
      {ndviComponent}
      {cloudComponent}
      {image.satelliteImage.provider && (
        <SatelliteProviderLabel provider={image.satelliteImage.provider} />
      )}
    </div>
  );
}
