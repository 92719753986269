import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Select from '../../../../../../../components/Select';
import { DataLayersListContext } from '../../../../hooks/useDataLayersList';
import { TransformedTopographyMap } from '../../../../../../../helpers/types/dataset';
import {
  extractTopographyMapGeoMapAttributes,
  getTopographyMapAttributeNameHandler,
} from '../../../../../../../helpers/functions/entities/topographyMap';
import { AssetType } from '../../../../../../../helpers/constants/entities/asset';
import CreateAnalysisDataLayerTypeSelector from '../CreateAnalysisDataLayerTypeSelector';

import './index.scss';

interface BaseProps {
  selectedAttribute: string;
  onAttributeChange: (attribute: string | null) => void;
}

interface WithDataLayerTypeProps extends BaseProps {
  selectedDataLayerType: AssetType;
  dataLayersOptions: { value: AssetType; title: string }[];
  onDataLayerTypeChange: (value: AssetType | null) => void;
}

export default function CreateAnalysisTopographyMapsPanel(
  props: BaseProps | WithDataLayerTypeProps,
) {
  const { t } = useTranslation();

  const withDataLayerTypeControl = 'dataLayersOptions' in props;

  const context = useContext(DataLayersListContext);
  const datasets = context.assets as TransformedTopographyMap[];
  const { selectedUuid } = context;
  const selectedTopographyMap = datasets.find(
    (dataset) => dataset.uuid === selectedUuid,
  );

  const options = useMemo(() => {
    if (!selectedTopographyMap) {
      return [];
    }

    const attributes = extractTopographyMapGeoMapAttributes(
      selectedTopographyMap,
    );
    const nameHandler = getTopographyMapAttributeNameHandler(
      selectedTopographyMap.fullAttributes,
    );
    return attributes.map((attribute) => ({
      value: attribute,
      title: nameHandler(attribute),
    }));
  }, [selectedTopographyMap]);

  const selectedValue = useMemo(
    () =>
      options.find((option) => option.value === props.selectedAttribute)
        ?.value ?? '',
    [options, props.selectedAttribute],
  );

  return (
    <div className="create-analysis-topography-maps-panel">
      {withDataLayerTypeControl && (
        <CreateAnalysisDataLayerTypeSelector
          selectedType={props.selectedDataLayerType}
          dataLayersOptions={props.dataLayersOptions}
          onDataLayerTypeChange={props.onDataLayerTypeChange}
        />
      )}
      <Select
        id="topography-map-attribute-selector"
        required
        disabled={!selectedTopographyMap}
        labelPosition="left"
        classes={{
          root: 'create-analysis-topography-maps-panel__select',
          selectInput: clsx({
            'create-analysis-topography-maps-panel__strict-input-width':
              withDataLayerTypeControl,
          }),
        }}
        label={t('zones-ops.multi-layer.steps.4.settings-table.attribute')}
        value={selectedValue}
        options={options}
        onChange={props.onAttributeChange}
      />
    </div>
  );
}
