import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import { AssetGroupType } from '../../../../../../../helpers/constants/entities/asset';
import './index.scss';

export default function GroupPlaceholderLoadingItem({
  assetGroupType,
}: {
  assetGroupType: AssetGroupType.satelliteImages;
}) {
  const { t } = useTranslation();

  const DESCRIPTIONS = {
    [AssetGroupType.satelliteImages]: t(
      'field.assets.placeholder.satellite-images-loading-description',
    ),
  };

  return (
    <div className="group-placeholder-loading-item">
      <Typography
        classes={{
          root: 'group-placeholder-loading-item__description',
        }}
      >
        {DESCRIPTIONS[assetGroupType]}
      </Typography>
    </div>
  );
}
