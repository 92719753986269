query getZonesMapsListFields($fieldUuids: [ID], $areaUnit: AreaUnit!) {
  getFarms {
    uuid
    name
    fields(fieldUuids: $fieldUuids) {
      uuid
      farmUuid
      name
      labels {
        name
        value
      }
      area(unit: $areaUnit)
      notes {
        uuid
        fieldUuid
        vectorAnalysisMap {
          uuid
          fieldUuid
        }
      }
    }
  }
}
