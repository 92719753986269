import { CustomError } from '../errorHandling';

export const readEnvVariable = (variable: keyof ImportMetaEnv): string => {
  const result = import.meta.env[variable];

  if (result == null) {
    throw new CustomError(`[Environment] Variable ${variable} is not defined.`);
  }

  return result;
};

export const isProductionEnvironment = () =>
  import.meta.env.MODE !== 'development';
