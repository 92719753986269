import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../../../components/Button';
import ToolsPanel from '../../../../../components/ToolsPanel';
import { selectHasUpdates } from '../../userSettingsSelectors';
import { saveUpdates } from '../../userSettingsSlice';

import './index.scss';

const UserSettingsToolsPanel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasUpdates = useSelector(selectHasUpdates);

  const handleSave = () => {
    dispatch(saveUpdates());
  };

  return (
    <ToolsPanel
      classes={{
        root: 'user-settings-tools-panel',
      }}
      actionsRight={
        hasUpdates && (
          <Button variant="contained" color="primary" onClick={handleSave}>
            {t('user-settings.save-button')}
          </Button>
        )
      }
    />
  );
};

export default UserSettingsToolsPanel;
