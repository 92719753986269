query getFieldData($farmUuids: [ID], $fieldUuids: [ID], $areaUnit: AreaUnit!) {
  getFarms(farmUuids: $farmUuids) {
    uuid
    fields(fieldUuids: $fieldUuids) {
      uuid
      name
      status
      farmUuid
      boundaryUrl
      boundingBox
      geoMaps {
        shortName
        url
      }
      area(unit: $areaUnit)
      centroid {
        latitude
        longitude
      }
      createdDate
      labels {
        name
        value
      }
    }
  }
}
