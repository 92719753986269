export const PREDEFINED_PREFIXES = {
  search: '_search',
};

export const PREDEFINED_VALUES = {
  allValues: '_allValues',
};

export const getSelectedOptionByUuid = (option, value) =>
  option.uuid === value.uuid;
