import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import subHours from 'date-fns/subHours';
import roundToNearestMinutes from 'date-fns/roundToNearestMinutes';

import { OperationType } from '../../operationsWorkflow/helpers/constants/operation';
import { getOperations } from '../../operationsWorkflow/helpers/functions/operations';
import type { Operation } from '../../operationsWorkflow/types/operation';
import { useGetPlatformContextQuery } from '../../../asyncOperations/asyncOperationsAPI';
import { selectUuid } from '../../../user/userSelectors';

export default function useHomepageOperations(): {
  isFetching: boolean;
  records: Record<OperationType, Operation[]>;
} {
  const userUuid = useSelector(selectUuid);

  const filter = useMemo(() => {
    const to = roundToNearestMinutes(new Date());
    const from = subHours(to, 24);

    return {
      dateFrom: from.toISOString(),
      dateTo: to.toISOString(),
      userUuids: [userUuid],
    };
  }, [userUuid]);
  const { data, isFetching } = useGetPlatformContextQuery({
    filter,
    options: { skipFields: true },
  });
  const records = useMemo(
    () => getOperations({ eventsWithFields: data }),
    [data],
  );

  return {
    isFetching,
    records,
  };
}
