import React from 'react';
import clsx from 'clsx';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { FormHelperText, Typography } from '@material-ui/core';

import './index.scss';

export default function ButtonGroup<T extends string | number>({
  label,
  value,
  options,
  helperText,
  orientation = 'horizontal',
  size,
  classes,
  onChange,
}: {
  label?: string;
  value: T;
  options: { value: T; label: string }[];
  helperText?: {
    type: 'info';
    text: string;
  };
  orientation?: 'vertical' | 'horizontal';
  size?: 'small' | 'medium';
  classes?: {
    root?: string;
    label?: string;
  };
  onChange: (value: T) => void;
}) {
  return (
    <div className="button-group">
      {label && (
        <Typography
          className={clsx('button-group__label', classes?.label, {
            'button-group__label_small': size === 'small',
          })}
        >
          {label}
        </Typography>
      )}
      <ToggleButtonGroup
        className={clsx('button-group__group', classes?.root)}
        exclusive
        size={size}
        orientation={orientation}
        value={value}
        onChange={(_e, v) => onChange(v)}
      >
        {options.map((option) => (
          <ToggleButton
            key={option.value}
            classes={{
              root: 'button-group__button',
              selected: 'button-group__button_selected',
              sizeSmall: 'button-group__button_small',
            }}
            value={option.value}
          >
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {helperText && <FormHelperText>{helperText.text}</FormHelperText>}
    </div>
  );
}
