import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import TextField from '../../../../../../components/TextField';
import Button from '../../../../../../components/Button';
import Link from '../../../../../../components/Link';
import CollapsibleList from '../../../../../../components/CollapsibleList';
import UsersList from '../../../components/UsersList';
import { openPopup } from '../../../../popups/popupsSlice';
import { filterByKey } from '../../../../../../helpers';
import { PAGES_ROOTS, getRootLink } from '../../../../../../helpers/navigation';
import { selectOrganizations } from '../../../../../user/userSelectors';
import {
  deleteUsersFromOrganization,
  shareFarmToUser,
} from '../../../userOrganizationsSlice';
import POPUPS from '../../../../popups/helpers/constants/popups';

import './index.scss';

const UserOrganizationsAdminPanel = ({
  organizations,
  onAddOrganizationClick,
  onOrganizationSettingsClick,
  onInviteUserClick,
  onUserRoleChange,
  onDeleteUserClick,
}) => {
  const { t } = useTranslation();
  const [emailFilters, setEmailFilters] = useState({});
  const [advisorWarningDisplayed, setAdvisorWarningDisplayed] = useState(false);

  const handleFilterChange = (value, uuid) => {
    setEmailFilters({
      ...emailFilters,
      [uuid]: value,
    });
  };

  const handleUserRoleChange = (uuid, user, allFarms) => {
    setAdvisorWarningDisplayed(allFarms);
    onUserRoleChange(uuid, user, allFarms);
  };

  const handlePanelChange = () => {
    setAdvisorWarningDisplayed(false);
  };

  const handleOrganizationSettingsClick = (e, organziation) => {
    e.stopPropagation();

    onOrganizationSettingsClick(organziation);
  };

  const getHeader = (hasOrganizations) => {
    let result;

    if (hasOrganizations) {
      result = (
        <div className="user-organizations-admin__header">
          <Typography variant="body2" className="header-description">
            {t('user-organizations.admin.description')}
          </Typography>
          <Button
            classes={{
              root: 'header-button',
            }}
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={onAddOrganizationClick}
          >
            {t('user-organizations.admin.add-organization')}
          </Button>
        </div>
      );
    } else {
      result = (
        <div className="user-organizations-admin__header user-organizations-admin__header_no-orgs">
          <Typography
            variant="body2"
            className="header-description header-description_no-orgs"
          >
            {t('user-organizations.admin.description')}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            classes={{
              root: 'header-button',
            }}
            startIcon={
              <AddIcon
                classes={{
                  root: 'header-button__icon',
                }}
              />
            }
            onClick={onAddOrganizationClick}
          >
            {t('user-organizations.admin.add-organization')}
          </Button>
        </div>
      );
    }

    return result;
  };

  const getPanelContent = ({ uuid, name, users }) => {
    let result;

    if (users.length === 0) {
      result = (
        <div>
          <Typography
            variant="body2"
            className="collapsible-list__item-content-description"
          >
            {t('user-organizations.admin.no-users')}
          </Typography>
          <Button
            color="primary"
            variant="contained"
            onClick={() => onInviteUserClick(uuid, name)}
          >
            {t('user-organizations.admin.invite')}
          </Button>
        </div>
      );
    } else {
      result = (
        <div className="collapsible-list__item-content-list">
          <div className="collapsible-list__item-content-filter">
            <TextField
              classes={{
                wrapper: 'collapsible-list__item-content-filter__input',
              }}
              type="search"
              placeholder={t('general.controls.email-filter')}
              value={emailFilters[uuid] || ''}
              onChange={(e) => handleFilterChange(e.target.value, uuid)}
              onEndAdornmentClick={() => handleFilterChange('', uuid)}
            />
            <Button
              color="primary"
              variant="contained"
              onClick={() => onInviteUserClick(uuid, name)}
            >
              {t('user-organizations.admin.invite')}
            </Button>
          </div>
          {advisorWarningDisplayed && (
            <div className="collapsible-list__item-content-warning">
              <InfoIcon
                fontSize="small"
                className="collapsible-list__item-content-warning-icon"
              />
              <Typography className="collapsible-list__item-content-warning-text">
                {t('user-organizations.admin.org-warning')}
              </Typography>
            </div>
          )}
          <UsersList
            users={filterByKey('email', users, emailFilters[uuid])}
            onRoleChange={(user, allFarms) =>
              handleUserRoleChange(uuid, user, allFarms)
            }
            onDeleteClick={(user) => onDeleteUserClick(uuid, user)}
          />
        </div>
      );
    }

    return result;
  };

  return (
    <div className="user-organizations-admin">
      {getHeader(organizations.length !== 0)}
      <CollapsibleList
        items={organizations}
        panelContentRenderer={(organization) => (
          <Fragment>
            <Typography
              variant="body2"
              className="collapsible-list__item-content-notification"
            >
              <Trans i18nKey="user-organizations.admin.org-notification">
                text
                <span className="collapsible-list__item-content-notification_bold">
                  bold
                </span>
                text
                <Link to={getRootLink(PAGES_ROOTS.userFarms)}>link</Link>
              </Trans>
            </Typography>
            {getPanelContent(organization)}
          </Fragment>
        )}
        summaryOptionsRenderer={(item) => (
          <IconButton onClick={(e) => handleOrganizationSettingsClick(e, item)}>
            <SettingsIcon />
          </IconButton>
        )}
        onPanelChange={handlePanelChange}
        expandFirstItem
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  organizations: selectOrganizations(state),
});

const mapDispatchToProps = (dispatch) => ({
  onOrganizationSettingsClick: ({ uuid, ...organization }) => {
    dispatch(
      openPopup({
        type: 'organization-settings',
        form: organization,
        uuid,
      }),
    );
  },
  onAddOrganizationClick: () => {
    dispatch(
      openPopup({
        type: 'organization-settings',
      }),
    );
  },
  onInviteUserClick: (uuid, name) => {
    dispatch(
      openPopup({
        type: 'invite-user',
        uuid,
        name,
      }),
    );
  },
  onUserRoleChange: (uuid, user, allFarms) => {
    dispatch(
      shareFarmToUser({
        allFarms,
        userUuid: user.userUuid,
        organizationUuid: uuid,
      }),
    );
  },
  onDeleteUserClick: (uuid, user) => {
    dispatch(
      openPopup({
        type: POPUPS.deleteUserFromOrganization,
        entityName: user.email,
        onConfirm: () => {
          dispatch(
            deleteUsersFromOrganization({
              organizationUuid: uuid,
              users: [user],
            }),
          );
        },
      }),
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserOrganizationsAdminPanel);
