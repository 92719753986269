import { API, graphqlOperation } from '@aws-amplify/api';

import getMinifiedSatelliteImagesQuery from './graphql/queries/getMinifiedSatelliteImages.gql';
import getSatelliteImagesQuery from './graphql/queries/getSatelliteImages.gql';
import generateGetSatelliteImagesGeoMapsQuery from './graphql/queries/getSatelliteImagesGeoMaps';
import { transform } from './helpers/functions/satelliteImages';
import {
  SatelliteImagesResponse,
  SatelliteImagesTypedGeoMapsResponse,
  TypedGeoMapsSatelliteImages,
} from './types/api';
import generatePeriods from './helpers/functions/generatePeriods';
import { Period, Periods } from './types/periods';
import { millisToDateString } from '../../helpers/functions/utils/date';
import { DEFAULT_OFFSET } from './helpers/constants/generatePeriods';
import {
  GeoMapType,
  MinifiedSatelliteImage,
  SatelliteImage,
  TransformedSatelliteImage,
} from './types/satelliteImage';
import { comparator } from '../../helpers/functions/entities/satelliteImage';
import {
  CustomError,
  captureException,
  hasErrors,
} from '../../helpers/functions/utils/errorHandling';
import { GeoMap } from '../../helpers/types/api';

const requestByPeriods = async <T extends MinifiedSatelliteImage>(
  farmUuid: string,
  fieldUuid: string,
  query: string,
  periods: Periods,
): Promise<T[]> => {
  const periodBatches = await Promise.all([
    requestBatch<T>(farmUuid, fieldUuid, query, periods[0]),
    requestBatch<T>(farmUuid, fieldUuid, query, periods[1]),
  ]);

  return periodBatches.flat();
};

const isRetryableError = (error: unknown): boolean => {
  const PAYLOAD_SIZE_ERROR_REGEXP = /payload size exceeded maximum allowed/i;
  const TIME_OUT_ERROR_REGEXP = /task timed out/i;
  const LARGE_TRANSFORMATION_ERROR_REGEXP = /transformation too large/i;
  const MAPPING_TEMPLATE_ERROR = /list size cannot exceed/i;
  const errorMessage = hasErrors<{ message: string }[]>(error)
    ? error.errors?.[0].message
    : '';

  return (
    PAYLOAD_SIZE_ERROR_REGEXP.test(errorMessage) ||
    TIME_OUT_ERROR_REGEXP.test(errorMessage) ||
    MAPPING_TEMPLATE_ERROR.test(errorMessage) ||
    LARGE_TRANSFORMATION_ERROR_REGEXP.test(errorMessage)
  );
};

const requestBatch = async <T extends MinifiedSatelliteImage>(
  farmUuid: string,
  fieldUuid: string,
  query: string,
  { before, after }: Period,
): Promise<T[]> => {
  let result: T[] = [];

  try {
    const { data } = (await API.graphql(
      graphqlOperation(query, {
        farmUuids: [farmUuid],
        fieldUuids: [fieldUuid],
        before,
        after,
      }),
    )) as SatelliteImagesResponse<T>;

    result = data?.getFarms?.[0]?.fields?.[0]?.satelliteImages || [];
  } catch (error) {
    if (isRetryableError(error)) {
      result = await requestByPeriods<T>(
        farmUuid,
        fieldUuid,
        query,
        generatePeriods({ before, after }),
      );
    } else {
      const typedError = error as SatelliteImagesResponse<T>;

      if (typedError.data) {
        result = typedError.data?.getFarms[0].fields[0].satelliteImages || [];
      } else {
        captureException({
          error: new CustomError('[SatelliteImages] requestBatch', {
            cause: error,
          }),
        });

        throw error;
      }
    }
  }

  return result.filter((v) => !!v);
};

const requestSatelliteImages = async <T extends MinifiedSatelliteImage>(
  farmUuid: string,
  fieldUuid: string,
  query: string,
) => {
  const lowerLimit = millisToDateString(Date.now() - DEFAULT_OFFSET);
  const satImages = await requestByPeriods<T>(farmUuid, fieldUuid, query, [
    { after: lowerLimit },
    { before: lowerLimit },
  ]);

  return transform(satImages);
};

export const fetchMinifiedSatelliteImages = (
  farmUuid: string,
  fieldUuid: string,
) =>
  requestSatelliteImages<MinifiedSatelliteImage>(
    farmUuid,
    fieldUuid,
    getMinifiedSatelliteImagesQuery,
  );

export const fetchAllSatelliteImages = (farmUuid: string, fieldUuid: string) =>
  requestSatelliteImages<SatelliteImage>(
    farmUuid,
    fieldUuid,
    getSatelliteImagesQuery,
  );

export const fetchSatelliteImagesByUuids = async (
  farmUuid: string,
  fieldUuid: string,
  uuids: string[],
) => {
  const { data } = (await API.graphql(
    graphqlOperation(getSatelliteImagesQuery, {
      farmUuids: [farmUuid],
      fieldUuids: [fieldUuid],
      imagesUuids: uuids,
    }),
  )) as SatelliteImagesResponse<SatelliteImage>;
  return transform(data?.getFarms[0].fields[0].satelliteImages);
};

export const fetchSatelliteImagesGeoMaps = async ({
  farmUuid,
  fieldUuid,
  uuids,
  types,
}: {
  farmUuid: string;
  fieldUuid: string;
  uuids: string[];
  types: GeoMapType[];
}) => {
  const query = generateGetSatelliteImagesGeoMapsQuery(types, uuids);

  const { data } = (await API.graphql(
    graphqlOperation(query, {
      farmUuids: [farmUuid],
      fieldUuids: [fieldUuid],
    }),
  )) as SatelliteImagesTypedGeoMapsResponse;

  const field = data?.getFarms[0].fields[0];
  const allSatelliteImages: TypedGeoMapsSatelliteImages = [];

  if (field) {
    const keys = Object.keys(field) as (keyof typeof field)[];

    for (const key of keys) {
      if (Array.isArray(field[key])) {
        allSatelliteImages.push(...field[key]!);
      }
    }
  }

  return allSatelliteImages.reduce(
    (acc, image) => ({
      ...acc,
      [image.satelliteImage.uuid]: [
        ...(acc[image.satelliteImage.uuid] || []),
        ...image.geoMaps,
      ],
    }),
    {} as Record<string, GeoMap[]>,
  );
};

export const fetchRequiredSatelliteImages = async ({
  farmUuid,
  fieldUuid,
  fetched,
  required,
}: {
  farmUuid: string;
  fieldUuid: string;
  fetched: TransformedSatelliteImage[];
  required: string[];
}) => {
  const fetchedUuids = new Set(fetched.map(({ uuid }) => uuid));
  const missingUuids = required.filter((uuid) => !fetchedUuids.has(uuid));
  let result: TransformedSatelliteImage[];

  if (missingUuids.length === 0) {
    result = fetched;
  } else {
    const missing = await fetchSatelliteImagesByUuids(
      farmUuid,
      fieldUuid,
      missingUuids,
    );

    result = [...fetched, ...missing].sort(comparator);
  }

  return result;
};
