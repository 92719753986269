import React from 'react';
import { Link } from 'react-router-dom';

import './index.scss';

const EnhancedLink = ({ children, className = '', ...props }) => (
  <Link className={`link ${className}`} {...props}>
    {children}
  </Link>
);

export default EnhancedLink;
