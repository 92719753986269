query getAssetsUrls(
  $farmUuids: [ID]
  $fieldUuids: [ID]
  $satelliteImageUuids: [ID]
  $soilDatasetUuids: [ID]
  $yieldDatasetUuids: [ID]
  $asAppliedDatasetUuids: [ID]
  $topographyMapUuids: [ID]
  $vamapUuids: [ID]
  $hasSatellite: Boolean!
  $hasSoil: Boolean!
  $hasYield: Boolean!
  $hasAsApplied: Boolean!
  $hasTopography: Boolean!
  $hasVamap: Boolean!
  $exportFormat: GeoFormat!
  $buffer: BufferSize
  $withSourceUrl: Boolean!
  $withOriginalUrl: Boolean!
) {
  getFarms(farmUuids: $farmUuids) {
    uuid
    fields(fieldUuids: $fieldUuids) {
      uuid
      vectorAnalysisMaps(vectorAnalysisMapUuids: $vamapUuids)
        @include(if: $hasVamap) {
        uuid
        sourceDataUrl(format: $exportFormat, buffer: $buffer)
      }
      satelliteImages(threshold: 0, satelliteImageUuids: $satelliteImageUuids)
        @include(if: $hasSatellite) {
        satelliteImage {
          uuid
        }
        sourceDataUrl(format: $exportFormat, buffer: $buffer)
      }
      soilDatasets(soilDatasetUuids: $soilDatasetUuids) @include(if: $hasSoil) {
        uuid
        sourceDataUrl(format: $exportFormat, buffer: $buffer)
          @include(if: $withSourceUrl)
        originDataUrl(format: $exportFormat) @include(if: $withOriginalUrl)
      }
      yieldDatasets(yieldDatasetUuids: $yieldDatasetUuids)
        @include(if: $hasYield) {
        uuid
        sourceDataUrl(format: $exportFormat, buffer: $buffer)
          @include(if: $withSourceUrl)
        originDataUrl(format: $exportFormat) @include(if: $withOriginalUrl)
      }
      asAppliedDatasets(asAppliedDatasetUuids: $asAppliedDatasetUuids)
        @include(if: $hasAsApplied) {
        uuid
        sourceDataUrl(format: $exportFormat, buffer: $buffer)
          @include(if: $withSourceUrl)
        originDataUrl(format: $exportFormat) @include(if: $withOriginalUrl)
      }
      topographyMaps(topographyMapUuids: $topographyMapUuids)
        @include(if: $hasTopography) {
        uuid
        sourceDataUrl(format: $exportFormat, buffer: $buffer)
      }
    }
  }
}
