import i18n from 'i18next';

import {
  errorNotify,
  successNotify,
} from '../notifications/helpers/functions/notify';
import { getSubscriptionObservable } from '../subscription/subscriptionSlice';
import { transformFarms } from '../field/helpers/functions/assets';
import { sortComparator } from './helpers/functions/farms';
import type {
  GetFarmsResponse,
  GetFarmArg,
  GetFarmResult,
  GetFarmsResult,
  GetFarmResponse,
  CreateFarmArg,
  CreateFarmResponse,
  CreateFarmResult,
  RenameFarmArg,
  RenameFarmResult,
  RenameFarmResponse,
  DeleteFarmResult,
  DeleteFarmArg,
  DeleteFarmResponse,
  ShareFarmResult,
  ShareFarmsArg,
  ShareFarmsResponse,
  UnshareFarmsArg,
  UnshareFarmsResponse,
  UnshareFarmsResult,
  GetFarmUsersResult,
  GetFarmUsersArg,
  GetFarmUsersResponse,
} from './types/api';
import getFarmsQuery from './graphql/queries/getFarms.gql';
import getFarmQuery from './graphql/queries/getFarm.gql';
import getFarmsUsersByUuidsQuery from './graphql/queries/getFarmsUsersByUuids.gql';
import createFarmMutation from './graphql/mutations/createFarm.gql';
import renameFarmMutation from './graphql/mutations/renameFarm.gql';
import deleteFarmMutation from './graphql/mutations/deleteFarm.gql';
import shareFarmsMutation from './graphql/mutations/shareFarms.gql';
import unshareFarmsMutation from './graphql/mutations/unshareFarms.gql';
import { emptyAPI, LIST_ID, TagType } from '../emptyApi/emptyAPI';
import { CustomError } from '../../helpers/functions/utils/errorHandling';
import { selectUuid } from '../user/userSelectors';
import { RootState } from '../../app/store/helpers/types';

const farmsAPI = emptyAPI.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getFarms: builder.query<GetFarmsResult, void>({
      queryFn: async (_arg, { dispatch }, _extraOptions, baseQuery) => {
        const getFarmsResult = await baseQuery({
          document: getFarmsQuery,
        });

        if (getFarmsResult.error) {
          errorNotify({
            error: new CustomError('[Farms] Unable to get farms.', {
              cause: getFarmsResult.error,
            }),
            dispatch,
          });

          return {
            error: getFarmsResult.error,
          };
        }

        const { getFarms } = getFarmsResult.data as GetFarmsResponse;

        return {
          data: transformFarms(getFarms).sort(sortComparator),
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ uuid }) => ({ type: TagType.farm, id: uuid })),
              { type: TagType.farm, id: LIST_ID },
            ]
          : [{ type: TagType.farm, id: LIST_ID }],
      onCacheEntryAdded: async (
        _arg,
        {
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          dispatch,
          getState,
        },
      ) => {
        await cacheDataLoaded;
        const eventCounters: Record<string, number> = {};

        const handleFarmUpdate = async (farmUuid: string) => {
          const actionResult = dispatch(
            farmsAPI.endpoints.getFarmUsersEmails.initiate({
              farmUuid,
            }),
          );
          const { data: usersEmails = [] } = await actionResult;

          try {
            updateCachedData((draft) => {
              const farmToUpdate = draft.find(({ uuid }) => uuid === farmUuid);
              if (farmToUpdate) {
                farmToUpdate.usersEmails = usersEmails;
              }
            });
          } catch {
            // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`
          }

          actionResult.unsubscribe();
        };

        const subscribe = async () => {
          const state = getState() as RootState;
          const userUuid = selectUuid(state);
          const observable = await getSubscriptionObservable(userUuid);
          return observable.subscribe({
            next: ({ action, pathLength, farmUuid }) => {
              if (farmUuid !== '' && pathLength === 1 && action === 'INSERT') {
                eventCounters[farmUuid] = (eventCounters[farmUuid] || 0) + 1;
              }

              if (eventCounters[farmUuid] === 2) {
                void handleFarmUpdate(farmUuid);
              }
            },
            error: () => {
              void subscribe();
            },
          });
        };

        const subscription = await subscribe();

        await cacheEntryRemoved;
        (await subscription).unsubscribe();
      },
    }),
    getFarm: builder.query<GetFarmResult, GetFarmArg>({
      queryFn: async ({ farmUuid }, { dispatch }, _extraOptions, baseQuery) => {
        if (!farmUuid) {
          return {
            error: '[Farms] Farm UUID is not provided.',
          };
        }

        const getFarmResult = await baseQuery({
          document: getFarmQuery,
          variables: {
            farmUuids: [farmUuid],
          },
        });

        if (getFarmResult.error) {
          errorNotify({
            error: new CustomError('[Farms] Unable to get farm.', {
              cause: getFarmResult.error,
            }),
            dispatch,
          });

          return {
            error: getFarmResult.error,
          };
        }

        const { getFarms } = getFarmResult.data as GetFarmResponse;

        return {
          data: transformFarms(getFarms)[0],
        };
      },
      providesTags: (_result, _error, { farmUuid }) => [
        { type: TagType.farm, id: farmUuid },
      ],
    }),
    getFarmUsersEmails: builder.query<GetFarmUsersResult, GetFarmUsersArg>({
      queryFn: async ({ farmUuid }, { dispatch }, _extraOptions, baseQuery) => {
        const getFarmUsersResult = await baseQuery({
          document: getFarmsUsersByUuidsQuery,
          variables: {
            farmUuids: [farmUuid],
          },
        });

        if (getFarmUsersResult.error) {
          errorNotify({
            error: new CustomError('[Farms] Unable to get farm users.', {
              cause: getFarmUsersResult.error,
            }),
            dispatch,
          });

          return {
            error: getFarmUsersResult.error,
          };
        }

        const { getFarms } = getFarmUsersResult.data as GetFarmUsersResponse;

        return {
          data: [...new Set(getFarms[0].usersEmails)],
        };
      },
      providesTags: (_result, _error, { farmUuid }) => [
        { type: TagType.farm, id: farmUuid },
      ],
    }),
    createFarm: builder.mutation<CreateFarmResult, CreateFarmArg>({
      queryFn: async ({ name }, { dispatch }, _extraOptions, baseQuery) => {
        const createFarmResult = await baseQuery({
          document: createFarmMutation,
          variables: {
            input: {
              name,
            },
          },
        });

        if (createFarmResult.error) {
          errorNotify({
            error: new CustomError('[Farms] Unable to create farm.', {
              cause: createFarmResult.error,
            }),
            dispatch,
          });

          return {
            error: createFarmResult.error,
          };
        }

        successNotify({
          message: i18n.t('general.notifications.farm-created'),
        });

        const { createFarm } = createFarmResult.data as CreateFarmResponse;

        return {
          data: {
            uuid: createFarm.uuid,
            name: createFarm.name,
          },
        };
      },
      invalidatesTags: [{ type: TagType.farm, id: LIST_ID }],
    }),
    renameFarm: builder.mutation<RenameFarmResult, RenameFarmArg>({
      queryFn: async (
        { name, farmUuid },
        { dispatch },
        _extraOptions,
        baseQuery,
      ) => {
        const renameFarmResult = await baseQuery({
          document: renameFarmMutation,
          variables: {
            input: {
              name,
              uuid: farmUuid,
            },
          },
        });

        if (renameFarmResult.error) {
          errorNotify({
            error: new CustomError('[Farms] Unable to rename farm.', {
              cause: renameFarmResult.error,
            }),
            message: i18n.t('general.notifications.unable-to-rename-farm'),
            dispatch,
          });

          return {
            error: renameFarmResult.error,
          };
        }

        const { saveFarm } = renameFarmResult.data as RenameFarmResponse;

        return {
          data: saveFarm.uuid,
        };
      },
      invalidatesTags: (_result, _error, { farmUuid }) => [
        { type: TagType.farm, id: farmUuid },
      ],
    }),
    deleteFarm: builder.mutation<DeleteFarmResult, DeleteFarmArg>({
      queryFn: async ({ farmUuid }, { dispatch }, _extraOptions, baseQuery) => {
        const deleteFarmResult = await baseQuery({
          document: deleteFarmMutation,
          variables: {
            input: {
              uuid: farmUuid,
            },
          },
        });

        if (deleteFarmResult.error) {
          errorNotify({
            error: new CustomError('[Farms] Unable to delete farm.', {
              cause: deleteFarmResult.error,
            }),
            message: i18n.t('general.notifications.unable-to-delete-farm'),
            dispatch,
          });

          return {
            error: deleteFarmResult.error,
          };
        }

        successNotify({
          message: i18n.t('general.notifications.farm-deleted'),
        });

        const { deleteFarm } = deleteFarmResult.data as DeleteFarmResponse;

        return {
          data: deleteFarm.uuid,
        };
      },
      invalidatesTags: (_result, _error, { farmUuid }) => [
        { type: TagType.farm, id: farmUuid },
      ],
    }),
    shareFarms: builder.mutation<ShareFarmResult, ShareFarmsArg>({
      queryFn: async (
        { farmUuids, emails },
        { dispatch },
        _extraOptions,
        baseQuery,
      ) => {
        const shareFarmsResult = await baseQuery({
          document: shareFarmsMutation,
          variables: {
            input: {
              farmUuids,
              emails,
            },
          },
        });

        if (shareFarmsResult.error) {
          errorNotify({
            error: new CustomError('[Farms] Unable to share farms.', {
              cause: shareFarmsResult.error,
            }),
            message: i18n.t('general.notifications.unable-to-share-farms'),
            dispatch,
          });

          return {
            error: shareFarmsResult.error,
          };
        }

        successNotify({
          message: i18n.t('general.notifications.farms-shared'),
        });

        const { shareFarms } = shareFarmsResult.data as ShareFarmsResponse;
        const farmsUuidsSet = new Set(shareFarms.map(({ uuid }) => uuid));

        return {
          data: [...farmsUuidsSet],
        };
      },
      invalidatesTags: (_result, _error, { farmUuids }) =>
        farmUuids.map((uuid) => ({ type: TagType.farm, id: uuid })),
    }),
    unshareFarms: builder.mutation<UnshareFarmsResult, UnshareFarmsArg>({
      queryFn: async (
        { farmUuids, emails },
        { dispatch },
        _extraOptions,
        baseQuery,
      ) => {
        const unshareFarmsResult = await baseQuery({
          document: unshareFarmsMutation,
          variables: {
            input: {
              farmUuids,
              userEmails: emails,
            },
          },
        });

        if (unshareFarmsResult.error) {
          errorNotify({
            error: new CustomError('[Farms] Unable to unshare farms.', {
              cause: unshareFarmsResult.error,
            }),
            message: i18n.t('general.notifications.unable-to-unshare-farms'),
            dispatch,
          });

          return {
            error: unshareFarmsResult.error,
          };
        }

        const { deleteFarms } = unshareFarmsResult.data as UnshareFarmsResponse;
        const farmsUuidsSet = new Set(deleteFarms.map(({ uuid }) => uuid));

        return {
          data: [...farmsUuidsSet],
        };
      },
      invalidatesTags: (_result, _error, { farmUuids }) =>
        farmUuids.map((uuid) => ({ type: TagType.farm, id: uuid })),
    }),
  }),
});

export const {
  useGetFarmsQuery,
  useLazyGetFarmsQuery,
  useGetFarmQuery,
  useCreateFarmMutation,
  useRenameFarmMutation,
  useDeleteFarmMutation,
  useShareFarmsMutation,
  useUnshareFarmsMutation,
} = farmsAPI;
