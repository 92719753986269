import i18n from 'i18next';

import { TransformedSatelliteImage } from '../../../../satelliteImages/types/satelliteImage';
import {
  TransformedAsAppliedDataset,
  TransformedSoilDataset,
  TransformedTopographyMap,
  TransformedYieldDataset,
} from '../../../../../helpers/types/dataset';
import { AssetType } from '../../../../../helpers/constants/entities/asset';
import { DataLayer } from '../../types/ui';
import { SatelliteImageIndex } from '../../../../satelliteImages/helpers/constants/satelliteImageIndex';
import { SATELLITE_INDEXES } from '../../../../../helpers/satellite';

export const getDataLayersOptions = ({
  satelliteImages,
  soilDatasets,
  yieldDatasets,
  asAppliedDatasets,
  topographyMaps,
}: {
  satelliteImages: TransformedSatelliteImage[];
  soilDatasets: TransformedSoilDataset[];
  yieldDatasets: TransformedYieldDataset[];
  asAppliedDatasets: TransformedAsAppliedDataset[];
  topographyMaps: TransformedTopographyMap[];
}) => {
  const result = [];

  if (satelliteImages.length > 0) {
    result.push({
      value: AssetType.satelliteImage,
      title: i18n.t('general.shared.satellite-monitoring'),
    });
  }

  if (soilDatasets.length > 0) {
    result.push({
      value: AssetType.soilDataset,
      title: i18n.t('general.shared.soil-data'),
    });
  }

  if (yieldDatasets.length > 0) {
    result.push({
      value: AssetType.yieldDataset,
      title: i18n.t('general.shared.yield-data'),
    });
  }

  if (asAppliedDatasets.length > 0) {
    result.push({
      value: AssetType.asAppliedDataset,
      title: i18n.t('general.shared.as-applied-data'),
    });
  }

  if (topographyMaps.length > 0) {
    result.push({
      value: AssetType.topographyMap,
      title: i18n.t('general.shared.topography'),
    });
  }

  return result;
};

export const initDataLayer = (layerType: AssetType): DataLayer => {
  const layer: DataLayer = {
    type: layerType,
    weight: '1',
  };

  if (layerType === AssetType.satelliteImage) {
    layer.index = SATELLITE_INDEXES[0].value as SatelliteImageIndex;
    layer.satelliteImageUuids = [];
  } else if (
    layerType === AssetType.soilDataset ||
    layerType === AssetType.yieldDataset ||
    layerType === AssetType.asAppliedDataset ||
    layerType === AssetType.topographyMap
  ) {
    layer.attribute = '';
    layer.datasetUuid = '';
  }

  return layer;
};
