import React, { ReactElement } from 'react';

import Tooltip from '../../../../../../components/Tooltip';
import { TransformedSatelliteImage } from '../../../../../satelliteImages/types/satelliteImage';
import { GeoMap } from '../../../../../../helpers/types/api';
import {
  getCloudIcon,
  getSatelliteImageLegendColors,
} from '../../../../../satelliteImages/helpers/functions/ui';
import SatelliteProviderLabel from '../../../../../satelliteImages/components/SatelliteProviderLabel';
import ColorsLegend from '../../../../../../components/Map/Controls/MiniLegend/ColorsLegend';
import { prettifyNumber } from '../../../../../../helpers/markup';

import './index.scss';

export default function SatelliteImageLegendTooltip({
  image,
  index,
  geoMap,
  children,
}: {
  image: TransformedSatelliteImage;
  index: string;
  geoMap: GeoMap;
  children: ReactElement;
}) {
  let CloudIcon;
  let cloudComponent;
  let ndviComponent;
  let providerComponent;

  if (image.ndviAverage) {
    ndviComponent = (
      <span className="satellite-image-preview-legend-tooltip__text">
        {prettifyNumber(image.ndviAverage, 3)} ndvi
      </span>
    );
  }

  if (image.cloudFreeLand != null) {
    CloudIcon = getCloudIcon(image.cloudFreeLand);
  }

  if (CloudIcon && image.cloudFreeLand != null) {
    cloudComponent = (
      <CloudIcon className="satellite-image-preview-legend-tooltip__info-icon" />
    );
  }

  if (image.satelliteImage.provider) {
    providerComponent = (
      <SatelliteProviderLabel
        provider={image.satelliteImage.provider}
        classes={{
          label: 'satellite-image-preview-legend-tooltip__text',
        }}
      />
    );
  }

  const items = getSatelliteImageLegendColors(geoMap, index);

  return (
    <Tooltip
      classes={{
        label: 'satellite-image-preview-legend-tooltip__label',
        tooltip: 'satellite-image-preview-legend-tooltip__tooltip',
      }}
      placement="bottom"
      tooltip={
        <div className="satellite-image-preview-legend-tooltip">
          <div className="satellite-image-preview-legend-tooltip__info">
            {ndviComponent}
            {cloudComponent}
            {providerComponent}
          </div>
          <ColorsLegend direction="horizontal" items={items} name={index} />
        </div>
      }
    >
      {children}
    </Tooltip>
  );
}
