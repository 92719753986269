query getFieldsOperationsJohnDeere(
  $fieldOperationFilter: FieldOperationFilterJohnDeere
) {
  getFieldsOperationsJohnDeere(fieldOperationFilter: $fieldOperationFilter) {
    fields {
      id
      orgId
      fieldOperations {
        fieldOperationType
        seasons {
          cropSeason
          totalCount
        }
      }
    }
  }
}
