import { API, graphqlOperation } from '@aws-amplify/api';

import getFarmBBoxesQuery from './graphql/queries/getFarmsBboxes.gql';

export const getFarmBBoxes = async (farmUuid) =>
  API.graphql(
    graphqlOperation(getFarmBBoxesQuery, {
      farmUuids: [farmUuid],
    }),
  ).then(({ data }) => data.getFarms);

export default { getFarmBBoxes };
