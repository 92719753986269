import React, { ReactElement } from 'react';
import { SnackbarProvider } from 'notistack';

import Notification from '../../components/Notification';

import './index.scss';

declare module 'notistack' {
  interface VariantOverrides {
    warning: {
      messageElement?: ReactElement;
    };
    success: {
      messageElement?: ReactElement;
    };
    processing: true;
  }
}

export default function NotificationsProvider({
  children,
}: {
  children: ReactElement;
}) {
  return (
    <SnackbarProvider
      Components={{
        success: Notification,
        error: Notification,
        warning: Notification,
        processing: Notification,
        info: Notification,
      }}
      maxSnack={3}
      autoHideDuration={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      classes={{ containerRoot: 'notifications-container' }}
    >
      {children}
    </SnackbarProvider>
  );
}
