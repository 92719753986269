import React, {
  useState,
  useMemo,
  ChangeEvent,
  useEffect,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import LinkIcon from '@material-ui/icons/Link';

import TextField from '../../TextField';
import ComboBox, { Option } from '../../ComboBox';
import {
  DEFAULT_NUMBER_OF_ZONES,
  MAX_NUMBER_OF_ZONES,
  MIN_NUMBER_OF_ZONES,
  SPATIALLY_LOCALIZED_TYPE_MAX_NUMBER_OF_ZONES,
} from '../../../features/createAnalysis/helpers/constants/vamap';
import { getI18nAreaUnit } from '../../../helpers';
import { isNumber } from '../../../helpers/functions/utils/number';
import { AreaUnit } from '../../../features/user/helpers/constants/user';
import { DataClassificationType } from '../../../helpers/constants/entities/vectorAnalysisMap';

import './index.scss';

const generateNumberOfZonesOptions = (min: number, max: number) => {
  const numberOfZonesOptions = [];

  for (let i = min; i <= max; i += 1) {
    numberOfZonesOptions.push({ value: i, title: i.toString() });
  }

  return numberOfZonesOptions;
};

const findNumberOfZonesByZoneAreaValue = ({
  fieldArea,
  zoneArea,
  options,
}: {
  fieldArea: number;
  zoneArea: number;
  options: Option<number>[];
}) => {
  const calculatedNumberOfZones =
    fieldArea && zoneArea ? fieldArea / zoneArea : '';

  if (!calculatedNumberOfZones) {
    return null;
  }

  let nearestOptionValue: number | null = null;
  let minimumDifference = 0;

  options.forEach(({ value }) => {
    const currentDifference = Math.abs(calculatedNumberOfZones - value);

    if (!nearestOptionValue || currentDifference < minimumDifference) {
      minimumDifference = currentDifference;
      nearestOptionValue = value;
    }
  });

  return nearestOptionValue;
};

const calculateZoneArea = (
  fieldArea?: number,
  numberOfZoneValue?: number | null,
) =>
  fieldArea && numberOfZoneValue
    ? Math.round((fieldArea / numberOfZoneValue) * 100) / 100
    : '';

const getValidZoneAreaValue = ({
  zoneArea,
  min,
  max,
}: {
  zoneArea?: number | string;
  min: number;
  max: number;
}) => {
  if (!zoneArea || !isNumber(zoneArea) || zoneArea > max) {
    return max;
  }

  if (zoneArea < min) {
    return min;
  }

  return zoneArea;
};

const getZoneAreaMinAndMaxValues = (fieldArea: number) => {
  const minZoneArea = fieldArea / SPATIALLY_LOCALIZED_TYPE_MAX_NUMBER_OF_ZONES;
  const maxZoneArea = fieldArea / MIN_NUMBER_OF_ZONES;

  return {
    minZoneAreaValue: Math.floor(minZoneArea * 100) / 100,
    maxZoneAreaValue: Math.ceil(maxZoneArea * 100) / 100,
  };
};

const LinkedZonesControls = ({
  areaUnit,
  fieldArea,
  numberOfZones,
  dataClassificationType,
  onNumberOfZonesChange = () => {},
}: {
  areaUnit: AreaUnit;
  fieldArea: number;
  numberOfZones: number | null;
  dataClassificationType: DataClassificationType;
  onNumberOfZonesChange: (value: number | null) => void;
}) => {
  const { t } = useTranslation();
  const [zoneArea, setZoneArea] = useState(
    calculateZoneArea(fieldArea, numberOfZones),
  );

  const isSpatiallyLocalizedType = useMemo(
    () =>
      dataClassificationType ===
        DataClassificationType.spatiallyLocalizedBalanced ||
      dataClassificationType ===
        DataClassificationType.spatiallyLocalizedSpatial ||
      dataClassificationType === DataClassificationType.spatiallyLocalizedValue,
    [dataClassificationType],
  );

  const numberOfZonesOptions = useMemo(
    () =>
      isSpatiallyLocalizedType
        ? generateNumberOfZonesOptions(
            MIN_NUMBER_OF_ZONES,
            SPATIALLY_LOCALIZED_TYPE_MAX_NUMBER_OF_ZONES,
          )
        : generateNumberOfZonesOptions(
            MIN_NUMBER_OF_ZONES,
            MAX_NUMBER_OF_ZONES,
          ),
    [isSpatiallyLocalizedType],
  );

  const numberOfZonesOption =
    numberOfZonesOptions.find((option) => option.value === numberOfZones) ??
    null;

  const { minZoneAreaValue, maxZoneAreaValue } =
    getZoneAreaMinAndMaxValues(fieldArea);

  const handleNumberOfZonesChange = useCallback(
    (numberOfZonesValue: number | null) => {
      const zoneAreaValue = calculateZoneArea(fieldArea, numberOfZonesValue);

      setZoneArea(zoneAreaValue);
      onNumberOfZonesChange(numberOfZonesValue);
    },
    [fieldArea, onNumberOfZonesChange],
  );

  const handleZoneAreaChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    const zoneAreaValue = isNumber(value) ? value : '';

    const isZoneAreaValueValid =
      zoneAreaValue &&
      zoneAreaValue >= minZoneAreaValue &&
      zoneAreaValue <= maxZoneAreaValue;

    const numberOfZonesValue = isZoneAreaValueValid
      ? findNumberOfZonesByZoneAreaValue({
          fieldArea,
          zoneArea: zoneAreaValue,
          options: numberOfZonesOptions,
        })
      : null;

    setZoneArea(zoneAreaValue);
    onNumberOfZonesChange(numberOfZonesValue);
  };

  const handleZoneAreaBlur = () => {
    const zoneAreaValue = getValidZoneAreaValue({
      zoneArea,
      min: minZoneAreaValue,
      max: maxZoneAreaValue,
    });

    const numberOfZonesValue = findNumberOfZonesByZoneAreaValue({
      fieldArea,
      zoneArea: zoneAreaValue,
      options: numberOfZonesOptions,
    });

    setZoneArea(zoneAreaValue);
    onNumberOfZonesChange(numberOfZonesValue);
  };

  useEffect(() => {
    if (
      !isSpatiallyLocalizedType &&
      numberOfZones &&
      numberOfZones > MAX_NUMBER_OF_ZONES
    ) {
      handleNumberOfZonesChange(DEFAULT_NUMBER_OF_ZONES);
    }
  }, [isSpatiallyLocalizedType, numberOfZones, handleNumberOfZonesChange]);

  return (
    <div className="linked-zones-controls">
      <ComboBox
        required
        classes={{
          root: 'linked-zones-controls__number-of-zones-select',
        }}
        disableClearable
        disableCloseOnSelect={false}
        title={t('zones-ops.common.number-of-zones')}
        options={numberOfZonesOptions}
        value={numberOfZonesOption as Option<number>}
        getOptionSelected={(option, value) => option.value === value?.value}
        onChange={(_event, item) => {
          handleNumberOfZonesChange(item?.value ?? null);
        }}
      />
      {isSpatiallyLocalizedType && (
        <>
          <LinkIcon className="linked-zones-controls__link-icon" />
          <TextField
            type="number"
            title={t('zones-ops.common.zone-area', {
              areaUnit: t(`general.area-unit.${getI18nAreaUnit(areaUnit)}`),
            })}
            value={zoneArea}
            onChange={handleZoneAreaChange}
            onBlur={handleZoneAreaBlur}
            InputProps={{
              inputProps: {
                min: 0,
              },
              classes: {
                root: 'linked-zones-controls__zone-area',
              },
              endAdornment: (
                <div className="linked-zones-controls__input-end-adornment">
                  <span>
                    {t('zones-ops.common.min-value', { min: minZoneAreaValue })}
                  </span>
                  <span>
                    {t('zones-ops.common.max-value', { max: maxZoneAreaValue })}
                  </span>
                </div>
              ),
            }}
          />
        </>
      )}
    </div>
  );
};

export default LinkedZonesControls;
