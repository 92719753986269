import React from 'react';
import clsx from 'clsx';

import './index.scss';

const getThead = (headers) => (
  <thead className="legend-table__header">
    <tr>
      {headers.map((header) => (
        <th
          key={header.id}
          className={clsx('cell', {
            'cell_align-left': header.align === 'left',
          })}
        >
          {header.label}
        </th>
      ))}
    </tr>
  </thead>
);
const getCell = (cell) => {
  let content;
  let className = '';

  if (cell.color) {
    className = 'cell__color';
    content = (
      <span
        className="color"
        style={{
          backgroundColor: cell.color,
        }}
      ></span>
    );
  } else if (cell.text) {
    content = (
      <span className="text">
        {cell.text}{' '}
        {cell.secondaryText && (
          <span className="text_secondary">{cell.secondaryText}</span>
        )}
      </span>
    );
  }

  return {
    content,
    className,
  };
};
const getRow = (cols, row) => (
  <tr key={row.id} className="row">
    {cols.map(({ id }, cellInd) => {
      const { className, content } = getCell(row.data[id]);

      return (
        <td key={cellInd} className={`cell ${className}`}>
          {content}
        </td>
      );
    })}
  </tr>
);
const getTbody = (cols, rows) => (
  <tbody className="legend-table__body">
    {rows.map((row) => getRow(cols, row))}
  </tbody>
);

const LegendTable = ({ columns, rows }) => (
  <table className="legend-table">
    {getThead(columns)}
    {getTbody(columns, rows)}
  </table>
);

export default LegendTable;
