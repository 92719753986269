import { GeometryType } from '../../constants/entities/dataset';
import { TileSize } from '../../constants/map';
import { Dataset } from '../../types/dataset';

const POINTS_DATASETS_TILE_SIZE_THRESHOLDS = {
  500: TileSize.xLarge,
  10000: TileSize.large,
  25000: TileSize.medium,
};

const POLYGONS_DATASETS_TILE_SIZE_THRESHOLDS = {
  80000: TileSize.large,
  200000: TileSize.medium,
};

const getTileSize = ({
  thresholds,
  geometries = 0,
}: {
  thresholds: Record<number, TileSize>;
  geometries?: number;
}) => {
  const threshold = Object.keys(thresholds)
    .map(Number)
    .sort((a, b) => a - b)
    .find((thresholdSize) => geometries <= thresholdSize);

  return threshold ? thresholds[threshold] : TileSize.small;
};

const getDatasetTileSize = (dataset: Dataset) => {
  const { geometryType, number: geometries } = dataset;

  if (!geometryType) {
    return TileSize.small;
  }

  if (
    geometryType === GeometryType.Points ||
    geometryType === GeometryType.MultiPoints
  ) {
    return getTileSize({
      thresholds: POINTS_DATASETS_TILE_SIZE_THRESHOLDS,
      geometries,
    });
  }

  if (
    geometryType === GeometryType.Polygons ||
    geometryType === GeometryType.MultiPolygons
  ) {
    return getTileSize({
      thresholds: POLYGONS_DATASETS_TILE_SIZE_THRESHOLDS,
      geometries,
    });
  }

  return TileSize.small;
};

export default getDatasetTileSize;
