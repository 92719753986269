mutation generateSoilVectorAnalysisMap(
  $input: GenerateSoilVectorAnalysisMapInput!
) {
  generateSoilVectorAnalysisMapAsync(input: $input) {
    uuid
    fieldUuid
    statusCode
    statusMessage
  }
}
