import React, { ElementType, ReactElement } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import noop from '../../helpers/functions/utils/noop';

import './index.scss';

interface TabLabel {
  secondary?: string;
  primary?: string;
  primaryPostfix?: string | number | null;
  icon?: ReactElement;
}

const getTabLabelNode = (label: TabLabel) => {
  let result;
  const textElement = (
    <>
      {label.secondary && (
        <Typography
          className="tabs__tab-wrapper-secondary-label"
          variant="body2"
        >
          {label.secondary}
        </Typography>
      )}
      <Typography className="tabs__tab-wrapper-primary-label" variant="body2">
        {label.primary} {label.primaryPostfix && ` (${label.primaryPostfix})`}
      </Typography>
    </>
  );

  if (label.icon) {
    result = (
      <>
        <div className="tabs__tab-wrapper-icon">{label.icon}</div>
        {textElement}
      </>
    );
  } else {
    result = textElement;
  }

  return result;
};

export default function EnhancedTabs<T>({
  withDeleteButtons = false,
  tabs = [],
  component = 'div',
  value,
  classes = {},
  tabClasses = {},
  centered = false,
  variant = 'scrollable',
  onTabChange,
  onTabRemove,
}: {
  withDeleteButtons?: boolean;
  tabs?: {
    value: T;
    label?: TabLabel;
    disabled?: boolean;
    deletable?: boolean;
    to?: string;
  }[];
  component?: ElementType;
  value: T;
  classes?: {
    root?: string;
  };
  tabClasses?: {
    root?: string;
    selected?: string;
    wrapper?: string;
  };
  centered?: boolean;
  variant?: 'standard' | 'scrollable' | 'fullWidth';
  onTabChange: (_event: unknown, v: T) => void;
  onTabRemove?: (_event: unknown, i: number) => void;
}) {
  const { root, ...otherClasses } = classes;
  const {
    root: tabRoot,
    selected: tabSelected,
    wrapper: tabWrapper,
    ...otherTabClasses
  } = tabClasses;
  const v = value != null ? value : tabs[0].value || 0;

  return (
    <Tabs
      value={v}
      onChange={onTabChange || noop}
      indicatorColor="primary"
      variant={variant}
      centered={centered}
      classes={{
        root: clsx('tabs', root),
        ...otherClasses,
      }}
    >
      {tabs.map((tab, index) => {
        const icon =
          withDeleteButtons && tab.deletable ? (
            <IconButton
              size="small"
              className="tab__close-btn"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onTabRemove?.(event, index);
              }}
            >
              <CloseIcon className="tab__close-icon" />
            </IconButton>
          ) : null;

        return (
          <Tab
            key={index}
            value={tab.value || index}
            disableRipple={withDeleteButtons}
            disabled={tab.disabled}
            component={component}
            to={component === Link ? tab.to : null}
            {...(icon ? { icon } : null)}
            {...(tab.label ? { label: getTabLabelNode(tab.label) } : null)}
            classes={{
              root: clsx('tabs__tab', tabRoot),
              selected: clsx('tabs__tab_selected', tabSelected),
              wrapper: clsx('tabs__tab-wrapper', tabWrapper),
              ...otherTabClasses,
            }}
          />
        );
      })}
    </Tabs>
  );
}
