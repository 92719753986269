import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { useSelector } from 'react-redux';
import SelectableListPopup from '../../components/SelectableListPopup';
import {
  useGetFarmsQuery,
  useShareFarmsMutation,
} from '../../../../farms/farmsAPI';
import {
  selectOrganizations,
  selectUuid,
} from '../../../../user/userSelectors';

const ShareFarmsOrgPopup = ({ organizationUuid, farmUuids, ...popup }) => {
  const { t } = useTranslation();
  const organizations = useSelector(selectOrganizations);
  const currentUserUuid = useSelector(selectUuid);
  const { data: farms } = useGetFarmsQuery();
  const [shareFarms] = useShareFarmsMutation();
  const { name, users } = organizations.find(
    ({ uuid }) => uuid === organizationUuid,
  );
  const farmsToShareUsers = farms
    .filter((farm) => farmUuids.includes(farm.uuid))
    .map(({ usersEmails }) => new Set(usersEmails));

  // User should only be preselected if he's presented in every farm
  const commonUsersEmails = farmsToShareUsers.reduce((acc, usersEmails) =>
    [...acc].filter((userUuid) => usersEmails.has(userUuid)),
  );
  const items = users.reduce((acc, { email, userUuid }) => {
    if (userUuid !== currentUserUuid) {
      acc.push({
        label: email,
        id: email,
      });
    }

    return acc;
  }, []);

  const handleConfirm = async (selectedEmails) => {
    await shareFarms({
      emails: selectedEmails,
      farmUuids,
    });

    popup.onConfirm();
  };

  return (
    <SelectableListPopup
      {...popup}
      initialItemsIds={[...commonUsersEmails]}
      items={items}
      title={t('general.popups.share-farms.title')}
      description={
        <Trans i18nKey="general.popups.share-farms-org.description">
          text
          <span className="selectable-list-popup__content-text_highlight">
            {{ name }}
          </span>
          text
        </Trans>
      }
      filter={{
        placeholder: t('general.controls.email-filter'),
      }}
      confirm={{
        label: t('general.controls.share'),
      }}
      onConfirm={handleConfirm}
    />
  );
};

export default ShareFarmsOrgPopup;
