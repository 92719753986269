import type { Dictionary } from '@reduxjs/toolkit';

const isNotEmpty = <T>(value: T | undefined | null): value is T =>
  value != null;

export const addFieldUuid = <E>(entities: E[], fieldUuid: string) =>
  entities.map((entity) => ({
    ...entity,
    fieldUuid,
  }));

export const mapUuidsToEntities = <E>(
  entities: Dictionary<E>,
  uuids: string[] = [],
) => uuids.map((uuid) => entities[uuid]).filter(isNotEmpty);
