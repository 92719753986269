import React from 'react';
import { useDispatch } from 'react-redux';

import Stepper from '../../../../../../../components/Stepper';
import EquationsPanel from '../../../../../../equations/containers/EquationsPanel';
import {
  goToNextStep,
  goToPrevStep,
} from '../../../../../zonesOps/zonesOpsSlice';

const StepperEquation = ({ steps, stepIndex }) => {
  const dispatch = useDispatch();

  const onClickBack = () => {
    dispatch(goToPrevStep());
  };

  const onClickNext = () => {
    dispatch(goToNextStep());
  };

  return (
    <Stepper
      activeStep={stepIndex}
      steps={steps}
      onClickNext={onClickNext}
      onClickBack={onClickBack}
    >
      <EquationsPanel key="panel" />
    </Stepper>
  );
};

export default StepperEquation;
