import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { errorNotify } from '../../notifications/helpers/functions/notify';
import { CustomError } from '../../../helpers/functions/utils/errorHandling';
import {
  selectEmail,
  selectOrganizations,
  selectUuid,
  selectAreaUnit,
} from '../../user/userSelectors';
import { getLoadMoreFilter, getUpdatedFilter } from './helpers/functions/redux';
import { transformAction } from './helpers/functions/action';
import { getUserActionsData } from './userActivityLogAPI';
import prepareFilter from './helpers/functions/prepareFilter';
import { selectActionsData, selectFilter } from './userActivityLogSelectors';
import {
  ACTIONS_PAGE_SIZE,
  MAX_ACTIONS_PAGE_SIZE,
} from './helpers/constants/action';

const initialState = {
  filter: {
    period: 'today',
    operation: null,
    dateFrom: null,
    dateTill: null,
    pageNumber: 0,
    organizations: [],
    users: [],
  },
  actionsData: {
    actions: [],
    lastRequestedIndex: -1,
    totalCount: 0,
  },
  isLoaded: true,
};

const requestUserActivityLog = (filter) => (_dispatch, getState) => {
  const state = getState();
  const currentUser = {
    uuid: selectUuid(state),
    email: selectEmail(state),
  };
  const organizations = selectOrganizations(state);
  const areaUnit = selectAreaUnit(state);

  return getUserActionsData({
    filter: prepareFilter(filter),
    areaUnit,
  }).then(({ actions, totalCount }) => ({
    actions: transformAction(actions, organizations, currentUser),
    totalCount,
  }));
};

export const updateFilterActivityLog = createAsyncThunk(
  'userActivityLog/updateFilterActivityLog',
  async (filter, { dispatch }) => {
    let result = null;

    try {
      result = await dispatch(requestUserActivityLog(getUpdatedFilter(filter)));
    } catch (error) {
      errorNotify({
        error: new CustomError(
          'Unable to request activity log for updated filter.',
          {
            cause: error,
          },
        ),
        dispatch,
      });
    }

    return result;
  },
);

export const loadMoreActivityLogData = createAsyncThunk(
  'userActivityLog/loadMoreActivityLogData',
  async (_, { dispatch, getState }) => {
    const filter = selectFilter(getState());
    let result = null;

    try {
      const { actions, totalCount } = await dispatch(
        requestUserActivityLog(filter),
      );

      result = {
        actions,
        totalCount,
      };
    } catch (error) {
      errorNotify({
        error: new CustomError('Unable to load mode activity log data', {
          cause: error,
        }),
        dispatch,
      });
    }

    return result;
  },
);

export const fetchActivityLog = createAsyncThunk(
  'userActivityLog/fetchActivityLog',
  async (_, { dispatch, getState }) => {
    const filter = selectFilter(getState());
    let result = null;

    try {
      result = await dispatch(requestUserActivityLog(filter));
    } catch (error) {
      errorNotify({
        error: new CustomError('Unable to fetch activity log.', {
          cause: error,
        }),
        dispatch,
      });
    }

    return result;
  },
);

export const updateActivityLog = createAsyncThunk(
  'userActivityLog/updateActivityLog',
  async (_, { dispatch, getState }) => {
    const state = getState();
    const filter = selectFilter(state);
    const { lastRequestedIndex } = selectActionsData(state);
    const batchesAmount = Math.ceil(lastRequestedIndex / MAX_ACTIONS_PAGE_SIZE);
    const requests = [];

    for (let i = 0; i < batchesAmount; i += 1) {
      requests.push(
        dispatch(
          requestUserActivityLog({
            ...filter,
            pageNumber: i,
            pageSize: MAX_ACTIONS_PAGE_SIZE,
          }),
        ),
      );
    }

    let result = null;

    if (requests.length === 0) {
      return result;
    }

    try {
      result = await Promise.all(requests).then((responses) => {
        const allActions = responses.reduce(
          (acc, { actions }) => [...acc, ...actions],
          [],
        );

        return {
          actions: allActions,
          totalCount: responses[0].totalCount,
        };
      });
    } catch (error) {
      errorNotify({
        error: new CustomError('Unable to update activity log.', {
          cause: error,
        }),
        dispatch,
      });
    }

    return result;
  },
);

export const userActivityLogSlice = createSlice({
  name: 'userActivityLog',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateFilterActivityLog.pending, (state, action) => {
        state.filter = getUpdatedFilter(action.meta.arg);
        state.isLoaded = false;
        state.actionsData.lastRequestedIndex = -1;
      })
      .addCase(loadMoreActivityLogData.pending, (state) => {
        state.filter = getLoadMoreFilter(state.filter);
        state.actionsData.lastRequestedIndex =
          state.actionsData.actions.length + ACTIONS_PAGE_SIZE;
        state.isLoaded = false;
      })
      .addCase(loadMoreActivityLogData.fulfilled, (state, action) => {
        state.isLoaded = true;

        if (action.payload) {
          state.actionsData.totalCount = action.payload.totalCount;
          state.actionsData.actions.push(...action.payload.actions);
        }
      })
      .addCase(fetchActivityLog.pending, (state) => {
        state.isLoaded = false;
      })
      .addMatcher(
        ({ type }) =>
          type === updateFilterActivityLog.fulfilled.type ||
          type === fetchActivityLog.fulfilled.type ||
          type === updateActivityLog.fulfilled.type,
        (state, action) => {
          state.isLoaded = true;

          if (action.payload) {
            state.actionsData.actions = action.payload.actions;
            state.actionsData.totalCount = action.payload.totalCount;
          }
        },
      )
      .addMatcher(
        ({ type }) =>
          type === updateFilterActivityLog.fulfilled.type ||
          type === fetchActivityLog.fulfilled.type,
        (state, action) => {
          state.actionsData.lastRequestedIndex = action.payload.actions.length;
        },
      );
  },
});

export const { reset } = userActivityLogSlice.actions;

export default userActivityLogSlice.reducer;
