import { useTranslation } from 'react-i18next';

import { successNotify } from '../features/notifications/helpers/functions/notify';
import POPUPS from '../features/ui/popups/helpers/constants/popups';
import { openPopup } from '../features/ui/popups/popupsSlice';
import { useAppDispatch } from '../app/store/helpers/functions';

export default function useReportIssuePopup() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const openReportIssuePopup = ({
    commentTemplate,
    successMessage,
  }: {
    commentTemplate?: string;
    successMessage?: string;
  } = {}) => {
    dispatch(
      openPopup({
        type: POPUPS.reportIssue,
        commentTemplate,
        onConfirm: () => {
          successNotify({
            message:
              successMessage ?? t('general.notifications.report-issue-success'),
          });
        },
      }),
    );
  };

  return {
    openReportIssuePopup,
  };
}
