import React from 'react';
import clsx from 'clsx';

import './index.scss';

const PanelMapLayout = ({ className, panel, map }) => (
  <div className={clsx('panel-map-layout', className)}>
    <div className="panel-map-layout__panel">{panel}</div>
    <div className="panel-map-layout__map">{map}</div>
  </div>
);

export default PanelMapLayout;
