import { GetStripePriceOpenDataConfigResponse } from './types/api';
import normalizePricingData from './helpers/functions/normalizePricingData';
import getStripePriceOpenDataConfigQuery from './graphql/queries/getStripePriceOpenDataConfig.gql';
import { emptyAPI } from '../emptyApi/emptyAPI';
import {
  captureException,
  CustomError,
} from '../../helpers/functions/utils/errorHandling';
import { PricingConfig } from './types/config';

export const stripePriceDataConfigAPI = emptyAPI.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getStripePriceOpenDataConfig: builder.query<PricingConfig, void>({
      queryFn: async (_arg, _api, _extraOptions, baseQuery) => {
        const getStripePriceOpenDataConfigResult = await baseQuery({
          document: getStripePriceOpenDataConfigQuery,
          withApiKey: true,
        });

        if (getStripePriceOpenDataConfigResult.error) {
          captureException({
            error: new CustomError(
              '[Stripe] Unable to fetch stripe price open data config.',
              {
                cause: getStripePriceOpenDataConfigResult.error,
              },
            ),
          });

          return {
            error: getStripePriceOpenDataConfigResult.error,
          };
        }

        const data =
          getStripePriceOpenDataConfigResult.data as GetStripePriceOpenDataConfigResponse;
        const pricingData = normalizePricingData(
          data.getStripePriceOpenDataConfig,
        );

        return {
          data: pricingData,
        };
      },
    }),
  }),
});

export const { useGetStripePriceOpenDataConfigQuery } =
  stripePriceDataConfigAPI;
