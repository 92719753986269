import Fuse from 'fuse.js';

/**
 * Performs fuzzy search on the list of strings. Returns strings that matches any of patterns.
 */
const getPatternsMatch = <D>(
  data: D[],
  patterns: string[],
  options?: {
    keys?: string[];
    threshold?: number;
    ignoreLocation?: boolean;
    limit?: number;
  },
): Set<Fuse.FuseResult<D>> => {
  const fuse = new Fuse(data, {
    threshold: options?.threshold || 0.35,
    keys: options?.keys || [],
    ignoreLocation: options?.ignoreLocation,
    includeScore: true,
  });

  const searchOptions = options?.limit ? { limit: options?.limit } : undefined;

  const results = new Set<Fuse.FuseResult<D>>();
  const items = new Set<D>();

  patterns.forEach((pattern) => {
    const searchResults = fuse.search(pattern, searchOptions);

    searchResults.forEach((result) => {
      // Do not add search result if item is duplicated
      if (!items.has(result.item)) {
        items.add(result.item);
        results.add(result);
      }
    });
  });

  return results;
};

export default getPatternsMatch;
