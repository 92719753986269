import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { useHistory } from 'react-router-dom';

import PopupHeader from '../../../../components/Popups/PopupHeader';
import Button from '../../../../components/Button';
import {
  getVectorAnalysisMapLink,
  getZonesMapLink,
} from '../../../../helpers/navigation';
import useCreateAnotherAnalysis from '../../../ui/zonesOps/hooks/useCreateAnotherAnalysis';
import { selectSelectedField } from '../../../ui/zonesOps/zonesOpsSelectors';
import { setBackTo } from '../../../ui/zonesMap/zonesMapSlice';

import './index.scss';

const SaveZonesMapPopup = ({ name, vamapUuid, onCancel = () => {} }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const createAnotherAnalysis = useCreateAnotherAnalysis();
  const { farmUuid, fieldUuid } = useSelector(selectSelectedField);

  const onConfirm = (action) => {
    if (action === 'create-another') {
      createAnotherAnalysis();
    } else if (action === 'assign-rates') {
      dispatch(setBackTo('field'));
      history.push(getZonesMapLink(farmUuid, fieldUuid, vamapUuid));
    } else if (action === 'go-zones-map') {
      history.push(getVectorAnalysisMapLink(farmUuid, fieldUuid, vamapUuid));
    }
    onCancel();
  };

  return (
    <Dialog
      open
      classes={{
        root: 'save-zones-dialog',
        paper: 'save-zones-dialog__paper',
      }}
    >
      <PopupHeader
        title={t('general.popups.save-zones-map.title')}
        onCancel={onCancel}
      />
      <DialogContent
        classes={{
          root: 'save-zones-dialog__content',
        }}
      >
        <DialogContentText component="span" color="textPrimary" variant="body2">
          <Trans
            i18nKey="general.popups.save-zones-map.content-text"
            name={name}
          >
            Zones Map{' '}
            <span className="save-zones-dialog__text_highlighted">
              {{ name }}
            </span>{' '}
            was created successfully.
          </Trans>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="save-zones-dialog__actions">
        <Button onClick={() => onConfirm('create-another')} variant="outlined">
          {t('general.popups.create-another-one')}
        </Button>
        <Button
          onClick={() => onConfirm('assign-rates')}
          variant="contained"
          color="primary"
        >
          {t('general.popups.save-zones-map.actions.assign-rates')}
        </Button>
        <Button
          onClick={() => onConfirm('go-zones-map')}
          variant="contained"
          color="primary"
        >
          {t('general.popups.save-zones-map.actions.go-to-zones-map')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveZonesMapPopup;
