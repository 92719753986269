import { AsyncThunk } from '@reduxjs/toolkit';

import {
  AppDispatch,
  AppGetState,
  RootState,
} from '../../../app/store/helpers/types';
import { selectFieldUuid } from '../fieldSelectors';
import { CustomError } from '../../../helpers/functions/utils/errorHandling';

const withFieldUuid = <Returned, Args>(
  action: AsyncThunk<Returned, Args, { state: RootState }>,
) => {
  const upgradedAction =
    (payload: Args) => (dispatch: AppDispatch, getState: AppGetState) => {
      const state = getState();
      const fieldUuid = selectFieldUuid(state);

      if (!fieldUuid) {
        throw new CustomError('[withFieldUuid] fieldUuid is empty.');
      }

      const updatedPayload: Args & { fieldUuid: string } = {
        fieldUuid,
        ...payload,
      };

      return dispatch(action(updatedPayload));
    };

  return Object.assign(upgradedAction, action);
};

export default withFieldUuid;
