import { RootState } from '../../../app/store/helpers/types';

export const selectDatasetUuid = ({ cleanCalibrate }: RootState) =>
  cleanCalibrate.datasetUuid;

export const selectTab = ({ cleanCalibrate }: RootState) => cleanCalibrate.tab;

export const selectStep = ({ cleanCalibrate }: RootState) =>
  cleanCalibrate.step;

export const selectIsProcessing = ({ cleanCalibrate }: RootState) =>
  cleanCalibrate.isProcessing;

export const selectSequence = ({ cleanCalibrate }: RootState) =>
  cleanCalibrate.sequence;

export const selectCalibrationType = ({ cleanCalibrate }: RootState) =>
  cleanCalibrate.calibrate.type;

export const selectCalibrateCalibrationAttributes = ({
  cleanCalibrate,
}: RootState) => cleanCalibrate.calibrate.calibrationAttributes;

export const selectCalibrateSmoothWindowSize = ({
  cleanCalibrate,
}: RootState) => cleanCalibrate.calibrate.smoothWindowSize;

export const selectCalibratePathwiseCalibrationBasis = ({
  cleanCalibrate,
}: RootState) => cleanCalibrate.calibrate.pathwiseCondition.calibrationBasis;

export const selectCalibratePathwiseSyntheticMachinePath = ({
  cleanCalibrate,
}: RootState) =>
  cleanCalibrate.calibrate.pathwiseCondition.syntheticMachinePath;

export const selectCalibrateAvgTotalConditions = ({
  cleanCalibrate,
}: RootState) => cleanCalibrate.calibrate.avgTotalConditions;

export const selectCalibrateMinMaxConditions = ({
  cleanCalibrate,
}: RootState) => cleanCalibrate.calibrate.minMaxConditions;

export const selectCleaningType = ({ cleanCalibrate }: RootState) =>
  cleanCalibrate.clean.type;

export const selectCleanTargetAttribute = ({ cleanCalibrate }: RootState) =>
  cleanCalibrate.clean.targetAttribute;

export const selectCleanExcludedAttributes = ({ cleanCalibrate }: RootState) =>
  cleanCalibrate.clean.excludedAttributes;

export const selectCleanMinMaxConditions = ({ cleanCalibrate }: RootState) =>
  cleanCalibrate.clean.minMaxConditions;

export const selectCleanMinMaxUSDAConditions = ({
  cleanCalibrate,
}: RootState) => cleanCalibrate.clean.minMaxUSDAConditions;
