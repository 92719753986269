import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import PopupHeader from '../../../../components/Popups/PopupHeader';
import Button from '../../../../components/Button';
import Checkbox from '../../../../components/Checkbox';
import transformToObjectMap from '../../helpers/functions/transformToObjectMap';
import useCurrentPage from '../../../../hooks/useCurrentPage';
import AmplitudeAnalytics from '../../../../helpers/classes/amplitudeAnalytics';

import './index.scss';

const RemoveLabelsPopup = ({
  labels: fieldsLabels,
  onCancel = () => {},
  onConfirm = () => {},
}) => {
  const { t } = useTranslation();
  const page = useCurrentPage();
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(true);
  const [labels, setLabels] = useState(() =>
    Object.entries(transformToObjectMap(fieldsLabels))
      .map(([name, values]) =>
        [...new Set(values)].map((value) => ({
          name,
          value,
          selected: false,
        })),
      )
      .flat(),
  );

  useEffect(() => {
    if (page) {
      AmplitudeAnalytics.trackLabelsPopupOpened({
        page,
        type: 'removeFieldsLabels',
      });
    }
  }, [page]);

  const handleLabelClick = (event) => {
    const updatedLabels = labels.map((label, index) => {
      if (index.toString() === event.target.name) {
        return {
          ...label,
          selected: !label.selected,
        };
      }

      return label;
    });
    const hasSelectedLabel = updatedLabels.some(({ selected }) => selected);

    setLabels(updatedLabels);
    setConfirmButtonDisabled(!hasSelectedLabel);
  };

  const handleConfirmClick = () => {
    const labelsToRemove = labels
      .filter(({ selected }) => selected)
      .map(({ name, value }) => ({ name, value }));

    onConfirm(labelsToRemove);
  };

  return (
    <Dialog open>
      <PopupHeader
        title={t('general.popups.remove-labels.remove-labels')}
        onCancel={onCancel}
      />
      <DialogContent>
        <DialogContentText
          classes={{
            root: 'remove-labels-popup__content-text',
          }}
        >
          {t('general.popups.remove-labels.description')}
        </DialogContentText>
        <div className="remove-labels-popup__checkboxes">
          {labels.map((label, index) => (
            <FormControlLabel
              key={index}
              classes={{
                root: 'remove-labels-popup__checkboxes__label-wrapper',
                label: 'remove-labels-popup__checkboxes__checkbox-label',
              }}
              control={
                <Checkbox
                  value={label.selected ? 2 : 0}
                  name={index.toString()}
                  onChange={handleLabelClick}
                />
              }
              label={`${label.name}: ${label.value}`}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions
        classes={{
          root: 'remove-labels-popup__actions',
        }}
      >
        <Button variant="outlined" onClick={onCancel}>
          {t('general.controls.cancel')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={confirmButtonDisabled}
          onClick={handleConfirmClick}
        >
          {t('general.popups.remove-labels.remove-labels')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveLabelsPopup;
