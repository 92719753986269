import React from 'react';
import Typography from '@material-ui/core/Typography';

import ItemPlaceholder from '../ItemPlaceholder';

import './index.scss';

export default function DefaultItem({
  label,
  value,
}: {
  label: string;
  value: string;
}) {
  return (
    <div className="source-assets-default-item">
      <Typography variant="body2" className="source-assets-default-item__label">
        {label}
      </Typography>
      <Typography
        variant="body2"
        classes={{
          root: 'source-assets-default-item__value',
        }}
      >
        {value || <ItemPlaceholder />}
      </Typography>
    </div>
  );
}
