import { useCallback, useState } from 'react';

import {
  isAsAppliedDataset,
  isSatelliteImage,
  isSoilDataset,
  isTopographyMap,
  isYieldDataset,
  isEquationMap,
} from '../helpers/functions/entities/assets';
import { getRasterSources } from '../helpers/mapbox/map';
import { TransformedAsset } from '../helpers/types';
import { AreaUnit } from '../features/user/helpers/constants/user';
import { DatasetViewType } from '../helpers/constants/entities/dataset';
import { TransformedField } from '../features/field/types/field';
import { prepareToDisplaySelectedItem } from '../helpers/functions/map/mapConfig';
import { prepareToDisplayFieldLayer } from '../helpers/functions/map/mapLayerConfig';
import { TransformedSatelliteImage } from '../features/satelliteImages/types/satelliteImage';

export default function useDataLayersMapConfig({
  selectedItem,
  satelliteImages,
  field,
  apiKey,
  areaUnit,
  isCreationView = false,
  topographyViewTypeControlEnabled = false,
  overlayLayersControlEnabled = false,
  datasetAttributesEnabled = true,
  showHideControlEnabled,
}: {
  selectedItem?: TransformedAsset | null;
  satelliteImages?: TransformedSatelliteImage[];
  field?: Pick<
    TransformedField,
    'status' | 'centroid' | 'boundingBox' | 'geoMaps' | 'uuid'
  > | null;
  isCreationView?: boolean;
  topographyViewTypeControlEnabled?: boolean;
  overlayLayersControlEnabled?: boolean;
  datasetAttributesEnabled?: boolean;
  showHideControlEnabled?: boolean;
  areaUnit: AreaUnit;
  apiKey: string;
}) {
  const [hideLayers, setHideLayers] = useState(false);
  const [activeSatelliteViewType, setActiveSatelliteViewType] = useState('');
  const [activeSoilAttribute, setActiveSoilAttribute] = useState('');
  const [activeYieldAttribute, setActiveYieldAttribute] = useState('');
  const [activeAsAppliedAttribute, setActiveAsAppliedAttribute] = useState('');
  const [activeTopographyMapViewType, setActiveTopographyMapViewType] =
    useState('');
  const [activeEquationMapViewType, setActiveEquationMapViewType] =
    useState('');
  const [activeSoilViewType, setActiveSoilViewType] =
    useState<DatasetViewType | null>(null);
  const [activeYieldViewType, setActiveYieldViewType] =
    useState<DatasetViewType | null>(null);
  const [activeAsAppliedViewType, setActiveAsAppliedViewType] =
    useState<DatasetViewType | null>(null);
  const useBounds = !!selectedItem && !isSatelliteImage(selectedItem);

  const { rasterSources: borderRasterSource = [], ...otherBorderLayerProps } =
    prepareToDisplayFieldLayer(field, useBounds);
  const {
    rasterSources,
    tileSize,
    attribution,
    datasetViewControl,
    indexesControl,
    miniLegendControl,
    showHideControl,
    legendControl,
    geoMapsControl,
    geoMapTypeControl,
    overlayLayersControls,
  } = prepareToDisplaySelectedItem(selectedItem, {
    soilAttribute: activeSoilAttribute,
    soilViewType: activeSoilViewType,
    yieldAttribute: activeYieldAttribute,
    yieldViewType: activeYieldViewType,
    asAppliedAttribute: activeAsAppliedAttribute,
    asAppliedViewType: activeAsAppliedViewType,
    satelliteViewType: activeSatelliteViewType,
    satelliteImages,
    equationMapGeoMap: activeEquationMapViewType,
    topographyMapViewType: activeTopographyMapViewType,
    topographyViewTypeControlEnabled,
    datasetAttributesEnabled,
    showHideControlEnabled,
    overlayLayersControlEnabled,
    isCreationView,
    areaUnit,
  });
  const mapProps = {
    ...otherBorderLayerProps,
    uuid: field?.uuid,
    apiKey,
    rasterSources: getRasterSources({ rasterSources, hideLayers }),
    tileSize,
    borderRasterSource,
    attribution,
  };
  const targetControl = {
    fitBounds: otherBorderLayerProps.map.fitBounds,
    fitBoundsOptions: otherBorderLayerProps.map.fitBoundsOptions,
  };

  const handleShowHideChange = (hide: boolean) => {
    setHideLayers(hide);
  };

  const handleViewTypeChange = useCallback(
    (viewType: string, newSelectedItem?: TransformedAsset) => {
      const assetItem = newSelectedItem || selectedItem;

      if (!assetItem) {
        return;
      }

      if (isSatelliteImage(assetItem)) {
        setActiveSatelliteViewType(viewType);
      } else if (isSoilDataset(assetItem)) {
        setActiveSoilAttribute(viewType);
      } else if (isYieldDataset(assetItem)) {
        setActiveYieldAttribute(viewType);
      } else if (isAsAppliedDataset(assetItem)) {
        setActiveAsAppliedAttribute(viewType);
      } else if (isTopographyMap(assetItem)) {
        setActiveTopographyMapViewType(viewType);
      } else if (isEquationMap(assetItem)) {
        setActiveEquationMapViewType(viewType);
      }
    },
    [selectedItem],
  );

  const handleDatasetViewTypeChange = (
    item: DatasetViewType,
    newSelectedItem?: TransformedAsset,
  ) => {
    const assetItem = newSelectedItem || selectedItem;

    if (!assetItem) {
      return;
    }

    if (isSoilDataset(assetItem)) {
      setActiveSoilViewType(item);
    } else if (isYieldDataset(assetItem)) {
      setActiveYieldViewType(item);
    } else if (isAsAppliedDataset(assetItem)) {
      setActiveAsAppliedViewType(item);
    }
  };

  return {
    targetControl,
    datasetViewControl,
    indexesControl,
    miniLegendControl,
    showHideControl,
    legendControl,
    geoMapsControl,
    geoMapTypeControl,
    overlayLayersControls,
    mapProps,
    onDatasetViewTypeChange: handleDatasetViewTypeChange,
    onViewTypeChange: handleViewTypeChange,
    onShowHideChange: handleShowHideChange,
  };
}
