const transformToObjectMap = (labels) =>
  labels.reduce((acc, { name, value }) => {
    if (!acc[name]) {
      acc[name] = [];
    }

    acc[name].push(value);

    return acc;
  }, {});

export default transformToObjectMap;
