import React from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { formatDate } from '../../../../helpers/functions/utils/date';

import './index.scss';

const PinsTable = ({ pins = [] }) => {
  const { t } = useTranslation();

  return (
    <Table
      size="small"
      classes={{
        root: 'pins-table',
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>
            {t('field-profiler.fields-list.columns.date-created')}
          </TableCell>
          <TableCell>
            {t('field-profiler.fields-list.columns.date-updated')}
          </TableCell>
          <TableCell>{t('zones-ops.common.title')}</TableCell>
          <TableCell>{t('zones-map.tabs.description')}</TableCell>
          <TableCell>
            {t('upload-data-john-deere.steps.selectData.table.status')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {pins.map((pin, index) => (
          <TableRow key={index}>
            <TableCell>{formatDate(pin.createdDate)}</TableCell>
            <TableCell>{formatDate(pin.updatedDate)}</TableCell>
            <TableCell
              classes={{
                root: 'cell_min-200',
              }}
            >
              {pin.title}
            </TableCell>
            <TableCell
              classes={{
                root: 'cell_big',
              }}
            >
              {pin.comment}
            </TableCell>
            <TableCell
              classes={{
                root: 'cell_nowrap',
              }}
            >
              {pin.completed
                ? t('general.popups.pin-details.completed')
                : t('general.popups.pin-details.not-completed')}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default PinsTable;
