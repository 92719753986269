import React from 'react';
import clsx from 'clsx';

import type { SatelliteImageProvider } from '../../types/satelliteImage';
import Label from '../../../../components/Label';

import './index.scss';

const getProviderClassName = (provider: SatelliteImageProvider) => {
  const [providerFirsLetter] = provider;

  return clsx('satellite-provider-label', {
    'satellite-provider-label_planet': provider === 'PS',
    'satellite-provider-label_landsat': providerFirsLetter === 'L',
    'satellite-provider-label_sentinel': providerFirsLetter === 'S',
  });
};

export default function SatelliteProviderLabel({
  provider,
  classes,
}: {
  provider: SatelliteImageProvider;
  classes?: {
    label?: string;
  };
}) {
  return (
    <Label
      classes={{
        label: clsx(getProviderClassName(provider), classes?.label),
      }}
      text={provider}
    />
  );
}
