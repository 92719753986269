import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { TableCell, TableRow } from '@material-ui/core';

import { useAppSelector } from '../../../../../../../app/store/helpers/functions';
import ButtonGroup from '../../../../../../../components/ButtonGroup';
import { SavingsComparison } from '../../../../types/product';
import { selectAreaUnit } from '../../../../../../user/userSelectors';
import {
  selectZones,
  selectProducts,
  selectRatesPerProduct,
} from '../../../../zonesMapSelectors';
import { calculateSavings } from '../../../../helpers/functions/savings';
import { AREA_UNIT_TO_CURRENCY_MAP } from '../../../../../../user/helpers/constants/user';
import { ABSOLUTE_UNIT_TO_I18N_LABEL } from '../../../../../../../helpers/constants/units/absoluteUnit';
import { convertProductUnitToAbsoluteUnit } from '../../../../../../../helpers/functions/units/productUnit';
import { prettifyNumber } from '../../../../../../../helpers/markup';

import './index.scss';

export default function SavingsRow({
  activeProductIndex,
  onProductCellClick,
}: {
  activeProductIndex: number;
  onProductCellClick: (productIndex: number) => void;
}) {
  const { t } = useTranslation();

  const areaUnit = useAppSelector(selectAreaUnit);
  const products = useAppSelector(selectProducts);
  const zones = useAppSelector(selectZones);
  const ratesPerProduct = useAppSelector(selectRatesPerProduct);

  const [savingsComparison, setSavingsComparison] =
    useState<SavingsComparison>('highest');

  if (!zones?.length || !products?.length) return null;

  // @ts-expect-error
  const currency = AREA_UNIT_TO_CURRENCY_MAP[areaUnit];

  return (
    <TableRow className="savings-row">
      <TableCell className="savings-row__cell" colSpan={2}>
        <ButtonGroup
          value={savingsComparison}
          size="small"
          classes={{ label: 'savings-row__text' }}
          options={[
            {
              value: 'highest',
              label: t('zones-map.rates.savings-calculator.highest-rate'),
            },
            {
              value: 'custom',
              label: t('zones-map.rates.savings-calculator.custom-rate'),
            },
          ]}
          onChange={(value) => setSavingsComparison(value)}
        />
      </TableCell>

      {products.map((product, productIndex) => {
        const absoluteUnitLabel = t(
          ABSOLUTE_UNIT_TO_I18N_LABEL[
            convertProductUnitToAbsoluteUnit(product.unit)
          ],
        );

        const savings = calculateSavings(
          zones,
          product,
          ratesPerProduct,
          productIndex,
          savingsComparison,
        );

        return (
          <TableCell
            key={productIndex}
            className={clsx('savings-row__cell', 'product-column', {
              'active-column': productIndex === activeProductIndex,
            })}
            onClick={() => onProductCellClick(productIndex)}
          >
            <div className="savings-row__cell-savings">
              <span className="savings-row__text">
                {t('zones-map.rates.savings-calculator.product-saved', {
                  productUnit: absoluteUnitLabel,
                  amount: prettifyNumber(savings.amountSaved, 1),
                })}
              </span>
              <span className="savings-row__text">
                {t('zones-map.rates.savings-calculator.percentage-saved', {
                  amount: prettifyNumber(savings.percentageSaved, 1),
                })}
              </span>
              <span className="savings-row__text">
                {t('zones-map.rates.savings-calculator.money-saved', {
                  currencySign: currency.sign,
                  amount: prettifyNumber(savings.moneySaved, 1),
                })}
              </span>
            </div>
          </TableCell>
        );
      })}
    </TableRow>
  );
}
