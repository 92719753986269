import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  asAppliedDatasetsAdapter,
  equationMapsAdapter,
  farmsAdapter,
  fieldsAdapter,
  satelliteImagesAdapter,
  soilDatasetsAdapter,
  topographyMapsAdapter,
  vectorAnalysisMapsAdapter,
  yieldDatasetsAdapter,
} from './assetsAdapter';
import { createAppAsyncThunk } from '../../app/store/helpers/functions';
import { fetchFarmsFieldsDatasetsAnalyses as fetchFarmsFieldsDatasetsAnalysesAPI } from './assetsAPI';
import { selectAreaUnit } from '../user/userSelectors';
import { addFieldUuid } from './helpers/functions/adapter';
import { AssetsInitialState, FieldEntity } from './types/state';
import LoadStatus from '../../helpers/constants/utils/loadStatus';
import { ParsedEvent } from '../subscription/types/event';
import { AppThunk } from '../../app/store/helpers/types';
import {
  fetchGeneratedEquationMap as fetchEquationMapAPI,
  generateEquationMap as generateEquationMapAPI,
} from '../createAnalysis/createAnalysisAPI';
import type { GenerateEquationMapArg } from '../createAnalysis/types/api';
import { batchOperations } from '../../helpers/functions/utils/batchOperations';
import { isInvalid as isEquationMapInvalid } from '../../helpers/functions/entities/equationMap';
import { TransformedEquationMap } from '../../helpers/types/equationMap';
import { fetchAllSatelliteImages as fetchAllSatelliteImagesAPI } from '../satelliteImages/satelliteImagesAPI';
import {
  selectFieldsEntities,
  selectSatelliteImagesLoadStatus,
} from './assetsSelectors';

const initialState = {
  farms: farmsAdapter.getInitialState<AssetsInitialState>({
    loadStatus: {},
  }),
  fields: fieldsAdapter.getInitialState<AssetsInitialState>({
    loadStatus: {},
  }),
  soilDatasets: soilDatasetsAdapter.getInitialState<AssetsInitialState>({
    loadStatus: {},
  }),
  yieldDatasets: yieldDatasetsAdapter.getInitialState<AssetsInitialState>({
    loadStatus: {},
  }),
  asAppliedDatasets:
    asAppliedDatasetsAdapter.getInitialState<AssetsInitialState>({
      loadStatus: {},
    }),
  topographyMaps: topographyMapsAdapter.getInitialState<AssetsInitialState>({
    loadStatus: {},
  }),
  vectorAnalysisMaps:
    vectorAnalysisMapsAdapter.getInitialState<AssetsInitialState>({
      loadStatus: {},
    }),
  equationMaps: equationMapsAdapter.getInitialState<AssetsInitialState>({
    loadStatus: {},
  }),
  satelliteImages: satelliteImagesAdapter.getInitialState<AssetsInitialState>({
    loadStatus: {},
  }),
};

export const fetchFarmsFieldsDatasetsAnalyses = createAppAsyncThunk(
  'assets/fetchFarmsFieldsDatasetsAnalyses',
  async (
    {
      farmUuids,
      fieldUuids,
    }: {
      farmUuids: string[];
      fieldUuids: string[];
    },
    { getState },
  ) => {
    const state = getState();
    const areaUnit = selectAreaUnit(state);

    return fetchFarmsFieldsDatasetsAnalysesAPI({
      areaUnit,
      farmUuids,
      fieldUuids,
    });
  },
);

export const fetchFieldSatelliteImages = createAppAsyncThunk(
  'assets/fetchFieldSatelliteImages',
  async (
    {
      fieldUuid,
    }: {
      fieldUuid: string;
    },
    { getState, rejectWithValue },
  ) => {
    const state = getState();
    const fieldsEntities = selectFieldsEntities(state);
    const farmUuid = fieldsEntities[fieldUuid]?.farmUuid;

    if (!farmUuid) {
      return rejectWithValue(
        new Error('[Satellite images assets] no farmUuid'),
      );
    }

    return fetchAllSatelliteImagesAPI(farmUuid, fieldUuid);
  },
  {
    condition: ({ fieldUuid }, { getState }) => {
      const state = getState();
      const satelliteImagesLoadStatus = selectSatelliteImagesLoadStatus(state);
      const fieldSatelliteImagesLoadStatus =
        satelliteImagesLoadStatus[fieldUuid];

      return (
        fieldSatelliteImagesLoadStatus !== LoadStatus.loading &&
        fieldSatelliteImagesLoadStatus !== LoadStatus.success
      );
    },
  },
);

export const generateEquationMaps = createAppAsyncThunk(
  'assets/generateEquationMaps',
  async (args: GenerateEquationMapArg[]) => {
    const GENERATION_BATCH_SIZE = 10;

    return batchOperations(
      async (arg) => generateEquationMapAPI(arg),
      args,
      GENERATION_BATCH_SIZE,
    );
  },
);

export const subscription =
  (parsedEvent: ParsedEvent): AppThunk =>
  async (dispatch, getState) => {
    const { pathLength, farmUuid, fieldUuid, equationMapUuid } = parsedEvent;

    if (pathLength !== 3) {
      return;
    }

    const state = getState();
    const areaUnit = selectAreaUnit(state);

    if (equationMapUuid !== '') {
      const equationMap = await fetchEquationMapAPI({
        farmUuid,
        fieldUuid,
        equationMapUuid,
        areaUnit,
      });

      dispatch(
        equationMapGenerationFinished({
          equationMap,
          fieldUuid,
        }),
      );
    }
  };

const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    equationMapGenerationFinished(
      state,
      action: PayloadAction<{
        equationMap: TransformedEquationMap;
        fieldUuid: string;
      }>,
    ) {
      if (!isEquationMapInvalid(action.payload.equationMap)) {
        equationMapsAdapter.upsertOne(state.equationMaps, {
          fieldUuid: action.payload.fieldUuid,
          ...action.payload.equationMap,
        });
      }
    },
    resetSatelliteImages(state) {
      state.satelliteImages = initialState.satelliteImages;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFarmsFieldsDatasetsAnalyses.pending, (state, action) => {
        const { fieldUuids } = action.meta.arg;

        fieldUuids.forEach((fieldUuid) => {
          state.farms.loadStatus[fieldUuid] = LoadStatus.loading;
          state.fields.loadStatus[fieldUuid] = LoadStatus.loading;
          state.soilDatasets.loadStatus[fieldUuid] = LoadStatus.loading;
          state.yieldDatasets.loadStatus[fieldUuid] = LoadStatus.loading;
          state.asAppliedDatasets.loadStatus[fieldUuid] = LoadStatus.loading;
          state.topographyMaps.loadStatus[fieldUuid] = LoadStatus.loading;
          state.vectorAnalysisMaps.loadStatus[fieldUuid] = LoadStatus.loading;
          state.equationMaps.loadStatus[fieldUuid] = LoadStatus.loading;
        });
      })
      .addCase(fetchFarmsFieldsDatasetsAnalyses.fulfilled, (state, action) => {
        action.payload.forEach((farm) => {
          const farmEntity = {
            ...farm,
            fields: farm.fields.map(({ uuid }) => uuid),
          };

          farmsAdapter.addOne(state.farms, farmEntity);

          farm.fields.forEach((field) => {
            const {
              soilDatasets = [],
              yieldDatasets = [],
              asAppliedDatasets = [],
              topographyMaps = [],
              vectorAnalysisMaps = [],
              equationMaps = [],
              ...cleanedField
            } = field;
            const fieldWithUuids: FieldEntity = {
              ...cleanedField,
              soilDatasets: field.soilDatasets?.map(({ uuid }) => uuid),
              yieldDatasets: field.yieldDatasets?.map(({ uuid }) => uuid),
              asAppliedDatasets: field.asAppliedDatasets?.map(
                ({ uuid }) => uuid,
              ),
              topographyMaps: field.topographyMaps?.map(({ uuid }) => uuid),
              vectorAnalysisMaps: field.vectorAnalysisMaps?.map(
                ({ uuid }) => uuid,
              ),
              equationMaps: field.equationMaps?.map(({ uuid }) => uuid),
            };

            fieldsAdapter.addOne(state.fields, fieldWithUuids);

            soilDatasetsAdapter.addMany(
              state.soilDatasets,
              addFieldUuid(soilDatasets, field.uuid),
            );

            yieldDatasetsAdapter.addMany(
              state.yieldDatasets,
              addFieldUuid(yieldDatasets, field.uuid),
            );

            asAppliedDatasetsAdapter.addMany(
              state.asAppliedDatasets,
              addFieldUuid(asAppliedDatasets, field.uuid),
            );

            topographyMapsAdapter.addMany(
              state.topographyMaps,
              addFieldUuid(topographyMaps, field.uuid),
            );

            vectorAnalysisMapsAdapter.addMany(
              state.vectorAnalysisMaps,
              addFieldUuid(vectorAnalysisMaps, field.uuid),
            );

            equationMapsAdapter.addMany(
              state.equationMaps,
              addFieldUuid(equationMaps, field.uuid),
            );
          });
        });

        const { fieldUuids } = action.meta.arg;

        fieldUuids.forEach((fieldUuid) => {
          state.farms.loadStatus[fieldUuid] = LoadStatus.success;
          state.fields.loadStatus[fieldUuid] = LoadStatus.success;
          state.soilDatasets.loadStatus[fieldUuid] = LoadStatus.success;
          state.yieldDatasets.loadStatus[fieldUuid] = LoadStatus.success;
          state.asAppliedDatasets.loadStatus[fieldUuid] = LoadStatus.success;
          state.topographyMaps.loadStatus[fieldUuid] = LoadStatus.success;
          state.vectorAnalysisMaps.loadStatus[fieldUuid] = LoadStatus.success;
          state.equationMaps.loadStatus[fieldUuid] = LoadStatus.success;
        });
      })
      .addCase(fetchFarmsFieldsDatasetsAnalyses.rejected, (state, action) => {
        const { fieldUuids } = action.meta.arg;

        fieldUuids.forEach((fieldUuid) => {
          state.farms.loadStatus[fieldUuid] = LoadStatus.error;
          state.fields.loadStatus[fieldUuid] = LoadStatus.error;
          state.soilDatasets.loadStatus[fieldUuid] = LoadStatus.error;
          state.yieldDatasets.loadStatus[fieldUuid] = LoadStatus.error;
          state.asAppliedDatasets.loadStatus[fieldUuid] = LoadStatus.error;
          state.topographyMaps.loadStatus[fieldUuid] = LoadStatus.error;
          state.vectorAnalysisMaps.loadStatus[fieldUuid] = LoadStatus.error;
          state.equationMaps.loadStatus[fieldUuid] = LoadStatus.error;
        });
      })
      .addCase(fetchFieldSatelliteImages.pending, (state, action) => {
        const { fieldUuid } = action.meta.arg;
        state.satelliteImages.loadStatus[fieldUuid] = LoadStatus.loading;
      })
      .addCase(fetchFieldSatelliteImages.fulfilled, (state, action) => {
        const { fieldUuid } = action.meta.arg;
        const satelliteImagesUuids = action.payload?.map(({ uuid }) => uuid);
        const fieldEntity = state.fields.entities[fieldUuid];

        state.satelliteImages.loadStatus[fieldUuid] = LoadStatus.success;

        if (fieldEntity) {
          fieldsAdapter.updateOne(state.fields, {
            id: fieldEntity.uuid,
            changes: {
              satelliteImages: satelliteImagesUuids,
            },
          });

          satelliteImagesAdapter.addMany(
            state.satelliteImages,
            addFieldUuid(action.payload, fieldUuid),
          );
        }
      })
      .addCase(fetchFieldSatelliteImages.rejected, (state, action) => {
        const { fieldUuid } = action.meta.arg;
        state.satelliteImages.loadStatus[fieldUuid] = LoadStatus.error;
      });
  },
});

export const { equationMapGenerationFinished, resetSatelliteImages } =
  assetsSlice.actions;

export default assetsSlice.reducer;
