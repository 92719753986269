query getOrdersAndAdditionalUserData(
  $getStripeCustomerInput: GetStripeCustomerInput
) {
  getUserData {
    uuid
    email
    acceptedTermsAndConditions
    country
    companyType
    areaUnit
    maxArea
    reachedAreaLimit
    orders {
      plan
      currency
      billingPeriodInMonths
      paymentMethod
      orderDate
      uuid
      status
    }
    packages {
      orderUuid
      startDate
      endDate
    }
  }

  getStripeCustomer(input: $getStripeCustomerInput) {
    id
    phone
  }
}
