import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';

const Loading = () => {
  const { t } = useTranslation();

  return <div className="loading-label">{t('general.controls.loading')}</div>;
};

export default Loading;
