import React from 'react';
import { useTranslation } from 'react-i18next';

import MessagePopup from '../../../../../components/Popups/MessagePopup';

export default function UnsavedChangesPopup({
  titleLabel,
  cancelLabel,
  ...popup
}) {
  const { t } = useTranslation();

  return (
    <MessagePopup
      className="unsaved-changes-popup"
      title={titleLabel || t('general.popups.unsaved-changes.title')}
      message={t('general.popups.unsaved-changes.description')}
      confirmLabel={t('general.popups.unsaved-changes.quit')}
      cancelLabel={
        cancelLabel || t('general.popups.unsaved-changes.keep-editing')
      }
      {...popup}
    />
  );
}
