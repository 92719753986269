query getVamapAssets(
  $farmUuids: [ID]
  $fieldUuids: [ID]
  $satelliteImageUuids: [ID]
  $vectorAnalysisMapUuids: [ID]
  $soilDatasetUuids: [ID]
  $yieldDatasetUuids: [ID]
  $asAppliedDatasetUuids: [ID]
  $topographyMapUuids: [ID]
  $hasSatellite: Boolean!
  $hasVectorAnalysis: Boolean!
  $hasSoil: Boolean!
  $hasYield: Boolean!
  $hasAsApplied: Boolean!
  $hasTopography: Boolean!
) {
  getFarms(farmUuids: $farmUuids) {
    uuid
    fields(fieldUuids: $fieldUuids) {
      uuid
      vectorAnalysisMaps(vectorAnalysisMapUuids: $vectorAnalysisMapUuids)
        @include(if: $hasVectorAnalysis) {
        uuid
        name
        geoMaps {
          shortName
          url
          classes
          hexColorCodes
        }
      }
      satelliteImages(threshold: 0, satelliteImageUuids: $satelliteImageUuids)
        @include(if: $hasSatellite) {
        satelliteImage {
          uuid
          provider
          acquisitionDate
        }
        ndviAverage
      }
      soilDatasets(soilDatasetUuids: $soilDatasetUuids) @include(if: $hasSoil) {
        uuid
        name
        createdDate
        geoMaps {
          shortName
          url
          classes
          hexColorCodes
        }
        fullAttributes {
          fullName
          unit
          transliteratedName
        }
      }
      yieldDatasets(yieldDatasetUuids: $yieldDatasetUuids)
        @include(if: $hasYield) {
        uuid
        name
        createdDate
        geoMaps {
          shortName
          url
          classes
          hexColorCodes
        }
        fullAttributes {
          fullName
          unit
          transliteratedName
        }
      }
      asAppliedDatasets(asAppliedDatasetUuids: $asAppliedDatasetUuids)
        @include(if: $hasAsApplied) {
        uuid
        name
        createdDate
        geoMaps {
          shortName
          url
          classes
          hexColorCodes
        }
        fullAttributes {
          fullName
          unit
          transliteratedName
        }
      }
      topographyMaps(topographyMapUuids: $topographyMapUuids)
        @include(if: $hasTopography) {
        uuid
        name
        createdDate
        geoMaps {
          shortName
          url
          hexColorCodes
          classes
        }
        fullAttributes {
          fullName
          unit
          transliteratedName
        }
      }
    }
  }
}
