query getOrganizationsJohnDeere($orgFilter: OrganizationFilterJohnDeere) {
  getOrganizationsJohnDeere(orgFilter: $orgFilter) {
    orgs {
      id
      name
      links {
        rel
        uri
      }
    }
    totalCount
  }
}
