import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import AddIcon from '@material-ui/icons/Add';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import EditIcon from '@material-ui/icons/Edit';
import PlanetIcon from '@material-ui/icons/SatelliteOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';

import Tabs from '../../../../../components/Tabs';
import ToolsPanel from '../../../../../components/ToolsPanel';
import Button from '../../../../../components/Button';
import ButtonMenu from '../../../../../components/ButtonMenu';
import EditFieldBoundaryIcon from '../../../../../components/Icons/editFieldBoundary';
import ExportIcon from '../../../../../components/Icons/export';
import ExportFileIcon from '../../../../../components/Icons/exportFile';
import RenameIcon from '../../../../../components/Icons/rename';
import CompareLayersIcon from '../../../../../components/Icons/compare-layers.svg';
import ExportPdfIcon from '../../components/Icons/exportPdf';
import ExportWorkPlanIcon from '../../../../../components/Icons/exportWorkPlan';
import { triggerMapResize } from '../../../../../helpers/mapbox/map';
import { isFeatureTypesCorrect } from '../../../../field/helpers/functions/features';
import { openPopup } from '../../../popups/popupsSlice';
import { exportAsset } from '../../../../exportData/exportDataSlice';
import {
  warningNotify,
  errorNotify,
} from '../../../../notifications/helpers/functions/notify';
import { CustomError } from '../../../../../helpers/functions/utils/errorHandling';
import {
  selectFarmUuid,
  selectField,
  selectName,
  selectFieldUuid,
  selectLabels,
  selectItemPins,
  selectFieldFeatureEdit,
} from '../../../../field/fieldSelectors';
import { selectDataLayersTreeViewType } from '../../fieldWorkflowSelectors';
import {
  saveBoundaryFeature,
  resetFeatureEdit,
} from '../../../../field/fieldSlice';
import {
  selectPermissionedPlanet,
  selectAreaUnit,
} from '../../../../user/userSelectors';
import { PAGES_ROOTS, getRootLink } from '../../../../../helpers/navigation';
import AmplitudeAnalytics from '../../../../../helpers/classes/amplitudeAnalytics';
import FieldTab from '../../helpers/constants/fieldTab';
import isEdit from '../../../../../helpers/functions/pages/isEdit';
import POPUPS from '../../../popups/helpers/constants/popups';
import {
  isInUnsupportedRegion,
  isInvalid,
} from '../../../../field/helpers/functions/field';
import {
  isJohnDeereExportWorkflowEnabled,
  isPdfReportsEnabled,
} from '../../../../../helpers/functions/utils/appConfig';
import {
  useDeleteFieldsMutation,
  useRenameFieldMutation,
  useSetFieldLabelsMutation,
} from '../../../../fields/fieldsAPI';
import { useGetFarmQuery } from '../../../../farms/farmsAPI';
import { setDataLayersTreeViewType } from '../../fieldWorkflowSlice';
import { selectJDIntegrationData } from '../../../../jdProfile/jdProfileSelectors';
import DataLayersTreeViewType from '../../helpers/constants/dataLayersTreeViewType';
import { getWorkflowOptions } from '../../../zonesOps/helpers/functions/workflowOptions';
import { isJDExportAsWorkPlanDisabled } from '../../../jdWorkPlan/helpers/functions/ui';
import { openPopupExportToJohnDeereAsWorkPlan } from '../../../jdWorkPlan/jdWorkPlanSlice';
import ExportToJohnDeereTooltip from '../../../dataLayersView/components/DataLayersTree/ExportToJohnDeereTooltip';
import FieldOperation from '../../helpers/constants/fieldOperation';

import './index.scss';

const FieldWorkflowToolsPanel = ({ tab, onTabChange }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [deleteFields] = useDeleteFieldsMutation();
  const [renameField] = useRenameFieldMutation();
  const [setFieldLabels] = useSetFieldLabelsMutation();

  const field = useSelector(selectField);
  const fieldUuid = useSelector(selectFieldUuid);
  const fieldName = useSelector(selectName);
  const farmUuid = useSelector(selectFarmUuid);
  const areaUnit = useSelector(selectAreaUnit);
  const { data: farm } = useGetFarmQuery({ farmUuid });
  const isEditMode = isEdit(location.search);
  const labels = useSelector(selectLabels);
  const pins = useSelector((state) => selectItemPins(state, fieldUuid));
  const shouldDisplayPinsWarning = !!pins.length;
  const editFeature = useSelector(selectFieldFeatureEdit);
  const isPlanetAllowed = useSelector(selectPermissionedPlanet);
  const viewType = useSelector(selectDataLayersTreeViewType);
  const { jdProfileReady, ...otherJDIntegrationData } = useSelector(
    selectJDIntegrationData,
  );
  const showPlanet =
    isPlanetAllowed && !isInUnsupportedRegion(field) && !isInvalid(field);

  const workflowOptions = useMemo(
    () =>
      getWorkflowOptions({
        farmUuid,
        fieldUuid,
        options: {
          fromField: true,
        },
      }),
    [farmUuid, fieldUuid],
  );

  const handleCreateMapItemClick = ({ id }) => {
    AmplitudeAnalytics.trackCreateAnalysisMapWorkflowSelected({
      workflow: id,
      page: PAGES_ROOTS.field,
    });
  };

  const onFieldOpsClick = ({ id }) => {
    AmplitudeAnalytics.trackFieldOperationClicked({
      operation: id,
      page: PAGES_ROOTS.field,
    });

    if (id === FieldOperation.manageFieldLabels) {
      dispatch(
        openPopup({
          type: 'manage-labels',
          fieldLabels: labels,
          fieldName,
          onConfirm: (newLabels) => {
            void setFieldLabels({
              farmUuid,
              uuid: fieldUuid,
              labels: newLabels,
            });
          },
        }),
      );
    } else if (id === FieldOperation.editFieldBoundary) {
      const searchParams = new URLSearchParams(location.search);
      dispatch(
        setDataLayersTreeViewType({
          viewType: DataLayersTreeViewType.default,
        }),
      );

      searchParams.set('edit', true);

      history.push({
        search: searchParams.toString(),
      });
      triggerMapResize();
    } else if (id === FieldOperation.downloadBoundary) {
      dispatch(
        exportAsset({
          fieldBoundaries: [
            {
              uuid: farmUuid,
              fieldUuid,
            },
          ],
        }),
      );
    } else if (id === FieldOperation.exportBoundaryToJDAsFiles) {
      dispatch(
        openPopup({
          type: POPUPS.exportBoundaryToJohnDeere,
          labels,
          farmName: farm?.name,
          fieldName,
          boundary: {
            name: fieldName,
            farmUuid,
            fieldUuid,
          },
        }),
      );
    } else if (id === FieldOperation.renameField) {
      dispatch(
        openPopup({
          type: 'rename-field',
          entityName: fieldName,
          onConfirm: (name) => {
            void renameField({
              farmUuid,
              uuid: fieldUuid,
              name,
            });
          },
        }),
      );
    } else if (id === FieldOperation.deleteField) {
      dispatch(
        openPopup({
          type: POPUPS.deleteField,
          entityName: fieldName,
          shouldDisplayPinsWarning,
          onConfirm: async () => {
            try {
              await deleteFields([
                {
                  farmUuid,
                  uuid: fieldUuid,
                },
              ]).unwrap();

              history.replace(getRootLink(PAGES_ROOTS.fields));
            } catch (error) {
              errorNotify({
                error: new CustomError(
                  '[fieldWorkflow] Unable to delete field.',
                  {
                    cause: error,
                  },
                ),
                dispatch,
              });
            }
          },
        }),
      );
    } else if (id === FieldOperation.exportPdf) {
      handleExportPdfClick();
    } else if (id === FieldOperation.exportWorkPlan) {
      dispatch(openPopupExportToJohnDeereAsWorkPlan({ areaUnit }));
    }
  };

  const onPlanetClick = () => {
    dispatch(
      openPopup({
        type: POPUPS.planetMetadataRequest,
        field: {
          uuid: fieldUuid,
          farmUuid,
          name: fieldName,
        },
      }),
    );
  };

  const cancelEditMode = () => {
    const searchParams = new URLSearchParams(location.search);

    searchParams.delete('edit');
    history.push({
      search: searchParams.toString(),
    });
    triggerMapResize();
    dispatch(resetFeatureEdit());
  };

  const handleEditCancel = () => {
    if (editFeature) {
      dispatch(
        openPopup({
          type: 'unsaved-changes',
          onConfirm: cancelEditMode,
        }),
      );
    } else {
      cancelEditMode();
    }
  };

  const onEditSaveClick = () => {
    const featureTypesCorrect = isFeatureTypesCorrect(editFeature.features);

    if (!featureTypesCorrect) {
      warningNotify({
        message: t('general.notifications.oops-draw-field'),
      });

      return;
    }

    dispatch(saveBoundaryFeature()).then(cancelEditMode);
  };

  const handleCompareLayersClick = () => {
    dispatch(
      setDataLayersTreeViewType({
        viewType:
          viewType === DataLayersTreeViewType.compareLayers
            ? DataLayersTreeViewType.default
            : DataLayersTreeViewType.compareLayers,
      }),
    );
  };

  const handleExportPdfClick = () => {
    dispatch(
      setDataLayersTreeViewType({
        viewType:
          viewType === DataLayersTreeViewType.exportPdf
            ? DataLayersTreeViewType.default
            : DataLayersTreeViewType.exportPdf,
      }),
    );
  };

  const getLeftActions = () => {
    if (isEditMode) {
      return (
        <Typography className="field-tools-panel__edit-description">
          {t('field.edit-description')}
        </Typography>
      );
    }

    return (
      <>
        <ButtonMenu
          key="create-map-button"
          startIcon={<AddIcon />}
          items={workflowOptions}
          onItemClick={handleCreateMapItemClick}
          horizontalAnchor="left"
        >
          {t('field.tools-panel.create-map')}
        </ButtonMenu>
        <ButtonMenu
          key="field-ops-button"
          startIcon={<EditIcon />}
          items={[
            {
              id: FieldOperation.manageFieldLabels,
              label: t('field.tools-panel.field-ops-items.manage-field-labels'),
              IconComponent: LocalOfferIcon,
            },
            {
              id: FieldOperation.editFieldBoundary,
              label: t('field.tools-panel.field-ops-items.edit-field-boundary'),
              IconComponent: EditFieldBoundaryIcon,
            },
            {
              id: FieldOperation.downloadBoundary,
              label: t('field.tools-panel.field-ops-items.download-boundary'),
              IconComponent: ExportIcon,
            },
            {
              id: '_divider',
              type: 'divider',
            },
            {
              id: FieldOperation.renameField,
              label: t('general.controls.rename'),
              IconComponent: RenameIcon,
            },
            {
              id: FieldOperation.deleteField,
              label: t('general.controls.delete'),
              IconComponent: DeleteIcon,
            },
          ]}
          onItemClick={onFieldOpsClick}
        >
          {t('field.tools-panel.field-operations')}
        </ButtonMenu>
        {showPlanet && (
          <Button startIcon={<PlanetIcon />} onClick={onPlanetClick}>
            Planet
          </Button>
        )}
        <Button
          pressed={viewType === DataLayersTreeViewType.compareLayers}
          startIcon={<CompareLayersIcon />}
          onClick={handleCompareLayersClick}
        >
          {t('field.tools-panel.compare-layers')}
        </Button>
        {viewType === DataLayersTreeViewType.exportPdf ? (
          <Button
            pressed={viewType === DataLayersTreeViewType.exportPdf}
            startIcon={<ExportPdfIcon />}
            onClick={handleExportPdfClick}
          >
            {t('field.tools-panel.export-pdf')}
          </Button>
        ) : (
          <ButtonMenu
            key="field-export-button"
            startIcon={<ExportIcon />}
            items={[
              ...(isJohnDeereExportWorkflowEnabled()
                ? [
                    {
                      id: FieldOperation.exportBoundaryToJDAsFiles,
                      label: t(
                        'field.tools-panel.field-ops-items.export-boundary-to-jd-as-files',
                      ),
                      IconComponent: ExportFileIcon,
                      disabled: !jdProfileReady,
                    },
                    {
                      id: FieldOperation.exportWorkPlan,
                      label: t(
                        'field.assets.zones-map-ops-items.export-to-jd-as-work-plan',
                      ),
                      IconComponent: ExportWorkPlanIcon,
                      disabled: isJDExportAsWorkPlanDisabled(
                        otherJDIntegrationData,
                      ),
                      tooltipRenderer: () => (
                        <ExportToJohnDeereTooltip {...otherJDIntegrationData} />
                      ),
                    },
                  ]
                : []),
              ...(isPdfReportsEnabled()
                ? [
                    {
                      id: FieldOperation.exportPdf,
                      label: t('field.tools-panel.export-pdf'),
                      IconComponent: ExportPdfIcon,
                    },
                  ]
                : []),
            ]}
            onItemClick={onFieldOpsClick}
          >
            {t('export.export')}
          </ButtonMenu>
        )}
      </>
    );
  };

  const getRightActions = () => {
    let result;

    if (isEditMode) {
      result = [
        <Button key="cancel" variant="outlined" onClick={handleEditCancel}>
          {t('general.controls.cancel')}
        </Button>,
        <Button
          disabled={!editFeature}
          key="save-and-finish"
          variant="contained"
          color="primary"
          onClick={onEditSaveClick}
        >
          {t('zones-ops.common.finish')}
        </Button>,
      ];
    }

    return result;
  };

  const getLeftFilters = () => {
    if (isEditMode) {
      return null;
    }

    return (
      <Tabs
        tabs={[
          {
            label: {
              primary: t('general.controls.map-legend.data-layers.title'),
            },
            value: FieldTab.dataLayers,
          },
          {
            label: {
              primary: t('field.field-details'),
            },
            value: FieldTab.fieldDetails,
          },
        ]}
        value={tab}
        onTabChange={(_event, val) => onTabChange(val)}
      />
    );
  };

  return (
    <ToolsPanel
      classes={{
        root: 'field-tools-panel',
        actions: 'field-tools-panel__actions',
        actionsLeft: 'field-tools-panel__actions_left',
        actionsRight: 'field-tools-panel__actions_right',
        filters: clsx('field-tools-panel__filters', {
          'field-tools-panel__filters_empty': isEditMode,
        }),
      }}
      actionsLeft={getLeftActions()}
      actionsRight={getRightActions()}
      filtersLeft={getLeftFilters()}
    />
  );
};

export default FieldWorkflowToolsPanel;
