import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';

import KeyValue from '../../../KeyValue';

import './index.scss';

export default function Overview({
  items = [],
  header,
  subheader,
  noData = '-',
  classes,
}: {
  items: {
    title: string;
    value: string;
  }[];
  header?: ReactNode;
  subheader: ReactNode;
  noData?: string;
  classes?: {
    root: string;
  };
}) {
  const { t } = useTranslation();

  return (
    <div className={clsx('overview', classes?.root)}>
      <div className="overview__title">
        <Typography className="overview__header">
          {header || t('general.controls.map-legend.overview')}
        </Typography>
        {subheader && (
          <Typography className="overview__subheader">{subheader}</Typography>
        )}
      </div>
      <div className="overview__items">
        {items.map((item, index) => {
          const value = item.value != null ? item.value : noData;

          return <KeyValue key={index} title={item.title} value={value} />;
        })}
      </div>
    </div>
  );
}
