import React, { useState } from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

import './index.scss';

interface State<T> {
  value: T;
  title: string;
  name?: string;
  icon?: React.FC<React.SVGProps<SVGSVGElement>> | React.FC<SvgIconProps>;
}

const ToggleButton = <T,>({
  compact,
  disabled,
  noOffset,
  onState,
  offState,
  value,
  classes,
  onChange,
}: {
  compact?: boolean;
  disabled?: boolean;
  noOffset?: boolean;
  onState: State<T>;
  offState: State<T>;
  value?: T;
  classes?: {
    root?: string;
    icon?: string;
  };
  onChange: (v: T) => void;
}) => {
  const [innerValue, setInnerValue] = useState(value || offState.value);
  let activeState = onState;

  if (innerValue === onState.value) {
    activeState = offState;
  }

  const Icon = activeState.icon;

  return (
    <button
      {...(activeState.title ? { title: activeState.title } : null)}
      disabled={disabled}
      className={clsx('map-toggle-button', classes?.root, {
        'map-toggle-button_compact': compact,
        'map-toggle-button_no_offset': noOffset,
        'map-toggle-button_disabled': disabled,
      })}
      onClick={() => {
        setInnerValue(activeState.value);
        onChange(activeState.value);
      }}
    >
      {Icon && (
        <Icon className={clsx('map-toggle-button__icon', classes?.icon)} />
      )}
      {activeState.name && (
        <Typography variant="body2" className="map-toggle-button__name">
          {activeState.name}
        </Typography>
      )}
    </button>
  );
};

export default ToggleButton;
