import { AssetType } from '../../../../helpers/constants/entities/asset';
import type { AnalysisMapType } from '../../../../helpers/constants/entities/vectorAnalysisMap';
import type { PinsGroup } from '../../types';

const getPinsGroupEntity = ({
  assetType,
  uuid,
  analysisType,
}: {
  assetType: AssetType;
  uuid: string;
  analysisType?: AnalysisMapType;
}): PinsGroup['entity'] => {
  const result: PinsGroup['entity'] = {
    assetType,
    uuid,
  };

  if (analysisType) {
    result.analysisType = analysisType;
  }

  return result;
};

export const createPinsGroup = (
  entity: {
    assetType: AssetType;
    uuid: string;
    name?: string;
    analysisType?: AnalysisMapType;
  },
  fieldUuid: string,
): PinsGroup => ({
  assetType: AssetType.pinsGroup,
  uuid: entity.uuid,
  name: entity.name ?? '',
  fieldUuid,
  entity: getPinsGroupEntity(entity),
  pins: [],
});

export const getAssetHasPins = (
  uuid: string,
  pinsGroups: PinsGroup[] = [],
): boolean => {
  const pinsGroup = pinsGroups.find((p) => p.uuid === uuid);

  return !!pinsGroup?.pins?.length;
};
