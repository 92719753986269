import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Stepper, { Step } from '../../../../../../../components/Stepper';
import Panel from '../../../SelectFieldsWorkflowPanel';
import { selectSelectedFieldsUuids } from '../../../../batchAnalyticsSelectors';
import { goToWorkflowStep } from '../../../../batchAnalyticsSlice';

export default function SelectFieldsWorkflowStepper({
  steps,
  stepIndex,
}: {
  steps: Step[];
  stepIndex: number;
}) {
  const dispatch = useDispatch();
  const fieldsUuids = useSelector(selectSelectedFieldsUuids);

  const handleClickNext = () => {
    dispatch(goToWorkflowStep());
  };

  return (
    <Stepper
      activeStep={stepIndex}
      steps={steps}
      backDisabled
      nextDisabled={!fieldsUuids.length}
      onClickNext={handleClickNext}
    >
      <Panel key="panel" />
    </Stepper>
  );
}
