import i18n from 'i18next';

import getCommonSteps from '../../../zonesOps/helpers/functions/getCommonSteps';
import Draw from '../../containers/Stepper/Steps/Draw';
import { Step } from '../../../../../components/Stepper';

const getSteps = (withFieldSelection = false) => {
  let firstSteps: Step[] = [];

  if (withFieldSelection) {
    firstSteps = getCommonSteps();
  }

  return [
    ...firstSteps,
    {
      name: i18n.t('zones-ops.draw-manually.stepper.draw'),
      id: 'draw',
      component: Draw,
    },
  ];
};

export default getSteps;
