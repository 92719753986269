import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import CreateIcon from '@material-ui/icons/Create';

import Button from '../../../../../components/Button';

import './index.scss';

export default function EquationPreview({
  equationAsText,
  dataVariables,
  equationResultVariable,
  onAdjustEquationClick,
}: {
  equationAsText: string;
  dataVariables: string[];
  equationResultVariable: string;
  onAdjustEquationClick: () => void;
}) {
  const { t } = useTranslation();
  const dataVariablesString = dataVariables.join(', ');

  return (
    <div className="equation-preview">
      <div className="equation-preview__header">
        <Typography className="equation-preview__header-title">
          {t('zones-ops.equation-based.steps.4.panel.textarea-title')}
        </Typography>
        <Button
          className="equation-preview__header-button"
          variant="text"
          color="primary"
          startIcon={<CreateIcon />}
          onClick={onAdjustEquationClick}
        >
          {t('batch-analytics.steps.equation.adjust-equation')}
        </Button>
      </div>
      <div className="equation-preview__formula">{equationAsText}</div>
      <div className="equation-preview__variables">
        <div className="equation-preview__datasets-variables">
          <Typography className="equation-preview__datasets-variables-title">
            {t('zones-ops.equation-based.steps.4.panel.variables-list.title')}
          </Typography>
          <Typography>{dataVariablesString}</Typography>
        </div>
        <div className="equation-preview__output-variable">
          <Typography className="equation-preview__output-variable-title">
            {t('zones-ops.equation-based.steps.4.panel.output-variable-title')}
          </Typography>
          <Typography>{equationResultVariable}</Typography>
        </div>
      </div>
    </div>
  );
}
