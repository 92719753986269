import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ToolsPanel from '../../../../../components/ToolsPanel';
import ZonesMapsListFilter from '../../../../../components/ZonesMapsListFilter';
import { remapNameToTitle } from '../../../../../helpers/markup';
import { fetchLabels } from '../../../../labels/labelsSlice';
import { updateExportFlowFilter } from '../../jdExportSlice';
import { selectLabelsMap } from '../../../../labels/labelsSelectors';
import { selectExportFlowFilter } from '../../jdExportSelectors';
import useAllFields from '../../../../fields/hooks/useAllFields';
import FieldFragment from '../../../../fields/helpers/constants/fieldFragment';
import LoadStatus from '../../../../../helpers/constants/utils/loadStatus';

import './index.scss';

const Panel = () => {
  const dispatch = useDispatch();
  const {
    farm: farmValue,
    field: fieldValue,
    type,
    purpose,
    labels,
    withRates: withRatesValue,
  } = useSelector(selectExportFlowFilter);
  const { allFields, status: allFieldsStatus } = useAllFields(
    FieldFragment.filterField,
  );
  const labelsMap = useSelector(selectLabelsMap);
  const typeValue = type || '';
  const purposeValue = purpose || '';

  const onFilterUpdate = (update) => {
    dispatch(updateExportFlowFilter(update));
  };

  const onFarmChange = (value) => {
    const update = {
      farm: value,
      type: null,
      purpose: null,
      withRates: false,
    };

    if (value && fieldValue) {
      const hasSelectedField = allFields.some(
        ({ farmUuid }) => farmUuid === fieldValue.farmUuid,
      );

      if (!hasSelectedField) {
        update.field = null;
      }
    }

    dispatch(updateExportFlowFilter(update));
  };

  const onFieldChange = (value) => {
    let update = {
      field: value,
    };

    if (!value) {
      update = {
        ...update,
        type: null,
        purpose: null,
        withRates: false,
      };
    }

    dispatch(updateExportFlowFilter(update));
  };

  const onLabelsFilterGetOptions = () => {
    dispatch(fetchLabels());
  };

  return (
    <ToolsPanel
      classes={{
        root: 'export-data-john-deere-tools-panel',
      }}
      filtersLeft={
        <ZonesMapsListFilter
          farm={farmValue}
          fields={remapNameToTitle(allFields)}
          fieldsLoading={allFieldsStatus === LoadStatus.loading}
          field={fieldValue}
          type={typeValue}
          purpose={purposeValue}
          labelsMap={labelsMap}
          labels={labels}
          withRates={withRatesValue}
          onFarmChange={onFarmChange}
          onFieldChange={onFieldChange}
          onFilterUpdate={onFilterUpdate}
          onLabelsFilterGetOptions={onLabelsFilterGetOptions}
        />
      }
    />
  );
};

export default Panel;
