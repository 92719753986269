import { useEffect } from 'react';

import { selectVectorAnalysisMap } from '../fieldSelectors';
import { fetchVamapWithGeojsonAndPins } from '../fieldSlice';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../app/store/helpers/functions';
import { selectIsLoaded } from '../../user/userSelectors';
import { selectLoadStatus } from '../../ui/zonesMap/zonesMapSelectors';
import LoadStatus from '../../../helpers/constants/utils/loadStatus';

export default function useVamap(
  farmUuid: string,
  fieldUuid: string,
  uuid: string,
) {
  const dispatch = useAppDispatch();
  const userLoaded = useAppSelector(selectIsLoaded);
  const loadStatus = useAppSelector(selectLoadStatus);
  const vamap = useAppSelector((state) => selectVectorAnalysisMap(state, uuid));

  useEffect(() => {
    const fetchVamap = async () => {
      await dispatch(
        fetchVamapWithGeojsonAndPins({
          farmUuid,
          fieldUuid,
          uuid,
        }),
      );
    };

    if (userLoaded && loadStatus === LoadStatus.idle) {
      void fetchVamap();
    }
  }, [dispatch, farmUuid, fieldUuid, uuid, userLoaded, loadStatus]);

  return { vamap };
}
