import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import BatchOperationWorkflow from './helpers/constants/workflows';
import type {
  AppDispatch,
  AppGetState,
} from '../../../app/store/helpers/types';
import { fetchFarmsFieldsDatasetsAnalyses } from '../../assets/assetsSlice';
import { selectSelectedFields } from './batchAnalyticsSelectors';

export interface BatchAnalyticsState {
  stepIndex: number;
  workflow: BatchOperationWorkflow;
  selectedFields: Record<
    string /* fieldUuid */,
    {
      farmUuid: string;
      area: number;
    }
  >;
}

const initialState: BatchAnalyticsState = {
  stepIndex: 0,
  workflow: BatchOperationWorkflow.equationBased,
  selectedFields: {},
};

export const goToWorkflowStep =
  () => (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const selectedFields = selectSelectedFields(state);
    const { farmUuids, fieldUuids } = Object.keys(selectedFields).reduce<{
      farmUuids: string[];
      fieldUuids: string[];
    }>(
      (acc, fieldUuid) => {
        acc.fieldUuids.push(fieldUuid);
        acc.farmUuids.push(selectedFields[fieldUuid].farmUuid);

        return acc;
      },
      {
        farmUuids: [],
        fieldUuids: [],
      },
    );

    dispatch(goToNextStep());
    void dispatch(
      fetchFarmsFieldsDatasetsAnalyses({
        farmUuids: [...new Set(farmUuids)],
        fieldUuids,
      }),
    );
  };

const batchAnalyticsSlice = createSlice({
  name: 'batchAnalytics',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    toggleFieldSelection(
      state,
      action: PayloadAction<{
        area: number;
        farmUuid: string;
        fieldUuid: string;
      }>,
    ) {
      if (state.selectedFields[action.payload.fieldUuid]) {
        const { [action.payload.fieldUuid]: fieldUuid, ...selectedFields } =
          state.selectedFields;

        state.selectedFields = selectedFields;
      } else {
        state.selectedFields = {
          ...state.selectedFields,
          [action.payload.fieldUuid]: {
            farmUuid: action.payload.farmUuid,
            area: action.payload.area,
          },
        };
      }
    },
    unselectField(state, action: PayloadAction<string>) {
      const { [action.payload]: fieldUuid, ...selectedFields } =
        state.selectedFields;

      state.selectedFields = selectedFields;
    },
    setFieldsSelection(
      state,
      action: PayloadAction<{
        fields: {
          area: number;
          farmUuid: string;
          fieldUuid: string;
        }[];
        selected: boolean;
      }>,
    ) {
      state.selectedFields = action.payload.fields.reduce(
        (selectedFields, field) => {
          if (action.payload.selected) {
            return {
              ...selectedFields,
              [field.fieldUuid]: {
                farmUuid: field.farmUuid,
                area: field.area,
              },
            };
          }

          const { [field.fieldUuid]: removed, ...restFields } = selectedFields;

          return restFields;
        },
        state.selectedFields,
      );
    },
    changeWorkflow(state, action: PayloadAction<BatchOperationWorkflow>) {
      state.workflow = action.payload;
    },
    goToStep(state, action: PayloadAction<number>) {
      state.stepIndex = action.payload;
    },
    goToNextStep(state) {
      state.stepIndex += 1;
    },
    goToPrevStep(state) {
      state.stepIndex -= 1;
    },
  },
});

export const {
  reset,
  toggleFieldSelection,
  unselectField,
  setFieldsSelection,
  changeWorkflow,
  goToStep,
  goToNextStep,
  goToPrevStep,
} = batchAnalyticsSlice.actions;

export default batchAnalyticsSlice.reducer;
