import React, { ChangeEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';

import TextField from '../../../../../../../components/TextField';
import Slider from '../../../../../../../components/Slider';
import Select from '../../../../../../../components/Select';
import ButtonGroup from '../../../../../../../components/ButtonGroup';
import { Product } from '../../../../types/product';
import {
  PRODUCT_UNIT_TO_I18N_LABEL,
  ProductUnit,
} from '../../../../../../../helpers/constants/units/productUnit';
import {
  selectProductByIndex,
  selectZones,
  selectRatesPerProduct,
  selectVamapType,
} from '../../../../zonesMapSelectors';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../app/store/helpers/functions';
import {
  convertProductUnitToAbsoluteUnit,
  getProductUnitOptions,
} from '../../../../../../../helpers/functions/units/productUnit';
import { ABSOLUTE_UNIT_TO_I18N_LABEL } from '../../../../../../../helpers/constants/units/absoluteUnit';
import { selectAreaUnit } from '../../../../../../user/userSelectors';
import { AREA_UNIT_TO_CURRENCY_MAP } from '../../../../../../user/helpers/constants/user';
import { getPurposeOptions } from '../../../../../../../helpers/functions/entities/vectorAnalysisMap';
import { VectorAnalysisMapType } from '../../../../../../../helpers/constants/entities/vectorAnalysisMap';
import {
  updateProduct,
  updateProductRates,
  setVamapType,
} from '../../../../zonesMapSlice';
import { calculateDistribution } from '../../../../helpers/functions/distribution';
import { getView } from '../../../../helpers/functions/viewType';

import './index.scss';
import useVamap from '../../../../../../field/hooks/useVamap';

const MAX_PRODUCT_NAME_LENGTH = 10;

export default function ProductSettings() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { farmUuid, fieldUuid, uuid } = useParams<{
    farmUuid: string;
    fieldUuid: string;
    uuid: string;
  }>();
  const view = getView(location.search);
  const activeProductIndex = view.productIndex || 0;

  const areaUnit = useAppSelector(selectAreaUnit);
  const product = useAppSelector((state) =>
    selectProductByIndex(state, activeProductIndex),
  );
  const zones = useAppSelector(selectZones);
  const ratesPerProduct = useAppSelector(selectRatesPerProduct);
  const vamapType = useAppSelector(selectVamapType);
  const { vamap } = useVamap(farmUuid, fieldUuid, uuid);
  const selectedVamapType =
    vamapType || vamap?.type || VectorAnalysisMapType.general;

  const handleProductNameChange = (value: string) => {
    if (value.length > MAX_PRODUCT_NAME_LENGTH) {
      return;
    }

    handleProductChange('name', value);
  };

  const handleVamapTypeChange = (value: VectorAnalysisMapType) => {
    dispatch(setVamapType(value));
  };

  const handleProductChange = (
    property: keyof Product,
    value: string | number | ProductUnit | null,
  ) => {
    if (!product) {
      return;
    }

    const updatedProduct = {
      ...product,
      [property]: Number.isNaN(value) ? '' : value,
    };

    dispatch(
      updateProduct({
        productIndex: activeProductIndex,
        product: updatedProduct,
      }),
    );

    if (updatedProduct && zones) {
      const newRates = calculateDistribution(
        zones,
        updatedProduct,
        ratesPerProduct,
        activeProductIndex,
      );

      dispatch(
        updateProductRates({
          productIndex: activeProductIndex,
          rates: newRates,
        }),
      );
    }
  };

  if (!product) {
    return null;
  }

  // @ts-expect-error
  const currency = AREA_UNIT_TO_CURRENCY_MAP[areaUnit];
  const productUnitLabel = t(PRODUCT_UNIT_TO_I18N_LABEL[product.unit]);
  const absoluteUnitLabel = t(
    ABSOLUTE_UNIT_TO_I18N_LABEL[convertProductUnitToAbsoluteUnit(product.unit)],
  );

  return (
    <Paper className="product-settings">
      <div className="product-settings__row">
        <TextField
          title={t('zones-map.rates.product.title')}
          variant="small"
          value={product.name}
          tooltip={t('zones-map.rates.product.description', {
            maxLength: MAX_PRODUCT_NAME_LENGTH,
          })}
          onChange={(e: ChangeEvent<{ value: string }>) =>
            handleProductNameChange(e.target.value)
          }
        />

        <Select
          id="product-type"
          label={t('general.controls.purpose')}
          value={selectedVamapType}
          tooltip={t('zones-map.rates.purpose-description')}
          options={getPurposeOptions()}
          onChange={(value) =>
            handleVamapTypeChange(value as VectorAnalysisMapType)
          }
        />

        <Select
          id="product-unit"
          label={t('zones-map.rates.product-unit')}
          value={product.unit}
          tooltip={t('zones-map.rates.product-unit-description')}
          options={getProductUnitOptions(false)}
          onChange={(value) =>
            handleProductChange('unit', value as ProductUnit)
          }
        />
      </div>

      <ButtonGroup
        label={t('zones-map.rates.distribution-method')}
        value={product.distributionMethod}
        options={[
          {
            value: 'total',
            label: t('zones-map.rates.total-amount'),
          },
          {
            value: 'average',
            label: t('zones-map.rates.average-value'),
          },
        ]}
        onChange={(value) => handleProductChange('distributionMethod', value)}
      />

      <div className="product-settings__row">
        {product.distributionMethod === 'total' ? (
          <TextField
            title={t('zones-map.rates.total-amount')}
            required
            type="number"
            variant="small"
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: (
                <span className="text_secondary">{absoluteUnitLabel}</span>
              ),
            }}
            value={product.totalAmount}
            onChange={(e: ChangeEvent<{ value: string }>) =>
              handleProductChange('totalAmount', parseFloat(e.target.value))
            }
          />
        ) : (
          <TextField
            title={t('zones-map.rates.average-rate')}
            required
            type="number"
            variant="small"
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: (
                <span className="text_secondary">{productUnitLabel}</span>
              ),
            }}
            value={product.averageRate}
            onChange={(e: ChangeEvent<{ value: string }>) =>
              handleProductChange('averageRate', parseFloat(e.target.value))
            }
          />
        )}
      </div>

      <div className="product-settings__row">
        <TextField
          title={t('zones-map.rates.min-value.title')}
          type="number"
          variant="small"
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: (
              <span className="text_secondary">{productUnitLabel}</span>
            ),
          }}
          value={product.minValue}
          tooltip={
            <Trans i18nKey="zones-map.rates.min-value.description"></Trans>
          }
          onChange={(e: ChangeEvent<{ value: string }>) =>
            handleProductChange('minValue', parseFloat(e.target.value))
          }
        />

        <TextField
          title={t('zones-map.rates.max-value.title')}
          type="number"
          variant="small"
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: (
              <span className="text_secondary">{productUnitLabel}</span>
            ),
          }}
          value={product.maxValue}
          tooltip={
            <Trans i18nKey="zones-map.rates.max-value.description"></Trans>
          }
          onChange={(e: ChangeEvent<{ value: string }>) =>
            handleProductChange('maxValue', parseFloat(e.target.value))
          }
        />

        <TextField
          type="number"
          title={t('zones-map.rates.threshold.title')}
          value={product.threshold}
          tooltip={t('zones-map.rates.threshold.description')}
          variant="small"
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: (
              <span className="text_secondary">{productUnitLabel}</span>
            ),
          }}
          onChange={(e: ChangeEvent<{ value: string }>) =>
            handleProductChange('threshold', parseFloat(e.target.value))
          }
        />
      </div>

      <div className="product-settings__row">
        <TextField
          type="number"
          title={t('zones-map.rates.product-price')}
          value={product.price}
          variant="small"
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: (
              <span className="text_secondary">{currency.sign}</span>
            ),
          }}
          onChange={(e: ChangeEvent<{ value: string }>) =>
            handleProductChange('price', parseFloat(e.target.value))
          }
        />

        <TextField
          type="number"
          title={t('zones-map.rates.custom-rate')}
          value={product.customRate}
          variant="small"
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: (
              <span className="text_secondary">{productUnitLabel}</span>
            ),
          }}
          onChange={(e: ChangeEvent<{ value: string }>) =>
            handleProductChange('customRate', parseFloat(e.target.value))
          }
        />
      </div>

      <Slider
        title={t('zones-map.rates.zone-difference.title')}
        helperText={{
          type: 'info',
          text: t('zones-map.rates.zone-difference.description', {
            value: product.zoneDifference,
          }),
        }}
        value={product.zoneDifference}
        min={0}
        max={50}
        onChangeCommitted={(value) =>
          handleProductChange('zoneDifference', value as number)
        }
      />

      <ButtonGroup
        label={t('zones-map.rates.relationship.title')}
        value={product.relationshipType}
        options={[
          {
            value: 'normal',
            label: t('zones-map.rates.relationship.normal'),
          },
          {
            value: 'reverse',
            label: t('zones-map.rates.relationship.reverse'),
          },
        ]}
        helperText={{
          type: 'info',
          text:
            product.relationshipType === 'normal'
              ? t('zones-map.rates.relationship.normal-description')
              : t('zones-map.rates.relationship.reverse-description'),
        }}
        onChange={(value) => handleProductChange('relationshipType', value)}
      />
    </Paper>
  );
}
