import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Select, { SelectOption } from '../../../../../../../components/Select';
import { AssetType } from '../../../../../../../helpers/constants/entities/asset';
import CreateAnalysisDataLayerTypeSelector from '../CreateAnalysisDataLayerTypeSelector';

import './index.scss';

interface BaseProps {
  selectedIndex: string;
}

interface WithIndexControlProps extends BaseProps {
  indexes: SelectOption<string>[];
  onIndexChange: (index: string | null) => void;
}

interface WithDataLayerTypeProps extends BaseProps {
  selectedDataLayerType: AssetType;
  dataLayersOptions: { value: AssetType; title: string }[];
  onDataLayerTypeChange: (value: AssetType | null) => void;
}

export default function CreateAnalysisSatelliteImagesPanel(
  props: BaseProps | WithIndexControlProps | WithDataLayerTypeProps,
) {
  const { t } = useTranslation();

  const withIndexControl = 'indexes' in props;
  const withDataLayerTypeControl = 'dataLayersOptions' in props;

  return (
    <div className="create-analysis-satellite-images-panel">
      {withDataLayerTypeControl && (
        <CreateAnalysisDataLayerTypeSelector
          selectedType={props.selectedDataLayerType}
          dataLayersOptions={props.dataLayersOptions}
          onDataLayerTypeChange={props.onDataLayerTypeChange}
        />
      )}
      {withIndexControl && (
        <Select
          id="satellite-index-selector"
          required
          labelPosition="left"
          classes={{
            root: 'create-analysis-satellite-images-panel__select',
            selectInput: clsx({
              'create-analysis-satellite-images-panel__strict-input-width':
                withDataLayerTypeControl,
            }),
          }}
          label={t('zones-ops.multi-layer.steps.3.satellite-index')}
          value={props.selectedIndex}
          options={props.indexes}
          onChange={props.onIndexChange}
        />
      )}
    </div>
  );
}
