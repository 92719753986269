import React from 'react';

import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../app/store/helpers/functions';
import getSmartSamplingTableRows from '../../helpers/functions/getSmartSamplingTableRows';
import { generatePinsGroups } from '../../smartSamplingSlice';
import { selectFieldsStatusesEntities } from '../../smartSamplingSelectors';
import { selectAreaUnit, selectApiKey } from '../../../../user/userSelectors';
import { useGetFarmsQuery } from '../../../../farms/farmsAPI';
import FieldProfilerTableComponent from '../../../fieldsList/components/Table';
import { DefaultFieldTableRow } from '../../../fieldsList/types/tableRow';
import getTableColumns from '../../helpers/functions/getTableColumns';
import useInfiniteFieldsList from '../../../../fields/hooks/useInfiniteFieldsList';
import useFieldsListParams from '../../../fieldsList/hooks/useFieldsListParams';

export default function SmartSamplingTable() {
  const dispatch = useAppDispatch();
  const fieldsStatuses = useAppSelector(selectFieldsStatusesEntities);
  const apiKey = useAppSelector(selectApiKey);
  const areaUnit = useAppSelector(selectAreaUnit);
  const fieldsListParams = useFieldsListParams();

  const { data: farms, isFetching: farmsFetching } = useGetFarmsQuery();

  const { fields, fieldsLoading, fieldsLoadComplete, triggerNextPage } =
    useInfiniteFieldsList({
      farmUuid: fieldsListParams?.farmUuid,
      fieldName: fieldsListParams?.fieldName,
      labels: fieldsListParams?.labels,
      skip: !fieldsListParams,
    });

  const handleLoadMoreRows = () => {
    triggerNextPage();
  };

  const onRowStartButtonClick = (row: DefaultFieldTableRow) => {
    void dispatch(
      // @ts-expect-error
      generatePinsGroups({
        fieldUuid: row.id,
      }),
    );
  };

  const rows = getSmartSamplingTableRows({
    fieldsStatuses,
    fields,
    farms: farms || [],
    apiKey,
  });
  const columns = getTableColumns({
    areaUnit,
    onRowStartButtonClick,
  });

  return (
    <FieldProfilerTableComponent
      rows={rows}
      columns={columns}
      loadComplete={fieldsLoadComplete}
      loading={fieldsLoading || farmsFetching}
      labelsExpanded={fieldsListParams?.labelsExpanded}
      onLoadMoreRows={handleLoadMoreRows}
    />
  );
}
