import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from '../../../../../../../../components/Button';
import {
  selectFieldUuid,
  selectYieldDataset,
} from '../../../../../../../field/fieldSelectors';
import { useCleanCalibrateYieldDatasetMutation } from '../../../../../cleanCalibrateAPI';
import {
  selectDatasetUuid,
  selectStep,
} from '../../../../../cleanCalibrateSelectors';
import { setStep } from '../../../../../cleanCalibrateSlice';
import { useAppSelector } from '../../../../../../../../app/store/helpers/functions';
import useCleanParams from '../../../../../hooks/useCleanParams';

export default function CleanTabRightActions({
  onCancel,
}: {
  onCancel: () => void;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [cleanCalibrateYieldDataset] = useCleanCalibrateYieldDatasetMutation();
  const step = useAppSelector(selectStep);
  const fieldUuid = useAppSelector(selectFieldUuid);
  const datasetUuid = useAppSelector(selectDatasetUuid);
  const dataset = useAppSelector((state) =>
    selectYieldDataset(state, datasetUuid),
  );
  const params = useCleanParams();

  const handleRun = async () => {
    if (!params || !fieldUuid || !dataset?.uuid) {
      return;
    }

    await cleanCalibrateYieldDataset({
      fieldUuid,
      yieldDatasetUuid: dataset.uuid,
      actions: {
        actions: ['CLEAN'],
        cleanAction: params,
      },
    });

    onCancel();
  };

  const handleProceed = () => {
    dispatch(setStep(step + 1));
  };

  const handleBack = () => {
    dispatch(setStep(step - 1));
  };

  return (
    <>
      {step === 0 ? (
        <>
          <Button variant="outlined" color="primary" onClick={onCancel}>
            {t('general.controls.cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={handleProceed}>
            {t('general.controls.proceed')}
          </Button>
        </>
      ) : (
        <>
          <Button variant="outlined" color="primary" onClick={handleBack}>
            {t('general.stepper.back')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!params}
            onClick={handleRun}
          >
            {t('general.controls.run')}
          </Button>
        </>
      )}
    </>
  );
}
