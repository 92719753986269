import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import PopupHeader from '../../../../../components/Popups/PopupHeader';
import BackdropLoading from '../../../../../components/Loading/BackdropLoading';
import Button from '../../../../../components/Button';
import OrganizationSettingsForm from '../../../../../components/Forms/OrganizationSettings';
import { saveOrganization } from '../../../../user/userSlice';

import './index.scss';

const OrganizationSettingsPopup = ({
  form = {
    name: '',
    surname: '',
    givenName: '',
    phone: '',
    email: '',
  },
  uuid,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const [updatedForm, setUpdatedForm] = useState(null);
  const [saveDisabled, setSaveDisabled] = useState(true);

  const getTitle = () =>
    uuid
      ? t('general.popups.organization-settings.title-update')
      : t('general.popups.organization-settings.title-create');

  const handleFormUpdate = ({ updatedForm: newForm, valid, changed }) => {
    const disabled = !valid || !changed;

    if (!disabled) {
      setUpdatedForm(newForm);
    }
    setSaveDisabled(disabled);
  };

  const handleSaveClick = () => {
    setProcessing(true);
    dispatch(
      saveOrganization({
        ...updatedForm,
        uuid,
      }),
    ).then(onConfirm);
  };

  return (
    <Dialog
      open
      classes={{
        paper: 'organization-settings-popup',
      }}
    >
      <PopupHeader title={getTitle()} onCancel={onCancel} />
      <DialogContent>
        <OrganizationSettingsForm form={form} onFormUpdate={handleFormUpdate} />
      </DialogContent>
      <DialogActions
        classes={{
          root: 'organization-settings-popup__actions',
        }}
      >
        <Button variant="outlined" onClick={onCancel}>
          {t('general.stepper.back')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={saveDisabled}
          onClick={handleSaveClick}
        >
          {t('general.controls.save')}
        </Button>
      </DialogActions>
      {processing && <BackdropLoading />}
    </Dialog>
  );
};

export default OrganizationSettingsPopup;
