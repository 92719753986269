import React, { ReactNode, KeyboardEvent } from 'react';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';

import { isEnterKeyPressedOnCurrentTarget } from '../../../../../../helpers/functions/utils/navigation';
import { getTreeViewItemOffset } from '../../../helpers/functions/tree';

import './index.scss';

export default function SimpleItem({
  children,
  offset,
  isParent,
  isExpanded,
  isSelected,
  disabled,
  classes,
  onClick,
}: {
  children: ReactNode;
  offset: number;
  isParent?: boolean;
  isExpanded?: boolean;
  isSelected?: boolean;
  disabled?: boolean;
  classes?: {
    wrapper?: string;
    button?: string;
    icon?: string;
  };
  onClick?: () => void;
}) {
  const handleClick = () => {
    if (disabled) {
      return;
    }

    onClick?.();
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (!disabled && isEnterKeyPressedOnCurrentTarget(event)) {
      onClick?.();
    }
  };

  const itemButtonProps =
    onClick && !disabled
      ? { onClick: handleClick, onKeyDown: handleKeyDown, tabIndex: 0 }
      : {};

  return (
    <div className={clsx('tree-simple-item__wrapper', classes?.wrapper)}>
      <div
        role="button"
        style={{ paddingLeft: getTreeViewItemOffset(offset) }}
        className={clsx('tree-simple-item', classes?.button, {
          'tree-simple-item_parent': isParent,
          'tree-simple-item_selected': isSelected,
          'tree-simple-item_disabled': disabled,
        })}
        {...itemButtonProps}
      >
        {isParent && (
          <IconButton
            className={clsx(
              'tree-simple-item_parent__expand-button',
              classes?.icon,
              {
                'tree-simple-item_parent__expand-button_expanded': isExpanded,
              },
            )}
            edge="end"
            size="small"
          >
            <ExpandMoreIcon color="primary" />
          </IconButton>
        )}
        {children}
      </div>
    </div>
  );
}
