import React, { ReactNode } from 'react';

import NoIntegartions from '../NoIntegrations';

import './index.scss';

export default function MarketplaceLayout({
  children,
}: {
  children?: ReactNode;
}) {
  return (
    <div className="marketplace-layout">
      {children == null ? (
        <NoIntegartions />
      ) : (
        <div className="marketplace-layout__grid">{children}</div>
      )}
    </div>
  );
}
