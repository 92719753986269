import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  getFilteredFieldsUuids,
  getOperationsFilters,
} from '../helpers/functions/filters';
import useOperationsFilters from './useOperationsFilters';
import { useGetPlatformContextQuery } from '../../../asyncOperations/asyncOperationsAPI';
import { getOperations } from '../helpers/functions/operations';
import { Operation } from '../types/operation';
import { OperationType } from '../helpers/constants/operation';
import { selectUuid } from '../../../user/userSelectors';
import type { Field } from '../../../field/types/field';

export default function useOperations(): {
  operations: Record<OperationType, Operation[]>;
  fieldsWithOperations: Field[];
  isLoading: boolean;
} {
  const userUuid = useSelector(selectUuid);

  const { timeRange, farmUuid, fieldUuid, dateFrom, dateTo } =
    useOperationsFilters();

  const filter = useMemo(
    () => getOperationsFilters(timeRange, dateFrom, dateTo, userUuid),
    [timeRange, dateFrom, dateTo, userUuid],
  );

  const { data, isFetching } = useGetPlatformContextQuery({ filter });

  const operations = useMemo(() => {
    const filteredFieldsUuids = getFilteredFieldsUuids(
      data?.fields,
      fieldUuid,
      farmUuid,
    );

    return getOperations({
      eventsWithFields: data,
      filteredFieldsUuids,
    });
  }, [data, fieldUuid, farmUuid]);

  return {
    operations,
    fieldsWithOperations: data?.fields || [],
    isLoading: isFetching,
  };
}
