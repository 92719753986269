import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectVectorAnalysisMaps,
  selectAssets,
} from '../../../../../../field/fieldSelectors';
import useDataLayersTree from '../../../../../dataLayersView/hooks/useDataLayersTree';
import {
  ASSET_TYPE_TO_ASSET_GROUP,
  AssetGroupType,
  AssetType,
} from '../../../../../../../helpers/constants/entities/asset';
import type { TransformedAsset } from '../../../../../../../helpers/types';
import { setClonePolygonsLayer } from '../../../../drawManuallyWorkflowSlice';
import {
  selectClonePolygonsAssetType,
  selectClonePolygonsUuid,
} from '../../../../drawManuallyWorkflowSelectors';

import './index.scss';

export default function ClonePolygonsPanel() {
  const dispatch = useDispatch();

  const uuid = useSelector(selectClonePolygonsUuid);
  const assetType = useSelector(selectClonePolygonsAssetType);
  const assetGroupType =
    ASSET_TYPE_TO_ASSET_GROUP[assetType as AssetType] || undefined;
  const vectorAnalysisMaps = useSelector(selectVectorAnalysisMaps);
  const { soilDatasets, yieldDatasets, asAppliedDatasets } =
    useSelector(selectAssets);

  const onAssetItemClick = (
    _assetGroupType: AssetGroupType,
    item: TransformedAsset,
  ) => {
    dispatch(
      setClonePolygonsLayer({
        uuid: item.uuid,
        assetType: item.assetType,
      }),
    );
  };

  const { dataLayersTreeComponent } = useDataLayersTree({
    assets: {
      vectorAnalysisMaps,
      soilDatasets,
      yieldDatasets,
      asAppliedDatasets,
    },
    collapsible: true,
    hasGroupPlaceholderAction: false,
    selectedAssetUuid: uuid,
    selectedAssetGroupType: assetGroupType,
    onAssetNodeClick: onAssetItemClick,
  });

  return <div className="clone-polygons-panel">{dataLayersTreeComponent}</div>;
}
