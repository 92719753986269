import React, { ReactElement } from 'react';

import Tooltip from '../../../../../../components/Tooltip';
import { GeoMap } from '../../../../../../helpers/types/api';
import {
  getDatasetLegendColors,
  getFullAttribute,
} from '../../../../../../helpers/functions/entities/dataset';
import { getAttributeUnitLabel } from '../../../../../../helpers/functions/units/attributeUnit';
import { getTopographyMapAttributeNameHandler } from '../../../../../../helpers/functions/entities/topographyMap';
import ColorsLegend from '../../../../../../components/Map/Controls/MiniLegend/ColorsLegend';
import { TransformedTopographyMap } from '../../../../../../helpers/types/dataset';

import './index.scss';

export default function TopographyMapLegendTooltip({
  topographyMap,
  attribute,
  geoMap,
  children,
}: {
  topographyMap: TransformedTopographyMap;
  attribute: string;
  geoMap: GeoMap;
  children: ReactElement;
}) {
  const items = getDatasetLegendColors(geoMap);
  const nameHandler = getTopographyMapAttributeNameHandler(
    topographyMap.fullAttributes,
  );
  const fullAttribute = getFullAttribute(
    attribute,
    topographyMap.fullAttributes,
  );

  return (
    <Tooltip
      classes={{
        label: 'topography-map-preview-legend-tooltip__label',
        tooltip: 'topography-map-preview-legend-tooltip__tooltip',
      }}
      placement="bottom"
      tooltip={
        <div className="topography-map-preview-legend-tooltip">
          <ColorsLegend
            direction="horizontal"
            items={items}
            name={nameHandler(attribute)}
            unit={getAttributeUnitLabel(fullAttribute?.unit)}
          />
        </div>
      }
    >
      {children}
    </Tooltip>
  );
}
