import { RootState } from '../../app/store/helpers/types';

export const selectGeneratedVectorAnalysisMap = ({
  createAnalysis,
}: RootState) => createAnalysis.generatedVectorAnalysisMap;

export const selectGeneratedEquationMap = ({ createAnalysis }: RootState) =>
  createAnalysis.generatedEquationMap;

export const selectSaveInProgress = ({ createAnalysis }: RootState) =>
  createAnalysis.saveInProgress;
