import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { subMonths } from 'date-fns';
import clsx from 'clsx';

import {
  TIME_RANGES,
  CUSTOM_TIME_RANGE_FILTER,
} from '../../helpers/constants/filters';
import { TimeRange, TimeRangeErrors } from '../../types/filters';
import CustomTimeRange from '../CustomTimeRange';
import ButtonGroup from '../../../../../components/ButtonGroup';

import './index.scss';

export default function TimeRangeFilter({
  timeRange,
  dateFrom,
  dateTo,
  monthsBackAllowed,
  label,
  inline = false,
  onTimeRangeChange,
  onCustomTimeRangeChange,
  validateCustomTimeRange,
}: {
  timeRange: string;
  dateFrom: Date | null;
  dateTo: Date | null;
  monthsBackAllowed: number;
  label: string;
  inline?: boolean;
  onTimeRangeChange: (value: string) => void;
  onCustomTimeRangeChange: (timeRange: TimeRange) => void;
  validateCustomTimeRange: (
    filterType: keyof TimeRange,
    timeRange: TimeRange,
  ) => TimeRangeErrors;
}) {
  const { t } = useTranslation();

  const isExtendedFilter = timeRange === CUSTOM_TIME_RANGE_FILTER;

  const minAllowedDate = useMemo(
    () => subMonths(new Date(), monthsBackAllowed),
    [monthsBackAllowed],
  );

  return (
    <div
      className={clsx('time-range-filter', {
        'time-range-filter_inline': inline,
      })}
    >
      <ButtonGroup
        label={label}
        value={timeRange}
        onChange={onTimeRangeChange}
        options={TIME_RANGES.map((range) => ({
          value: range,
          label: t(`operations.filters.time-range.values.${range}`),
        }))}
      />
      {isExtendedFilter && (
        <div className="time-range-filter__custom-filters">
          <span className="time-range-filter__divider">:</span>
          <CustomTimeRange
            dateFrom={dateFrom}
            dateTo={dateTo}
            minDate={minAllowedDate}
            onTimeRangeChange={onCustomTimeRangeChange}
            validate={validateCustomTimeRange}
          />
        </div>
      )}
    </div>
  );
}
