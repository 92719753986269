import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import Button from '../../../../../../../components/Button';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../app/store/helpers/functions';
import { clonePolygons } from '../../../../drawManuallyWorkflowSlice';
import type { MapUpdateEntry } from '../../../../types/ui';
import { selectClonePolygonsUuid } from '../../../../drawManuallyWorkflowSelectors';
import getAssetByUuid from '../../../../../../../helpers/functions/entities/getAssetByUuid';
import { selectField } from '../../../../../../field/fieldSelectors';
import { AssetType } from '../../../../../../../helpers/constants/entities/asset';
import { MAX_GEOMETRIES } from '../../../../helpers/constants/instruments';

import './index.scss';

export default function ClonePolygonsActions({
  onConfirm,
}: {
  onConfirm: (r: MapUpdateEntry | null) => void;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const layerUuid = useAppSelector(selectClonePolygonsUuid);
  const field = useAppSelector(selectField);
  const item = getAssetByUuid(layerUuid, field);
  const geometries = item && 'number' in item ? item?.number : 0;
  const assetInvalid =
    item?.assetType !== AssetType.vectorAnalysisMap &&
    (geometries == null || geometries > MAX_GEOMETRIES);

  const handleConfirm = async () => {
    const result = await dispatch(clonePolygons());
    onConfirm(result);
  };

  return (
    <>
      {assetInvalid && (
        <Typography className="clone-polygons-actions__error">
          {t(
            'zones-ops.draw-manually.steps.3.clone-polygons.max-geometries-error',
            {
              geometries: MAX_GEOMETRIES,
            },
          )}
        </Typography>
      )}
      <Button
        variant="contained"
        color="primary"
        disabled={assetInvalid}
        onClick={handleConfirm}
      >
        {t('general.controls.clone')}
      </Button>
    </>
  );
}
