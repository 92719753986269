import { extractFieldsMap } from '../../../../farms/helpers/functions/farms';
import { getThumbnailUrl } from '../../../../../helpers/functions/utils/url';
import type { Field } from '../../types/api';
import type { ZonesMapData } from '../../types/ui';
import type { TransformedVectorAnalysisMap } from '../../../../../helpers/types/vectorAnalysisMap';
import { getThumbnailGeoMap } from '../../../../../helpers/functions/entities/vectorAnalysisMap';

const getZonesMapsData = (
  apiKey: string,
  zonesMapsWithFields?: {
    zonesMaps: TransformedVectorAnalysisMap[];
    fields: Field[];
  },
): ZonesMapData[] => {
  if (!zonesMapsWithFields) {
    return [];
  }

  const { zonesMaps, fields } = zonesMapsWithFields;
  const fieldsByFieldUuid = extractFieldsMap([{ fields }]);

  return zonesMaps.map((zonesMap) => {
    const thumbnail = getThumbnailGeoMap(zonesMap.geoMaps);
    const field = fieldsByFieldUuid[zonesMap.fieldUuid || ''];

    return {
      uuid: zonesMap.uuid,
      name: zonesMap.name,
      fieldUuid: zonesMap.fieldUuid,
      farmUuid: field?.farmUuid,
      fieldName: field?.name,
      updatedDate: zonesMap.updatedDate,
      preview: getThumbnailUrl({ thumbnail, size: 185, apiKey }),
    };
  });
};

export default getZonesMapsData;
