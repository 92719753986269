import { FieldStatus } from '../../../field/types/field';

const isValidSynchronizedFieldJohnDeereAccount = <
  T extends { status: FieldStatus },
>(
  synchronizedField: T,
) =>
  synchronizedField &&
  (synchronizedField.status === 'VERIFIED' ||
    synchronizedField.status === 'TILES_REGISTERED' ||
    synchronizedField.status === 'GRIDS_CREATED');

export default isValidSynchronizedFieldJohnDeereAccount;
