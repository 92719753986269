mutation generateYieldVectorAnalysisMap(
  $input: GenerateYieldVectorAnalysisMapInput!
) {
  generateYieldVectorAnalysisMapAsync(input: $input) {
    uuid
    fieldUuid
    statusCode
    statusMessage
  }
}
