import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';

import PopupHeader from '../../../../../components/Popups/PopupHeader';
import SelectTypeStep from './Steps/01-SelectType';
import SelectFiltersStep from './Steps/02-SelectFilters';
import useDidMount from '../../../../../hooks/useDidMount';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../app/store/helpers/functions';
import { importAllData, importData } from '../../jdImportSlice';
import { selectHasSelectedFields } from '../../jdImportSelectors';
import { PAGES_ROOTS, getRootLink } from '../../../../../helpers/navigation';
import { FilterParam } from '../../../operationsWorkflow/helpers/constants/filters';
import { selectFieldsFilterFieldName } from '../../../../jdFields/jdFieldsSelectors';
import useGetFieldsOperations from '../../hooks/useGetFieldsOperations';

import './index.scss';

enum Steps {
  selectType = 'select-type',
  selectFilters = 'select-filters',
}

export default function ImportSettingsPopup({
  onConfirm,
  onCancel,
}: {
  onConfirm: () => void;
  onCancel: () => void;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const hasSelectedFields = useAppSelector(selectHasSelectedFields);
  const filterFieldName = useAppSelector(selectFieldsFilterFieldName);
  const { getFieldsOperations } = useGetFieldsOperations();
  const [step, setStep] = useState(Steps.selectType);

  const goToSelectTypeStep = () => {
    setStep(Steps.selectType);
  };

  const goToSelectFiltersStep = () => {
    setStep(Steps.selectFilters);
  };

  const handleConfirm = async () => {
    const opsPageLink = `${getRootLink(PAGES_ROOTS.operations)}?${FilterParam.timeRange}=12`;

    onConfirm();

    if (hasSelectedFields) {
      await dispatch(importData()).unwrap();
      history.push(opsPageLink);
    } else {
      // @ts-expect-error
      await dispatch(importAllData({ fieldName: filterFieldName })).unwrap();
      history.push(opsPageLink);
    }
  };

  useDidMount(() => {
    void getFieldsOperations();
  });

  let content = (
    <SelectTypeStep
      onCancel={onCancel}
      onNext={goToSelectFiltersStep}
      onConfirm={handleConfirm}
    />
  );

  if (step === Steps.selectFilters) {
    content = (
      <SelectFiltersStep
        onBack={goToSelectTypeStep}
        onConfirm={handleConfirm}
      />
    );
  }

  return (
    <Dialog open className="jd-import-settings-popup">
      <PopupHeader
        title={t('upload-data-john-deere.import-settings.title')}
        onCancel={onCancel}
      />
      {content}
    </Dialog>
  );
}
