import React from 'react';

import './index.scss';

const ImageListItem = ({ url, onItemClick = () => {} }) => (
  <div role="listitem">
    <button
      className="image-list-item"
      style={{
        backgroundImage: `url(${url})`,
      }}
      onClick={onItemClick}
    />
  </div>
);

export default ImageListItem;
