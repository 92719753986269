import React from 'react';
import { useTranslation } from 'react-i18next';

import ZonesMapTable from '../../Controls/ZonesMapTable';
import Overview from '../../Controls/Overview';
import LabelsList from '../../Controls/LabelsList';
import RatesTable from '../../Controls/RatesTable';

const DatasetZonesMapLegendGroup = ({
  TableComponent = ZonesMapTable,
  zonesMapTable,
  ratesTable,
  overview,
  dataSource,
  labels,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <TableComponent {...zonesMapTable} />
      {ratesTable && <RatesTable {...ratesTable} />}
      <Overview {...overview} />
      <Overview
        noData={
          <span className="text_disabled">
            {t('general.controls.data-removed')}
          </span>
        }
        {...dataSource}
      />
      <LabelsList {...labels} />
    </>
  );
};

export default DatasetZonesMapLegendGroup;
