import React, { useCallback } from 'react';
import clsx from 'clsx';

import formatCommentDate from '../../helpers/functions/formatCommentDate';
import ImageList from '../ImageList';

import './index.scss';

const PinCommentDisplay = ({ comment = {}, onPhotoClick = () => {} }) => {
  const handleImageClick = useCallback(
    (currentPhotoIndex) => {
      onPhotoClick(currentPhotoIndex, comment.photos);
    },
    [comment, onPhotoClick],
  );

  return (
    <div className="pin-comment-display">
      <div className="pin-comment-display__date">
        {formatCommentDate(comment.createdDate)}
      </div>
      <div className="pin-comment-display__content">
        <div className="pin-comment-display__text">{comment.text}</div>
        {Array.isArray(comment.photos) && comment.photos.length > 0 && (
          <ImageList
            images={comment.photos}
            className={clsx({
              'pin-comment-display__image-list': !!comment.text,
            })}
            onImageClick={handleImageClick}
          />
        )}
      </div>
    </div>
  );
};

export default PinCommentDisplay;
