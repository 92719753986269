import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

import AntSwitch from '../../../../../components/AntSwitch';
import ComboBox from '../../../../../components/ComboBox';
import type { FullAttributeItem } from '../../types/ui';
import { getComboBoxOptionSelected } from '../../helpers/functions/ui';

import './index.scss';

export default function CalibrationBasis({
  syntheticUsed,
  syntheticUsedDisabled,
  options,
  basisValue,
  basisDisabled,
  label,
  onBasisChange,
  onSyntheticUsedChange,
}: {
  label: string;
  syntheticUsed: boolean;
  syntheticUsedDisabled: boolean;
  options: FullAttributeItem[];
  basisValue: FullAttributeItem | undefined;
  basisDisabled: boolean;
  onBasisChange: (i: FullAttributeItem) => void;
  onSyntheticUsedChange: (v: boolean) => void;
}) {
  const { t } = useTranslation();

  return (
    <>
      <div className="calibration-basis__header">
        <Typography className="calibration-basis__header-title">
          {label}
          <span className="required-asterisk">*</span>
        </Typography>
        <AntSwitch
          isChecked={syntheticUsed}
          disabled={syntheticUsedDisabled}
          label={`${t('clean-calibrate.yield-popup.tabs.calibrate.configure.use-simulated')}:`}
          labelPlacement="start"
          onValueChange={onSyntheticUsedChange}
        />
      </div>
      <ComboBox
        placeholder={t('general.controls.select')}
        options={options}
        value={basisValue || (null as unknown as FullAttributeItem)}
        getOptionSelected={getComboBoxOptionSelected}
        disableCloseOnSelect={false}
        disabled={basisDisabled}
        disableClearable
        required
        onChange={(_e, item) => onBasisChange(item)}
      />
    </>
  );
}
