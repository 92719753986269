#import "../fragments/soilDataset.gql"

query getSoilDatasets(
  $farmUuids: [ID]
  $fieldUuids: [ID]
  $datasetUuids: [ID]
  $areaUnit: AreaUnit
) {
  getFarms(farmUuids: $farmUuids) {
    uuid
    fields(fieldUuids: $fieldUuids) {
      uuid
      soilDatasets(soilDatasetUuids: $datasetUuids) {
        ...soilDatasetFields
      }
    }
  }
}
