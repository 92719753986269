export const enrichFieldAssets = (field) => {
  const { vectorAnalysisMaps, pinsGroups, ...restField } = field;

  if (vectorAnalysisMaps) {
    restField.vectorAnalysisMaps = vectorAnalysisMaps.map((vamap) => ({
      ...vamap,
      farmUuid: field.farmUuid,
    }));
  }

  if (pinsGroups) {
    restField.pinsGroups = pinsGroups.map((pinsGroup) => ({
      ...pinsGroup,
      farmUuid: field.farmUuid,
    }));
  }

  return restField;
};

export default { enrichFieldAssets };
