import type { TransformedEquationMap } from '../../../../../helpers/types/equationMap';

export const calculateWeightedSum = (
  equationMaps: TransformedEquationMap[],
): number | null => {
  let weightedSum: number | null = null;
  const hasSum = equationMaps.some(
    (equationMap) => equationMap.statistics?.sum != null,
  );

  if (hasSum) {
    const sumAreaSum = equationMaps.reduce((acc, equationMap) => {
      let result = 0;

      if (equationMap.statistics?.sum != null && equationMap.area != null) {
        result = equationMap.statistics.sum * equationMap.area;
      }

      return acc + result;
    }, 0);

    const totalArea = equationMaps.reduce(
      (acc, equationMap) => acc + (equationMap.area || 0),
      0,
    );

    weightedSum = sumAreaSum / totalArea;
  }

  return weightedSum;
};

export const calculateWeightedAvg = (
  equationMaps: TransformedEquationMap[],
): number | null => {
  let weightedAvg: number | null = null;
  const hasAvg = equationMaps.some(
    (equationMap) => equationMap.statistics?.avg != null,
  );

  if (hasAvg) {
    const avgAreaSum = equationMaps.reduce((acc, equationMap) => {
      let result = 0;

      if (equationMap.statistics?.avg != null && equationMap.area != null) {
        result = equationMap.statistics.avg * equationMap.area;
      }

      return acc + result;
    }, 0);

    const totalArea = equationMaps.reduce(
      (acc, equationMap) => acc + (equationMap.area || 0),
      0,
    );

    weightedAvg = avgAreaSum / totalArea;
  }

  return weightedAvg;
};
