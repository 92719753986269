query getProfileJohnDeere {
  getProfileJohnDeere {
    acceptUrl
    updateAcceptUrl
    hasRefreshToken
    accountName
    hasAutoImportAllFieldOperations
    hasAutoUpdateFieldBoundaries
    hasAutoUpdateFieldStructure
    hasAutoCreateZonesMapLayers
    hasAutoCreateSoilMapLayers
    hasAutoCreateYieldMapLayers
    hasAutoCreateTopographyMapLayers
    hasAutoCreateEquationMapLayers
    flushDateRefreshToken
    flushUserRefreshToken
    providerHealthCheck
  }
}
