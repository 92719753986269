import { comparator } from '../utils/string';

export const getValueOptionsArray = (valueOptions, titleProcessor) =>
  valueOptions.map((valueOption) => ({
    title: titleProcessor(valueOption),
    value: valueOption,
  }));

export const getSortedValueOptionsArray = (valueOptions, titleProcessor) =>
  getValueOptionsArray(valueOptions, titleProcessor).sort((a, b) =>
    comparator(a.title, b.title),
  );
