import { featureCollection } from '@turf/turf';

import { AssetType } from '../../../../../helpers/constants/entities/asset';
import { sortZoneNumbers } from '../../../../../helpers/functions/entities/geojson';

export const mapUuidsToZoneNumbers = (zonesOperationMaps) =>
  zonesOperationMaps.reduce((acc, { map, zoneNumbers }) => {
    acc[map.uuid] = zoneNumbers;

    return acc;
  }, {});

export const prepareMultiLayerDataLayers = (dataLayers) =>
  dataLayers
    .map((dataLayer) => {
      let result = {
        weight: dataLayer.factor,
      };

      if (dataLayer.satelliteImages) {
        const satelliteImageUuids = dataLayer.satelliteImages.map(
          ({ uuid }) => uuid,
        );

        result = {
          ...result,
          type: AssetType.satelliteImage,
          index: dataLayer.index,
          satelliteImageUuids,
        };
      } else if (dataLayer.soilAttribute) {
        result = {
          ...result,
          type: AssetType.soilDataset,
          attribute: dataLayer.soilAttribute,
          datasetUuid: dataLayer.soilDataset.uuid,
        };
      } else if (dataLayer.yieldAttribute) {
        result = {
          ...result,
          type: AssetType.yieldDataset,
          attribute: dataLayer.yieldAttribute,
          datasetUuid: dataLayer.yieldDataset.uuid,
        };
      } else if (dataLayer.asAppliedAttribute) {
        result = {
          ...result,
          type: AssetType.asAppliedDataset,
          attribute: dataLayer.asAppliedAttribute,
          datasetUuid: dataLayer.asAppliedDataset.uuid,
        };
      } else if (dataLayer.topographyAttribute) {
        result = {
          ...result,
          type: AssetType.topographyMap,
          attribute: dataLayer.topographyAttribute,
          datasetUuid: dataLayer.topographyMap.uuid,
        };
      } else {
        result = null;
      }

      return result;
    })
    .filter(Boolean);

export const prepareDrawManuallyFeature = ({ features }) => {
  const preparedFeatures = features.map(({ type, geometry, properties }) => ({
    type,
    geometry,
    properties: {
      zone: properties.zone,
      attributes: {
        ...properties.attributes,
        rates: [],
        ratesHeaders: [],
        ratesPrices: [],
        ratesUnits: [],
      },
    },
  }));

  return sortZoneNumbers(featureCollection(preparedFeatures));
};
