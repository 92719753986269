import React from 'react';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import { Chip } from '@material-ui/core';

import './index.scss';

export default function Label({
  text = '',
  title,
  color = 'default',
  disabled = false,
  classes,
  className,
  onDelete,
  ...props
}: {
  text: string;
  title?: string;
  classes?: {
    label: string;
  };
  className?: string;
  color?: 'default' | 'primary';
  disabled?: boolean;
  onDelete?: () => void;
}) {
  return (
    <Chip
      size="small"
      className={clsx('label', classes?.label, className, {
        label_primary: color === 'primary',
        label_disabled: disabled,
      })}
      variant="outlined"
      disabled={disabled}
      label={text}
      title={title ?? text}
      {...(onDelete
        ? {
            onDelete,
            deleteIcon: <CloseIcon className="label__icon" tabIndex={0} />,
          }
        : null)}
      {...props}
    />
  );
}
