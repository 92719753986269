import {
  createSlice,
  createAsyncThunk,
  nanoid,
  PayloadAction,
  Dispatch,
} from '@reduxjs/toolkit';
import i18n from 'i18next';

import { FIELD_STATUS_MESSAGES_TO_I18N_KEYS_MAP } from '../../field/helpers/constants/field';
import { readFileAsArrayBuffer } from '../../../helpers/functions/utils/uploadFiles';
import {
  successNotify,
  warningNotify,
} from '../../notifications/helpers/functions/notify';
import { openUploadFiles } from '../popups/popupsSlice';
import {
  FileStatus,
  UploadFileExtension,
  UploadStep,
  UploadType,
} from './helpers/constants/upload';
import {
  getFileUploadStatus,
  getUploadStep,
  zipFieldBoundaries,
} from './helpers/functions/upload';
import {
  getAssetStatus,
  SupportedUploadType,
  uploadFile,
} from './uploadDataAPI';
import {
  selectFarm,
  selectFiles,
  selectProcessingPanelFiles,
  selectProcessingPanelStep,
} from './uploadDataSelectors';
import { AppThunk, RootState } from '../../../app/store/helpers/types';
import InMemoryStorageService from './helpers/services/InMemoryStorageService';
import { BATCH_SIZE } from '../../../helpers/constants/utils/batchOperations';
import { batchOperations } from '../../../helpers/functions/utils/batchOperations';
import { ParsedEvent } from '../../subscription/types/event';
import PlatformEventAction from '../../subscription/helpers/constants/action';
import {
  captureException,
  CustomError,
} from '../../../helpers/functions/utils/errorHandling';
import { StagedFile, UploadingFile } from './types/file';

const FileStorageService = new InMemoryStorageService<File>();

export interface UploadDataState {
  isMachineryFilesUploading: boolean;
  tab: UploadType;
  farm?: {
    uuid: string;
    name: string;
  };
  files: { [k in UploadType]: StagedFile[] };
  processingPanel: {
    step: UploadStep;
    files: UploadingFile[];
  };
}

const initialState: UploadDataState = {
  isMachineryFilesUploading: false,
  tab: UploadType.fieldBoundaries,
  farm: undefined,
  files: {
    [UploadType.allTypes]: [],
    [UploadType.fieldBoundaries]: [],
    [UploadType.soilData]: [],
    [UploadType.yieldData]: [],
    [UploadType.asApplied]: [],
    [UploadType.machineryFormats]: [],
  },
  processingPanel: {
    step: UploadStep.uploading,
    files: [],
  },
};

interface PreparedFile {
  id: string;
  fileStorageIds: string[];
  name: string;
  type: UploadType;
  farmUuid?: string;
  arraybuffer: ArrayBuffer;
  fileExtension?: UploadFileExtension;
}

const getProcessFileUploadFn =
  (dispatch: Dispatch) =>
  async ({
    id,
    name,
    type,
    farmUuid,
    arraybuffer,
    fileExtension,
    fileStorageIds,
  }: PreparedFile) => {
    try {
      const uuid = await uploadFile({
        type: type as SupportedUploadType,
        farmUuid,
        arraybuffer,
        fileExtension,
      });

      dispatch(
        setupUploadedFile({
          id,
          uuid,
        }),
      );
    } catch (error) {
      captureException({
        error: new CustomError(`[UploadData] Unable to upload asset ${name}:`, {
          cause: error,
        }),
      });

      dispatch(setStepProcessingPanel(UploadStep.failed));
      dispatch(
        markFailedFile({
          id,
          status: FileStatus.failed,
        }),
      );
    } finally {
      fileStorageIds.forEach((fileStorageId) =>
        FileStorageService.removeItem(fileStorageId),
      );
    }
  };

const processFieldFiles = async (
  files: StagedFile[],
  farmUuid: string,
): Promise<PreparedFile[]> => {
  const { filesToZip, zipFiles, filesToKmz, kmzFiles } = (files || []).reduce<{
    filesToZip: StagedFile[];
    zipFiles: StagedFile[];
    filesToKmz: StagedFile[];
    kmzFiles: StagedFile[];
  }>(
    (acc, file) => {
      const ext = file.name.substring(file.name.lastIndexOf('.'));

      if (ext === '.zip') {
        acc.zipFiles.push(file);
      } else if (ext === '.kmz') {
        acc.kmzFiles.push(file);
      } else if (ext === '.kml') {
        acc.filesToKmz.push(file);
      } else {
        acc.filesToZip.push(file);
      }

      return acc;
    },
    {
      filesToZip: [],
      zipFiles: [],
      filesToKmz: [],
      kmzFiles: [],
    },
  );

  const zippedFiles = await zipFieldBoundaries(
    filesToZip.map((file) => ({
      id: file.id,
      file: FileStorageService.getItem(file.id)!,
    })),
  );
  const zippedKmlFiles = await zipFieldBoundaries(
    filesToKmz.map((file) => ({
      id: file.id,
      file: FileStorageService.getItem(file.id)!,
    })),
  );
  const zipArrayBuffers = await Promise.all(
    zipFiles.map((file) =>
      readFileAsArrayBuffer(FileStorageService.getItem(file.id)!),
    ),
  );
  const kmzArrayBuffers = await Promise.all(
    kmzFiles.map((file) =>
      readFileAsArrayBuffer(FileStorageService.getItem(file.id)!),
    ),
  );

  return [
    ...zippedFiles.map((zippedFile) => ({
      farmUuid,
      type: UploadType.fieldBoundaries,
      id: nanoid(),
      name: zippedFile.name,
      fileStorageIds: zippedFile.fileIds,
      arraybuffer: zippedFile.arraybuffer,
    })),
    ...zipFiles.map((file, ind) => ({
      farmUuid,
      type: UploadType.fieldBoundaries,
      id: nanoid(),
      fileStorageIds: [file.id],
      name: file.name,
      arraybuffer: zipArrayBuffers[ind],
    })),
    ...zippedKmlFiles.map((zippedKmlFile) => ({
      farmUuid,
      type: UploadType.fieldBoundaries,
      id: nanoid(),
      name: zippedKmlFile.name,
      fileStorageIds: zippedKmlFile.fileIds,
      arraybuffer: zippedKmlFile.arraybuffer,
      fileExtension: UploadFileExtension.kmz,
    })),
    ...kmzFiles.map((file, ind) => ({
      farmUuid,
      type: UploadType.fieldBoundaries,
      id: nanoid(),
      fileStorageIds: [file.id],
      name: file.name,
      arraybuffer: kmzArrayBuffers[ind],
      fileExtension: UploadFileExtension.kmz,
    })),
  ];
};

const processFiles = async (
  type: UploadType,
  files: StagedFile[],
): Promise<PreparedFile[]> => {
  const storedFiles = files.map((file) => FileStorageService.getItem(file.id));
  const arrayBuffers = await Promise.all(
    storedFiles.map((storedFile) => readFileAsArrayBuffer(storedFile!)),
  );

  return files.map((file, ind) => ({
    id: nanoid(),
    name: file.name,
    type,
    fileStorageIds: [file.id],
    arraybuffer: arrayBuffers[ind],
  }));
};

const uploadMachineryFiles = createAsyncThunk(
  'uploadData/uploadMachineryFiles',
  async (files: StagedFile[], { dispatch }) => {
    const processFileUpload = getProcessFileUploadFn(dispatch);
    const machineryFilesToUpload = await processFiles(
      UploadType.machineryFormats,
      files,
    );

    return batchOperations(
      processFileUpload,
      machineryFilesToUpload,
      BATCH_SIZE,
    ).then(() => {
      successNotify({
        message: i18n.t('upload-data.notifications.machinery-files-uploaded'),
      });
    });
  },
  {
    condition: (files) => files && files.length !== 0,
  },
);

export const uploadFiles = createAsyncThunk<UploadingFile[]>(
  'uploadData/uploadFiles',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const farm = selectFarm(state);
    const {
      [UploadType.fieldBoundaries]: fieldFiles,
      [UploadType.machineryFormats]: machineryFiles,
      ...otherFiles
    } = selectFiles(state);
    const processFileUpload = getProcessFileUploadFn(dispatch);

    const fieldFilesToUpload = await processFieldFiles(
      fieldFiles,
      farm?.uuid ?? '',
    );

    const otherFilesToUpload = (
      await Promise.all(
        Object.entries(otherFiles).map(([type, value]) =>
          processFiles(type as UploadType, value),
        ),
      )
    ).flat();

    // fields boundaries should be uploaded before other files
    void batchOperations(
      processFileUpload,
      fieldFilesToUpload,
      BATCH_SIZE,
    ).then(() => {
      void dispatch(uploadMachineryFiles(machineryFiles));

      return batchOperations(processFileUpload, otherFilesToUpload, BATCH_SIZE);
    });

    const popupFiles = [...fieldFilesToUpload, ...otherFilesToUpload];

    if (popupFiles.length > 0) {
      dispatch(setStepProcessingPanel(UploadStep.uploading));
      void dispatch(openUploadFiles());
    }

    return popupFiles.map((file) => ({
      id: file.id,
      name: file.name,
      type: file.type,
      status: FileStatus.uploading,
    }));
  },
);

export const fetchAssetStatus =
  ({
    farmUuid,
    fieldUuid,
    uuid,
    isField,
    isSoil,
    isYield,
    isAsApplied,
  }: {
    farmUuid: string;
    fieldUuid: string;
    uuid: string;
    isField: boolean;
    isSoil: boolean;
    isYield: boolean;
    isAsApplied: boolean;
  }): AppThunk<
    Promise<
      | {
          uuid: string;
          status: FileStatus;
          statusMessage?: string;
        }
      | undefined
    >
  > =>
  async () => {
    let status;
    let statusMessage;

    try {
      const result = await getAssetStatus({
        farmUuid,
        fieldUuid,
        uuid,
        isField,
        isSoil,
        isYield,
        isAsApplied,
      });

      status = result.status;
      statusMessage = result.statusMessage;
    } catch (error) {
      captureException({
        error: new CustomError('[UploadData] Unable to get assets status', {
          cause: error,
        }),
      });
      warningNotify({
        message: i18n.t('upload-data.notifications.first-upload-shapefile'),
      });
    }

    if (!status) {
      return;
    }

    return {
      uuid,
      status: getFileUploadStatus(status),
      statusMessage,
    };
  };

export const subscription =
  (parsedEvent: ParsedEvent): AppThunk =>
  (dispatch, getState) => {
    const {
      pathLength,
      action,
      farmUuid,
      fieldUuid,
      soilDatasetUuid,
      yieldDatasetUuid,
      asAppliedDatasetUuid,
    } = parsedEvent;

    if (action !== PlatformEventAction.insert) {
      return;
    }

    let uuid;
    let isField = false;
    let isSoil = false;
    let isYield = false;
    let isAsApplied = false;

    if (fieldUuid && fieldUuid !== '' && pathLength === 2) {
      isField = true;
      uuid = fieldUuid;
    } else if (soilDatasetUuid && soilDatasetUuid !== '' && pathLength === 3) {
      isSoil = true;
      uuid = soilDatasetUuid;
    } else if (
      yieldDatasetUuid &&
      yieldDatasetUuid !== '' &&
      pathLength === 3
    ) {
      isYield = true;
      uuid = yieldDatasetUuid;
    } else if (
      asAppliedDatasetUuid &&
      asAppliedDatasetUuid !== '' &&
      pathLength === 3
    ) {
      isAsApplied = true;
      uuid = asAppliedDatasetUuid;
    }

    const files = selectProcessingPanelFiles(getState());
    const filesUuids = files.map((file) => file.uuid);

    if (!uuid || !filesUuids.includes(uuid)) {
      return;
    }

    dispatch(
      tryUpdateFileStatusProcessingPanel({
        uuid,
        status: FileStatus.complete,
      }),
    );
    void dispatch(
      fetchAssetStatus({
        farmUuid,
        fieldUuid,
        uuid,
        isField,
        isSoil,
        isYield,
        isAsApplied,
      }),
    )
      .then((update) => {
        if (!update) {
          return;
        }

        dispatch(
          updateFileStatus({
            uuid: update.uuid,
            status: update.status,
            farmUuid,
            fieldUuid,
          }),
        );

        if (
          isField &&
          update.status === FileStatus.failed &&
          update.statusMessage
        ) {
          const errorMessageKey =
            FIELD_STATUS_MESSAGES_TO_I18N_KEYS_MAP[update.statusMessage];

          if (errorMessageKey) {
            warningNotify({
              message: i18n.t(errorMessageKey),
            });
          }
        }
      })
      .then(() => {
        const state = getState();
        const newFiles = selectProcessingPanelFiles(state);
        const step = selectProcessingPanelStep(state);
        const uploadStep = getUploadStep(newFiles);

        if (step !== uploadStep) {
          dispatch(setStepProcessingPanel(uploadStep));
        }
      });
  };

export const uploadDataSlice = createSlice({
  name: 'uploadData',
  initialState,
  reducers: {
    addFiles: {
      prepare({ type, files }: { type: UploadType; files: File[] }) {
        return {
          payload: {
            type,
            files: files.map((file) => {
              const id = nanoid();

              FileStorageService.addItem(id, file);

              return {
                id,
                name: file.name,
              };
            }),
          },
        };
      },
      reducer(
        state,
        action: PayloadAction<{
          type: UploadType;
          files: { id: string; name: string }[];
        }>,
      ) {
        state.files[action.payload.type].push(
          ...action.payload.files.map(({ id, name }) => ({
            id,
            name,
            type: action.payload.type,
          })),
        );
      },
    },
    removeFile: {
      prepare(id: string) {
        FileStorageService.removeItem(id);

        return {
          payload: id,
        };
      },
      reducer(state, action: PayloadAction<string>) {
        state.files[state.tab] = state.files[state.tab].filter(
          ({ id }) => id !== action.payload,
        );
      },
    },
    setFarm(state, action) {
      state.farm = action.payload;
    },
    setTab(state, action) {
      state.tab = action.payload;
    },
    resetProcessingPanel(state) {
      state.processingPanel = initialState.processingPanel;
    },
    setStepProcessingPanel(state, action) {
      state.processingPanel.step = action.payload;
    },
    tryUpdateFileStatusProcessingPanel(
      state,
      action: PayloadAction<{
        uuid: string;
        status: FileStatus;
      }>,
    ) {
      state.processingPanel.files = state.processingPanel.files.map((file) => {
        if (
          file.status === FileStatus.verified ||
          file.uuid !== action.payload.uuid
        ) {
          return file;
        }

        return {
          ...file,
          status: action.payload.status,
        };
      });
    },
    markFailedFile(
      state,
      action: PayloadAction<{
        id: string;
        status: FileStatus;
      }>,
    ) {
      state.processingPanel.files = state.processingPanel.files.map((file) => {
        if (file.id !== action.payload.id) {
          return file;
        }

        return {
          ...file,
          status: action.payload.status,
        };
      });
    },
    setupUploadedFile(
      state,
      action: PayloadAction<{
        id: string;
        uuid: string;
      }>,
    ) {
      state.processingPanel.files = state.processingPanel.files.map((file) => {
        if (file.id !== action.payload.id) {
          return file;
        }

        return {
          ...file,
          uuid: action.payload.uuid,
        };
      });
    },
    updateFileStatus(
      state,
      action: PayloadAction<{
        uuid: string;
        status: FileStatus;
        farmUuid: string;
        fieldUuid: string;
      }>,
    ) {
      state.processingPanel.files = state.processingPanel.files.map((file) => {
        if (file.uuid !== action.payload.uuid) {
          return file;
        }

        return {
          ...file,
          status: action.payload.status,
          farmUuid: action.payload.farmUuid,
          fieldUuid: action.payload.fieldUuid,
        };
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadFiles.fulfilled, (state, action) => {
        state.farm = initialState.farm;
        state.files = initialState.files;
        state.processingPanel.files.push(...action.payload);
      })
      .addCase(uploadMachineryFiles.pending, (state) => {
        state.isMachineryFilesUploading = true;
      })
      .addCase(uploadMachineryFiles.fulfilled, (state) => {
        state.isMachineryFilesUploading = false;
      });
  },
});

export const {
  setupUploadedFile,
  updateFileStatus,
  markFailedFile,
  addFiles,
  removeFile,
  setFarm,
  setTab,
  resetProcessingPanel,
  setStepProcessingPanel,
  tryUpdateFileStatusProcessingPanel,
} = uploadDataSlice.actions;

export default uploadDataSlice.reducer;
