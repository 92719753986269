import { API, graphqlOperation } from '@aws-amplify/api';

import getProfileQuery from './graphql/queries/getProfile.gql';
import manageProfileJohnDeereMutation from './graphql/mutations/manageProfileJohnDeere.gql';
import deleteJDConnectionMutation from './graphql/mutations/deleteJDConnection.gql';
import {
  DeleteJDConnectionResponse,
  GetProfileResponse,
  ManageProfileJohnDeereResponse,
} from './types/api';

export const getProfile = async () => {
  const { data } = (await API.graphql(
    graphqlOperation(getProfileQuery),
  )) as GetProfileResponse;

  return data!.getProfileJohnDeere;
};

export const manageProfile = async ({
  hasAutoImportAllFieldOperations,
  hasAutoUpdateFieldBoundaries,
  hasAutoUpdateFieldStructure,
  hasAutoCreateZonesMapLayers,
  hasAutoCreateSoilMapLayers,
  hasAutoCreateYieldMapLayers,
  hasAutoCreateTopographyMapLayers,
  hasAutoCreateEquationMapLayers,
}: {
  hasAutoImportAllFieldOperations?: boolean;
  hasAutoUpdateFieldBoundaries?: boolean;
  hasAutoUpdateFieldStructure?: boolean;
  hasAutoCreateZonesMapLayers?: boolean;
  hasAutoCreateSoilMapLayers?: boolean;
  hasAutoCreateYieldMapLayers?: boolean;
  hasAutoCreateTopographyMapLayers?: boolean;
  hasAutoCreateEquationMapLayers?: boolean;
}) => {
  const { data } = (await API.graphql(
    graphqlOperation(manageProfileJohnDeereMutation, {
      input: {
        hasAutoImportAllFieldOperations,
        hasAutoUpdateFieldBoundaries,
        hasAutoUpdateFieldStructure,
        hasAutoCreateZonesMapLayers,
        hasAutoCreateSoilMapLayers,
        hasAutoCreateYieldMapLayers,
        hasAutoCreateTopographyMapLayers,
        hasAutoCreateEquationMapLayers,
      },
    }),
  )) as ManageProfileJohnDeereResponse;

  return data!.manageProfileJohnDeere;
};

export const deleteJDConnection = async () => {
  const { data } = (await API.graphql(
    graphqlOperation(deleteJDConnectionMutation),
  )) as DeleteJDConnectionResponse;

  return data!.flushProfileJohnDeere;
};
