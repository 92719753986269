query getStripePriceOpenDataConfig {
  getStripePriceOpenDataConfig {
    prices {
      amount
      currency
      plan
      price
    }
    productKey
  }
}
