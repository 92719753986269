import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import type { FullAttributeItem } from '../../types/ui';
import type { AvgTotalCalibrationType } from '../../helpers/constants/ui';
import AvgTotalCalibrateConditionsComponent from '../../components/AvgTotalCalibrateConditions';
import { useAppSelector } from '../../../../../app/store/helpers/functions';
import { selectYieldDataset } from '../../../../field/fieldSelectors';
import {
  selectDatasetUuid,
  selectCalibrateAvgTotalConditions,
} from '../../cleanCalibrateSelectors';
import {
  addEmptyCalibrateAvgTotalCondition,
  updateCalibrateAvgTotalCondition,
  removeCalibrateAvgTotalCondition,
  initCalibrateAvgTotalConditions,
} from '../../cleanCalibrateSlice';
import { getAvailableFullAttributeItems } from '../../helpers/functions/ui';
import useDidMount from '../../../../../hooks/useDidMount';

export default function AvgTotalCalibrateConditions({
  availableAttributes,
  typeSelectorTitle,
  title,
}: {
  availableAttributes: string[] | null;
  typeSelectorTitle: string;
  title?: string;
}) {
  const dispatch = useDispatch();

  const datasetUuid = useAppSelector(selectDatasetUuid);
  const dataset = useAppSelector((state) =>
    selectYieldDataset(state, datasetUuid),
  );
  const avgTotalConditions = useAppSelector(selectCalibrateAvgTotalConditions);

  const availableAttributesItems = useMemo(
    () => getAvailableFullAttributeItems(availableAttributes, dataset),
    [dataset, availableAttributes],
  );

  const handleAddCondition = () => {
    dispatch(addEmptyCalibrateAvgTotalCondition());
  };

  const handleConditionAttributeChange = (
    attributeItem: FullAttributeItem,
    index: number,
  ) => {
    dispatch(
      updateCalibrateAvgTotalCondition({
        index,
        update: { calibrationAttribute: attributeItem?.value ?? null },
      }),
    );
  };

  const handleConditionTypeChange = (
    type: AvgTotalCalibrationType,
    index: number,
  ) => {
    dispatch(
      updateCalibrateAvgTotalCondition({
        index,
        update: { type },
      }),
    );
  };

  const handleConditionAvgChange = (average: number | null, index: number) => {
    dispatch(
      updateCalibrateAvgTotalCondition({
        index,
        update: { average },
      }),
    );
  };

  const handleConditionTotalChange = (total: number | null, index: number) => {
    dispatch(
      updateCalibrateAvgTotalCondition({
        index,
        update: { total },
      }),
    );
  };

  const handleConditionDelete = (index: number) => {
    dispatch(removeCalibrateAvgTotalCondition(index));
  };

  useDidMount(() => {
    if (avgTotalConditions.length === 0) {
      dispatch(initCalibrateAvgTotalConditions());
    }
  });

  return (
    <AvgTotalCalibrateConditionsComponent
      title={title}
      typeSelectorTitle={typeSelectorTitle}
      attributesItems={availableAttributesItems}
      conditions={avgTotalConditions}
      onAddConditionClick={handleAddCondition}
      onConditionAttributeChange={handleConditionAttributeChange}
      onConditionTypeChange={handleConditionTypeChange}
      onConditionAvgChange={handleConditionAvgChange}
      onConditionTotalChange={handleConditionTotalChange}
      onConditionDelete={handleConditionDelete}
    />
  );
}
