import React, { useMemo } from 'react';
import type { Dictionary } from '@reduxjs/toolkit';

import PreviewTile from '../PreviewTile';
import ScrollContainer from '../../../../../components/ScrollContainer';
import { useAppSelector } from '../../../../../app/store/helpers/functions';
import {
  selectEquationMapsEntities,
  selectFieldsEntitiesList,
} from '../../../../assets/assetsSelectors';
import {
  selectEquationMapsData,
  selectListFilterSearchTerm,
  selectListFilterFarmUuid,
  selectListFilterSortOrder,
} from '../../createBatchEquationBasedAnalysisSelectors';
import { selectSelectedFieldsUuids } from '../../../batchAnalytics/batchAnalyticsSelectors';
import {
  SORT_ORDER_TO_COMPARATOR_FN,
  SortOrder,
} from '../../helpers/constants/ui';
import type {
  EquationMapEntity,
  FieldEntity,
} from '../../../../assets/types/state';
import type { EquationMapData } from '../../types/equationMap';

import './index.scss';

const filterFieldsEntities = (
  fields: FieldEntity[],
  filter: {
    query: string;
    farmUuid: string | null;
  },
) => {
  const normalizedQuery = filter.query.toLowerCase();
  return fields.filter((field) => {
    const normalizedFieldName = field.name?.toLowerCase();

    return (
      normalizedFieldName?.includes(normalizedQuery) &&
      (!filter.farmUuid || field.farmUuid === filter.farmUuid)
    );
  });
};

const sortFieldEntities = (
  fields: FieldEntity[],
  equationMaps: Dictionary<EquationMapEntity>,
  equationMapsData: Record<string, EquationMapData>,
  sortOrder: SortOrder,
) => {
  const comparatorFn = SORT_ORDER_TO_COMPARATOR_FN[sortOrder];

  return [...fields].sort((a, b) => {
    const equationMapAUuid = equationMapsData[a.uuid]?.uuid || '';
    const equationMapBUuid = equationMapsData[b.uuid]?.uuid || '';

    return comparatorFn(
      equationMaps[equationMapAUuid],
      equationMaps[equationMapBUuid],
    );
  });
};

export default function PreviewEquationMaps() {
  const selectedFieldsUuids = useAppSelector(selectSelectedFieldsUuids);
  const fieldsEntitiesList = useAppSelector((state) =>
    selectFieldsEntitiesList(state, selectedFieldsUuids),
  );
  const equationMapsEntities = useAppSelector(selectEquationMapsEntities);
  const equationMapsData = useAppSelector(selectEquationMapsData);
  const listFilterSearchTerm = useAppSelector(selectListFilterSearchTerm);
  const listFilterFarmUuid = useAppSelector(selectListFilterFarmUuid);
  const listFilterSortOrder = useAppSelector(selectListFilterSortOrder);

  const sortedFieldsEntities = useMemo(() => {
    const filteredFieldsEntities = filterFieldsEntities(fieldsEntitiesList, {
      query: listFilterSearchTerm,
      farmUuid: listFilterFarmUuid,
    });

    return sortFieldEntities(
      filteredFieldsEntities,
      equationMapsEntities,
      equationMapsData,
      listFilterSortOrder,
    );
  }, [
    listFilterFarmUuid,
    listFilterSearchTerm,
    listFilterSortOrder,
    fieldsEntitiesList,
    equationMapsEntities,
    equationMapsData,
  ]);

  return (
    <ScrollContainer
      classes={{
        root: 'preview-equation-maps',
        wrapper: 'preview-equation-maps__wrapper',
      }}
    >
      {sortedFieldsEntities.map((fieldEntity) => (
        <PreviewTile key={fieldEntity.uuid} fieldUuid={fieldEntity.uuid} />
      ))}
    </ScrollContainer>
  );
}
