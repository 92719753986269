export const selectSettings = ({ createMultiLayerAnalysis }) =>
  createMultiLayerAnalysis.settings;

export const selectDataLayers = ({ createMultiLayerAnalysis }) =>
  createMultiLayerAnalysis.dataLayers;

export const selectIsValidSetup = ({ createMultiLayerAnalysis }) =>
  createMultiLayerAnalysis.isValidSetup;

export const selectChanged = ({ createMultiLayerAnalysis }) =>
  createMultiLayerAnalysis.changed;

export const selectCloned = ({ createMultiLayerAnalysis }) =>
  createMultiLayerAnalysis.cloned;

export const selectActiveDataLayerIndex = ({ createMultiLayerAnalysis }) =>
  createMultiLayerAnalysis.activeDataLayerIndex;

export const selectActiveDataLayer = ({ createMultiLayerAnalysis }) => {
  const { dataLayers, activeDataLayerIndex } = createMultiLayerAnalysis;

  return dataLayers[activeDataLayerIndex];
};

export const selectActiveImageUuid = ({ createMultiLayerAnalysis }) =>
  createMultiLayerAnalysis.activeImageUuid;
