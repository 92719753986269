import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Stepper from '../../../../../../../components/Stepper';
import AutocompleteSingle from '../../../../../../../components/AutocompleteSingle';
import OrganizationsTable from '../../../../../jdImport/containers/OrganizationsTable';
import { fetchOrganizationsJohnDeereWithNewOrgName } from '../../../../../../jdOrganizations/jdOrganizationsSlice';
import {
  selectOrganizationExportToJohnDeere,
  setStepExportToJohnDeere,
} from '../../../../jdExportSlice';
import {
  selectOrganizationsIsLoading,
  selectOrganizationsOrgName,
} from '../../../../../../jdOrganizations/jdOrganizationsSelectors';
import {
  selectExportHasSelectedOrganization,
  selectExportSelectedOrganization,
} from '../../../../jdExportSelectors';
import getSteps from '../getSteps';
import AmplitudeAnalytics from '../../../../../../../helpers/classes/amplitudeAnalytics';
import { PAGES_ROOTS } from '../../../../../../../helpers/navigation';

import './index.scss';

const StepperSelectOrganization = () => {
  const dispatch = useDispatch();

  const isLoadingOrganizations = useSelector(selectOrganizationsIsLoading);
  const orgName = useSelector(selectOrganizationsOrgName);
  const hasSelectedOrganization = useSelector(
    selectExportHasSelectedOrganization,
  );
  const selectedOrganization = useSelector(selectExportSelectedOrganization);

  const { t } = useTranslation();
  const steps = getSteps().slice(0, 1);

  const onOrganizationChange = (newSelectedOrganization) => {
    dispatch(
      selectOrganizationExportToJohnDeere({
        selectedOrganization: newSelectedOrganization,
      }),
    );
  };

  const onChangeFilterByOrgName = (newOrgName) => {
    dispatch(
      fetchOrganizationsJohnDeereWithNewOrgName({ orgName: newOrgName }),
    );
    if (newOrgName) {
      AmplitudeAnalytics.trackOrganizationNameFilterChanged({
        page: PAGES_ROOTS.exportDataJohnDeere,
      });
    }
  };

  const onClickNext = () => {
    dispatch(setStepExportToJohnDeere({ step: 'selectZonesMaps' }));
  };

  return (
    <Stepper
      classes={{
        root: 'john-deere__authorized-select-organization-step',
        backButton: 'back-button',
      }}
      activeStep={0}
      steps={steps}
      backHidden
      nextDisabled={!hasSelectedOrganization || isLoadingOrganizations}
      onClickNext={onClickNext}
    >
      <Fragment key="panel">
        <AutocompleteSingle
          key="filter-by-name"
          placeholder={t(
            'export-data-john-deere.steps.selectOrganization.search-by-name',
          )}
          classes={{
            root: 'john-deere__authorized-search',
          }}
          disabled={isLoadingOrganizations}
          value={orgName}
          onChange={onChangeFilterByOrgName}
        />
        {isLoadingOrganizations ? (
          <div className="stepper-john-deere-panel__loader">
            <CircularProgress />
          </div>
        ) : (
          <OrganizationsTable
            typeSelection={{
              type: 'single',
              selectedOrganization: hasSelectedOrganization
                ? selectedOrganization.id
                : 0,
              onOrganizationChange,
            }}
          />
        )}
      </Fragment>
    </Stepper>
  );
};

export default StepperSelectOrganization;
