import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import './index.scss';

export default function NamedItem({
  item,
  namePlaceholder,
  classes,
}: {
  item: { name?: string };
  namePlaceholder?: string;
  classes?: {
    root?: string;
  };
}) {
  const { t } = useTranslation();

  return (
    <div className={clsx('named-item', classes?.root)}>
      <span className="named-item__label">
        {item.name || namePlaceholder || t('general.shared.no-name')}
      </span>
    </div>
  );
}
