import React from 'react';
import IconButton from '@material-ui/core/IconButton';

import './index.scss';

const MapControlsGroup = ({ buttons = [] }) => (
  <div className="map-controls__group">
    {buttons.map((button, ind) => (
      <IconButton
        key={ind}
        className="map-controls__button"
        onClick={button.onClick}
      >
        {button.icon}
      </IconButton>
    ))}
  </div>
);

export default MapControlsGroup;
