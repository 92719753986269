import i18n from 'i18next';
import { PURPOSES_I18N_KEY_LABEL_MAP, getRatesData } from '../../analysis';
import { VectorAnalysisMapType } from '../../constants/entities/vectorAnalysisMap';
import { GeoMap } from '../../types/api';
import type { TransformedVectorAnalysisMap } from '../../types/vectorAnalysisMap';

export const isVamapInvalid = (
  vamap: TransformedVectorAnalysisMap,
): boolean => {
  const { geoMaps, status } = vamap || {};

  return status === 'ERROR' || !geoMaps || geoMaps.length === 0;
};

export const hasRates = (vamap: TransformedVectorAnalysisMap) => {
  const attributes = vamap.zonesMapGeojson || vamap.attributes;
  const ratesHeaders = getRatesData(attributes);

  return !!ratesHeaders.length;
};

export const getThumbnailGeoMap = (geoMaps: GeoMap[] = []) =>
  geoMaps.find(({ shortName }) => shortName === 'thumbnail');

export const getZonesHexColorCodes = (geoMaps: GeoMap[] = []) => {
  const zonesGeoMap = geoMaps.find(({ shortName }) => shortName === 'zones');

  return zonesGeoMap?.hexColorCodes || [];
};

export const getPurposeOptions = (): {
  value: VectorAnalysisMapType;
  title: string;
}[] => [
  {
    value: VectorAnalysisMapType.general,
    title: i18n.t(PURPOSES_I18N_KEY_LABEL_MAP[VectorAnalysisMapType.general]),
  },
  {
    value: VectorAnalysisMapType.spraying,
    title: i18n.t(PURPOSES_I18N_KEY_LABEL_MAP[VectorAnalysisMapType.spraying]),
  },
  {
    value: VectorAnalysisMapType.seeding,
    title: i18n.t(PURPOSES_I18N_KEY_LABEL_MAP[VectorAnalysisMapType.seeding]),
  },
  {
    value: VectorAnalysisMapType.fertilizing,
    title: i18n.t(
      PURPOSES_I18N_KEY_LABEL_MAP[VectorAnalysisMapType.fertilizing],
    ),
  },
  {
    value: VectorAnalysisMapType.irrigation,
    title: i18n.t(
      PURPOSES_I18N_KEY_LABEL_MAP[VectorAnalysisMapType.irrigation],
    ),
  },
];
