import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import { Virtuoso } from 'react-virtuoso';

import { formatDateTime } from '../../../../../helpers/functions/utils/date';
import {
  OperationStatus,
  OperationType,
} from '../../helpers/constants/operation';
import type { Operation } from '../../types/operation';
import { countOperations } from '../../helpers/functions/operations';
import OperationsListItem from '../OperationsListItem';
import StatusTabs from '../StatusTabs';

import './index.scss';

export default function OperationsList({
  type,
  lastUpdate,
  operations,
  loading,
}: {
  type?: OperationType;
  lastUpdate: string;
  operations: Operation[];
  loading: boolean;
}) {
  const { t } = useTranslation();
  const [filterStatus, setFilterStatus] = useState(OperationStatus.all);
  let content: ReactNode;

  if (type && !loading) {
    const filteredOperations = operations.reduce<Operation[]>(
      (acc, operation) => {
        const filteredSubOperations = operation.subOperations?.filter(
          ({ status }) =>
            filterStatus === OperationStatus.all || status === filterStatus,
        );

        return filteredSubOperations.length
          ? [
              ...acc,
              {
                ...operation,
                subOperations: filteredSubOperations,
              },
            ]
          : acc;
      },
      [],
    );
    const counters = countOperations(operations);

    const itemRenderer = (_i: number, operation: Operation) => (
      <OperationsListItem operation={operation} />
    );

    content = (
      <>
        <div className="operations-list__title-container">
          <Typography variant="h5" className="operations-list__title">
            {t(`operations.types.${type}`)}
          </Typography>
          <span className="operations-list__last-update">
            {t('operations.type-panel.last-update')}:{' '}
            {formatDateTime(lastUpdate)}
          </span>
        </div>
        <StatusTabs
          inProgressCount={counters.inProgress}
          doneCount={counters.done}
          errorCount={counters.error}
          ignoredCount={counters.ignored}
          status={filterStatus}
          onStatusChange={setFilterStatus}
        />
        <div className="operations-list__items-wrapper">
          <Virtuoso data={filteredOperations} itemContent={itemRenderer} />
        </div>
      </>
    );
  } else {
    content = (
      <div className="operations-list__center-block">
        {loading ? (
          <CircularProgress />
        ) : (
          <Typography className="operations-list__no-operation">
            {t('operations.no-operation')}
          </Typography>
        )}
      </div>
    );
  }

  return <div className="operations-list">{content}</div>;
}
