import React, { Ref, forwardRef, memo } from 'react';
import ListElement, { ListProps } from '@material-ui/core/List';

const List = ({ children, ...props }: ListProps, ref: Ref<HTMLDivElement>) => (
  <ListElement {...props} ref={ref as Ref<HTMLUListElement>}>
    {children}
  </ListElement>
);

const MemoizedList = memo(forwardRef(List));

export default MemoizedList;
