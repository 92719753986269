import { DatasetViewType } from '../../constants/entities/dataset';
import { TransformedDataset } from '../../types/dataset';
import {
  isAsAppliedDataset,
  isDataset,
  isSoilDataset,
  isYieldDataset,
} from '../entities/assets';

const getDatasetMapAttributeAndViewType = (
  item?: TransformedDataset | null,
  {
    soilAttribute,
    yieldAttribute,
    asAppliedAttribute,
    soilViewType,
    yieldViewType,
    asAppliedViewType,
  }: {
    soilAttribute?: string;
    yieldAttribute?: string;
    asAppliedAttribute?: string;
    soilViewType?: DatasetViewType | null;
    yieldViewType?: DatasetViewType | null;
    asAppliedViewType?: DatasetViewType | null;
  } = {},
) => {
  let attribute;
  let viewType = null;

  if (!item || !isDataset(item)) {
    return {
      attribute,
      viewType,
    };
  }

  if (isSoilDataset(item)) {
    attribute = soilAttribute;
    viewType = soilViewType;
  } else if (isYieldDataset(item)) {
    attribute = yieldAttribute;
    viewType = yieldViewType;
  } else if (isAsAppliedDataset(item)) {
    attribute = asAppliedAttribute;
    viewType = asAppliedViewType;
  }

  return {
    attribute,
    viewType,
  };
};

export default getDatasetMapAttributeAndViewType;
