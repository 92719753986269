import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from '@material-ui/core';
import clsx from 'clsx';

import TextField from '../../../../../components/TextField';
import Datepicker from '../../../../../components/Datepicker';
import ResetFiltersButton from '../ResetFiltersButton';
import { DefaultAssetGroupFilters } from '../../types/assetGroup';
import { DefaultAssetGroupFilterKey } from '../../helpers/constants/assetGroup';

import './index.scss';

const DefaultAssetGroupFilter = ({
  value,
  classes = {},
  onChange,
  onFiltersClear,
}: {
  value: DefaultAssetGroupFilters;
  classes?: { root?: string };
  onChange: (v: DefaultAssetGroupFilters) => void;
  onFiltersClear?: () => void;
}) => {
  const { t } = useTranslation();
  const { nameFilterValue, createdFrom, createdTo } = value;
  const handleChange = (
    prop: DefaultAssetGroupFilterKey,
    filterValue: string | Date | null,
  ) => {
    onChange({
      ...value,
      [prop]: filterValue,
    });
  };

  return (
    <div className={clsx('default-asset-group-filters', classes.root)}>
      <TextField
        type="search"
        placeholder={t('general.controls.filters.name-filter-placeholder')}
        classes={{
          title: 'default-asset-group-filters__control-label',
          wrapper: 'default-asset-group-filters__control',
        }}
        value={nameFilterValue}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          handleChange(
            DefaultAssetGroupFilterKey.nameFilterValue,
            event.target.value,
          );
        }}
        onEndAdornmentClick={() => {
          handleChange(DefaultAssetGroupFilterKey.nameFilterValue, '');
        }}
      />
      <div className="default-asset-group-filters__range-controls">
        <label className="default-asset-group-filters__control-label">
          {t('general.controls.filters.creation-date')}
        </label>
        <Datepicker
          value={createdFrom}
          maxDate={createdTo || new Date()}
          placeholder={t('operations.filters.time-range.placeholder')}
          classes={{ root: 'default-asset-group-filters__range-item' }}
          onChange={(from: Date | null) => {
            handleChange(DefaultAssetGroupFilterKey.createdFrom, from);
          }}
        />
        <Divider className="default-asset-group-filters__divider" />
        <Datepicker
          value={createdTo}
          placeholder={t('operations.filters.time-range.placeholder')}
          maxDate={new Date()}
          classes={{ root: 'default-asset-group-filters__range-item' }}
          onChange={(to: Date | null) => {
            handleChange(DefaultAssetGroupFilterKey.createdTo, to);
          }}
        />
      </div>
      {onFiltersClear && (
        <ResetFiltersButton
          classes={{ root: 'default-asset-group-filters__reset-button' }}
          onClick={onFiltersClear}
        />
      )}
    </div>
  );
};

export default DefaultAssetGroupFilter;
