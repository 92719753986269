import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '../../../../../../../components/Button';
import { selectProfileAcceptUrl } from '../../../../../../jdProfile/jdProfileSelectors';
import AmplitudeAnalytics from '../../../../../../../helpers/classes/amplitudeAnalytics';
import useCurrentPage from '../../../../../../../hooks/useCurrentPage';

const JohnDeereComponent = () => {
  const { t } = useTranslation();
  const page = useCurrentPage();
  const acceptUrl = useSelector(selectProfileAcceptUrl);

  const onConnectToAccountClick = () => {
    AmplitudeAnalytics.trackConnectToJDOrganizationLinkClicked({
      page,
    });
    window.location.href = acceptUrl;
  };

  return (
    <section className="john-deere__not-authorized">
      <p>{t('john-deere.connect-to-account.label')}</p>
      <Button
        variant="contained"
        color="primary"
        onClick={onConnectToAccountClick}
      >
        {t('john-deere.connect-to-account.button')}
      </Button>
    </section>
  );
};

export default JohnDeereComponent;
