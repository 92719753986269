import React from 'react';
import { useTranslation } from 'react-i18next';

import { getBrandName } from '../../../../../../../helpers/functions/utils/appConfig';

const JohnDeereComponent = () => {
  const { t } = useTranslation();
  return (
    <section className="john-deere__failed">
      <p>
        {t('john-deere.failed.label', {
          brandName: getBrandName(),
        })}
      </p>
    </section>
  );
};

export default JohnDeereComponent;
