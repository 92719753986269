import React from 'react';

import { useAppSelector } from '../../../../../app/store/helpers/functions';
import { selectWorkflow } from '../../zonesOpsSelectors';
import getStepper from './config';

export default function StepperZonesOps() {
  const workflow = useAppSelector(selectWorkflow);
  const Component = getStepper(workflow);

  return <Component />;
}
