import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Drawer from '../../components/Drawer';
import { selectApplicationStatus } from '../../applicationShellSelectors';
import {
  selectEmail,
  selectIsUserOrganizationsAccessible,
} from '../../../../user/userSelectors';
import { resetFieldsListParams } from '../../../fieldsList/fieldsListSlice';
import { reset } from '../../../zonesMaps/zonesMapsSlice';
import useSubscriptionAvailable from '../../../../../hooks/useSubscriptionAvailable';
import { PAGES_ROOTS } from '../../../../../helpers/navigation';
import useDrawerNotifications from '../../hooks/useDrawerNotifications';

interface Options {
  page?: (typeof PAGES_ROOTS)[keyof typeof PAGES_ROOTS];
}

const WrappedDrawer = () => {
  const dispatch = useDispatch();
  const email = useSelector(selectEmail);
  const applicationStatus = useSelector(selectApplicationStatus);
  const displayOrganizations = useSelector(selectIsUserOrganizationsAccessible);
  const displaySubscription = useSubscriptionAvailable();
  const notifications = useDrawerNotifications();

  const handleListItemClick = ({ page }: Options) => {
    if (page === 'fields') {
      dispatch(resetFieldsListParams());
    } else if (page === 'zones-maps') {
      dispatch(reset());
    }
  };

  return (
    <Drawer
      email={email}
      displayOrganizations={displayOrganizations}
      displaySubscription={displaySubscription}
      applicationStatus={applicationStatus}
      notifications={notifications}
      onListItemClick={handleListItemClick}
    />
  );
};

export default WrappedDrawer;
