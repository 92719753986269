import { API, graphqlOperation } from '@aws-amplify/api';

import exportToJohnDeereAsSeedingWorkPlan from './graphql/mutations/exportToJohnDeereAsSeedingWorkPlan.gql';
import exportToJohnDeereAsApplicationWorkPlan from './graphql/mutations/exportToJohnDeereAsApplicationWorkPlan.gql';
import { CustomError } from '../../../helpers/functions/utils/errorHandling';
import { getWorkPlanInputData } from './helpers/functions/api';
import { getResourceArchiveUrl } from '../jdExport/jdExportAPI';
import { isValidUrl } from '../../../helpers/functions/utils/url';
import { ExportWorkPlanData } from './types/api';
import { WORK_PLAN_ID_NAME } from './helpers/constants/workPlan';
import { AssetToExport } from './types/workPlan';

const getWorkPlanResourcesArchiveUrls = async (
  resources: AssetToExport[],
): Promise<
  {
    uuid: string;
    archiveUrl: string;
    archiveResourceName: string;
  }[]
> => {
  const cache = new Map();

  const promises = resources.map((resource) => {
    if (cache.has(resource.uuid)) {
      return cache.get(resource.uuid); // Use promise from the cache to avoid generating the archive URL for the same resource
    }

    const promise = (async () => {
      const archiveUrl = await getResourceArchiveUrl({
        resource,
        ratesOnly: true,
      });

      return {
        uuid: resource.uuid,
        archiveResourceName: resource.name,
        archiveUrl,
      };
    })();

    cache.set(resource.uuid, promise);

    return promise;
  });

  return Promise.all(promises).then((results) => results);
};

const executeExportResourceAsSeedingWorkPlan = async (
  data: ExportWorkPlanData,
  fieldUuid: string,
) => {
  const resources = [
    ...('varietyResource' in data ? [data.varietyResource] : []),
    ...('chemicalResource' in data ? [data.chemicalResource] : []),
    ...('fertilizerResource' in data ? [data.fertilizerResource] : []),
    ...('tankMixResource' in data ? [data.tankMixResource] : []),
    ...('dryBlendResource' in data ? [data.dryBlendResource] : []),
  ];

  const archiveUrlsData = await getWorkPlanResourcesArchiveUrls(resources);

  if (archiveUrlsData.every(({ archiveUrl }) => isValidUrl(archiveUrl))) {
    const response = await API.graphql(
      graphqlOperation(exportToJohnDeereAsSeedingWorkPlan, {
        input: {
          fieldUuid,
          ...getWorkPlanInputData(data, archiveUrlsData),
        },
      }),
    );

    if (response.data.exportToJohnDeereAsSeedingWorkPlan) {
      const {
        data: {
          exportToJohnDeereAsSeedingWorkPlan: { status = '', details },
        },
      } = response;

      if (status === '204') {
        return (details as { id: string; name: string }[]).find(
          ({ name }) => name === WORK_PLAN_ID_NAME,
        )?.id;
      }
    }

    throw new CustomError('[JD Export] Seeding work plan not exported', {
      cause: {
        status: response.data.exportToJohnDeereAsSeedingWorkPlan?.status,
      },
    });
  } else {
    throw new CustomError('[JD Export] Not valid archive URL');
  }
};

export const executeExportToJohnDeereAsSeedingWorkPlan = async ({
  exportData = [],
  fieldUuid,
}: {
  exportData: ExportWorkPlanData[];
  fieldUuid: string;
}) =>
  Promise.allSettled(
    exportData.map((data) =>
      executeExportResourceAsSeedingWorkPlan(data, fieldUuid),
    ),
  ).then((results) =>
    results.map((result) => {
      if (result.status === 'rejected') {
        throw result.reason;
      }
      return result.value;
    }),
  );

const executeExportResourceAsApplicationWorkPlan = async (
  data: ExportWorkPlanData,
  fieldUuid: string,
) => {
  const resources = [
    ...('chemicalResource' in data ? [data.chemicalResource] : []),
    ...('fertilizerResource' in data ? [data.fertilizerResource] : []),
    ...('tankMixResource' in data ? [data.tankMixResource] : []),
    ...('dryBlendResource' in data ? [data.dryBlendResource] : []),
  ];

  const archiveUrlsData = await getWorkPlanResourcesArchiveUrls(resources);

  if (archiveUrlsData.every(({ archiveUrl }) => isValidUrl(archiveUrl))) {
    const response = await API.graphql(
      graphqlOperation(exportToJohnDeereAsApplicationWorkPlan, {
        input: {
          fieldUuid,
          ...getWorkPlanInputData(data, archiveUrlsData),
        },
      }),
    );

    if (response.data.exportToJohnDeereAsApplicationWorkPlan) {
      const {
        data: {
          exportToJohnDeereAsApplicationWorkPlan: { status = '', details },
        },
      } = response;

      if (status === '204') {
        return (details as { id: string; name: string }[]).find(
          ({ name }) => name === WORK_PLAN_ID_NAME,
        )?.id;
      }
    }

    throw new CustomError('[JD Export] Application work plan not exported', {
      cause: {
        status: response.data.exportToJohnDeereAsApplicationWorkPlan?.status,
      },
    });
  } else {
    throw new CustomError('[JD Export] Not valid archive URL');
  }
};

export const executeExportToJohnDeereAsApplicationWorkPlan = async ({
  exportData = [],
  fieldUuid,
}: {
  exportData: ExportWorkPlanData[];
  fieldUuid: string;
}) =>
  Promise.allSettled(
    exportData.map((data) =>
      executeExportResourceAsApplicationWorkPlan(data, fieldUuid),
    ),
  ).then((results) =>
    results.map((result) => {
      if (result.status === 'rejected') {
        throw result.reason;
      }
      return result.value;
    }),
  );
