import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import './index.scss';

interface DrawerTooltipListItem {
  href?: string;
  title?: string;
  target?: string;
  link?: string;
  notification?: string;
  active?: boolean;
  disabled?: boolean;
}

interface ListConfig {
  type: 'list';
  header: string;
  items: DrawerTooltipListItem[];
}

interface SingleItemConfig {
  type: 'single-item';
  title: string;
  active: boolean;
}

export default function DrawerTooltip({
  config,
}: {
  config: ListConfig | SingleItemConfig;
}) {
  return (
    <>
      {config.type === 'list' && (
        <List
          className="drawer-tooltip__list"
          onClick={(e) => e.stopPropagation()}
        >
          <ListItem className="drawer-tooltip__header">
            {config.header}
          </ListItem>
          <Divider />
          {config.items.map((item, index) => (
            <ListItem
              key={index}
              className={clsx('drawer-tooltip__list-item', {
                'drawer-tooltip__list-item_active': item.active,
              })}
              disabled={item.disabled}
              button
              component={item.href ? 'a' : NavLink}
              href={item.href}
              to={item.link}
              target={item.target}
            >
              {item.title}
              {item.notification && (
                <div
                  className={clsx(
                    'drawer-tooltip__list-item-notification-dot',
                    {
                      'drawer-tooltip__list-item-notification-dot_error':
                        item.notification === 'error',
                    },
                  )}
                ></div>
              )}
            </ListItem>
          ))}
        </List>
      )}
      {config.type === 'single-item' && (
        <Typography
          variant="subtitle2"
          className={clsx('drawer-tooltip__header', {
            'drawer-tooltip__header_active': config.active,
          })}
        >
          {config.title}
        </Typography>
      )}
    </>
  );
}
