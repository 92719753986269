import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import TextFieldPopup from '../TextFieldPopup';
import useCurrentPage from '../../../hooks/useCurrentPage';
import AmplitudeAnalytics, {
  EntityType,
} from '../../../helpers/classes/amplitudeAnalytics';

const RenameEntity = ({
  i18nKey,
  entityType,
  entityName = '',
  onCancel = () => {},
  onConfirm = () => {},
}: {
  i18nKey: string;
  entityType: EntityType;
  entityName: string;
  onCancel: () => void;
  onConfirm: (name: string) => void;
}) => {
  const { t } = useTranslation();
  const page = useCurrentPage();

  useEffect(() => {
    if (page) {
      AmplitudeAnalytics.trackRenameEntityPopupOpened({
        entityType,
        page,
      });
    }
  }, [page, entityType]);

  const handleConfirm = (name: string) => {
    AmplitudeAnalytics.trackEntityRenamed({
      entityType,
      page,
    });
    onConfirm(name);
  };

  return (
    <TextFieldPopup
      title={t(`general.popups.rename-entity.title.${i18nKey}`)}
      textValue={entityName}
      description={
        <Trans i18nKey={`general.popups.rename-entity.description.${i18nKey}`}>
          Text{' '}
          <span className="text-field-popup__content-text_highlighted">
            {{ entityName }}
          </span>
        </Trans>
      }
      placeholder={t(`general.popups.rename-entity.placeholder.${i18nKey}`)}
      confirmText={t('general.controls.rename')}
      onCancel={onCancel}
      onConfirm={handleConfirm}
    />
  );
};

export default RenameEntity;
