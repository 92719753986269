import React, { ReactNode, Ref } from 'react';
import clsx from 'clsx';

import './index.scss';

const ScrollContainer = (
  {
    children,
    containerChild = null,
    classes,
    onScroll = () => {},
  }: {
    children: ReactNode;
    containerChild?: ReactNode;
    classes?: {
      root?: string;
      wrapper?: string;
    };
    onScroll?: () => void;
  },
  ref: Ref<HTMLDivElement>,
) => (
  <div className={clsx('scroll-container', classes?.root)}>
    <div
      ref={ref}
      className={clsx('scroll-container__wrapper', classes?.wrapper)}
      onScroll={onScroll}
    >
      {children}
    </div>
    {containerChild}
  </div>
);

export default React.forwardRef(ScrollContainer);
