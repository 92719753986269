import React from 'react';
import { useSelector } from 'react-redux';

import {
  selectSequence,
  selectStep,
} from '../../../../../cleanCalibrateSelectors';
import CONFIG from './config';

export default function CalibrateCleanTabContent({
  onCancel,
}: {
  onCancel: () => void;
}) {
  const sequence = useSelector(selectSequence);
  const step = useSelector(selectStep);
  const ContentComponent = CONFIG[sequence][step - 1];

  return <ContentComponent onCancel={onCancel} />;
}
