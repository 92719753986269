import { useDispatch, useSelector } from 'react-redux';

import { generateSatelliteVectorAnalysisMap } from '../../../createAnalysis/createAnalysisSlice';
import {
  selectSelectedUuids,
  selectSettings,
} from '../createStabilityZonesSelectors';
import { RVI_INDEX } from '../../../../helpers/satellite';

export default function useGenerateStabilityZonesVamap() {
  const dispatch = useDispatch();
  const satelliteImageUuids = useSelector(selectSelectedUuids);
  const settings = useSelector(selectSettings);

  return () =>
    dispatch(
      generateSatelliteVectorAnalysisMap({
        index: RVI_INDEX.value,
        satelliteImageUuids,
        ...settings,
      }),
    );
}
