import React, { useEffect, useMemo } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';

import { useAppSelector } from '../../../../../app/store/helpers/functions';
import BackdropLoading from '../../../../../components/Loading/BackdropLoading';
import ScrollContainer from '../../../../../components/ScrollContainer';
import { getView, updateView } from '../../helpers/functions/viewType';
import PanelView from '../../helpers/constants/view';
import {
  selectUpdateInProgress,
  selectProducts,
} from '../../zonesMapSelectors';
import TabsContainer from './TabsContainer';
import DescriptionTab from './DescriptionTab';
import ProductTab from './ProductTab';
import useVamapDistributionData from '../../hooks/useVamapDistributionData';

import './index.scss';

export default function ZonesMapPanel() {
  const location = useLocation();
  const history = useHistory();

  const updateInProgress = useAppSelector(selectUpdateInProgress);
  const { viewType, productIndex } = getView(location.search);
  const products = useAppSelector(selectProducts);
  const { farmUuid, fieldUuid, uuid } = useParams<{
    farmUuid: string;
    fieldUuid: string;
    uuid: string;
  }>();

  useVamapDistributionData({ farmUuid, fieldUuid, uuid });

  useEffect(() => {
    // Check if products items exist, but the requested product doesn't exist
    if (
      viewType === PanelView.product &&
      productIndex != null &&
      products.length > 0 &&
      !products[productIndex]
    ) {
      updateView(location.search, history, {
        type: PanelView.description,
        productIndex: null,
      });
    }
  }, [history, location.search, viewType, productIndex, products]);

  const content = useMemo(() => {
    if (
      viewType === PanelView.product &&
      productIndex != null &&
      products[productIndex]
    ) {
      return <ProductTab />;
    }

    return <DescriptionTab />;
  }, [productIndex, viewType, products]);

  return (
    <div className="zones-map-panel">
      <TabsContainer />
      <ScrollContainer classes={{ root: 'zones-map-panel-content' }}>
        {content}
        {updateInProgress && <BackdropLoading />}
      </ScrollContainer>
    </div>
  );
}
