import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { VectorAnalysisMapType } from '../../../helpers/constants/entities/vectorAnalysisMap';
import {
  goToStep,
  unselectField,
  reset as resetBatchAnalytics,
} from '../batchAnalytics/batchAnalyticsSlice';
import type {
  AppDispatch,
  AppGetState,
} from '../../../app/store/helpers/types';
import type { AssetVariableData, AssignedVariables } from './types/variables';
import type {
  EquationMapData,
  GridSize,
  TitleTemplateItem,
} from './types/equationMap';
import {
  DEFAULT_GRID_SIZE,
  DEFAULT_EQUATION_MAP_TITLE_TEMPLATE,
} from './helpers/constants/equationMap';
import { SortOrder } from './helpers/constants/ui';
import {
  resetCurrentEquation,
  setCurrentEquation,
} from '../../equations/equationsSlice';
import { selectEquationMapsEntitiesList } from '../../assets/assetsSelectors';
import LoadStatus from '../../../helpers/constants/utils/loadStatus';
import { openExportEquationMapsPopup } from '../../exportData/exportDataSlice';
import { selectCurrentEquation } from '../../equations/equationsSelectors';
import { generateEquationMapsArchiveName } from './helpers/functions/equationMap';
import { equationMapGenerationFinished } from '../../assets/assetsSlice';
import { isInvalid as isEquationMapInvalid } from '../../../helpers/functions/entities/equationMap';
import { selectEquationMapsDataByStatuses } from './createBatchEquationBasedAnalysisSelectors';

export interface CreateBatchEquationBasedAnalysisState {
  type: VectorAnalysisMapType;
  gridSize: GridSize;
  equationMapsData: Record<string /* fieldUuid */, EquationMapData>;
  listFilter: {
    searchTerm: string;
    farmUuid: string | null;
    sortOrder: SortOrder;
  };
  editMode: boolean;
  fullScreenEditor: boolean;
  titleTemplate: TitleTemplateItem[];
}

const initialState: CreateBatchEquationBasedAnalysisState = {
  type: VectorAnalysisMapType.general,
  gridSize: {
    x: DEFAULT_GRID_SIZE,
    y: DEFAULT_GRID_SIZE,
  },
  equationMapsData: {},
  listFilter: {
    searchTerm: '',
    farmUuid: null,
    sortOrder: SortOrder.none,
  },
  editMode: false,
  fullScreenEditor: false,
  titleTemplate: DEFAULT_EQUATION_MAP_TITLE_TEMPLATE,
};

export const downloadSuccessEquationMaps =
  () => (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const successEquationMapsData = selectEquationMapsDataByStatuses(state, [
      LoadStatus.success,
    ]);
    const successEquationMapsUuids = successEquationMapsData
      .map(({ uuid }) => uuid)
      .filter((uuid): uuid is string => !!uuid);
    const successEquationMapsEntities = selectEquationMapsEntitiesList(
      state,
      successEquationMapsUuids,
    );
    const { title } = selectCurrentEquation(state);
    const archiveName = generateEquationMapsArchiveName(title);

    dispatch(
      openExportEquationMapsPopup({
        equationMaps: successEquationMapsEntities,
        archiveName,
      }),
    );
  };

export const restartSameFields = () => (dispatch: AppDispatch) => {
  dispatch(reset());
  dispatch(resetCurrentEquation());
  dispatch(goToStep(1));
};

export const restartAnotherFields = () => (dispatch: AppDispatch) => {
  dispatch(reset());
  dispatch(resetCurrentEquation());
  dispatch(resetBatchAnalytics());
};

export const resetBatchEquationBasedAnalysis =
  () => (dispatch: AppDispatch) => {
    dispatch(reset());
    dispatch(resetCurrentEquation());
  };

export const createBatchEquationBasedAnalysisSlice = createSlice({
  name: 'createBatchEquationBasedAnalysis',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    setType(state, action: PayloadAction<VectorAnalysisMapType>) {
      state.type = action.payload;
    },
    setGridXSize(state, action: PayloadAction<number>) {
      state.gridSize.x = action.payload;
    },
    setGridYSize(state, action: PayloadAction<number>) {
      state.gridSize.y = action.payload;
    },
    populatePreviewData(
      state,
      action: PayloadAction<{
        fieldsUuids: string[];
        assignedVariables: Record<string, AssignedVariables>;
      }>,
    ) {
      action.payload.fieldsUuids.forEach((fieldUuid) => {
        const equationMapData = state.equationMapsData[fieldUuid];

        state.equationMapsData[fieldUuid] = {
          ...equationMapData,
          assignedVariables: {
            ...equationMapData?.assignedVariables,
            ...action.payload.assignedVariables[fieldUuid],
          },
          generationStatus:
            equationMapData?.generationStatus ?? LoadStatus.idle,
        };
      });
    },
    setTitles(
      state,
      action: PayloadAction<{
        fieldsUuids: string[];
        titles: Record<string, string>;
      }>,
    ) {
      action.payload.fieldsUuids.forEach((fieldUuid) => {
        state.equationMapsData[fieldUuid] = {
          ...state.equationMapsData[fieldUuid],
          title: action.payload.titles[fieldUuid],
        };
      });
    },
    updateEquationMapsData(
      state,
      action: PayloadAction<
        Record<string, Partial<Omit<EquationMapData, 'assignedVariables'>>>
      >,
    ) {
      for (const [fieldUuid, update] of Object.entries(action.payload)) {
        const equationMapData = state.equationMapsData[fieldUuid];

        state.equationMapsData[fieldUuid] = {
          ...equationMapData,
          title: update.title ?? equationMapData.title,
          gridSize: {
            x: update.gridSize?.x ?? equationMapData.gridSize?.x,
            y: update.gridSize?.y ?? equationMapData.gridSize?.y,
          },
          uuid: update.uuid ?? equationMapData.uuid,
          generationStatus:
            update.generationStatus ?? equationMapData.generationStatus,
        };
      }
    },
    assignEquationMapData(
      state,
      action: PayloadAction<{
        assignedVariable: {
          fieldUuid: string;
          variable: string;
          data: AssetVariableData;
        };
      }>,
    ) {
      const { fieldUuid, variable, data } = action.payload.assignedVariable;
      state.equationMapsData[fieldUuid].assignedVariables[variable] = data;
    },
    setListFilterSearchTerm(state, action: PayloadAction<string>) {
      state.listFilter.searchTerm = action.payload;
    },
    setListFilterFarmUuid(state, action: PayloadAction<string | null>) {
      state.listFilter.farmUuid = action.payload;
    },
    setListFilterSortOrder(state, action: PayloadAction<SortOrder>) {
      state.listFilter.sortOrder = action.payload;
    },
    setTitleTemplate(state, action: PayloadAction<TitleTemplateItem[]>) {
      state.titleTemplate = action.payload;
    },
    setEditMode(state, action: PayloadAction<boolean>) {
      state.editMode = action.payload;
    },
    toggleFullScreenEditor(state) {
      state.fullScreenEditor = !state.fullScreenEditor;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(equationMapGenerationFinished, (state, action) => {
        const equationMapData =
          state.equationMapsData[action.payload.fieldUuid];

        if (equationMapData) {
          state.equationMapsData[action.payload.fieldUuid] = {
            ...equationMapData,
            generationStatus: isEquationMapInvalid(action.payload.equationMap)
              ? LoadStatus.error
              : LoadStatus.success,
          };
        }
      })
      .addCase(unselectField, (state, action) => {
        const { [action.payload]: fieldUuid, ...equationMapsData } =
          state.equationMapsData;

        state.equationMapsData = equationMapsData;
      })
      .addCase(setCurrentEquation, (state) => {
        state.equationMapsData = Object.entries(state.equationMapsData).reduce(
          (acc, [fieldUuid, { title, ...equationMapData }]) => ({
            ...acc,
            [fieldUuid]: {
              ...equationMapData,
              assignedVariables: {},
            },
          }),
          {},
        );
      });
  },
});

export const {
  reset,
  setType,
  setGridXSize,
  setGridYSize,
  setTitles,
  populatePreviewData,
  updateEquationMapsData,
  assignEquationMapData,
  setListFilterSearchTerm,
  setListFilterFarmUuid,
  setListFilterSortOrder,
  setTitleTemplate,
  setEditMode,
  toggleFullScreenEditor,
} = createBatchEquationBasedAnalysisSlice.actions;

export default createBatchEquationBasedAnalysisSlice.reducer;
