export const selectFarm = ({ createField }) => createField.farm;

export const selectName = ({ createField }) => createField.name;

export const selectLabels = ({ createField }) => createField.labels;

export const selectDrawnFeature = ({ createField }) =>
  createField.drawn.feature;

export const selectDrawnError = ({ createField }) => createField.drawn.error;
