import React, { useState } from 'react';

import AdditionalUserDataForm from '../../components/AdditionalUserDataForm';
import Panel from '../../components/AuthenticationPanel';
import { errorNotify } from '../../../../notifications/helpers/functions/notify';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../app/store/helpers/functions';
import { CustomError } from '../../../../../helpers/functions/utils/errorHandling';
import { selectAdditionalUserData } from '../../../../user/userSelectors';
import { updateAdditionalUserData } from '../../../../user/userSlice';
import { AdditionalUserDataFields } from '../../types/ui';
import { tryGetApiErrors } from '../../../../emptyApi/helpers/functions/api';
import { useGetCountiesListQuery } from '../../../../countries/countriesAPI';
import useAuthenticate from '../../hooks/useAuthenticate';

const AdditionalUserDataPanel = () => {
  const [saveInProgress, setSaveInProgress] = useState(false);
  const userData = useAppSelector(selectAdditionalUserData);
  const dispatch = useAppDispatch();
  const { signOut } = useAuthenticate();

  const { data: countriesData, isFetching: countriesIsFetching } =
    useGetCountiesListQuery();

  const handleSave = async (data: AdditionalUserDataFields) => {
    let errors: Error[] | null = null;
    setSaveInProgress(true);

    try {
      // @ts-expect-error
      await dispatch(updateAdditionalUserData(data)).unwrap();
      setSaveInProgress(false);
    } catch (error) {
      errors = tryGetApiErrors(error);
      setSaveInProgress(false);
    }

    return errors;
  };

  const handleSaveUnknownError = (error: Error[]) => {
    errorNotify({
      error: new CustomError(
        '[Additional User Info] Unable to save user data.',
        { cause: error },
      ),
      dispatch,
    });
  };

  return (
    <Panel loading={saveInProgress || countriesIsFetching}>
      <AdditionalUserDataForm
        userData={{
          country: userData.country,
          companyType: userData.companyType,
          acceptedTermsAndConditions: userData.acceptedTermsAndConditions,
          phoneNumber: userData.phoneNumber,
          email: userData.email,
        }}
        countries={countriesData?.countries || []}
        onSave={handleSave}
        onSaveUnknownError={handleSaveUnknownError}
        onLogOutClick={() => signOut()}
      />
    </Panel>
  );
};

export default AdditionalUserDataPanel;
