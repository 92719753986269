import React, { forwardRef, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import FilterIcon from '../FilterIcon';

import './index.scss';

function ExpandFiltersButton(
  {
    isExpanded,
    onClick,
  }: {
    isExpanded: boolean;
    onClick: (isExpanded: boolean) => void;
  },
  ref: Ref<HTMLButtonElement>,
) {
  const { t } = useTranslation();

  return (
    <Button
      ref={ref}
      startIcon={<FilterIcon className="expand-filter-button__icon" />}
      endIcon={isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
      classes={{
        root: 'expand-filter-button',
        startIcon: 'expand-filter-button__start-icon',
        endIcon: 'expand-filter-button__end-icon',
      }}
      onClick={(event) => {
        event.stopPropagation();
        onClick(!isExpanded);
      }}
    >
      {t('general.controls.filters.filter')}
    </Button>
  );
}

export default forwardRef(ExpandFiltersButton);
