const updateEntity = <T>(
  update: Partial<T>,
  entities: T[],
  condition: (e: T) => boolean,
) =>
  entities.map((entity) => {
    if (condition(entity)) {
      return {
        ...entity,
        ...update,
      };
    }

    return entity;
  });

export default updateEntity;
