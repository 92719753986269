import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { errorNotify } from '../../notifications/helpers/functions/notify';
import { CustomError } from '../../../helpers/functions/utils/errorHandling';
import FieldPipelineStatus from './helpers/constants/fieldPipelineStatus';
import { PINS_GROUPS_EXCEPTION_TO_STATUS_MAP } from './helpers/constants/pinsGroupsExceptions';
import {
  generatePinsGroups as generatePinsGroupsAPI,
  generateZonesMaps as generateZonesMapsAPI,
} from './smartSamplingAPI';
import {
  selectFieldStatus,
  selectZonesMapsAsync,
} from './smartSamplingSelectors';

const initialState = {
  /**
   * [fieldUuid: string]: FIELDS_STATUSES
   */
  fieldsStatuses: {},
  /**
   * [fieldUuid: string]: {
   *  uuid: string,
   *  fieldUuid: string,
   *  statusCode: 202 | 204 | 208 | 500,
   *  finished?: boolean,
   * }[]
   */
  zonesMapsAsync: {},
};

export const generatePinsGroups = createAsyncThunk(
  'smartSampling/generatePinsGroups',
  ({ fieldUuid }, { dispatch }) =>
    generatePinsGroupsAPI(fieldUuid)
      .then(() => ({
        status: FieldPipelineStatus.pinsGroupsCreated,
      }))
      .catch(({ errors }) => {
        const error = errors[0].errorType;
        const status = PINS_GROUPS_EXCEPTION_TO_STATUS_MAP[error];

        if (!status) {
          errorNotify({
            error: new CustomError(
              '[Smart Sampling] Unable to generate pins groups.',
              {
                cause: error,
              },
            ),
            dispatch,
          });

          throw error;
        }

        if (status === FieldPipelineStatus.vamapsOrdering) {
          void dispatch(
            generateZonesMaps({
              fieldUuid,
            }),
          );
        }

        return {
          status,
        };
      }),
);

export const generateZonesMaps = createAsyncThunk(
  'smartSampling/generateZonesMaps',
  ({ fieldUuid }, { dispatch }) =>
    generateZonesMapsAPI(fieldUuid)
      .then((zonesMaps) => {
        const orderedZonesMaps = zonesMaps.reduce((acc, zonesMap) => {
          if (zonesMap.statusCode === 500) {
            throw zonesMap;
          }

          if (zonesMap.statusCode === 202) {
            acc.push(zonesMap);
          }

          return acc;
        }, []);

        return {
          orderedZonesMaps,
        };
      })
      .catch((error) => {
        errorNotify({
          error: new CustomError(
            '[Smart Sampling] Unable to generate zones maps.',
            {
              cause: error,
            },
          ),
          dispatch,
        });

        throw error;
      }),
);

export const subscription = (parsedEvent) => (dispatch, getState) => {
  const { action, pathLength, fieldUuid, vectorAnalysisMapUuid } = parsedEvent;

  if (action !== 'INSERT' || !vectorAnalysisMapUuid || pathLength !== 3) {
    return;
  }

  const zonesMapsAsync = selectZonesMapsAsync(getState(), fieldUuid);

  if (!zonesMapsAsync) {
    return;
  }

  const hasFinishedZonesMap = zonesMapsAsync.some(
    ({ uuid }) => uuid === vectorAnalysisMapUuid,
  );

  if (hasFinishedZonesMap) {
    dispatch(
      finishZonesMap({
        fieldUuid,
        zonesMapUuid: vectorAnalysisMapUuid,
      }),
    );
  }

  const state = getState();
  const updatedZonesMapsAsync = selectZonesMapsAsync(state, fieldUuid);
  const fieldsStatus = selectFieldStatus(state, fieldUuid);

  const zonesMapsCreated = updatedZonesMapsAsync.every(
    ({ finished }) => finished,
  );

  if (zonesMapsCreated && fieldsStatus === FieldPipelineStatus.vamapsOrdered) {
    dispatch(
      generatePinsGroups({
        fieldUuid,
      }),
    );
  }
};

export const smartSamplingSlice = createSlice({
  name: 'smartSampling',
  initialState,
  reducers: {
    finishZonesMap(state, action) {
      state.zonesMapsAsync[action.payload.fieldUuid] = state.zonesMapsAsync[
        action.payload.fieldUuid
      ].map((zonesMapAsync) => {
        if (zonesMapAsync.uuid === action.payload.zonesMapUuid) {
          return {
            ...zonesMapAsync,
            finished: true,
          };
        }

        return zonesMapAsync;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generatePinsGroups.pending, (state, action) => {
        state.fieldsStatuses[action.meta.arg.fieldUuid] =
          FieldPipelineStatus.pinsGroupsOrdering;
      })
      .addCase(generatePinsGroups.fulfilled, (state, action) => {
        state.fieldsStatuses[action.meta.arg.fieldUuid] = action.payload.status;
      })
      .addCase(generatePinsGroups.rejected, (state, action) => {
        state.fieldsStatuses[action.meta.arg.fieldUuid] =
          FieldPipelineStatus.failed;
      })
      .addCase(generateZonesMaps.fulfilled, (state, action) => {
        if (action.payload.orderedZonesMaps.length !== 0) {
          state.zonesMapsAsync[action.meta.arg.fieldUuid] =
            action.payload.orderedZonesMaps;
          state.fieldsStatuses[action.meta.arg.fieldUuid] =
            FieldPipelineStatus.vamapsOrdered;
        }
      })
      .addCase(generateZonesMaps.rejected, (state, action) => {
        state.fieldsStatuses[action.meta.arg.fieldUuid] =
          FieldPipelineStatus.failed;
      });
  },
});

export const { finishZonesMap } = smartSamplingSlice.actions;

export default smartSamplingSlice.reducer;
