import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { GeoFormat } from '../../../helpers/constants/api';
import { errorNotify } from '../../notifications/helpers/functions/notify';
import { CustomError } from '../../../helpers/functions/utils/errorHandling';
import { openPopup } from '../popups/popupsSlice';
import { fetchAssetFeatures } from '../../field/fieldAPI';
import { selectFarmUuid, selectFieldUuid } from '../../field/fieldSelectors';
import { save3dMap } from './createThreeDMapAPI';
import {
  selectAssetType,
  selectSettings,
  selectShape,
  selectZonesMapUuid,
} from './createThreeDMapSelectors';
import { AssetType } from '../../../helpers/constants/entities/asset';

const initialState = {
  loading: false,
  assetType: '',
  shape: {
    uuid: '',
    attribute: '',
    viewType: null,
    pointsStored: false,
  },
  settings: {
    name: '',
    exaggeration: 0,
  },
  zonesMapUuid: null,
};

export const fetchShapePoints = createAsyncThunk(
  'createThreeDMap/fetchShapePoints',
  async (_payload, { getState, dispatch }) => {
    const state = getState();
    const fieldUuid = selectFieldUuid(state);
    const farmUuid = selectFarmUuid(state);
    const assetType = selectAssetType(state);
    const { uuid } = selectShape(state);

    return fetchAssetFeatures({
      farmUuid,
      fieldUuid,
      assetType,
      uuid,
      format: GeoFormat.geojson,
    })
      .then((response) => response.json())
      .then((points) => ({
        points,
      }))
      .catch((error) => {
        errorNotify({
          error: new CustomError(
            '[UI Create 3D] Unable to fetch source data.',
            {
              cause: error,
            },
          ),
          dispatch,
        });

        throw error;
      });
  },
);

export const save = createAsyncThunk(
  'createThreeDMap/save',
  async (_payload, { getState, dispatch }) => {
    const state = getState();
    const fieldUuid = selectFieldUuid(state);
    const assetType = selectAssetType(state);
    const { name, exaggeration } = selectSettings(state);
    const vectorAnalysisMapUuid = selectZonesMapUuid(state);
    const { uuid, attribute } = selectShape(state);

    const dataSourceLayer = {
      factor: exaggeration,
    };

    if (assetType === AssetType.soilDataset) {
      dataSourceLayer.soilAttribute = attribute;
      dataSourceLayer.soilDatasetUuid = uuid;
    } else if (assetType === AssetType.yieldDataset) {
      dataSourceLayer.yieldAttribute = attribute;
      dataSourceLayer.yieldDatasetUuid = uuid;
    } else if (assetType === AssetType.topographyMap) {
      dataSourceLayer.topographyAttribute = attribute;
      dataSourceLayer.topographyMapUuid = uuid;
    }

    return save3dMap({
      fieldUuid,
      vectorAnalysisMapUuid,
      name,
      dataSourceLayer,
    })
      .then((map3d) => {
        dispatch(
          openPopup({
            type: 'save-3d-map',
            name,
            map3dUuid: map3d.uuid,
          }),
        );

        return map3d;
      })
      .catch((error) => {
        errorNotify({
          error: new CustomError('[UI Create 3D] Unable to save 3d map.', {
            cause: error,
          }),
          dispatch,
        });

        throw error;
      });
  },
);

export const createThreeDMapSlice = createSlice({
  name: 'createThreeDMap',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    setViewType(state, action) {
      state.shape.viewType = action.payload;
    },
    setAssetType(state, action) {
      state.assetType = action.payload;
      state.shape = initialState.shape;
    },
    setShapeData(state, action) {
      state.shape.attribute = action.payload.attribute;

      if (action.payload.uuid !== state.shape.uuid) {
        state.shape.uuid = action.payload.uuid;
        state.shape.pointsStored = false;
      }
    },
    setZonesMapUuid(state, action) {
      state.zonesMapUuid = action.payload;
    },
    updateSettings(state, action) {
      state.settings[action.payload.prop] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShapePoints.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchShapePoints.fulfilled, (state) => {
        state.shape.pointsStored = true;
        state.loading = false;
      })
      .addCase(fetchShapePoints.rejected, (state) => {
        state.loading = false;
      })
      .addCase(save.pending, (state) => {
        state.loading = true;
      })
      .addCase(save.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(save.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  reset,
  setViewType,
  setAssetType,
  setShapeData,
  setZonesMapUuid,
  updateSettings,
} = createThreeDMapSlice.actions;

export default createThreeDMapSlice.reducer;
