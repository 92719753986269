import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import { captureException } from './helpers/functions/utils/errorHandling';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    backend: {
      loadPath: `./locales/{{lng}}.json?v=${BUILD_TIMESTAMP}`,
    },
    lng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })
  .catch((error) => {
    captureException({ error });
  });

export default i18n;
