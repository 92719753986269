import { API, graphqlOperation } from '@aws-amplify/api';

import importFromJohnDeereAsync from './graphql/mutations/importFromJohnDeereAsync.gql';
import importAllFromJohnDeereAsync from './graphql/mutations/importAllFromJohnDeereAsync.gql';
import getFieldsOperationsJohnDeereQuery from './graphql/queries/getFieldsOperationsJohnDeere.gql';

export const importData = async ({
  fields,
  importType,
  fieldOperationTypes,
  cropSeasons,
}) => {
  const response = await API.graphql(
    graphqlOperation(importFromJohnDeereAsync, {
      input: {
        fields,
        importType,
        fieldOperationTypes,
        cropSeasons,
      },
    }),
  );

  const { status = '' } = response.data.importFromJohnDeereAsync;

  return { status };
};

export const importAllData = async ({
  organizationsIds,
  fieldName,
  importType,
  fieldOperationTypes,
  cropSeasons,
}) => {
  const response = await API.graphql(
    graphqlOperation(importAllFromJohnDeereAsync, {
      input: {
        organizationsIds,
        fieldName,
        importType,
        fieldOperationTypes,
        cropSeasons,
      },
    }),
  );

  const { status = '' } = response.data.importAllFromJohnDeereAsync;

  return { status };
};

export const getFieldsOperations = async (fieldOperationFilter) => {
  const response = await API.graphql(
    graphqlOperation(getFieldsOperationsJohnDeereQuery, {
      fieldOperationFilter,
    }),
  );

  return response.data.getFieldsOperationsJohnDeere.fields;
};
