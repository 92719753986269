query getFarms {
  getFarms {
    uuid
    name
    farmOwnership
    userUuid
    email
    ownerEmail
    usersEmails
  }
}
