#import "../fragments/asAppliedDataset.gql"
#import "../fragments/soilDataset.gql"
#import "../fragments/topographyMap.gql"
#import "../fragments/yieldDataset.gql"
#import "../fragments/vectorAnalysisMap.gql"
#import "../fragments/equationMap.gql"

query getFieldAllAssets(
  $farmUuids: [ID]
  $fieldUuids: [ID]
  $areaUnit: AreaUnit!
) {
  getFarms(farmUuids: $farmUuids) {
    uuid
    fields(fieldUuids: $fieldUuids) {
      uuid
      farmUuid
      name
      status
      farmUuid
      boundaryUrl
      boundingBox
      geoMaps {
        shortName
        url
      }
      area(unit: $areaUnit)
      centroid {
        latitude
        longitude
      }
      createdDate
      vectorAnalysisMaps(statuses: [EXECUTED, SAVED]) {
        ...vectorAnalysisMapFields
      }
      equationMaps {
        ...equationMapFields
      }
      threeDimensionalMaps {
        uuid
        name
        createdDate
        updatedDate
        dataSourceLayer {
          factor
          satelliteImages {
            satelliteImage {
              uuid
            }
          }
          index
          soilDataset {
            uuid
          }
          soilAttribute
          yieldDataset {
            uuid
          }
          yieldAttribute
          asAppliedDataset {
            uuid
          }
          asAppliedAttribute
          topographyMap {
            uuid
          }
          topographyAttribute
        }
        vectorAnalysisMap {
          uuid
        }
      }
      soilDatasets {
        ...soilDatasetFields
      }
      yieldDatasets {
        ...yieldDatasetFields
      }
      asAppliedDatasets {
        ...asAppliedDatasetFields
      }
      topographyMaps(statuses: [EXECUTED]) {
        ...topographyMapFields
      }
      labels {
        name
        value
      }
      notes {
        uuid
        fieldUuid
        centroid {
          latitude
          longitude
        }
        createdDate
        updatedDate
        type
        title
        completed
        comment
        photos {
          uuid
          noteUuid
          commentUuid
          url
          createdDate
          updatedDate
        }
        comments {
          uuid
          createdDate
          text
          photos {
            uuid
            noteUuid
            commentUuid
            url
            createdDate
            updatedDate
          }
        }
        vectorAnalysisMap {
          uuid
        }
        soilDataset {
          uuid
        }
      }
    }
  }
}
