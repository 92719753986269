query getField($farmUuids: [ID], $fieldUuids: [ID], $areaUnit: AreaUnit!) {
  getFarms(farmUuids: $farmUuids) {
    uuid
    fields(fieldUuids: $fieldUuids) {
      uuid
      farmUuid
      name
      area(unit: $areaUnit)
      centroid {
        latitude
        longitude
      }
      boundaryUrl
      boundingBox
      geoMaps {
        shortName
        url
      }
    }
  }
}
