import i18n from 'i18next';

import type { TransformedSatelliteImage } from '../../../../satelliteImages/types/satelliteImage';
import type { TransformedVectorAnalysisMap } from '../../../../../helpers/types/vectorAnalysisMap';
import type {
  TransformedAsAppliedDataset,
  TransformedDataset,
  TransformedSoilDataset,
  TransformedTopographyMap,
  TransformedYieldDataset,
} from '../../../../../helpers/types/dataset';
import type { TransformedThreeDimensionalMap } from '../../../../../helpers/types/threeDimensionalMap';
import type { TransformedEquationMap } from '../../../../../helpers/types/equationMap';
import type { PinsGroup } from '../../../../pins/types';
import type { UnknownWithUuid } from '../../../../../helpers/types/shared';
import type {
  DataLayers,
  DatasetDataLayers,
  DataLayersTreeNode,
  SatelliteDataLayers,
} from '../../types/dataLayersTree';
import { AssetGroupType } from '../../../../../helpers/constants/entities/asset';
import {
  getDatasetAttributeNameHandler,
  getDatasetViewProps,
  getDatasetViewTypes,
} from '../../../../../helpers/functions/entities/dataset';
import {
  getTopographyMapAttributeNameHandler,
  getTopographyMapViewProps,
} from '../../../../../helpers/functions/entities/topographyMap';
import {
  datasetsComparator,
  datasetsGroupComparator,
  nameComparator,
} from './comparator';
import {
  generateAssetNodeId,
  generateAttributeNodeId,
  generateFilterNodeId,
} from './dataLayersTree';
import { CustomError } from '../../../../../helpers/functions/utils/errorHandling';
import { GEOMAP_CONTROL_ITEMS } from '../../../../../helpers/constants/entities/equationMap';
import { getSatelliteImageViewProps } from '../../../../../helpers/functions/entities/satelliteImage';
import { VIEW_TO_I18N_LABEL } from '../../../../../helpers/constants/entities/dataset';

const getGroupPlaceholderNodes = ({
  assetGroupType,
  renderer,
  parentNode,
}: {
  assetGroupType: AssetGroupType;
  renderer: DataLayers<AssetGroupType>['renderer'];
  parentNode: DataLayersTreeNode | null;
}): DataLayersTreeNode[] => {
  const id = `${assetGroupType}_placeholder`;

  return [
    {
      id,
      getElement: (offset) => renderer(assetGroupType, id, offset),
      parent: parentNode,
      type: 'placeholder',
      assetGroupType,
      selectable: false,
    },
  ];
};

const getAssetGroupNode = ({
  assetGroupType,
  renderer,
}: {
  assetGroupType: AssetGroupType;
  renderer: DataLayers<AssetGroupType>['renderer'];
}): DataLayersTreeNode => {
  const id = assetGroupType;

  return {
    id,
    getElement: (offset) => renderer(assetGroupType, id, offset),
    parent: null,
    type: 'group',
    assetGroupType,
    selectable: true,
  };
};

const getDatasetViewTypeNodes = <T extends TransformedDataset>({
  dataset,
  renderer,
  parentNode,
  withAttributes,
  selectable = true,
  attributeRenderer,
}: {
  dataset: T;
  parentNode: DataLayersTreeNode;
  withAttributes?: boolean;
  selectable?: boolean;
  renderer: NonNullable<
    DatasetDataLayers<T>['withDatasetViewType']
  >['renderer'];
  attributeRenderer?: NonNullable<
    DatasetDataLayers<T>['withAttributes']
  >['renderer'];
}): DataLayersTreeNode[] => {
  const viewTypes = getDatasetViewTypes(dataset);

  return viewTypes.map((viewType) => {
    const id = generateAttributeNodeId(dataset.uuid, viewType);
    const title = i18n.t(VIEW_TO_I18N_LABEL[viewType]);

    const node: DataLayersTreeNode = {
      id,
      getElement: (offset) => renderer(dataset.assetType, id, title, offset),
      parent: parentNode,
      type: 'attribute',
      selectable,
      assetGroupType: parentNode.assetGroupType,
    };

    if (withAttributes && attributeRenderer) {
      const attributesNodes = getDatasetAttributesNodes({
        dataset,
        renderer: attributeRenderer,
        parentNode: node,
        viewType,
        selectable,
      });

      node.children = attributesNodes;
    }

    return node;
  });
};

const getDatasetAttributesNodes = <T extends TransformedDataset>({
  dataset,
  renderer,
  parentNode,
  viewType,
  selectable = true,
}: {
  dataset: T;
  renderer: NonNullable<DatasetDataLayers<T>['withAttributes']>['renderer'];
  parentNode: DataLayersTreeNode;
  viewType?: string;
  selectable?: boolean;
}): DataLayersTreeNode[] => {
  const { attributes } = getDatasetViewProps(dataset);
  const nameHandler = getDatasetAttributeNameHandler(dataset.fullAttributes);

  return attributes.map((attribute: string) => {
    const id = generateAttributeNodeId(dataset.uuid, attribute, viewType);
    const name = nameHandler(attribute);

    return {
      id,
      getElement: (offset) => renderer(dataset.assetType, id, name, offset),
      parent: parentNode,
      type: 'attribute',
      selectable,
      assetGroupType: parentNode.assetGroupType,
      attributes: {
        attribute,
        viewType,
      },
    } as DataLayersTreeNode;
  });
};

const getTopographyAttributesNodes = ({
  dataset,
  renderer,
  parentNode,
  selectable = true,
}: {
  dataset: TransformedTopographyMap;
  renderer: NonNullable<
    DatasetDataLayers<TransformedTopographyMap>['withAttributes']
  >['renderer'];
  parentNode: DataLayersTreeNode;
  selectable?: boolean;
}): DataLayersTreeNode[] => {
  const { attributes } = getTopographyMapViewProps(dataset);
  const nameHandler = getTopographyMapAttributeNameHandler(
    dataset.fullAttributes,
  );

  return attributes.map((viewType: string) => {
    const id = generateAttributeNodeId(dataset.uuid, viewType);
    const name = nameHandler(viewType);

    return {
      id,
      getElement: (offset) => renderer(dataset.assetType, id, name, offset),
      parent: parentNode,
      type: 'attribute',
      assetGroupType: parentNode.assetGroupType,
      selectable,
      attributes: {
        attribute: viewType,
      },
    };
  });
};

const getSatelliteImageAttributesNodes = ({
  image,
  renderer,
  parentNode,
  selectable = true,
}: {
  image: TransformedSatelliteImage;
  renderer: NonNullable<
    DatasetDataLayers<TransformedSatelliteImage>['withAttributes']
  >['renderer'];
  parentNode: DataLayersTreeNode;
  selectable?: boolean;
}): DataLayersTreeNode[] => {
  const { viewTypes } = getSatelliteImageViewProps({ image });

  return viewTypes.map((viewType: string) => {
    const id = generateAttributeNodeId(image.uuid, viewType);
    const title = viewType.toUpperCase();

    return {
      id,
      getElement: (offset) => renderer(image.assetType, id, title, offset),
      parent: parentNode,
      type: 'attribute',
      assetGroupType: parentNode.assetGroupType,
      selectable,
      attributes: {
        attribute: viewType,
      },
    } as DataLayersTreeNode;
  });
};

const getSatelliteImagesNodes = ({
  items,
  renderer,
  parentNode,
  assetGroupType,
  withAttributes,
  isItemSelectable = () => true,
}: {
  items: TransformedSatelliteImage[];
  renderer: DataLayers<TransformedSatelliteImage>['renderer'];
  parentNode: DataLayersTreeNode | null;
  assetGroupType: AssetGroupType;
  withAttributes?: DataLayers<TransformedSatelliteImage>['withAttributes'];
  isItemSelectable?: (item: TransformedSatelliteImage) => boolean;
}): DataLayersTreeNode[] =>
  items.map((item) => {
    const id = generateAssetNodeId(item.uuid, assetGroupType);
    const selectable = isItemSelectable(item);

    const itemNode: DataLayersTreeNode = {
      id,
      getElement: (offset) => renderer(item, id, offset),
      parent: parentNode,
      type: 'asset',
      assetGroupType,
      selectable,
      uuid: item.uuid,
    };

    if (withAttributes?.enabled) {
      const attributesNodes = getSatelliteImageAttributesNodes({
        image: item,
        renderer: withAttributes.renderer,
        parentNode: itemNode,
        selectable,
      });

      itemNode.children = attributesNodes;
    }

    return itemNode;
  });

const getAssetsNodes = <T extends UnknownWithUuid>({
  items,
  renderer,
  parentNode,
  assetGroupType,
  isItemSelectable = () => true,
}: {
  items: T[];
  renderer: DataLayers<T>['renderer'];
  parentNode: DataLayersTreeNode | null;
  assetGroupType: AssetGroupType;
  isItemSelectable?: (item: T) => boolean;
}): DataLayersTreeNode[] =>
  items.map((item) => {
    const id = generateAssetNodeId(item.uuid, assetGroupType);

    return {
      id,
      getElement: (offset) => renderer(item, id, offset),
      parent: parentNode,
      type: 'asset',
      assetGroupType,
      selectable: isItemSelectable(item),
      uuid: item.uuid,
    };
  });

const getSortedAssetsNodes = <T extends UnknownWithUuid & { name?: string }>({
  items,
  renderer,
  parentNode,
  assetGroupType,
  isItemSelectable = () => true,
}: {
  items: T[];
  renderer: DataLayers<T>['renderer'];
  parentNode: DataLayersTreeNode | null;
  assetGroupType: AssetGroupType;
  isItemSelectable?: (item: T) => boolean;
}): DataLayersTreeNode[] =>
  getAssetsNodes({
    items: [...items].sort(nameComparator),
    renderer,
    parentNode,
    assetGroupType,
    isItemSelectable,
  });

const getEquationMapAttributesNodes = ({
  equationMap,
  renderer,
  parentNode,
  selectable,
}: {
  equationMap: TransformedEquationMap;
  renderer: NonNullable<
    DatasetDataLayers<TransformedEquationMap>['withAttributes']
  >['renderer'];
  parentNode: DataLayersTreeNode;
  selectable?: boolean;
}): DataLayersTreeNode[] =>
  GEOMAP_CONTROL_ITEMS.map((geoMapType) => {
    const id = generateAttributeNodeId(equationMap.uuid, geoMapType.value);

    return {
      id,
      getElement: (offset) =>
        renderer(equationMap.assetType, id, geoMapType.title, offset),
      parent: parentNode,
      type: 'attribute',
      assetGroupType: parentNode.assetGroupType,
      attributes: {
        attribute: geoMapType.value,
      },
      selectable,
    } as DataLayersTreeNode;
  });

const getEquationMapNodes = ({
  items,
  renderer,
  parentNode,
  assetGroupType,
  withAttributes,
  isItemSelectable = () => true,
}: {
  items: TransformedEquationMap[];
  renderer: DataLayers<TransformedEquationMap>['renderer'];
  parentNode: DataLayersTreeNode | null;
  assetGroupType: AssetGroupType;
  withAttributes?: DatasetDataLayers<TransformedEquationMap>['withAttributes'];
  isItemSelectable?: (item: TransformedEquationMap) => boolean;
}): DataLayersTreeNode[] => {
  const sortedItems = [...items].sort(nameComparator);

  return sortedItems.map((item) => {
    const id = generateAssetNodeId(item.uuid, assetGroupType);
    const selectable = isItemSelectable(item);

    const itemNode: DataLayersTreeNode = {
      id,
      getElement: (offset) => renderer(item, id, offset),
      parent: parentNode,
      type: 'asset',
      assetGroupType,
      selectable,
      uuid: item.uuid,
    };

    if (withAttributes?.enabled) {
      const attributesNode = getEquationMapAttributesNodes({
        equationMap: item,
        renderer: withAttributes.renderer,
        parentNode: itemNode,
        selectable,
      });

      itemNode.children = attributesNode;
    }

    return itemNode;
  });
};

const getDatasetsNodes = <T extends TransformedDataset>({
  items,
  renderer,
  parentNode,
  withAttributes,
  withDatasetViewType,
  assetGroupType,
  grouping,
  isItemSelectable = () => true,
}: {
  items: T[];
  renderer: DatasetDataLayers<T>['renderer'];
  parentNode: DataLayersTreeNode | null;
  withAttributes?: DatasetDataLayers<T>['withAttributes'];
  withDatasetViewType?: DatasetDataLayers<T>['withDatasetViewType'];
  assetGroupType: AssetGroupType;
  grouping?: {
    enabled: boolean;
  };
  isItemSelectable?: (item: T) => boolean;
}): DataLayersTreeNode[] => {
  if (
    (withAttributes?.enabled || withDatasetViewType?.enabled) &&
    grouping?.enabled
  ) {
    throw new CustomError(
      '[generateDataLayersTree] grouping and withAttributes or withDatasetViewType cannot be applied simultaneously.',
    );
  }

  let datasetsNodes;

  if (grouping?.enabled) {
    const datasetsByOperationId = items.reduce<Record<string, T[]>>(
      (acc, dataset) => {
        if (dataset.operationId) {
          if (acc[dataset.operationId]) {
            acc[dataset.operationId].push(dataset);
          } else {
            acc[dataset.operationId] = [dataset];
          }
        }

        return acc;
      },
      {},
    );

    const { groupsParentDatasets, groupsChildrenDatasets } = Object.values(
      datasetsByOperationId,
    ).reduce<{
      groupsParentDatasets: T[];
      groupsChildrenDatasets: Record<string, T[]>;
    }>(
      (acc, datasets) => {
        const sortedDatasets = [...datasets].sort(datasetsGroupComparator);
        const groupParentDataset = sortedDatasets.shift();

        if (groupParentDataset) {
          acc.groupsChildrenDatasets[groupParentDataset.uuid] = sortedDatasets;
          acc.groupsParentDatasets.push(groupParentDataset);
        }

        return acc;
      },
      {
        groupsParentDatasets: [],
        groupsChildrenDatasets: {},
      },
    );
    const individualDatasets = items.filter(({ operationId }) => !operationId);

    datasetsNodes = [...groupsParentDatasets, ...individualDatasets]
      .sort(datasetsComparator)
      .map<DataLayersTreeNode>((dataset) => {
        let node: DataLayersTreeNode;
        const groupChildren = groupsChildrenDatasets[dataset.uuid];
        const id = generateAssetNodeId(dataset.uuid, assetGroupType);

        if (groupChildren?.length) {
          node = {
            id,
            getElement: (offset) =>
              renderer(dataset, id, offset, {
                grouping: {
                  memberType: 'parent',
                  groupAmount: groupChildren.length,
                },
              }),
            parent: parentNode,
            type: 'asset',
            assetGroupType,
            uuid: dataset.uuid,
          };

          node.children = groupChildren.map<DataLayersTreeNode>(
            (childDataset) => {
              const childDatasetId = generateAssetNodeId(
                childDataset.uuid,
                assetGroupType,
              );

              return {
                id: childDatasetId,
                getElement: (offset) =>
                  renderer(childDataset, childDatasetId, offset, {
                    grouping: {
                      memberType: 'child',
                    },
                  }),
                parent: node,
                type: 'asset',
                assetGroupType,
                uuid: childDataset.uuid,
              };
            },
          );
        } else {
          node = {
            id,
            getElement: (offset) => renderer(dataset, id, offset),
            parent: parentNode,
            type: 'asset',
            assetGroupType,
            uuid: dataset.uuid,
          };
        }

        return node;
      });
  } else {
    datasetsNodes = [...items].sort(datasetsComparator).map((dataset) => {
      const id = generateAssetNodeId(dataset.uuid, assetGroupType);
      const selectable = isItemSelectable(dataset);
      const datasetNode: DataLayersTreeNode = {
        id,
        getElement: (offset) => renderer(dataset, id, offset),
        parent: parentNode,
        type: 'asset',
        assetGroupType,
        selectable,
        uuid: dataset.uuid,
      };

      if (withDatasetViewType?.enabled) {
        const viewTypeNodes = getDatasetViewTypeNodes({
          dataset,
          renderer: withDatasetViewType.renderer,
          parentNode: datasetNode,
          withAttributes: withAttributes?.enabled,
          attributeRenderer: withAttributes?.renderer,
          selectable,
        });

        datasetNode.children = viewTypeNodes;
      } else if (withAttributes?.enabled) {
        const attributesNodes = getDatasetAttributesNodes({
          dataset,
          renderer: withAttributes.renderer,
          parentNode: datasetNode,
          selectable,
        });

        datasetNode.children = attributesNodes;
      }

      return datasetNode;
    });
  }

  return datasetsNodes;
};

const getTopographyNodes = ({
  items,
  renderer,
  parentNode,
  withAttributes,
  isItemSelectable = () => true,
}: {
  items: TransformedTopographyMap[];
  renderer: DatasetDataLayers<TransformedTopographyMap>['renderer'];
  parentNode: DataLayersTreeNode | null;
  withAttributes?: DatasetDataLayers<TransformedTopographyMap>['withAttributes'];
  isItemSelectable?: (item: TransformedTopographyMap) => boolean;
}): DataLayersTreeNode[] => {
  const topographyNodes = [...items].sort(nameComparator).map((topography) => {
    const id = generateAssetNodeId(
      topography.uuid,
      AssetGroupType.topographyMaps,
    );
    const selectable = isItemSelectable(topography);
    const topographyNode: DataLayersTreeNode = {
      id,
      getElement: (offset) => renderer(topography, id, offset),
      parent: parentNode,
      type: 'asset',
      assetGroupType: AssetGroupType.topographyMaps,
      uuid: topography.uuid,
      selectable,
    };

    if (withAttributes?.enabled) {
      const attributesNodes = getTopographyAttributesNodes({
        dataset: topography,
        renderer: withAttributes.renderer,
        parentNode: topographyNode,
        selectable,
      });

      topographyNode.children = attributesNodes;
    }

    return topographyNode;
  });

  return topographyNodes;
};

const getFilterNode = ({
  renderer,
  parentNode,
  assetGroupType,
}: {
  renderer: DataLayers<AssetGroupType>['filterRenderer'];
  parentNode: DataLayersTreeNode | null;
  assetGroupType: AssetGroupType;
}): DataLayersTreeNode => ({
  id: generateFilterNodeId(assetGroupType),
  getElement: renderer,
  parent: parentNode,
  type: 'filter',
  assetGroupType,
  selectable: false,
});

const getRecommendedImagesNode = ({
  parentNode,
  renderer,
}: {
  renderer: SatelliteDataLayers<TransformedSatelliteImage>['recommendedImages']['renderer'];
  parentNode: DataLayersTreeNode | null;
}): DataLayersTreeNode => ({
  id: 'recommended_images',
  getElement: renderer,
  parent: parentNode,
  type: 'recommendedImages',
  assetGroupType: AssetGroupType.satelliteImages,
  selectable: false,
});

const generateDataLayersTree = ({
  satelliteImages,
  vectorAnalysisMaps,
  soilDatasets,
  yieldDatasets,
  asAppliedDatasets,
  topographyMaps,
  threeDimensionalMaps,
  equationMaps,
  assetGroup,
  pinsGroups,
  groupPlaceholder,
}: {
  satelliteImages?: SatelliteDataLayers<TransformedSatelliteImage>;
  vectorAnalysisMaps?: DataLayers<TransformedVectorAnalysisMap>;
  soilDatasets?: DatasetDataLayers<TransformedSoilDataset>;
  yieldDatasets?: DatasetDataLayers<TransformedYieldDataset>;
  asAppliedDatasets?: DatasetDataLayers<TransformedAsAppliedDataset>;
  topographyMaps?: DatasetDataLayers<TransformedTopographyMap>;
  threeDimensionalMaps?: DataLayers<TransformedThreeDimensionalMap>;
  equationMaps?: DataLayers<TransformedEquationMap>;
  pinsGroups?: DataLayers<PinsGroup>;
  assetGroup: {
    renderer: DataLayers<AssetGroupType>['renderer'];
  };
  groupPlaceholder: {
    renderer: DataLayers<AssetGroupType>['renderer'];
    enabled: boolean;
  };
}): DataLayersTreeNode[] => {
  const result: DataLayersTreeNode[] = [];

  if (
    satelliteImages?.assets?.length ||
    groupPlaceholder?.enabled ||
    satelliteImages?.satelliteImagesLoading
  ) {
    const assetGroupParentNode = getAssetGroupNode({
      assetGroupType: AssetGroupType.satelliteImages,
      renderer: assetGroup.renderer,
    });

    let assetGroupNodes: DataLayersTreeNode[] = [];

    if (satelliteImages?.filteredAssets?.length) {
      assetGroupNodes = getSatelliteImagesNodes({
        items: satelliteImages.filteredAssets,
        renderer: satelliteImages.renderer,
        parentNode: assetGroupParentNode,
        assetGroupType: AssetGroupType.satelliteImages,
        withAttributes: satelliteImages.withAttributes,
      });
    } else if (groupPlaceholder?.enabled || satelliteImages?.assets?.length) {
      assetGroupNodes = getGroupPlaceholderNodes({
        assetGroupType: AssetGroupType.satelliteImages,
        renderer: groupPlaceholder.renderer,
        parentNode: assetGroupParentNode,
      });
    }

    if (satelliteImages?.recommendedImages.enabled) {
      const recommendedImagesNode = getRecommendedImagesNode({
        renderer: satelliteImages.recommendedImages.renderer,
        parentNode: assetGroupParentNode,
      });

      assetGroupNodes = [recommendedImagesNode, ...assetGroupNodes];
    }

    if (satelliteImages?.filterRenderer) {
      const filterNode = getFilterNode({
        renderer: satelliteImages?.filterRenderer,
        parentNode: assetGroupParentNode,
        assetGroupType: AssetGroupType.satelliteImages,
      });

      assetGroupNodes = [filterNode, ...assetGroupNodes];
    }

    if (assetGroupParentNode) {
      assetGroupParentNode.children = assetGroupNodes;
      result.push(assetGroupParentNode);
    } else {
      result.push(...assetGroupNodes);
    }
  }

  if (vectorAnalysisMaps?.assets?.length || groupPlaceholder?.enabled) {
    const assetGroupParentNode = getAssetGroupNode({
      assetGroupType: AssetGroupType.vectorAnalysisMaps,
      renderer: assetGroup.renderer,
    });
    let assetGroupNodes: DataLayersTreeNode[] = [];

    if (vectorAnalysisMaps?.filteredAssets?.length) {
      assetGroupNodes = getSortedAssetsNodes({
        items: vectorAnalysisMaps.filteredAssets,
        renderer: vectorAnalysisMaps.renderer,
        parentNode: assetGroupParentNode,
        assetGroupType: AssetGroupType.vectorAnalysisMaps,
      });
    } else if (
      groupPlaceholder?.enabled ||
      vectorAnalysisMaps?.assets?.length
    ) {
      assetGroupNodes = getGroupPlaceholderNodes({
        assetGroupType: AssetGroupType.vectorAnalysisMaps,
        renderer: groupPlaceholder.renderer,
        parentNode: assetGroupParentNode,
      });
    }

    if (vectorAnalysisMaps?.filterRenderer) {
      const filterNode = getFilterNode({
        renderer: vectorAnalysisMaps?.filterRenderer,
        parentNode: assetGroupParentNode,
        assetGroupType: AssetGroupType.vectorAnalysisMaps,
      });

      assetGroupNodes = [filterNode, ...assetGroupNodes];
    }

    if (assetGroupParentNode) {
      assetGroupParentNode.children = assetGroupNodes;
      result.push(assetGroupParentNode);
    } else {
      result.push(...assetGroupNodes);
    }
  }

  if (soilDatasets?.assets?.length || groupPlaceholder?.enabled) {
    const assetGroupParentNode = getAssetGroupNode({
      assetGroupType: AssetGroupType.soilDatasets,
      renderer: assetGroup.renderer,
    });
    let assetGroupNodes: DataLayersTreeNode[] = [];

    if (soilDatasets?.filteredAssets?.length) {
      assetGroupNodes = getDatasetsNodes({
        items: soilDatasets.filteredAssets,
        renderer: soilDatasets.renderer,
        parentNode: assetGroupParentNode,
        withAttributes: soilDatasets.withAttributes,
        withDatasetViewType: soilDatasets.withDatasetViewType,
        assetGroupType: AssetGroupType.soilDatasets,
        grouping: soilDatasets.grouping,
        isItemSelectable: soilDatasets.isItemSelectable,
      });
    } else if (groupPlaceholder?.enabled || soilDatasets?.assets?.length) {
      assetGroupNodes = getGroupPlaceholderNodes({
        assetGroupType: AssetGroupType.soilDatasets,
        renderer: groupPlaceholder.renderer,
        parentNode: assetGroupParentNode,
      });
    }

    if (soilDatasets?.filterRenderer) {
      const filterNode = getFilterNode({
        renderer: soilDatasets?.filterRenderer,
        parentNode: assetGroupParentNode,
        assetGroupType: AssetGroupType.soilDatasets,
      });

      assetGroupNodes = [filterNode, ...assetGroupNodes];
    }

    if (assetGroupParentNode) {
      assetGroupParentNode.children = assetGroupNodes;
      result.push(assetGroupParentNode);
    } else {
      result.push(...assetGroupNodes);
    }
  }

  if (yieldDatasets?.assets?.length || groupPlaceholder?.enabled) {
    const assetGroupParentNode = getAssetGroupNode({
      assetGroupType: AssetGroupType.yieldDatasets,
      renderer: assetGroup.renderer,
    });
    let assetGroupNodes: DataLayersTreeNode[] = [];

    if (yieldDatasets?.filteredAssets?.length) {
      assetGroupNodes = getDatasetsNodes({
        items: yieldDatasets.filteredAssets,
        renderer: yieldDatasets.renderer,
        parentNode: assetGroupParentNode,
        withAttributes: yieldDatasets.withAttributes,
        withDatasetViewType: yieldDatasets.withDatasetViewType,
        assetGroupType: AssetGroupType.yieldDatasets,
        grouping: yieldDatasets.grouping,
        isItemSelectable: yieldDatasets.isItemSelectable,
      });
    } else if (groupPlaceholder?.enabled || yieldDatasets?.assets?.length) {
      assetGroupNodes = getGroupPlaceholderNodes({
        assetGroupType: AssetGroupType.yieldDatasets,
        renderer: groupPlaceholder.renderer,
        parentNode: assetGroupParentNode,
      });
    }

    if (yieldDatasets?.filterRenderer) {
      const filterNode = getFilterNode({
        renderer: yieldDatasets?.filterRenderer,
        parentNode: assetGroupParentNode,
        assetGroupType: AssetGroupType.yieldDatasets,
      });

      assetGroupNodes = [filterNode, ...assetGroupNodes];
    }

    if (assetGroupParentNode) {
      assetGroupParentNode.children = assetGroupNodes;
      result.push(assetGroupParentNode);
    } else {
      result.push(...assetGroupNodes);
    }
  }

  if (asAppliedDatasets?.assets?.length || groupPlaceholder?.enabled) {
    const assetGroupParentNode = getAssetGroupNode({
      assetGroupType: AssetGroupType.asAppliedDatasets,
      renderer: assetGroup.renderer,
    });
    let assetGroupNodes: DataLayersTreeNode[] = [];

    if (asAppliedDatasets?.filteredAssets?.length) {
      assetGroupNodes = getDatasetsNodes({
        items: asAppliedDatasets.filteredAssets,
        renderer: asAppliedDatasets.renderer,
        parentNode: assetGroupParentNode,
        withAttributes: asAppliedDatasets.withAttributes,
        withDatasetViewType: asAppliedDatasets.withDatasetViewType,
        assetGroupType: AssetGroupType.asAppliedDatasets,
        grouping: asAppliedDatasets.grouping,
        isItemSelectable: asAppliedDatasets.isItemSelectable,
      });
    } else if (groupPlaceholder?.enabled || asAppliedDatasets?.assets?.length) {
      assetGroupNodes = getGroupPlaceholderNodes({
        assetGroupType: AssetGroupType.asAppliedDatasets,
        renderer: groupPlaceholder.renderer,
        parentNode: assetGroupParentNode,
      });
    }

    if (asAppliedDatasets?.filterRenderer) {
      const filterNode = getFilterNode({
        renderer: asAppliedDatasets?.filterRenderer,
        parentNode: assetGroupParentNode,
        assetGroupType: AssetGroupType.asAppliedDatasets,
      });

      assetGroupNodes = [filterNode, ...assetGroupNodes];
    }

    if (assetGroupParentNode) {
      assetGroupParentNode.children = assetGroupNodes;
      result.push(assetGroupParentNode);
    } else {
      result.push(...assetGroupNodes);
    }
  }

  if (topographyMaps?.assets?.length || groupPlaceholder?.enabled) {
    const assetGroupParentNode = getAssetGroupNode({
      assetGroupType: AssetGroupType.topographyMaps,
      renderer: assetGroup.renderer,
    });
    let assetGroupNodes: DataLayersTreeNode[] = [];

    if (topographyMaps?.filteredAssets?.length) {
      assetGroupNodes = getTopographyNodes({
        items: topographyMaps.filteredAssets,
        renderer: topographyMaps.renderer,
        parentNode: assetGroupParentNode,
        withAttributes: topographyMaps.withAttributes,
      });
    } else if (groupPlaceholder?.enabled || topographyMaps?.assets?.length) {
      assetGroupNodes = getGroupPlaceholderNodes({
        assetGroupType: AssetGroupType.topographyMaps,
        renderer: groupPlaceholder.renderer,
        parentNode: assetGroupParentNode,
      });
    }

    if (topographyMaps?.filterRenderer) {
      const filterNode = getFilterNode({
        renderer: topographyMaps?.filterRenderer,
        parentNode: assetGroupParentNode,
        assetGroupType: AssetGroupType.topographyMaps,
      });

      assetGroupNodes = [filterNode, ...assetGroupNodes];
    }

    if (assetGroupParentNode) {
      assetGroupParentNode.children = assetGroupNodes;
      result.push(assetGroupParentNode);
    } else {
      result.push(...assetGroupNodes);
    }
  }

  if (threeDimensionalMaps?.assets?.length || groupPlaceholder?.enabled) {
    const assetGroupParentNode = getAssetGroupNode({
      assetGroupType: AssetGroupType.threeDimensionalMaps,
      renderer: assetGroup.renderer,
    });
    let assetGroupNodes: DataLayersTreeNode[] = [];

    if (threeDimensionalMaps?.filteredAssets?.length) {
      assetGroupNodes = getSortedAssetsNodes({
        items: threeDimensionalMaps.filteredAssets,
        renderer: threeDimensionalMaps.renderer,
        parentNode: assetGroupParentNode,
        assetGroupType: AssetGroupType.threeDimensionalMaps,
      });
    } else if (
      groupPlaceholder?.enabled ||
      threeDimensionalMaps?.assets?.length
    ) {
      assetGroupNodes = getGroupPlaceholderNodes({
        assetGroupType: AssetGroupType.threeDimensionalMaps,
        renderer: groupPlaceholder.renderer,
        parentNode: assetGroupParentNode,
      });
    }

    if (threeDimensionalMaps?.filterRenderer) {
      const filterNode = getFilterNode({
        renderer: threeDimensionalMaps?.filterRenderer,
        parentNode: assetGroupParentNode,
        assetGroupType: AssetGroupType.threeDimensionalMaps,
      });

      assetGroupNodes = [filterNode, ...assetGroupNodes];
    }

    if (assetGroupParentNode) {
      assetGroupParentNode.children = assetGroupNodes;
      result.push(assetGroupParentNode);
    } else {
      result.push(...assetGroupNodes);
    }
  }

  if (equationMaps?.assets?.length || groupPlaceholder?.enabled) {
    const assetGroupParentNode = getAssetGroupNode({
      assetGroupType: AssetGroupType.equationMaps,
      renderer: assetGroup.renderer,
    });
    let assetGroupNodes: DataLayersTreeNode[] = [];

    if (equationMaps?.filteredAssets?.length) {
      assetGroupNodes = getEquationMapNodes({
        items: equationMaps.filteredAssets,
        renderer: equationMaps.renderer,
        parentNode: assetGroupParentNode,
        isItemSelectable: equationMaps.isItemSelectable,
        assetGroupType: AssetGroupType.equationMaps,
        withAttributes: equationMaps.withAttributes,
      });
    } else if (groupPlaceholder?.enabled || equationMaps?.assets?.length) {
      assetGroupNodes = getGroupPlaceholderNodes({
        assetGroupType: AssetGroupType.equationMaps,
        renderer: groupPlaceholder.renderer,
        parentNode: assetGroupParentNode,
      });
    }

    if (equationMaps?.filterRenderer) {
      const filterNode = getFilterNode({
        renderer: equationMaps?.filterRenderer,
        parentNode: assetGroupParentNode,
        assetGroupType: AssetGroupType.equationMaps,
      });

      assetGroupNodes = [filterNode, ...assetGroupNodes];
    }

    if (assetGroupParentNode) {
      assetGroupParentNode.children = assetGroupNodes;
      result.push(assetGroupParentNode);
    } else {
      result.push(...assetGroupNodes);
    }
  }

  if (pinsGroups?.assets?.length || groupPlaceholder?.enabled) {
    const assetGroupParentNode = getAssetGroupNode({
      assetGroupType: AssetGroupType.pinsGroups,
      renderer: assetGroup.renderer,
    });
    let assetGroupNodes: DataLayersTreeNode[] = [];

    if (pinsGroups?.filteredAssets?.length) {
      assetGroupNodes = getSortedAssetsNodes({
        items: pinsGroups.filteredAssets,
        renderer: pinsGroups.renderer,
        parentNode: assetGroupParentNode,
        assetGroupType: AssetGroupType.pinsGroups,
      });
    } else if (groupPlaceholder?.enabled || pinsGroups?.assets?.length) {
      assetGroupNodes = getGroupPlaceholderNodes({
        assetGroupType: AssetGroupType.pinsGroups,
        renderer: groupPlaceholder.renderer,
        parentNode: assetGroupParentNode,
      });
    }

    if (pinsGroups?.filterRenderer) {
      const filterNode = getFilterNode({
        renderer: pinsGroups?.filterRenderer,
        parentNode: assetGroupParentNode,
        assetGroupType: AssetGroupType.pinsGroups,
      });

      assetGroupNodes = [filterNode, ...assetGroupNodes];
    }

    if (assetGroupParentNode) {
      assetGroupParentNode.children = assetGroupNodes;
      result.push(assetGroupParentNode);
    } else {
      result.push(...assetGroupNodes);
    }
  }

  return result;
};

export default generateDataLayersTree;
