import parseISO from 'date-fns/parseISO';
import { isBefore, addMonths, differenceInMinutes } from 'date-fns';

import { OrderStatus, PricingPlan } from '../constants/order';

export const getOrderEndDate = (order) => {
  let result = null;

  if (order?.packageInfo?.endDate) {
    result = parseISO(order.packageInfo.endDate);
  } else if (order?.packageInfo?.startDate && order.billingPeriodInMonths) {
    result = addMonths(
      parseISO(order.packageInfo.startDate),
      order.billingPeriodInMonths,
    );
  } else if (order?.orderDate && order.billingPeriodInMonths) {
    result = addMonths(parseISO(order.orderDate), order.billingPeriodInMonths);
  }

  return result;
};

export const getOrderStartDate = (order) => {
  let result = null;

  if (order?.packageInfo?.startDate) {
    result = parseISO(order.packageInfo.startDate);
  } else if (order?.orderDate) {
    result = parseISO(order.orderDate);
  }

  return result;
};

export const findLastPaidOrder = (orders = []) =>
  orders.find((order) => order.status === OrderStatus.InvoicePaid);

export const isFreeTrialPlan = (order) => order?.plan === PricingPlan.FreeTrial;

export const isSoftLimitPlan = (order) =>
  order?.plan === PricingPlan.Enterprise ||
  order?.plan === PricingPlan.MonthlyPayAsYouGo;

export const isPayAsYouGoPlan = (order) =>
  order?.plan === PricingPlan.MonthlyPayAsYouGo;

export const isAnnualSubscription = (order) =>
  order?.plan === PricingPlan.AnnualSubscription;

export const getOrderDaysLeft = (orderEndDate) => {
  if (!orderEndDate) {
    return 0;
  }

  const today = new Date();

  if (isBefore(orderEndDate, today)) {
    return 0;
  }

  const diffInMinutes = differenceInMinutes(orderEndDate, today);

  return Math.ceil(diffInMinutes / (24 * 60));
};
