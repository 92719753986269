import { createSelector } from '@reduxjs/toolkit';

export const selectExportStep = ({ jdExport }) => jdExport.step;

export const selectExportHasSelectedOrganization = ({ jdExport }) =>
  jdExport.selectedOrganization !== null;

export const selectExportSelectedOrganization = ({ jdExport }) =>
  jdExport.selectedOrganization;

export const selectExportIsEmptyZonesMaps = ({ jdExport }) =>
  !jdExport?.zonesMaps?.zonesMaps;

export const selectExportZonesMaps = ({ jdExport }) =>
  jdExport.zonesMaps ? jdExport.zonesMaps.zonesMaps : [];

export const selectExportSelectedZonesMaps = ({ jdExport }) =>
  jdExport.selectZonesMaps.zonesMaps || [];

export const selectExportFlowFilter = ({ jdExport }) =>
  jdExport.selectZonesMaps.filter;

export const selectLastEvaluatedKey = ({ jdExport }) =>
  jdExport.zonesMaps?.lastEvaluatedKey;

export const selectLegendZonesMap = createSelector(
  selectExportSelectedZonesMaps,
  ({ jdExport }) => jdExport.legend?.uuid,
  (vamaps, uuid) => vamaps.find((vamap) => vamap.uuid === uuid),
);

export const selectExportIsExecutingFetchingZonesMaps = ({ jdExport }) =>
  jdExport?.zonesMaps?.isLoading === true;

export const selectExportIsExecutingExport = ({ jdExport }) =>
  jdExport.isLoading;
