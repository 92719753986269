import React, { Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../../../../../../../components/Loading';
import BackdropLoading from '../../../../../../../components/Loading/BackdropLoading';
import Stepper from '../../../../../../../components/Stepper';
import Panel from '../../../Panel/DataLayers';
import { goToPrevStep } from '../../../../../zonesOps/zonesOpsSlice';
import { fetchAllAssets } from '../../../../../../field/fieldSlice';
import Message from '../../../../../../../components/Messages/Message';
import {
  selectIsAllAssetsLoaded,
  selectSatelliteImages,
} from '../../../../../../field/fieldSelectors';
import { selectSelectedUuids } from '../../../../createStabilityZonesSelectors';
import { selectSelectedField } from '../../../../../zonesOps/zonesOpsSelectors';
import useDidMount from '../../../../../../../hooks/useDidMount';
import { navigateToSettings } from '../../../../createStabilityZonesSlice';
import DataLayersPreviews from '../../../DataLayersPreviews';

const Map = React.lazy(() => import('../../../Map'));

const StepperDataLayersStabilityZones = ({ steps, stepIndex, step }) => {
  const dispatch = useDispatch();

  const selectedUuids = useSelector(selectSelectedUuids);
  const isLoaded = useSelector(selectIsAllAssetsLoaded);
  const { farmUuid, fieldUuid } = useSelector(selectSelectedField);
  const images = useSelector(selectSatelliteImages);

  const stats = images
    .filter((image) => selectedUuids.includes(image.uuid))
    .reduce(
      (acc, curr) => {
        const [name] = curr.satelliteImage.provider;

        if (name === 'L') {
          acc.landsat += 1;
        } else if (name === 'S') {
          acc.sentinel += 1;
        }

        return acc;
      },
      {
        landsat: 0,
        sentinel: 0,
      },
    );
  const nextDisabled = !(stats.sentinel >= 2 || stats.landsat >= 2);
  const fieldHasNoAssets = images.length === 0;

  const onClickNext = () => {
    dispatch(navigateToSettings());
  };

  const onClickBack = () => {
    dispatch(goToPrevStep());
  };

  useDidMount(() => {
    dispatch(
      fetchAllAssets({
        farmUuid,
        fieldUuid,
        requiredSatImages: selectedUuids,
      }),
    );
  });

  let content;

  if (isLoaded) {
    if (fieldHasNoAssets) {
      content = (
        <Message
          key="panel"
          messageTextLocation="zones-ops.common.no-assets-message"
        />
      );
    } else {
      content = [
        <Panel key="panel" step={step} />,
        <Suspense key="map" fallback={<Loading />}>
          <Map step={step} />
        </Suspense>,
        <DataLayersPreviews key="previews" />,
      ];
    }
  } else {
    content = <BackdropLoading key="loader" />;
  }

  return (
    <Stepper
      activeStep={stepIndex}
      steps={steps}
      nextDisabled={nextDisabled}
      onClickBack={onClickBack}
      onClickNext={onClickNext}
    >
      {content}
    </Stepper>
  );
};

export default StepperDataLayersStabilityZones;
