import { hasErrors } from '../../../../helpers/functions/utils/errorHandling';
import type {
  GetFieldAssetsResponse,
  TransformedFieldAssetsResponse,
} from '../../types/api';
import { transformFields } from './assets';
import { isInUnsupportedRegion } from './field';

export const transformFieldAssetsResponse = (
  response: GetFieldAssetsResponse,
): TransformedFieldAssetsResponse => {
  let result: TransformedFieldAssetsResponse;

  const farm = response.data?.getFarms[0];

  if (farm?.fields.length) {
    const field = transformFields(farm.fields)[0];

    result = {
      field,
      noMonitoring: isInUnsupportedRegion(field),
    };
  } else {
    result = null;
  }

  return result;
};

export const isTokenExpiredSoonError = (error: unknown): boolean => {
  const regexp = /token is invalid/gi;
  const message = hasErrors<{ message: string }[]>(error)
    ? error?.errors[0].message
    : '';

  return regexp.test(message);
};

export const isTimeoutError = (error: unknown): boolean => {
  if (error == null) {
    return false;
  }

  if (typeof error === 'object' && 'errors' in error) {
    const errorObj = error as { errors?: { message?: string }[] };
    const errorMessage = errorObj.errors?.[0]?.message;

    if (typeof errorMessage === 'string') {
      const TIMEOUT_ERROR_REGEXP = /timed out/i;
      return TIMEOUT_ERROR_REGEXP.test(errorMessage);
    }
  }

  return false;
};
