import React from 'react';
import AddIcon from '@material-ui/icons/Add';

import Button from '../../Button';

import './index.scss';

const ListHeader = ({
  addButtonLabel,
  onAddClick,
}: {
  addButtonLabel?: string;
  onAddClick?: () => void;
}) => (
  <>
    {addButtonLabel && onAddClick ? (
      <Button
        className="list-header__add-button"
        onClick={onAddClick}
        startIcon={<AddIcon className="list-header__add-button-icon" />}
      >
        {addButtonLabel}
      </Button>
    ) : null}
  </>
);

export default ListHeader;
