import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ColorRange } from '../../../../../helpers/types/map';
import Histogram from '../Histogram';
import { isNumber } from '../../../../../helpers/functions/utils/number';

import './index.scss';

const ColorsRangeLegend = ({
  ranges,
  name,
  unit,
  histogramLoading,
  minified,
}: {
  ranges?: ColorRange[];
  name?: string;
  unit?: string;
  histogramLoading?: boolean;
  minified?: boolean;
}) => {
  const { t } = useTranslation();
  const title =
    name ?? t('general.controls.map-legend.mini-legend.legend.title');

  const withHistogram =
    ranges?.some(({ avg }) => isNumber(avg)) || histogramLoading;

  return (
    <div className="colors-range-legend">
      <div className="colors-range-legend__header">
        <span className="colors-range-legend__header-title" title={title}>
          {title}
          {unit && (
            <span className="colors-range-legend__header-unit" title={unit}>
              {' '}
              ({unit})
            </span>
          )}
        </span>
      </div>
      <div
        className={clsx('colors-range-legend__content', {
          'colors-range-legend__content_minified': minified && !withHistogram,
        })}
      >
        <div className="colors-range-legend__legend">
          {ranges?.map(({ min, max, color }, index) => (
            <div
              key={index}
              className={clsx('colors-range-legend__legend-item', {
                'colors-range-legend__legend-item_small':
                  (min == null || max == null) && !withHistogram,
                'colors-range-legend__legend-item_reversed': withHistogram,
              })}
            >
              <span
                className="colors-range-legend__legend-item-color"
                style={{
                  ...(color && { backgroundColor: color }),
                }}
              />
              <span className="colors-range-legend__legend-item-threshold">
                {min}
              </span>
            </div>
          ))}
        </div>
        {withHistogram && (
          <Histogram ranges={ranges} loading={histogramLoading} />
        )}
      </div>
    </div>
  );
};
export default ColorsRangeLegend;
