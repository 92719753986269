import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';

import type { NullableAvgTotalCalibrateCondition } from '../../types/actions';
import type { FullAttributeItem } from '../../types/ui';
import { AvgTotalCalibrationType } from '../../helpers/constants/ui';
import Button from '../../../../../components/Button';
import TextField from '../../../../../components/TextField';
import ComboBox from '../../../../../components/ComboBox';
import RadioGroup from '../../../../../components/RadioGroup';
import {
  getComboBoxOptionSelected,
  isFullAttributeItemDisabled,
  transformCalibrateAvgTotalConditions,
} from '../../helpers/functions/ui';

import './index.scss';

export default function AvgTotalCalibrateConditions({
  title = '',
  typeSelectorTitle,
  attributesItems,
  conditions,
  onAddConditionClick,
  onConditionAttributeChange,
  onConditionTypeChange,
  onConditionAvgChange,
  onConditionTotalChange,
  onConditionDelete,
}: {
  title?: string;
  typeSelectorTitle: string;
  attributesItems: FullAttributeItem[];
  conditions: NullableAvgTotalCalibrateCondition[];
  onAddConditionClick: () => void;
  onConditionAttributeChange: (i: FullAttributeItem, index: number) => void;
  onConditionTypeChange: (i: AvgTotalCalibrationType, index: number) => void;
  onConditionAvgChange: (i: number | null, index: number) => void;
  onConditionTotalChange: (i: number | null, index: number) => void;
  onConditionDelete: (i: number) => void;
}) {
  const { t } = useTranslation();

  const handleAvgTotalCalibrationTypeChange = (
    type: AvgTotalCalibrationType,
    index: number,
  ) => {
    onConditionTypeChange(type, index);
  };

  const handleConditionAvgChange = (
    e: ChangeEvent<{ value: string }>,
    index: number,
  ) => {
    const value = e.target.value ? parseFloat(e.target.value) : null;
    onConditionAvgChange(value, index);
  };

  const handleConditionTotalChange = (
    e: ChangeEvent<{ value: string }>,
    index: number,
  ) => {
    const value = e.target.value ? parseFloat(e.target.value) : null;
    onConditionTotalChange(value, index);
  };

  const handleConditionDelete = (index: number) => {
    onConditionDelete(index);
  };

  return (
    <div className="avg-total-calibrate-conditions">
      <div className="avg-total-calibrate-conditions__header">
        <Typography className="avg-total-calibrate-conditions__title">
          {title}
        </Typography>
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={onAddConditionClick}
        >
          {t('clean-calibrate.yield-popup.add-attribute')}
        </Button>
      </div>
      <div className="avg-total-calibrate-conditions__items">
        {conditions.map((condition, index) => {
          const conditionAttributeItem = attributesItems.find(
            (attributeItem) =>
              attributeItem.value === condition.calibrationAttribute,
          );

          return (
            <div key={index} className="avg-total-calibrate-conditions-item">
              <Typography className="avg-total-calibrate-conditions-item__type-selector-title">
                {typeSelectorTitle}
              </Typography>
              <RadioGroup
                horizontal
                options={[
                  {
                    title: t(
                      'clean-calibrate.yield-popup.tabs.calibrate.configure.avg-total-conditions.types.avg',
                    ),
                    value: AvgTotalCalibrationType.avg,
                  },
                  {
                    title: t(
                      'clean-calibrate.yield-popup.tabs.calibrate.configure.avg-total-conditions.types.total',
                    ),
                    value: AvgTotalCalibrationType.total,
                  },
                ]}
                value={condition.type}
                onChange={(type: AvgTotalCalibrationType) =>
                  handleAvgTotalCalibrationTypeChange(type, index)
                }
              />
              <div className="avg-total-calibrate-conditions-item__attribute">
                <ComboBox
                  classes={{
                    root: 'avg-total-calibrate-conditions-item__attribute-selector',
                  }}
                  title={t(
                    'clean-calibrate.yield-popup.tabs.calibrate.configure.attribute-title',
                    {
                      number: index + 1,
                    },
                  )}
                  disableClearable
                  placeholder={t('general.controls.select')}
                  options={attributesItems}
                  value={
                    conditionAttributeItem ||
                    (null as unknown as FullAttributeItem)
                  }
                  getOptionSelected={getComboBoxOptionSelected}
                  getOptionDisabled={(o) =>
                    isFullAttributeItemDisabled(
                      transformCalibrateAvgTotalConditions(conditions),
                      o,
                      conditionAttributeItem,
                    )
                  }
                  disableCloseOnSelect={false}
                  onChange={(_e, item) =>
                    onConditionAttributeChange(item, index)
                  }
                />
                {condition.type === AvgTotalCalibrationType.avg ? (
                  <TextField
                    classes={{
                      title:
                        'avg-total-calibrate-conditions-item__value-input-label',
                    }}
                    type="number"
                    title={t(
                      'clean-calibrate.yield-popup.tabs.calibrate.configure.avg-total-conditions.item-avg-label',
                    )}
                    value={condition.average != null ? condition.average : ''}
                    onChange={(e: ChangeEvent<{ value: string }>) =>
                      handleConditionAvgChange(e, index)
                    }
                  />
                ) : (
                  <TextField
                    classes={{
                      title:
                        'avg-total-calibrate-conditions-item__value-input-label',
                    }}
                    type="number"
                    title={t(
                      'clean-calibrate.yield-popup.tabs.calibrate.configure.avg-total-conditions.item-total-label',
                    )}
                    value={condition.total != null ? condition.total : ''}
                    onChange={(e: ChangeEvent<{ value: string }>) =>
                      handleConditionTotalChange(e, index)
                    }
                  />
                )}
                <IconButton
                  size="small"
                  onClick={() => handleConditionDelete(index)}
                >
                  <DeleteSharpIcon />
                </IconButton>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
