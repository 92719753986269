import React, { Suspense } from 'react';
import {
  createMuiTheme,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Provider as ReduxProvider } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';

import routes from './router/routes';
import setupStore from './app/setupStore';
import { getPalette } from './helpers/functions/utils/appConfig';
import BootstrapLoading from './components/Loading/BootstrapLoading';
import TokensBroker from './features/ui/authentication/containers/TokensBroker';
import NotificationsProvider from './features/notifications/containers/NotificationsProvider';

const palette = getPalette();
const theme = createMuiTheme({
  palette: {
    primary: {
      main: palette?.primary?.main || '#33691E',
      light: palette?.primary?.light || '#629749',
      dark: palette?.primary?.dark || '#003D00',
    },
    secondary: {
      main: palette?.secondary?.main || '#8E0000',
      light: palette?.secondary?.light || '#EF6443',
    },
    error: {
      main: palette?.error?.main || '#C62828',
    },
  },
  zIndex: {
    modal: 1360, // to overflow drawer
  },
  overrides: {
    MuiTypography: {
      h5: {
        fontSize: '20px',
      },
      body1: {
        fontSize: '14px',
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 700,
      lg: 1200,
      xl: 1536,
    },
  },
});

const App = () => (
  <ReduxProvider store={setupStore()}>
    <ThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <Suspense fallback={<BootstrapLoading />}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <HashRouter>
              <TokensBroker>
                <NotificationsProvider>
                  <Switch>
                    {routes.map((route, i) => (
                      <Route key={i} {...route} />
                    ))}
                  </Switch>
                </NotificationsProvider>
              </TokensBroker>
            </HashRouter>
          </MuiPickersUtilsProvider>
        </Suspense>
      </StylesProvider>
    </ThemeProvider>
  </ReduxProvider>
);

export default App;
