import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

import BackdropLoading from '../../../../../components/Loading/BackdropLoading';
import PopupHeader from '../../../../../components/Popups/PopupHeader';
import Button from '../../../../../components/Button';
import TextField from '../../../../../components/TextField';
import { resetVerifyEquation, verifyEquation } from '../../../equationsSlice';
import { selectVerifyEquation } from '../../../equationsSelectors';

import './index.scss';

export default function EquationTestRunPopup({
  equationAsText,
  equationResultVariable,
  dataVariables,
  useNumpy,
  onCancel = () => {},
}: {
  equationAsText: string;
  equationResultVariable: string;
  dataVariables: string[];
  useNumpy: boolean;
  onCancel: () => void;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { errorMessage, inProgress, result } =
    useSelector(selectVerifyEquation);

  const [sampleValues, setSampleValues] = useState<Record<string, string>>({});

  const runButtonDisabled = dataVariables.some(
    (variable) => !sampleValues[variable],
  );

  useEffect(() => {
    dispatch(resetVerifyEquation());
  }, [dispatch]);

  const handleSampleValueChange = (variable: string, value: string) => {
    dispatch(resetVerifyEquation());
    setSampleValues({
      ...sampleValues,
      [variable]: value,
    });
  };

  const handleRunClick = () => {
    dispatch(
      verifyEquation({
        equationAsText,
        equationResultVariable,
        dataVariables,
        useNumpy,
        sampleValues,
      }),
    );
  };

  const handleCancel = () => {
    dispatch(resetVerifyEquation());
    onCancel();
  };

  return (
    <Dialog
      open
      classes={{
        paper: 'equation-test-run-popup__paper',
      }}
    >
      <PopupHeader
        title={t('general.popups.equation-test-run.title')}
        onCancel={handleCancel}
      />
      <DialogContent>
        <DialogContentText
          classes={{
            root: 'equation-test-run-popup__content-text',
          }}
        >
          {t('general.popups.equation-test-run.description')}
        </DialogContentText>
        <div className="equation-test-run-popup__variables-list">
          {dataVariables.map((variable, index) => (
            <TextField
              key={index}
              classes={{
                wrapper: 'equation-test-run-popup__variable-textfield',
              }}
              type="number"
              title={variable}
              value={sampleValues[variable] || ''}
              placeholder={t('general.popups.equation-test-run.placeholder')}
              onChange={(e: ChangeEvent<{ value: string }>) =>
                handleSampleValueChange(variable, e.target.value)
              }
            />
          ))}
        </div>
        {(result != null || errorMessage) && (
          <>
            <Divider className="equation-test-run-popup__divider" />
            <Typography className="equation-test-run-popup__sub-title">
              Result
            </Typography>
          </>
        )}
        {result != null && (
          <div className="equation-test-run-popup__result-container">
            <Typography className="equation-test-run-popup__result-text">
              {result}
            </Typography>
          </div>
        )}
        {errorMessage && (
          <div className="equation-test-run-popup__error-container">
            <CancelOutlinedIcon
              className="equation-test-run-popup__error-icon"
              fontSize="small"
            />
            <Typography className="equation-test-run-popup__error-text">
              {errorMessage}
            </Typography>
          </div>
        )}
      </DialogContent>
      <DialogActions
        classes={{
          root: 'equation-test-run-popup__actions',
        }}
      >
        <Button onClick={handleCancel} variant="outlined">
          {t('general.controls.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={runButtonDisabled}
          onClick={handleRunClick}
        >
          {t('general.controls.run')}
        </Button>
      </DialogActions>
      {inProgress && <BackdropLoading />}
    </Dialog>
  );
}
