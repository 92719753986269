import i18n from 'i18next';

import CommonSteps from '../constants/steps';
import SelectFieldsWorkflow from '../../containers/Stepper/common/SelectFieldsWorkflow';
import { Step } from '../../../../../components/Stepper';

const getCommonSteps = (): Step[] => [
  {
    name: i18n.t('batch-analytics.steps.select-fields-workflow.name'),
    description: i18n.t(
      'batch-analytics.steps.select-fields-workflow.description',
    ),
    id: CommonSteps.selectFieldsWorkflow,
    component: SelectFieldsWorkflow,
  },
];

export default getCommonSteps;
