import React from 'react';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import type { AttributeStatistics } from '../../../../../helpers/types/dataset';
import type {
  FullAttributeItem,
  NullableMinMaxUSDACondition,
} from '../../types/ui';
import { isFullAttributeItemDisabled } from '../../helpers/functions/ui';
import StandardDeviation from '../StandardDeviation';
import AntSwitch from '../../../../../components/AntSwitch';
import MinMaxConditionsBaseItem from '../MinMaxConditionsBaseItem';

import './index.scss';

export default function MinMaxUSDAConditions({
  title,
  attributesItems,
  conditions,
  statistics = [],
  onConditionAttributeChange,
  onConditionMinChange,
  onConditionMaxChange,
  onConditionSelected,
  onStandardDeviationChange,
  onStandardDeviationChecked,
}: {
  title?: string;
  attributesItems: FullAttributeItem[];
  conditions: NullableMinMaxUSDACondition[];
  statistics?: AttributeStatistics[];
  onConditionAttributeChange: (i: FullAttributeItem, index: number) => void;
  onConditionMinChange: (i: number | null, index: number) => void;
  onConditionMaxChange: (i: number | null, index: number) => void;
  onConditionSelected: (i: boolean, index: number) => void;
  onStandardDeviationChange: (i: number | null, index: number) => void;
  onStandardDeviationChecked: (i: boolean, index: number) => void;
}) {
  const handleConditionMinChange = (value: string, index: number) => {
    const min = value ? parseFloat(value) : null;
    onConditionMinChange(min, index);
  };

  const handleConditionMaxChange = (value: string, index: number) => {
    const max = value ? parseFloat(value) : null;
    onConditionMaxChange(max, index);
  };

  const getConditionAttributeOptionDisabled = (
    attribute: FullAttributeItem,
    selectedAttribute?: FullAttributeItem,
  ) => isFullAttributeItemDisabled(conditions, attribute, selectedAttribute);

  return (
    <div className="min-max-usda-conditions">
      <Typography className="min-max-usda-conditions__title">
        {title}
      </Typography>
      <div>
        {conditions.map((condition, index) => {
          const attributeStatistics = statistics.find(
            (statistic) => statistic.attribute === condition.attribute,
          );
          const disabled = condition.selected === false;

          return (
            <div key={index} className="min-max-usda-conditions-item__wrapper">
              <div
                className={clsx('min-max-usda-conditions-item', {
                  'min-max-usda-conditions-item__disabled': disabled,
                })}
              >
                <MinMaxConditionsBaseItem
                  condition={condition}
                  attributesItems={attributesItems}
                  attributeStatistics={attributeStatistics}
                  title={condition.title || ''}
                  disabled={disabled}
                  classes={{
                    label: 'min-max-usda-conditions-item__label',
                  }}
                  onConditionAttributeChange={(item) =>
                    onConditionAttributeChange(item, index)
                  }
                  onConditionMinChange={(min) =>
                    handleConditionMinChange(min, index)
                  }
                  onConditionMaxChange={(min) =>
                    handleConditionMaxChange(min, index)
                  }
                  getConditionAttributeOptionDisabled={
                    getConditionAttributeOptionDisabled
                  }
                />
                <Typography className="min-max-usda-conditions-item__description">
                  {condition.description}
                </Typography>
                {condition.withStdNumber && (
                  <StandardDeviation
                    value={condition.stdNumber}
                    disabled={disabled}
                    onChange={(value: number | null) =>
                      onStandardDeviationChange(value, index)
                    }
                    onChecked={(value: boolean) =>
                      onStandardDeviationChecked(value, index)
                    }
                  />
                )}
              </div>
              <AntSwitch
                classes={{ root: 'min-max-usda-conditions-item__switch' }}
                isChecked={condition.selected}
                onValueChange={(value: boolean) =>
                  onConditionSelected(value, index)
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
