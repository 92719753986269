import React, { useCallback } from 'react';

import BackdropLoading from '../../../../../components/Loading/BackdropLoading';
import InfiniteTable from '../../../tableView/components/InfiniteTable';
import TableHeadContent from '../../../tableView/components/TableHeadContent';
import TableRowContent from '../../../tableView/components/TableRowContent';
import useLabelsExpanded from '../../../tableView/hooks/useLabelsExpanded';
import type { TableColumn } from '../../../tableView/types/column';
import type {
  DefaultZonesMapTableRow,
  ZonesMapTableRow,
} from '../../types/tableRow';
import { isRowExpanded } from '../../../tableView/helpers/functions/rows';

import './index.scss';

export default function ZonesMapsTable({
  loading,
  rows,
  columns,
  loadComplete,
  labelsExpanded,
  onLoadMoreRows,
}: {
  loading: boolean;
  rows: ZonesMapTableRow[];
  columns: TableColumn<DefaultZonesMapTableRow>[];
  loadComplete: boolean;
  labelsExpanded: boolean;
  onLoadMoreRows: () => void;
}) {
  const { expanded, handleExpandedChange } = useLabelsExpanded(labelsExpanded);

  const headerContentRenderer = useCallback(
    () => <TableHeadContent columns={columns} />,
    [columns],
  );

  const rowRenderer = useCallback(
    (row: ZonesMapTableRow) => {
      const isExpanded = isRowExpanded(row, expanded, labelsExpanded);

      return (
        <TableRowContent
          columns={columns}
          row={row}
          isExpanded={isExpanded}
          onRowExpandedChange={handleExpandedChange}
        />
      );
    },
    [columns, expanded, labelsExpanded, handleExpandedChange],
  );

  return (
    <div className="zones-maps-table">
      {loading && <BackdropLoading />}
      <InfiniteTable
        data={rows}
        loadComplete={loadComplete}
        columnsAmount={columns.length}
        rowRenderer={rowRenderer}
        headerContentRenderer={headerContentRenderer}
        onLoadMoreRows={onLoadMoreRows}
      />
    </div>
  );
}
