import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import Button from '../../../../../components/Button';
import { getFieldLink } from '../../../../../helpers/navigation';

import './index.scss';

const CompareLayersToolsPanel = () => {
  const { t } = useTranslation();
  const { farmUuid, fieldUuid } = useParams();

  return (
    <div className="compare-layers-tools-panel">
      {t('compare-layers.screen-description')}
      <Button
        variant="outlined"
        component={Link}
        to={getFieldLink(farmUuid, fieldUuid)}
      >
        {t('general.controls.close')}
      </Button>
    </div>
  );
};

export default CompareLayersToolsPanel;
