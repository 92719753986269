import type { useHistory } from 'react-router-dom';

import {
  getSearchParam,
  updateSearchParam,
} from '../../../../../helpers/navigation';
import PanelView from '../constants/view';

export const getView = (search: string) => {
  const viewTypeParam = getSearchParam(search, 'view');
  const productIndexParam = getSearchParam(search, 'product');

  return {
    viewType: viewTypeParam ? (viewTypeParam as PanelView) : null,
    productIndex: productIndexParam ? parseInt(productIndexParam, 10) : null,
  };
};

export const updateView = (
  search: string,
  history: ReturnType<typeof useHistory>,
  params: {
    type: PanelView;
    productIndex: number | null;
  },
) => {
  const updates = [];

  if (params.type != null) {
    updates.push({ name: 'view', value: params.type });
  }

  if (params.productIndex != null) {
    updates.push({ name: 'product', value: params.productIndex.toString() });
  }

  return updateSearchParam(search, history, updates);
};
