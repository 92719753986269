mutation saveVamapWithGeoMaps(
  $vamapInput: SaveVectorAnalysisMapInput!
  $geoMapsInput: SetGeoMapsInput
  $areaUnit: AreaUnit!
  $hasColors: Boolean!
  $hasGeojson: Boolean!
  $hasAttributesJson: Boolean!
) {
  saveVectorAnalysisMap(input: $vamapInput) {
    uuid
    name
    zonesMapGeojson(unit: $areaUnit) @include(if: $hasGeojson)
    attributesJson(unit: $areaUnit) @include(if: $hasAttributesJson)
  }
  setGeoMaps(input: $geoMapsInput) @include(if: $hasColors) {
    shortName
    url
    classes
    hexColorCodes
  }
}
