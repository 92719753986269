import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import GetAppIcon from '@material-ui/icons/GetApp';
import HomeIcon from '@material-ui/icons/Home';

import PopupHeader from '../../../../../../components/Popups/PopupHeader';
import Button from '../../../../../../components/Button';
import RestartSameIcon from '../../../components/Icons/restartSame';
import RestartIcon from '../../../components/Icons/restart';
import { useAppDispatch } from '../../../../../../app/store/helpers/functions';
import {
  downloadSuccessEquationMaps,
  restartAnotherFields,
  restartSameFields,
} from '../../../createBatchEquationBasedAnalysisSlice';
import { PAGES_ROOTS, getRootLink } from '../../../../../../helpers/navigation';

import './index.scss';

export default function FinishGenerationPopup({
  onCancel,
}: {
  onCancel: () => void;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleRestartSameFields = () => {
    dispatch(restartSameFields());
    onCancel();
  };

  const handleRestartAnotherFields = () => {
    dispatch(restartAnotherFields());
    onCancel();
  };

  const handleDownloadAll = () => {
    dispatch(downloadSuccessEquationMaps());
    onCancel();
  };

  const handleGoToHome = () => {
    onCancel();
  };

  return (
    <Dialog
      open
      classes={{
        paper: 'finish-generation-popup__paper',
      }}
    >
      <PopupHeader
        classes={{
          root: 'finish-generation-popup__header',
        }}
        title={t('batch-equation-based.steps.preview.finish-popup.title')}
        onCancel={onCancel}
      />
      <DialogContent className="finish-generation-popup__content">
        <DialogContentText className="finish-generation-popup__content-text">
          {t('batch-equation-based.steps.preview.finish-popup.description')}
        </DialogContentText>
        <div className="finish-generation-popup__buttons">
          <Button
            classes={{
              root: 'finish-generation-popup__button',
              startIcon: 'finish-generation-popup__button-icon',
            }}
            variant="outlined"
            color="primary"
            startIcon={<RestartSameIcon />}
            onClick={handleRestartSameFields}
          >
            {t(
              'batch-equation-based.steps.preview.finish-popup.restart-same-fields',
            )}
          </Button>
          <Button
            classes={{
              root: 'finish-generation-popup__button',
              startIcon: 'finish-generation-popup__button-icon',
            }}
            variant="outlined"
            color="primary"
            startIcon={<RestartIcon />}
            onClick={handleRestartAnotherFields}
          >
            {t(
              'batch-equation-based.steps.preview.finish-popup.restart-another-fields',
            )}
          </Button>
          <Button
            classes={{
              root: 'finish-generation-popup__button',
              startIcon: 'finish-generation-popup__button-icon',
            }}
            variant="outlined"
            color="primary"
            startIcon={<GetAppIcon />}
            onClick={handleDownloadAll}
          >
            {t('batch-equation-based.steps.preview.finish-popup.download-all')}
          </Button>
          <Button
            classes={{
              root: 'finish-generation-popup__button',
              startIcon: 'finish-generation-popup__button-icon',
            }}
            variant="outlined"
            color="primary"
            component={Link}
            to={getRootLink(PAGES_ROOTS.homepage)}
            startIcon={<HomeIcon />}
            onClick={handleGoToHome}
          >
            {t('batch-equation-based.steps.preview.finish-popup.go-to-home')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
