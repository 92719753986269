query getVamaps(
  $farmUuids: [ID]
  $fieldUuids: [ID]
  $vectorAnalysisMapUuids: [ID]
  $areaUnit: AreaUnit!
) {
  getFarms(farmUuids: $farmUuids) {
    uuid
    fields(fieldUuids: $fieldUuids) {
      uuid
      vectorAnalysisMaps(
        vectorAnalysisMapUuids: $vectorAnalysisMapUuids
        statuses: [EXECUTED, SAVED]
      ) {
        uuid
        fieldUuid
        name
        type
        analysisType
        area(unit: $areaUnit)
        createdDate
        status
        geoMaps {
          shortName
          url
          classes
          hexColorCodes
        }
        satelliteImages {
          satelliteImage {
            uuid
          }
        }
        soilDataset {
          uuid
        }
        yieldDataset {
          uuid
        }
        asAppliedDataset {
          uuid
        }
        topographyMap {
          uuid
        }
        dataLayers {
          factor
          index
          satelliteImages {
            satelliteImage {
              uuid
              acquisitionDate
            }
          }
          soilDataset {
            uuid
          }
          soilAttribute
          yieldDataset {
            uuid
          }
          yieldAttribute
          asAppliedDataset {
            uuid
          }
          asAppliedAttribute
          topographyMap {
            uuid
          }
          topographyAttribute
        }
        zonesOperationMaps {
          map {
            uuid
          }
          zoneNumbers
        }
        parameters {
          index
          numberOfZones
          soilAttribute
          yieldAttribute
          asAppliedAttribute
          topographyAttribute
          zonesOperation
          polygonMinArea
          dataClassificationType
        }
        variabilityFactor
      }
    }
  }
}
