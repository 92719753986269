import React, { ReactNode } from 'react';

import './index.scss';

export default function OperationsFilters({
  children,
}: {
  children: ReactNode;
}) {
  return <div className="operations-filters">{children}</div>;
}
