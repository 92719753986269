import { Pin } from '../../types';

const preparePhotos = ({
  addedPhotos = [],
  photos = [],
  deletedPhotos = [],
}: {
  addedPhotos: Pin['photos'];
  photos: Pin['photos'];
  deletedPhotos: string[];
}) => {
  let processedPhotos = photos;

  if (deletedPhotos?.length) {
    processedPhotos = photos.filter(
      (photo) => !deletedPhotos.includes(photo.uuid),
    );
  }

  if (addedPhotos?.length) {
    processedPhotos = [
      ...processedPhotos,
      ...addedPhotos.map(
        ({ uuid, name, arrayBuffer, noteUuid, commentUuid, type }) => ({
          uuid,
          name,
          noteUuid,
          commentUuid,
          isRecentlySaved: true,
          url: arrayBuffer
            ? URL.createObjectURL(
                new Blob([arrayBuffer], { type: `image/${type}` }),
              )
            : '',
        }),
      ),
    ];
  }
  return processedPhotos;
};

export default preparePhotos;
