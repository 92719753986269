import React, { ReactElement } from 'react';

import Tooltip from '../../../../../../components/Tooltip';
import { GeoMap } from '../../../../../../helpers/types/api';
import {
  getDatasetAttributeNameHandler,
  getFullAttribute,
} from '../../../../../../helpers/functions/entities/dataset';
import { getAttributeUnitLabel } from '../../../../../../helpers/functions/units/attributeUnit';
import { getDatasetColorsRanges } from '../../../../../../helpers/functions/map/legend';
import { TransformedDataset } from '../../../../../../helpers/types/dataset';
import ColorsRangeLegend from '../../../../../../components/Map/Controls/MiniLegend/ColorsRangeLegend';

import './index.scss';

export default function DatasetLegendTooltip({
  dataset,
  attribute,
  geoMap,
  children,
}: {
  dataset: TransformedDataset;
  attribute: string;
  geoMap: GeoMap;
  children: ReactElement;
}) {
  const ranges = getDatasetColorsRanges({ geoMap });
  const nameHandler = getDatasetAttributeNameHandler(dataset.fullAttributes);
  const fullAttribute = getFullAttribute(attribute, dataset.fullAttributes);

  return (
    <Tooltip
      classes={{
        label: 'dataset-preview-legend-tooltip__label',
        tooltip: 'dataset-preview-legend-tooltip__tooltip',
      }}
      placement="bottom"
      tooltip={
        <div className="dataset-preview-legend-tooltip">
          <ColorsRangeLegend
            minified
            ranges={ranges}
            name={nameHandler(attribute)}
            unit={getAttributeUnitLabel(fullAttribute?.unit)}
          />
        </div>
      }
    >
      {children}
    </Tooltip>
  );
}
