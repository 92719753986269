import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getI18nAreaUnit } from '../../../../helpers';
import { selectAreaUnit } from '../../../user/userSelectors';
import { prettifyNumber } from '../../../../helpers/markup';

import './index.scss';

export default function FieldTitle({
  fieldArea,
  fieldName,
}: {
  fieldArea: number;
  fieldName: string;
}) {
  const areaUnit = useSelector(selectAreaUnit);
  const { t } = useTranslation();

  return (
    <>
      {fieldName && <span>{fieldName}</span>}
      {fieldArea && areaUnit && (
        <span className="field-title__area">
          ({prettifyNumber(fieldArea)} {t(getI18nAreaUnit(areaUnit))})
        </span>
      )}
    </>
  );
}
