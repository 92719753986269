import React from 'react';
import clsx from 'clsx';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiRadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';

import './index.scss';

const RadioGroup = ({
  options,
  value,
  horizontal = false,
  classes = {},
  onChange,
}) => (
  // TODO: wrap with FormControl and add optional FormLabel
  // (https://v4.mui.com/components/radio-buttons/#radiogroup)
  <MuiRadioGroup
    className={clsx('radio-group', classes?.root)}
    row={horizontal}
    value={value}
    onChange={(_event, v) => onChange(v)}
  >
    {options.map((option) => (
      <FormControlLabel
        key={option.value}
        className={clsx('radio-group__button', {
          'radio-group__button_horizontal': horizontal,
        })}
        value={option.value}
        disabled={option.disabled}
        control={
          <Radio color="primary" className="radio-group__button-input" />
        }
        label={
          <div className="radio-group__button-label-container">
            <Typography className="radio-group__button-label radio-group__button-label_primary">
              {option.title}
            </Typography>
            <Typography className="radio-group__button-label radio-group__button-label_secondary">
              {option.description}
            </Typography>
          </div>
        }
      />
    ))}
  </MuiRadioGroup>
);
export default RadioGroup;
