import i18n from 'i18next';

import type {
  FullAttribute,
  TransformedYieldDataset,
} from '../../../../../helpers/types/dataset';
import {
  NullableAvgTotalCalibrateCondition,
  NullableMinMaxCalibrateCondition,
  NullableMinMaxCleanCondition,
  NullableMinMaxCleanUSDACondition,
} from '../../types/actions';
import type {
  FullAttributeItem,
  NullableMinMaxCondition,
  NullableMinMaxUSDACondition,
} from '../../types/ui';
import { getAttributeString } from './attributes';
import { USDACleaningAttribute } from '../constants/ui';

export const createFullAttributeItem = (
  fullAttribute: FullAttribute,
): FullAttributeItem => ({
  value: getAttributeString(fullAttribute),
  title: fullAttribute.fullName || fullAttribute.transliteratedName,
});

export const getComboBoxOptionSelected = (
  option: FullAttributeItem,
  value: FullAttributeItem,
) => option.value === value.value;

export const isFullAttributeItemDisabled = (
  conditions: { attribute: string | null }[],
  attribute: FullAttributeItem,
  selectedAttribute?: FullAttributeItem,
) => {
  const conditionAttributes = new Set(conditions.map((c) => c.attribute));

  return (
    attribute.value !== selectedAttribute?.value &&
    conditionAttributes.has(attribute.value)
  );
};

export const getAvailableFullAttributeItems = (
  availableAttributes: string[] | null,
  dataset?: TransformedYieldDataset,
) => {
  const availableAttributesValues = new Set(availableAttributes);

  return (dataset?.fullAttributes || [])
    .filter((fullAttribute) => {
      const fullAttributeItem = createFullAttributeItem(fullAttribute);

      return availableAttributesValues.has(fullAttributeItem.value);
    })
    .map((fullAttribute) => createFullAttributeItem(fullAttribute));
};

export const transformCalibrateMinMaxConditions = (
  conditions: NullableMinMaxCalibrateCondition[],
): NullableMinMaxCondition[] =>
  conditions.map((condition) => ({
    attribute: condition.calibrationAttribute,
    min: condition.min,
    minIncluded: condition.minIncluded,
    max: condition.max,
    maxIncluded: condition.maxIncluded,
  }));

export const transformCalibrateAvgTotalConditions = (
  conditions: NullableAvgTotalCalibrateCondition[],
): { attribute: string | null }[] =>
  conditions.map((condition) => ({
    attribute: condition.calibrationAttribute,
  }));

export const transformCleanMinMaxConditions = (
  conditions: NullableMinMaxCleanCondition[],
): NullableMinMaxCondition[] =>
  conditions.map((condition) => ({
    attribute: condition.cleanAttribute,
    min: condition.min,
    max: condition.max,
  }));

export const transformCleanMinMaxUSDAConditions = ({
  conditions,
  titleI18nKeys,
  descriptionI18nKeys,
}: {
  conditions: NullableMinMaxCleanUSDACondition[];
  titleI18nKeys: Record<USDACleaningAttribute, string>;
  descriptionI18nKeys: Record<USDACleaningAttribute, string>;
}): NullableMinMaxUSDACondition[] =>
  Object.values(USDACleaningAttribute).map((type) => {
    const condition = conditions.find((c) => c.type === type);

    return {
      attribute: condition?.cleanAttribute ?? null,
      min: condition?.min ?? null,
      max: condition?.max ?? null,
      selected: condition?.selected ?? false,
      title: i18n.t(titleI18nKeys[type]),
      description: i18n.t(descriptionI18nKeys[type]),
      stdNumber: condition?.stdNumber,
      withStdNumber: type === USDACleaningAttribute.yield,
    };
  });
