import { AttributeStatistics } from '../../../../../helpers/types/dataset';
import {
  NullableMinMaxCalibrateCondition,
  NullableMinMaxCleanCondition,
} from '../../types/actions';

export const populateMinMaxCleanConditions = <
  T extends NullableMinMaxCleanCondition,
>(
  condition: T,
  statistics?: AttributeStatistics[] | null,
) => {
  if (!condition.cleanAttribute) {
    return condition;
  }

  const attributeStatistics = statistics?.find(
    ({ attribute }) => attribute === condition.cleanAttribute,
  );

  return {
    ...condition,
    min: condition.min ?? attributeStatistics?.min ?? null,
    max: condition.max ?? attributeStatistics?.max ?? null,
  };
};

export const populateMinMaxCalibrateConditions = (
  condition: NullableMinMaxCalibrateCondition,
  statistics?: AttributeStatistics[] | null,
) => {
  if (!condition.calibrationAttribute) {
    return condition;
  }

  const attributeStatistics = statistics?.find(
    ({ attribute }) => attribute === condition.calibrationAttribute,
  );

  return {
    ...condition,
    min: condition.min ?? attributeStatistics?.min ?? null,
    max: condition.max ?? attributeStatistics?.max ?? null,
  };
};
