import React from 'react';
import TableCell from '@material-ui/core/TableCell';

import getCellClasses from '../../../helpers/functions/getCellClasses';

import './index.scss';

function LabeledTextCell({ headCell = {}, row = {} }) {
  const value = row[headCell.id];

  return (
    <TableCell
      rowSpan={headCell.rowSpan}
      align={headCell.horizontalAlign || 'left'}
      className={getCellClasses('labeled-text-cell', headCell, row)}
    >
      <span className="labeled-text-cell__title">{value.title}</span>
      {value.label}
    </TableCell>
  );
}

export default LabeledTextCell;
