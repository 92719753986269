import React from 'react';
import clsx from 'clsx';
import Backdrop, { BackdropProps } from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import './index.scss';

const BackdropLoading = ({
  classes,
  inlined,
  size,
  label,
}: {
  classes?: BackdropProps['classes'];
  inlined?: boolean;
  size?: number | string;
  label?: string;
}) => (
  <Backdrop
    open
    classes={classes}
    className={clsx('backdrop-loading', {
      'backdrop-loading_inlined': inlined,
    })}
  >
    <div className="backdrop-loading__container">
      <CircularProgress data-test="backdrop-loading" size={size} />
      {label && (
        <Typography className="backdrop-loading__label">{label}</Typography>
      )}
    </div>
  </Backdrop>
);

export default BackdropLoading;
