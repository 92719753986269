export const DEFAULT_TOPOGRAPHY_MAP_ATTRIBUTES = [
  'dem',
  'hillshade',
  'elevation+hillshade',
  'slope',
  'aspect',
  'aspect+slope',
  'tpi',
  'tri',
  'roughness',
];

export const CREATE_ANALYSIS_TOPOGRAPHY_MAP_ATTRIBUTES =
  DEFAULT_TOPOGRAPHY_MAP_ATTRIBUTES.filter(
    (attribute) =>
      attribute !== 'elevation+hillshade' && attribute !== 'aspect+slope',
  );

export const ATTRIBUTE_LABELS_MAP = {
  dem: 'ELEVATION',
  hillshade: 'HILLSHADE',
  'elevation+hillshade': 'ELEVATION + HILLSHADE',
  slope: 'SLOPE',
  aspect: 'ASPECT',
  'aspect+slope': 'ASPECT + SLOPE',
  tpi: 'RELIEF POSITION',
  tri: 'RUGGEDNESS',
  roughness: 'ROUGHNESS',
};
