import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../../app/store/helpers/types';
import {
  asAppliedDatasetsAdapter,
  equationMapsAdapter,
  farmsAdapter,
  fieldsAdapter,
  satelliteImagesAdapter,
  soilDatasetsAdapter,
  topographyMapsAdapter,
  vectorAnalysisMapsAdapter,
  yieldDatasetsAdapter,
} from './assetsAdapter';
import { mapUuidsToEntities } from './helpers/functions/adapter';
import type {
  FieldDatasetEntity,
  EquationMapEntity,
  FarmEntity,
  FieldAssetEntity,
  FieldEntity,
} from './types/state';
import { datasetsComparator } from '../ui/dataLayersView/helpers/functions/comparator';
import { AssetType } from '../../helpers/constants/entities/asset';
import LoadStatus from '../../helpers/constants/utils/loadStatus';

const farmsSelectors = farmsAdapter.getSelectors<RootState>(
  (state) => state.assets.farms,
);

const fieldsSelectors = fieldsAdapter.getSelectors<RootState>(
  (state) => state.assets.fields,
);

const soilDatasetsSelectors = soilDatasetsAdapter.getSelectors<RootState>(
  (state) => state.assets.soilDatasets,
);

const yieldDatasetsSelectors = yieldDatasetsAdapter.getSelectors<RootState>(
  (state) => state.assets.yieldDatasets,
);

const asAppliedDatasetsSelectors =
  asAppliedDatasetsAdapter.getSelectors<RootState>(
    (state) => state.assets.asAppliedDatasets,
  );

const topographyMapsSelectors = topographyMapsAdapter.getSelectors<RootState>(
  (state) => state.assets.topographyMaps,
);

const vectorAnalysisMapsSelectors =
  vectorAnalysisMapsAdapter.getSelectors<RootState>(
    (state) => state.assets.vectorAnalysisMaps,
  );

const equationMapsSelectors = equationMapsAdapter.getSelectors<RootState>(
  (state) => state.assets.equationMaps,
);

const satelliteImagesSelectors = satelliteImagesAdapter.getSelectors<RootState>(
  (state) => state.assets.satelliteImages,
);

export const selectFarmsAssetsLoadStatus = ({ assets }: RootState) =>
  assets.farms.loadStatus;

export const selectFieldsAssetsLoadStatus = ({ assets }: RootState) =>
  assets.fields.loadStatus;

export const selectSoilDatasetsAssetsLoadStatus = ({ assets }: RootState) =>
  assets.soilDatasets.loadStatus;

export const selectYieldDatasetsAssetsLoadStatus = ({ assets }: RootState) =>
  assets.yieldDatasets.loadStatus;

export const selectAsAppliedDatasetsAssetsLoadStatus = ({
  assets,
}: RootState) => assets.asAppliedDatasets.loadStatus;

export const selectTopographyMapsAssetsLoadStatus = ({ assets }: RootState) =>
  assets.topographyMaps.loadStatus;

export const selectVectorAnalysisMapsAssetsLoadStatus = ({
  assets,
}: RootState) => assets.vectorAnalysisMaps.loadStatus;

export const selectEquationMapsAssetsLoadStatus = ({ assets }: RootState) =>
  assets.equationMaps.loadStatus;

export const selectSatelliteImagesLoadStatus = ({ assets }: RootState) =>
  assets.satelliteImages.loadStatus;

export const selectFieldsAnyAssetsLoading = createSelector(
  selectFarmsAssetsLoadStatus,
  selectFieldsAssetsLoadStatus,
  selectSoilDatasetsAssetsLoadStatus,
  selectYieldDatasetsAssetsLoadStatus,
  selectAsAppliedDatasetsAssetsLoadStatus,
  selectTopographyMapsAssetsLoadStatus,
  selectVectorAnalysisMapsAssetsLoadStatus,
  selectEquationMapsAssetsLoadStatus,
  (_: RootState, fieldUuids: string[]) => fieldUuids,
  (
    farmsLoadStatus,
    fieldsLoadStatus,
    soilDatasetsLoadStatus,
    yieldDatasetsLoadStatus,
    asAppliedDatasetsLoadStatus,
    topographyMapsLoadStatus,
    vectorAnalysisMapsLoadStatus,
    equationMapsLoadStatus,
    fieldUuids,
  ) =>
    fieldUuids.some((fieldUuid) => {
      const fieldLoadStatuses = [
        farmsLoadStatus[fieldUuid],
        fieldsLoadStatus[fieldUuid],
        soilDatasetsLoadStatus[fieldUuid],
        yieldDatasetsLoadStatus[fieldUuid],
        asAppliedDatasetsLoadStatus[fieldUuid],
        topographyMapsLoadStatus[fieldUuid],
        vectorAnalysisMapsLoadStatus[fieldUuid],
        equationMapsLoadStatus[fieldUuid],
      ];

      return fieldLoadStatuses.some((status) => status === LoadStatus.loading);
    }),
);

export const selectFarmEntity = farmsSelectors.selectById;

export const selectFarmsEntities = farmsSelectors.selectEntities;

export const selectAllFields = fieldsSelectors.selectAll;

export const selectFieldEntity = fieldsSelectors.selectById;

export const selectFieldsEntities = fieldsSelectors.selectEntities;

export const selectFieldsIds = fieldsSelectors.selectIds;

export const selectSoilDatasetsEntities = soilDatasetsSelectors.selectEntities;

export const selectYieldDatasetsEntities =
  yieldDatasetsSelectors.selectEntities;

export const selectAsAppliedDatasetsEntities =
  asAppliedDatasetsSelectors.selectEntities;

export const selectTopographyMapsEntities =
  topographyMapsSelectors.selectEntities;

export const selectVectorAnalysisMapsEntities =
  vectorAnalysisMapsSelectors.selectEntities;

export const selectAllEquationMaps = equationMapsSelectors.selectAll;

export const selectEquationMapsEntities = equationMapsSelectors.selectEntities;

export const selectEquationMapEntity = equationMapsSelectors.selectById;

export const selectSatelliteImagesEntities =
  satelliteImagesSelectors.selectEntities;

export const selectFarmsEntitiesByFieldUuids = createSelector(
  selectFarmsEntities,
  selectFieldsEntities,
  (_: RootState, fieldUuids: string[]) => fieldUuids,
  (farmsEntities, fieldsEntities, fieldUuids) =>
    fieldUuids.reduce<Record<string, FarmEntity | undefined>>(
      (acc, fieldUuid) => {
        const farmUuid = fieldsEntities[fieldUuid]?.farmUuid;

        if (farmUuid) {
          acc[farmUuid] = farmsEntities[farmUuid];
        }

        return acc;
      },
      {},
    ),
);

export const selectFarmsEntitiesListByFieldUuids = createSelector(
  selectFarmsEntities,
  selectFieldsEntities,
  (_: RootState, fieldUuids: string[]) => fieldUuids,
  (farmsEntities, fieldsEntities, fieldUuids) => {
    const farmsEntitiesSet = fieldUuids.reduce<Set<FarmEntity>>(
      (acc, fieldUuid) => {
        const farmUuid = fieldsEntities[fieldUuid]?.farmUuid;

        if (farmUuid) {
          const farmEntity = farmsEntities[farmUuid];

          if (farmEntity) {
            acc.add(farmEntity);
          }
        }

        return acc;
      },
      new Set(),
    );

    return [...farmsEntitiesSet];
  },
);

export const selectFieldsEntitiesList = createSelector(
  selectFieldsEntities,
  (_: RootState, uuids: string[]) => uuids,
  (fieldsEntities, uuids) =>
    uuids.reduce<FieldEntity[]>((acc, uuid) => {
      const fieldEntity = fieldsEntities[uuid];

      if (fieldEntity) {
        acc.push(fieldEntity);
      }

      return acc;
    }, []),
);

export const selectFieldAssetsEntitiesByType = createSelector(
  selectFieldsEntities,
  selectSoilDatasetsEntities,
  selectYieldDatasetsEntities,
  selectAsAppliedDatasetsEntities,
  selectTopographyMapsEntities,
  (_: RootState, fieldUuid?: string, assetType?: AssetType) => ({
    fieldUuid,
    assetType,
  }),
  (
    fieldsEntities,
    soilDatasetsEntities,
    yieldDatasetsEntities,
    asAppliedDatasetsEntities,
    topographyMapsEntities,
    { fieldUuid, assetType },
  ) => {
    let result: FieldAssetEntity[] = [];

    for (const [uuid, entity] of Object.entries(fieldsEntities)) {
      if (fieldUuid === uuid) {
        switch (assetType) {
          case AssetType.soilDataset:
            result = mapUuidsToEntities(
              soilDatasetsEntities,
              entity?.soilDatasets,
            );
            break;
          case AssetType.yieldDataset:
            result = mapUuidsToEntities(
              yieldDatasetsEntities,
              entity?.yieldDatasets,
            );
            break;
          case AssetType.asAppliedDataset:
            result = mapUuidsToEntities(
              asAppliedDatasetsEntities,
              entity?.asAppliedDatasets,
            );
            break;
          case AssetType.topographyMap:
            result = mapUuidsToEntities(
              topographyMapsEntities,
              entity?.topographyMaps,
            );
            break;
          default:
            break;
        }

        return result;
      }
    }

    return result;
  },
);

export const selectSortedFieldsDatasets = createSelector(
  selectFieldsEntities,
  selectSoilDatasetsEntities,
  selectYieldDatasetsEntities,
  selectAsAppliedDatasetsEntities,
  selectTopographyMapsEntities,
  (_: RootState, fieldUuids: string[]) => fieldUuids,
  (
    fieldsEntities,
    soilDatasetsEntities,
    yieldDatasetsEntities,
    asAppliedDatasetsEntities,
    topographyMapsEntities,
    fieldUuids,
  ) => {
    const result: Record<string, FieldDatasetEntity[]> = {};

    for (const [fieldUuid, fieldEntity] of Object.entries(fieldsEntities)) {
      if (fieldUuids.includes(fieldUuid)) {
        const soilDatasetsEntitiesList = mapUuidsToEntities(
          soilDatasetsEntities,
          fieldEntity?.soilDatasets,
        );
        const yieldDatasetsEntitiesList = mapUuidsToEntities(
          yieldDatasetsEntities,
          fieldEntity?.yieldDatasets,
        );
        const asAppliedDatasetsEntitiesList = mapUuidsToEntities(
          asAppliedDatasetsEntities,
          fieldEntity?.asAppliedDatasets,
        );
        const topographyMapsEntitiesList = mapUuidsToEntities(
          topographyMapsEntities,
          fieldEntity?.topographyMaps,
        );

        result[fieldUuid] = [
          ...soilDatasetsEntitiesList.sort(datasetsComparator),
          ...yieldDatasetsEntitiesList.sort(datasetsComparator),
          ...asAppliedDatasetsEntitiesList.sort(datasetsComparator),
          ...topographyMapsEntitiesList,
        ];
      }
    }

    return result;
  },
);

export const selectFieldAssetsEntities = createSelector(
  selectFieldsEntities,
  selectSoilDatasetsEntities,
  selectYieldDatasetsEntities,
  selectAsAppliedDatasetsEntities,
  selectTopographyMapsEntities,
  selectSatelliteImagesEntities,
  selectVectorAnalysisMapsEntities,
  selectEquationMapsEntities,
  (_: RootState, fieldUuid: string) => fieldUuid,
  (
    fieldsEntities,
    soilDatasetsEntities,
    yieldDatasetsEntities,
    asAppliedDatasetsEntities,
    topographyMapsEntities,
    satelliteImagesEntities,
    vectorAnalysisMapsEntities,
    equationMapsEntities,
    fieldUuid,
  ) => {
    const fieldEntity = fieldsEntities[fieldUuid];

    const soilDatasetsEntitiesList = mapUuidsToEntities(
      soilDatasetsEntities,
      fieldEntity?.soilDatasets,
    );
    const yieldDatasetsEntitiesList = mapUuidsToEntities(
      yieldDatasetsEntities,
      fieldEntity?.yieldDatasets,
    );
    const asAppliedDatasetsEntitiesList = mapUuidsToEntities(
      asAppliedDatasetsEntities,
      fieldEntity?.asAppliedDatasets,
    );
    const topographyMapsEntitiesList = mapUuidsToEntities(
      topographyMapsEntities,
      fieldEntity?.topographyMaps,
    );
    const satelliteImagesEntitiesList = mapUuidsToEntities(
      satelliteImagesEntities,
      fieldEntity?.satelliteImages,
    );
    const vectorAnalysisMapsEntitiesList = mapUuidsToEntities(
      vectorAnalysisMapsEntities,
      fieldEntity?.vectorAnalysisMaps,
    );
    const equationMapsEntitiesList = mapUuidsToEntities(
      equationMapsEntities,
      fieldEntity?.equationMaps,
    );

    return {
      soilDatasets: soilDatasetsEntitiesList,
      yieldDatasets: yieldDatasetsEntitiesList,
      asAppliedDatasets: asAppliedDatasetsEntitiesList,
      topographyMaps: topographyMapsEntitiesList,
      satelliteImages: satelliteImagesEntitiesList,
      vectorAnalysisMaps: vectorAnalysisMapsEntitiesList,
      equationMaps: equationMapsEntitiesList,
    };
  },
);

export const selectFieldAssetEntity = createSelector(
  selectSoilDatasetsEntities,
  selectYieldDatasetsEntities,
  selectAsAppliedDatasetsEntities,
  selectTopographyMapsEntities,
  selectVectorAnalysisMapsEntities,
  selectEquationMapsEntities,
  (_: RootState, assetUuid: string | null) => assetUuid,
  (
    soilDatasetsEntities,
    yieldDatasetsEntities,
    asAppliedDatasetsEntities,
    topographyMapsEntities,
    vectorAnalysisMapsEntities,
    equationMapsEntities,
    assetUuid,
  ) =>
    ({
      ...soilDatasetsEntities,
      ...yieldDatasetsEntities,
      ...asAppliedDatasetsEntities,
      ...topographyMapsEntities,
      ...vectorAnalysisMapsEntities,
      ...equationMapsEntities,
    })[assetUuid ?? ''],
);

export const selectEquationMapsEntitiesList = createSelector(
  selectEquationMapsEntities,
  (_: RootState, uuids: string[]) => uuids,
  (equationMapsEntities, uuids) =>
    uuids.reduce<EquationMapEntity[]>((acc, uuid) => {
      const equationMapEntity = equationMapsEntities[uuid];

      if (equationMapEntity) {
        acc.push(equationMapEntity);
      }

      return acc;
    }, []),
);

export const selectIsFieldSatelliteImagesLoading = createSelector(
  selectSatelliteImagesLoadStatus,
  (_: RootState, fieldUuid: string) => fieldUuid,
  (satelliteImagesLoadStatus, fieldUuid) =>
    satelliteImagesLoadStatus[fieldUuid] === LoadStatus.loading ||
    !satelliteImagesLoadStatus[fieldUuid],
);
