import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { PAGES_ROOTS } from '../helpers/navigation';

export default function useCurrentPage() {
  const { pathname } = useLocation();

  const [page, setPage] = useState<string>('');

  useEffect(() => {
    const cleanPath = pathname.replace(/^\/|^#\/?/g, '');
    const sortedRoots = Object.values(PAGES_ROOTS).sort(
      (a, b) => b.length - a.length,
    );
    const currentPage = sortedRoots.find((key) => cleanPath.startsWith(key));

    setPage(currentPage ?? cleanPath);
  }, [pathname]);

  return page;
}
