import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from '../../../../../../components/Button';
import Tabs from '../../../../../../components/Tabs';
import PanelView from '../../../helpers/constants/view';
import { getView, updateView } from '../../../helpers/functions/viewType';
import {
  useAppSelector,
  useAppDispatch,
} from '../../../../../../app/store/helpers/functions';
import { selectProducts } from '../../../zonesMapSelectors';
import { addEmptyProduct, removeProduct } from '../../../zonesMapSlice';

import './index.scss';

const MAX_PRODUCTS = 3;

const createTabValue = (
  view: PanelView | null,
  productIndex?: number | null,
) => {
  if (!view || productIndex == null) {
    return PanelView.description;
  }

  return `${view}-${productIndex}`;
};

const parseTabValue = (value: string) => {
  const [viewType, productIndex] = value.split('-');

  return {
    viewType: viewType as PanelView,
    productIndex: productIndex != null ? parseInt(productIndex, 10) : null,
  };
};

const createTab = ({
  value,
  label,
  productIndex,
  deletable = false,
}: {
  value: PanelView;
  label: string;
  productIndex?: number | null;
  deletable?: boolean;
}) => ({
  value: createTabValue(value, productIndex),
  label: { primary: label },
  deletable,
});

export default function TabsContainer() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();

  const { viewType: activeViewType, productIndex: activeProductIndex } =
    getView(location.search);
  const products = useAppSelector(selectProducts);
  const [tabs, setTabs] = useState([
    createTab({
      value: PanelView.description,
      label: t('zones-map.tabs.description'),
    }),
  ]);

  const tabValue = useMemo(
    () => createTabValue(activeViewType, activeProductIndex),
    [activeViewType, activeProductIndex],
  );

  useEffect(() => {
    setTabs([
      createTab({
        value: PanelView.description,
        label: t('zones-map.tabs.description'),
      }),
      ...products.map((product, index) =>
        createTab({
          value: PanelView.product,
          label: product.name,
          productIndex: index,
          deletable: true,
        }),
      ),
    ]);
  }, [t, products]);

  const handleTabChange = (_event: unknown, value: string) => {
    const { viewType, productIndex } = parseTabValue(value);

    updateView(location.search, history, {
      type: viewType,
      productIndex,
    });
  };

  const handleAddProduct = () => {
    if (products.length >= MAX_PRODUCTS) {
      return;
    }

    const productIndex = products.length;
    const productName = `${t('zones-map.rates.product.default-name')} ${productIndex + 1}`;

    dispatch(addEmptyProduct({ name: productName }));

    setTabs([
      ...tabs,
      createTab({
        value: PanelView.product,
        label: productName,
        productIndex,
      }),
    ]);

    updateView(location.search, history, {
      type: PanelView.product,
      productIndex,
    });
  };

  const handleTabRemove = (_e: unknown, tabIndex: number) => {
    // First tab is a description tab
    dispatch(removeProduct({ productIndex: tabIndex - 1 }));
    updateView(location.search, history, {
      type: PanelView.description,
      productIndex: null,
    });
  };

  if (activeProductIndex != null && tabs.length < 2) {
    return null;
  }

  return (
    <div className="zones-map-tabs-container">
      <Tabs
        variant="standard"
        withDeleteButtons
        tabs={tabs}
        value={tabValue}
        onTabChange={handleTabChange}
        onTabRemove={handleTabRemove}
      />
      <Button
        variant="outlined"
        disabled={products.length >= MAX_PRODUCTS}
        onClick={handleAddProduct}
      >
        {t('zones-map.rates.add-product')}
      </Button>
    </div>
  );
}
