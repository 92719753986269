import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Button from '../../../../../components/Button';
import PopupHeader from '../../../../../components/Popups/PopupHeader';
import SelectClientFarmField from '../../components/SelectClientFarmField';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../app/store/helpers/functions';
import { selectJDField, selectLabels } from '../../../../field/fieldSelectors';
import { ResourceToExport } from '../../types/api';
import { exportToJohnDeereAsAdmFiles } from '../../jdExportSlice';
import { getJDFieldOrgId } from '../../../../field/helpers/functions/field';
import useCurrentPage from '../../../../../hooks/useCurrentPage';
import AmplitudeAnalytics, {
  JohnDeereExportType,
} from '../../../../../helpers/classes/amplitudeAnalytics';

import './index.scss';

export default function ExportToJohnDeereAsAdmFilePopup({
  title,
  items,
  farmName,
  fieldName,
  jdExportType,
  onConfirm,
  onCancel,
}: {
  title: string;
  items: ResourceToExport[];
  farmName: string;
  fieldName: string;
  jdExportType: JohnDeereExportType;
  onCancel: () => void;
  onConfirm: () => void;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const page = useCurrentPage();
  const labels = useAppSelector(selectLabels);
  const jdField = useAppSelector(selectJDField);
  const orgId = getJDFieldOrgId(jdField);
  const [client, setClient] = useState('');
  const [farm, setFarm] = useState('');
  const [field, setField] = useState('');

  useEffect(() => {
    if (page) {
      AmplitudeAnalytics.trackExportToJDPopupOpened({
        page,
        type: jdExportType,
      });
    }
  }, [page, jdExportType]);

  const handleFarmChange = (newFarm: string) => {
    setFarm(newFarm);
  };
  const handleClientChange = (newClient: string) => {
    setClient(newClient);
  };
  const handleFieldChange = (newField: string) => {
    setField(newField);
  };
  const handleConfirm = () => {
    void dispatch(
      // @ts-expect-error
      exportToJohnDeereAsAdmFiles({
        exportResources: items,
        orgId,
        client,
        farm,
        field,
      }),
    );
    onConfirm();
  };

  return (
    <Dialog
      open
      classes={{
        paper: 'export-to-john-deere-as-adm-file-popup',
      }}
    >
      <PopupHeader title={title} onCancel={onCancel} />
      <DialogContent
        classes={{ root: 'export-to-john-deere-as-adm-file-popup__content' }}
      >
        <SelectClientFarmField
          fieldLabels={labels ?? []}
          fieldName={fieldName}
          farmName={farmName}
          farm={farm}
          field={field}
          client={client}
          onClientChangeHandler={handleClientChange}
          onFarmChangeHandler={handleFarmChange}
          onFieldChangeHandler={handleFieldChange}
        />
      </DialogContent>
      <DialogActions
        classes={{ root: 'export-to-john-deere-as-adm-file-popup__actions' }}
      >
        <Button variant="outlined" onClick={onCancel}>
          {t('general.controls.cancel')}
        </Button>
        <Button
          disabled={!(orgId && client && farm && field)}
          variant="contained"
          color="primary"
          onClick={handleConfirm}
        >
          {t('general.controls.proceed')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
