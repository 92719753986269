import getPatternsMatch from '../../../../../helpers/functions/utils/getPatternsMatch';
import {
  DELAY_PATTERNS,
  MACHINE_ID_PATTERNS,
  VELOCITY_PATTERNS,
  YIELD_PATTERNS,
  SWATH_WIDTH_PATTERNS,
} from '../constants/patterns';
import { FullAttribute } from '../../../../../helpers/types/dataset';

// TODO: almost duplicates findYieldAttribute
export const findYieldAttributes = (
  fullAttributes: FullAttribute[],
): FullAttribute[] => {
  const matches = getPatternsMatch(fullAttributes, YIELD_PATTERNS, {
    keys: ['fullName', 'transliteratedName'],
  });

  return [...matches].map((match) => match.item);
};

export const findMachineIdAttribute = (
  fullAttributes: FullAttribute[],
): FullAttribute | undefined => {
  const results = getPatternsMatch(fullAttributes, MACHINE_ID_PATTERNS, {
    keys: ['fullName', 'transliteratedName'],
  });

  return [...results][0]?.item;
};

export const findDelayAttribute = (
  fullAttributes: FullAttribute[],
): FullAttribute | undefined => {
  const results = getPatternsMatch(fullAttributes, DELAY_PATTERNS, {
    keys: ['fullName', 'transliteratedName'],
  });

  return [...results][0]?.item;
};

export const findVelocityAttribute = (
  fullAttributes: FullAttribute[],
): FullAttribute | undefined => {
  const results = getPatternsMatch(fullAttributes, VELOCITY_PATTERNS, {
    keys: ['fullName', 'transliteratedName'],
  });

  return [...results][0]?.item;
};

export const findSwathWidthAttribute = (
  fullAttributes: FullAttribute[],
): FullAttribute | undefined => {
  const results = getPatternsMatch(fullAttributes, SWATH_WIDTH_PATTERNS, {
    keys: ['fullName', 'transliteratedName'],
  });

  return [...results][0]?.item;
};

export const getAttributeString = (fullAttribute: FullAttribute): string =>
  fullAttribute.transliteratedName;
