import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import i18n from 'i18next';

import { JOHN_DEERE_DEFAULT_PAGE_SIZE_PAGINATION } from './helpers/constants/api';
import {
  selectExportFlowFilter,
  selectExportSelectedZonesMaps,
  selectLastEvaluatedKey,
  selectLegendZonesMap,
} from './jdExportSelectors';
import {
  executeExportToJohnDeereAsFiles,
  executeExportToJohnDeereAsMapsLayers,
  executeExportGeoPardFieldsToJohnDeereAsFiles,
  executeExportToJohnDeereAsAdmFiles,
} from './jdExportAPI';
import singleAtTheTime from '../../../app/store/mixins/singleAtTheTime';
import { fetchZonesMapsList } from '../zonesMaps/zonesMapsSlice';
import { fetchVamapAssets } from '../../field/fieldAPI';
import { MAX_ASSETS_COUNT } from '../downloadFiles/helpers/constants/assets';
import {
  warningNotify,
  errorNotify,
  successNotify,
} from '../../notifications/helpers/functions/notify';
import { CustomError } from '../../../helpers/functions/utils/errorHandling';
import { openPopup } from '../popups/popupsSlice';
import { selectAreaUnit } from '../../user/userSelectors';
import { getUserDataFetcher } from '../applicationShell/applicationShellSlice';
import { selectLabelsMap } from '../../labels/labelsSelectors';
import transformToFilterUpdates from '../fieldsList/helpers/functions/transformToFilterUpdates';
import POPUPS from '../popups/helpers/constants/popups';

const initialState = {
  step: 'selectOrganization',
  selectedOrganization: null,
  zonesMaps: null,
  legend: {},
  selectZonesMaps: {
    zonesMaps: [],
    filter: {
      farm: null,
      field: null,
      type: null,
      purpose: null,
      labels: [],
      withRates: false,
    },
  },
  isLoading: false,
};

export const fetchMoreZonesMapsExportFlow = createAsyncThunk(
  'jdExport/fetchMoreZonesMapsExportFlow',
  async (_payload, { getState, dispatch }) => {
    const state = getState();
    const labelsMap = selectLabelsMap(state);
    const { labels, ...restFilters } = selectExportFlowFilter(state);
    const { labels: labelsFilter } = transformToFilterUpdates(
      labels,
      labelsMap,
    );

    return dispatch(
      fetchZonesMapsList({
        ...restFilters,
        labels: labelsFilter,
        pageSize: JOHN_DEERE_DEFAULT_PAGE_SIZE_PAGINATION,
        lastEvaluatedKey: selectLastEvaluatedKey(state),
        areaUnit: selectAreaUnit(state),
      }),
    );
  },
);

export const updateExportFlowFilter = singleAtTheTime(
  createAsyncThunk(
    'jdExport/updateExportFlowFilter',
    async (_payload, { getState, dispatch }) => {
      await getUserDataFetcher();

      const state = getState();
      const labelsMap = selectLabelsMap(state);
      const { labels, ...restFilters } = selectExportFlowFilter(state);
      const { labels: labelsFilter } = transformToFilterUpdates(
        labels,
        labelsMap,
      );

      return dispatch(
        fetchZonesMapsList({
          ...restFilters,
          labels: labelsFilter,
          pageSize: JOHN_DEERE_DEFAULT_PAGE_SIZE_PAGINATION,
          lastEvaluatedKey: selectLastEvaluatedKey(state),
          areaUnit: selectAreaUnit(state),
        }),
      );
    },
    {
      condition: (payload, { getState }) => {
        if (
          JSON.stringify(payload) ===
          JSON.stringify(selectExportFlowFilter(getState()))
        ) {
          return false;
        }
      },
    },
  ),
);

export const openLegendExportToJohnDeere = createAsyncThunk(
  'jdExport/openLegendExport',
  async (_payload, { getState }) => {
    const state = getState();
    const zonesMap = selectLegendZonesMap(state);
    let { field } = zonesMap;

    if (!field) {
      field = await fetchVamapAssets({
        farmUuid: zonesMap.farmUuid,
        fieldUuid: zonesMap.fieldUuid,
        vamap: zonesMap,
      });
    }

    return field;
  },
);

export const toggleZoneMapSelectionExportToJohnDeere =
  (uuid) => (dispatch, getState) => {
    const zonesMaps = selectExportSelectedZonesMaps(getState());

    if (zonesMaps.find((map) => map.uuid === uuid)) {
      dispatch(unselectRowExportToJohnDeere({ uuid }));
    } else if (zonesMaps.length + 1 > MAX_ASSETS_COUNT) {
      warningNotify({
        message: i18n.t('export.notifications.max-assets-reached'),
      });
    } else {
      dispatch(selectRowExportToJohnDeere({ uuid }));
    }
  };

export const openPopupExportToJohnDeereAsFiles =
  ({
    vectorAnalysisMaps = [],
    equationMaps = [],
    ratesOnly = false,
    onConfirm,
  }) =>
  (dispatch) =>
    dispatch(
      openPopup({
        type: POPUPS.exportToJohnDeereAsFiles,
        vectorAnalysisMaps,
        equationMaps,
        ratesOnly,
        onConfirm,
      }),
    );

export const openPopupExportToJohnDeereAsYieldOperation =
  ({ items, farmName, fieldName, onConfirm }) =>
  (dispatch) =>
    dispatch(
      openPopup({
        type: POPUPS.exportToJohnDeereAsAdmFile,
        title: i18n.t(
          'general.popups.export-to-john-deere-as-adm-file.yield-operation.title',
        ),
        jdExportType: 'asYieldOperation',
        items,
        farmName,
        fieldName,
        onConfirm,
      }),
    );

export const exportToJohnDeereAsFiles = createAsyncThunk(
  'jdExport/exportToJohnDeere',
  (
    {
      orgId = '',
      client = '',
      farm = '',
      field = '',
      vectorAnalysisMaps = [],
      equationMaps = [],
      boundaries = [],
      ratesOnly = false,
    },
    { dispatch },
  ) =>
    executeExportToJohnDeereAsFiles({
      orgId,
      client,
      farm,
      field,
      vectorAnalysisMaps,
      equationMaps,
      boundaries,
      ratesOnly,
    }).then((success) => {
      if (success) {
        successNotify({
          message: i18n.t('export-data-john-deere.notifications.exported'),
        });
      } else {
        errorNotify({
          error: new CustomError('[JD Export] Data as files not exported'),
          message: i18n.t('export-data-john-deere.notifications.not-exported'),
          dispatch,
        });
      }
      return {
        success,
      };
    }),
);

export const exportToJohnDeereAsMapsLayers = createAsyncThunk(
  'jdExport/exportToJohnDeereAsMapsLayers',
  (
    {
      fieldUuid,
      vectorAnalysisMapUuid,
      topographyMapUuid,
      equationMapUuid,
      soilDatasetUuid,
      yieldDatasetUuid,
      satelliteImageUuid,
    },
    { dispatch },
  ) =>
    executeExportToJohnDeereAsMapsLayers({
      fieldUuid,
      vectorAnalysisMapUuid,
      topographyMapUuid,
      equationMapUuid,
      soilDatasetUuid,
      yieldDatasetUuid,
      satelliteImageUuid,
    }).then((success) => {
      if (success) {
        successNotify({
          message: i18n.t('export-data-john-deere.notifications.exported'),
        });
      } else {
        errorNotify({
          error: new CustomError(
            '[JD Export] Data as maps layers not exported',
          ),
          message: i18n.t('export-data-john-deere.notifications.not-exported'),
          dispatch,
        });
      }
      return {
        success,
      };
    }),
);

export const exportGeoPardFieldsToJohnDeereAsFiles = createAsyncThunk(
  'jdExport/exportGeoPardFieldsToJohnDeereAsFiles',
  (fieldUuids, { dispatch }) =>
    executeExportGeoPardFieldsToJohnDeereAsFiles(fieldUuids).then((success) => {
      if (success) {
        successNotify({
          message: i18n.t('export-data-john-deere.notifications.exported'),
        });
      } else {
        errorNotify({
          error: new CustomError('[JD Export] GeoPard fields not exported'),
          message: i18n.t('export-data-john-deere.notifications.not-exported'),
          dispatch,
        });
      }
      return {
        success,
      };
    }),
);

export const exportToJohnDeereAsAdmFiles = createAsyncThunk(
  'jdExport/exportToJohnDeereAsAdmFiles',
  (
    { orgId = '', client = '', farm = '', field = '', exportResources = [] },
    { dispatch },
  ) =>
    executeExportToJohnDeereAsAdmFiles({
      orgId,
      client,
      farm,
      field,
      exportResources,
    }).then((success) => {
      if (success) {
        successNotify({
          message: i18n.t('export-data-john-deere.notifications.exported'),
        });
      } else {
        errorNotify({
          error: new CustomError('[JD Export] ADM file not exported'),
          message: i18n.t('export-data-john-deere.notifications.not-exported'),
          dispatch,
        });
      }
      return {
        success,
      };
    }),
);

export const jdExportSlice = createSlice({
  name: 'jdExport',
  initialState,
  reducers: {
    resetStateExportJohnDeere(state) {
      // eslint-disable-next-line
      state = { ...initialState };
    },
    selectOrganizationExportToJohnDeere(state, action) {
      state.selectedOrganization = action.payload.selectedOrganization;
    },
    setStepExportToJohnDeere(state, action) {
      state.step = action.payload.step;
    },
    selectRowExportToJohnDeere(state, action) {
      state.selectZonesMaps.zonesMaps = [
        ...state.selectZonesMaps.zonesMaps,
        (state.zonesMaps?.zonesMaps || []).find(
          ({ uuid }) => uuid === action.payload.uuid,
        ),
      ];
    },
    unselectRowExportToJohnDeere(state, action) {
      return {
        ...state,
        legend: {
          ...state.legend,
          uuid:
            state.legend.uuid === action.payload.uuid
              ? null
              : state.legend.uuid,
        },
        selectZonesMaps: {
          ...state.selectZonesMaps,
          zonesMaps: state.selectZonesMaps.zonesMaps.filter(
            ({ uuid }) => uuid !== action.payload.uuid,
          ),
        },
      };
    },
    resetLegendJohnDeere(state) {
      state.legend = {
        ...initialState.selectZonesMaps.legend,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(openLegendExportToJohnDeere.pending, (state, action) => {
        state.legend = {
          uuid: action.meta.arg,
        };
      })
      .addCase(openLegendExportToJohnDeere.fulfilled, (state, action) => {
        state.selectZonesMaps.zonesMaps = state.selectZonesMaps.zonesMaps.map(
          (zonesMap) => {
            if (zonesMap.uuid !== action.meta.arg) {
              return zonesMap;
            }

            return {
              ...zonesMap,
              field: action.payload,
            };
          },
        );
      })
      .addCase(fetchMoreZonesMapsExportFlow.pending, (state) => {
        state.zonesMaps.isLoading = true;
      })
      .addCase(fetchMoreZonesMapsExportFlow.fulfilled, (state, action) => {
        const { lastEvaluatedKey, zonesMaps } = action.payload;

        return {
          ...state,
          zonesMaps: {
            ...state.zonesMaps,
            isLoading: false,
            lastEvaluatedKey,
            zonesMaps: [...state.zonesMaps.zonesMaps, ...zonesMaps],
          },
        };
      })
      .addCase(updateExportFlowFilter.pending, (state, action) => ({
        ...state,
        zonesMaps: {
          ...state.zonesMaps,
          zonesMaps: [],
          lastEvaluatedKey: null,
          isLoading: true,
        },
        selectZonesMaps: {
          ...state.selectZonesMaps,
          filter: {
            ...state.selectZonesMaps.filter,
            ...(action.meta.arg || {}),
          },
        },
      }))
      .addCase(updateExportFlowFilter.fulfilled, (state, action) => {
        if (!action.payload) {
          return state;
        }

        return {
          ...state,
          zonesMaps: {
            ...action.payload,
            isLoading: false,
          },
        };
      })
      .addMatcher(
        ({ type }) =>
          type === exportToJohnDeereAsFiles.pending.type ||
          type === exportToJohnDeereAsMapsLayers.pending.type ||
          type === exportToJohnDeereAsAdmFiles.pending.type,
        (state) => {
          state.isLoading = true;
        },
      )
      .addMatcher(
        ({ type }) =>
          type === exportToJohnDeereAsFiles.fulfilled.type ||
          type === exportToJohnDeereAsFiles.rejected.type ||
          type === exportToJohnDeereAsMapsLayers.fulfilled.type ||
          type === exportToJohnDeereAsMapsLayers.rejected.type ||
          type === exportToJohnDeereAsAdmFiles.fulfilled.type ||
          type === exportToJohnDeereAsAdmFiles.rejected.type,
        (state) => {
          state.isLoading = false;
        },
      );
  },
});

export const {
  resetStateExportJohnDeere,
  selectOrganizationExportToJohnDeere,
  setStepExportToJohnDeere,
  selectRowExportToJohnDeere,
  unselectRowExportToJohnDeere,
  resetLegendJohnDeere,
} = jdExportSlice.actions;

export default jdExportSlice.reducer;
