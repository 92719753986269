import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AssetGroupType } from '../../../../../../../helpers/constants/entities/asset';

import './index.scss';

const ASSET_GROUP_TYPE_TO_I18N_KEY = {
  [AssetGroupType.satelliteImages]: 'general.shared.satellite-monitoring',
  [AssetGroupType.vectorAnalysisMaps]: 'general.shared.zones-map',
  [AssetGroupType.soilDatasets]: 'general.shared.soil-data',
  [AssetGroupType.yieldDatasets]: 'general.shared.yield-data',
  [AssetGroupType.asAppliedDatasets]: 'general.shared.as-applied-data',
  [AssetGroupType.topographyMaps]: 'general.shared.topography',
  [AssetGroupType.threeDimensionalMaps]: 'general.shared.3d-map',
  [AssetGroupType.equationMaps]: 'general.shared.equation-map',
  [AssetGroupType.pinsGroups]: 'general.shared.scouting-sampling',
};

export default function AssetGroupItem<T>({
  assetGroup,
  assetGroupType,
  satelliteImagesLoading,
  children,
}: {
  assetGroup?: T[];
  assetGroupType: AssetGroupType;
  satelliteImagesLoading: boolean;
  children?: ReactNode;
}) {
  const { t } = useTranslation();

  const loading =
    assetGroupType === AssetGroupType.satelliteImages && satelliteImagesLoading;
  const childrenAmount = assetGroup?.length;

  return (
    <div className="asset-group-item">
      <span>
        {t(ASSET_GROUP_TYPE_TO_I18N_KEY[assetGroupType])}
        {!!childrenAmount && <span>&nbsp;({childrenAmount})</span>}
      </span>
      {loading && (
        <div className="asset-group-item__loader-wrapper">
          <CircularProgress size={20} />
        </div>
      )}
      {children}
    </div>
  );
}
