import React from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import clsx from 'clsx';

import Button from '../../../../../../components/Button';
import Link from '../../../../../../components/Link';
import {
  FileStatus,
  UploadType,
  UPLOAD_TYPE_TO_ASSET_GROUP,
  UPLOAD_TYPE_I18N_KEY,
} from '../../../helpers/constants/upload';
import {
  getFieldAssetItemLink,
  getFieldLink,
} from '../../../../../../helpers/navigation';
import { UploadingFile } from '../../../types/file';

import './index.scss';

const getListItemStatusIcon = (fileStatus: FileStatus) => {
  if (
    fileStatus === FileStatus.uploading ||
    fileStatus === FileStatus.complete
  ) {
    return <CircularProgress size={20} />;
  }

  if (fileStatus === FileStatus.verified) {
    return <CheckCircleOutlineIcon fontSize="small" color="primary" />;
  }

  if (fileStatus === FileStatus.failed) {
    return <ErrorOutlineIcon fontSize="small" color="error" />;
  }

  return null;
};

const getDetailsLink = ({
  status,
  type,
  farmUuid,
  fieldUuid,
  uuid,
}: UploadingFile) => {
  if (status !== FileStatus.verified) {
    return null;
  }

  let trackLink = '';
  let trackLinkTitle = '';

  if (type === UploadType.fieldBoundaries) {
    trackLink = getFieldLink(farmUuid, uuid);
    trackLinkTitle = i18n.t('general.popups.upload-data.actions.go-to-field');
  } else {
    trackLink = getFieldAssetItemLink(
      farmUuid,
      fieldUuid,
      UPLOAD_TYPE_TO_ASSET_GROUP[type],
      uuid,
    );
    trackLinkTitle = i18n.t('general.popups.upload-data.actions.go-to-dataset');
  }

  return (
    <Button
      classes={{
        root: 'upload-files-popup-files-item__details-link',
      }}
      target="_blank"
      component={Link}
      to={trackLink}
    >
      {trackLinkTitle}
    </Button>
  );
};

export default function UploadFilesPopupFileItem({
  file,
}: {
  file: UploadingFile;
}) {
  const { t } = useTranslation();
  let statusText = '';

  if (file.status === FileStatus.failed) {
    statusText = t('general.popups.upload-data.status.failed');
  }

  return (
    <TableRow
      className={clsx({
        'upload-files-popup-files-item_error':
          file.status === FileStatus.failed,
      })}
    >
      <TableCell className="upload-files-popup-files-item__cell upload-files-popup-files-item__name">
        {file.name}
      </TableCell>
      <TableCell className="upload-files-popup-files-item__cell">
        <span
          className={clsx(
            'upload-files-popup-files-item__tag',
            `upload-files-popup-files-item__tag_${file.type}`,
          )}
        >
          {t(UPLOAD_TYPE_I18N_KEY[file.type] ?? '')}
        </span>
      </TableCell>
      <TableCell className="upload-files-popup-files-item__cell">
        <div className="upload-files-popup-files-item__status">
          {statusText}
          {getDetailsLink(file)}
          {getListItemStatusIcon(file.status)}
        </div>
      </TableCell>
    </TableRow>
  );
}
