import React, { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';

import BackdropLoading from '../../../components/Loading/BackdropLoading';
import { selectApplicationStatus } from '../../../features/ui/applicationShell/applicationShellSelectors';
import ApplicationStatus from '../../../features/ui/applicationShell/helpers/constants/applicationStatus';
import {
  selectIsLoaded,
  selectPricingData,
} from '../../../features/user/userSelectors';
import { getPricingPageLink } from '../../../helpers/navigation';
import { useAppSelector } from '../../../app/store/helpers/functions';

export default function PricingRedirector({
  children,
}: {
  children: ReactElement;
}) {
  const isUserLoaded = useAppSelector(selectIsLoaded);
  const applicationStatus = useAppSelector(selectApplicationStatus);
  const pricingUserData = useAppSelector(selectPricingData);

  let result;

  if (!isUserLoaded) {
    result = <BackdropLoading />;
  } else if (applicationStatus === ApplicationStatus.available) {
    result = children;
  } else {
    const params: {
      id?: string;
      currency?: string;
    } = {};

    if (pricingUserData?.stripeCustomerId) {
      params.id = pricingUserData?.stripeCustomerId;
    }

    if (pricingUserData?.currency) {
      params.currency = pricingUserData?.currency;
    }

    result = <Redirect to={getPricingPageLink(params)} />;
  }

  return result;
}
