import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import { openPopup } from '../../../ui/popups/popupsSlice';
import EquationsList from '../../components/EquationsList';
import EquationsListItem from '../../components/EquationsListItem';
import {
  selectFilterCategory,
  selectCurrentCategoryEquations,
  selectEquationTemplateUuid,
  selectEquationsLoading,
} from '../../equationsSelectors';
import {
  fetchCurrentCategoryEquations,
  removeEquation,
  setCurrentEquation,
  updateEquation,
} from '../../equationsSlice';
import EquationsFilters from '../EquationsFilters';
import type { EquationListItem } from '../../types/ui';
import BackdropLoading from '../../../../components/Loading/BackdropLoading';
import { isNewEquation } from '../../helpers/functions/equations';
import { EMPTY_EQUATION } from '../../helpers/constants/equations';
import POPUPS from '../../../ui/popups/helpers/constants/popups';

import './index.scss';

export default function EquationsPanel({
  classes,
}: {
  classes?: {
    root: string;
  };
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const category = useSelector(selectFilterCategory);
  const { records, nextToken } = useSelector(selectCurrentCategoryEquations);
  const equationTemplateUuid = useSelector(selectEquationTemplateUuid);
  const equationsLoading = useSelector(selectEquationsLoading);

  useEffect(() => {
    if (!nextToken && records.length === 0) {
      dispatch(fetchCurrentCategoryEquations());
    }
  }, [dispatch, category, records.length, nextToken]);

  const handleEquationSelect = (equation: EquationListItem) => {
    dispatch(setCurrentEquation(equation));
  };

  const handleEquationMenuItemClick = (
    id: string,
    equation: EquationListItem,
  ) => {
    if (id === 'edit') {
      dispatch(
        openPopup({
          type: POPUPS.updateEquation,
          equation,
        }),
      );
    } else if (id === 'delete') {
      dispatch(
        openPopup({
          type: POPUPS.deleteEquation,
          entityName: equation.title,
          onConfirm: () => {
            dispatch(
              removeEquation({
                uuid: equation.uuid,
              }),
            );
          },
        }),
      );
    }
  };

  const handleSetUrlClick = (equation: EquationListItem) => {
    dispatch(
      openPopup({
        type: 'edit-source-url-equation',
        textValue: equation.sourceUrl || '',
        entityName: equation.title,
        validator: () => true,
        confirmText: t('general.controls.update'),
        onConfirm: (sourceUrl: string) => {
          dispatch(
            updateEquation({
              equation,
              update: {
                sourceUrl,
              },
            }),
          );
        },
      }),
    );
  };

  const onNewEquationRadioClick = () => {
    dispatch(
      setCurrentEquation({
        uuid: '',
        title: '',
        description: '',
        useNumpy: false,
        dataVariables: [],
        equationAsText: '',
        equationResultVariable: '',
        productUnit: null,
      }),
    );
  };

  const handleLoadMoreEquations = () => {
    dispatch(fetchCurrentCategoryEquations());
  };

  return (
    <div className={clsx('equations-panel', classes?.root)}>
      <EquationsListItem
        item={{
          ...EMPTY_EQUATION,
          title: t('general.controls.create-new'),
          description: t(
            'zones-ops.equation-based.steps.3.panel.create-new-equation-text',
          ),
          selected: isNewEquation(equationTemplateUuid),
          allowSetSourceUrl: false,
        }}
        onEquationSelect={onNewEquationRadioClick}
      />
      <Typography className="equations-panel__header" variant="h6">
        {t('zones-ops.equation-based.steps.3.panel.select-from-existing')}
      </Typography>
      <EquationsFilters />
      <div className="equations-panel__list-container">
        {equationsLoading && <BackdropLoading />}
        {records.length !== 0 && (
          <EquationsList
            equations={records}
            selectedEquationUuid={equationTemplateUuid}
            category={category}
            hasNext={!!nextToken}
            onEquationSelect={handleEquationSelect}
            onSetUrlClick={handleSetUrlClick}
            onMenuItemClick={handleEquationMenuItemClick}
            loadMoreItems={handleLoadMoreEquations}
          />
        )}
      </div>
    </div>
  );
}
