mutation saveOrganization($input: OrganizationInput!) {
  saveOrganization(input: $input) {
    uuid
    name
    surname
    givenName
    email
    phone
  }
}
