import Big from 'bignumber.js';

export const isNumber = (input: unknown): input is number =>
  typeof input === 'number' && !Number.isNaN(input);

export const sumBigNumbers = (
  num1: number | string,
  num2: number | string,
): number => {
  const sum = new Big(num1).plus(num2).toFixed();
  return Number(sum);
};
