query getFertilizersJohnDeere($fertilizerFilter: ProductFilterJohnDeere) {
  getFertilizersJohnDeere(fertilizerFilter: $fertilizerFilter) {
    fertilizers {
      id
      name
      companyName
    }
    totalCount
  }
}
