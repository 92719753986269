import { area, featureCollection } from '@turf/turf';

import { getRatesData } from '../../analysis';
import { getUserUnitArea } from '../..';
import { ZonesMapGeoJson } from '../../types/vectorAnalysisMap/geojson';
import { AreaUnit } from '../../../features/user/helpers/constants/user';
import { convertStringToNumber } from '../../markup';

const normalizeRatesAndCalcArea = <RateType>(
  geojson: ZonesMapGeoJson<RateType>,
  areaUnit: AreaUnit,
): ZonesMapGeoJson<RateType> | null => {
  if (!geojson) {
    return null;
  }

  const normalizedGeoJson: ZonesMapGeoJson<RateType> = featureCollection([]);
  const ratesHeaders = getRatesData(geojson, 'ratesHeaders');
  const ratesUnits = getRatesData(geojson, 'ratesUnits');
  const ratesPrices = getRatesData(geojson, 'ratesPrices');
  const zoneToRatesMap = geojson.features.reduce((acc, ftr) => {
    const { zone, attributes: { rates } = {} } = ftr.properties;

    if (
      !acc.has(zone) &&
      Array.isArray(rates) &&
      rates.length === ratesHeaders.length
    ) {
      acc.set(zone, rates);
    }

    return acc;
  }, new Map<number, RateType[]>());

  normalizedGeoJson.features.push(
    ...geojson.features.map((ftr) => ({
      ...ftr,
      properties: {
        ...ftr.properties,
        attributes: {
          ...ftr.properties.attributes,
          ratesHeaders,
          ratesUnits,
          ratesPrices,
          rates:
            zoneToRatesMap.get(ftr.properties.zone) ||
            Array(ratesHeaders.length).fill(0),
          area: getUserUnitArea(area(ftr), areaUnit),
        },
      },
    })),
  );

  return normalizedGeoJson;
};

export const sortZoneNumbers = <RateType>(
  geojson: ZonesMapGeoJson<RateType> | null,
): ZonesMapGeoJson<RateType> | null => {
  if (!geojson) {
    return null;
  }

  const newFeatureCollection: ZonesMapGeoJson<RateType> = featureCollection([]);
  const maxZone = Math.max(
    ...geojson.features.map((ftr) => ftr.properties.zone),
  );
  const sortedFeatures = geojson.features
    .map((ftr) => {
      const { zone } = ftr.properties;

      return {
        ...ftr,
        properties: {
          ...ftr.properties,
          zone: zone > 0 ? zone : maxZone - zone,
        },
      };
    })
    .sort((ftrA, ftrB) => ftrA.properties.zone - ftrB.properties.zone)
    .map((ftr, ind) => ({
      ...ftr,
      properties: {
        ...ftr.properties,
        zone: ind + 1,
      },
    }));

  newFeatureCollection.features.push(...sortedFeatures);

  return newFeatureCollection;
};

export const normalizeAndSortZonesMapGeojson = (
  geojson: ZonesMapGeoJson<number>,
  areaUnit: AreaUnit,
): ZonesMapGeoJson<number> | null =>
  sortZoneNumbers(normalizeRatesAndCalcArea(geojson, areaUnit));

export const convertRatesToNumbers = (
  geojson: ZonesMapGeoJson<string | number>,
): ZonesMapGeoJson<number> => ({
  ...geojson,
  features: geojson.features.map((feature) => {
    const { attributes } = feature.properties;

    return {
      ...feature,
      properties: {
        ...feature.properties,
        attributes: {
          ...attributes,
          ratesHeaders: attributes.ratesHeaders.map((header) => header.trim()),
          rates: attributes.rates.map(convertStringToNumber),
          ratesPrices: attributes.ratesPrices.map(convertStringToNumber),
        },
      },
    };
  }),
});
