import { TransformedField } from '../../../features/field/types/field';
import { PinsGroup } from '../../../features/pins/types';
import { TransformedSatelliteImage } from '../../../features/satelliteImages/types/satelliteImage';
import { AssetLoadStatus, AssetType } from '../../constants/entities/asset';
import {
  AnalysisMapType,
  ZonesOperationType,
} from '../../constants/entities/vectorAnalysisMap';
import { formatAcquisitionDate } from '../../satellite';
import type { TransformedAsset } from '../../types';
import {
  TransformedAsAppliedDataset,
  TransformedDataset,
  TransformedSoilDataset,
  TransformedTopographyMap,
  TransformedYieldDataset,
} from '../../types/dataset';
import { TransformedEquationMap } from '../../types/equationMap';
import { TransformedThreeDimensionalMap } from '../../types/threeDimensionalMap';
import { TransformedVectorAnalysisMap } from '../../types/vectorAnalysisMap';

export const isAssetAvailable = (assetStatus: AssetLoadStatus): boolean =>
  assetStatus !== AssetLoadStatus.noData;

export const isAssetRequested = (
  assetStatus: AssetLoadStatus | null,
): boolean => assetStatus !== AssetLoadStatus.idle;

export const isAssetLoadingError = (
  assetStatus: AssetLoadStatus | null,
): boolean => assetStatus === AssetLoadStatus.error;

export const isField = (node: TransformedAsset): node is TransformedField =>
  node && node.assetType === AssetType.field;

export const isSatelliteImage = (
  node: TransformedAsset | null,
): node is TransformedSatelliteImage =>
  !!node && node.assetType === AssetType.satelliteImage;

export const isSoilDataset = (
  node: TransformedAsset | null,
): node is TransformedSoilDataset =>
  !!node && node.assetType === AssetType.soilDataset;

export const isYieldDataset = (
  node: TransformedAsset | null,
): node is TransformedYieldDataset =>
  !!node && node.assetType === AssetType.yieldDataset;

export const isAsAppliedDataset = (
  node: TransformedAsset | null,
): node is TransformedAsAppliedDataset =>
  !!node && node.assetType === AssetType.asAppliedDataset;

export const isDataset = (
  node: TransformedAsset | null,
): node is
  | TransformedSoilDataset
  | TransformedYieldDataset
  | TransformedAsAppliedDataset =>
  !!node &&
  (isSoilDataset(node) || isYieldDataset(node) || isAsAppliedDataset(node));

export const isTopographyMap = (
  node: TransformedAsset | null,
): node is TransformedTopographyMap =>
  !!node && node.assetType === AssetType.topographyMap;

export const is3dMap = (
  node: TransformedAsset | null,
): node is TransformedThreeDimensionalMap =>
  !!node && node.assetType === AssetType.threeDimensionalMap;

export const isEquationMap = (
  node: TransformedAsset | null,
): node is TransformedEquationMap =>
  !!node && node.assetType === AssetType.equationMap;

export const isVectorAnalysis = (
  node: TransformedAsset | null,
): node is TransformedVectorAnalysisMap =>
  !!node && node.assetType === AssetType.vectorAnalysisMap;

export const isPinsGroup = (node: TransformedAsset | null): node is PinsGroup =>
  !!node && node.assetType === AssetType.pinsGroup;

export const areSatelliteImages = (
  nodes: TransformedAsset[],
): nodes is TransformedSatelliteImage[] => nodes.some(isSatelliteImage);

export const areSoilDatasets = (
  nodes: TransformedAsset[],
): nodes is TransformedSoilDataset[] => nodes.some(isSoilDataset);

export const areYieldDatasets = (
  nodes: TransformedAsset[],
): nodes is TransformedYieldDataset[] => nodes.some(isYieldDataset);

export const areAsAppliedDatasets = (
  nodes: TransformedAsset[],
): nodes is TransformedAsAppliedDataset[] => nodes.some(isAsAppliedDataset);

export const areDatasets = (
  nodes: TransformedAsset[],
): nodes is TransformedDataset[] => nodes.some(isDataset);

export const areTopographyMaps = (
  nodes: TransformedAsset[],
): nodes is TransformedTopographyMap[] => nodes.some(isTopographyMap);

export const areVectorAnalysisMaps = (
  nodes: TransformedAsset[],
): nodes is TransformedVectorAnalysisMap[] => nodes.some(isVectorAnalysis);

export const isDrawnManuallyVectorAnalysis = (
  node: TransformedAsset,
): boolean => {
  let result = false;

  if (isVectorAnalysis(node)) {
    result = node.analysisType === AnalysisMapType.drawn;
  }

  return result;
};

export const isZonesOperationsIntersectVectorAnalysis = (
  node: TransformedAsset,
) => {
  let result = false;

  if (isVectorAnalysis(node)) {
    result =
      node.analysisType === AnalysisMapType.zoneOperations &&
      node.parameters?.zonesOperation === ZonesOperationType.intersect;
  }

  return result;
};

export const isMultiLayerVectorAnalysis = (node: TransformedAsset) => {
  let result = false;

  if (isVectorAnalysis(node)) {
    result = node.analysisType === AnalysisMapType.multilayers;
  }

  return result;
};

export const isSatelliteVectorAnalysis = (node: TransformedAsset) => {
  let result = false;

  if (isVectorAnalysis(node)) {
    result = node.analysisType === AnalysisMapType.satellite;
  }

  return result;
};

export const isSoilVectorAnalysis = (node: TransformedAsset) => {
  let result = false;

  if (isVectorAnalysis(node)) {
    result = node.analysisType === AnalysisMapType.soil;
  }

  return result;
};

export const isYieldVectorAnalysis = (node: TransformedAsset) => {
  let result = false;

  if (isVectorAnalysis(node)) {
    result = node.analysisType === AnalysisMapType.yield;
  }

  return result;
};

export const isAsAppliedVectorAnalysis = (node: TransformedAsset) => {
  let result = false;

  if (isVectorAnalysis(node)) {
    result = node.analysisType === AnalysisMapType.asApplied;
  }

  return result;
};

export const isTopographyVectorAnalysis = (node: TransformedAsset) => {
  let result = false;

  if (isVectorAnalysis(node)) {
    result = node.analysisType === AnalysisMapType.topography;
  }

  return result;
};

export const getAssetName = (asset: TransformedAsset): string => {
  let name: string;

  if (isSatelliteImage(asset)) {
    name = formatAcquisitionDate(asset.satelliteImage.acquisitionDate);
  } else {
    name = asset.name || '';
  }

  return name;
};
