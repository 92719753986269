import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HelpOutlineSharpIcon from '@material-ui/icons/HelpOutlineSharp';
import BugReportIcon from '@material-ui/icons/BugReport';
import ExitToAppSharpIcon from '@material-ui/icons/ExitToAppSharp';

import { getHelpLink } from '../../../../../helpers/functions/utils/appConfig';
import Button from '../../../../../components/Button';
import { getComponent } from '../../../../../helpers/markup';

import './index.scss';

enum ComponentKey {
  pricingPlanStatus = 'pricing-plan-status',
}

type ElementWithKey = ReactElement<{ key: ComponentKey }>;

export default function AppShellHeader({
  withReportIssue,
  text,
  secondaryText,
  withBackAction,
  children,
  onLogOutClick,
  onReportIssueClick,
  onBackButtonClick,
}: {
  withReportIssue: boolean;
  text: ReactElement;
  secondaryText: ReactElement;
  withBackAction?: boolean;
  children: ElementWithKey | ElementWithKey[];
  onLogOutClick: () => void;
  onReportIssueClick: () => void;
  onBackButtonClick?: () => void;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const pricingPlanStatus = getComponent(
    ComponentKey.pricingPlanStatus,
    children,
  );

  const handleBackButtonClick = () => {
    if (onBackButtonClick) {
      onBackButtonClick();
      return;
    }

    history.goBack();
  };

  return (
    <div className="app-shell-header">
      {(withBackAction || onBackButtonClick) && (
        <IconButton
          classes={{
            root: 'app-shell-header__back-button',
          }}
          onClick={handleBackButtonClick}
        >
          <ArrowBackIcon />
        </IconButton>
      )}
      <div className="app-shell-header__container">
        <span className="app-shell-header__text">
          {text}
          <span className="app-shell-header__text_secondary">
            {secondaryText}
          </span>
        </span>
        {pricingPlanStatus}
        <div className="app-shell-header__buttons">
          {withReportIssue && (
            <Button
              className="app-shell-header__button"
              startIcon={
                <BugReportIcon className="app-shell-header__button-icon" />
              }
              onClick={onReportIssueClick}
            >
              {t('general.navigation.report-issue')}
            </Button>
          )}
          <IconButton
            component="a"
            href={getHelpLink()}
            target="_blank"
            className="app-shell-header__icon-button"
            title={t('general.navigation.tutorial')}
          >
            <HelpOutlineSharpIcon />
          </IconButton>
          <IconButton
            onClick={onLogOutClick}
            className="app-shell-header__icon-button"
            title={t('general.navigation.log-out')}
          >
            <ExitToAppSharpIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
}
