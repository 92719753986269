query getEquations($filter: EquationFilter!) {
  getEquations(filter: $filter) {
    nextToken
    equations {
      uuid
      userUuid
      organizationUuid
      title
      description
      sourceUrl
      dataVariables
      equationResultVariable
      equationAsText
      productUnit
      useNumpy
    }
  }
}
