const singleAtTheTime = (action) => {
  let promise;

  const upgradedAction =
    (...args) =>
    (dispatch) => {
      if (promise) {
        promise.abort();
      }

      promise = dispatch(action(...args));

      promise.then((act) => {
        // need to clean promise link only if promise finished, otherwise it links to another call
        if (promise?.requestId === act.meta.requestId) {
          promise = null;
        }
      });
    };

  return Object.assign(upgradedAction, action);
};

export default singleAtTheTime;
