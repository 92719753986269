import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';

import Button from '../../../../../components/Button';
import ColorSchemaViewer from '../../components/ColorSchemaViewer';
import Select from '../../../../../components/Select';
import { openPopup } from '../../../popups/popupsSlice';
import POPUPS from '../../../popups/helpers/constants/popups';
import { updateAreaUnit, updateLanguage } from '../../userSettingsSlice';
import { AreaUnit, Language } from '../../../../user/helpers/constants/user';
import { selectUpdates } from '../../userSettingsSelectors';
import {
  selectEmail,
  selectLanguage,
  selectMaxArea,
  selectTotalArea,
  selectAreaUnit,
} from '../../../../user/userSelectors';
import { getI18nAreaAmount } from '../../../../user/helpers/functions/user';
import { selectColorSchemas } from '../../../../colorSchemas/colorSchemasSelectors';
import {
  addSchema,
  deleteSchema,
  getColorSchemas,
  updateSchema,
} from '../../../../colorSchemas/colorSchemasSlice';
import useDidMount from '../../../../../hooks/useDidMount';
import { getAvailableLanguages } from '../../../../../helpers/functions/utils/appConfig';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../app/store/helpers/functions';
import { prettifyNumber } from '../../../../../helpers/markup';

import './index.scss';

const getLanguageOptions = () => {
  const LANGUAGE_OPTIONS = [
    { value: Language.English, title: 'English' },
    { value: Language.German, title: 'Deutsch' },
    { value: Language.French, title: 'Français' },
    { value: Language.Dutch, title: 'Nederlands' },
    { value: Language.Ukrainian, title: 'Українська' },
    { value: Language.Russian, title: 'Русский' },
    { value: Language.PortugueseBrazil, title: 'Português (Brasil)' },
    { value: Language.Hungarian, title: 'Magyar' },
    { value: Language.Slovenian, title: 'Slovenski' },
    { value: Language.Bulgarian, title: 'Български' },
    { value: Language.Italian, title: 'Italiano' },
    { value: Language.Spanish, title: 'Español' },
  ];
  const availableLanguagesSet = new Set(getAvailableLanguages());

  return LANGUAGE_OPTIONS.filter((option) =>
    availableLanguagesSet.has(option.value),
  );
};

const getAreaUnitOptions = (t) => [
  {
    value: AreaUnit.acres,
    title: t('general.area-unit.acres-unit'),
  },
  {
    value: AreaUnit.hectares,
    title: t('general.area-unit.hectares-unit'),
  },
];

const Panel = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const maxArea = useAppSelector(selectMaxArea);
  const areaUnit = useAppSelector(selectAreaUnit);
  const language = useAppSelector(selectLanguage);
  const totalArea = useAppSelector(selectTotalArea);
  const email = useAppSelector(selectEmail);
  const colorSchemas = useAppSelector(selectColorSchemas);

  const { language: updatedLanguage, areaUnit: updatedAreaUnit } =
    useAppSelector(selectUpdates) || {};
  const unit = getI18nAreaAmount(areaUnit).toLowerCase();

  useDidMount(() => {
    void dispatch(getColorSchemas());
  });

  const handleLanguageChange = (value) => {
    dispatch(updateLanguage(value));
  };

  const handleAreaUnitChange = (value) => {
    dispatch(updateAreaUnit(value));
  };

  const handleAddColorSchemaClick = () => {
    dispatch(
      openPopup({
        type: POPUPS.colorSchema,
        colorSchema: {},
        onConfirm: (title, colors) => {
          void dispatch(
            addSchema({
              title,
              colors,
            }),
          );
        },
      }),
    );
  };

  const handleColorSchemaActionClick = (action, schema) => {
    if (action === 'delete') {
      dispatch(
        openPopup({
          type: POPUPS.deleteColorSchema,
          onConfirm: () => {
            void dispatch(deleteSchema(schema.uuid));
          },
        }),
      );
    } else if (action === 'edit') {
      dispatch(
        openPopup({
          type: POPUPS.colorSchema,
          colorSchema: schema,
          onConfirm: (title, colors) => {
            void dispatch(
              updateSchema({
                uuid: schema.uuid,
                title,
                colors,
              }),
            );
          },
        }),
      );
    }
  };

  return (
    <div className="user-settings-data-pers">
      <div className="user-settings-data-pers__section">
        <Typography variant="h6">
          {t('user-settings.tabs.data-and-personalization.data')}
        </Typography>
        <ul className="list">
          <li className="item">
            <p className="item__key">
              {t('user-settings.tabs.data-and-personalization.account')}
            </p>
            <p className="item__value">{email}</p>
          </li>
          <li className="item">
            <p className="item__key">
              {t('user-settings.tabs.data-and-personalization.total-area')}
            </p>
            <p className="item__value">{`${prettifyNumber(totalArea)} ${unit}`}</p>
          </li>
          <li className="item">
            <p className="item__key">
              {t('user-settings.tabs.data-and-personalization.max-area')}
            </p>
            <p className="item__value">{`${prettifyNumber(maxArea)} ${unit}`}</p>
          </li>
        </ul>
      </div>
      <div className="user-settings-data-pers__section">
        <Typography variant="h6">
          {t('user-settings.tabs.data-and-personalization.personalization')}
        </Typography>
        <ul className="list">
          <li className="item">
            <Select
              id="language"
              label={t('user-settings.tabs.data-and-personalization.language')}
              options={getLanguageOptions()}
              value={updatedLanguage || language}
              onChange={handleLanguageChange}
            />
          </li>
          <li className="item">
            <Select
              id="areaUnit"
              label={t('user-settings.tabs.data-and-personalization.area-unit')}
              options={getAreaUnitOptions(t)}
              value={updatedAreaUnit || areaUnit}
              onChange={handleAreaUnitChange}
            />
          </li>
        </ul>
      </div>
      <div className="user-settings-data-pers__section">
        <Typography variant="h6">
          {t('user-settings.tabs.data-and-personalization.color-schemes')}
        </Typography>
        <div className="color-panel">
          <div>
            {colorSchemas.map((colorSchema, index) => (
              <ColorSchemaViewer
                key={index}
                colorSchema={colorSchema}
                onSchemaActionClick={handleColorSchemaActionClick}
              />
            ))}
          </div>
          <Button startIcon={<AddIcon />} onClick={handleAddColorSchemaClick}>
            {t('general.popups.color-schema.color-schema')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Panel;
