import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import ImpactedAreaIcon from '../../../../../../components/Icons/impacted-area.svg';

import './index.scss';

const ImpactedAreaTooltip = () => {
  const { t } = useTranslation();

  return (
    <div className="impacted-area-tooltip">
      <ImpactedAreaIcon className="impacted-area-tooltip__img" />
      <div className="impacted-area-tooltip__explanation">
        <p className="impacted-area-tooltip__definition">
          <Trans i18nKey="general.controls.map-legend.impacted-area-definition">
            <span className="text_primary">area</span> text
          </Trans>
        </p>
        {t('general.controls.map-legend.impacted-area-example')}
      </div>
    </div>
  );
};

export default ImpactedAreaTooltip;
