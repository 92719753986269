import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import type { Location } from 'history';

import useDidMount from '../../../hooks/useDidMount';
import BackdropLoading from '../../../components/Loading/BackdropLoading';
import { setupApp } from '../../../features/ui/applicationShell/applicationShellSlice';
import { selectIsLoaded } from '../../../features/user/userSelectors';
import { View } from '../../../features/ui/authentication/helpers/constants/authentication';
import { getAuthLink } from '../../../helpers/navigation';
import { buildRedirectedFrom } from '../../../features/ui/authentication/helpers/functions/navigation';
import useAuthenticate from '../../../features/ui/authentication/hooks/useAuthenticate';
import { signOut } from '../../../features/user/userSlice';

export default function AuthenticationRedirector({
  children,
}: {
  children: ReactNode;
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const userDataLoaded = useSelector(selectIsLoaded);
  const [signedOut, setSignedOut] = useState(false);
  const { authenticate, authenticated, authenticationCompleted } =
    useAuthenticate({
      onSignOutSuccess: () => {
        setSignedOut(true);
        dispatch(signOut());
      },
    });

  const getCurrentPath = (location: Location) =>
    location.pathname + location.search;

  useDidMount(() => {
    authenticate();
  });

  useEffect(() => {
    // Setup app data when:
    // 1. User is authenticated
    // 2. User data hasn't been loaded yet
    // 3. User hasn't just signed out (prevents setupApp during sign-out transition)
    if (authenticated && !userDataLoaded && !signedOut) {
      dispatch(setupApp());
    }
  }, [authenticated, userDataLoaded, dispatch, signedOut]);

  let result = <BackdropLoading />;

  if (!authenticationCompleted) {
    result = <BackdropLoading />;
  } else if (!authenticated) {
    result = (
      <Redirect
        to={{
          pathname: getAuthLink(View.logIn),
          search: signedOut
            ? ''
            : buildRedirectedFrom(getCurrentPath(history.location)),
        }}
      />
    );
  } else if (authenticated && userDataLoaded) {
    result = <>{children}</>;
  }

  return result;
}
