mutation generatePresignedPostPhotoData(
  $input: GeneratePresignedPostPhotoDataInput!
) {
  generatePresignedPostPhotoData(input: $input) {
    uuid
    farmUuid
    s3Url
    s3Fields
  }
}
