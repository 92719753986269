import i18n from 'i18next';

import TreeNodeEntity from '../../../../../helpers/constants/entities/treeNodeEntity';
import ContextEventStatus from '../../../../asyncOperations/helpers/constants/contextEventStatus';
import type {
  Field,
  PlatformContextEvent,
} from '../../../../asyncOperations/types/api';
import { extractFieldsMap } from '../../../../farms/helpers/functions/farms';
import type { Operation, SubOperation } from '../../types/operation';
import { MESSAGE_KEY_TO_I18N_KEY_MAP } from '../constants/messages';
import { OperationStatus, OperationType } from '../constants/operation';
import { geOperationsFilteredByFields } from './filters';

type EventsByOperationType = {
  [K in OperationType]?: Record<string, PlatformContextEvent[]>;
};

const TYPES_MAP: Partial<Record<TreeNodeEntity, OperationType>> = {
  [TreeNodeEntity.soilDataset]: OperationType.soilDataset,
  [TreeNodeEntity.yieldDataset]: OperationType.yieldDataset,
  [TreeNodeEntity.asAppliedDataset]: OperationType.asAppliedDataset,
  [TreeNodeEntity.elevation]: OperationType.elevation,
  [TreeNodeEntity.fieldSatelliteImage]: OperationType.fieldSatelliteImage,
  [TreeNodeEntity.planetMask]: OperationType.planetMask,
  [TreeNodeEntity.planetImage]: OperationType.planetImage,
  [TreeNodeEntity.vectorAnalysisMap]: OperationType.vectorAnalysisMap,
  [TreeNodeEntity.equationMap]: OperationType.equationMap,
  [TreeNodeEntity.threeDimensionalMap]: OperationType.threeDimensionalMap,
};

const STATUSES_MAP = {
  [ContextEventStatus.error]: OperationStatus.error,
  [ContextEventStatus.done]: OperationStatus.done,
  [ContextEventStatus.warning]: OperationStatus.done,
  [ContextEventStatus.ignored]: OperationStatus.ignored,
  [ContextEventStatus.inProgress]: OperationStatus.inProgress,
};

const isDatasetEvent = ({ subFieldType }: PlatformContextEvent) =>
  subFieldType === TreeNodeEntity.soilDataset ||
  subFieldType === TreeNodeEntity.yieldDataset ||
  subFieldType === TreeNodeEntity.asAppliedDataset;

const getSubOperation = (
  event: PlatformContextEvent,
  collectedFromJD: boolean,
  field?: Field,
): SubOperation => {
  let message: string;

  if (event.messageKey) {
    const localizedMessage = i18n.t(
      MESSAGE_KEY_TO_I18N_KEY_MAP[event.messageKey],
    );
    message = localizedMessage || event.messageKey;
  } else if (
    isDatasetEvent(event) &&
    event.status === ContextEventStatus.ignored
  ) {
    message = i18n.t('operations.operation-item.messages.dataset-ignored');
  } else {
    message = '';
  }

  return {
    fieldName: field?.name,
    fieldUuid: field?.uuid,
    farmUuid: field?.farmUuid,
    assetUuid: event.subFieldUuid,
    operationId: event.operationId,
    subFieldUuid: event.subFieldUuid,
    assetType: event.subFieldType,
    status:
      STATUSES_MAP[event.status as keyof typeof STATUSES_MAP] ??
      OperationStatus.inProgress,
    executionDate: event.executionDate,
    message,
    collectedFromJD,
  };
};

const getOperation = (
  subOperations: SubOperation[],
  type: OperationType,
): Operation => ({
  subOperations,
  type,
});

export const getOperations = ({
  eventsWithFields,
  filteredFieldsUuids = [],
}: {
  eventsWithFields?: {
    events: PlatformContextEvent[];
    fields: Field[];
  } | null;
  filteredFieldsUuids?: string[];
}): Record<OperationType, Operation[]> => {
  const result: Record<OperationType, Operation[]> = {
    [OperationType.soilDataset]: [],
    [OperationType.yieldDataset]: [],
    [OperationType.asAppliedDataset]: [],
    [OperationType.elevation]: [],
    [OperationType.fieldSatelliteImage]: [],
    [OperationType.planetMask]: [],
    [OperationType.planetImage]: [],
    [OperationType.vectorAnalysisMap]: [],
    [OperationType.equationMap]: [],
    [OperationType.threeDimensionalMap]: [],
  };

  if (!eventsWithFields) {
    return result;
  }

  const { events: operationsLogEvents, fields } = eventsWithFields;

  const eventsFilteredByFields = geOperationsFilteredByFields(
    operationsLogEvents,
    filteredFieldsUuids,
  );

  const eventsByOperationTypes =
    eventsFilteredByFields.reduce<EventsByOperationType>((acc, event) => {
      const { subFieldType, operationId, subFieldUuid } = event;

      const operationType = TYPES_MAP[subFieldType];

      if (!operationType) {
        return acc;
      }

      const eventsByType = acc[operationType] ?? {};

      let key = removeQuotes(operationId);

      // If event doesn't have operationId, search for the events in the same processing pipeline by subFieldUuid
      if (!key) {
        const matchedOperationId = eventsFilteredByFields.find(
          (filteredEvent) =>
            filteredEvent.subFieldUuid === subFieldUuid &&
            filteredEvent.subFieldType === subFieldType &&
            filteredEvent.operationId,
        )?.operationId;

        // If an event in the same processing pipeline has an operationId, use that operationId as a grouping key. If not, group events without an operationId by subFieldUuid
        key = removeQuotes(matchedOperationId) || subFieldUuid;
      }

      if (eventsByType[key]) {
        eventsByType[key].push(event);
      } else {
        eventsByType[key] = [event];
      }

      return { ...acc, [operationType]: eventsByType };
    }, {});

  const fieldsByFieldUuid = extractFieldsMap([{ fields }]);

  Object.entries(eventsByOperationTypes).forEach(([type, eventsByType]) => {
    Object.values(eventsByType).forEach((eventsGroup) => {
      const eventsByFieldUuid = eventsGroup.reduce<
        Record<string, PlatformContextEvent[]>
      >((acc, event) => {
        let key = event.fieldUuid;

        // If event doesn't have fieldUuid, search for the events in the same processing pipeline by subFieldUuid
        if (!key) {
          const matchedEventBySubFieldUuid = eventsGroup.find(
            ({ subFieldUuid, fieldUuid }) =>
              event.subFieldUuid === subFieldUuid && fieldUuid,
          );

          // If an event in the same processing pipeline has fieldUuid, use that fieldUuid as a grouping key. If not, group events without fieldUuid by subFieldUuid
          key = matchedEventBySubFieldUuid?.fieldUuid || event.subFieldUuid;
        }

        if (acc[key]) {
          acc[key].push(event);
        } else {
          acc[key] = [event];
        }

        return acc;
      }, {});

      const subOperations: SubOperation[] = [];

      Object.values(eventsByFieldUuid).forEach((events) => {
        const event =
          events.find(
            ({ status }) =>
              status === ContextEventStatus.error ||
              status === ContextEventStatus.warning ||
              status === ContextEventStatus.ignored ||
              status === ContextEventStatus.done ||
              status === ContextEventStatus.inProgress,
          ) || events[0];

        const collectedFromJD = eventsGroup.some(
          ({ status }) => status === ContextEventStatus.collectedFromJohnDeere,
        );
        const subOperation = getSubOperation(
          event,
          collectedFromJD,
          fieldsByFieldUuid[event.fieldUuid],
        );
        subOperations.push(subOperation);
      });

      const eventType = type as OperationType;
      const operation = getOperation(subOperations, eventType);
      result[eventType].push(operation);
    });
  });

  return result;
};

const removeQuotes = (str = '') => str.replace(/^"|"$/g, '');

export const countOperations = (operations: Operation[]) =>
  operations.reduce(
    (acc, { subOperations }) => {
      subOperations.forEach((subOperation) => {
        const status = subOperation.status as keyof typeof acc;
        const counter = acc[status];

        if (counter !== null) {
          acc[status] = counter + 1;
        }
      });

      return acc;
    },
    {
      [OperationStatus.inProgress]: 0,
      [OperationStatus.done]: 0,
      [OperationStatus.error]: 0,
      [OperationStatus.ignored]: 0,
    },
  );
