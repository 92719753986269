import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import {
  ASSET_TYPE_TO_ASSET_GROUP,
  AssetGroupType,
  AssetType,
} from '../../../../helpers/constants/entities/asset';
import { findNodeById, traverseTree } from '../helpers/functions/tree';
import generateDataLayersTree from '../helpers/functions/generateDataLayersTree';
import {
  generateAssetNodeId,
  getModeValue,
  isAttributesDisplayed,
  isDatasetsGroupingEnabled,
} from '../helpers/functions/dataLayersTree';
import { isInvalid as isEquationMapInvalid } from '../../../../helpers/functions/entities/equationMap';
import SatelliteImageNode from '../components/DataLayersTree/Nodes/SatelliteImageNode';
import DatasetNode from '../components/DataLayersTree/Nodes/DatasetNode';
import ZonesMapNode from '../components/DataLayersTree/Nodes/ZonesMapNode';
import AssetGroupNode from '../components/DataLayersTree/Nodes/AssetGroupNode';
import EquationMapNode from '../components/DataLayersTree/Nodes/EquationMapNode';
import PinsGroupNode from '../components/DataLayersTree/Nodes/PinsGroupNode';
import DataLayersTree from '../components/DataLayersTree';
import TopographyNode from '../components/DataLayersTree/Nodes/TopographyNode';
import AttributeNode from '../components/DataLayersTree/Nodes/AttributeNode';
import ThreeDimensionalMapNode from '../components/DataLayersTree/Nodes/ThreeDimensionalMapNode';
import GroupPlaceholderNode from '../components/DataLayersTree/Nodes/GroupPlaceholderNode';
import useTreeView from './useTreeView';
import type {
  ModeConfig,
  DatasetDataLayers,
  DataLayersTreeNode,
} from '../types/dataLayersTree';
import isDatasetItemDisabled from '../helpers/functions/isDatasetItemDisabled';
import { filterSatelliteImages } from '../../filters/helpers/functions/satelliteImage';
import { filterPinsGroups } from '../../filters/helpers/functions/pinsGroups';
import { filterVamaps } from '../../filters/helpers/functions/analysis';
import { DEFAULT_VAMAPS_FILTER } from '../../filters/helpers/constants/analysis';
import { DEFAULT_SAT_IMAGES_FILTER } from '../../filters/helpers/constants/satelliteImage';
import { DEFAULT_PINS_GROUPS_FILTER } from '../../filters/helpers/constants/pinsGroups';
import {
  AssetGroupFilterKey,
  AssetGroupsFilters,
} from '../../filters/types/assetGroup';
import filterAssetGroupItems from '../../filters/helpers/functions/filterAssetGroupItems';
import type { TransformedSatelliteImage } from '../../../satelliteImages/types/satelliteImage';
import type { TransformedVectorAnalysisMap } from '../../../../helpers/types/vectorAnalysisMap';
import type {
  TransformedAsAppliedDataset,
  TransformedDataset,
  TransformedSoilDataset,
  TransformedTopographyMap,
  TransformedYieldDataset,
} from '../../../../helpers/types/dataset';
import { DEFAULT_ASSET_GROUP_FILTER } from '../../filters/helpers/constants/assetGroup';
import filterDatasets from '../../filters/helpers/functions/filterDatasets';
import type { TransformedThreeDimensionalMap } from '../../../../helpers/types/threeDimensionalMap';
import type { TransformedEquationMap } from '../../../../helpers/types/equationMap';
import type { PinsGroup } from '../../../pins/types';
import type { ArrayItem } from '../../../../helpers/types/shared';
import type { TransformedAsset } from '../../../../helpers/types';
import RecommendedSatelliteImagesPicker from '../components/RecommendedSatelliteImagesPicker';
import SelectedFilters from '../../filters/components/SelectedFilters';
import {
  assetGroupFilterRenderer,
  getDefaultAssetGroupFilterValue,
  getDefaultFilterValue,
} from '../../filters/helpers/functions';
import FiltersPopover from '../../filters/components/FiltersPopover';
import { updateParentSelection } from '../helpers/functions/parentSelection';

const TREE_LEVEL_OFFSET = 27;

export default function useDataLayersTree({
  assets,
  mode = 'default',
  withAttributes = false,
  withDatasetViewType = false,
  withGeoMapAttributes = false,
  grouping = false,
  satelliteImagesLoading = false,
  jdProfileIsHealth = false,
  jdProfileIsAuthorized = false,
  jdWorkPlanExportAvailable = false,
  isSynchronizedJohnDeereField = false,
  publishedDatasetsClickable = false,
  showItemMenu = false,
  showCleanCalibrateDataset = false,
  showEmptyGroups = false,
  showSatelliteRecommendations = false,
  collapsible = false,
  selectedAssetUuid,
  selectedAssetGroupType,
  farmUuid = '',
  fieldUuid = '',
  checked = {},
  children = [],
  hasGroupPlaceholderAction = true,
  onAssetNodeClick = () => {},
  onMenuItemClick = () => {},
  onAttributeNodeClick,
  onGroupPlaceholderActionClick = () => {},
  onCheckedChange = () => {},
  onCleanCalibrateDatasetClick = () => {},
}: {
  assets: {
    satelliteImages?: TransformedSatelliteImage[];
    vectorAnalysisMaps?: TransformedVectorAnalysisMap[];
    soilDatasets?: TransformedSoilDataset[];
    yieldDatasets?: TransformedYieldDataset[];
    asAppliedDatasets?: TransformedAsAppliedDataset[];
    topographyMaps?: TransformedTopographyMap[];
    threeDimensionalMaps?: TransformedThreeDimensionalMap[];
    equationMaps?: TransformedEquationMap[];
    pinsGroups?: PinsGroup[];
  };
  withAttributes?: boolean;
  withDatasetViewType?: boolean;
  withGeoMapAttributes?: boolean;
  mode?: ModeConfig;
  grouping?: boolean;
  satelliteImagesLoading?: boolean;
  jdProfileIsHealth?: boolean;
  jdProfileIsAuthorized?: boolean;
  jdWorkPlanExportAvailable?: boolean;
  isSynchronizedJohnDeereField?: boolean;
  publishedDatasetsClickable?: boolean;
  showItemMenu?: boolean;
  showCleanCalibrateDataset?: boolean;
  showEmptyGroups?: boolean;
  showSatelliteRecommendations?: boolean;
  collapsible?: boolean;
  selectedAssetUuid?: string;
  selectedAssetGroupType?: AssetGroupType | null;
  farmUuid?: string;
  fieldUuid?: string;
  checked?: Record<string, number>;
  children?: ReactElement[];
  hasGroupPlaceholderAction?: boolean;
  onAssetNodeClick?: <T extends TransformedAsset>(
    t: AssetGroupType,
    i: T,
  ) => void;
  onMenuItemClick?: <T extends TransformedAsset>(m: string, i: T) => void;
  onAttributeNodeClick?: (itemNode: DataLayersTreeNode | null) => void;
  onGroupPlaceholderActionClick?: <T>(type: AssetGroupType, action?: T) => void;
  onCheckedChange?: (
    u: Record<string, number>,
    item?: DataLayersTreeNode,
  ) => void;
  onCleanCalibrateDatasetClick?: (d: TransformedDataset) => void;
}) {
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});
  const [selectedAssetPathExpanded, setSelectedAssetPathExpanded] =
    useState(false);
  const [selectedAssetScrolled, setSelectedAssetScrolled] = useState(false);
  const [activeAssetGroup, setActiveAssetGroup] = useState<string | null>();

  const [filtersValue, setFiltersValue] = useState<AssetGroupsFilters>({
    [AssetGroupType.satelliteImages]: DEFAULT_SAT_IMAGES_FILTER,
    [AssetGroupType.vectorAnalysisMaps]: DEFAULT_VAMAPS_FILTER,
    [AssetGroupType.pinsGroups]: DEFAULT_PINS_GROUPS_FILTER,
    [AssetGroupType.equationMaps]: DEFAULT_ASSET_GROUP_FILTER,
    [AssetGroupType.soilDatasets]: DEFAULT_ASSET_GROUP_FILTER,
    [AssetGroupType.yieldDatasets]: DEFAULT_ASSET_GROUP_FILTER,
    [AssetGroupType.asAppliedDatasets]: DEFAULT_ASSET_GROUP_FILTER,
    [AssetGroupType.topographyMaps]: DEFAULT_ASSET_GROUP_FILTER,
    [AssetGroupType.threeDimensionalMaps]: DEFAULT_ASSET_GROUP_FILTER,
  });

  const filteredVectorAnalysisMaps = useMemo(
    () =>
      filterVamaps(
        filtersValue[AssetGroupType.vectorAnalysisMaps],
        assets.vectorAnalysisMaps,
      ),
    [assets.vectorAnalysisMaps, filtersValue],
  );
  const filteredSatelliteImages = useMemo(
    () =>
      filterSatelliteImages(
        filtersValue[AssetGroupType.satelliteImages],
        assets.satelliteImages,
      ),
    [assets.satelliteImages, filtersValue],
  );
  const filteredPinsGroups = useMemo(
    () =>
      filterPinsGroups(
        filtersValue[AssetGroupType.pinsGroups],
        assets.pinsGroups,
      ),
    [assets.pinsGroups, filtersValue],
  );
  const filteredSoilDatasets = useMemo(
    () =>
      filterDatasets(
        filtersValue[AssetGroupType.soilDatasets],
        assets.soilDatasets,
      ),
    [assets.soilDatasets, filtersValue],
  );
  const filteredYieldDatasets = useMemo(
    () =>
      filterDatasets(
        filtersValue[AssetGroupType.yieldDatasets],
        assets.yieldDatasets,
      ),
    [assets.yieldDatasets, filtersValue],
  );
  const filteredAsAppliedDatasets = useMemo(
    () =>
      filterDatasets(
        filtersValue[AssetGroupType.asAppliedDatasets],
        assets.asAppliedDatasets,
      ),
    [assets.asAppliedDatasets, filtersValue],
  );
  const filteredEquationMaps = useMemo(
    () =>
      filterAssetGroupItems(
        filtersValue[AssetGroupType.equationMaps],
        assets.equationMaps,
      ),
    [assets.equationMaps, filtersValue],
  );
  const filteredTopographyMaps = useMemo(
    () =>
      filterAssetGroupItems(
        filtersValue[AssetGroupType.topographyMaps],
        assets.topographyMaps,
      ),
    [assets.topographyMaps, filtersValue],
  );
  const filteredThreeDimensionalMaps = useMemo(
    () =>
      filterAssetGroupItems(
        filtersValue[AssetGroupType.threeDimensionalMaps],
        assets.threeDimensionalMaps,
      ),
    [assets.threeDimensionalMaps, filtersValue],
  );

  const handleAssetGroupNodeClick = (assetGroupType: AssetGroupType) => {
    const groupExpanded = !expanded[assetGroupType];

    if (groupExpanded) {
      setActiveAssetGroup(assetGroupType);
    } else if (!groupExpanded && assetGroupType === activeAssetGroup) {
      setActiveAssetGroup(null);
    }

    setExpanded({
      ...expanded,
      [assetGroupType]: groupExpanded,
    });
  };

  const handleAssetNodeClick = <T extends TransformedAsset>(
    assetGroupType: AssetGroupType,
    item: T,
    id: string,
    opts?: {
      skipExpand?: boolean;
    },
  ) => {
    setSelectedAssetScrolled(true);
    setSelectedAssetPathExpanded(true);

    onAssetNodeClick(assetGroupType, item);

    if (opts?.skipExpand) {
      return;
    }

    const itemExpanded = !expanded[id];

    setActiveAssetGroup(assetGroupType);
    setExpanded({
      ...expanded,
      [id]: itemExpanded,
    });
  };

  const handleViewTypeNodeClick = (id: string) => {
    const itemExpanded = !expanded[id];

    setExpanded({
      ...expanded,
      [id]: itemExpanded,
    });
  };

  const handleExpandMoreClick = (
    assetGroupType: AssetGroupType,
    id: string,
  ) => {
    setSelectedAssetScrolled(true);
    setSelectedAssetPathExpanded(true);

    const itemExpanded = !expanded[id];

    setActiveAssetGroup(assetGroupType);
    setExpanded({
      ...expanded,
      [id]: itemExpanded,
    });
  };

  const handleAssetGroupToggleClick = (
    assetGroupType: AssetGroupType,
    id: string,
  ) => {
    const itemExpanded = !expanded[id];

    setActiveAssetGroup(assetGroupType);
    setExpanded({
      ...expanded,
      [id]: itemExpanded,
    });
  };

  const handleCheckboxClick = (
    selectUpdate: Record<string, number>,
    item: DataLayersTreeNode,
  ) => {
    onCheckedChange(selectUpdate, item);
  };

  const handleMenuItemClick = <T extends TransformedAsset>(
    menuItem: string,
    item: T,
  ) => {
    onMenuItemClick(menuItem, item);
  };

  const isEquationMapSelectable = (item: TransformedEquationMap) =>
    !isEquationMapInvalid(item);

  const isDatasetSelectable = (item: TransformedDataset) =>
    !isDatasetItemDisabled(item);

  const treeNodeGetter = (id: string) => findNodeById(dataLayersTree, id);

  const handleRecommendedImagesSelect = (uuids: string[]) => {
    const selectUpdate = uuids.reduce<Record<string, number>>(
      (acc, uuid) => ({
        ...acc,
        [generateAssetNodeId(uuid, AssetGroupType.satelliteImages)]: 2,
      }),
      {} as Record<string, number>,
    );

    if (uuids.length === assets.satelliteImages?.length) {
      selectUpdate[AssetGroupType.satelliteImages] = 2;
    } else if (uuids.length !== 0) {
      selectUpdate[AssetGroupType.satelliteImages] = 1;
    }

    onCheckedChange(selectUpdate);
  };

  const recommendedImagesRenderer = () => (
    <RecommendedSatelliteImagesPicker
      allImages={assets.satelliteImages}
      filteredImages={filteredSatelliteImages}
      onSelect={handleRecommendedImagesSelect}
    />
  );

  const handleFiltersUpdate = (newFilters: Partial<AssetGroupsFilters>) => {
    setFiltersValue((prev) => ({ ...prev, ...newFilters }));
  };

  const handleFiltersClear = (type: AssetGroupType) => {
    const defaultAssetGroupFilter = getDefaultAssetGroupFilterValue(type);

    handleFiltersUpdate({
      [type]: defaultAssetGroupFilter,
    });
  };

  const handleFilterClear = (
    type: AssetGroupType,
    key: AssetGroupFilterKey,
  ) => {
    const assetGroupFilter = filtersValue[type];

    handleFiltersUpdate({
      [type]: {
        ...assetGroupFilter,
        [key]: getDefaultFilterValue({ [type]: key }),
      },
    });
  };

  const assetGroupNodeRenderer = (
    assetGroupType: AssetGroupType,
    id: string,
    offset: number,
  ) => {
    const assetGroup = {
      ...assets,
      satelliteImages: filteredSatelliteImages,
      vectorAnalysisMaps: filteredVectorAnalysisMaps,
      pinsGroups: filteredPinsGroups,
    }[assetGroupType];

    return (
      <AssetGroupNode<ArrayItem<typeof assetGroup>>
        id={id}
        assetGroupType={assetGroupType}
        mode={getModeValue(assetGroupType, mode)}
        assetGroup={assetGroup}
        checked={checked}
        expanded={expanded}
        offset={offset}
        treeNodeGetter={() => treeNodeGetter(id)}
        satelliteImagesLoading={satelliteImagesLoading}
        onCheckboxClick={handleCheckboxClick}
        onClick={() => handleAssetGroupNodeClick(assetGroupType)}
      >
        {expanded[id] && !!assets[assetGroupType]?.length && (
          <FiltersPopover
            onFiltersClear={() => handleFiltersClear(assetGroupType)}
          >
            {assetGroupFilterRenderer({
              filtersValue,
              type: assetGroupType,
              onFiltersUpdate: handleFiltersUpdate,
              assets: {
                satelliteImages: assets.satelliteImages ?? [],
                vectorAnalysisMaps: assets.vectorAnalysisMaps ?? [],
                pinsGroups: assets.pinsGroups ?? [],
              },
            })}
          </FiltersPopover>
        )}
      </AssetGroupNode>
    );
  };

  const satelliteImageNodeRenderer = (
    image: TransformedSatelliteImage,
    id: string,
    offset: number,
  ) => (
    <SatelliteImageNode
      id={id}
      image={image}
      mode={getModeValue(AssetGroupType.satelliteImages, mode)}
      checked={checked}
      expanded={expanded}
      withAttributes={isAttributesDisplayed(
        withAttributes,
        AssetGroupType.satelliteImages,
        mode,
      )}
      treeNodeGetter={() => treeNodeGetter(id)}
      jdProfileIsHealth={jdProfileIsHealth}
      jdProfileIsAuthorized={jdProfileIsAuthorized}
      isSynchronizedJohnDeereField={isSynchronizedJohnDeereField}
      showItemMenu={showItemMenu}
      selectedItemUuid={selectedAssetUuid}
      selectedItemGroupType={selectedAssetGroupType}
      offset={offset}
      onCheckboxClick={handleCheckboxClick}
      onClick={() =>
        handleAssetNodeClick(AssetGroupType.satelliteImages, image, id)
      }
      onMenuItemClick={(menuItem) => handleMenuItemClick(menuItem, image)}
      onExpandMoreClick={() =>
        handleExpandMoreClick(AssetGroupType.satelliteImages, id)
      }
    />
  );

  const vectorAnalysisMapNodeRenderer = (
    vamap: TransformedVectorAnalysisMap,
    id: string,
    offset: number,
  ) => (
    <ZonesMapNode
      id={id}
      vamap={vamap}
      mode={getModeValue(AssetGroupType.vectorAnalysisMaps, mode)}
      checked={checked}
      treeNodeGetter={() => treeNodeGetter(id)}
      jdProfileIsHealth={jdProfileIsHealth}
      jdProfileIsAuthorized={jdProfileIsAuthorized}
      isSynchronizedJohnDeereField={isSynchronizedJohnDeereField}
      jdWorkPlanExportAvailable={jdWorkPlanExportAvailable}
      showItemMenu={showItemMenu}
      selectedItemUuid={selectedAssetUuid}
      selectedItemGroupType={selectedAssetGroupType}
      offset={offset}
      onCheckboxClick={handleCheckboxClick}
      onClick={() =>
        handleAssetNodeClick(AssetGroupType.vectorAnalysisMaps, vamap, id)
      }
      onMenuItemClick={(menuItem) => handleMenuItemClick(menuItem, vamap)}
    />
  );

  const datasetNodeRenderer: DatasetDataLayers<TransformedDataset>['renderer'] =
    (dataset, id, offset, opts) => {
      const assetGroupType = ASSET_TYPE_TO_ASSET_GROUP[dataset.assetType];
      const groupingEnabled = isDatasetsGroupingEnabled(
        grouping,
        assetGroupType,
        mode,
      );
      const attributesDisplayed = isAttributesDisplayed(
        withAttributes || withDatasetViewType,
        assetGroupType,
        mode,
      );

      return (
        <DatasetNode
          id={id}
          dataset={dataset}
          mode={getModeValue(assetGroupType, mode)}
          checked={checked}
          expanded={expanded}
          treeNodeGetter={() => treeNodeGetter(id)}
          jdProfileIsHealth={jdProfileIsHealth}
          jdProfileIsAuthorized={jdProfileIsAuthorized}
          isSynchronizedJohnDeereField={isSynchronizedJohnDeereField}
          withAttributes={attributesDisplayed}
          grouping={groupingEnabled ? opts?.grouping : undefined}
          showItemMenu={showItemMenu}
          showCleanCalibrate={showCleanCalibrateDataset}
          assetGroupType={assetGroupType}
          selectedItemUuid={selectedAssetUuid}
          selectedItemGroupType={selectedAssetGroupType}
          publishedClickable={publishedDatasetsClickable}
          offset={offset}
          onCheckboxClick={handleCheckboxClick}
          onMenuItemClick={(menuItem) => handleMenuItemClick(menuItem, dataset)}
          onClick={() =>
            handleAssetNodeClick(assetGroupType, dataset, id, {
              skipExpand:
                opts?.grouping?.memberType === 'parent' || attributesDisplayed,
            })
          }
          onExpandMoreClick={() => handleExpandMoreClick(assetGroupType, id)}
          onGroupToggleClick={() =>
            handleAssetGroupToggleClick(assetGroupType, id)
          }
          onCleanCalibrateClick={() => onCleanCalibrateDatasetClick(dataset)}
        />
      );
    };

  const topographyNodeRenderer = (
    dataset: TransformedTopographyMap,
    id: string,
    offset: number,
  ) => {
    const attributesDisplayed = isAttributesDisplayed(
      withAttributes,
      AssetGroupType.topographyMaps,
      mode,
    );

    return (
      <TopographyNode
        id={id}
        dataset={dataset}
        mode={getModeValue(AssetGroupType.topographyMaps, mode)}
        checked={checked}
        expanded={expanded}
        treeNodeGetter={() => treeNodeGetter(id)}
        jdProfileIsHealth={jdProfileIsHealth}
        jdProfileIsAuthorized={jdProfileIsAuthorized}
        isSynchronizedJohnDeereField={isSynchronizedJohnDeereField}
        withAttributes={attributesDisplayed}
        showItemMenu={showItemMenu}
        selectedItemUuid={selectedAssetUuid}
        selectedItemGroupType={selectedAssetGroupType}
        offset={offset}
        onCheckboxClick={handleCheckboxClick}
        onMenuItemClick={(menuItem) => handleMenuItemClick(menuItem, dataset)}
        onClick={() =>
          handleAssetNodeClick(AssetGroupType.topographyMaps, dataset, id, {
            skipExpand: attributesDisplayed,
          })
        }
        onExpandMoreClick={() =>
          handleExpandMoreClick(AssetGroupType.topographyMaps, id)
        }
      />
    );
  };

  const threeDimensionalMapNodeRenderer = (
    threeDMap: TransformedThreeDimensionalMap,
    id: string,
    offset: number,
  ) => (
    <ThreeDimensionalMapNode
      id={id}
      threeDMap={threeDMap}
      mode={getModeValue(AssetGroupType.threeDimensionalMaps, mode)}
      checked={checked}
      treeNodeGetter={() => treeNodeGetter(id)}
      showItemMenu={showItemMenu}
      selectedItemUuid={selectedAssetUuid}
      selectedItemGroupType={selectedAssetGroupType}
      offset={offset}
      onCheckboxClick={handleCheckboxClick}
      onClick={() =>
        handleAssetNodeClick(AssetGroupType.threeDimensionalMaps, threeDMap, id)
      }
      onMenuItemClick={(menuItem) => handleMenuItemClick(menuItem, threeDMap)}
    />
  );

  const equationMapNodeRenderer = (
    equationMap: TransformedEquationMap,
    id: string,
    offset: number,
  ) => (
    <EquationMapNode
      id={id}
      equationMap={equationMap}
      mode={getModeValue(AssetGroupType.equationMaps, mode)}
      withAttributes={isAttributesDisplayed(
        withGeoMapAttributes,
        AssetGroupType.equationMaps,
        mode,
      )}
      checked={checked}
      expanded={expanded}
      treeNodeGetter={() => treeNodeGetter(id)}
      jdProfileIsHealth={jdProfileIsHealth}
      jdProfileIsAuthorized={jdProfileIsAuthorized}
      jdWorkPlanExportAvailable={jdWorkPlanExportAvailable}
      isSynchronizedJohnDeereField={isSynchronizedJohnDeereField}
      showItemMenu={showItemMenu}
      selectedItemUuid={selectedAssetUuid}
      selectedItemGroupType={selectedAssetGroupType}
      offset={offset}
      onCheckboxClick={handleCheckboxClick}
      onClick={() =>
        handleAssetNodeClick(AssetGroupType.equationMaps, equationMap, id)
      }
      onMenuItemClick={(menuItem) => handleMenuItemClick(menuItem, equationMap)}
      onExpandMoreClick={() =>
        handleExpandMoreClick(AssetGroupType.equationMaps, id)
      }
    />
  );

  const pinsGroupNodeRenderer = (
    pinsGroup: PinsGroup,
    id: string,
    offset: number,
  ) => (
    <PinsGroupNode
      id={id}
      pinsGroup={pinsGroup}
      mode={getModeValue(AssetGroupType.pinsGroups, mode)}
      checked={checked}
      offset={offset}
      treeNodeGetter={() => treeNodeGetter(id)}
      selectedItemUuid={selectedAssetUuid}
      selectedItemGroupType={selectedAssetGroupType}
      onCheckboxClick={handleCheckboxClick}
      onClick={() =>
        handleAssetNodeClick(AssetGroupType.pinsGroups, pinsGroup, id)
      }
    />
  );

  const groupPlaceholderNodeRenderer = (assetGroupType: AssetGroupType) => (
    <GroupPlaceholderNode
      farmUuid={farmUuid}
      fieldUuid={fieldUuid}
      assetGroupType={assetGroupType}
      satelliteImagesLoading={satelliteImagesLoading}
      withAction={hasGroupPlaceholderAction}
      onActionClick={onGroupPlaceholderActionClick}
    />
  );

  const attributeNodeRenderer = (
    assetType: AssetType,
    id: string,
    name: string,
    offset: number,
  ) => {
    const assetGroupType = ASSET_TYPE_TO_ASSET_GROUP[assetType];

    return (
      <AttributeNode
        id={id}
        name={name}
        isExpanded={expanded[id]}
        mode={getModeValue(assetGroupType, mode)}
        checked={checked}
        offset={offset}
        treeNodeGetter={() => treeNodeGetter(id)}
        onCheckboxClick={handleCheckboxClick}
        onAttributeClick={onAttributeNodeClick}
        onExpandMoreClick={() => handleViewTypeNodeClick(id)}
      />
    );
  };

  const datasetViewTypeAttributeNodeRenderer = (
    assetType: AssetType,
    id: string,
    name: string,
    offset: number,
  ) => {
    const assetGroupType = ASSET_TYPE_TO_ASSET_GROUP[assetType];
    const attributesDisplayed = isAttributesDisplayed(
      withAttributes,
      assetGroupType,
      mode,
    );

    return (
      <AttributeNode
        id={id}
        name={name}
        isExpanded={expanded[id]}
        isParent={attributesDisplayed}
        mode={getModeValue(assetGroupType, mode)}
        checked={checked}
        offset={offset}
        treeNodeGetter={() => treeNodeGetter(id)}
        onCheckboxClick={handleCheckboxClick}
        onExpandMoreClick={() => handleViewTypeNodeClick(id)}
      />
    );
  };

  const filterRenderer = (type: AssetGroupType) => (
    <SelectedFilters
      type={type}
      filtersValue={filtersValue[type]}
      onFilterClear={(key) => handleFilterClear(type, key)}
      onFiltersClear={() => handleFiltersClear(type)}
    />
  );

  const getFilterRenderer = (type: AssetGroupType) =>
    assets[type]?.length ? () => filterRenderer(type) : null;

  const dataLayersTree = generateDataLayersTree({
    satelliteImages: assets.satelliteImages
      ? {
          assets: assets.satelliteImages,
          filteredAssets: filteredSatelliteImages,
          satelliteImagesLoading,
          renderer: satelliteImageNodeRenderer,
          filterRenderer: getFilterRenderer(AssetGroupType.satelliteImages),
          recommendedImages: {
            enabled: showSatelliteRecommendations,
            renderer: recommendedImagesRenderer,
          },
          withAttributes: {
            enabled: isAttributesDisplayed(
              withAttributes,
              AssetGroupType.satelliteImages,
              mode,
            ),
            renderer: attributeNodeRenderer,
          },
        }
      : undefined,
    vectorAnalysisMaps: assets.vectorAnalysisMaps
      ? {
          assets: assets.vectorAnalysisMaps,
          filteredAssets: filteredVectorAnalysisMaps,
          renderer: vectorAnalysisMapNodeRenderer,
          filterRenderer: getFilterRenderer(AssetGroupType.vectorAnalysisMaps),
        }
      : undefined,
    soilDatasets: assets.soilDatasets
      ? {
          assets: assets.soilDatasets,
          filteredAssets: filteredSoilDatasets,
          renderer: datasetNodeRenderer,
          filterRenderer: getFilterRenderer(AssetGroupType.soilDatasets),
          withDatasetViewType: {
            enabled: isAttributesDisplayed(
              withDatasetViewType,
              AssetGroupType.soilDatasets,
              mode,
            ),
            renderer: datasetViewTypeAttributeNodeRenderer,
          },
          withAttributes: {
            enabled: isAttributesDisplayed(
              withAttributes,
              AssetGroupType.soilDatasets,
              mode,
            ),
            renderer: attributeNodeRenderer,
          },
          grouping: {
            enabled: isDatasetsGroupingEnabled(
              grouping,
              AssetGroupType.soilDatasets,
              mode,
            ),
          },
          isItemSelectable: isDatasetSelectable,
        }
      : undefined,
    yieldDatasets: assets.yieldDatasets
      ? {
          assets: assets.yieldDatasets,
          filteredAssets: filteredYieldDatasets,
          renderer: datasetNodeRenderer,
          filterRenderer: getFilterRenderer(AssetGroupType.yieldDatasets),
          withDatasetViewType: {
            enabled: isAttributesDisplayed(
              withDatasetViewType,
              AssetGroupType.yieldDatasets,
              mode,
            ),
            renderer: datasetViewTypeAttributeNodeRenderer,
          },
          withAttributes: {
            enabled: isAttributesDisplayed(
              withAttributes,
              AssetGroupType.yieldDatasets,
              mode,
            ),
            renderer: attributeNodeRenderer,
          },
          grouping: {
            enabled: isDatasetsGroupingEnabled(
              grouping,
              AssetGroupType.yieldDatasets,
              mode,
            ),
          },
          isItemSelectable: isDatasetSelectable,
        }
      : undefined,
    asAppliedDatasets: assets.asAppliedDatasets
      ? {
          assets: assets.asAppliedDatasets,
          filteredAssets: filteredAsAppliedDatasets,
          renderer: datasetNodeRenderer,
          filterRenderer: getFilterRenderer(AssetGroupType.asAppliedDatasets),
          withDatasetViewType: {
            enabled: isAttributesDisplayed(
              withDatasetViewType,
              AssetGroupType.asAppliedDatasets,
              mode,
            ),
            renderer: datasetViewTypeAttributeNodeRenderer,
          },
          withAttributes: {
            enabled: isAttributesDisplayed(
              withAttributes,
              AssetGroupType.asAppliedDatasets,
              mode,
            ),
            renderer: attributeNodeRenderer,
          },
          grouping: {
            enabled: isDatasetsGroupingEnabled(
              grouping,
              AssetGroupType.asAppliedDatasets,
              mode,
            ),
          },
          isItemSelectable: isDatasetSelectable,
        }
      : undefined,
    topographyMaps: assets.topographyMaps
      ? {
          assets: assets.topographyMaps,
          filteredAssets: filteredTopographyMaps,
          renderer: topographyNodeRenderer,
          filterRenderer: getFilterRenderer(AssetGroupType.topographyMaps),
          withAttributes: {
            enabled: isAttributesDisplayed(
              withAttributes,
              AssetGroupType.topographyMaps,
              mode,
            ),
            renderer: attributeNodeRenderer,
          },
        }
      : undefined,
    threeDimensionalMaps: assets.threeDimensionalMaps
      ? {
          assets: assets.threeDimensionalMaps,
          filteredAssets: filteredThreeDimensionalMaps,
          renderer: threeDimensionalMapNodeRenderer,
          filterRenderer: getFilterRenderer(
            AssetGroupType.threeDimensionalMaps,
          ),
        }
      : undefined,
    equationMaps: assets.equationMaps
      ? {
          assets: assets.equationMaps,
          filteredAssets: filteredEquationMaps,
          renderer: equationMapNodeRenderer,
          isItemSelectable: isEquationMapSelectable,
          filterRenderer: getFilterRenderer(AssetGroupType.equationMaps),
          withAttributes: {
            enabled: isAttributesDisplayed(
              withGeoMapAttributes,
              AssetGroupType.equationMaps,
              mode,
            ),
            renderer: attributeNodeRenderer,
          },
        }
      : undefined,
    pinsGroups: assets.pinsGroups
      ? {
          assets: assets.pinsGroups,
          filteredAssets: filteredPinsGroups,
          renderer: pinsGroupNodeRenderer,
          filterRenderer: getFilterRenderer(AssetGroupType.pinsGroups),
        }
      : undefined,
    assetGroup: {
      renderer: assetGroupNodeRenderer,
    },
    groupPlaceholder: {
      enabled: showEmptyGroups,
      renderer: groupPlaceholderNodeRenderer,
    },
  });

  const { treeView, ref, getNodeIndexById } = useTreeView({
    nodes: dataLayersTree,
    offset: TREE_LEVEL_OFFSET,
    expanded,
  });

  useEffect(() => {
    if (
      !selectedAssetUuid ||
      !selectedAssetGroupType ||
      !dataLayersTree ||
      selectedAssetPathExpanded
    ) {
      return;
    }

    const node = findNodeById(
      dataLayersTree,
      generateAssetNodeId(selectedAssetUuid, selectedAssetGroupType),
    );

    if (!node) {
      return;
    }

    let nodeParent = node.parent;
    let expandedUpdate = expanded;

    while (nodeParent && !expanded[nodeParent.id]) {
      expandedUpdate = {
        ...expandedUpdate,
        [nodeParent.id]: true,
      };

      nodeParent = nodeParent.parent;
    }

    setExpanded((prev) => ({
      ...prev,
      ...expandedUpdate,
    }));

    setSelectedAssetPathExpanded(true);
  }, [
    dataLayersTree,
    selectedAssetUuid,
    selectedAssetGroupType,
    expanded,
    selectedAssetPathExpanded,
  ]);

  useEffect(() => {
    if (
      !selectedAssetUuid ||
      !selectedAssetGroupType ||
      !dataLayersTree ||
      selectedAssetScrolled
    ) {
      return;
    }

    const node = findNodeById(
      dataLayersTree,
      generateAssetNodeId(selectedAssetUuid, selectedAssetGroupType),
    );

    if (!node) {
      return;
    }

    // setTimeout to wait for expanded items to be rendered
    setTimeout(() => {
      const indexToScroll = getNodeIndexById(node.id);
      ref.current?.scrollToIndex(indexToScroll);
      setSelectedAssetScrolled(true);
      setSelectedAssetPathExpanded(true);
    });
  }, [
    dataLayersTree,
    selectedAssetPathExpanded,
    getNodeIndexById,
    ref,
    selectedAssetUuid,
    selectedAssetGroupType,
    selectedAssetScrolled,
  ]);

  useEffect(() => {
    if (!Object.entries(checked).length) {
      return;
    }

    let parentSelection = {};

    for (const node of traverseTree(dataLayersTree)) {
      if (checked[node.id]) {
        parentSelection = {
          ...parentSelection,
          ...updateParentSelection(node.parent, checked),
        };
      }
    }

    onCheckedChange({
      ...checked,
      ...parentSelection,
    });
    // Checked object is excluded from dependency array in as the onCheckedChanged function modifies the checked object.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filteredVectorAnalysisMaps,
    filteredSatelliteImages,
    filteredPinsGroups,
    filteredEquationMaps,
    filteredSoilDatasets,
    filteredYieldDatasets,
    filteredAsAppliedDatasets,
    filteredTopographyMaps,
    filteredThreeDimensionalMaps,
  ]);

  return {
    dataLayersTreeComponent: (
      <DataLayersTree
        dataLayersTree={dataLayersTree}
        treeView={treeView}
        collapsible={collapsible}
      >
        {children}
      </DataLayersTree>
    ),
    dataLayersTree,
  };
}
