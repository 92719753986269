import React from 'react';

import LayersLimitPanel from '../../../dataLayersView/components/DataLayersTree/Panels/LayersLimitPanel';
import { PDF_EXPORT_LIMIT } from '../../helpers/constants/export';
import { SelectedDataLayersData } from '../../../dataLayersView/types/dataLayersTree';

export default function ExportLimitDataLayersTreePanel(props: {
  checked: Record<string, number>;
  onCancelClick: () => void;
  onConfirmClick: (d: SelectedDataLayersData) => void;
}) {
  return (
    <LayersLimitPanel
      {...props}
      limit={PDF_EXPORT_LIMIT}
      confirmButtonTitleI18nKey="general.controls.export"
      descriptionI18nKey="field.assets.export-limit"
    />
  );
}
