import React from 'react';

import CheckboxItem from '../../../TreeView/CheckboxItem';
import PinsGroupItem from '../../../Items/PinsGroupItem';
import SimpleItem from '../../../TreeView/SimpleItem';
import type {
  Mode,
  DataLayersTreeNode,
} from '../../../../types/dataLayersTree';
import { AssetGroupType } from '../../../../../../../helpers/constants/entities/asset';
import type { PinsGroup } from '../../../../../../pins/types';

export default function PinsGroupNode({
  id,
  pinsGroup,
  mode,
  checked,
  offset,
  treeNodeGetter,
  selectedItemUuid,
  selectedItemGroupType,
  onCheckboxClick,
  onClick,
}: {
  id: string;
  pinsGroup: PinsGroup;
  mode: Mode;
  checked: Record<string, number>;
  offset: number;
  treeNodeGetter: () => DataLayersTreeNode | null;
  selectedItemUuid?: string;
  selectedItemGroupType?: string | null;
  onCheckboxClick: (
    v: Record<string, number>,
    item: DataLayersTreeNode,
  ) => void;
  onClick: () => void;
}) {
  let result;
  const item = <PinsGroupItem pinsGroup={pinsGroup} />;
  const isItemSelected =
    pinsGroup.uuid === selectedItemUuid &&
    selectedItemGroupType === AssetGroupType.pinsGroups;

  if (mode === 'multiSelect') {
    result = (
      <CheckboxItem
        itemId={id}
        treeNodeGetter={treeNodeGetter}
        checked={checked}
        isSelected={isItemSelected}
        offset={offset}
        onCheckboxClick={onCheckboxClick}
        onClick={onClick}
      >
        {item}
      </CheckboxItem>
    );
  } else {
    result = (
      <SimpleItem isSelected={isItemSelected} offset={offset} onClick={onClick}>
        {item}
      </SimpleItem>
    );
  }

  return result;
}
