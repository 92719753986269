import { useAppSelector } from '../../../../app/store/helpers/functions';
import { selectYieldDataset } from '../../../field/fieldSelectors';
import {
  selectCleanExcludedAttributes,
  selectCleaningType,
  selectCleanMinMaxConditions,
  selectCleanMinMaxUSDAConditions,
  selectCleanTargetAttribute,
  selectDatasetUuid,
} from '../cleanCalibrateSelectors';
import { CleaningType } from '../helpers/constants/ui';
import {
  isSmartCleanParamsValid,
  isUSDACleanParamsValid,
} from '../helpers/functions/actions';
import { CleanDatasetActionParams } from '../types/actions';

export default function useCleanParams(): CleanDatasetActionParams | null {
  const datasetUuid = useAppSelector(selectDatasetUuid);
  const dataset = useAppSelector((state) =>
    selectYieldDataset(state, datasetUuid),
  );
  const cleaningType = useAppSelector(selectCleaningType);
  const targetAttribute = useAppSelector(selectCleanTargetAttribute);
  const excludedAttributes = useAppSelector(selectCleanExcludedAttributes);
  const minMaxConditions = useAppSelector(selectCleanMinMaxConditions);
  const minMaxUSDAConditions = useAppSelector(selectCleanMinMaxUSDAConditions);

  let params: CleanDatasetActionParams | null = null;
  const requiredParams = {
    targetAttribute,
    conditionMinMaxClean: [
      ...minMaxUSDAConditions
        .filter(({ selected }) => selected)
        .map((condition) => ({
          cleanAttribute: condition.cleanAttribute,
          min: condition.min,
          max: condition.max,
          ...(condition.stdNumber !== undefined
            ? { stdNumber: condition.stdNumber }
            : null),
        })),
      ...minMaxConditions.map((condition) => ({
        cleanAttribute: condition.cleanAttribute,
        min: condition.min,
        max: condition.max,
      })),
    ],
  };

  if (
    cleaningType === CleaningType.smart &&
    isSmartCleanParamsValid(requiredParams, dataset?.statistics || [])
  ) {
    params = {
      conditionAutoClean: {
        targetAttribute: requiredParams.targetAttribute,
        excludedAttributes: excludedAttributes || [],
      },
      conditionMinMaxClean: requiredParams.conditionMinMaxClean,
    };
  } else if (
    cleaningType === CleaningType.usda &&
    isUSDACleanParamsValid(requiredParams, dataset?.statistics || [])
  ) {
    params = {
      conditionMinMaxClean: requiredParams.conditionMinMaxClean,
    };
  }

  return params;
}
