import React from 'react';
import Typography from '@material-ui/core/Typography';

import GeoMapPreviewTooltip from '../../../../Tooltip/Tooltips/GeoMapPreviewTooltip';
import Link from '../../../../Link';
import { GeoMap } from '../../../../../helpers/types/api';
import ItemPlaceholder from '../ItemPlaceholder';

import './index.scss';

export interface AssetItemAnalysisMap {
  link: string;
  geoMap?: GeoMap;
  attribute?: string;
}

export default function AnalysisMapItem({
  label,
  value,
  analysisMap,
  apiKey,
}: {
  label: string;
  value: string;
  analysisMap: AssetItemAnalysisMap;
  apiKey: string;
}) {
  return (
    <div className="source-assets-analysis-map-item">
      <Typography
        variant="body2"
        className="source-assets-analysis-map-item__label"
      >
        {label}
      </Typography>
      <div className="source-assets-analysis-map-item__value">
        {value ? (
          <>
            {analysisMap.geoMap ? (
              <GeoMapPreviewTooltip
                apiKey={apiKey}
                geoMap={analysisMap.geoMap}
                hideLegend={true}
              >
                <Link to={analysisMap.link} target="_blank">
                  {value}
                </Link>
              </GeoMapPreviewTooltip>
            ) : (
              <Link to={analysisMap.link} target="_blank">
                {value}
              </Link>
            )}
          </>
        ) : (
          <ItemPlaceholder />
        )}
      </div>
    </div>
  );
}
