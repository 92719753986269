import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContentText } from '@material-ui/core';

import RadioGroup from '../../../../../components/RadioGroup';
import { CleaningType } from '../../helpers/constants/ui';

import './index.scss';

const TYPE_TO_DESCRIPTIONS_MAP = {
  [CleaningType.smart]:
    'clean-calibrate.yield-popup.tabs.clean.configure.cleaning-type.smart.description',
  [CleaningType.usda]:
    'clean-calibrate.yield-popup.tabs.clean.configure.cleaning-type.usda.description',
};

export default function CleaningTypeSelector({
  value,
  onTypeChange,
}: {
  value: CleaningType;
  onTypeChange: (v: CleaningType) => void;
}) {
  const { t } = useTranslation();
  const description = t(TYPE_TO_DESCRIPTIONS_MAP[value]);

  return (
    <div className="cleaning-type-selector">
      <DialogContentText className="cleaning-type-selector__title">
        {t(
          'clean-calibrate.yield-popup.tabs.clean.configure.cleaning-type.title',
        )}
      </DialogContentText>
      <RadioGroup
        classes={{
          root: 'cleaning-type-selector__radio-group',
        }}
        horizontal
        options={[
          {
            title: t(
              'clean-calibrate.yield-popup.tabs.clean.configure.cleaning-type.smart.title',
            ),
            value: CleaningType.smart,
          },
          {
            title: t(
              'clean-calibrate.yield-popup.tabs.clean.configure.cleaning-type.usda.title',
            ),
            value: CleaningType.usda,
          },
        ]}
        value={value}
        onChange={onTypeChange}
      />
      <DialogContentText className="cleaning-type-selector__description">
        {description}
      </DialogContentText>
    </div>
  );
}
