import React, { useCallback, useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import AddAPhoto from '@material-ui/icons/AddAPhoto';
import Send from '@material-ui/icons/Send';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';

import PinPhoto from '../PinPhoto';
import { MAX_FILE_SIZE, MAX_PHOTO_COUNT } from '../../helpers/constants/pin';

import './index.scss';

const PinCommentInput = ({ onSave = () => {}, onWarning = () => {} }) => {
  const [comment, setComment] = useState('');
  const [photos, setPhotos] = useState([]);
  const [isSaveButtonVisible, setIsSaveButtonVisible] = useState(false);
  const { t } = useTranslation();

  const onPhotosAccepted = (acceptedPhotos) => {
    if (!acceptedPhotos?.length) {
      return;
    }

    setPhotos([
      ...photos,
      ...acceptedPhotos.map((photo) => ({
        file: photo,
        tempId: Math.random(),
        url: window.URL.createObjectURL(photo),
      })),
    ]);
  };

  const onPhotoDeleteClick = (item) => {
    setPhotos(photos.filter((photo) => photo.tempId !== item.tempId));
  };

  const onSaveClick = () => {
    onSave({
      comment,
      photos,
    });
    setComment('');
    setPhotos([]);
  };

  const handleCommentChange = useCallback((event) => {
    setComment(event?.target?.value || '');
  }, []);

  useEffect(() => {
    setIsSaveButtonVisible(!!(photos?.length || comment));
  }, [photos, comment]);

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    noDrag: true,
    accept: ['.jpg', '.jpeg', '.png'],
    maxSize: MAX_FILE_SIZE,
    maxFiles: MAX_PHOTO_COUNT - photos.length,
    onDropAccepted: onPhotosAccepted,
    onDropRejected: onWarning,
  });
  return (
    <div className="pin-comment-input">
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <IconButton
          classes={{
            root: 'pin-comment-input__button pin-comment-input__button_add',
          }}
          onClick={open}
          disabled={photos.length === MAX_PHOTO_COUNT}
        >
          <AddAPhoto />
        </IconButton>
      </div>
      <div className="pin-comment-input__input-container">
        <InputBase
          className="pin-comment-input__textarea"
          placeholder={t('general.popups.pin-details.comment-placeholder')}
          value={comment}
          onChange={handleCommentChange}
          multiline
          rowsMax="5"
        />
        {photos.length > 0 && (
          <div className="pin-comment-input__photos">
            {photos.map((photo, i) => (
              <PinPhoto
                key={`photo_${i}`}
                photo={photo}
                onPhotoDeleteClick={onPhotoDeleteClick}
              />
            ))}
            <div className="pin-comment-input__limit">
              <span>{`${photos.length}/${MAX_PHOTO_COUNT}`}</span>
            </div>
          </div>
        )}
      </div>
      {isSaveButtonVisible && (
        <IconButton
          classes={{
            root: 'pin-comment-input__button',
          }}
          aria-label="save comment button"
          onClick={onSaveClick}
        >
          <Send />
        </IconButton>
      )}
    </div>
  );
};

export default PinCommentInput;
