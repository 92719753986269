import TreeNodeEntity from '../../../../helpers/constants/entities/treeNodeEntity';
import ContextEventStatus from './contextEventStatus';

export const DEFAULT_STATUSES = [
  ContextEventStatus.start,
  ContextEventStatus.done,
  ContextEventStatus.error,
  ContextEventStatus.warning,
  ContextEventStatus.ignored,
  ContextEventStatus.inProgress,
  ContextEventStatus.collectedFromJohnDeere,
] as const;

export const DEFAULT_SUB_FIELD_TYPES = [
  TreeNodeEntity.soilDataset,
  TreeNodeEntity.yieldDataset,
  TreeNodeEntity.asAppliedDataset,
  TreeNodeEntity.elevation,
  TreeNodeEntity.fieldSatelliteImage,
  TreeNodeEntity.planetMask,
  TreeNodeEntity.planetImage,
  TreeNodeEntity.vectorAnalysisMap,
  TreeNodeEntity.equationMap,
  TreeNodeEntity.threeDimensionalMap,
];
