import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import PopupHeader from '../PopupHeader';
import Button from '../../Button';

import './index.scss';

export default function ExportData({
  title,
  content,
  actionsContent,
  cancelLabel = '',
  confirmLabel = '',
  onCancel = () => {},
  onConfirm = () => {},
}: {
  title: string;
  content: ReactNode;
  actionsContent?: ReactNode;
  cancelLabel?: string;
  confirmLabel?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
}) {
  const { t } = useTranslation();

  return (
    <Dialog
      open
      classes={{
        paper: 'export-data-popup__paper',
      }}
    >
      <PopupHeader title={title} onCancel={onCancel} />
      <DialogContent>
        <DialogContentText
          component="div"
          classes={{
            root: 'export-data-popup__content-text',
          }}
        >
          {t('general.popups.export-zones-map.description')}
        </DialogContentText>
        {content}
      </DialogContent>
      <DialogActions
        classes={{
          root: 'export-data-popup__actions',
        }}
      >
        {actionsContent}
        <div className="export-data-popup__buttons">
          <Button onClick={() => onCancel()} variant="outlined">
            {cancelLabel || t('general.controls.cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onConfirm()}
          >
            {confirmLabel || t('export.download')}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
