import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';

import AuthenticationHeader from '../AuthenticationHeader';
import BackdropLoading from '../../../../../components/Loading/BackdropLoading';
import bgXl from './bgXl.webp?url';
import bg from './bg.webp?url';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    padding: '24px 16px',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    backgroundImage: 'none',
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${bg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: '75%',
      alignItems: 'center',
    },
    [theme.breakpoints.up('xl')]: {
      backgroundImage: `url(${bgXl})`,
      backgroundPosition: 'center',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
    backgroundColor: 'white',
    padding: '0',
    borderRadius: '20px',
    [theme.breakpoints.up('md')]: {
      padding: '40px',
    },
  },
}));

const AuthenticationPanel = ({
  loading = false,
  children,
}: {
  loading?: boolean;
  children: ReactNode;
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {loading && <BackdropLoading />}
        <AuthenticationHeader />
        {children}
      </div>
    </div>
  );
};

export default AuthenticationPanel;
