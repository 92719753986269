#import "../fragments/asAppliedDataset.gql"

query getAsAppliedDatasets(
  $farmUuids: [ID]
  $fieldUuids: [ID]
  $datasetUuids: [ID]
  $areaUnit: AreaUnit
) {
  getFarms(farmUuids: $farmUuids) {
    uuid
    fields(fieldUuids: $fieldUuids) {
      uuid
      asAppliedDatasets(asAppliedDatasetUuids: $datasetUuids) {
        ...asAppliedDatasetFields
      }
    }
  }
}
