import React, { ChangeEvent } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';

import Label from '../../../../../../components/Label';
import Button from '../../../../../../components/Button';
import type { CollapsibleLabelsNestedTableRow } from '../../../types/row';

import './index.scss';

export default function CollapsibleLabelsCell({
  editMode = false,
  row,
  colSpan,
  classes = {},
  isExpanded,
  onRowExpandedChange,
}: {
  editMode: boolean;
  row: CollapsibleLabelsNestedTableRow;
  isSelected: boolean;
  isExpanded: boolean;
  colSpan: number;
  classes?: {
    cell?: string;
  };
  onRowExpandedChange?: (id: string, expanded: boolean) => void;
}) {
  const { t } = useTranslation();

  const handleAccordionChange = (
    event: ChangeEvent<unknown>,
    expanded: boolean,
  ) => {
    event.stopPropagation();
    onRowExpandedChange?.(row.id, expanded);
  };

  return (
    <TableCell
      className={clsx('collapsible-labels-cell', classes.cell)}
      colSpan={colSpan}
    >
      <Accordion
        classes={{
          root: 'collapsible-labels-cell__accordion',
          expanded: 'collapsible-labels-cell__accordion_expanded',
        }}
        expanded={isExpanded}
        onChange={(e, expanded) => handleAccordionChange(e, expanded)}
      >
        <AccordionSummary
          classes={{
            root: 'collapsible-labels-cell__accordion-summary',
            content: 'collapsible-labels-cell__accordion-summary-content',
            expanded:
              'collapsible-labels-cell__accordion-summary-content_expanded',
            expandIcon: 'collapsible-labels-cell__accordion-summary-icon',
          }}
          expandIcon={<ExpandMoreIcon fontSize="small" />}
        >
          <Typography className="collapsible-labels-cell__title">
            {t('field-profiler.fields-list.nested-rows.labels', {
              amount: row.labels.length,
            })}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: 'collapsible-labels-cell__accordion-details',
          }}
        >
          {row.labels.map((label, index) => (
            <Label
              key={index}
              text={`${label.name}: ${label.value}`}
              classes={{
                label: 'collapsible-labels-cell__label',
              }}
              {...(editMode
                ? { onDelete: () => row.onDeleteLabelClick(row, index) }
                : null)}
            />
          ))}
          {editMode && (
            <Button
              className="collapsible-labels-cell__add-button"
              size="small"
              startIcon={
                <AddIcon className="collapsible-labels-cell__add-button-icon" />
              }
              onClick={() => row.onAddLabelClick(row)}
            >
              {t('field-profiler.fields-list.cells.add-label')}
            </Button>
          )}
        </AccordionDetails>
      </Accordion>
    </TableCell>
  );
}
