import i18n from 'i18next';

import {
  selectLanguage,
  selectMaxArea,
  selectTotalArea,
} from '../../user/userSelectors';
import { getUserData, setupUserIdentity } from '../../user/userSlice';
import displayAreaLimitNotification from './helpers/functions/displayAreaLimitNotification';
import { CustomError } from '../../../helpers/functions/utils/errorHandling';
import { errorNotify } from '../../notifications/helpers/functions/notify';

let userDataFetcher = null;

export const getUserDataFetcher = () => userDataFetcher || Promise.reject();

export const setupApp = () => async (dispatch, getState) => {
  userDataFetcher = dispatch(getUserData()).unwrap();

  try {
    await userDataFetcher;
  } catch (error) {
    errorNotify({
      error: new CustomError('[setupApp] Unable to fetch user data', {
        cause: error,
      }),
      message: i18n.t('error-fallback.description'),
      dispatch,
    });

    return;
  }

  const state = getState();
  const userMaxArea = selectMaxArea(state);
  const userTotalArea = selectTotalArea(state);

  await i18n.changeLanguage(selectLanguage(state));

  dispatch(setupUserIdentity());

  if (userTotalArea > userMaxArea && userMaxArea > 0) {
    displayAreaLimitNotification();
  }
};
