import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import SelectableListPopup from '../../components/SelectableListPopup';
import useSingleFarmFromList from '../../../../farms/hooks/useSingleFarmFromList';
import { selectEmail } from '../../../../user/userSelectors';
import { useUnshareFarmsMutation } from '../../../../farms/farmsAPI';

const StopFarmSharePopup = ({ farmUuid, farmName, ...popup }) => {
  const { t } = useTranslation();
  const { farm } = useSingleFarmFromList({ farmUuid });
  const userEmail = useSelector(selectEmail);
  const [unshareFarms] = useUnshareFarmsMutation();
  const items = farm.usersEmails
    .filter((email) => email !== userEmail)
    .map((email) => ({
      label: email,
      id: email,
    }));

  const onConfirm = async (usersEmails) => {
    await unshareFarms({
      emails: usersEmails,
      farmUuids: [farmUuid],
    });

    popup.onConfirm();
  };

  return (
    <SelectableListPopup
      {...popup}
      items={items}
      title={t('general.popups.stop-farm-share.title')}
      description={
        <Trans i18nKey="general.popups.stop-farm-share.description">
          Text
          <span className="selectable-list-popup__content-text_bold">text</span>
          text
          <span className="selectable-list-popup__content-text_highlight">
            {{ name: farmName }}
          </span>
          text
        </Trans>
      }
      filter={{
        hide: true,
      }}
      confirm={{
        label: t('general.popups.stop-farm-share.stop-sharing'),
        color: 'secondary',
      }}
      onConfirm={onConfirm}
    />
  );
};

export default StopFarmSharePopup;
