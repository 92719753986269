mutation save3dMap($input: SaveThreeDimensionalMapInput!) {
  saveThreeDimensionalMap(input: $input) {
    uuid
    name
    createdDate
    updatedDate
    dataSourceLayer {
      factor
      satelliteImages {
        satelliteImage {
          uuid
        }
      }
      index
      soilDataset {
        uuid
      }
      soilAttribute
      yieldDataset {
        uuid
      }
      yieldAttribute
      asAppliedDataset {
        uuid
      }
      asAppliedAttribute
      topographyMap {
        uuid
      }
      topographyAttribute
    }
    vectorAnalysisMap {
      uuid
    }
  }
}
