import React from 'react';
import ListItemElement, { ListItemProps } from '@material-ui/core/ListItem';

import './index.scss';

const ListItem = ({ children, ...props }: ListItemProps) => (
  <ListItemElement {...props} className="list-item" button={false}>
    {children}
  </ListItemElement>
);

export default ListItem;
