import i18n from 'i18next';

const OWNERSHIP = {
  own: 'OWN',
  shared: 'SHARED',
};

export const isOwned = (ownership) => ownership === OWNERSHIP.own;

export const isShared = (ownership) => ownership === OWNERSHIP.shared;

export const getOwnedText = (ownership) =>
  isOwned(ownership)
    ? i18n.t('general.own-types.own')
    : i18n.t('general.own-types.shared');
