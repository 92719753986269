import React, { ChangeEvent, MouseEvent } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import clsx from 'clsx';

import CheckboxOutlineIcon from '../Icons/checkbox-outline.svg';
import CheckboxCheckedIcon from '../Icons/checkbox-checked.svg';
import CheckboxIndeterminedIcon from '../Icons/checkbox-indetermined.svg';

import './index.scss';

export default function EnhancedCheckbox({
  value,
  disabled,
  name,
  id,
  classes,
  onChange = () => {},
  onClick = () => {},
}: {
  value: number;
  disabled?: boolean;
  name?: string;
  id?: string;
  classes?: { root?: string };
  onChange?: (e: ChangeEvent<{ checked: boolean }>, v: boolean) => void;
  onClick?: (e: MouseEvent) => void;
}) {
  let checked = false;
  let indeterminate = false;

  if (value === 2) {
    checked = true;
  } else if (value === 1) {
    indeterminate = true;
  }

  return (
    <Checkbox
      disabled={disabled}
      name={name}
      id={id}
      color="primary"
      classes={{
        root: clsx('checkbox', classes?.root),
        indeterminate: 'checkbox_indeterminate',
        disabled: 'checkbox_disabled',
      }}
      icon={<CheckboxOutlineIcon />}
      checkedIcon={<CheckboxCheckedIcon />}
      indeterminateIcon={<CheckboxIndeterminedIcon />}
      checked={checked}
      indeterminate={indeterminate}
      onChange={onChange}
      onClick={onClick}
    />
  );
}
