import i18n from 'i18next';

import { VectorAnalysisMapZoneType } from '../../types/dataVariableAttribute';
import { VAMAP_VARIABLE_ZONE_TYPE_TO_I18N_KEY } from '../constants/dataVariableAttribute';

export const getAttributeOptionValue = (
  attributeOptions: ({ title: string; value: string } | string)[] = [],
  attribute = '',
) => {
  const selectedOption = attributeOptions.find((option) => {
    const optionValue = typeof option === 'string' ? option : option.value;
    return optionValue.toUpperCase() === attribute.toUpperCase();
  });

  if (!selectedOption) {
    return '';
  }

  return typeof selectedOption === 'string'
    ? selectedOption
    : selectedOption.value;
};

export const getVamapVariableZoneTypeAttributes = () => [
  {
    value: 'ZONE_ID',
    title: i18n.t(VAMAP_VARIABLE_ZONE_TYPE_TO_I18N_KEY.ZONE_ID),
  },
  {
    value: 'ZONE_VALUE',
    title: i18n.t(VAMAP_VARIABLE_ZONE_TYPE_TO_I18N_KEY.ZONE_VALUE),
  },
];

export const getVamapVariableZoneTypeAttributeName = (
  type?: VectorAnalysisMapZoneType,
) => (type ? i18n.t(VAMAP_VARIABLE_ZONE_TYPE_TO_I18N_KEY[type]) : '');
