import React, { useCallback, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@material-ui/icons/ReportProblemOutlined';

import Panel from '../../../Panel/Settings';
import Loading from '../../../../../../../components/Loading';
import BackdropLoading from '../../../../../../../components/Loading/BackdropLoading';
import Stepper from '../../../../../../../components/Stepper';
import ZonesCreation from '../../../../../../../components/OpsPanel/ZonesCreation';
import Button from '../../../../../../../components/Button';
import {
  goBackFromSettings,
  updateSettings,
} from '../../../../createMultiLayerAnalysisSlice';
import { updateVectorAnalysisMap } from '../../../../../../createAnalysis/createAnalysisSlice';
import {
  selectGeneratedVectorAnalysisMap,
  selectSaveInProgress,
} from '../../../../../../createAnalysis/createAnalysisSelectors';
import {
  selectDataLayers,
  selectIsValidSetup,
  selectSettings,
} from '../../../../createMultiLayerAnalysisSelectors';
import useGenerateMultiLayerVamap from '../../../../hooks/useGenerateMultiLayerVamap';
import { selectAreaUnit } from '../../../../../../user/userSelectors';
import { selectArea } from '../../../../../../field/fieldSelectors';

import './index.scss';

const Map = React.lazy(() => import('../../../Map'));

const StepperSettings = ({ steps, stepIndex, step }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    name,
    numberOfZones,
    polygonMinArea,
    dataClassificationType,
    useInterpolatedData,
  } = useSelector(selectSettings);
  const dataLayers = useSelector(selectDataLayers);
  const isValidSetup = useSelector(selectIsValidSetup);
  const generatedVectorAnalysisMap = useSelector(
    selectGeneratedVectorAnalysisMap,
  );
  const saveInProgress = useSelector(selectSaveInProgress);
  const areaUnit = useSelector(selectAreaUnit);
  const fieldArea = useSelector(selectArea);
  const generateVamap = useGenerateMultiLayerVamap();
  const runAnalysisDisabled = !isValidSetup || !name || !numberOfZones;

  const onClickBack = () => {
    dispatch(goBackFromSettings(dataLayers.length > 0 ? 0 : false));
  };
  const onClickNext = () => {
    dispatch(updateVectorAnalysisMap({ name }));
  };
  const onRunAnalysis = () => {
    generateVamap();
  };
  const onZonesOpsPropChange = useCallback(
    (prop, value) => {
      dispatch(
        updateSettings({
          prop,
          value,
        }),
      );
    },
    [dispatch],
  );

  return (
    <>
      {!isValidSetup && (
        <div className="create-multi-layer-settings__custom-error">
          <div className="error__message">
            <Icon className="error__icon" fontSize="small" />
            <span className="error__text">
              {t(
                'zones-ops.multi-layer.steps.4.notifications.need-at-least-2-layers',
              )}
            </span>
          </div>
          <Button className="error__action" onClick={onClickBack}>
            {t('general.controls.select')}
          </Button>
        </div>
      )}
      <Stepper
        activeStep={stepIndex}
        steps={steps}
        nextDisabled={!generatedVectorAnalysisMap}
        nextLabel={t('zones-ops.common.finish')}
        onClickBack={onClickBack}
        onClickNext={onClickNext}
      >
        <ZonesCreation
          key="tools-panel"
          name={name}
          areaUnit={areaUnit}
          fieldArea={fieldArea}
          numberOfZones={numberOfZones}
          dataClassificationType={dataClassificationType}
          polygonMinArea={polygonMinArea}
          runAnalysisDisabled={runAnalysisDisabled}
          useInterpolatedData={useInterpolatedData}
          onPropChange={onZonesOpsPropChange}
          onRunAnalysis={onRunAnalysis}
        />
        <Panel key="panel" />
        <Suspense key="map" fallback={<Loading />}>
          <Map step={step} />
        </Suspense>
      </Stepper>
      {saveInProgress && <BackdropLoading />}
    </>
  );
};

export default StepperSettings;
