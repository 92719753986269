import type { Field, TransformedField } from '../../types/field';

export const isInUnsupportedRegion = <
  T extends Field | TransformedField = Field,
>(
  field?: T | null,
): boolean => field?.status === 'TILES_IGNORED';

export const isInvalid = <T extends Field | TransformedField = Field>(
  field?: T,
): boolean => field?.status === 'INVALID';

export const isSameField = <T extends Field | TransformedField = Field>(
  oldField?: T | null,
  newField?: T | null,
): boolean => newField?.uuid === oldField?.uuid;

export const getJDFieldOrgId = (jdField: { orgId: string } | null | false) =>
  jdField && typeof jdField === 'object' ? jdField.orgId : '';

export const getFieldThumbnailGeoMap = (
  geoMaps: { url: string; shortName: string }[] = [],
) => geoMaps.find(({ shortName }) => shortName === 'thumbnail');
