mutation exportToJohnDeereAsSeedingWorkPlan(
  $input: ExportToJohnDeereAsSeedingWorkPlanInput!
) {
  exportToJohnDeereAsSeedingWorkPlan(input: $input) {
    details {
      id
      name
    }
    status
  }
}
