import React, { ReactElement } from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import NotFoundIcon from './NotFound.svg';

import './index.scss';

const NotFound = ({
  title,
  description,
  link,
  small = false,
}: {
  title?: string;
  description: string;
  link?: ReactElement;
  small?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx('not-found', {
        'not-found-small': small,
      })}
    >
      <Typography variant="h2" className="not-found__title">
        {title ?? t('general.errors.not-found')}
      </Typography>
      <p className="not-found__description">{description}</p>
      <p className="not-found__link">{link}</p>
      <NotFoundIcon className="not-found__icon" />
    </div>
  );
};

export default NotFound;
