import React from 'react';

import VamapTypeLabel from '../../../../../../components/VamapTypeLabel';
import type { TransformedVectorAnalysisMap } from '../../../../../../helpers/types/vectorAnalysisMap';

import './index.scss';

export default function ZonesMapItem({
  zonesMap,
}: {
  zonesMap: TransformedVectorAnalysisMap;
}) {
  return (
    <div className="zones-map-item">
      <span className="zones-map-item__name">{zonesMap.name}</span>
      <VamapTypeLabel entity={zonesMap} />
    </div>
  );
}
