import { LIST_ID, TagType, emptyAPI } from '../emptyApi/emptyAPI';
import getVarietiesJohnDeereQuery from './graphql/queries/getVarietiesJohnDeere.gql';
import getFertilizersJohnDeereQuery from './graphql/queries/getFertilizersJohnDeere.gql';
import getChemicalsJohnDeereQuery from './graphql/queries/getChemicalsJohnDeere.gql';
import getDryBlendsJohnDeereQuery from './graphql/queries/getDryBlendsJohnDeere.gql';
import getTankMixesJohnDeereQuery from './graphql/queries/getTankMixesJohnDeere.gql';
import {
  GetChemicalsResponse,
  GetDryBlendsResponse,
  GetFertilizersResponse,
  GetProductsArg,
  GetTankMixesResponse,
  GetVarietiesResponse,
  ProductsPageResult,
} from './types/api';
import {
  JohnDeereChemical,
  JohnDeereDryBlend,
  JohnDeereFertilizer,
  JohnDeereTankMix,
  JohnDeereVariety,
} from './types/products';
import { AppDispatch } from '../../app/store/helpers/types';
import { errorNotify } from '../notifications/helpers/functions/notify';
import { CustomError } from '../../helpers/functions/utils/errorHandling';

const PRODUCTS_PAGE_SIZE = 100;

export const jdProductsAPI = emptyAPI.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getJohnDeereVarieties: builder.query<JohnDeereVariety[], GetProductsArg>({
      queryFn: async (
        { orgId, page = 1, pageSize = PRODUCTS_PAGE_SIZE },
        { dispatch },
        _extraOptions,
        baseQuery,
      ) => {
        let result: ProductsPageResult<JohnDeereVariety>;

        const fetchVarietiesPage = async (
          varietiesPage: number,
        ): Promise<ProductsPageResult<JohnDeereVariety>> => {
          const { data, error } = (await baseQuery({
            document: getVarietiesJohnDeereQuery,
            variables: {
              varietyFilter: {
                orgId,
                pageSize,
                page: varietiesPage,
              },
            },
          })) as GetVarietiesResponse;

          if (data?.getVarietiesJohnDeere) {
            const { totalCount, varieties } = data.getVarietiesJohnDeere;

            result = {
              data:
                result && 'data' in result
                  ? [...result.data, ...varieties]
                  : varieties,
            };

            if (totalCount > result.data.length) {
              await fetchVarietiesPage(varietiesPage + 1);
            }
          } else {
            errorNotify({
              error: new CustomError(
                '[JD Varieties] Unable to fetch John Deere organization varieties',
                {
                  cause: error,
                },
              ),
              dispatch,
            });

            result = {
              error,
            };
          }

          return result;
        };

        return fetchVarietiesPage(page);
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: TagType.johnDeereVariety,
                id,
              })),
              { type: TagType.johnDeereVariety, id: LIST_ID },
            ]
          : [{ type: TagType.johnDeereVariety, id: LIST_ID }],
    }),
    getJohnDeereFertilizers: builder.query<
      JohnDeereFertilizer[],
      GetProductsArg
    >({
      queryFn: async (
        { orgId, page = 1, pageSize = PRODUCTS_PAGE_SIZE },
        { dispatch },
        _extraOptions,
        baseQuery,
      ) => {
        let result: ProductsPageResult<JohnDeereFertilizer>;

        const fetchFertilizersPage = async (
          fertilizersPage: number,
        ): Promise<ProductsPageResult<JohnDeereFertilizer>> => {
          const { data, error } = (await baseQuery({
            document: getFertilizersJohnDeereQuery,
            variables: {
              fertilizerFilter: {
                orgId,
                pageSize,
                page: fertilizersPage,
              },
            },
          })) as GetFertilizersResponse;

          if (data?.getFertilizersJohnDeere) {
            const { totalCount, fertilizers } = data.getFertilizersJohnDeere;

            result = {
              data:
                result && 'data' in result
                  ? [...result.data, ...fertilizers]
                  : fertilizers,
            };

            if (totalCount > result.data.length) {
              await fetchFertilizersPage(fertilizersPage + 1);
            }
          } else {
            errorNotify({
              error: new CustomError(
                '[JD Fertilizers] Unable to fetch John Deere organization fertilizers',
                {
                  cause: error,
                },
              ),
              dispatch,
            });

            result = {
              error,
            };
          }

          return result;
        };

        return fetchFertilizersPage(page);
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: TagType.johnDeereFertilizer,
                id,
              })),
              { type: TagType.johnDeereFertilizer, id: LIST_ID },
            ]
          : [{ type: TagType.johnDeereFertilizer, id: LIST_ID }],
    }),
    getJohnDeereChemicals: builder.query<JohnDeereChemical[], GetProductsArg>({
      queryFn: async (
        { orgId, page = 1, pageSize = PRODUCTS_PAGE_SIZE },
        { dispatch },
        _extraOptions,
        baseQuery,
      ) => {
        let result: ProductsPageResult<JohnDeereChemical>;

        const fetchChemicalsPage = async (
          chemicalsPage: number,
        ): Promise<ProductsPageResult<JohnDeereChemical>> => {
          const { data, error } = (await baseQuery({
            document: getChemicalsJohnDeereQuery,
            variables: {
              chemicalFilter: {
                orgId,
                pageSize,
                page: chemicalsPage,
              },
            },
          })) as GetChemicalsResponse;

          if (data?.getChemicalsJohnDeere) {
            const { totalCount, chemicals } = data.getChemicalsJohnDeere;

            result = {
              data:
                result && 'data' in result
                  ? [...result.data, ...chemicals]
                  : chemicals,
            };

            if (totalCount > result.data.length) {
              await fetchChemicalsPage(chemicalsPage + 1);
            }
          } else {
            errorNotify({
              error: new CustomError(
                '[JD Chemicals] Unable to fetch John Deere organization chemicals',
                {
                  cause: error,
                },
              ),
              dispatch,
            });

            result = {
              error,
            };
          }

          return result;
        };

        return fetchChemicalsPage(page);
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: TagType.johnDeereChemical,
                id,
              })),
              { type: TagType.johnDeereChemical, id: LIST_ID },
            ]
          : [{ type: TagType.johnDeereChemical, id: LIST_ID }],
    }),
    getJohnDeereTankMixes: builder.query<JohnDeereTankMix[], GetProductsArg>({
      queryFn: async (
        { orgId, page = 1, pageSize = PRODUCTS_PAGE_SIZE },
        { dispatch },
        _extraOptions,
        baseQuery,
      ) => {
        let result: ProductsPageResult<JohnDeereTankMix>;

        const fetchTankMixesPage = async (
          tankMixesPage: number,
        ): Promise<ProductsPageResult<JohnDeereTankMix>> => {
          const { data, error } = (await baseQuery({
            document: getTankMixesJohnDeereQuery,
            variables: {
              tankMixFilter: {
                orgId,
                pageSize,
                page: tankMixesPage,
              },
            },
          })) as GetTankMixesResponse;

          if (data?.getTankMixesJohnDeere) {
            const { totalCount, tankMixes } = data.getTankMixesJohnDeere;

            result = {
              data:
                result && 'data' in result
                  ? [...result.data, ...tankMixes]
                  : tankMixes,
            };

            if (totalCount > result.data.length) {
              await fetchTankMixesPage(tankMixesPage + 1);
            }
          } else {
            errorNotify({
              error: new CustomError(
                '[JD Tank Mixes] Unable to fetch John Deere organization tank mixes',
                {
                  cause: error,
                },
              ),
              dispatch,
            });

            result = {
              error,
            };
          }

          return result;
        };

        return fetchTankMixesPage(page);
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: TagType.johnDeereTankMix,
                id,
              })),
              { type: TagType.johnDeereTankMix, id: LIST_ID },
            ]
          : [{ type: TagType.johnDeereTankMix, id: LIST_ID }],
    }),
    getJohnDeereDryBlends: builder.query<JohnDeereDryBlend[], GetProductsArg>({
      queryFn: async (
        { orgId, page = 1, pageSize = PRODUCTS_PAGE_SIZE },
        { dispatch },
        _extraOptions,
        baseQuery,
      ) => {
        let result: ProductsPageResult<JohnDeereDryBlend>;

        const fetchDryBlendsPage = async (
          dryBlendsPage: number,
        ): Promise<ProductsPageResult<JohnDeereDryBlend>> => {
          const { data, error } = (await baseQuery({
            document: getDryBlendsJohnDeereQuery,
            variables: {
              dryBlendFilter: {
                orgId,
                pageSize,
                page: dryBlendsPage,
              },
            },
          })) as GetDryBlendsResponse;

          if (data?.getDryBlendsJohnDeere) {
            const { totalCount, dryBlends } = data.getDryBlendsJohnDeere;

            result = {
              data:
                result && 'data' in result
                  ? [...result.data, ...dryBlends]
                  : dryBlends,
            };

            if (totalCount > result.data.length) {
              await fetchDryBlendsPage(dryBlendsPage + 1);
            }
          } else {
            errorNotify({
              error: new CustomError(
                '[JD Dry Blends] Unable to fetch John Deere organization dry blends',
                {
                  cause: error,
                },
              ),
              dispatch,
            });

            result = {
              error,
            };
          }

          return result;
        };

        return fetchDryBlendsPage(page);
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: TagType.johnDeereDryBlend,
                id,
              })),
              { type: TagType.johnDeereDryBlend, id: LIST_ID },
            ]
          : [{ type: TagType.johnDeereDryBlend, id: LIST_ID }],
    }),
  }),
});

export const {
  useGetJohnDeereVarietiesQuery,
  useGetJohnDeereFertilizersQuery,
  useGetJohnDeereChemicalsQuery,
  useGetJohnDeereTankMixesQuery,
  useGetJohnDeereDryBlendsQuery,
} = jdProductsAPI;

export const resetJohnDeereProductsCache = () => (dispatch: AppDispatch) => {
  dispatch(jdProductsAPI.util.resetApiState());
};
