import { RootState } from '../../../app/store/helpers/types';
import { Product } from './types/product';

export const selectUpdates = ({ zonesMap }: RootState) => zonesMap.updates;

export const selectVamapType = ({ zonesMap }: RootState) => zonesMap.vamapType;

export const selectZonesMapChanged = ({ zonesMap }: RootState) =>
  zonesMap.zonesMapChanged;

export const selectSelectedZone = ({ zonesMap }: RootState) =>
  zonesMap.selectedZone;

export const selectGeometriesChanged = ({ zonesMap }: RootState) =>
  zonesMap.geometriesChanged;

export const selectUpdateInProgress = ({ zonesMap }: RootState) =>
  zonesMap.updateInProgress;

export const selectInstrument = ({ zonesMap }: RootState) =>
  zonesMap.instrument;

export const selectBackTo = ({ zonesMap }: RootState) => zonesMap.backTo;

export const selectLoadStatus = ({ zonesMap }: RootState) =>
  zonesMap.loadStatus;

export const selectProducts = ({ zonesMap }: RootState) =>
  zonesMap.distributionData.products;

export const selectProductByIndex = (
  { zonesMap }: RootState,
  index: number,
): Product | undefined => zonesMap.distributionData.products[index];

export const selectZones = ({ zonesMap }: RootState) =>
  zonesMap.distributionData.zones;

export const selectRatesPerProduct = ({ zonesMap }: RootState) =>
  zonesMap.distributionData.ratesPerProduct;
