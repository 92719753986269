import { getType, featureCollection, combine, flatten } from '@turf/turf';
import type { FeatureCollection, MultiPolygon, Polygon } from 'geojson';

type PolygonFeatures = FeatureCollection<MultiPolygon | Polygon>['features'];

export const isFeatureTypesCorrect = (
  features: PolygonFeatures = [],
): boolean => {
  const hasCorrectFeatureTypes = features.every((feature) => {
    const featureType = getType(feature);

    return featureType === 'Polygon' || featureType === 'MultiPolygon';
  });

  return features.length !== 0 && hasCorrectFeatureTypes;
};

/**
 * Combines multiple GeoJSON Polygon or MultiPolygon features into a single MultiPolygon
 * and returns its geometry as a JSON.
 *
 * @param {PolygonFeatures} features Array of GeoJSON features (Polygon or MultiPolygon)
 * @returns {string} JSON of the combined MultiPolygon geometry
 */
export const combineFeaturesToMultiPolygonJson = (
  features: PolygonFeatures,
): string => {
  // The result is a FeatureCollection with a single MultiPolygon feature
  const combined = combine(featureCollection(features));
  // Extract this MultiPolygon's geometry and return it as a string
  const multiPolygonGeometry = combined.features[0].geometry;

  return JSON.stringify(multiPolygonGeometry);
};

/**
 * Transforms an array of Polygon and MultiPolygon features into a FeatureCollection
 * where each polygon is represented as a separate Polygon feature.
 *
 * @param {PolygonFeatures} features Array of GeoJSON features (Polygon or MultiPolygon)
 * @returns {FeatureCollection<Polygon>} FeatureCollection of individual Polygon features
 */
export const explodeToPolygons = (
  features: PolygonFeatures,
): FeatureCollection<Polygon> => flatten(featureCollection(features));
