import { useEffect } from 'react';

import {
  useAppDispatch,
  useAppSelector,
} from '../../../../app/store/helpers/functions';
import { setPreviewExpanded } from '../zonesOpsSlice';
import { selectPreviewExpanded } from '../zonesOpsSelectors';
import { triggerMapResize } from '../../../../helpers/mapbox/map';

const MIN_HEIGHT_FOR_EXPANDED_PREVIEW = 720;

export default function useDataLayersPreviewExpanded() {
  const dispatch = useAppDispatch();
  const previewExpanded = useAppSelector(selectPreviewExpanded);

  useEffect(() => {
    if (previewExpanded != null) {
      return;
    }

    dispatch(
      setPreviewExpanded(window.innerHeight >= MIN_HEIGHT_FOR_EXPANDED_PREVIEW),
    );
  }, [previewExpanded, dispatch]);

  useEffect(() => {
    if (previewExpanded != null) {
      triggerMapResize();
    }
  }, [previewExpanded]);

  const handlePreviewExpandedChange = (expanded: boolean) => {
    dispatch(setPreviewExpanded(expanded));
  };

  return {
    previewExpanded,
    handlePreviewExpandedChange,
  };
}
