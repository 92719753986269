export const selectAssetType = ({ createSingleLayerAnalysis }) =>
  createSingleLayerAnalysis.assetType;

export const selectSettings = ({ createSingleLayerAnalysis }) =>
  createSingleLayerAnalysis.settings;

export const selectDataLayer = ({ createSingleLayerAnalysis }) =>
  createSingleLayerAnalysis.dataLayer;

export const selectChanged = ({ createSingleLayerAnalysis }) =>
  createSingleLayerAnalysis.changed;

export const selectCloned = ({ createSingleLayerAnalysis }) =>
  createSingleLayerAnalysis.cloned;
