import React, { Fragment } from 'react';

import IntersectionSourcesTable from '../../Controls/IntersectionSoucesTable';
import Overview from '../../Controls/Overview';
import LabelsList from '../../Controls/LabelsList';
import Header from '../../Controls/Header';
import RatesTable from '../../Controls/RatesTable';

const ZonesIntersectionMapLegendGroup = ({
  sourcesTable,
  ratesTable,
  overview,
  labels,
  header,
}) => (
  <Fragment>
    <Header {...header} />
    <IntersectionSourcesTable {...sourcesTable} />
    {ratesTable && <RatesTable {...ratesTable} />}
    <Overview {...overview} />
    <LabelsList {...labels} />
  </Fragment>
);

export default ZonesIntersectionMapLegendGroup;
