import React from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CircularProgress from '@material-ui/core/CircularProgress';

import { OperationStatus } from '../../helpers/constants/operation';

import './index.scss';

export default function StatusIcon({ status }: { status: OperationStatus }) {
  let statusIcon = null;

  if (status === OperationStatus.done) {
    statusIcon = <CheckCircleOutlineIcon color="primary" />;
  } else if (status === OperationStatus.error) {
    statusIcon = <ErrorOutlineIcon color="error" />;
  } else if (status === OperationStatus.inProgress) {
    statusIcon = <CircularProgress size={20} />;
  } else if (status === OperationStatus.ignored) {
    statusIcon = <ErrorOutlineIcon className="status-icon_warning" />;
  } else {
    statusIcon = <span>{status}</span>;
  }

  return statusIcon;
}
