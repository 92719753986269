import React from 'react';
import clsx from 'clsx';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

import getCellClasses from '../../../helpers/functions/getCellClasses';

import './index.scss';

const MultilineCell = ({ row = {}, headCell = {} }) => {
  const onLineClick = headCell.onLineClick || (() => {});

  return (
    <TableCell
      align={headCell.horizontalAlign || 'left'}
      className={getCellClasses('multiline-cell', headCell, row)}
    >
      {row[headCell.id].map((line, lineIndex) => (
        <Typography
          key={`${headCell.id}_${lineIndex}`}
          className={clsx({
            'multiline-cell__line_nowrap': !!headCell.noWrap,
          })}
          variant="body2"
          onClick={() => onLineClick(row, lineIndex, line)}
        >
          {line}
        </Typography>
      ))}
    </TableCell>
  );
};

export default MultilineCell;
