import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../app/store/helpers/types';
import LoadStatus from '../../../helpers/constants/utils/loadStatus';

export const selectType = ({ createBatchEquationBasedAnalysis }: RootState) =>
  createBatchEquationBasedAnalysis.type;

export const selectGridSize = ({
  createBatchEquationBasedAnalysis,
}: RootState) => createBatchEquationBasedAnalysis.gridSize;

export const selectEquationMapsData = ({
  createBatchEquationBasedAnalysis,
}: RootState) => createBatchEquationBasedAnalysis.equationMapsData;

export const selectEquationMapsDataByStatuses = createSelector(
  selectEquationMapsData,
  (_: RootState, statuses?: LoadStatus[]) => statuses,
  (equationMapsData, statuses) => {
    const allowedStatuses = new Set(
      statuses ?? [LoadStatus.loading, LoadStatus.success, LoadStatus.error],
    );

    return Object.values(equationMapsData).filter(
      (equationMapData) =>
        equationMapData.uuid &&
        allowedStatuses.has(equationMapData.generationStatus),
    );
  },
);

export const selectEquationMapData = createSelector(
  selectEquationMapsData,
  (_: RootState, fieldUuid: string) => fieldUuid,
  (equationMapsData, fieldUuid) => equationMapsData[fieldUuid],
);

export const selectListFilterSearchTerm = ({
  createBatchEquationBasedAnalysis,
}: RootState) => createBatchEquationBasedAnalysis.listFilter.searchTerm;

export const selectListFilterFarmUuid = ({
  createBatchEquationBasedAnalysis,
}: RootState) => createBatchEquationBasedAnalysis.listFilter.farmUuid;

export const selectListFilterSortOrder = ({
  createBatchEquationBasedAnalysis,
}: RootState) => createBatchEquationBasedAnalysis.listFilter.sortOrder;

export const selectEditMode = ({
  createBatchEquationBasedAnalysis,
}: RootState) => createBatchEquationBasedAnalysis.editMode;

export const selectFullScreenEditor = ({
  createBatchEquationBasedAnalysis,
}: RootState) => createBatchEquationBasedAnalysis.fullScreenEditor;

export const selectTitleTemplate = ({
  createBatchEquationBasedAnalysis,
}: RootState) => createBatchEquationBasedAnalysis.titleTemplate;
