import i18n from 'i18next';

import { NAME } from '../../../jdIntegration/helpers/constants';
import getIntegrationUrl from './getIntegrationUrl';

export const getHeaderProps = (pathname: string) => {
  let result: {
    text: string;
    withBackAction?: boolean;
  } = {
    text: i18n.t('general.navigation.integrations'),
  };

  if (pathname.startsWith(getIntegrationUrl('jd'))) {
    result = {
      text: getIntegrationTitle(NAME),
      withBackAction: true,
    };
  }

  return result;
};

export const getIntegrationTitle = (name: string) =>
  `${name} ${i18n.t('integrations.marketplace.integration')}`;
