import i18n from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { closeSnackbar } from 'notistack';

import {
  selectSelectedField,
  selectWorkflow,
} from '../ui/zonesOps/zonesOpsSelectors';
import {
  warningNotify,
  errorNotify,
  successNotify,
} from '../notifications/helpers/functions/notify';
import {
  closeProcessingNotification,
  processingNotify,
} from '../notifications/notificationsSlice';
import { openPopup } from '../ui/popups/popupsSlice';
import { selectAreaUnit } from '../user/userSelectors';
import {
  selectGeneratedEquationMap,
  selectGeneratedVectorAnalysisMap,
  selectSaveInProgress,
} from './createAnalysisSelectors';
import {
  fetchGeneratedEquationMap,
  fetchGeneratedVectorAnalysisMap,
  generateSatelliteVectorAnalysisMap as generateSatelliteVectorAnalysisMapAPI,
  generateSoilVectorAnalysisMap as generateSoilVectorAnalysisMapAPI,
  generateYieldVectorAnalysisMap as generateYieldVectorAnalysisMapAPI,
  generateAsAppliedVectorAnalysisMap as generateAsAppliedVectorAnalysisMapAPI,
  generateTopographyVectorAnalysisMap as generateTopographyVectorAnalysisMapAPI,
  generateEquationMap as generateEquationMapAPI,
  executeZonesOperation as executeZonesOperationAPI,
  generateMultiLayerVectorAnalysisMap as generateMultiLayerVectorAnalysisMapAPI,
  saveNewVectorAnalysisMap as saveNewVectorAnalysisMapAPI,
} from './createAnalysisAPI';
import { isVamapInvalid } from '../../helpers/functions/entities/vectorAnalysisMap';
import { isInvalid as isEqmapInvalid } from '../../helpers/functions/entities/equationMap';
import { goToResults as goToEquationResults } from '../ui/createEquationBasedAnalysis/createEquationBasedAnalysisSlice';
import { goToSettings as goToFieldPotentialSettings } from '../ui/createFieldPotentialZones/createFieldPotentialZonesSlice';
import { goToSettings as goToStabilitySettings } from '../ui/createStabilityZones/createStabilityZonesSlice';
import { goToSettings as goToSingleLayerSettings } from '../ui/createSingleLayerAnalysis/createSingleLayerAnalysisSlice';
import { goToSettings as goToMultiLayerSettings } from '../ui/createMultiLayerAnalysis/createMultiLayerAnalysisSlice';
import { goToSettings as goToCrossLayerSettings } from '../ui/crossLayer/crossLayerSlice';
import { renameAsset, saveEquationMap } from '../field/fieldAPI';
import type { TransformedVectorAnalysisMap } from '../../helpers/types/vectorAnalysisMap';
import { TransformedEquationMap } from '../../helpers/types/equationMap';
import { createAppAsyncThunk } from '../../app/store/helpers/functions';
import { ParsedEvent } from '../subscription/types/event';
import { AppDispatch, AppThunk } from '../../app/store/helpers/types';
import getVectorAnalysisMapErrorMessage from '../notifications/helpers/functions/getVectorAnalysisMapErrorMessage';
import { GenerateVectorAnalysisMapSettings } from './types/settings';
import {
  getDataLayersInput,
  verifyGenerateVamapResponse,
} from './helpers/functions/api';
import {
  VectorAnalysisMapType,
  ZonesOperationType,
} from '../../helpers/constants/entities/vectorAnalysisMap';
import { ProductUnit } from '../../helpers/constants/units/productUnit';
import { AssetType } from '../../helpers/constants/entities/asset';
import { GenerationResult, ZonesOperationMap } from './types/api';
import { DataLayer } from '../ui/createMultiLayerAnalysis/types/ui';
import { CustomError } from '../../helpers/functions/utils/errorHandling';
import { InputDataVariable } from '../ui/dataVariable/types/inputDataVariable';
import ZonesOpsWorkflow from '../ui/zonesOps/helpers/constants/zonesOpsWorkflow';
import { isTimeoutError } from '../field/helpers/functions/api';
import { ZonesMapGeoJson } from '../../helpers/types/vectorAnalysisMap/geojson';

export interface CreateAnalysisState {
  generatedVectorAnalysisMap: TransformedVectorAnalysisMap | null;
  generatedEquationMap: TransformedEquationMap | null;
  saveInProgress: boolean;
}

const initialState: CreateAnalysisState = {
  generatedVectorAnalysisMap: null,
  generatedEquationMap: null,
  saveInProgress: false,
};

export const updateVectorAnalysisMap = createAppAsyncThunk(
  'createAnalysis/updateVectorAnalysisMap',
  async (
    {
      name,
    }: {
      name: string;
    },
    { getState, dispatch },
  ) => {
    const state = getState();
    const generatedVamap = selectGeneratedVectorAnalysisMap(state);
    const { fieldUuid } = selectSelectedField(state);
    let result: {
      name: string;
      uuid: string;
    };

    if (!generatedVamap) {
      throw new CustomError('[Create Analysis] Generated vamap is empty.');
    }

    try {
      if (generatedVamap.name !== name) {
        result = await renameAsset(generatedVamap, name, fieldUuid);
      } else {
        result = {
          name: generatedVamap.name,
          uuid: generatedVamap.uuid,
        };
      }

      dispatch(
        openPopup({
          type: 'save-zones-map',
          name,
          vamapUuid: generatedVamap.uuid,
        }),
      );
    } catch (error) {
      errorNotify({
        error: new CustomError('[Create Analysis] Unable to update vamap.', {
          cause: error,
        }),
        dispatch,
      });

      throw error;
    }

    return result;
  },
);

export const updateEquationMap = createAppAsyncThunk(
  'createAnalysis/updateEquationMap',
  async (
    {
      name,
      type,
    }: {
      name: string;
      type: VectorAnalysisMapType;
    },
    { getState, dispatch },
  ) => {
    const state = getState();
    const generatedEquationMap = selectGeneratedEquationMap(state);
    const { fieldUuid } = selectSelectedField(state);
    let result:
      | {
          name: string;
          uuid: string;
          type: VectorAnalysisMapType;
        }
      | undefined;

    if (!generatedEquationMap) {
      throw new CustomError(
        '[Create Analysis] Generated equation map is empty.',
      );
    }

    try {
      if (
        generatedEquationMap?.name === name &&
        generatedEquationMap?.type === type
      ) {
        result = {
          name: generatedEquationMap.name,
          uuid: generatedEquationMap.uuid,
          type: generatedEquationMap.type,
        };
      } else {
        result = await saveEquationMap({
          fieldUuid,
          uuid: generatedEquationMap.uuid,
          name,
          type,
        });
      }

      dispatch(
        openPopup({
          type: 'save-equation-map',
        }),
      );
    } catch (error) {
      errorNotify({
        error: new CustomError(
          '[Create Analysis] Unable to update equation map.',
          {
            cause: error,
          },
        ),
        dispatch,
      });

      throw error;
    }

    return result;
  },
);

const catchGenerationError =
  (error: unknown, type: string) => (dispatch: AppDispatch) => {
    const typedError = error as { message: string };
    let statusMessage = '';

    if (typedError.message) {
      statusMessage = typedError.message;
    }

    dispatch(closeProcessingNotification());
    errorNotify({
      error: new CustomError(`[Create Analysis] Unable to generate ${type}.`, {
        cause: error,
      }),
      message: getVectorAnalysisMapErrorMessage(statusMessage),
      dispatch,
    });
  };

export const generateSatelliteVectorAnalysisMap = createAppAsyncThunk(
  'createAnalysis/generateSatelliteVectorAnalysisMap',
  async (
    {
      index,
      satelliteImageUuids,
      ...settings
    }: {
      index: number;
      satelliteImageUuids: string[];
    } & GenerateVectorAnalysisMapSettings,
    { dispatch, getState },
  ) => {
    const state = getState();
    const { fieldUuid } = selectSelectedField(state);
    const generatedVamap = selectGeneratedVectorAnalysisMap(state);

    try {
      dispatch(
        processingNotify({
          message: i18n.t('zones-ops.common.notifications.zones-being-created'),
        }),
      );

      const result = await generateSatelliteVectorAnalysisMapAPI({
        uuid: generatedVamap?.uuid,
        fieldUuid,
        index,
        satelliteImageUuids,
        ...settings,
      });

      verifyGenerateVamapResponse(result);

      return result;
    } catch (error) {
      dispatch(catchGenerationError(error, 'satellite vector analysis map'));
      throw error;
    }
  },
);

export const generateSoilVectorAnalysisMap = createAppAsyncThunk(
  'createAnalysis/generateSoilVectorAnalysisMap',
  async (
    {
      soilAttribute,
      soilDatasetUuid,
      ...settings
    }: {
      soilAttribute: string;
      soilDatasetUuid: string;
    } & GenerateVectorAnalysisMapSettings,
    { dispatch, getState },
  ) => {
    const state = getState();
    const { fieldUuid } = selectSelectedField(state);
    const generatedVamap = selectGeneratedVectorAnalysisMap(state);

    try {
      dispatch(
        processingNotify({
          message: i18n.t('zones-ops.common.notifications.zones-being-created'),
        }),
      );

      const result = await generateSoilVectorAnalysisMapAPI({
        uuid: generatedVamap?.uuid,
        fieldUuid,
        soilAttribute,
        soilDatasetUuid,
        ...settings,
      });

      verifyGenerateVamapResponse(result);

      return result;
    } catch (error) {
      dispatch(catchGenerationError(error, 'soil vector analysis map'));
      throw error;
    }
  },
);

export const generateYieldVectorAnalysisMap = createAppAsyncThunk(
  'createAnalysis/generateYieldVectorAnalysisMap',
  async (
    {
      yieldAttribute,
      yieldDatasetUuid,
      ...settings
    }: {
      yieldAttribute: string;
      yieldDatasetUuid: string;
    } & GenerateVectorAnalysisMapSettings,
    { dispatch, getState },
  ) => {
    const state = getState();
    const { fieldUuid } = selectSelectedField(state);
    const generatedVamap = selectGeneratedVectorAnalysisMap(state);

    try {
      dispatch(
        processingNotify({
          message: i18n.t('zones-ops.common.notifications.zones-being-created'),
        }),
      );

      const result = await generateYieldVectorAnalysisMapAPI({
        uuid: generatedVamap?.uuid,
        fieldUuid,
        yieldAttribute,
        yieldDatasetUuid,
        ...settings,
      });

      verifyGenerateVamapResponse(result);

      return result;
    } catch (error) {
      dispatch(catchGenerationError(error, 'yield vector analysis map'));
      throw error;
    }
  },
);

export const generateAsAppliedVectorAnalysisMap = createAppAsyncThunk(
  'createAnalysis/generateAsAppliedVectorAnalysisMap',
  async (
    {
      asAppliedAttribute,
      asAppliedDatasetUuid,
      ...settings
    }: {
      asAppliedAttribute: string;
      asAppliedDatasetUuid: string;
    } & GenerateVectorAnalysisMapSettings,
    { dispatch, getState },
  ) => {
    const state = getState();
    const { fieldUuid } = selectSelectedField(state);
    const generatedVamap = selectGeneratedVectorAnalysisMap(state);

    try {
      dispatch(
        processingNotify({
          message: i18n.t('zones-ops.common.notifications.zones-being-created'),
        }),
      );

      const result = await generateAsAppliedVectorAnalysisMapAPI({
        uuid: generatedVamap?.uuid,
        fieldUuid,
        asAppliedAttribute,
        asAppliedDatasetUuid,
        ...settings,
      });

      verifyGenerateVamapResponse(result);

      return result;
    } catch (error) {
      dispatch(catchGenerationError(error, 'as applied vector analysis map'));
      throw error;
    }
  },
);

export const generateTopographyVectorAnalysisMap = createAppAsyncThunk(
  'createAnalysis/generateTopographyVectorAnalysisMap',
  async (
    {
      topographyAttribute,
      topographyMapUuid,
      ...settings
    }: {
      topographyAttribute: string;
      topographyMapUuid: string;
    } & GenerateVectorAnalysisMapSettings,
    { dispatch, getState },
  ) => {
    const state = getState();
    const { fieldUuid } = selectSelectedField(state);
    const generatedVamap = selectGeneratedVectorAnalysisMap(state);

    try {
      dispatch(
        processingNotify({
          message: i18n.t('zones-ops.common.notifications.zones-being-created'),
        }),
      );

      const result = await generateTopographyVectorAnalysisMapAPI({
        uuid: generatedVamap?.uuid,
        fieldUuid,
        topographyAttribute,
        topographyMapUuid,
        ...settings,
      });

      verifyGenerateVamapResponse(result);

      return result;
    } catch (error) {
      dispatch(catchGenerationError(error, 'topography vector analysis map'));
      throw error;
    }
  },
);

export const generateMultiLayerVectorAnalysisMap = createAppAsyncThunk(
  'createAnalysis/generateMultiLayerVectorAnalysisMap',
  async (
    {
      dataLayers,
      ...settings
    }: {
      dataLayers: DataLayer[];
    } & GenerateVectorAnalysisMapSettings,
    { dispatch, getState },
  ) => {
    const state = getState();
    const { fieldUuid } = selectSelectedField(state);
    const generatedVamap = selectGeneratedVectorAnalysisMap(state);

    try {
      dispatch(
        processingNotify({
          message: i18n.t('zones-ops.common.notifications.zones-being-created'),
        }),
      );

      const dataLayersInput = getDataLayersInput(dataLayers);
      const result = await generateMultiLayerVectorAnalysisMapAPI({
        uuid: generatedVamap?.uuid,
        fieldUuid,
        dataLayers: dataLayersInput,
        ...settings,
      });

      verifyGenerateVamapResponse(result);

      return result;
    } catch (error) {
      dispatch(catchGenerationError(error, 'multi layer vector analysis map'));
      throw error;
    }
  },
);

export const generateCrossLayerVectorAnalysisMap = createAppAsyncThunk(
  'createAnalysis/generateCrossLayerVectorAnalysisMap',
  async (
    {
      name,
      polygonMinArea,
      zonesOperation,
      zonesOperationMaps,
    }: {
      name: string;
      polygonMinArea: number;
      zonesOperation: ZonesOperationType;
      zonesOperationMaps: ZonesOperationMap[];
    },
    { dispatch, getState },
  ) => {
    const state = getState();
    const { fieldUuid } = selectSelectedField(state);
    const generatedVamap = selectGeneratedVectorAnalysisMap(state);

    try {
      dispatch(
        processingNotify({
          message: i18n.t('zones-ops.common.notifications.zones-being-created'),
        }),
      );

      const result = await executeZonesOperationAPI({
        uuid: generatedVamap?.uuid,
        fieldUuid,
        name,
        polygonMinArea,
        zonesOperation,
        zonesOperationMaps,
      });

      if (result.status === 'EMPTY') {
        dispatch(closeProcessingNotification());
        warningNotify({
          message: i18n.t(
            'zones-ops.cross-layer.notifications.no-intersection',
          ),
        });

        return result;
      }
      if (result.statusMessage) {
        throw new CustomError(result.statusMessage);
      }

      return result;
    } catch (error) {
      dispatch(catchGenerationError(error, 'cross layer vector analysis map'));
      throw error;
    }
  },
);

export const generateEquationMap = createAppAsyncThunk(
  'createAnalysis/generateEquationMap',
  async (
    {
      name,
      gridXSize,
      gridYSize,
      type,
      equation,
      equationUuid,
      resultVariable,
      dataVariables,
      productUnit,
      useNumpy,
    }: {
      name: string;
      gridXSize: number;
      gridYSize: number;
      type: VectorAnalysisMapType;
      equation?: string;
      equationUuid: string | null;
      resultVariable: string;
      dataVariables: InputDataVariable[];
      productUnit: ProductUnit | null;
      useNumpy: boolean;
    },
    { dispatch, getState },
  ) => {
    const state = getState();
    const { fieldUuid } = selectSelectedField(state);
    const generatedEquationMap = selectGeneratedEquationMap(state);

    try {
      dispatch(
        processingNotify({
          message: i18n.t(
            'zones-ops.common.notifications.equation-map-being-created',
          ),
        }),
      );

      return await generateEquationMapAPI({
        uuid: generatedEquationMap?.uuid,
        fieldUuid,
        name,
        gridXSize,
        gridYSize,
        type,
        equation,
        equationUuid,
        resultVariable,
        dataVariables,
        productUnit,
        useNumpy,
      });
    } catch (error) {
      dispatch(catchGenerationError(error, 'equation map'));
      throw error;
    }
  },
);

export const saveNewVectorAnalysisMap = createAppAsyncThunk(
  'createAnalysis/saveNewVectorAnalysisMap',
  async (
    {
      name,
      zonesMapGeojson,
      hexColorCodes,
    }: {
      name: string;
      zonesMapGeojson: ZonesMapGeoJson<number>;
      hexColorCodes: string[];
    },
    { getState, rejectWithValue },
  ) => {
    const state = getState();
    const { fieldUuid } = selectSelectedField(state);
    const areaUnit = selectAreaUnit(state);

    try {
      return await saveNewVectorAnalysisMapAPI({
        fieldUuid,
        name,
        type: VectorAnalysisMapType.general,
        zonesMapGeojson,
        hexColorCodes,
        areaUnit,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const subscription =
  (parsedEvent: ParsedEvent): AppThunk =>
  async (dispatch, getState) => {
    const {
      pathLength,
      farmUuid,
      fieldUuid,
      vectorAnalysisMapUuid,
      equationMapUuid,
    } = parsedEvent;

    if (pathLength !== 3) {
      return;
    }

    const state = getState();
    const areaUnit = selectAreaUnit(state);
    const generatedVectorAnalysisMap = selectGeneratedVectorAnalysisMap(state);
    const generatedEquationMap = selectGeneratedEquationMap(state);
    const workflow = selectWorkflow(state);
    const saveInProgress = selectSaveInProgress(state);

    if (
      vectorAnalysisMapUuid !== '' &&
      vectorAnalysisMapUuid === generatedVectorAnalysisMap?.uuid
    ) {
      try {
        const vamap = await fetchGeneratedVectorAnalysisMap({
          farmUuid,
          fieldUuid,
          vectorAnalysisMapUuid,
          areaUnit,
        });

        if (isVamapInvalid(vamap)) {
          throw new CustomError('[Create Analysis] Vamap is invalid.');
        }

        dispatch(finalizeVectorAnalysisMapGeneration(vamap));
        dispatch(closeProcessingNotification());
        successNotify({
          message: i18n.t('zones-map.notifications.zones-map-saved'),
        });
      } catch (error) {
        dispatch(resetGeneratedVectorAnalysisMap());
        dispatch(catchGenerationError(error, 'vector analysis map'));
      }
    } else if (
      equationMapUuid !== '' &&
      equationMapUuid === generatedEquationMap?.uuid
    ) {
      try {
        const equationMap = await fetchGeneratedEquationMap({
          farmUuid,
          fieldUuid,
          equationMapUuid,
          areaUnit,
        });

        if (isEqmapInvalid(equationMap)) {
          throw new CustomError('[Create Analysis] Equation map is invalid.');
        }

        dispatch(finalizeEquationMapGeneration(equationMap));
        dispatch(closeProcessingNotification());
        successNotify({
          message: i18n.t('zones-map.notifications.equation-map-saved'),
        });
      } catch (error) {
        dispatch(resetGeneratedEquationMap());
        dispatch(catchGenerationError(error, 'equation map'));
      }
    } else if (
      // Handle timeout error during saving vamap with big amount of geometries
      (workflow === ZonesOpsWorkflow.drawManually ||
        workflow === ZonesOpsWorkflow.cloneZonesMap) &&
      saveInProgress
    ) {
      const vamap = await fetchGeneratedVectorAnalysisMap({
        farmUuid,
        fieldUuid,
        vectorAnalysisMapUuid,
        areaUnit,
      });

      dispatch(
        openPopup({
          type: 'save-zones-map',
          name: vamap.name,
          vamapUuid: vectorAnalysisMapUuid,
        }),
      );

      closeSnackbar(fieldUuid);
      dispatch(finalizeVectorAnalysisMapGeneration(vamap));
    }
  };

export const createAnalysisSlice = createSlice({
  name: 'createAnalysis',
  initialState,
  reducers: {
    resetGeneratedVectorAnalysisMap(state) {
      state.generatedVectorAnalysisMap =
        initialState.generatedVectorAnalysisMap;
    },
    resetGeneratedEquationMap(state) {
      state.generatedEquationMap = initialState.generatedEquationMap;
    },
    reset() {
      return initialState;
    },
    finalizeVectorAnalysisMapGeneration(
      state,
      action: PayloadAction<TransformedVectorAnalysisMap>,
    ) {
      state.saveInProgress = false;
      state.generatedVectorAnalysisMap = action.payload;
    },
    finalizeEquationMapGeneration(
      state,
      action: PayloadAction<TransformedEquationMap>,
    ) {
      state.saveInProgress = false;
      state.generatedEquationMap = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateVectorAnalysisMap.fulfilled, (state, action) => {
        state.saveInProgress = false;

        if (state.generatedVectorAnalysisMap && action.payload) {
          state.generatedVectorAnalysisMap.name = action.payload.name;
        }
      })
      .addCase(updateEquationMap.fulfilled, (state, action) => {
        state.saveInProgress = false;

        if (state.generatedEquationMap && action.payload) {
          state.generatedEquationMap.name = action.payload.name;
          state.generatedEquationMap.type = action.payload.type;
        }
      })
      .addCase(saveNewVectorAnalysisMap.pending, (state) => {
        state.saveInProgress = true;
      })
      .addCase(saveNewVectorAnalysisMap.fulfilled, (state) => {
        state.saveInProgress = false;
      })
      .addCase(saveNewVectorAnalysisMap.rejected, (state, action) => {
        if (!isTimeoutError(action.payload)) {
          state.saveInProgress = false;
        }
      })
      // TODO: fix typings when createEquationBasedAnalysisSlice refactored
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .addCase(goToEquationResults, (state, action: PayloadAction<any>) => {
        if (!action.payload.changed) {
          return;
        }

        state.generatedEquationMap = state.generatedEquationMap
          ? {
              assetType: AssetType.equationMap,
              uuid: state.generatedEquationMap.uuid,
            }
          : initialState.generatedEquationMap;
      })
      .addCase(generateEquationMap.fulfilled, (state, action) => {
        state.generatedEquationMap = {
          assetType: AssetType.equationMap,
          uuid: action.payload.uuid,
        };
      })
      .addMatcher(
        ({ type }) =>
          type === generateSatelliteVectorAnalysisMap.fulfilled.type ||
          type === generateSoilVectorAnalysisMap.fulfilled.type ||
          type === generateYieldVectorAnalysisMap.fulfilled.type ||
          type === generateAsAppliedVectorAnalysisMap.fulfilled.type ||
          type === generateTopographyVectorAnalysisMap.fulfilled.type ||
          type === generateMultiLayerVectorAnalysisMap.fulfilled.type ||
          type === generateCrossLayerVectorAnalysisMap.fulfilled.type,
        (state, action: PayloadAction<GenerationResult>) => {
          state.generatedVectorAnalysisMap = {
            assetType: AssetType.vectorAnalysisMap,
            uuid: action.payload.uuid,
          };
        },
      )
      .addMatcher(
        ({ type }) =>
          type === updateVectorAnalysisMap.pending.type ||
          type === updateEquationMap.pending.type,
        (state) => {
          state.saveInProgress = true;
        },
      )
      .addMatcher(
        ({ type }) =>
          type === updateVectorAnalysisMap.rejected.type ||
          type === updateEquationMap.rejected.type,
        (state) => {
          state.saveInProgress = false;
        },
      )
      .addMatcher(
        ({ type }) =>
          type === goToCrossLayerSettings.type ||
          type === goToStabilitySettings.type ||
          type === goToFieldPotentialSettings.type ||
          type === goToSingleLayerSettings.type ||
          type === goToMultiLayerSettings.type,
        (state, action) => {
          if (!action.payload.changed) {
            return;
          }

          if (state.generatedVectorAnalysisMap) {
            state.generatedVectorAnalysisMap = {
              assetType: AssetType.vectorAnalysisMap,
              uuid: state.generatedVectorAnalysisMap.uuid,
            };
          } else {
            state.generatedVectorAnalysisMap =
              initialState.generatedVectorAnalysisMap;
          }
        },
      );
  },
});

export const {
  resetGeneratedVectorAnalysisMap,
  resetGeneratedEquationMap,
  finalizeVectorAnalysisMapGeneration,
  finalizeEquationMapGeneration,
  reset,
} = createAnalysisSlice.actions;

export default createAnalysisSlice.reducer;
