import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { selectFieldUuid } from '../../../../field/fieldSelectors';
import ComboBox, { Option } from '../../../../../components/ComboBox';
import ApplicationsWorkPlanPanel from '../ApplicationsWorkPlanPanel';
import AssetToExportSelect from '../AssetToExportSelect';
import { TransformedEquationMap } from '../../../../../helpers/types/equationMap';
import { TransformedVectorAnalysisMap } from '../../../../../helpers/types/vectorAnalysisMap';
import { VARIETY_UNITS } from '../../helpers/constants/workPlanUnits';
import { selectAreaUnit } from '../../../../user/userSelectors';
import { getAssetUnit, getWorkPlanUint } from '../../helpers/functions/unit';
import VarietySelector from '../VarietySelector';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../app/store/helpers/functions';
import { selectJDSeedingWorkPlanSettings } from '../../jdWorkPlanSelectors';
import { setSeedingWorkPlanSettings } from '../../jdWorkPlanSlice';
import { getComboBoxOptionSelected } from '../../helpers/functions/ui';
import { AreaUnit } from '../../../../user/helpers/constants/user';

import './index.scss';

const SeedingWorkPlanPanel = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { unit, selectedAsset } = useAppSelector(
    selectJDSeedingWorkPlanSettings,
  );
  const fieldUuid = useAppSelector(selectFieldUuid);
  const areaUnit: AreaUnit = useAppSelector(selectAreaUnit);
  const workPlanUnits = useMemo(
    () => VARIETY_UNITS[areaUnit] ?? [],
    [areaUnit],
  );

  const unitOptions = useMemo(
    () =>
      workPlanUnits.map((value) => ({
        value,
        title: t(`general.popups.export-work-plan.units.${value}`),
      })),
    [workPlanUnits, t],
  );

  const selectedUnitValue = useMemo(
    () => unitOptions.find((option) => option.value === unit) ?? null,
    [unitOptions, unit],
  );

  const handleUnitChange = (
    _e: React.ChangeEvent<unknown>,
    item: Option<string> | null,
  ) => {
    dispatch(
      setSeedingWorkPlanSettings({
        unit: item?.value ?? null,
      }),
    );
  };

  const handleSelectedAssetChange = (
    asset: TransformedEquationMap | TransformedVectorAnalysisMap | null,
  ) => {
    const newSelectedAsset = asset
      ? {
          uuid: asset.uuid,
          name: asset.name ?? '',
          resourceType: asset.assetType,
          type: asset.type,
          fieldUuid: fieldUuid ?? '',
          unit: getAssetUnit(asset),
        }
      : null;

    const workPlanUnit = getWorkPlanUint(workPlanUnits, newSelectedAsset?.unit);

    dispatch(
      setSeedingWorkPlanSettings({
        selectedAsset: newSelectedAsset,
        unit: workPlanUnit ?? unit,
      }),
    );
  };

  return (
    <>
      <div className="seeding-work-plan-panel">
        <AssetToExportSelect
          selectedAssetUuid={selectedAsset?.uuid}
          onAssetSelect={handleSelectedAssetChange}
        />
        <div className="seeding-work-plan-panel__row">
          <VarietySelector />
          <ComboBox
            classes={{
              root: 'seeding-work-plan-panel__unit-select',
            }}
            title={t('general.labels.unit')}
            placeholder={t('general.controls.select')}
            options={unitOptions}
            value={selectedUnitValue}
            getOptionSelected={getComboBoxOptionSelected}
            disableCloseOnSelect={false}
            onChange={handleUnitChange}
          />
        </div>
      </div>
      <ApplicationsWorkPlanPanel defaultAsset={selectedAsset} />
    </>
  );
};

export default SeedingWorkPlanPanel;
