import { UnknownWithUuid } from '../../../../helpers/types/shared';
import { comparator } from '../../../../helpers/functions/utils/string';

export const sortComparator = (a: { name?: string }, b: { name?: string }) =>
  comparator(a.name || '', b.name || '');

/**
 * Returns plain array of fields from the array of farms.
 * @param farms Array of farms
 * @returns Array of fields
 */
export const extractFields = <T>(farms: { fields: T[] }[]): T[] =>
  farms.reduce<T[]>((acc, farm) => {
    acc.push(...farm.fields);

    return acc;
  }, []);

/**
 * Returns fields map (fieldUuids as keys) from the array of farms.
 * @param farms Array of farms
 * @returns [fieldUuid: string]: T[]
 */
export const extractFieldsMap = <T extends UnknownWithUuid>(
  farms: { fields: T[] }[],
): Record<string, T> =>
  farms.reduce<Record<string, T>>((acc, { fields }) => {
    if (fields.length !== 0) {
      fields.forEach((field) => {
        acc[field.uuid] = field;
      });
    }

    return acc;
  }, {});

export const getUniqueFieldUuids = (
  items: { fieldUuid?: string }[],
): string[] => {
  const uniqueFieldUuids = new Set<string>();

  items.forEach(({ fieldUuid }) => {
    if (fieldUuid) {
      uniqueFieldUuids.add(fieldUuid);
    }
  });

  return Array.from(uniqueFieldUuids);
};
