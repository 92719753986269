import {
  AssetLoadStatus,
  AssetType,
} from '../../../../helpers/constants/entities/asset';
import { GeoMap } from '../../../../helpers/types/api';
import {
  GeoMapType,
  MinifiedSatelliteImage,
  TransformedSatelliteImage,
} from '../../types/satelliteImage';
import { GeoMapTypeVariant, GeoMapTypeOption } from '../constants/geoMapType';

export const remapUuid = <T extends { satelliteImage: { uuid: string } }>(
  images?: T[],
) =>
  (images || []).map((image) => ({
    ...image,
    uuid: image.satelliteImage.uuid,
  }));

export const transform = <T extends MinifiedSatelliteImage>(
  images?: T[],
): TransformedSatelliteImage<T>[] => {
  const remappedImages = remapUuid<T>(images);

  return remappedImages.map((image) => ({
    ...image,
    assetType: AssetType.satelliteImage,
    cropStatus: AssetLoadStatus.idle,
    contrastStatus: AssetLoadStatus.idle,
    rawStatus: AssetLoadStatus.idle,
    rawCropStatus: AssetLoadStatus.idle,
    rawContrastStatus: AssetLoadStatus.idle,
  }));
};

export const getGeoMapTypeValue = ({
  isRawType = false,
  geoMapType = GeoMapTypeOption.default,
}: {
  isRawType?: boolean;
  geoMapType?: GeoMapTypeOption;
}): GeoMapType | null => {
  if (geoMapType === GeoMapTypeOption.default) {
    return isRawType ? 'RAW' : null;
  }

  if (geoMapType === GeoMapTypeOption.crop) {
    return isRawType ? 'RAW_CROPPED' : 'CROPPED';
  }

  if (geoMapType === GeoMapTypeOption.contrast) {
    return isRawType ? 'RAW_CONTRAST' : 'CONTRAST';
  }

  return null;
};

const getSatelliteImageGeoMapName = (
  geoMapType: GeoMapType | null,
  index: string,
) => {
  const formattedIndex = index.toLowerCase();
  let result = formattedIndex;

  if (geoMapType === 'RAW') {
    result = `raw_${formattedIndex}`;
  } else if (geoMapType === 'CROPPED') {
    result = `cropped_${formattedIndex}`;
  } else if (geoMapType === 'CONTRAST') {
    result = `contrast_${formattedIndex}`;
  } else if (geoMapType === 'RAW_CROPPED') {
    result = `raw_cropped_${formattedIndex}`;
  } else if (geoMapType === 'RAW_CONTRAST') {
    result = `raw_contrast_${formattedIndex}`;
  }

  return result;
};

export const getSatelliteImageGeoMap = (
  geoMaps: GeoMap[] | undefined,
  geoMapType: GeoMapType | null,
  index: string,
): GeoMap | null => {
  const geoMapName = getSatelliteImageGeoMapName(geoMapType, index);
  const geoMap = (geoMaps || []).find(
    ({ shortName }) => shortName === geoMapName,
  );

  return geoMap || null;
};

export const getGeoMapLoadStatusKey = ({
  isRawType = false,
  geoMapType = GeoMapTypeOption.default,
}: {
  isRawType?: boolean;
  geoMapType?: GeoMapTypeOption;
}): GeoMapTypeVariant | null => {
  if (geoMapType === GeoMapTypeOption.default) {
    return isRawType ? GeoMapTypeVariant.rawStatus : null;
  }

  if (geoMapType === GeoMapTypeOption.crop) {
    return isRawType
      ? GeoMapTypeVariant.rawCropStatus
      : GeoMapTypeVariant.cropStatus;
  }

  if (geoMapType === GeoMapTypeOption.contrast) {
    return isRawType
      ? GeoMapTypeVariant.rawContrastStatus
      : GeoMapTypeVariant.contrastStatus;
  }

  return null;
};
