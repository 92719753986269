query getChemicalsJohnDeere($chemicalFilter: ProductFilterJohnDeere) {
  getChemicalsJohnDeere(chemicalFilter: $chemicalFilter) {
    chemicals {
      id
      name
      companyName
    }
    totalCount
  }
}
