import { circle } from '@turf/turf';

import { appendApiKey } from '../../../../helpers/functions/utils/url';

export const getCircles = (pins = []) => {
  const options = {
    steps: 64,
    units: 'kilometers',
  };

  return pins.map((pin) => {
    const {
      centroid: { latitude, longitude },
    } = pin;
    const MIN_DISTANCE = 0.003; // 3 meters

    return circle([longitude, latitude], MIN_DISTANCE, options);
  });
};

export const getPhotosToPreload = (pin) => {
  if (!pin) {
    return [];
  }

  return [
    ...(pin.photos || []),
    ...(pin.comments || []).flatMap((comment) => comment.photos || []),
  ];
};

export const prepareForDisplay = (pins, apiKey) =>
  (pins || []).map((pin) => ({
    ...pin,
    photos:
      pin.photos?.map?.((photo) => {
        if (photo.isRecentlySaved) {
          return photo;
        }

        return {
          ...photo,
          url: appendApiKey(photo.url, apiKey),
        };
      }) || [],
    comments: pin.comments?.map?.((comment) => ({
      ...comment,
      photos:
        comment.photos?.map?.((commentPhoto) => {
          if (commentPhoto.isRecentlySaved) {
            return commentPhoto;
          }

          return {
            ...commentPhoto,
            url: appendApiKey(commentPhoto.url, apiKey),
          };
        }) || [],
    })),
  }));
