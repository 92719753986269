import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store/helpers/types';

export const selectJDWorkPlanLoading = ({ jdWorkPlan }: RootState) =>
  jdWorkPlan.isLoading;

export const selectJDWorkPlanType = ({ jdWorkPlan }: RootState) =>
  jdWorkPlan.type;

export const selectJDWorkPlanSettings = ({ jdWorkPlan }: RootState) =>
  jdWorkPlan.settings;

export const selectJDSeedingWorkPlanSettings = ({ jdWorkPlan }: RootState) =>
  jdWorkPlan.settings.seeding;

export const selectApplications = createSelector(
  selectJDWorkPlanSettings,
  selectJDWorkPlanType,
  (settings, workPlanType) => settings[workPlanType].applications,
);

export const selectApplication = createSelector(
  selectJDWorkPlanSettings,
  selectJDWorkPlanType,
  (_: RootState, id: string) => id,
  (settings, workPlanType, id) =>
    settings[workPlanType].applications.find(
      (application) => application.id === id,
    ),
);
