import { API, graphqlOperation } from '@aws-amplify/api';

import getLabelsQuery from './graphql/queries/getLabels.gql';
import saveLabelsMutation from './graphql/mutations/saveLabels.gql';
import deleteLabelMutation from './graphql/mutations/deleteLabel.gql';

export const getLabels = async () =>
  API.graphql(graphqlOperation(getLabelsQuery)).then(
    ({ data }) => data.getLabels,
  );

export const saveLabels = async (labels) =>
  API.graphql(
    graphqlOperation(saveLabelsMutation, {
      input: {
        labels,
      },
    }),
  ).then(({ data }) => data.saveLabels);

export const deleteLabel = async (label) =>
  API.graphql(
    graphqlOperation(deleteLabelMutation, {
      input: label,
    }),
  ).then(({ data }) => data.deleteLabel);
