import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function CloneIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1248 0.916626H2.2915V15.5833H4.12484V2.74996H15.1248V0.916626ZM19.7082 4.58329H5.95817V21.0833H19.7082V4.58329ZM7.7915 19.25H17.8748V6.41663H7.7915V19.25Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
