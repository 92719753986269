import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import POPUPS from './helpers/constants/popups';
import { selectPopups } from './popupsSelectors';

export const openUploadFiles = createAsyncThunk(
  'popups/openUploadFiles',
  () => {},
  {
    condition: (_, { getState }) => {
      const state = getState();
      const popups = selectPopups(state);
      const uploadPopupActive = popups.some(
        ({ type }) => type === POPUPS.uploadFiles,
      );

      if (uploadPopupActive) {
        return false;
      }
    },
  },
);

const getPopupEntry = (popup) => ({
  ...popup,
  id: Date.now().toString(),
});

export const popupsSlice = createSlice({
  name: 'popups',
  initialState: [],
  reducers: {
    openPopup(state, action) {
      state.push(getPopupEntry(action.payload));
    },
    closePopup(state, action) {
      return state.filter(({ id }) => id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(openUploadFiles.fulfilled, (state) => {
      state.push(
        getPopupEntry({
          type: POPUPS.uploadFiles,
        }),
      );
    });
  },
});

export const { closePopup, openPopup } = popupsSlice.actions;

export default popupsSlice.reducer;
