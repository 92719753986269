import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MapProvider } from '../../../helpers/constants/map';
import { MapAttributes } from './types/mapAttributes';
import { LayersToCompareData } from './types/layer';

export interface CompareLayersState {
  mapControls: {
    is3d: boolean;
    mapProvider: MapProvider;
  };
  layers: LayersToCompareData;
  legend: {
    uuid: string;
  };
}

const initialState: CompareLayersState = {
  mapControls: {
    is3d: false,
    mapProvider: MapProvider.mapbox,
  },
  layers: [],
  legend: {
    uuid: '',
  },
};

export const compareLayersSlice = createSlice({
  name: 'compareLayers',
  initialState,
  reducers: {
    setLegend(state: CompareLayersState, action: PayloadAction<string>) {
      state.legend.uuid = action.payload;
    },
    resetLegend(state: CompareLayersState) {
      state.legend = initialState.legend;
    },
    addLayers(
      state: CompareLayersState,
      { payload }: PayloadAction<{ layers: LayersToCompareData }>,
    ) {
      state.layers = payload.layers;
    },
    removeLayer(state: CompareLayersState, action: PayloadAction<string>) {
      state.layers = state.layers.filter(
        ({ layerId }) => layerId !== action.payload,
      );
    },
    updateLayerMapAttributes(
      state: CompareLayersState,
      action: PayloadAction<{
        layerId: string;
        mapAttributes: MapAttributes[keyof MapAttributes];
      }>,
    ) {
      state.layers = state.layers.map((layer) => {
        if (layer.layerId !== action.payload.layerId) {
          return layer;
        }

        return {
          ...layer,
          mapAttributes: {
            ...layer.mapAttributes,
            ...action.payload.mapAttributes,
          },
        };
      });
    },
    setMapIs3d(state: CompareLayersState, action: PayloadAction<boolean>) {
      state.mapControls.is3d = action.payload;
    },
    setMapProvider(
      state: CompareLayersState,
      action: PayloadAction<MapProvider>,
    ) {
      state.mapControls.mapProvider = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const {
  setLegend,
  resetLegend,
  addLayers,
  removeLayer,
  updateLayerMapAttributes,
  setMapIs3d,
  setMapProvider,
  reset,
} = compareLayersSlice.actions;

export default compareLayersSlice.reducer;
