export const selectIsProcessing = ({ userSettings }) =>
  userSettings.isProcessing;

export const selectUpdates = ({ userSettings }) => userSettings.updates;

export const selectHasUpdates = (state) => {
  const { language, areaUnit } = selectUpdates(state);

  return language !== '' || areaUnit !== '';
};
