import React, { ReactElement, useState } from 'react';
import clsx from 'clsx';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { triggerMapResize } from '../../../../../helpers/mapbox/map';
import { getComponent } from '../../../../../helpers/markup';

import './index.scss';

export default function DataLayersList({
  listView,
  collapsible = false,
  children = [],
}: {
  listView: ReactElement;
  collapsible?: boolean;
  children?: ReactElement | ReactElement[];
}) {
  const [listCollapsed, setListCollapsed] = useState<boolean>(false);

  const handleCollapseClick = () => {
    triggerMapResize();
    setListCollapsed(!listCollapsed);
  };

  return (
    <div
      className={clsx('data-layers-list', {
        'data-layers-list_collapsed': listCollapsed,
      })}
    >
      {getComponent('top-panel', children)}
      {getComponent('list-header', children)}
      <div className="data-layers-list__list-wrapper">
        {listView}
        {collapsible && (
          <button
            className="data-layers-list__collapse-button"
            onClick={handleCollapseClick}
          >
            {listCollapsed ? (
              <ChevronRightIcon fontSize="small" />
            ) : (
              <ChevronLeftIcon fontSize="small" />
            )}
          </button>
        )}
      </div>
      <div className="data-layers-list__bottom-panel">
        {getComponent('bottom-panel', children)}
      </div>
    </div>
  );
}
