import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

import { QueryFragment, Strategy } from '../../types/api';

const QUERIES: {
  [key in Strategy]: (fieldFragment: QueryFragment) => DocumentNode;
} = {
  fieldsPage: ({ name, fragment }) => gql`
      query getFieldsPage($filter: FieldFilter) {
        getFields(filter: $filter) {
          lastEvaluatedKey {
            PartitionKey
            SortKey
            TagString
          }
          fields {
            ...${name}
          }
        }
      }
      ${fragment}
    `,
  single: ({ name, fragment }) => gql`
      query getField($filter: FieldFilter) {
        getFields(filter: $filter) {
          fields {
            ...${name}
          }
        }
      }
      ${fragment}
    `,
} as const;

const fieldQueryBuilder = (
  strategy: Strategy,
  fieldFragment: QueryFragment,
): DocumentNode => QUERIES[strategy](fieldFragment);

export default fieldQueryBuilder;
