import React from 'react';
import TableCell from '@material-ui/core/TableCell';

import type { DefaultTableRow } from '../../../types/row';
import type { DefaultTableColumn } from '../../../types/column';
import getCellClasses from '../../../helpers/functions/getCellClasses';

import './index.scss';

export default function DefaultCell({
  row,
  headCell,
}: {
  row: DefaultTableRow;
  headCell: DefaultTableColumn;
}) {
  const property = row[headCell.id as keyof typeof row] as string;

  return (
    <TableCell
      key={headCell.id}
      className={getCellClasses('default-cell', headCell, row)}
    >
      <span title={property}>{property}</span>
    </TableCell>
  );
}
