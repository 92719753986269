import React, { useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '../../../../../components/TextField';
import getDebouncer from '../../../../../helpers/functions/utils/getDebouncer';
import { variableNameFormatter } from '../../../../../helpers/functions/entities/equation';

import './index.scss';

const TIMEOUT = 500;
const debouncer = getDebouncer(TIMEOUT);

const VariableNameDataLayersTreePanel = ({
  variable = '',
  error = '',
  onVariableChange,
}: {
  variable?: string;
  error?: string;
  onVariableChange: (v: string) => void;
}) => {
  const { t } = useTranslation();

  const [fieldValue, setFieldValue] = useState(variable);

  const handleVariableChange = (e: ChangeEvent<HTMLInputElement>) => {
    const formattedValue = variableNameFormatter(e.target.value || '');

    setFieldValue(formattedValue);

    debouncer(() => {
      onVariableChange(formattedValue);
    });
  };

  return (
    <TextField
      required
      title={t(
        'zones-ops.equation-based.steps.4.panel.variables-list.variable-name',
      )}
      placeholder={t(
        'zones-ops.equation-based.steps.4.panel.variables-list.add-new-variable',
      )}
      classes={{
        wrapper: 'variable-name-panel',
        title: 'variable-name-panel__title',
      }}
      value={fieldValue}
      error={!!error}
      helperText={t(error)}
      onChange={handleVariableChange}
    />
  );
};

export default VariableNameDataLayersTreePanel;
