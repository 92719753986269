import { TileSize } from '../constants/map';

export const repl1 = (source = '', key = '', value = '') => {
  const start = source.indexOf(key);
  const end = source.indexOf('&', start);
  let r;
  if (start > -1) {
    if (end === -1) {
      r = `${source.substring(0, start + key.length + 1)}${value}`;
    } else {
      r = `${source.substring(0, start + key.length + 1)}${value}${source.substring(end)}`;
    }
  } else {
    r = source;
  }
  return r;
};

const repl = (sources = [], key = '', value = '') =>
  sources.map((source) => repl1(source, key, value));

export const encode = (sources = [], key = '') =>
  sources.map((source) => {
    const start = source.indexOf(key);
    const end = source.indexOf('&', start);
    let r;
    if (start > -1) {
      if (end === -1) {
        const value = source.substring(start + key.length + 1);
        r = `${source.substring(0, start + key.length + 1)}${encodeURIComponent(value)}`;
      } else {
        const value = source.substring(start + key.length + 1, end);
        r = `${source.substring(0, start + key.length + 1)}${encodeURIComponent(value)}${source.substring(end)}`;
      }
    } else {
      r = source;
    }
    return r;
  });

export const encodeCharacter = (sources = [], character = '') =>
  sources.map((source) =>
    source.replace(character, encodeURIComponent(character)),
  );

export const convertSourcesToMapBoxFormat = (
  sources = [],
  tileSize = TileSize.large,
) => {
  let result = repl(sources, 'BBOX', '{bbox-epsg-3857}');

  result = repl(result, 'SRS', 'EPSG:900913');
  result = repl(result, 'HEIGHT', tileSize);
  result = repl(result, 'WIDTH', tileSize);

  return encodeCharacter(encode(result, 'env'), ';');
};
