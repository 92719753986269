import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import IntegrationsPanelLayout from '../../components/IntegrationsPanelLayout';
import Loading from '../../../../../components/Loading';
import getIntegrationUrl from '../../helpers/functions/getIntegrationUrl';
import Marketplace from '../Marketplace';

const JDIntegrationPage = React.lazy(
  () => import('../../../jdIntegration/containers/IntegrationPage'),
);

export default function Panel() {
  return (
    <IntegrationsPanelLayout>
      <Switch>
        <Route path={getIntegrationUrl('jd')}>
          <Suspense fallback={<Loading />}>
            <JDIntegrationPage />
          </Suspense>
        </Route>
        <Route path="*">
          <Marketplace />
        </Route>
      </Switch>
    </IntegrationsPanelLayout>
  );
}
