import React from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import CloseIcon from '@material-ui/icons/Close';

import { getThumbnailUrl } from '../../../helpers/functions/utils/url';
import { getFieldThumbnailGeoMap } from '../../../features/field/helpers/functions/field';

import './index.scss';

const FieldBoundary = ({ apiKey, field, details, onGetDetails, onClose }) => {
  const { t } = useTranslation();
  const thumbnail = getFieldThumbnailGeoMap(field.geoMaps);

  return (
    <div className="field-boundary">
      <div className="card-header">
        <div className="card-header__container">
          <span className="card-header__text">
            {t('export.field-boundary')}
          </span>
          <IconButton size="small" className="btn" onClick={onGetDetails}>
            <InfoIcon fontSize="inherit" className="icon icon_primary" />
          </IconButton>
        </div>
        <IconButton size="small" className="btn" onClick={onClose}>
          <CloseIcon fontSize="inherit" className="icon" />
        </IconButton>
      </div>
      <div className="field-boundary__container">
        <img
          className="field-boundary__preview"
          src={getThumbnailUrl({ thumbnail, size: 250, apiKey })}
          alt={t('general.controls.zones-map-preview')}
        />
        {details}
      </div>
    </div>
  );
};

export default FieldBoundary;
