import { API, graphqlOperation } from '@aws-amplify/api';

import {
  UploadType,
  UPLOAD_TYPE_REMAPPING,
  UploadFileExtension,
} from './helpers/constants/upload';
import getAssetsStatusQuery from './graphql/queries/getAssetsStatus.gql';
import generatePresignedPostFileData from './graphql/mutations/generatePresignedPostFileData.gql';
import {
  GeneratePresignedPostFileDataResponse,
  GetAssetsStatusResponse,
} from './types/api';
import { FieldStatus } from '../../field/types/field';
import { DatasetStatus } from '../../../helpers/types/dataset';
import { AssetGroupType } from '../../../helpers/constants/entities/asset';

type AssetProp = Exclude<
  AssetGroupType,
  | 'satelliteImages'
  | 'vectorAnalysisMaps'
  | 'topographyMaps'
  | 'threeDimensionalMaps'
  | 'equationMaps'
  | 'pinsGroups'
>;

export const getAssetStatus = async ({
  farmUuid,
  fieldUuid,
  uuid,
  isField,
  isSoil,
  isYield,
  isAsApplied,
}: {
  farmUuid: string;
  fieldUuid: string;
  uuid: string;
  isField: boolean;
  isSoil: boolean;
  isYield: boolean;
  isAsApplied: boolean;
}) => {
  const variables: {
    farmUuids: string[];
    fieldUuids: string[];
    checkSoil: boolean;
    checkYield: boolean;
    checkAsApplied: boolean;
    soilDatasetUuids?: string[];
    yieldDatasetUuids?: string[];
    asAppliedDatasetUuids?: string[];
  } = {
    farmUuids: [farmUuid],
    fieldUuids: [fieldUuid],
    checkSoil: false,
    checkYield: false,
    checkAsApplied: false,
  };
  let fieldProp: AssetProp;

  if (isSoil) {
    variables.soilDatasetUuids = [uuid];
    variables.checkSoil = true;
    fieldProp = AssetGroupType.soilDatasets;
  } else if (isYield) {
    variables.yieldDatasetUuids = [uuid];
    variables.checkYield = true;
    fieldProp = AssetGroupType.yieldDatasets;
  } else if (isAsApplied) {
    variables.asAppliedDatasetUuids = [uuid];
    variables.checkAsApplied = true;
    fieldProp = AssetGroupType.asAppliedDatasets;
  }

  return (
    API.graphql(
      graphqlOperation(getAssetsStatusQuery, variables),
    ) as Promise<GetAssetsStatusResponse>
  ).then(({ data }) => {
    const field = data!.getFarms[0].fields[0];
    let status;
    let statusMessage;

    if (isField) {
      status = field.status;
      statusMessage = field.statusMessage;
    } else if (isSoil || isYield || isAsApplied) {
      status = field[fieldProp]![0].status;
    }

    return {
      status,
      statusMessage,
    } as {
      status: FieldStatus | DatasetStatus;
      statusMessage?: string;
    };
  });
};

export type SupportedUploadType = Exclude<UploadType, UploadType.allTypes>;

export const uploadFile = async ({
  type,
  farmUuid,
  arraybuffer,
  fileExtension = UploadFileExtension.zip,
}: {
  type: SupportedUploadType;
  farmUuid?: string;
  arraybuffer: ArrayBuffer;
  fileExtension?: UploadFileExtension;
}) => {
  const { data } = await (API.graphql(
    graphqlOperation(generatePresignedPostFileData, {
      input: {
        fileType: UPLOAD_TYPE_REMAPPING[type],
        farmUuid,
        fileExtension,
      },
    }),
  ) as GeneratePresignedPostFileDataResponse);

  const { s3Fields, s3Url: url, uuid } = data!.generatePresignedPostFileData;
  const fields = JSON.parse(s3Fields);
  const formData = new FormData();

  Object.keys(fields).forEach((key) => {
    formData.append(key, fields[key]);
  });
  formData.append('file', new Blob([arraybuffer]));

  await fetch(url, {
    method: 'POST',
    body: formData,
  });

  return uuid;
};
