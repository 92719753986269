import { nanoid } from '@reduxjs/toolkit';
import {
  isAsAppliedDataset,
  isEquationMap,
  isSatelliteImage,
  isSoilDataset,
  isTopographyMap,
  isYieldDataset,
} from '../../../../../helpers/functions/entities/assets';
import getAssetByUuid from '../../../../../helpers/functions/entities/getAssetByUuid';
import { TransformedField } from '../../../../field/types/field';
import {
  generateAssetNodeId,
  generateAttributeNodeId,
} from '../../../dataLayersView/helpers/functions/dataLayersTree';
import { SelectedDataLayersData } from '../../../dataLayersView/types/dataLayersTree';
import { LayersToCompareData } from '../../types/layer';
import prepareAssetMapAttributes from './prepareAssetMapAttributes';
import { DatasetViewType } from '../../../../../helpers/constants/entities/dataset';

export const transformSelectedLayersToCompareLayersData = (
  selectedDataLayers: SelectedDataLayersData,
  field: TransformedField | null,
  previousLayers: LayersToCompareData = [],
) => {
  const layers = selectedDataLayers.reduce(
    (acc: LayersToCompareData, { uuid, assetGroupType, attributes }) => {
      const item = getAssetByUuid(uuid, field);

      const layerData = {
        uuid,
        type: assetGroupType,
      };

      if (attributes?.length && item) {
        return [
          ...acc,
          ...attributes.map((attribute) => ({
            ...layerData,
            mapAttributes: prepareAssetMapAttributes(item, attribute),
          })),
        ];
      }

      return [...acc, layerData];
    },
    [],
  );

  return layers.map((layer) => {
    const id = previousLayers.find(
      ({ layerId, ...previousLayer }) =>
        JSON.stringify(previousLayer) === JSON.stringify(layer),
    )?.layerId;

    return {
      ...layer,
      layerId: id ?? nanoid(),
    };
  });
};

export const transformCompareLayersToCheckedData = (
  layers: LayersToCompareData,
  field: TransformedField | null,
) =>
  layers.reduce((acc, { uuid, type, mapAttributes }) => {
    if (!mapAttributes) {
      return {
        ...acc,
        [generateAssetNodeId(uuid, type)]: 2,
      };
    }

    const item = getAssetByUuid(uuid, field);

    if (
      isSoilDataset(item) ||
      isYieldDataset(item) ||
      isAsAppliedDataset(item)
    ) {
      const { attribute, viewType } = mapAttributes as {
        attribute: string;
        viewType: DatasetViewType;
      };

      return {
        ...acc,
        [generateAttributeNodeId(uuid, attribute, viewType)]: 2,
      };
    }
    if (isEquationMap(item)) {
      const { geoMap } = mapAttributes as { geoMap: string };

      return {
        ...acc,
        [generateAttributeNodeId(uuid, geoMap)]: 2,
      };
    }
    if (isSatelliteImage(item) || isTopographyMap(item)) {
      const { viewType } = mapAttributes as { viewType: string };

      return {
        ...acc,
        [generateAttributeNodeId(uuid, viewType)]: 2,
      };
    }

    return acc;
  }, {});
