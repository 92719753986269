mutation generatePresignedPostFileData(
  $input: GeneratePresignedPostFileDataInput!
) {
  generatePresignedPostFileData(input: $input) {
    uuid
    farmUuid
    s3Url
    s3Fields
  }
}
