import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function RestartSameIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 12C4.5 7.03 8.53 3 13.5 3C18.47 3 22.5 7.03 22.5 12C22.5 16.97 18.47 21 13.5 21C11.01 21 8.77 19.99 7.14 18.36L8.56 16.94C9.82 18.21 11.57 19 13.5 19C17.37 19 20.5 15.87 20.5 12C20.5 8.13 17.37 5 13.5 5C9.63 5 6.5 8.13 6.5 12H9.5L5.5 15.99L1.5 12H4.5ZM17.25 9.75V8.25H9.75V9.75H17.25ZM10.5 11.25H17.25V12.75H10.5V11.25ZM17.25 15.75V14.25H9.75V15.75H17.25Z"
      />
    </SvgIcon>
  );
}
