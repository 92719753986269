mutation manageProfileJohnDeere($input: ManageProfileJohnDeereInput!) {
  manageProfileJohnDeere(input: $input) {
    hasAutoImportAllFieldOperations
    hasAutoUpdateFieldBoundaries
    hasAutoUpdateFieldStructure
    hasAutoCreateZonesMapLayers
    hasAutoCreateSoilMapLayers
    hasAutoCreateYieldMapLayers
    hasAutoCreateTopographyMapLayers
    hasAutoCreateEquationMapLayers
  }
}
