query getColorSchemas {
  getUserData {
    colorSchemas {
      title
      uuid
      colors {
        index
        hexCode
      }
    }
  }
}
