import React, {
  Fragment,
  MouseEvent,
  useCallback,
  useMemo,
  KeyboardEvent,
  useEffect,
  useState,
  useRef,
} from 'react';
import { Virtuoso } from 'react-virtuoso';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import type { ListPanelItem } from '../types';
import { ButtonMenuItem } from '../../ButtonMenu/types';
import ButtonMenu from '../../ButtonMenu';
import { isNumber } from '../../../helpers/functions/utils/number';
import { isEnterKeyPressed } from '../../../helpers/functions/utils/navigation';
import EventKey from '../../../helpers/constants/eventKey';
import List from '../List';
import ListItem from '../ListItem';
import ListHeader from '../ListHeader';

import './index.scss';

// Set the overscan property to pre-render items in order to prevent losing focus when the user navigates using arrow keys,
// as scrolling stops working when the focused element is unmounted.
const OVERSCAN_SIZE = 200;

const MenuItems = ({
  index,
  item,
  menuItems,
  onMenuItemClick,
}: {
  index: number;
  item: ListPanelItem;
  menuItems: ButtonMenuItem[];
  onMenuItemClick?: (m: ButtonMenuItem, i: ListPanelItem, ind: number) => void;
}) => (
  <ButtonMenu
    isIconButton
    edge="end"
    size="small"
    items={menuItems}
    classes={{
      iconButton: {
        root: 'list-panel__item-menu',
      },
    }}
    onItemClick={(e) => onMenuItemClick?.(e, item, index)}
  >
    <MoreVertIcon fontSize="small" />
  </ButtonMenu>
);

const ListItemContent = ({
  index,
  item,
  menuItems,
  focusedItemIndex,
  activeItemsValues,
  emptyAddedItemsLabel,
  onItemSelect,
  onMenuItemClick,
  onItemFocus,
}: {
  index: number;
  item: ListPanelItem | null;
  focusedItemIndex: number | null;
  menuItems?: ButtonMenuItem[];
  activeItemsValues: Set<string>;
  emptyAddedItemsLabel?: string;
  onItemSelect: (e: MouseEvent | KeyboardEvent, i: ListPanelItem) => void;
  onMenuItemClick?: (m: ButtonMenuItem, i: ListPanelItem, ind: number) => void;
  onItemFocus: () => void;
}) => {
  const buttonRef = useRef<HTMLDivElement | null>(null);

  // Focus on the button to enable arrow key scrolling,
  // as Virtuoso does not support arrow key scrolling within a Material-UI dialog without focus.
  useEffect(() => {
    if (focusedItemIndex === index && buttonRef.current) {
      buttonRef.current.focus();
      onItemFocus();
    }
  }, [focusedItemIndex, index, onItemFocus]);

  const handleItemKeyDown = (event: KeyboardEvent) => {
    if (isEnterKeyPressed(event) && item) {
      onItemSelect(event, item);
    }
  };

  return (
    <Fragment key={index}>
      {item && (
        <>
          <div
            role="button"
            tabIndex={0}
            ref={buttonRef}
            className={clsx('list-panel__item-button', {
              'list-panel__item-button-selected': activeItemsValues.has(
                item.value,
              ),
            })}
            onClick={(e) => onItemSelect(e, item)}
            onKeyDown={handleItemKeyDown}
          >
            {item.title}
          </div>
          {menuItems && (
            <MenuItems
              index={index}
              item={item}
              menuItems={menuItems}
              onMenuItemClick={onMenuItemClick}
            />
          )}
        </>
      )}
      {!item && emptyAddedItemsLabel && (
        <div className="list-panel__empty-item">{emptyAddedItemsLabel}</div>
      )}
    </Fragment>
  );
};

const ListPanel = ({
  title,
  addButtonLabel,
  emptyAddedItemsLabel,
  items = [],
  activeItemsValues,
  menuItems,
  required = false,
  onItemSelect,
  onAddClick,
  onMenuItemClick = () => {},
}: {
  title: string;
  addButtonLabel?: string;
  emptyAddedItemsLabel?: string;
  items: ListPanelItem[];
  activeItemsValues: Set<string>;
  menuItems?: ButtonMenuItem[];
  required?: boolean;
  onItemSelect: (e: MouseEvent | KeyboardEvent, i: ListPanelItem) => void;
  onAddClick?: () => void;
  onMenuItemClick?: (m: ButtonMenuItem, i: ListPanelItem, ind: number) => void;
}) => {
  const [focusedItemIndex, setFocusedItemIndex] = useState<number | null>(null);

  const handleItemFocus = useCallback(() => {
    setFocusedItemIndex(null);
  }, []);

  const itemRenderer = (index: number, item: ListPanelItem | null) => (
    <ListItemContent
      index={index}
      item={item}
      menuItems={menuItems}
      focusedItemIndex={focusedItemIndex}
      activeItemsValues={activeItemsValues}
      emptyAddedItemsLabel={emptyAddedItemsLabel}
      onItemSelect={onItemSelect}
      onMenuItemClick={onMenuItemClick}
      onItemFocus={handleItemFocus}
    />
  );

  const headerRenderer = useCallback(
    () => (
      <ListHeader addButtonLabel={addButtonLabel} onAddClick={onAddClick} />
    ),
    [addButtonLabel, onAddClick],
  );

  const data = useMemo(
    () => (!items.length && emptyAddedItemsLabel ? [null] : items),
    [items, emptyAddedItemsLabel],
  );

  // Save the item index during arrow key navigation to focus current item.
  // This enables scrolling during arrow key navigation and improves navigation using the Tab button.
  const handleListKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const listItem: HTMLElement | null = (e.target as HTMLElement)?.closest(
        '.list-item',
      );

      if (e.key === EventKey.ArrowDown) {
        const currentIndex = Number(listItem?.dataset?.itemIndex);
        setFocusedItemIndex(
          isNumber(currentIndex) && data.length > currentIndex
            ? currentIndex + 1
            : null,
        );
      }

      if (e.key === EventKey.ArrowUp) {
        const currentIndex = Number(listItem?.dataset?.itemIndex);
        setFocusedItemIndex(
          isNumber(currentIndex) && currentIndex > 0 ? currentIndex - 1 : null,
        );
      }
    },
    [data.length],
  );

  return (
    <div className="list-panel">
      <Typography className="list-panel__title">
        {title}
        {required && <span className="required-asterisk">*</span>}
      </Typography>
      <div className="list-panel__list">
        <Virtuoso
          data={data}
          className="list-panel__scroller"
          overscan={OVERSCAN_SIZE}
          components={{
            List,
            Item: ListItem,
            Header: headerRenderer,
          }}
          itemContent={itemRenderer}
          onKeyDown={handleListKeyDown}
        />
      </div>
    </div>
  );
};

export default ListPanel;
