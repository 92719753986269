query getZonesMapsList($filter: ZonesMapsFilter!, $areaUnit: AreaUnit!) {
  getZonesMaps(filter: $filter) {
    zonesMaps {
      uuid
      name
      status
      createdDate
      updatedDate
      fieldUuid
      analysisType
      type
      attributesJson(unit: $areaUnit)
      geoMaps {
        hexColorCodes
        shortName
        url
        classes
      }
      satelliteImages {
        satelliteImage {
          uuid
        }
      }
      soilDataset {
        uuid
        name
      }
      yieldDataset {
        uuid
        name
      }
      asAppliedDataset {
        uuid
        name
      }
      topographyMap {
        uuid
        name
      }
      dataLayers {
        factor
        index
        satelliteImages {
          satelliteImage {
            uuid
            acquisitionDate
          }
        }
        soilDataset {
          uuid
        }
        soilAttribute
        yieldDataset {
          uuid
        }
        yieldAttribute
        asAppliedDataset {
          uuid
        }
        asAppliedAttribute
        topographyMap {
          uuid
        }
        topographyAttribute
      }
      zonesOperationMaps {
        map {
          uuid
        }
        zoneNumbers
      }
      parameters {
        index
        numberOfZones
        soilAttribute
        yieldAttribute
        asAppliedAttribute
        topographyAttribute
        zonesOperation
        polygonMinArea
        dataClassificationType
      }
      variabilityFactor
      area(unit: $areaUnit)
    }
    lastEvaluatedKey {
      PartitionKey
      SortKey
      TagString
    }
  }
}
