import React, { useContext, useMemo } from 'react';
import { Trans } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import Checkbox from '../../../../../../../components/Checkbox';
import { getParentSelectedValue } from '../../../../helpers/functions/parentSelection';
import { traverseTree } from '../../../../helpers/functions/tree';
import {
  DataLayersTreeNode,
  ModeConfig,
} from '../../../../types/dataLayersTree';
import DataLayersTreeContext from '../../context';

import './index.scss';

const getCheckboxValue = (checked: Record<string, number>): number => {
  const values = Object.values(checked);
  let result = 1;

  if (values.every((num) => num === 0)) {
    result = 0;
  } else if (values.every((num) => num === 2)) {
    result = 2;
  }

  return result;
};

const isNodeSelectable = (
  node: DataLayersTreeNode,
  mode: ModeConfig,
): boolean => {
  if (!node.selectable) {
    return false;
  }

  let result = false;
  let hasCheckbox;

  if (mode === 'default') {
    hasCheckbox = false;
  } else if (mode === 'multiSelect') {
    hasCheckbox = true;
  } else {
    hasCheckbox = mode[node.assetGroupType] === 'multiSelect';
  }

  if ((node.type === 'asset' || node.type === 'attribute') && hasCheckbox) {
    result = true;
  }

  return result;
};

export default function LayersSelectedPanel({
  checked,
  mode,
  onSelectAllChange,
}: {
  checked: Record<string, number>;
  mode: ModeConfig;
  onSelectAllChange: (v: Record<string, number>) => void;
}) {
  const dataLayersTree = useContext(DataLayersTreeContext);

  const selectableLeafsIds = useMemo(() => {
    const result: string[] = [];

    for (const node of traverseTree(dataLayersTree)) {
      if (isNodeSelectable(node, mode) && !node?.children) {
        result.push(node.id);
      }
    }

    return result;
  }, [dataLayersTree, mode]);

  const checkedLeafs = useMemo(
    () =>
      selectableLeafsIds.reduce<Record<string, number>>((acc, id) => {
        acc[id] = checked[id] || 0;

        return acc;
      }, {}),
    [checked, selectableLeafsIds],
  );

  const checkboxValue = useMemo(
    () => getCheckboxValue(checkedLeafs),
    [checkedLeafs],
  );

  const checkedAmount = useMemo(
    () => Object.values(checkedLeafs).filter((value) => value === 2).length,
    [checkedLeafs],
  );

  const handleCheckboxClick = (newValue: boolean) => {
    const update: Record<string, number> = {};

    const parentNodes = [];

    for (const node of traverseTree<DataLayersTreeNode>(dataLayersTree)) {
      if (node.children?.length && node.selectable) {
        parentNodes.push(node);
      }

      if (
        isNodeSelectable(node, mode) &&
        node.children?.[0]?.type !== 'placeholder'
      ) {
        update[node.id] = newValue ? 2 : 0;
      }
    }

    parentNodes.forEach((parentNode) => {
      update[parentNode.id] = getParentSelectedValue(parentNode, update);
    });

    onSelectAllChange(update);
  };

  return (
    <div className="layers-selected-panel">
      <Checkbox
        value={checkboxValue}
        onChange={(e) => handleCheckboxClick(e.target.checked)}
      />
      <Typography className="layers-selected-panel__label">
        <Trans i18nKey="field.assets.layers-selected">
          Text{' '}
          <span className="layers-selected-panel__label_primary">
            {{ selected: checkedAmount }}
          </span>
          Text{' '}
          <span className="layers-selected-panel__label_secondary">
            {{ all: selectableLeafsIds.length }}
          </span>
        </Trans>
      </Typography>
    </div>
  );
}
