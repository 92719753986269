import React, { ChangeEvent, ReactNode } from 'react';
import clsx from 'clsx';
import Switch from '@material-ui/core/Switch';
import FormControlLabel, {
  FormControlLabelProps,
} from '@material-ui/core/FormControlLabel';

import './index.scss';

export default function AntSwitch({
  isChecked = false,
  disabled,
  label = '',
  labelPlacement = 'start',
  classes = {},
  onValueChange = () => {},
}: {
  isChecked?: boolean;
  disabled?: boolean;
  label?: ReactNode;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  classes?: {
    root?: string;
    label?: string;
    switch?: {
      root?: string;
      base?: string;
      track?: string;
      checked?: string;
    };
  };
  onValueChange: (v: boolean) => void;
}) {
  const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isCurrentlyChecked = event?.target?.checked;

    onValueChange(isCurrentlyChecked);
  };

  return (
    <FormControlLabel
      classes={{
        root: clsx('ant-switch__control-root', classes?.root),
        label: clsx('ant-switch__control-label', classes?.label, {
          'ant-switch__control-label_start': labelPlacement === 'start',
          'ant-switch__control-label_end': labelPlacement === 'end',
        }),
      }}
      label={label}
      disabled={disabled}
      labelPlacement={labelPlacement}
      control={
        <Switch
          checked={isChecked}
          onChange={handleValueChange}
          classes={{
            root: clsx('ant-switch__element', classes?.switch?.root),
            switchBase: clsx(
              'ant-switch__element-switch-base',
              classes?.switch?.base,
            ),
            checked: clsx(
              'ant-switch__element-checked',
              classes?.switch?.checked,
            ),
            track: classes?.switch?.track,
          }}
        />
      }
    />
  );
}
