import React from 'react';

import getStepper from './config';
import { selectStepIndex, selectWorkflow } from '../../batchAnalyticsSelectors';
import useDidMount from '../../../../../hooks/useDidMount';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../app/store/helpers/functions';
import { reset } from '../../batchAnalyticsSlice';
import useResetWorkflow from '../../hooks/useResetWorkflow';

export default function StepperBatchAnalytics() {
  const dispatch = useAppDispatch();
  const workflow = useAppSelector(selectWorkflow);
  const stepIndex = useAppSelector(selectStepIndex);
  const Component = getStepper(stepIndex, workflow);
  const { resetWorkflow } = useResetWorkflow();

  useDidMount(() => () => {
    resetWorkflow();
    dispatch(reset());
  });

  return <Component />;
}
