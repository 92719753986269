query getReports($farmUuids: [ID], $fieldUuids: [ID], $reportUuids: [ID]) {
  getFarms(farmUuids: $farmUuids) {
    uuid
    fields(fieldUuids: $fieldUuids) {
      uuid
      reports(reportUuids: $reportUuids) {
        uuid
        url
      }
    }
  }
}
