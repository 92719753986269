import { reset } from '../../drawManuallyWorkflowSlice';
import getSteps from '../../helpers/functions/getSteps';
import { useAppDispatch } from '../../../../../app/store/helpers/functions';
import useStepComponent from '../../../zonesOps/hooks/useStepComponent';

export default function StepperDrawManually() {
  const dispatch = useAppDispatch();

  const stepComponent = useStepComponent({
    getStepsFn: getSteps,
    resetFn: () => {
      dispatch(reset());
    },
  });

  return stepComponent;
}
