import {
  isInteractableStatus,
  isPublishedStatus,
} from '../../../../../helpers/functions/entities/dataset';
import type { TransformedDataset } from '../../../../../helpers/types/dataset';

const isDatasetItemDisabled = (
  item: TransformedDataset,
  publishedDatasetsClickable = false,
) =>
  publishedDatasetsClickable
    ? !isInteractableStatus(item.status) && !isPublishedStatus(item.status)
    : !isInteractableStatus(item.status);

export default isDatasetItemDisabled;
