import { isDateMatch } from '.';
import { DefaultAssetGroupFilters } from '../../types/assetGroup';

export default function filterAssetGroupItems<
  T extends { name?: string; createdDate?: string },
>(filter: DefaultAssetGroupFilters, items: T[] = []) {
  const { nameFilterValue = '', createdFrom, createdTo } = filter || {};
  const preparedNameFilterValue = nameFilterValue
    ? nameFilterValue.toLowerCase()
    : '';

  return items.filter((item) => {
    const nameMatched =
      !nameFilterValue ||
      (item.name || '').toLowerCase().includes(preparedNameFilterValue);

    if (!item.createdDate) {
      return nameMatched;
    }

    return nameMatched && isDateMatch(item.createdDate, createdFrom, createdTo);
  });
}
