import TreeNodeEntity from './treeNodeEntity';
import { AnalysisMapType } from './vectorAnalysisMap';

export enum AssetLoadStatus {
  idle = 'idle',
  loading = 'loading',
  success = 'success',
  noData = 'noData',
  error = 'error',
}

export enum AssetType {
  farm = 'Farm',
  field = 'Field',
  satelliteImage = 'SatelliteImage',
  soilDataset = 'SoilDataset',
  yieldDataset = 'YieldDataset',
  asAppliedDataset = 'AsAppliedDataset',
  topographyMap = 'TopographyMap',
  threeDimensionalMap = 'ThreeDimensionalMap',
  equationMap = 'EquationMap',
  vectorAnalysisMap = 'VectorAnalysisMap',
  pinsGroup = 'PinsGroup',
}

export enum AssetGroupType {
  satelliteImages = 'satelliteImages',
  vectorAnalysisMaps = 'vectorAnalysisMaps',
  soilDatasets = 'soilDatasets',
  yieldDatasets = 'yieldDatasets',
  asAppliedDatasets = 'asAppliedDatasets',
  topographyMaps = 'topographyMaps',
  threeDimensionalMaps = 'threeDimensionalMaps',
  equationMaps = 'equationMaps',
  pinsGroups = 'pinsGroups',
}

export const ASSET_GROUP_TYPE_TO_TREE_NODE_ENTITY = {
  [AssetGroupType.satelliteImages]: TreeNodeEntity.fieldSatelliteImage,
  [AssetGroupType.vectorAnalysisMaps]: TreeNodeEntity.vectorAnalysisMap,
  [AssetGroupType.soilDatasets]: TreeNodeEntity.soilDataset,
  [AssetGroupType.yieldDatasets]: TreeNodeEntity.yieldDataset,
  [AssetGroupType.asAppliedDatasets]: TreeNodeEntity.asAppliedDataset,
  [AssetGroupType.topographyMaps]: TreeNodeEntity.elevation,
  [AssetGroupType.threeDimensionalMaps]: TreeNodeEntity.threeDimensionalMap,
  [AssetGroupType.equationMaps]: TreeNodeEntity.equationMap,
  [AssetGroupType.pinsGroups]: TreeNodeEntity.note,
} as const;

export const ASSET_TYPE_TO_ASSET_GROUP = {
  [AssetType.satelliteImage]: AssetGroupType.satelliteImages,
  [AssetType.soilDataset]: AssetGroupType.soilDatasets,
  [AssetType.yieldDataset]: AssetGroupType.yieldDatasets,
  [AssetType.asAppliedDataset]: AssetGroupType.asAppliedDatasets,
  [AssetType.topographyMap]: AssetGroupType.topographyMaps,
  [AssetType.threeDimensionalMap]: AssetGroupType.threeDimensionalMaps,
  [AssetType.vectorAnalysisMap]: AssetGroupType.vectorAnalysisMaps,
  [AssetType.equationMap]: AssetGroupType.equationMaps,
  [AssetType.pinsGroup]: AssetGroupType.pinsGroups,
  [AssetType.farm]: null,
  [AssetType.field]: null,
} as const;

export const ASSET_TYPE_TO_ANALYSIS_MAP_TYPE = {
  [AssetType.satelliteImage]: AnalysisMapType.satellite,
  [AssetType.soilDataset]: AnalysisMapType.soil,
  [AssetType.yieldDataset]: AnalysisMapType.yield,
  [AssetType.asAppliedDataset]: AnalysisMapType.asApplied,
  [AssetType.topographyMap]: AnalysisMapType.topography,
} as const;

export const ASSET_TYPE_TO_I18N_KEY = {
  [AssetType.satelliteImage]: 'general.shared.satellite-monitoring',
  [AssetType.soilDataset]: 'general.shared.soil-data',
  [AssetType.yieldDataset]: 'general.shared.yield-data',
  [AssetType.asAppliedDataset]: 'general.shared.as-applied-data',
  [AssetType.topographyMap]: 'general.shared.topography',
  [AssetType.threeDimensionalMap]: 'general.shared.3d-map',
  [AssetType.equationMap]: 'general.shared.equation-map',
  [AssetType.pinsGroup]: 'general.controls.pins.pins',
  [AssetType.farm]: 'general.shared.farm',
  [AssetType.field]: 'general.shared.field',
  [AssetType.vectorAnalysisMap]: 'general.shared.zones-map',
} as const;
