import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Button from '../../../../../components/Button';
import TextField from '../../../../../components/TextField';
import ButtonMenu from '../../../../../components/ButtonMenu';
import type { ButtonMenuItem } from '../../../../../components/ButtonMenu/types';

import './index.scss';

export default function VariablesEditor({
  dataVariables,
  resultVariable,
  onDataVariablesUpdate,
  onOutputVariableChange,
}: {
  dataVariables: string[];
  resultVariable: string;
  onDataVariablesUpdate: (v: string[]) => void;
  onOutputVariableChange: (v: string) => void;
}) {
  const { t } = useTranslation();

  const handleAddNewVariableClick = () => {
    onDataVariablesUpdate([...dataVariables, '']);
  };

  const handleDataVariableMenuItemClick = (
    { id }: ButtonMenuItem,
    index: number,
  ) => {
    let result = dataVariables;

    if (id === 'delete') {
      result = dataVariables.filter((_item, i) => index !== i);
    }

    onDataVariablesUpdate(result);
  };

  const handleVariableChange = (value: string, index: number) => {
    const result = dataVariables.map((variable, i) =>
      i === index ? value : variable,
    );

    onDataVariablesUpdate(result);
  };

  return (
    <div className="variables-editor">
      <div className="variables-editor__header">
        <Typography className="variables-editor__title">
          {t('zones-ops.equation-based.steps.4.panel.variables-list.title')}
        </Typography>
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddNewVariableClick}
        >
          {t(
            'zones-ops.equation-based.steps.4.panel.variables-list.add-new-variable',
          )}
        </Button>
      </div>
      {dataVariables.length === 0 ? (
        <Typography className="variables-editor__variables-placeholder">
          {t('batch-analytics.steps.equation.empty-variables')}
        </Typography>
      ) : (
        <div className="variables-editor__variables">
          {dataVariables.map((variable, index) => (
            <div key={index} className="variables-editor__variable">
              <TextField
                classes={{
                  wrapper: 'variables-editor__variable-textfield-wrapper',
                  title: 'variables-editor__variable-title',
                }}
                required
                title={t(
                  'zones-ops.equation-based.steps.4.panel.variables-list.variable-name',
                )}
                placeholder={t(
                  'zones-ops.equation-based.steps.4.panel.variables-list.item-placeholder',
                )}
                value={variable}
                onChange={(e: ChangeEvent<{ value: string }>) =>
                  handleVariableChange(e.target.value, index)
                }
              />
              <ButtonMenu
                classes={{
                  iconButton: {
                    root: 'variables-editor__variable-menu-button',
                  },
                }}
                isIconButton
                size="small"
                items={[{ id: 'delete', label: t('general.controls.delete') }]}
                onItemClick={(item) =>
                  handleDataVariableMenuItemClick(item, index)
                }
              >
                <MoreVertIcon fontSize="small" />
              </ButtonMenu>
            </div>
          ))}
        </div>
      )}
      <Typography className="variables-editor__title variables-editor__output-variable-title">
        {t('zones-ops.equation-based.steps.4.panel.output-variable-title')}
        <span className="required-asterisk">*</span>
      </Typography>
      <TextField
        classes={{
          wrapper: 'variables-editor__output-variable-textfield-wrapper',
        }}
        placeholder={t(
          'zones-ops.equation-based.steps.4.panel.output-variable-placeholder',
        )}
        value={resultVariable}
        onChange={(e: ChangeEvent<{ value: string }>) =>
          onOutputVariableChange(e.target.value)
        }
      />
    </div>
  );
}
