import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import type { DatasetAction } from '../../../../../types/actions';
import Button from '../../../../../../../../components/Button';
import {
  selectFieldUuid,
  selectYieldDataset,
} from '../../../../../../../field/fieldSelectors';
import { useCleanCalibrateYieldDatasetMutation } from '../../../../../cleanCalibrateAPI';
import {
  selectDatasetUuid,
  selectSequence,
  selectStep,
} from '../../../../../cleanCalibrateSelectors';
import { setStep } from '../../../../../cleanCalibrateSlice';
import { Sequence } from '../../../../../helpers/constants/ui';
import { useAppSelector } from '../../../../../../../../app/store/helpers/functions';
import useCleanParams from '../../../../../hooks/useCleanParams';
import useCalibrateParams from '../../../../../hooks/useCalibrateParams';

const LAST_STEP = 3;

export default function CalibrateCleanTabRightActions({
  onCancel,
}: {
  onCancel: () => void;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [cleanCalibrateYieldDataset] = useCleanCalibrateYieldDatasetMutation();
  const step = useAppSelector(selectStep);
  const sequence = useAppSelector(selectSequence);
  const fieldUuid = useAppSelector(selectFieldUuid);
  const datasetUuid = useAppSelector(selectDatasetUuid);
  const dataset = useAppSelector((state) =>
    selectYieldDataset(state, datasetUuid),
  );
  const calibrateParams = useCalibrateParams();
  const cleanParams = useCleanParams();

  const stepParametersValid = useMemo(() => {
    let result = true;

    if (step === LAST_STEP - 1) {
      if (sequence === Sequence.calibrateFirst) {
        result = !!calibrateParams;
      } else {
        result = !!cleanParams;
      }
    } else if (step === LAST_STEP) {
      if (sequence === Sequence.calibrateFirst) {
        result = !!cleanParams;
      } else {
        result = !!calibrateParams;
      }
    }

    return result;
  }, [sequence, step, calibrateParams, cleanParams]);

  const handleRun = async () => {
    if (!calibrateParams || !cleanParams || !fieldUuid || !dataset?.uuid) {
      return;
    }

    const operationsSequence: DatasetAction[] =
      sequence === Sequence.calibrateFirst
        ? ['CALIBRATE', 'CLEAN']
        : ['CLEAN', 'CALIBRATE'];

    await cleanCalibrateYieldDataset({
      fieldUuid,
      yieldDatasetUuid: dataset.uuid,
      actions: {
        actions: operationsSequence,
        calibrateAction: calibrateParams,
        cleanAction: cleanParams,
      },
    });

    onCancel();
  };

  const handleProceed = () => {
    dispatch(setStep(step + 1));
  };

  const handleBack = () => {
    dispatch(setStep(step - 1));
  };

  return (
    <>
      {step !== 0 ? (
        <Button variant="outlined" color="primary" onClick={handleBack}>
          {t('general.stepper.back')}
        </Button>
      ) : (
        <Button variant="outlined" color="primary" onClick={onCancel}>
          {t('general.controls.cancel')}
        </Button>
      )}

      {step !== LAST_STEP ? (
        <Button
          variant="contained"
          color="primary"
          disabled={!stepParametersValid}
          onClick={handleProceed}
        >
          {t('general.controls.proceed')}
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          disabled={!stepParametersValid}
          onClick={handleRun}
        >
          {t('general.controls.run')}
        </Button>
      )}
    </>
  );
}
