import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Loading from '../../../../../../components/Loading';
import GeneralPopup from '../../../../../../components/Popups/GeneralPopup';
import useDataLayersMapConfig from '../../../../../../hooks/useDataLayersMapConfig';

import Panel from './Panel';
import ToolsPanel from './ToolsPanel';
import { useAppSelector } from '../../../../../../app/store/helpers/functions';
import {
  selectApiKey,
  selectAreaUnit,
} from '../../../../../user/userSelectors';
import { selectField } from '../../../../../field/fieldSelectors';

import './index.scss';

const Map = React.lazy(() => import('./Map'));

const AddDataLayersPopup = ({ onCancel = () => {}, onConfirm = () => {} }) => {
  const { t } = useTranslation();
  const field = useAppSelector(selectField);
  const apiKey = useAppSelector(selectApiKey);
  const areaUnit = useAppSelector(selectAreaUnit);
  const [selectedItem, setSelectedItem] = useState();

  const { onViewTypeChange, onDatasetViewTypeChange, ...mapProps } =
    useDataLayersMapConfig({
      selectedItem,
      field,
      apiKey,
      areaUnit,
    });

  const handleAssetItemClick = (item) => {
    setSelectedItem(item);
  };

  return (
    <GeneralPopup
      title={t('compare-layers.title')}
      classes={{
        paper: 'compare-layers-popup',
      }}
      withActions={false}
      onCancel={onCancel}
    >
      <ToolsPanel key="tools-panel" />
      <Panel
        key="panel"
        selectedItem={selectedItem}
        onAssetItemClick={handleAssetItemClick}
        onViewTypeChange={onViewTypeChange}
        onDatasetViewTypeChange={onDatasetViewTypeChange}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
      <Suspense key="map" fallback={<Loading />}>
        <Map
          {...mapProps}
          selectedItem={selectedItem}
          onViewTypeChange={onViewTypeChange}
          onDatasetViewTypeChange={onDatasetViewTypeChange}
          onCancel={onCancel}
        />
      </Suspense>
    </GeneralPopup>
  );
};

export default AddDataLayersPopup;
