import type { ReactElement, ReactNode } from 'react';

import { isNumber } from './functions/utils/number';

export const getComponent = (
  key: string,
  children: ReactElement<Record<string, string>> | ReactElement[],
): ReactNode => {
  let result: ReactNode;

  if (Array.isArray(children)) {
    result =
      children.flat().filter((comp) => comp && comp.key === key)[0] || null;
  } else if ('key' in children) {
    result = children.key === key ? children : null;
  }

  return result;
};

/**
 * Transform number string (e.g. '123456.78') to the formatted one (e.g. '123 456.78').
 * Returns `null` if input string is not a decimal (decimal part is optional).
 *
 * @param {String} value number string to be formatted
 * @returns {String | null} formatted string
 */
export const convertNumberToFormattedString = (value = ''): string | null => {
  const decimalRegexp = /^-?\d+(\s\d+)*?\.?(\.\d+)?$/;

  if (value === '') {
    return value;
  }

  if (decimalRegexp.test(value)) {
    const [integerPart, decimalPart] = value.split('.');
    const integerPartStringWithoutSpaces = integerPart.replace(
      /[^-?0-9.]/g,
      '',
    );
    const formattedIntegerPartString = parseInt(
      integerPartStringWithoutSpaces,
      10,
    ).toLocaleString('ru-RU');

    if (typeof decimalPart === 'string') {
      return `${formattedIntegerPartString}.${decimalPart}`;
    }

    return formattedIntegerPartString;
  }

  return null;
};

export const prettifyNumber = (value?: number | string, digits = 2) => {
  if (value == null) {
    return '';
  }

  const number = isNumber(value) ? value : parseFloat(value);

  if (Number.isNaN(value)) {
    return '';
  }
  const isInteger = Number.isInteger(number);
  const digitsAfterDot = isInteger ? 0 : digits;

  return convertNumberToFormattedString(number.toFixed(digitsAfterDot)) ?? '';
};

export const convertStringToNumber = (value: string | number): number => {
  let result: number;

  if (typeof value === 'string') {
    const stringWithoutSpaces = value.replace(/[^0-9.]/g, '');
    result = parseFloat(stringWithoutSpaces) || 0;
  } else {
    result = value;
  }

  return result;
};

export const remapNameToTitleSingle = ({
  name,
  ...entity
}: {
  name?: string;
}) => ({
  ...entity,
  name,
  title: name,
});

export const remapNameToTitle = (arr: { name?: string }[]) =>
  arr.map(remapNameToTitleSingle);
