import { createSelector } from '@reduxjs/toolkit';

import { getAuthenticationRequiredGroups } from '../../../helpers/functions/utils/appConfig';
import {
  selectCognitoGroups,
  selectLastPaidOrder,
} from '../../user/userSelectors';
import calculateApplicationStatus from './helpers/functions/calculateApplicationStatus';

export const selectHasRequiredGroups = createSelector(
  selectCognitoGroups,
  (groups) =>
    getAuthenticationRequiredGroups().every((group) => groups.includes(group)),
);

export const selectApplicationStatus = createSelector(
  selectLastPaidOrder,
  selectHasRequiredGroups,
  (lastPaidOrder, hasGroupPermission) =>
    calculateApplicationStatus(lastPaidOrder, hasGroupPermission),
);
