import React, { ChangeEvent, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import BackdropLoading from '../../../../../components/Loading/BackdropLoading';
import PopupHeader from '../../../../../components/Popups/PopupHeader';
import Button from '../../../../../components/Button';
import TextField from '../../../../../components/TextField';
import Textarea from '../../../../../components/Textarea';

import './index.scss';

export default function SaveEquationPopup({
  initialValues,
  popup: { title: popupTitle, description: popupDescription },
  inProgress = false,
  onCancel = () => {},
  onConfirm = () => {},
}: {
  initialValues?: {
    title: string;
    description: string;
    sourceUrl: string;
  };
  popup: {
    title: string;
    description: ReactNode;
  };
  inProgress: boolean;
  onCancel: () => void;
  onConfirm: (t: string, d: string, s: string) => void;
}) {
  const { t } = useTranslation();

  const [title, setTitle] = useState(initialValues?.title || '');
  const [description, setDescription] = useState(
    initialValues?.description || '',
  );
  const [sourceUrl, setSourceUrl] = useState(initialValues?.sourceUrl || '');
  const saveButtonDisabled = !title.trim();

  const handleSaveClick = () => {
    onConfirm(title, description, sourceUrl);
  };

  const handleTitleChange = (e: ChangeEvent<{ value: string }>) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (value: string) => {
    setDescription(value);
  };

  const handleSourceUrlChange = (e: ChangeEvent<{ value: string }>) => {
    setSourceUrl(e.target.value);
  };

  return (
    <Dialog
      open
      classes={{
        paper: 'save-equation-popup__paper',
      }}
    >
      <PopupHeader title={popupTitle} onCancel={onCancel} />
      <DialogContent>
        <DialogContentText
          classes={{
            root: 'save-equation-popup__content-text',
          }}
        >
          {popupDescription}
        </DialogContentText>
        <TextField
          classes={{
            wrapper: 'save-equation-popup__field',
          }}
          required
          title={t('zones-ops.common.title')}
          value={title}
          placeholder={t('general.popups.save-equation.title-placeholder')}
          onChange={handleTitleChange}
        />
        <Textarea
          classes={{
            wrapper: 'save-equation-popup__field',
            element: 'save-equation-popup__description-element',
          }}
          header={{
            title: t('general.shared.description'),
          }}
          value={description}
          placeholder={t(
            'general.popups.save-equation.description-placeholder',
          )}
          onChange={handleDescriptionChange}
        />
        <TextField
          classes={{
            wrapper: 'save-equation-popup__field',
          }}
          title={t('zones-ops.equation-based.steps.4.panel.source-url')}
          value={sourceUrl}
          placeholder={t('general.popups.save-equation.source-url-placeholder')}
          onChange={handleSourceUrlChange}
        />
      </DialogContent>
      <DialogActions
        classes={{
          root: 'save-equation-popup__actions',
        }}
      >
        <Button onClick={onCancel} variant="outlined">
          {t('general.controls.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={saveButtonDisabled}
          onClick={handleSaveClick}
        >
          {t('general.controls.save')}
        </Button>
      </DialogActions>
      {inProgress && <BackdropLoading />}
    </Dialog>
  );
}
