import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function Cloud23Icon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="m15.87106,7.29434c0.74465,0.76756 1.26394,1.85066 1.51894,3.13566l0.3,1.5l1.53,0.11c1.56,0.1 2.78,1.41 2.78,2.96c0,1.65 -1.35,3 -3,3c-1.06435,-0.02831 -2.05565,-0.05662 -3.12,-0.08493m-3.88,-13.91507c-2.89,0 -5.4,1.64 -6.65,4.04c-3.01,0.32 -5.35,2.87 -5.35,5.96c0,3.31 2.69,6 6,6l13,0c2.76,0 5,-2.24 5,-5c0,-2.64 -2.05,-4.78 -4.65,-4.96c-0.68,-3.45 -3.71,-6.04 -7.35,-6.04z" />
    </SvgIcon>
  );
}
