import {
  CREATE_ANALYSIS_TOPOGRAPHY_MAP_ATTRIBUTES,
  DEFAULT_TOPOGRAPHY_MAP_ATTRIBUTES,
  ATTRIBUTE_LABELS_MAP,
} from '../../constants/entities/topographyMap';
import { GeoMap } from '../../types/api';
import { FullAttribute, TransformedTopographyMap } from '../../types/dataset';
import { comparator } from '../utils/string';
import { getFullAttribute } from './dataset';

export const convertAttributeToGeoMapName = (
  attribute: string,
  isThumbnail?: boolean,
) => {
  let result = attribute;
  const prefix = isThumbnail ? 'thumbnail_' : '';

  if (attribute === 'dem') {
    result = 'elevation';
  }

  return `${prefix}${result}`;
};

/**
 * Extracts the attributes from a topography map that are associated with geo maps.
 *
 * @param {TransformedTopographyMap} topographyMap The transformed topography map with geo maps.
 * @returns {string[]} An array of attributes associated with geo maps.
 */
export const extractTopographyMapGeoMapAttributes = ({
  geoMaps = [],
}: TransformedTopographyMap): string[] => {
  const geoMapsNames = geoMaps.map(({ shortName }) => shortName);

  return CREATE_ANALYSIS_TOPOGRAPHY_MAP_ATTRIBUTES.filter((attribute) =>
    geoMapsNames.includes(convertAttributeToGeoMapName(attribute)),
  );
};

export const addAttributesAndSortTopographyMaps = (
  topographyMaps: TransformedTopographyMap[],
) =>
  (topographyMaps || [])
    .map((topographyMap) => ({
      ...topographyMap,
      attributes: extractTopographyMapGeoMapAttributes(topographyMap),
    }))
    .sort((a, b) => comparator(a.name || '', b.name || ''));

export const getTopographyMapViewProps = (
  topographyMap: TransformedTopographyMap,
  attribute?: string | null,
  onlyAnalysisAttributes?: boolean,
) => {
  const attributes = onlyAnalysisAttributes
    ? CREATE_ANALYSIS_TOPOGRAPHY_MAP_ATTRIBUTES
    : DEFAULT_TOPOGRAPHY_MAP_ATTRIBUTES;
  const geoMapsNamesSet = new Set(
    (topographyMap.geoMaps || []).map((geoMap) => geoMap.shortName),
  );
  const attributesWithGeoMaps = attributes.filter((attr) =>
    geoMapsNamesSet.has(convertAttributeToGeoMapName(attr)),
  );
  const isSelectedGeoMapExist = geoMapsNamesSet.has(
    convertAttributeToGeoMapName(attribute || ''),
  );
  let selectedAttribute: string;

  if (!isSelectedGeoMapExist) {
    const [attributeWithGeoMap] = attributesWithGeoMaps;
    selectedAttribute = attributeWithGeoMap;
  } else {
    selectedAttribute = attribute || '';
  }

  return {
    attributes: attributesWithGeoMaps,
    attribute: selectedAttribute,
  };
};

export const getTopographyMapAttributeName = (
  attribute: string,
  fullAttributes?: FullAttribute[] | null,
) =>
  getFullAttribute(attribute, fullAttributes)?.fullName ||
  ATTRIBUTE_LABELS_MAP[attribute as keyof typeof ATTRIBUTE_LABELS_MAP] ||
  attribute;

export const getTopographyMapAttributeNameHandler =
  (fullAttributes?: FullAttribute[] | null) => (attribute: string) =>
    getTopographyMapAttributeName(attribute, fullAttributes);

export const getTopographyMapThumbnailGeoMap = ({
  geoMaps,
  attribute,
}: {
  geoMaps: GeoMap[] | undefined;
  attribute: string;
}): GeoMap | null => {
  const geoMapName = convertAttributeToGeoMapName(attribute, true);
  const geoMap = (geoMaps || []).find(
    ({ shortName }) => shortName === geoMapName,
  );

  return geoMap || null;
};
