import React, { KeyboardEvent, MouseEvent, ReactNode } from 'react';
import clsx from 'clsx';

import { ListNode } from '../../../types/node';
import { isEnterKeyPressedOnCurrentTarget } from '../../../../../../helpers/functions/utils/navigation';
import Checkbox from '../../../../../../components/Checkbox';

import './index.scss';

export default function CheckboxItem<T extends ListNode>({
  itemNode,
  isSelected,
  isChecked,
  disabled,
  children,
  onClick,
  onCheckboxClick,
}: {
  itemNode: T;
  isSelected: boolean;
  isChecked: boolean;
  disabled?: boolean;
  children: ReactNode;
  onClick?: (itemNode: T | null) => void;
  onCheckboxClick: (v: boolean, item: T) => void;
}) {
  const handleClick = () => {
    if (disabled) {
      return;
    }

    onClick?.(itemNode);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (!disabled && isEnterKeyPressedOnCurrentTarget(event)) {
      onClick?.(itemNode);
    }
  };

  const handleCheckboxClick = (e: MouseEvent) => {
    e.stopPropagation();

    const newValue = !isChecked;
    onCheckboxClick(newValue, itemNode);
  };

  const itemButtonProps =
    onClick && !disabled
      ? { onClick: handleClick, onKeyDown: handleKeyDown, tabIndex: 0 }
      : {};

  return (
    <div className={clsx('list-checkbox-item__wrapper')}>
      <div
        role="button"
        className={clsx('list-checkbox-item', {
          'list-checkbox-item_selected': isSelected,
          'list-checkbox-item_disabled': disabled,
          'list-checkbox-item_clickable': !!onClick && !disabled,
        })}
        {...itemButtonProps}
      >
        <Checkbox
          value={isChecked ? 2 : 0}
          disabled={disabled}
          onClick={handleCheckboxClick}
        />
        {children}
      </div>
    </div>
  );
}
