import { DefaultTableRow, TableRow } from '../../types/row';

type MaybeSelectableRow = TableRow & { selected?: boolean };
type SelectableRow = TableRow & { selected: boolean };

export const getRowsSelection = (rows: MaybeSelectableRow[]): number => {
  const selectableRows = rows.filter(
    (row): row is SelectableRow => 'selected' in row,
  );
  const { partialRowsSelection, fullRowsSelection } = selectableRows.reduce(
    (acc, { selected }) => ({
      partialRowsSelection: acc.partialRowsSelection || selected,
      fullRowsSelection: acc.fullRowsSelection && selected,
    }),
    {
      partialRowsSelection: false,
      fullRowsSelection: true,
    },
  );
  let rowsSelection = 0;

  if (fullRowsSelection) {
    rowsSelection = 2;
  } else if (partialRowsSelection) {
    rowsSelection = 1;
  }

  return rowsSelection;
};

export const isDefaultTableRow = (
  row: TableRow | null,
): row is DefaultTableRow => row?.rowType === 'default';

export const isRowExpanded = <T extends { rowType: string; id: string }>(
  row: T,
  expandedRows: Record<string, boolean>,
  allExpanded: boolean,
) => (row.rowType === 'nested' ? (expandedRows[row.id] ?? allExpanded) : false);
