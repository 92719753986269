import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from '../../../../../../../components/Tooltip';
import { prettifyNumber } from '../../../../../../../helpers/markup';

import './index.scss';

const getCloudiness = (cloudFreeLand: number): string => {
  const cloudiness = 1 - cloudFreeLand;

  return prettifyNumber(cloudiness * 100);
};

export default function CloudinessTooltip({
  hasNdvi,
  cloudFreeLand,
  children,
}: {
  hasNdvi: boolean;
  cloudFreeLand: number;
  children: ReactElement;
}) {
  const { t } = useTranslation();
  const cloudiness = getCloudiness(cloudFreeLand);

  return (
    <Tooltip
      classes={{
        label: 'cloudiness-tooltip__label',
        tooltip: 'cloudiness-tooltip__tooltip',
      }}
      placement="right"
      tooltip={
        <>
          <p className="cloudiness-tooltip__tooltip-line cloudiness-tooltip__tooltip-line_highlight">
            {t(
              'field.assets.satellite-image-items.cloudiness-tooltip.cloudiness',
              { cloudiness },
            )}
          </p>
          {hasNdvi ? null : (
            <p className="cloudiness-tooltip__tooltip-line">
              {t(
                'field.assets.satellite-image-items.cloudiness-tooltip.too-high-value',
              )}
            </p>
          )}
        </>
      }
    >
      {children}
    </Tooltip>
  );
}
