export enum TileSize {
  small = 128,
  medium = 256,
  large = 512,
  xLarge = 1024,
}

export enum MapProvider {
  mapbox = 'mapbox',
  bing = 'bing',
}
