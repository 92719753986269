import React from 'react';
import { Trans } from 'react-i18next';

import './index.scss';

const TopographyIndexesTooltip = () => (
  <div className="topography-indexes-tooltip">
    <ul className="list">
      <li>
        <Trans i18nKey="zones-ops.common.topography-indexes-tooltip.dem-description">
          - <span className="text_primary">DEM</span> represents absolute
          height;
        </Trans>
      </li>
      <li>
        <Trans i18nKey="zones-ops.common.topography-indexes-tooltip.hillshade-description">
          - <span className="text_primary">Hillshade</span> is a shaded relief
          effect;
        </Trans>
      </li>
      <li>
        <Trans i18nKey="zones-ops.common.topography-indexes-tooltip.slope-description">
          - <span className="text_primary">Slope</span> is the angle of
          inclination to the horizontal in percent;
        </Trans>
      </li>
      <li>
        <Trans i18nKey="zones-ops.common.topography-indexes-tooltip.aspect-description">
          - <span className="text_primary">Aspect</span> is the compass
          direction that a slope faces;
        </Trans>
      </li>
      <li>
        <Trans i18nKey="zones-ops.common.topography-indexes-tooltip.relief-position-description">
          - <span className="text_primary">Relief Position</span> marks spots
          with local deprecations (lowlands) and hills (highlands);
        </Trans>
      </li>
      <li>
        <Trans i18nKey="zones-ops.common.topography-indexes-tooltip.ruggedness-description">
          - <span className="text_primary">Ruggedness</span> describes the slope
          in absolute numbers for every particular location;
        </Trans>
      </li>
      <li>
        <Trans i18nKey="zones-ops.common.topography-indexes-tooltip.roughness-description">
          - <span className="text_primary">Roughness</span> measures the maximum
          absolute value of the slope in meters for every location.
        </Trans>
      </li>
    </ul>
  </div>
);

export default TopographyIndexesTooltip;
