import { API, graphqlOperation } from '@aws-amplify/api';

import findPlanetMasksMutation from './graphql/mutations/findPlanetMasks.gql';

export const getMetadata = async ({ fieldUuid, startDate, endDate }) =>
  API.graphql(
    graphqlOperation(findPlanetMasksMutation, {
      input: {
        fieldUuid,
        startDate: startDate.toISOString().substring(0, 10),
        endDate: endDate.toISOString().substring(0, 10),
      },
    }),
  )
    .then(({ data: { findPlanetMasks } }) => {
      const { statusCode, statusMessage, expectedNumberOfImages } =
        findPlanetMasks;

      return {
        amount: expectedNumberOfImages,
        statusCode,
        statusMessage,
      };
    })
    .catch((errorResp) => ({
      errors: errorResp.errors,
    }));

export default { getMetadata };
