import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18next';

import {
  EXPORT_ASSET_TYPE,
  MAX_ASSETS_COUNT,
} from './helpers/constants/assets';
import { warningNotify } from '../../notifications/helpers/functions/notify';
import { reset, setupLegend } from '../../exportData/exportDataSlice';
import { selectSelectedAssets } from './downloadFilesSelectors';

const initialState = {
  legend: {
    chartScale: null,
    asset: {
      farmUuid: null,
      fieldUuid: null,
      uuid: null,
      type: null,
    },
    loading: false,
  },
  selectedAssets: {
    [EXPORT_ASSET_TYPE.fieldBoundary]: [],
    [EXPORT_ASSET_TYPE.zonesMap]: [],
    [EXPORT_ASSET_TYPE.pinsGroup]: [],
  },
};

export const toggleAsset = (uuid, assetType) => (dispatch, getState) => {
  const state = getState();
  const selectedAssets = selectSelectedAssets(state);
  const uuids = selectedAssets[assetType];

  if (uuids.includes(uuid)) {
    dispatch(
      unselectAsset({
        uuid,
        assetType,
      }),
    );
  } else if (uuids.length + 1 > MAX_ASSETS_COUNT) {
    warningNotify({
      message: i18n.t('export.notifications.max-assets-reached'),
    });
  } else {
    dispatch(
      setSelectedAsset({
        uuid,
        assetType,
      }),
    );
  }
};

export const downloadFilesSlice = createSlice({
  name: 'downloadFiles',
  initialState,
  reducers: {
    setSelectedAsset(state, action) {
      state.selectedAssets[action.payload.assetType] = [
        ...state.selectedAssets[action.payload.assetType],
        action.payload.uuid,
      ];
    },
    unselectAsset(state, action) {
      state.selectedAssets[action.payload.assetType] = state.selectedAssets[
        action.payload.assetType
      ].filter((uuid) => uuid !== action.payload.uuid);
    },
    resetLegendAsset(state) {
      state.legend.asset = initialState.legend.asset;
    },
    setLegendChartScale(state, action) {
      state.legend.chartScale = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setupLegend.pending, (state, action) => {
      state.legend.loading = true;
      state.legend.asset.farmUuid = action.meta.arg.farmUuid;
      state.legend.asset.fieldUuid = action.meta.arg.fieldUuid;
      state.legend.asset.uuid = action.meta.arg.uuid;
      state.legend.asset.type = action.meta.arg.type;
    });
    builder
      .addCase(setupLegend.fulfilled, (state) => {
        state.legend.loading = false;
      })
      .addCase(reset, () => initialState);
  },
});

export const {
  setSelectedAsset,
  unselectAsset,
  resetLegendAsset,
  setLegendChartScale,
} = downloadFilesSlice.actions;

export default downloadFilesSlice.reducer;
