import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import ComboBox, { ComboBoxProps } from '../../../../../components/ComboBox';
import type { Label } from '../../../../field/types/field';

import '../common/index.scss';

const getOptionSelected: ComboBoxProps<
  string,
  undefined,
  undefined
>['getOptionSelected'] = (option, value) => option.value === value.value;

export default function SelectClientFarmField({
  fieldLabels = [],
  fieldName,
  farmName,
  client,
  farm,
  field,
  onClientChangeHandler,
  onFarmChangeHandler,
  onFieldChangeHandler,
}: {
  fieldLabels: Label[];
  fieldName: string;
  farmName: string;
  client: string;
  farm: string;
  field: string;
  classes?: {
    root?: string;
  };
  onClientChangeHandler: (v: string) => void;
  onFarmChangeHandler: (v: string) => void;
  onFieldChangeHandler: (v: string) => void;
}) {
  const { t } = useTranslation();

  const availableOptions = useMemo(() => {
    const labelsSet = new Set(
      fieldLabels.map(({ value }: { value: string }) => value),
    );
    return [...labelsSet.values()].map((value: string) => ({
      title: value,
      value,
    }));
  }, [fieldLabels]);
  const availableFarmOptions = useMemo(
    () =>
      availableOptions.find(
        (availableOption) => availableOption.value === farmName,
      )
        ? availableOptions
        : [...availableOptions, { title: farmName, value: farmName }],
    [farmName, availableOptions],
  );
  const availableFieldOptions = useMemo(
    () =>
      availableOptions.find(
        (availableOption) => availableOption.value === fieldName,
      )
        ? availableOptions
        : [...availableOptions, { title: fieldName, value: fieldName }],
    [fieldName, availableOptions],
  );
  const selectedClient =
    availableOptions.find(({ value }) => value === client) || null;
  const selectedFarm =
    availableFarmOptions.find(({ value }) => value === farm) || null;
  const selectedField =
    availableFieldOptions.find(({ value }) => value === field) || null;

  return (
    <div className="export-to-john-deere-popup__content">
      <p className="export-to-john-deere-popup__content-description">
        <Trans i18nKey="general.popups.export-to-john-deere.description-select-client-farm-field">
          text
          <a
            className="link"
            target="_blank"
            href="https://docs.geopard.tech/geopard-tutorials/product-tour-web-app/getting-started/add-a-label"
            rel="noreferrer"
          >
            link
          </a>
          text
        </Trans>
      </p>
      <ComboBox
        title={t('general.popups.export-to-john-deere.label-client')}
        disableCloseOnSelect={false}
        placeholder={t('general.controls.select')}
        options={availableOptions}
        classes={{
          root: 'export-to-john-deere-popup__content-select',
        }}
        getOptionSelected={getOptionSelected}
        value={selectedClient}
        onChange={(_event, newClient) => {
          onClientChangeHandler(newClient ? newClient.title : '');
        }}
      />
      <ComboBox
        title={t('general.popups.export-to-john-deere.label-farm')}
        disableCloseOnSelect={false}
        placeholder={t('general.controls.select')}
        options={availableFarmOptions}
        classes={{
          root: 'export-to-john-deere-popup__content-select',
        }}
        getOptionSelected={getOptionSelected}
        value={selectedFarm}
        onChange={(_event, newFarm) => {
          onFarmChangeHandler(newFarm ? newFarm.title : '');
        }}
      />
      <ComboBox
        title={t('general.popups.export-to-john-deere.label-field')}
        disableCloseOnSelect={false}
        placeholder={t('general.controls.select')}
        options={availableFieldOptions}
        classes={{
          root: 'export-to-john-deere-popup__content-select',
        }}
        getOptionSelected={getOptionSelected}
        value={selectedField}
        onChange={(_event, newField) => {
          onFieldChangeHandler(newField ? newField.title : '');
        }}
      />
    </div>
  );
}
