query getAssetsStatus(
  $farmUuids: [ID]!
  $fieldUuids: [ID]!
  $soilDatasetUuids: [ID]
  $yieldDatasetUuids: [ID]
  $asAppliedDatasetUuids: [ID]
  $checkSoil: Boolean!
  $checkYield: Boolean!
  $checkAsApplied: Boolean!
) {
  getFarms(farmUuids: $farmUuids) {
    uuid
    fields(fieldUuids: $fieldUuids) {
      uuid
      status
      statusMessage
      soilDatasets(soilDatasetUuids: $soilDatasetUuids)
        @include(if: $checkSoil) {
        uuid
        status
      }
      yieldDatasets(yieldDatasetUuids: $yieldDatasetUuids)
        @include(if: $checkYield) {
        uuid
        status
      }
      asAppliedDatasets(asAppliedDatasetUuids: $asAppliedDatasetUuids)
        @include(if: $checkAsApplied) {
        uuid
        status
      }
    }
  }
}
