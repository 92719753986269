import { API, graphqlOperation } from '@aws-amplify/api';

import {
  transformEquationMaps,
  transformVectorAnalysisMaps,
} from '../field/helpers/functions/assets';
import getGeneratedVectorAnalysisMapsQuery from './graphql/queries/getGeneratedVectorAnalysisMaps.gql';
import getGeneratedEquationMapsQuery from './graphql/queries/getGeneratedEquationMaps.gql';
import generateVectorAnalysisMapMutation from './graphql/mutations/generateVectorAnalysisMap.gql';
import generateSoilVectorAnalysisMapMutation from './graphql/mutations/generateSoilVectorAnalysisMap.gql';
import generateYieldVectorAnalysisMapMutation from './graphql/mutations/generateYieldVectorAnalysisMap.gql';
import generateAsAppliedVectorAnalysisMapMutation from './graphql/mutations/generateAsAppliedVectorAnalysisMap.gql';
import generateTopographyVectorAnalysisMapMutation from './graphql/mutations/generateTopographyVectorAnalysisMap.gql';
import generateMultiLayersVectorAnalysisMapMutation from './graphql/mutations/generateMultiLayersVectorAnalysisMap.gql';
import executeZonesOperationMutation from './graphql/mutations/executeZonesOperation.gql';
import generateEquationMapQuery from './graphql/mutations/generateEquationMap.gql';
import setGeoMapsMutation from './graphql/mutations/setGeoMaps.gql';
import saveNewVectorAnalysisMapMutation from './graphql/mutations/saveNewVectorAnalysisMap.gql';
import { AreaUnit } from '../user/helpers/constants/user';
import type {
  DataLayerInput,
  ExecuteZonesOperationResponse,
  GenerateAsAppliedVectorAnalysisMapResponse,
  GenerateEquationMapArg,
  GenerateEquationMapResponse,
  GenerateMultiLayersVectorAnalysisMapResponse,
  GenerateSoilVectorAnalysisMapResponse,
  GenerateTopographyVectorAnalysisMapResponse,
  GenerateVectorAnalysisMapResponse,
  GenerateYieldVectorAnalysisMapResponse,
  GetGeneratedEquationMapsResponse,
  GetGeneratedVamapsResponse,
  SetGeoMapsMutationResponse,
  SaveNewVectorAnalysisMapMutationResponse,
  ZonesOperationMap,
} from './types/api';
import type { GenerateVectorAnalysisMapSettings } from './types/settings';
import {
  VectorAnalysisMapType,
  ZonesOperationType,
} from '../../helpers/constants/entities/vectorAnalysisMap';
import { CustomError } from '../../helpers/functions/utils/errorHandling';
import { ZonesMapGeoJson } from '../../helpers/types/vectorAnalysisMap/geojson';

export const saveNewVectorAnalysisMap = async ({
  fieldUuid,
  name,
  type,
  zonesMapGeojson,
  hexColorCodes,
  areaUnit,
}: {
  fieldUuid: string;
  name: string;
  type: VectorAnalysisMapType;
  zonesMapGeojson: ZonesMapGeoJson<number>;
  hexColorCodes: string[];
  areaUnit: AreaUnit;
}) => {
  const response = (await API.graphql(
    graphqlOperation(saveNewVectorAnalysisMapMutation, {
      input: {
        fieldUuid,
        name,
        zonesMapGeojson: JSON.stringify(zonesMapGeojson),
        type,
      },
      areaUnit,
    }),
  )) as SaveNewVectorAnalysisMapMutationResponse;

  const vectorAnalysisMap = response.data?.saveVectorAnalysisMap;
  const vectorAnalysisMapUuid = vectorAnalysisMap?.uuid;

  if (!vectorAnalysisMapUuid) {
    throw new CustomError('[Create Analysis] vectorAnalysisMapUuid is empty.');
  }

  const geoMapsResponse = (await API.graphql(
    graphqlOperation(setGeoMapsMutation, {
      input: {
        geoMaps: [
          {
            shortName: 'zones',
            fieldUuid,
            vectorAnalysisMapUuid,
            hexColorCodes,
          },
          {
            shortName: 'thumbnail',
            fieldUuid,
            vectorAnalysisMapUuid,
            hexColorCodes,
          },
        ],
      },
    }),
  )) as SetGeoMapsMutationResponse;

  const geoMaps = geoMapsResponse.data?.setGeoMaps;

  return transformVectorAnalysisMaps([{ ...vectorAnalysisMap, geoMaps }])[0];
};

export const fetchGeneratedVectorAnalysisMap = async ({
  farmUuid,
  fieldUuid,
  vectorAnalysisMapUuid,
  areaUnit,
}: {
  farmUuid: string;
  fieldUuid: string;
  vectorAnalysisMapUuid: string;
  areaUnit: AreaUnit;
}) => {
  const response = (await API.graphql(
    graphqlOperation(getGeneratedVectorAnalysisMapsQuery, {
      farmUuids: [farmUuid],
      fieldUuids: [fieldUuid],
      vectorAnalysisMapUuids: [vectorAnalysisMapUuid],
      areaUnit,
    }),
  )) as GetGeneratedVamapsResponse;

  return transformVectorAnalysisMaps(
    response.data?.getFarms[0].fields[0].vectorAnalysisMaps,
  )[0];
};

export const fetchGeneratedEquationMap = async ({
  farmUuid,
  fieldUuid,
  equationMapUuid,
  areaUnit,
}: {
  farmUuid: string;
  fieldUuid: string;
  equationMapUuid: string;
  areaUnit: AreaUnit;
}) => {
  const response = (await API.graphql(
    graphqlOperation(getGeneratedEquationMapsQuery, {
      farmUuids: [farmUuid],
      fieldUuids: [fieldUuid],
      equationMapUuids: [equationMapUuid],
      areaUnit,
    }),
  )) as GetGeneratedEquationMapsResponse;

  return transformEquationMaps(
    response.data?.getFarms[0].fields[0].equationMaps,
  )[0];
};

export const generateSatelliteVectorAnalysisMap = async ({
  uuid,
  fieldUuid,
  index,
  satelliteImageUuids,
  ...settings
}: {
  uuid?: string;
  fieldUuid: string;
  index: number;
  satelliteImageUuids: string[];
} & GenerateVectorAnalysisMapSettings) => {
  const response = (await API.graphql(
    graphqlOperation(generateVectorAnalysisMapMutation, {
      input: {
        uuid,
        fieldUuid,
        index,
        satelliteImageUuids,
        ...settings,
      },
    }),
  )) as GenerateVectorAnalysisMapResponse;

  const result = response.data?.generateVectorAnalysisMapAsync;

  if (!result) {
    throw new CustomError(
      '[Create Analysis] generateVectorAnalysisMapAsync is empty.',
    );
  }

  return result;
};

export const generateSoilVectorAnalysisMap = async ({
  uuid,
  fieldUuid,
  soilAttribute,
  soilDatasetUuid,
  ...settings
}: {
  uuid?: string;
  fieldUuid: string;
  soilAttribute: string;
  soilDatasetUuid: string;
} & GenerateVectorAnalysisMapSettings) => {
  const response = (await API.graphql(
    graphqlOperation(generateSoilVectorAnalysisMapMutation, {
      input: {
        uuid,
        fieldUuid,
        soilAttribute,
        soilDatasetUuid,
        ...settings,
      },
    }),
  )) as GenerateSoilVectorAnalysisMapResponse;

  const result = response.data?.generateSoilVectorAnalysisMapAsync;

  if (!result) {
    throw new CustomError(
      '[Create Analysis] generateSoilVectorAnalysisMapAsync is empty.',
    );
  }

  return result;
};

export const generateYieldVectorAnalysisMap = async ({
  uuid,
  fieldUuid,
  yieldAttribute,
  yieldDatasetUuid,
  ...settings
}: {
  uuid?: string;
  fieldUuid: string;
  yieldAttribute: string;
  yieldDatasetUuid: string;
} & GenerateVectorAnalysisMapSettings) => {
  const response = (await API.graphql(
    graphqlOperation(generateYieldVectorAnalysisMapMutation, {
      input: {
        uuid,
        fieldUuid,
        yieldAttribute,
        yieldDatasetUuid,
        ...settings,
      },
    }),
  )) as GenerateYieldVectorAnalysisMapResponse;

  const result = response.data?.generateYieldVectorAnalysisMapAsync;

  if (!result) {
    throw new CustomError(
      '[Create Analysis] generateYieldVectorAnalysisMapAsync is empty.',
    );
  }

  return result;
};

export const generateAsAppliedVectorAnalysisMap = async ({
  uuid,
  fieldUuid,
  asAppliedAttribute,
  asAppliedDatasetUuid,
  ...settings
}: {
  uuid?: string;
  fieldUuid: string;
  asAppliedAttribute: string;
  asAppliedDatasetUuid: string;
} & GenerateVectorAnalysisMapSettings) => {
  const response = (await API.graphql(
    graphqlOperation(generateAsAppliedVectorAnalysisMapMutation, {
      input: {
        uuid,
        fieldUuid,
        asAppliedAttribute,
        asAppliedDatasetUuid,
        ...settings,
      },
    }),
  )) as GenerateAsAppliedVectorAnalysisMapResponse;

  const result = response.data?.generateAsAppliedVectorAnalysisMapAsync;

  if (!result) {
    throw new CustomError(
      '[Create Analysis] generateAsAppliedVectorAnalysisMapAsync is empty.',
    );
  }

  return result;
};

export const generateTopographyVectorAnalysisMap = async ({
  uuid,
  fieldUuid,
  topographyAttribute,
  topographyMapUuid,
  ...settings
}: {
  uuid?: string;
  fieldUuid: string;
  topographyAttribute: string;
  topographyMapUuid: string;
} & GenerateVectorAnalysisMapSettings) => {
  const response = (await API.graphql(
    graphqlOperation(generateTopographyVectorAnalysisMapMutation, {
      input: {
        uuid,
        fieldUuid,
        topographyAttribute,
        topographyMapUuid,
        ...settings,
      },
    }),
  )) as GenerateTopographyVectorAnalysisMapResponse;

  const result = response.data?.generateTopographyVectorAnalysisMapAsync;

  if (!result) {
    throw new CustomError(
      '[Create Analysis] generateTopographyVectorAnalysisMapAsync is empty.',
    );
  }

  return result;
};

export const generateMultiLayerVectorAnalysisMap = async ({
  uuid,
  fieldUuid,
  dataLayers,
  ...settings
}: {
  uuid?: string;
  fieldUuid: string;
  dataLayers: DataLayerInput[];
} & GenerateVectorAnalysisMapSettings) => {
  const response = (await API.graphql(
    graphqlOperation(generateMultiLayersVectorAnalysisMapMutation, {
      input: {
        uuid,
        fieldUuid,
        dataLayers,
        ...settings,
      },
    }),
  )) as GenerateMultiLayersVectorAnalysisMapResponse;

  const result = response.data?.generateMultiLayersVectorAnalysisMapAsync;

  if (!result) {
    throw new CustomError(
      '[Create Analysis] generateMultiLayersVectorAnalysisMapAsync is empty.',
    );
  }

  return result;
};

export const executeZonesOperation = async ({
  uuid,
  fieldUuid,
  name,
  polygonMinArea,
  zonesOperation,
  zonesOperationMaps,
}: {
  uuid?: string;
  fieldUuid: string;
  name: string;
  polygonMinArea: number;
  zonesOperation: ZonesOperationType;
  zonesOperationMaps: ZonesOperationMap[];
}) => {
  const response = (await API.graphql(
    graphqlOperation(executeZonesOperationMutation, {
      input: {
        uuid,
        fieldUuid,
        name,
        polygonMinArea,
        zonesOperation,
        zonesOperationMaps,
      },
    }),
  )) as ExecuteZonesOperationResponse;

  const result = response.data?.executeZonesOperation;

  if (!result) {
    throw new CustomError('[Create Analysis] executeZonesOperation is empty.');
  }

  return result;
};

export const generateEquationMap = async ({
  uuid,
  fieldUuid,
  name,
  gridXSize,
  gridYSize,
  type,
  equation,
  equationUuid,
  resultVariable,
  dataVariables,
  productUnit,
  useNumpy,
}: GenerateEquationMapArg) => {
  const response = (await API.graphql(
    graphqlOperation(generateEquationMapQuery, {
      input: {
        uuid,
        fieldUuid,
        name,
        gridPixelSizeX: gridXSize,
        gridPixelSizeY: gridYSize,
        type,
        equationAsText: equation,
        equationUuid,
        equationResultVariable: resultVariable,
        dataVariables,
        productUnit,
        useNumpy,
      },
    }),
  )) as GenerateEquationMapResponse;

  const result = response.data?.generateEquationMapAsync;

  if (!result) {
    throw new CustomError(
      '[Create Analysis] generateEquationMapAsync is empty.',
    );
  }

  return result;
};
