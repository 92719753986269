import React, { ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import PopupHeader from '../../../../../components/Popups/PopupHeader';
import TextField from '../../../../../components/TextField';
import Button from '../../../../../components/Button';
import Textarea from '../../../../../components/Textarea';
import IssueAttachments from './IssueAttachments';
import { ATTACHMENTS_SIZE_LIMIT } from '../../helpers/constants/sentry';

import './index.scss';

export default function ReportIssuePopup({
  name,
  email,
  comment,
  files,
  onNameChange,
  onEmailChange,
  onCommentChange,
  onFilesChanged,
  onFileRemoved,
  onCancel,
  onConfirm,
}: {
  name: string;
  email: string;
  comment: string;
  files: File[];
  onNameChange: (v: string) => void;
  onEmailChange: (v: string) => void;
  onCommentChange: (v: string) => void;
  onFilesChanged: (f: File[]) => void;
  onFileRemoved: (i: number) => void;
  onCancel: () => void;
  onConfirm: () => void;
}) {
  const { t } = useTranslation();

  const filesSize = useMemo(
    () => files.reduce((acc, file) => acc + file.size, 0),
    [files],
  );
  let attachmentsError = '';

  if (filesSize > ATTACHMENTS_SIZE_LIMIT) {
    attachmentsError = t(
      'general.popups.report-issue.files-size-exceeded-error',
    );
  }

  const confirmDisabled = !name || !email || !comment || !!attachmentsError;

  return (
    <Dialog open>
      <PopupHeader
        classes={{
          root: 'report-issue-popup__header',
        }}
        title={t('general.popups.report-issue.header')}
        onCancel={onCancel}
      />
      <DialogContent className="report-issue-popup__content">
        <DialogContentText>
          {t('general.popups.report-issue.description')}
        </DialogContentText>
        <TextField
          value={name}
          title={t('general.popups.report-issue.name-label')}
          placeholder={t('general.popups.report-issue.name-placeholder')}
          onChange={(e: ChangeEvent<{ value: string }>) =>
            onNameChange(e.target.value)
          }
        />
        <TextField
          value={email}
          title={t('general.popups.report-issue.email-label')}
          placeholder={t('general.popups.report-issue.email-placeholder')}
          onChange={(e: ChangeEvent<{ value: string }>) =>
            onEmailChange(e.target.value)
          }
        />
        <Textarea
          classes={{
            element: 'report-issue-popup__comment',
          }}
          value={comment}
          header={{
            title: t('general.popups.report-issue.comment-label'),
          }}
          placeholder={t('general.popups.report-issue.comment-placeholder')}
          onChange={onCommentChange}
        />
        <IssueAttachments
          files={files}
          sizeLimit={ATTACHMENTS_SIZE_LIMIT}
          error={attachmentsError}
          onFilesChanged={onFilesChanged}
          onFileRemoved={onFileRemoved}
        />
      </DialogContent>
      <DialogActions
        classes={{
          root: 'report-issue-popup__actions',
        }}
      >
        <Button variant="outlined" onClick={onCancel}>
          {t('general.controls.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={confirmDisabled}
          onClick={onConfirm}
        >
          {t('general.popups.report-issue.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
