import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function CrossLayerIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9341 10.0958L19.382 11.3052L19.3797 11.3034L17.749 12.5702L19.3797 13.8371L19.382 13.8353L20.9343 15.0448L21.012 15.1052L21.0119 15.1052L21.012 15.1053L20.142 15.7853L18.5491 17.0225L16.962 18.2553L12.012 22.1053L7.77683 18.8113L6.23196 17.6152L3.11366 15.1844L3.01196 15.1053L3.01208 15.1052L3.01196 15.1052L6.27114 12.5702L3.01196 10.0353L3.01208 10.0352L3.01196 10.0352L3.11393 9.95585L6.23196 7.52533L7.7767 6.32925L12.012 3.03516L16.962 6.88516L18.5491 8.11797L20.142 9.35516L21.012 10.0352L21.0119 10.0352L21.012 10.0353L20.9341 10.0958ZM12.0071 5.56914L10.842 6.47533L9.24634 7.71971L7.66196 8.95533L6.27196 10.0353L7.90012 11.3033L12.012 8.10516L14.112 9.73516L15.6963 10.9708L16.1222 11.3029L17.7493 10.0374L17.1191 9.54797L15.532 8.31516L12.0071 5.56914ZM14.4909 12.5702L14.2691 12.398L12.682 11.1652L12.012 10.6352L9.52708 12.5702L12.012 14.5053L12.682 13.9753L14.2691 12.7425L14.4909 12.5702ZM7.90012 13.8372L6.27196 15.1052L7.66196 16.1852L9.24634 17.4208L10.842 18.6652L12.0071 19.5714L15.532 16.8253L17.1191 15.5925L17.7493 15.1031L16.1222 13.8376L15.6963 14.1697L14.112 15.4053L12.012 17.0353L7.90012 13.8372Z"
      />
    </SvgIcon>
  );
}
