import { FieldStatus } from '../../types/field';

export enum FieldStatusMessage {
  fieldAreaTooLarge = 'Field area is too large (> 1235ac/500ha).',
  fieldExceedsAreaLimit = 'This field exceeds the account limit. The field is not registered.',
}

export const VALID_FIELD_STATUSES: FieldStatus[] = [
  'TILES_IGNORED',
  'TILES_REGISTERED',
  'GRIDS_CREATED',
  'VERIFIED',
];

export const FIELD_STATUS_MESSAGES_TO_I18N_KEYS_MAP: Record<
  string,
  string | undefined
> = {
  [FieldStatusMessage.fieldAreaTooLarge]: 'general.errors.field-too-large',
  [FieldStatusMessage.fieldExceedsAreaLimit]:
    'general.errors.field-exceeds-area-limit',
} as const;
