import type { Dictionary } from '@reduxjs/toolkit';
import i18n from 'i18next';

import type { FarmEntity, FieldEntity } from '../../../../assets/types/state';
import { convertDateToDisplayFormat } from '../../../../../helpers/functions/utils/date';
import { TitleTemplateItem } from '../../types/equationMap';
import { TitleTemplateKey } from '../constants/equationMap';

const shrinkName = (str?: string) => str?.slice(0, 10);

export const generateEquationMapsTitles = ({
  fieldsEntitiesList,
  farmsEntities,
  equationTitle,
  titleTemplate,
}: {
  fieldsEntitiesList: FieldEntity[];
  farmsEntities: Dictionary<FarmEntity>;
  equationTitle: string;
  titleTemplate: TitleTemplateItem[];
}) =>
  fieldsEntitiesList.reduce<Record<string, string>>((acc, fieldEntity) => {
    const result: (string | undefined)[] = [];

    titleTemplate.forEach(({ key, value }) => {
      switch (key) {
        case TitleTemplateKey.equationName:
          result.push(shrinkName(equationTitle));
          break;
        case TitleTemplateKey.fieldName:
          result.push(shrinkName(fieldEntity.name));
          break;
        case TitleTemplateKey.currentDate:
          result.push(convertDateToDisplayFormat(new Date()));
          break;
        case TitleTemplateKey.farmName:
          if (fieldEntity.farmUuid) {
            const farmEntity = farmsEntities[fieldEntity.farmUuid] || null;
            result.push(shrinkName(farmEntity?.name));
          }
          break;
        default:
          result.push(value);
      }
    });

    const title = result
      .filter((value) => value)
      .join(' - ')
      .trim();
    acc[fieldEntity.uuid] = title;

    return acc;
  }, {});

export const generateEquationMapTitleExample = ({
  titleTemplate,
  equationTitle,
}: {
  titleTemplate: TitleTemplateItem[];
  equationTitle: string;
}) => {
  const result: (string | undefined)[] = [];

  titleTemplate.forEach(({ key, value }) => {
    switch (key) {
      case TitleTemplateKey.equationName:
        result.push(shrinkName(equationTitle));
        break;
      case TitleTemplateKey.currentDate:
        result.push(convertDateToDisplayFormat(new Date()));
        break;
      case TitleTemplateKey.fieldName:
      case TitleTemplateKey.farmName:
        result.push(
          i18n.t(
            `batch-equation-based.steps.preview.title-template.options.${key}`,
          ),
        );
        break;
      default:
        result.push(value);
    }
  });

  return result
    .filter((value) => value)
    .join(' - ')
    .trim();
};

export const generateEquationMapsArchiveName = (equationTitle: string) => {
  const shortEquationName = shrinkName(equationTitle);
  const displayDate = convertDateToDisplayFormat(new Date());

  return `${shortEquationName} ${displayDate}`;
};
