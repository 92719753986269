import { useDispatch, useSelector } from 'react-redux';

import { generateCrossLayerVectorAnalysisMap } from '../../../createAnalysis/createAnalysisSlice';
import {
  selectNotEmptySelectedVectorAnalysisMaps,
  selectSelectedVamaps,
  selectSettings,
} from '../crossLayerSelectors';

export default function useGenerateCrossLayerVamap() {
  const dispatch = useDispatch();
  const { name, operation, polygonMinArea } = useSelector(selectSettings);
  const selectedVamaps = useSelector(selectSelectedVamaps);
  const zonesOperationMaps = useSelector(
    selectNotEmptySelectedVectorAnalysisMaps,
  ).map(({ uuid }) => ({
    uuid,
    zoneNumbers: selectedVamaps[uuid],
  }));

  return () =>
    dispatch(
      generateCrossLayerVectorAnalysisMap({
        name,
        polygonMinArea,
        zonesOperation: operation,
        zonesOperationMaps,
      }),
    );
}
