query getJohnDeereFieldsIds($fieldFilter: FieldFilterJohnDeere) {
  getFieldsJohnDeere(fieldFilter: $fieldFilter) {
    totalCount
    fields {
      id
      orgId
      area
    }
  }
}
