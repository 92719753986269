import React, { Fragment, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../../../../../../../components/Loading';
import Stepper from '../../../../../../../components/Stepper';
import BackdropLoading from '../../../../../../../components/Loading/BackdropLoading';
import Panel from '../../../Panel/DataLayers';
import { goToPrevStep } from '../../../../../zonesOps/zonesOpsSlice';
import Message from '../../../../../../../components/Messages/Message';
import {
  selectIsAllAssetsLoaded,
  selectSatelliteImage,
  selectSatelliteImages,
} from '../../../../../../field/fieldSelectors';
import { fetchAllAssets } from '../../../../../../field/fieldSlice';
import {
  selectActiveUuid,
  selectSelectedUuids,
} from '../../../../createFieldPotentialZonesSelectors';
import { selectSelectedField } from '../../../../../zonesOps/zonesOpsSelectors';
import useDidMount from '../../../../../../../hooks/useDidMount';
import { navigateToSettings } from '../../../../createFieldPotentialZonesSlice';
import DataLayersPreviews from '../../../DataLayersPreviews';
import { formatAcquisitionDate } from '../../../../../../../helpers/satellite';

const Map = React.lazy(() => import('../../../Map'));

const StepperDataLayers = ({ steps, stepIndex, step }) => {
  const dispatch = useDispatch();
  const { farmUuid, fieldUuid } = useSelector(selectSelectedField);
  const selectedUuids = useSelector(selectSelectedUuids);
  const satelliteImages = useSelector(selectSatelliteImages);
  const activeUuid = useSelector(selectActiveUuid);
  const activeImage = useSelector((state) =>
    selectSatelliteImage(state, activeUuid),
  );
  const isLoaded = useSelector(selectIsAllAssetsLoaded);
  const fieldHasNoAssets = satelliteImages.length === 0;
  const nextDisabled = selectedUuids.length === 0;

  const activeImageTitle = activeImage
    ? formatAcquisitionDate(activeImage?.satelliteImage.acquisitionDate)
    : '';

  const onClickNext = () => {
    dispatch(navigateToSettings());
  };

  const onClickBack = () => {
    dispatch(goToPrevStep());
  };

  useDidMount(() => {
    dispatch(
      fetchAllAssets({
        farmUuid,
        fieldUuid,
        requiredSatImages: selectedUuids,
      }),
    );
  });

  let content;

  if (isLoaded) {
    if (fieldHasNoAssets) {
      content = (
        <Message
          key="panel"
          messageTextLocation="zones-ops.common.no-assets-message"
        />
      );
    } else {
      content = [
        <Panel key="panel" step={step} />,
        <Suspense key="map" fallback={<Loading />}>
          <Map step={step} />
        </Suspense>,
        <Fragment key="map-title">{activeImageTitle}</Fragment>,
        <DataLayersPreviews key="previews" />,
      ];
    }
  } else {
    content = <BackdropLoading key="loader" />;
  }

  return (
    <Stepper
      activeStep={stepIndex}
      steps={steps}
      nextDisabled={nextDisabled}
      onClickBack={onClickBack}
      onClickNext={onClickNext}
    >
      {content}
    </Stepper>
  );
};

export default StepperDataLayers;
