import i18n from 'i18next';
import DeleteIcon from '@material-ui/icons/Delete';

import type { TableColumn } from '../../../tableView/types/column';
import type { RowMenuItem } from '../../../tableView/types/row';
import type { DefaultZonesMapTableRow } from '../../types/tableRow';
import ExportIcon from '../../../../../components/Icons/export';
import CloneIcon from '../../../../../components/Icons/clone';
import RenameIcon from '../../../../../components/Icons/rename';

const getRowMenuItems = (): RowMenuItem[] => [
  {
    id: 'clone',
    label: i18n.t('general.controls.clone'),
    IconComponent: CloneIcon,
  },
  {
    id: 'rename',
    label: i18n.t('general.controls.rename'),
    IconComponent: RenameIcon,
  },
  {
    id: 'delete',
    label: i18n.t('general.controls.delete'),
    IconComponent: DeleteIcon,
  },
];

const getTableColumns = ({
  selectionMode,
  rowsSelection,
  onHeadCheckboxClick,
  onRowCheckboxClick,
  onRatesLineClick,
  onFieldLinkClick,
  onZonesMapLinkClick,
  onExportButtonClick,
  onMenuItemClick,
}: {
  selectionMode: boolean;
  rowsSelection: number;
  onHeadCheckboxClick: (s: boolean) => void;
  onRowCheckboxClick: (r: DefaultZonesMapTableRow) => void;
  onRatesLineClick: (
    r: DefaultZonesMapTableRow,
    lineIndex: number,
    line: string,
  ) => void;
  onExportButtonClick: (r: DefaultZonesMapTableRow) => void;
  onMenuItemClick: (i: RowMenuItem, r: DefaultZonesMapTableRow) => void;
  onFieldLinkClick: () => void;
  onZonesMapLinkClick: () => void;
}): TableColumn<DefaultZonesMapTableRow>[] => {
  const baseColumns: TableColumn<DefaultZonesMapTableRow>[] = [
    {
      id: 'name',
      label: i18n.t('zones-maps.zones-list.columns.name'),
      element: 'editable-link',
      leading: true,
      rowSpan: 2,
      onStopEdit: () => {},
      onLinkClick: onZonesMapLinkClick,
    },
    {
      id: 'field',
      label: i18n.t('zones-maps.zones-list.columns.field'),
      element: 'editable-link',
      leading: true,
      onStopEdit: () => {},
      onLinkClick: onFieldLinkClick,
    },
    {
      id: 'farm',
      label: i18n.t('zones-maps.zones-list.columns.farm'),
      element: 'default',
      secondary: true,
    },
    {
      id: 'type',
      label: i18n.t('zones-maps.zones-list.columns.type'),
      element: 'zone-type',
    },
    {
      id: 'dateUpdated',
      label: i18n.t('zones-maps.zones-list.columns.updated'),
      element: 'default',
      secondary: true,
    },
    {
      id: 'details',
      label: i18n.t('zones-maps.zones-list.columns.details'),
      element: 'multiline',
      noWrap: true,
      secondary: true,
      onLineClick: () => {},
    },
    {
      id: 'rates',
      label: i18n.t('zones-maps.zones-list.columns.rates'),
      element: 'multiline',
      noWrap: true,
      secondary: true,
      onLineClick: onRatesLineClick,
    },
    {
      id: 'preview',
      label: i18n.t('general.shared.preview'),
      element: 'image',
    },
    {
      id: 'export',
      label: '',
      element: 'icon-button',
      tooltip: i18n.t('field.assets.zones-map-ops-items.export-map'),
      iconComponent: ExportIcon,
      onButtonClick: onExportButtonClick,
    },
    {
      id: 'actions',
      label: '',
      element: 'menu',
      menuItems: getRowMenuItems(),
      onMenuItemClick,
    },
  ];

  return selectionMode
    ? [
        {
          id: 'rowSelect',
          labelElement: 'checkbox',
          handler: onHeadCheckboxClick,
          value: rowsSelection,
          element: 'checkbox',
          rowSpan: 2,
          onCheckboxClick: onRowCheckboxClick,
        },
        ...baseColumns,
      ]
    : baseColumns;
};

export default getTableColumns;
