export enum VectorAnalysisFilterKey {
  typeFilterValue = 'typeFilterValue',
  nameFilterValue = 'nameFilterValue',
}

export const VAMAP_FILTER_KEYS_I18N_MAP = {
  [VectorAnalysisFilterKey.typeFilterValue]:
    'general.controls.zones-map-filters.type-label',
  [VectorAnalysisFilterKey.nameFilterValue]: 'general.controls.map-legend.name',
};

export const DEFAULT_VAMAPS_FILTER = {
  [VectorAnalysisFilterKey.typeFilterValue]: 'all',
  [VectorAnalysisFilterKey.nameFilterValue]: '',
};
