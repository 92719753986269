import React from 'react';
import TableCell from '@material-ui/core/TableCell';

import InfiniteTable from '../../../ui/tableView/components/InfiniteTable';
import EquationsListItem from '../EquationsListItem';
import { EquationCategory } from '../../helpers/constants/equations';
import { EquationListItem, EquationListRow } from '../../types/ui';
import { Equation } from '../../types/equation';
import {
  getEquationListRows,
  getEquationMenuItems,
} from '../../helpers/functions/list';

import './index.scss';

export default function EquationsList({
  equations,
  selectedEquationUuid,
  category,
  hasNext,
  onEquationSelect,
  onMenuItemClick,
  onSetUrlClick,
  loadMoreItems,
}: {
  equations: Equation[];
  selectedEquationUuid: string | null;
  category: EquationCategory;
  hasNext: boolean;
  onEquationSelect: (i: EquationListItem) => void;
  onMenuItemClick: (id: string, i: EquationListItem) => void;
  onSetUrlClick: (i: EquationListItem) => void;
  loadMoreItems: () => void;
}) {
  const menuItems = getEquationMenuItems(category);
  const rows = getEquationListRows(equations, selectedEquationUuid, category);

  const rowRenderer = (rowData: EquationListRow) => (
    <TableCell className="equations-list__cell" variant="body">
      <EquationsListItem
        item={rowData}
        menuItems={menuItems}
        onEquationSelect={onEquationSelect}
        onMenuItemClick={onMenuItemClick}
        onSetUrlClick={onSetUrlClick}
      />
    </TableCell>
  );

  const handleLoadMoreRows = () => {
    if (hasNext) {
      loadMoreItems();
    }
  };

  return (
    <InfiniteTable
      data={rows}
      loadComplete={!hasNext}
      rowRenderer={rowRenderer}
      onLoadMoreRows={handleLoadMoreRows}
    />
  );
}
