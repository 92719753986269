import React, { Fragment, ReactNode } from 'react';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import type { CognitoUserSession } from 'amazon-cognito-identity-js';

import { HelperTextType } from '../constants/helperTexts';
import { Provider } from '../constants/authentication';
import type { HelperText } from '../../types/ui';

export const getMultilineHelperText = (helperTexts: HelperText[]): ReactNode =>
  helperTexts.map(({ label, type }, index) => {
    const className = `authentication-panel__input-helper-line_${type}`;

    return (
      <Fragment key={index}>
        <span className={className}>{label}</span>
        <br />
      </Fragment>
    );
  });

export function hasErrors<T extends string>(
  helperTexts: Record<T, HelperText[]>,
): boolean {
  return (Object.values(helperTexts).flat() as HelperText[]).some(
    ({ type }) => type === HelperTextType.error,
  );
}

export const prepareEmailAttribute = (email: string): string =>
  email.toLowerCase().trim();

export const prepareAttribute = (attribute: string): string => attribute.trim();

const EMAIL_PROVIDER_LIST = [
  {
    regexp: /@mhp.com.ua/gi,
    provider: Provider.mhp,
  },
];

export const matchEmailToProvider = (email: string): Provider | null => {
  const emailProvider = EMAIL_PROVIDER_LIST.find(({ regexp }) =>
    regexp.test(email),
  );

  return emailProvider?.provider || null;
};

const NOT_REACHABLE_EMAIL_DOMAINS = [
  {
    domain: '@privaterelay.appleid.com',
    provider: Provider.apple,
  },
  {
    domain: '@vdsproddealer.deere.com',
    provider: Provider.johnDeere,
  },
];

export const isNotReachableEmail = (email: string | null) =>
  NOT_REACHABLE_EMAIL_DOMAINS.some(({ domain }) => email?.endsWith(domain));

export const getNotReachableEmailProvider = (email: string | null) => {
  const emailProvider = NOT_REACHABLE_EMAIL_DOMAINS.find(({ domain }) =>
    email?.endsWith(domain),
  );

  return emailProvider?.provider;
};

export const refreshUserSession = async (): Promise<CognitoUserSession> => {
  const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
  const currentSession = await Auth.currentSession();

  return new Promise<CognitoUserSession>((resolve, reject) => {
    cognitoUser.refreshSession(
      currentSession.getRefreshToken(),
      (error: unknown, session: CognitoUserSession) => {
        if (error) {
          reject(error);
        } else {
          resolve(session);
        }
      },
    );
  });
};
