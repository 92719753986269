import React from 'react';

import ToolsPanel from '../../../../../components/ToolsPanel';
import FieldsListFilter from '../../../fieldsList/containers/FieldsListFilter';

import './index.scss';

const Panel = () => (
  <ToolsPanel
    classes={{
      root: 'smart-sampling-tools-panel',
    }}
    filtersLeft={<FieldsListFilter withCreateFarm />}
  />
);

export default Panel;
