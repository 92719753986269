import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { SubOperation } from '../../types/operation';
import { OperationStatus } from '../../helpers/constants/operation';
import { getFieldLink } from '../../../../../helpers/navigation';
import { getEntityLink } from '../../helpers/functions/navigation';
import OperationException from '../OperationException';
import Link from '../../../../../components/Link';
import OperationLabel from '../OperationLabel';
import StatusIcon from '../StatusIcon/index';

import './index.scss';

const getItemTitle = (subOperation: SubOperation): string => {
  let result: string;

  if (subOperation.fieldName) {
    result = subOperation.fieldName;
  } else {
    result = subOperation.assetType;

    if (subOperation.subFieldUuid) {
      result += ` (${subOperation.subFieldUuid})`;
    }
  }

  return result;
};

export default function SubOperationItem({
  subOperation,
}: {
  subOperation: SubOperation;
}) {
  const { t } = useTranslation();

  let exceptionMessage: ReactNode;
  let operationLabel: ReactNode;
  const entityLink = getEntityLink(subOperation);
  const title = getItemTitle(subOperation);
  const links = [
    {
      label: t('operations.operation-item.assets-links.field'),
      url: getFieldLink(subOperation.farmUuid, subOperation.fieldUuid),
    },
    ...(subOperation.status !== OperationStatus.ignored
      ? [
          {
            label: t(entityLink.i18nKey),
            url: entityLink.url,
          },
        ]
      : []),
  ].filter(({ url }) => url);

  if (subOperation.status === OperationStatus.done) {
    exceptionMessage = <OperationException exception={subOperation.message} />;
  } else if (subOperation.status === OperationStatus.error) {
    exceptionMessage = (
      <OperationException
        type={subOperation.status}
        exception={subOperation.message}
      />
    );
  } else if (subOperation.status === OperationStatus.inProgress) {
    exceptionMessage = <OperationException exception={subOperation.message} />;
  } else if (subOperation.status === OperationStatus.ignored) {
    exceptionMessage = (
      <OperationException
        type={subOperation.status}
        exception={subOperation.message}
      />
    );
  }

  if (subOperation.collectedFromJD) {
    operationLabel = <OperationLabel type="john-deere" />;
  }

  return (
    <div className="sub-operation-item">
      <div className="sub-operation-item__content">
        <div className="sub-operation-item__header">
          <Typography className="sub-operation-item__title">{title}</Typography>
          <div className="sub-operation-item__labels">
            {operationLabel}
            <StatusIcon status={subOperation.status} />
          </div>
        </div>
        {exceptionMessage}
        {links.length !== 0 && (
          <div className="sub-operation-item__links">
            {links.map((link, index) => (
              <Link
                key={index}
                className="sub-operation-item__link"
                to={link.url}
                target="_blank"
              >
                {link.label}
                <ArrowForwardIcon
                  className="sub-operation-item__link-icon"
                  fontSize="small"
                />
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
