import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import ButtonMenu from '../../../../../components/ButtonMenu';
import { ButtonMenuItem } from '../../../../../components/ButtonMenu/types';
import { getVariablesActions } from '../../helpers/functions/variables';
import { VariableItem } from '../../types/variable';
import UseInterpolationCheckbox from '../../../../../components/OpsPanel/UseInterpolationCheckbox';

import './index.scss';

const VariablesListItem = ({
  item,
  onMenuItemClick,
  manageVariable,
  onUseInterpolatedDataChange,
}: {
  item: VariableItem;
  onMenuItemClick: (menuItem: ButtonMenuItem) => void;
  manageVariable: (variable: string) => void;
  onUseInterpolatedDataChange: (value: boolean) => void;
}) => {
  const { t } = useTranslation();
  const { name, dataset, useInterpolatedData } = item;

  const menuItems = getVariablesActions();

  return (
    <div className="variables-list-item">
      <div className="variables-list-item__variable">
        <Typography
          className="variables-list-item__variable-name"
          variant="subtitle2"
        >
          {name}
        </Typography>
        <ButtonMenu
          isIconButton
          edge="end"
          size="small"
          items={menuItems}
          onItemClick={onMenuItemClick}
        >
          <MoreVertIcon fontSize="small" />
        </ButtonMenu>
      </div>
      <div className="variables-list-item__variable-dataset">
        {dataset.name ? (
          <>
            <div className="variables-list-item__variable-dataset-item">
              {dataset.label && (
                <Typography
                  className="variables-list-item__variable-dataset-label"
                  variant="body2"
                >
                  {dataset.label}:
                </Typography>
              )}
              <Typography
                className="variables-list-item__variable-dataset-data"
                variant="body2"
              >
                {dataset.name}
              </Typography>
            </div>
            {dataset.selectedAttribute && (
              <div className="variables-list-item__variable-dataset-item">
                <Typography
                  className="variables-list-item__variable-dataset-label"
                  variant="body2"
                >
                  {t(
                    'zones-ops.equation-based.steps.4.panel.variables-list.attribute',
                  )}
                  :
                </Typography>
                <Typography
                  className="variables-list-item__variable-dataset-data"
                  variant="body2"
                >
                  {dataset.selectedAttribute}
                </Typography>
              </div>
            )}
            <UseInterpolationCheckbox
              classes={{
                root: 'variables-list-item__use-interpolation-checkbox',
              }}
              checked={useInterpolatedData}
              onChange={onUseInterpolatedDataChange}
            />
          </>
        ) : (
          <>
            <Typography
              className="variables-list-item__variable-dataset-manage"
              variant="body2"
            >
              {t(
                'zones-ops.equation-based.steps.4.panel.variables-list.no-dataset-selected',
              )}
            </Typography>
            <Link component="button" onClick={() => manageVariable(name)}>
              <Typography variant="caption" className="link">
                {t(
                  'zones-ops.equation-based.steps.4.panel.variables-list.manage',
                )}
              </Typography>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default VariablesListItem;
