import React, { ReactElement } from 'react';
import i18n from 'i18next';

import Link from '../../../../../components/Link';
import {
  isSatelliteVectorAnalysis,
  isTopographyVectorAnalysis,
  isSoilVectorAnalysis,
  isYieldVectorAnalysis,
  isAsAppliedVectorAnalysis,
} from '../../../../../helpers/functions/entities/assets';
import {
  getZonesMapLink,
  getFieldLink,
} from '../../../../../helpers/navigation';
import {
  PURPOSES_I18N_KEY_LABEL_MAP,
  getRatesData,
} from '../../../../../helpers/analysis';
import { getThumbnailUrl } from '../../../../../helpers/functions/utils/url';
import { formatDate } from '../../../../../helpers/functions/utils/date';
import { EnrichedVectorAnalysisMap } from '../../types/vectorAnalysisMap';
import {
  DefaultZonesMapTableRow,
  ZonesMapTableRow,
} from '../../types/tableRow';
import { getThumbnailGeoMap } from '../../../../../helpers/functions/entities/vectorAnalysisMap';

const getDatasetVamapDetails = (
  defaultDetails: string[],
  datasetName: string | undefined,
  attribute: string | undefined,
): string[] => [
  datasetName
    ? `${i18n.t('zones-maps.zones-list.details.dataset')}: ${datasetName}`
    : '',
  attribute
    ? `${i18n.t('zones-maps.zones-list.details.attribute')}: ${attribute}`
    : '',
  '\u00a0',
  ...defaultDetails,
];

const getVamapRates = (vamap: EnrichedVectorAnalysisMap): ReactElement[] => {
  const rates = getRatesData(vamap.attributes);

  if (rates.length !== 0) {
    const previewLink = (
      <Link to="#" className="rates-link">
        {i18n.t('general.shared.preview')}
      </Link>
    );

    return [previewLink, '\u00a0', ...rates];
  }

  const assignRatesLink = (
    <Link
      to={getZonesMapLink(vamap.farmUuid, vamap.fieldUuid, vamap.uuid)}
      className="rates-link"
    >
      {i18n.t('general.popups.save-zones-map.actions.assign-rates')}
    </Link>
  );

  return [assignRatesLink];
};

const getZonesMapsTableRows = ({
  vamaps,
  apiKey,
}: {
  vamaps: EnrichedVectorAnalysisMap[];
  apiKey: string;
}) =>
  (vamaps || []).reduce<ZonesMapTableRow[]>((acc, vamap) => {
    const thumbnail = getThumbnailGeoMap(vamap.geoMaps);

    let row: DefaultZonesMapTableRow = {
      rowType: 'default',
      data: vamap,
      id: vamap.uuid,
      name: {
        title: vamap.name,
        link: getZonesMapLink(vamap.farmUuid, vamap.fieldUuid, vamap.uuid),
      },
      field: {
        title: vamap.fieldName,
        link: getFieldLink(vamap.farmUuid, vamap.fieldUuid),
      },
      labels: vamap.labels,
      farm: vamap.farmName,
      dateUpdated: formatDate(vamap.updatedDate),
      details: [
        vamap.type
          ? `${i18n.t('general.controls.purpose')}: ${i18n.t(PURPOSES_I18N_KEY_LABEL_MAP[vamap.type])}`
          : '',
      ],
      uuid: vamap.uuid,
      fieldUuid: vamap.fieldUuid || '',
      fieldArea: vamap.fieldArea,
      farmUuid: vamap.farmUuid,
      selected: vamap.selected,
      type: vamap.type,
      attributes: vamap.attributes,
      geoMaps: vamap.geoMaps,
      area: vamap.area,
      preview: getThumbnailUrl({ thumbnail, size: 80, apiKey }),
      rates: getVamapRates(vamap),
      shouldDisplayPinsWarning: !!vamap.pins?.length,
    };

    if (isSatelliteVectorAnalysis(vamap)) {
      row = {
        ...row,
        details: [
          `${i18n.t('zones-maps.zones-list.details.satellite-images')}: ${vamap.satelliteImages?.length}`,
          '\u00a0',
          ...row.details,
        ],
      };
    } else if (isTopographyVectorAnalysis(vamap)) {
      row = {
        ...row,
        details: getDatasetVamapDetails(
          row.details,
          vamap.topographyMap?.name,
          vamap.parameters?.topographyAttribute,
        ),
      };
    } else if (isSoilVectorAnalysis(vamap)) {
      row = {
        ...row,
        details: getDatasetVamapDetails(
          row.details,
          vamap.soilDataset?.name,
          vamap.parameters?.soilAttribute,
        ),
      };
    } else if (isYieldVectorAnalysis(vamap)) {
      row = {
        ...row,
        details: getDatasetVamapDetails(
          row.details,
          vamap.yieldDataset?.name,
          vamap.parameters?.yieldAttribute,
        ),
      };
    } else if (isAsAppliedVectorAnalysis(vamap)) {
      row = {
        ...row,
        details: getDatasetVamapDetails(
          row.details,
          vamap.asAppliedDataset?.name,
          vamap.parameters?.asAppliedAttribute,
        ),
      };
    }

    acc.push(row);

    if (vamap.labels?.length) {
      row.hasNestedRow = true;
      acc.push({
        id: `nested-row-${vamap.uuid}`,
        rowType: 'nested',
        cellType: 'collapsible-labels',
        fieldUuid: vamap.fieldUuid || '',
        farmUuid: vamap.farmUuid || '',
        fieldName: vamap.fieldName,
        labels: vamap.labels,
        onDeleteLabelClick: () => {},
        onAddLabelClick: () => {},
      });
    }

    return acc;
  }, []);

export default getZonesMapsTableRows;
