mutation enableSubscriptionJohnDeere(
  $input: UpdateStatusSubscriptionJohnDeereInput!
) {
  enableSubscriptionJohnDeere(input: $input) {
    id
    name
    type
    status
    orgIds
    fieldOperationTypes
  }
}
