query getFieldsJohnDeere($fieldFilter: FieldFilterJohnDeere) {
  getFieldsJohnDeere(fieldFilter: $fieldFilter) {
    fields {
      id
      name
      orgId
      area
      areaUnit
      synchronized {
        farmUuid
        fieldUuid
        name
        createdDate
        updatedDate
        status
        statusMessage
      }
    }
    totalCount
  }
}
