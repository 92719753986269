import React from 'react';
import { useParams } from 'react-router-dom';

import Stepper, { Step } from '../../../../../../../components/Stepper';
import { selectAssetType } from '../../../../createThreeDMapSelectors';
import Panel from '../../../Panel/ShapeDataType';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../app/store/helpers/functions';
import { goToNextStep } from '../../../../../zonesOps/zonesOpsSlice';
import { selectIsDatasetsLoaded } from '../../../../../../field/fieldSelectors';
import BackdropLoading from '../../../../../../../components/Loading/BackdropLoading';
import useDidMount from '../../../../../../../hooks/useDidMount';
import { fetchDatasets } from '../../../../../../field/fieldSlice';

const ShapeDataType = ({
  steps,
  stepIndex,
}: {
  steps: Step[];
  stepIndex: number;
}) => {
  const dispatch = useAppDispatch();
  const assetType = useAppSelector(selectAssetType);
  const datasetsLoaded = useAppSelector(selectIsDatasetsLoaded);
  const params = useParams<{ farmUuid: string; fieldUuid: string }>();

  useDidMount(() => {
    const { farmUuid, fieldUuid } = params;
    void dispatch(fetchDatasets({ farmUuid, fieldUuid }));
  });

  const handleClickNext = () => {
    dispatch(goToNextStep());
  };

  return (
    <Stepper
      activeStep={stepIndex}
      steps={steps}
      backDisabled
      nextDisabled={!assetType}
      onClickNext={handleClickNext}
    >
      {datasetsLoaded ? (
        <Panel key="panel" />
      ) : (
        <BackdropLoading key="loader" />
      )}
    </Stepper>
  );
};

export default ShapeDataType;
