import React, { useMemo, ReactNode, ChangeEvent } from 'react';
import clsx from 'clsx';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { OutlinedInputProps } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import Tooltip from '../Tooltip';
import EndAdornment from './EndAdornment';

import './index.scss';

interface EnhancedInputProps extends OutlinedInputProps {
  classes?: {
    root?: string;
    input?: string;
    adornedEnd?: string;
    error?: string;
  };
}

interface EnhancedTextFieldProps
  extends Omit<TextFieldProps, 'classes' | 'variant'> {
  required?: boolean;
  title?: string;
  classes?: {
    wrapper?: string;
    title?: string;
    tooltipLabel?: string;
    tooltip?: string;
  };
  variant?: 'default' | 'small';
  tooltip?: ReactNode;
  InputProps?: EnhancedInputProps;
  onEndAdornmentClick?: () => void;
}

export default function EnhancedTextField(props: EnhancedTextFieldProps) {
  const {
    placeholder,
    required,
    title,
    classes,
    InputProps,
    variant = 'default',
    type,
    tooltip,
    helperText,
    onChange = () => {},
    onEndAdornmentClick = () => {},
    ...restTextFieldProps
  } = props;

  const id = useMemo(() => Math.random(), []);

  const prepareValue = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    const min = InputProps?.inputProps?.min;
    const max = InputProps?.inputProps?.max;

    if (min != null && value < min) {
      event.target.value = min.toString();
    }

    if (max != null && value > max) {
      event.target.value = max.toString();
    }

    return event;
  };

  return (
    <div className={clsx('textfield__wrapper', classes?.wrapper)}>
      {title && (
        <label
          htmlFor={`textfield_${id}`}
          className={clsx('textfield__label', classes?.title)}
        >
          {title}
          {required && <span className="required-asterisk"> *</span>}
          {tooltip && (
            <Tooltip
              classes={{
                tooltip: clsx('textfield__tooltip', classes?.tooltip),
                label: clsx('textfield__tooltip-label', classes?.tooltipLabel),
              }}
              tooltip={tooltip}
            >
              <InfoOutlinedIcon fontSize="small" />
            </Tooltip>
          )}
        </label>
      )}
      <TextField
        error={!!helperText}
        helperText={helperText}
        id={`textfield_${id}`}
        variant="outlined"
        size="small"
        placeholder={placeholder}
        type={type}
        classes={{
          root: clsx('textfield', { textfield_small: variant === 'small' }),
        }}
        InputProps={{
          ...InputProps,
          classes: {
            root: clsx('textfield__input', InputProps?.classes?.root),
            input: clsx('textfield__input-input', InputProps?.classes?.input),
            error: clsx('textfield__input_error', InputProps?.classes?.error),
            focused: 'textfield__input_focused',
            notchedOutline: 'textfield__outline',
            marginDense: 'textfield__input_margin-dense',
            adornedStart: 'textfield__input_adorned-start',
            adornedEnd: InputProps?.classes?.adornedEnd,
          },
          startAdornment:
            type === 'search' ? (
              <SearchIcon className="textfield__search-icon" />
            ) : (
              InputProps?.startAdornment
            ),
          endAdornment: (
            <EndAdornment
              type={type}
              endAdornment={InputProps?.endAdornment}
              value={restTextFieldProps.value as string}
              onEndAdornmentClick={onEndAdornmentClick}
            />
          ),
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onChange(prepareValue(e))
        }
        {...restTextFieldProps}
      />
    </div>
  );
}
