export default class InMemoryStorageService<T = unknown> {
  private items: Record<string, T> = {};

  addItem(id: string, item: T): void {
    this.items[id] = item;
  }

  removeItem(id: string): void {
    const { [id]: itemToRemove, ...otherItems } = this.items;

    this.items = otherItems;
  }

  getItem(id: string): T | undefined {
    return this.items[id];
  }
}
