import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';

import Label from '../../../Label';

import './index.scss';

const LabelsList = ({ header, labels, classes = {} }) => {
  const { t } = useTranslation();

  return (
    <div className={clsx('labels-list', classes.root)}>
      <Typography className="labels-list__header">
        {header || t('general.controls.map-legend.labels')}
      </Typography>
      <div className="labels-list__labels">
        {labels?.length > 0 ? (
          labels.map((label, labelIndex) => (
            <Label
              key={labelIndex}
              classes={{
                label: 'labels-list__labels__label',
              }}
              text={`${label.name}: ${label.value}`}
            />
          ))
        ) : (
          <span className="labels-list__no-labels">
            {t('general.controls.no-labels')}
          </span>
        )}
      </div>
    </div>
  );
};

export default LabelsList;
