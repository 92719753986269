import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import TextField from '../../TextField';
import isRequiredFieldsFilled from '../../../helpers/functions/utils/form';

import './index.scss';

const REQUIRED_FIELDS = ['name', 'surname', 'givenName', 'email'];

const OrganizationSettingsForm = ({ form: initialForm, onFormUpdate }) => {
  const { t } = useTranslation();
  const [form, setForm] = useState({
    name: initialForm.name || '',
    surname: initialForm.surname || '',
    givenName: initialForm.givenName || '',
    phone: initialForm.phone || '',
    email: initialForm.email || '',
  });

  const handleFieldChange = (e, fieldName) => {
    const updatedForm = {
      ...form,
      [fieldName]: e.target.value,
    };

    setForm(updatedForm);

    onFormUpdate({
      updatedForm,
      valid: isRequiredFieldsFilled(updatedForm, REQUIRED_FIELDS),
      changed: initialForm[fieldName] !== e.target.value,
    });
  };

  return (
    <div className="organization-settings">
      <div className="organization-settings__section">
        <TextField
          required
          title={t('general.popups.organization-settings.organization-name')}
          value={form.name}
          onChange={(e) => handleFieldChange(e, 'name')}
        />
      </div>
      <div className="organization-settings__section">
        <Typography variant="h6">
          {t('general.popups.organization-settings.owner')}
        </Typography>
        <Typography
          className="organization-settings__section-description"
          variant="body2"
        >
          {t('general.popups.organization-settings.owner-description')}
        </Typography>
        <div className="organization-settings__account-fields">
          <TextField
            className="organization-settings__account-field"
            required
            title={t('general.popups.organization-settings.surname')}
            value={form.surname}
            onChange={(e) => handleFieldChange(e, 'surname')}
          />
          <TextField
            className="organization-settings__account-field"
            required
            title={t('general.popups.organization-settings.given-name')}
            value={form.givenName}
            onChange={(e) => handleFieldChange(e, 'givenName')}
          />
          <TextField
            className="organization-settings__account-field"
            title={t('general.popups.organization-settings.phone')}
            value={form.phone}
            onChange={(e) => handleFieldChange(e, 'phone')}
          />
          <TextField
            className="organization-settings__account-field"
            required
            title={t('general.popups.organization-settings.email')}
            value={form.email}
            onChange={(e) => handleFieldChange(e, 'email')}
          />
        </div>
      </div>
    </div>
  );
};

export default OrganizationSettingsForm;
