import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function ExportPDFIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.34134 1.66675L3.33301 18.3334H16.6663V6.66675L11.6663 1.66675H3.34134ZM10.833 7.50008V2.91675L15.4163 7.50008H10.833ZM7.91634 14.1667H5.83301V15.8334H4.58301V10.8334H7.91634V14.1667ZM5.83301 12.9167H6.66634V12.0834H5.83301V12.9167ZM14.1663 12.0834H15.4163V10.8334H12.9163V15.8334H14.1663V14.1667H15.4163V12.9167H14.1663V12.0834ZM10.833 15.8334C11.5247 15.8334 12.083 15.2751 12.083 14.5834V12.0834C12.083 11.3917 11.5247 10.8334 10.833 10.8334H8.74967V15.8334H10.833ZM10.833 14.5834H9.99967V12.0834H10.833V14.5834Z"
      />
    </SvgIcon>
  );
}
