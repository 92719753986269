import { nanoid } from '@reduxjs/toolkit';

import { getBrandName } from '../../../../../helpers/functions/utils/appConfig';
import { ARCHIVE_NAME_MAX_LENGTH } from '../constants/api';

const getArchiveName = (name = '') => {
  const resourceName = name.replace(/[^a-zA-Z0-9_\p{L}]/gu, ''); // Only letters, numbers, and '_' are allowed; other symbols will be replaced with ''.
  const brandName: string = getBrandName();
  const id = nanoid(15);
  const additionalSymbolsLength = 2;
  const nameMaxLength =
    ARCHIVE_NAME_MAX_LENGTH -
    brandName.length -
    id.length -
    additionalSymbolsLength;
  const archiveName = `${brandName}-${resourceName.substring(0, nameMaxLength)}-${id}`;

  return archiveName;
};

export default getArchiveName;
