query getVamapsAttributesJson(
  $farmUuids: [ID]
  $fieldUuids: [ID]
  $vectorAnalysisMapUuids: [ID]
  $areaUnit: AreaUnit!
) {
  getFarms(farmUuids: $farmUuids) {
    uuid
    fields(fieldUuids: $fieldUuids) {
      uuid
      vectorAnalysisMaps(vectorAnalysisMapUuids: $vectorAnalysisMapUuids) {
        uuid
        analysisType
        attributesJson(unit: $areaUnit)
      }
    }
  }
}
