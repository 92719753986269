import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import { useAppSelector } from '../../../../../app/store/helpers/functions';
import {
  selectEquationMapsEntitiesList,
  selectFieldsEntitiesList,
} from '../../../../assets/assetsSelectors';
import { selectSelectedFieldsUuids } from '../../../batchAnalytics/batchAnalyticsSelectors';
import { selectCurrentEquation } from '../../../../equations/equationsSelectors';
import LoadStatus from '../../../../../helpers/constants/utils/loadStatus';
import {
  calculateWeightedAvg,
  calculateWeightedSum,
} from '../../helpers/functions/statistics';
import { prettifyNumber } from '../../../../../helpers/markup';
import { PRODUCT_UNIT_TO_I18N_LABEL } from '../../../../../helpers/constants/units/productUnit';
import { convertProductUnitToAbsoluteUnit } from '../../../../../helpers/functions/units/productUnit';
import { ABSOLUTE_UNIT_TO_I18N_LABEL } from '../../../../../helpers/constants/units/absoluteUnit';
import { selectAreaUnit } from '../../../../user/userSelectors';
import { getI18nAreaUnit } from '../../../../../helpers';
import { selectEquationMapsDataByStatuses } from '../../createBatchEquationBasedAnalysisSelectors';
import OperationsIcon from '../../../../../components/Icons/operations';
import OpsLogTooltip from '../../components/OpsLogTooltip';
import SummaryPanel from '../../components/SummaryPanel';
import Tooltip from '../../../../../components/Tooltip';

import './index.scss';

const DASH = '—';

const getPreviewSummary = ({
  fieldsAmount,
  fieldsArea,
  areaUnitLabel,
  avg,
  sum,
  generationInProgress,
  inProgressEquationMapsAmount,
  errorEquationMapsAmount,
  successEquationMapsAmount,
}: {
  fieldsAmount: number;
  inProgressEquationMapsAmount: number;
  errorEquationMapsAmount: number;
  successEquationMapsAmount: number;
  fieldsArea: string | null;
  areaUnitLabel: string;
  avg: string;
  sum: string;
  generationInProgress: boolean;
}) => [
  [
    {
      text: `${i18n.t('batch-equation-based.steps.preview.summary.selected-fields')}:`,
    },
    {
      text: fieldsAmount,
      status: 'success' as const,
    },
  ],
  [
    {
      text: `${i18n.t('batch-equation-based.steps.preview.summary.selected-fields-area')}:`,
    },
    {
      text: `${fieldsArea} ${areaUnitLabel}`,
      status: 'success' as const,
    },
  ],
  [
    {
      text: `${i18n.t('general.controls.map-legend.statistics.avg')}:`,
    },
    {
      text: avg,
      loading: generationInProgress && !successEquationMapsAmount,
      ...(successEquationMapsAmount ? { status: 'success' as const } : null),
    },
  ],
  [
    {
      text: `${i18n.t('general.controls.map-legend.statistics.sum')}:`,
    },
    {
      text: sum,
      loading: generationInProgress && !successEquationMapsAmount,
      ...(successEquationMapsAmount ? { status: 'success' as const } : null),
    },
  ],
  [
    {
      text: `${i18n.t('batch-equation-based.steps.preview.summary.equation-maps')}: `,
      icon: (
        <Tooltip
          classes={{
            tooltip: 'preview-summary__tooltip',
            label: 'preview-summary__tooltip-label',
          }}
          tooltip={<OpsLogTooltip />}
          interactive
        >
          <OperationsIcon className="preview-summary__icon" />
        </Tooltip>
      ),
    },
    {
      text: fieldsAmount,
      status: 'neutral' as const,
      withSeparator: true,
    },
    {
      text: `${i18n.t('operations.statuses.in-progress')}: ${inProgressEquationMapsAmount}`,
      withSeparator: true,
    },
    {
      text: `${i18n.t('operations.statuses.error')}: ${errorEquationMapsAmount}`,
      status: 'error' as const,
      withSeparator: true,
    },
    {
      text: `${i18n.t('operations.statuses.success')}: ${successEquationMapsAmount}`,
      status: 'success' as const,
    },
  ],
];

export default function PreviewSummary() {
  const { t } = useTranslation();

  const selectedFieldsUuids = useAppSelector(selectSelectedFieldsUuids);
  const successEquationMapsData = useAppSelector((state) =>
    selectEquationMapsDataByStatuses(state, [LoadStatus.success]),
  );
  const errorEquationMapsData = useAppSelector((state) =>
    selectEquationMapsDataByStatuses(state, [LoadStatus.error]),
  );
  const inProgressEquationMapsData = useAppSelector((state) =>
    selectEquationMapsDataByStatuses(state, [LoadStatus.loading]),
  );
  const successEquationMapsUuids = successEquationMapsData
    .map(({ uuid }) => uuid)
    .filter((uuid): uuid is string => !!uuid);
  const successEquationMapsEntities = useAppSelector((state) =>
    selectEquationMapsEntitiesList(state, successEquationMapsUuids),
  );
  const { productUnit } = useAppSelector(selectCurrentEquation);
  const fieldsEntities = useAppSelector((state) =>
    selectFieldsEntitiesList(state, selectedFieldsUuids),
  );
  const areaUnit = useAppSelector(selectAreaUnit);

  const areaUnitLabel = t(getI18nAreaUnit(areaUnit));

  const fieldsAreaSum = useMemo(
    () => fieldsEntities.reduce((acc, field) => acc + (field.area || 0), 0),
    [fieldsEntities],
  );

  const weightedSum = useMemo(
    () => calculateWeightedSum(successEquationMapsEntities),
    [successEquationMapsEntities],
  );

  const weightedAvg = useMemo(
    () => calculateWeightedAvg(successEquationMapsEntities),
    [successEquationMapsEntities],
  );

  const productUnitLabel = useMemo(() => {
    if (!productUnit) {
      return '';
    }

    return t(PRODUCT_UNIT_TO_I18N_LABEL[productUnit]);
  }, [productUnit, t]);

  const absoluteUnitLabel = useMemo(() => {
    if (!productUnit) {
      return '';
    }

    const absoluteUnit = convertProductUnitToAbsoluteUnit(productUnit);

    return t(ABSOLUTE_UNIT_TO_I18N_LABEL[absoluteUnit]);
  }, [productUnit, t]);

  const generationInProgress = inProgressEquationMapsData.length !== 0;

  const avg = useMemo(() => {
    if (!successEquationMapsData.length) {
      return DASH;
    }

    return weightedAvg != null
      ? `${prettifyNumber(weightedAvg)} ${productUnitLabel}`
      : t('batch-equation-based.steps.preview.summary.not-applicable');
  }, [productUnitLabel, successEquationMapsData.length, weightedAvg, t]);

  const sum = useMemo(() => {
    if (!successEquationMapsData.length) {
      return DASH;
    }

    return weightedSum != null
      ? `${prettifyNumber(weightedSum)} ${absoluteUnitLabel}`
      : t('batch-equation-based.steps.preview.summary.not-applicable');
  }, [successEquationMapsData.length, weightedSum, absoluteUnitLabel, t]);

  const summary = useMemo(
    () =>
      getPreviewSummary({
        fieldsAmount: selectedFieldsUuids.length,
        fieldsArea: prettifyNumber(fieldsAreaSum, 3),
        areaUnitLabel,
        avg,
        sum,
        generationInProgress,
        inProgressEquationMapsAmount: inProgressEquationMapsData.length,
        errorEquationMapsAmount: errorEquationMapsData.length,
        successEquationMapsAmount: successEquationMapsData.length,
      }),
    [
      areaUnitLabel,
      errorEquationMapsData.length,
      fieldsAreaSum,
      generationInProgress,
      inProgressEquationMapsData.length,
      selectedFieldsUuids.length,
      successEquationMapsData.length,
      avg,
      sum,
    ],
  );

  return <SummaryPanel summary={summary} />;
}
