import React, { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import LinkIcon from '@material-ui/icons/Link';
import DateRangeIcon from '@material-ui/icons/DateRange';

import { selectCurrentEquation } from '../../../../equations/equationsSelectors';
import {
  selectTitleTemplate,
  selectType,
} from '../../createBatchEquationBasedAnalysisSelectors';
import { PRODUCT_UNIT_TO_I18N_LABEL } from '../../../../../helpers/constants/units/productUnit';
import { PURPOSES_I18N_KEY_LABEL_MAP } from '../../../../../helpers/analysis';
import {
  DEFAULT_EQUATION_MAP_TITLE_TEMPLATE,
  EQUATION_MAP_TITLE_TEMPLATE_OPTIONS,
  TitleTemplateKey,
} from '../../helpers/constants/equationMap';
import TitleExample from '../../components/TitleExample';
import { useAppDispatch } from '../../../../../app/store/helpers/functions';
import { setTitleTemplate } from '../../createBatchEquationBasedAnalysisSlice';
import { TitleTemplateItem } from '../../types/equationMap';
import { generateEquationMapTitleExample } from '../../helpers/functions/equationMap';

import './index.scss';

const TemplateEditor = React.lazy(
  () => import('../../../../../components/TemplateEditor'),
);

export default function EquationSummary() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const equation = useSelector(selectCurrentEquation);
  const purpose = useSelector(selectType);
  const titleTemplate = useSelector(selectTitleTemplate);
  const productUnitLabel = equation.productUnit
    ? t(PRODUCT_UNIT_TO_I18N_LABEL[equation.productUnit])
    : t('general.controls.rate-units.none');

  const [expanded, setExpanded] = useState(false);
  const collapsedData = useMemo(
    () => [
      {
        title: t('general.controls.map-legend.equation-details.name'),
        content: equation.title,
        expandButton: true,
      },
      {
        title: t('general.controls.map-legend.equation-details.equation'),
        content: equation.equationAsText,
      },
      {
        title: t('general.controls.map-legend.equation-details.variables'),
        content: equation.dataVariables.join(', '),
      },
      {
        title: t(
          'zones-ops.equation-based.steps.4.panel.output-variable-title',
        ),
        content: equation.equationResultVariable,
      },
      {
        title: t('zones-ops.equation-based.steps.4.panel.product-unit'),
        content: productUnitLabel,
      },
      {
        title: t('general.controls.purpose'),
        content: t(PURPOSES_I18N_KEY_LABEL_MAP[purpose]),
      },
    ],
    [equation, purpose, productUnitLabel, t],
  );
  const expandedData = useMemo(
    () => [
      {
        title: t('general.controls.map-legend.equation-details.variables'),
        content: equation.dataVariables.join(', '),
      },
      {
        title: t(
          'zones-ops.equation-based.steps.4.panel.output-variable-title',
        ),
        content: equation.equationResultVariable,
      },
      {
        title: t('zones-ops.equation-based.steps.4.panel.product-unit'),
        content: productUnitLabel,
      },
      {
        title: t('general.controls.purpose'),
        content: t(PURPOSES_I18N_KEY_LABEL_MAP[purpose]),
      },
    ],
    [equation, purpose, productUnitLabel, t],
  );

  const titleExample = generateEquationMapTitleExample({
    titleTemplate,
    equationTitle: equation.title,
  });

  const handleToggleExpandedClick = () => {
    setExpanded(!expanded);
  };

  const handleTitleTemplateChange = (template: TitleTemplateItem[]) => {
    dispatch(setTitleTemplate(template));
  };

  const getTitleTemplateItemLabel = ({ key, value = '' }: TitleTemplateItem) =>
    key
      ? t(`batch-equation-based.steps.preview.title-template.options.${key}`)
      : value;

  const getTitleTemplateItemIcon = ({ key }: TitleTemplateItem) => {
    if (!key) {
      return null;
    }
    if (key === TitleTemplateKey.currentDate) {
      return DateRangeIcon;
    }

    return LinkIcon;
  };

  return (
    <div className="equation-summary__wrapper">
      <div
        className={clsx('equation-summary', {
          'equation-summary_expanded': expanded,
          'equation-summary_collapsed': !expanded,
        })}
      >
        {expanded ? (
          <>
            <div className="equation-summary__expanded-section">
              <Typography className="equation-summary__expanded-section-item-title">
                {t('general.controls.map-legend.equation-details.name')}:
              </Typography>
              <Typography className="equation-summary__expanded-section-equation-title">
                {equation.title}
              </Typography>
              <Typography className="equation-summary__expanded-section-item-title">
                {t('general.controls.map-legend.equation-details.equation')}:
              </Typography>
              <Typography className="equation-summary__expanded-section-equation">
                {equation.equationAsText}
              </Typography>
              <Link
                className="equation-summary__expanded-section-button link"
                component="button"
                onClick={handleToggleExpandedClick}
              >
                {t('general.shared.show-less')}
              </Link>
            </div>
            <div className="equation-summary__expanded-section">
              {expandedData.map(({ title, content }, index) => (
                <div
                  key={index}
                  className="equation-summary__expanded-section-item"
                >
                  <Typography className="equation-summary__expanded-section-item-title">
                    {title}:
                  </Typography>
                  <Typography className="equation-summary__expanded-section-item-content">
                    {content}
                  </Typography>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            {collapsedData.map(({ title, content, expandButton }, index) => (
              <Fragment key={index}>
                <div className="equation-summary__collapsed-section">
                  <Typography className="equation-summary__collapsed-section-title">
                    {title}:
                  </Typography>
                  <Typography className="equation-summary__collapsed-section-content">
                    {content}
                  </Typography>
                  {expandButton && (
                    <Link
                      className="equation-summary__collapsed-section-button link"
                      component="button"
                      onClick={handleToggleExpandedClick}
                    >
                      {t('general.shared.show-more')}
                    </Link>
                  )}
                </div>
              </Fragment>
            ))}
          </>
        )}
      </div>
      <div className="equation-summary__map-title-section">
        <div className="equation-summary__map-title-section-item">
          <TemplateEditor
            label={t('batch-equation-based.steps.preview.title-template.label')}
            value={titleTemplate}
            defaultValue={DEFAULT_EQUATION_MAP_TITLE_TEMPLATE}
            options={EQUATION_MAP_TITLE_TEMPLATE_OPTIONS}
            onChange={handleTitleTemplateChange}
            getItemLabel={getTitleTemplateItemLabel}
            getItemIcon={getTitleTemplateItemIcon}
          />
        </div>
        <div className="equation-summary__map-title-section-item">
          <TitleExample value={titleExample} />
        </div>
      </div>
    </div>
  );
}
