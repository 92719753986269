import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Legend from '../../../../../components/Legend';
import AssetLegendContent from '../../../../../components/Legend/AssetLegendContent';
import { isRequiredDataLoaded } from '../../../../../helpers/components/legend';
import { closeLegend } from '../../cloneZonesMapSlice';
import {
  fetchVamapAssets,
  fetchVamapAttributesJson,
} from '../../../../field/fieldSlice';
import {
  selectLegendOpen,
  selectSelectedVamapUuid,
  selectSelectedZonesMap,
} from '../../cloneZonesMapSelectors';
import {
  selectField,
  selectFullyLoaded,
} from '../../../../field/fieldSelectors';
import { selectSelectedField } from '../../../zonesOps/zonesOpsSelectors';
import { selectApiKey, selectAreaUnit } from '../../../../user/userSelectors';

const CloneZonesMapLegend = ({
  isOpen,
  loading,
  legend,
  field,
  uuid,
  areaUnit,
  apiKey,
  onLegendClose,
  requestAssets,
  requestAttributes,
}) => {
  useEffect(() => {
    if (!isOpen) {
      return;
    }

    requestAttributes(uuid);
    requestAssets();
  }, [uuid, isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isOpen) {
    return null;
  }

  return (
    <Legend
      loading={!loading}
      title={legend.name}
      type={legend.assetType}
      content={
        <AssetLegendContent
          item={legend}
          field={field}
          areaUnit={areaUnit}
          apiKey={apiKey}
        />
      }
      onClose={onLegendClose}
    />
  );
};

const mapStateToProps = (state) => {
  const legend = selectSelectedZonesMap(state);
  const { farmUuid, fieldUuid } = selectSelectedField(state);
  const field = selectField(state);

  return {
    isOpen: legend && selectLegendOpen(state),
    legend,
    uuid: selectSelectedVamapUuid(state),
    loading: isRequiredDataLoaded(legend, field, selectFullyLoaded(state)),
    field,
    farmUuid,
    fieldUuid,
    areaUnit: selectAreaUnit(state),
    apiKey: selectApiKey(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLegendClose: () => {
    dispatch(closeLegend());
  },
  requestAssets: (farmUuid, fieldUuid, vamap) => {
    dispatch(
      fetchVamapAssets({
        farmUuid,
        fieldUuid,
        vamap,
      }),
    );
  },
  requestAttributes: (uuid) => {
    dispatch(fetchVamapAttributesJson({ uuid }));
  },
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,
  requestAssets: () =>
    propsFromDispatch.requestAssets(
      propsFromState.farmUuid,
      propsFromState.fieldUuid,
      propsFromState.legend,
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(CloneZonesMapLegend);
