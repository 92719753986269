import i18n from 'i18next';
import {
  sortGeojsonFeatures,
  ISOXML_STATUSES,
  ISOXML_PURPOSE_TO_PRODUCT_UNITS,
} from '../../analysis';
import { VectorAnalysisMapType } from '../../constants/entities/vectorAnalysisMap';
import { captureException } from '../utils/errorHandling';
import { prettifyNumber } from '../../markup';

const getEquationMapTableHeaders = () => [
  {
    id: 'color',
    label: i18n.t('zones-ops.multi-layer.steps.4.results-table.color'),
    align: 'left',
  },
  {
    id: 'value',
    label: i18n.t('zones-ops.equation-based.steps.5.results-table.value'),
    align: 'right',
  },
];

const getEquationMapTableRows = (headers, geoMap) => {
  const { classes, hexColorCodes } = geoMap;
  const isRangeValues = hexColorCodes.length + 1 === classes.length;

  return hexColorCodes.map((color, index) => ({
    id: index + 1,
    data: headers.reduce((acc, { id }) => {
      if (id === 'color') {
        acc[id] = {
          color,
        };
      } else {
        const value = classes[index];
        let text;

        if (value == null) {
          text = '-';
        } else if (isRangeValues) {
          text = `${prettifyNumber(value)} - ${prettifyNumber(classes[index + 1])}`;
        } else {
          text = prettifyNumber(value);
        }

        acc[id] = {
          text,
        };
      }

      return acc;
    }, {}),
  }));
};

export const getEquationMapTableData = ({ geoMap }) => {
  const headers = getEquationMapTableHeaders();

  return {
    headers,
    rows: getEquationMapTableRows(headers, geoMap),
  };
};

export const prepareEquationMapJson = (geojson) => {
  let result;

  try {
    result = {
      ...geojson,
      features: sortGeojsonFeatures(geojson.features),
    };
  } catch (error) {
    captureException({
      message: 'Unable to prepare equation map geojson.',
      error,
    });
    result = geojson;
  }

  return result;
};

export const getPixelsGeoMap = (equationMap = {}) =>
  (equationMap?.geoMaps || []).find((geoMap) => geoMap.shortName === 'pixels');

export const isInvalid = (anEquationMap) =>
  !anEquationMap ||
  anEquationMap.status === 'ERROR' ||
  !anEquationMap.geoMaps ||
  anEquationMap.geoMaps.length === 0;

export const calculateEquationMapIsoXmlStatus = (equationMap = {}) => {
  const { type, productUnit } = equationMap;

  const isTypeValid =
    type === VectorAnalysisMapType.seeding ||
    type === VectorAnalysisMapType.spraying ||
    type === VectorAnalysisMapType.fertilizing;

  const isProductUnitValid =
    productUnit && ISOXML_PURPOSE_TO_PRODUCT_UNITS[type]?.includes(productUnit);

  return !isProductUnitValid || !isTypeValid
    ? ISOXML_STATUSES.INVALID
    : ISOXML_STATUSES.VALID;
};

export const getEquationMapThumbnailGeoMap = (geoMaps = []) =>
  geoMaps.find(({ shortName }) => shortName === 'thumbnail_pixels');
