import i18n from 'i18next';

import type { ButtonMenuItem } from '../../../../components/ButtonMenu/types';
import type { Equation } from '../../types/equation';
import type { EquationListRow } from '../../types/ui';
import { EquationCategory } from '../constants/equations';

export const getEquationListRows = (
  equations: Equation[],
  selectedEquationUuid: string | null,
  category: EquationCategory,
): EquationListRow[] =>
  equations.map((equation) => ({
    ...equation,
    rowType: 'default',
    id: equation.uuid,
    selected: equation.uuid === selectedEquationUuid,
    allowSetSourceUrl:
      category === EquationCategory.customUser && !equation.sourceUrl,
  }));

export const getEquationMenuItems = (
  category: EquationCategory,
): ButtonMenuItem[] => {
  let result: ButtonMenuItem[];

  if (category === EquationCategory.customUser) {
    result = [
      {
        id: 'edit',
        label: i18n.t(
          'zones-ops.equation-based.steps.3.panel.menu-items.edit-equation',
        ),
      },
      {
        id: 'delete',
        label: i18n.t(
          'zones-ops.equation-based.steps.3.panel.menu-items.delete-equation',
        ),
      },
    ];
  } else {
    result = [];
  }

  return result;
};
