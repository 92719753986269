import React, { ChangeEvent, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { List, ListItem, Popover, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { TransformedEquationMap } from '../../helpers/types/equationMap';
import { TransformedVectorAnalysisMap } from '../../helpers/types/vectorAnalysisMap';
import { getThumbnailGeoMap } from '../../helpers/functions/entities/vectorAnalysisMap';
import { getThumbnailUrl } from '../../helpers/functions/utils/url';
import { getEquationMapThumbnailGeoMap } from '../../helpers/functions/entities/equationMap';
import AssetsSelectItem from './AssetsSelectItem';
import VamapTypeLabel from '../VamapTypeLabel';
import Button from '../Button';
import TextField from '../TextField';
import getProductsFilteredByName from '../../features/jdProducts/helpers/functions/getProductsFilteredByName';
import { AssetsSelectOption, AssetsSelectItemOptionValue } from './types';
import getAssetsSortedByName from './helpers/functions/getAssetsSortedByName';

import './index.scss';

const ITEM_PREVIEW_HEIGHT = 50;

export default function AssetsSelect({
  apiKey,
  assets,
  selectedAssetUuid,
  isAssetDisabled,
  getAssetIcon,
  onAssetSelect,
}: {
  apiKey: string;
  assets: {
    vectorAnalysisMaps?: TransformedVectorAnalysisMap[];
    equationMaps?: TransformedEquationMap[];
  };
  selectedAssetUuid?: string;
  isAssetDisabled?: (map: AssetsSelectItemOptionValue) => boolean;
  getAssetIcon?: (map: AssetsSelectItemOptionValue) => ReactNode;
  onAssetSelect: (map: AssetsSelectItemOptionValue | null) => void;
}) {
  const { t } = useTranslation();
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [nameFilterValue, setNameFilterValue] = useState('');
  const assetsItemControlsRef = React.useRef(null);

  const vamapOptions = useMemo(() => {
    const sortedVamaps = getAssetsSortedByName(assets.vectorAnalysisMaps);

    return (
      sortedVamaps.map((item) => {
        const thumbnail = getThumbnailGeoMap(item.geoMaps);

        return {
          name: item.name || '',
          value: item,
          type: item.assetType,
          label: <VamapTypeLabel entity={item} />,
          icon: getAssetIcon?.(item),
          disabled: isAssetDisabled?.(item),
          src: getThumbnailUrl({
            thumbnail,
            size: ITEM_PREVIEW_HEIGHT,
            apiKey,
          }),
        };
      }) ?? []
    );
  }, [apiKey, assets.vectorAnalysisMaps, getAssetIcon, isAssetDisabled]);

  const equationMapOptions = useMemo(() => {
    const sortedEquationMaps = getAssetsSortedByName(assets.equationMaps);

    return (
      sortedEquationMaps.map((item) => {
        const thumbnail = getEquationMapThumbnailGeoMap(item.geoMaps);

        return {
          name: item.name || '',
          value: item,
          type: item.assetType,
          icon: getAssetIcon?.(item),
          disabled: isAssetDisabled?.(item),
          src: getThumbnailUrl({
            thumbnail,
            size: ITEM_PREVIEW_HEIGHT,
            apiKey,
          }),
        };
      }) ?? []
    );
  }, [apiKey, assets.equationMaps, getAssetIcon, isAssetDisabled]);

  const assetsOptions = useMemo(
    () => [...vamapOptions, ...equationMapOptions],
    [vamapOptions, equationMapOptions],
  );

  const filteredAssetsOptions: AssetsSelectOption[] = useMemo(
    () => getProductsFilteredByName(assetsOptions, nameFilterValue),
    [assetsOptions, nameFilterValue],
  );

  const selectedAsset = useMemo(
    () => assetsOptions.find(({ value }) => selectedAssetUuid === value.uuid),
    [assetsOptions, selectedAssetUuid],
  );

  const handleAssetChange = (item: AssetsSelectOption) => {
    if (item.disabled) {
      return;
    }

    onAssetSelect(item?.value ?? null);
    setIsSelectOpen(false);
    setNameFilterValue('');
  };

  const handleDeleteAsset = () => {
    onAssetSelect(null);
  };

  const handleSelectOpen = () => {
    setIsSelectOpen(true);
  };

  const assetsTitle = useMemo(() => {
    const availableMapTypes = [];

    if (assets.vectorAnalysisMaps) {
      availableMapTypes.push(t('assets-select.asset-type.vector-analysis-map'));
    }

    if (assets.equationMaps) {
      availableMapTypes.push(t('assets-select.asset-type.equation-map'));
    }

    return availableMapTypes.join('/');
  }, [assets, t]);

  return (
    <div className="assets-select">
      <div className="assets-select__controls">
        <Typography className="assets-select__title">
          {t('assets-select.title', { assetsTitle })}
        </Typography>
        {selectedAsset ? (
          <div className="assets-select__edit-asset-controls">
            <Button
              classes={{
                root: 'assets-select__button',
              }}
              variant="text"
              onClick={handleDeleteAsset}
            >
              {t('general.controls.delete')}
            </Button>
            <Button
              classes={{
                root: 'assets-select__button',
              }}
              variant="text"
              onClick={handleSelectOpen}
            >
              {t('general.controls.change')}
            </Button>
          </div>
        ) : (
          <Button
            classes={{
              root: 'assets-select__button',
            }}
            variant="text"
            onClick={handleSelectOpen}
          >
            {`+ ${t('assets-select.select-map')}`}
          </Button>
        )}
      </div>

      <div ref={assetsItemControlsRef}>
        {selectedAsset ? (
          <AssetsSelectItem item={selectedAsset} />
        ) : (
          <Typography className="assets-select__no-item-selected">
            {t('assets-select.map-not-selected', { assetsTitle })}
          </Typography>
        )}
      </div>

      {isSelectOpen && (
        <Popover
          classes={{
            paper: 'assets-select__items-paper',
          }}
          anchorEl={assetsItemControlsRef.current}
          open={isSelectOpen}
          onClose={() => {
            setIsSelectOpen(false);
            setNameFilterValue('');
          }}
        >
          <TextField
            type="search"
            classes={{
              wrapper: 'assets-select__filter',
            }}
            placeholder={t('general.controls.filters.name-filter-placeholder')}
            value={nameFilterValue}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setNameFilterValue(event.target.value);
            }}
            onEndAdornmentClick={() => setNameFilterValue('')}
          />
          <List className="assets-select__items">
            {filteredAssetsOptions.map((item) => (
              <ListItem
                key={item.value.uuid}
                disabled={item.disabled}
                selected={item.value.uuid === selectedAsset?.value?.uuid}
                onClick={() => handleAssetChange(item)}
                className={clsx('assets-select__item', {
                  'assets-select__item_disabled': item.disabled,
                })}
              >
                <AssetsSelectItem item={item} />
              </ListItem>
            ))}
          </List>
        </Popover>
      )}
    </div>
  );
}
