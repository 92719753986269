import React, { ReactElement } from 'react';

import AppShellHeaderComponent from '../../components/Header';
import { isApplicationShellReportIssueEnabled } from '../../../../../helpers/functions/utils/appConfig';
import PricingPlanStatus from '../PricingPlanStatus';
import useReportIssuePopup from '../../../../../hooks/useReportIssuePopup';
import useAuthenticate from '../../../authentication/hooks/useAuthenticate';

export default function AppShellHeader({
  text,
  secondaryText,
  withBackAction,
  onBackButtonClick,
}: {
  text: ReactElement;
  secondaryText: ReactElement;
  withBackAction?: boolean;
  onBackButtonClick?: () => void;
}) {
  const { openReportIssuePopup } = useReportIssuePopup();
  const { signOut } = useAuthenticate();

  return (
    <AppShellHeaderComponent
      withReportIssue={isApplicationShellReportIssueEnabled()}
      text={text}
      secondaryText={secondaryText}
      withBackAction={withBackAction}
      onBackButtonClick={onBackButtonClick}
      onLogOutClick={() => signOut()}
      onReportIssueClick={() => openReportIssuePopup()}
    >
      <PricingPlanStatus key="pricing-plan-status" />
    </AppShellHeaderComponent>
  );
}
