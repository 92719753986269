import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Link from '../../../../../components/Link';
import LastOperationsSummaryItem from '../LastOperationsSummaryItem';
import {
  OperationType,
  OPERATION_TYPES,
} from '../../../operationsWorkflow/helpers/constants/operation';
import type { Operation } from '../../../operationsWorkflow/types/operation';
import { formatDateTime } from '../../../../../helpers/functions/utils/date';
import { countOperations } from '../../../operationsWorkflow/helpers/functions/operations';
import { getOperationTypeLink } from '../../../operationsWorkflow/helpers/functions/navigation';
import { getRootLink, PAGES_ROOTS } from '../../../../../helpers/navigation';

import './index.scss';

const DISPLAYED_OPERATION_TYPES_AMOUNT = 3;

export default function LastOperations({
  operations,
  isFetching,
}: {
  isFetching: boolean;
  operations: Record<OperationType, Operation[]>;
}) {
  const { t } = useTranslation();

  return (
    <div className="last-operations">
      <Typography variant="h3" className="last-operations__title">
        {t('homepage.last-operations')}
      </Typography>
      <div className="last-operations__items">
        {OPERATION_TYPES.slice(0, DISPLAYED_OPERATION_TYPES_AMOUNT).map(
          (type) => {
            const ops = operations[type];
            const lastUpdate = ops?.[0]?.subOperations?.[0]?.executionDate;
            const { inProgress, done, error, ignored } = countOperations(ops);
            const total = inProgress + done + error + ignored;

            return (
              <div key={type} className="last-operations__item">
                <div className="last-operations__item-header">
                  <Typography className="last-operations__item-header-title">
                    {t(`operations.types.${type}`)}
                  </Typography>
                  <Typography className="last-operations__item-header-info">
                    {formatDateTime(lastUpdate)}
                  </Typography>
                </div>
                <div className="last-operations__item-summary">
                  <LastOperationsSummaryItem
                    type="total"
                    value={total}
                    isFetching={isFetching}
                  />
                  <LastOperationsSummaryItem
                    type="in-progress"
                    value={inProgress}
                    isFetching={isFetching}
                  />
                  <LastOperationsSummaryItem
                    type="success"
                    value={done}
                    isFetching={isFetching}
                  />
                  <LastOperationsSummaryItem
                    type="ignored"
                    value={ignored}
                    isFetching={isFetching}
                  />
                  <LastOperationsSummaryItem
                    type="error"
                    value={error}
                    isFetching={isFetching}
                  />
                </div>
                <div className="last-operations__item-footer">
                  <Link to={getOperationTypeLink(type)}>
                    {t('homepage.view-details')}
                  </Link>
                </div>
              </div>
            );
          },
        )}
        <div className="last-operations__view-all">
          <Link
            className="last-operations__view-all-link"
            to={getRootLink(PAGES_ROOTS.operations)}
          >
            <ArrowForwardIcon fontSize="small" />
            {t('homepage.view-all')}
          </Link>
        </div>
      </div>
    </div>
  );
}
