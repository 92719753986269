import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import i18n from 'i18next';

import {
  fetchFarmFields as fetchFarmFieldsAPI,
  fetchFieldWithExportAssets as fetchFieldWithExportAssetsAPI,
  exportData as exportDataAPI,
  exportIsoXmlData as exportIsoXmlDataAPI,
  fetchFieldLegendData,
} from './exportDataAPI';
import { fetchVamapAttributesJson } from '../field/fieldAPI';
import { errorNotify } from '../notifications/helpers/functions/notify';
import { CustomError } from '../../helpers/functions/utils/errorHandling';
import {
  processingNotify,
  closeProcessingNotification,
} from '../notifications/notificationsSlice';
import { getUserDataFetcher } from '../ui/applicationShell/applicationShellSlice';
import { selectAreaUnit } from '../user/userSelectors';
import { getExportFilePostfix } from '../../helpers/functions/utils/appConfig';
import {
  calculateIsoxmlStatusValidAssets,
  ISOXML_STATUSES,
} from '../../helpers/analysis';
import { openPopup } from '../ui/popups/popupsSlice';
import ExportType from './helpers/constants/exportType';
import { isValidUrl } from '../../helpers/functions/utils/url';
import exportDataAdapter from './exportDataAdapter';
import { enrichFieldAssets } from './helpers/functions/assets';
import { isRequiredDataLoaded } from '../../helpers/components/legend';
import {
  selectAllVectorAnalysisMaps,
  selectAsset,
  selectField,
} from './exportDataSelectors';
import { AssetGroupType } from '../../helpers/constants/entities/asset';
import { transform as transformSatelliteImages } from '../satelliteImages/helpers/functions/satelliteImages';
import {
  transformAsAppliedDatasets,
  transformFields,
  transformSoilDatasets,
  transformTopographyMaps,
  transformYieldDatasets,
} from '../field/helpers/functions/assets';
import { calculateEquationMapIsoXmlStatus } from '../../helpers/functions/entities/equationMap';
import POPUPS from '../ui/popups/helpers/constants/popups';

const initialState = {
  farms: exportDataAdapter.getInitialState(),
  fields: exportDataAdapter.getInitialState(),
  vectorAnalysisMaps: exportDataAdapter.getInitialState(),
  pinsGroups: exportDataAdapter.getInitialState(),
};

export const fetchFarmFields = createAsyncThunk(
  'exportData/fetchFarmFields',
  ({ uuid }, { dispatch }) =>
    fetchFarmFieldsAPI(uuid).catch((error) => {
      errorNotify({
        error: new CustomError('[Export Data] Unable to fetch farm fields.', {
          cause: error,
        }),
        dispatch,
      });

      throw error;
    }),
);

export const fetchFieldWithExportAssets = createAsyncThunk(
  'exportData/fetchFieldWithExportAssets',
  async ({ farmUuid, fieldUuid }, { getState }) => {
    await getUserDataFetcher();

    const state = getState();
    const areaUnit = selectAreaUnit(state);

    return fetchFieldWithExportAssetsAPI(farmUuid, fieldUuid, areaUnit).then(
      (field) => {
        const [transformedField] = transformFields([field]);

        return enrichFieldAssets(transformedField);
      },
    );
  },
);

export const fetchZonesMapAttributesJson = createAsyncThunk(
  'exportData/fetchZonesMapAttributesJson',
  async ({ farmUuid, fieldUuid, uuid }, { dispatch, getState }) => {
    const state = getState();
    const areaUnit = selectAreaUnit(state);

    return fetchVamapAttributesJson({
      farmUuid,
      fieldUuid,
      uuid,
      areaUnit,
    }).catch((error) => {
      errorNotify({
        error: new CustomError(
          '[Export Data] Unable to fetch zones map attributes JSON.',
          {
            cause: error,
          },
        ),
        dispatch,
      });
    });
  },
);

export const setupLegend = createAsyncThunk(
  'exportData/setupLegend',
  ({ farmUuid, fieldUuid, uuid, type }, { getState }) => {
    const state = getState();
    const field = selectField(state, fieldUuid);
    const asset = selectAsset(state, uuid, type);
    const vectorAnalysisMaps = selectAllVectorAnalysisMaps(state);
    const legendField = {
      ...field,
      vectorAnalysisMaps,
    };
    const requiredDataLoaded = isRequiredDataLoaded(asset, legendField, {
      metadata: !!field,
      [AssetGroupType.satelliteImages]: Array.isArray(field.satelliteImages),
      [AssetGroupType.soilDatasets]: Array.isArray(field.soilDatasets),
      [AssetGroupType.yieldDatasets]: Array.isArray(field.yieldDatasets),
      [AssetGroupType.asAppliedDatasets]: Array.isArray(
        field.asAppliedDatasets,
      ),
      [AssetGroupType.topographyMaps]: Array.isArray(field.topographyMaps),
      [AssetGroupType.vectorAnalysisMaps]: Array.isArray(
        field.vectorAnalysisMaps,
      ),
    });

    let result;

    if (requiredDataLoaded) {
      result = Promise.resolve();
    } else {
      result = fetchFieldLegendData(farmUuid, fieldUuid).then(
        ({
          satelliteImages,
          soilDatasets,
          yieldDatasets,
          asAppliedDatasets,
          topographyMaps,
        }) => ({
          satelliteImages: transformSatelliteImages(satelliteImages),
          soilDatasets: transformSoilDatasets(soilDatasets),
          yieldDatasets: transformYieldDatasets(yieldDatasets),
          asAppliedDatasets: transformAsAppliedDatasets(asAppliedDatasets),
          topographyMaps: transformTopographyMaps(topographyMaps),
        }),
      );
    }

    return result;
  },
);

export const exportAsset =
  ({
    vectorAnalysisMaps,
    fieldBoundaries,
    pinsGroups,
    equationMaps,
    asMultiGeometry,
    onlyProductColumns,
    archiveName,
  }) =>
  (dispatch) => {
    dispatch(
      processingNotify({
        message: i18n.t('export.notifications.downloadable-created'),
      }),
    );

    return exportDataAPI({
      filePostfix: getExportFilePostfix(),
      vectorAnalysisMaps,
      fieldBoundaries,
      notes: pinsGroups,
      equationMaps,
      asMultiGeometry,
      onlyProductColumns: !!onlyProductColumns,
      archiveName,
    })
      .then((archiveUrl) => {
        dispatch(closeProcessingNotification());
        if (isValidUrl(archiveUrl)) {
          window.location.href = archiveUrl;
        } else {
          errorNotify({
            error: new CustomError(
              `[ExportData] archiveUrl is invalid: ${archiveUrl}`,
            ),
            dispatch,
          });
        }
      })
      .catch((error) => {
        dispatch(closeProcessingNotification());
        errorNotify({
          error: new CustomError('[Export Data] Unable to export asset.', {
            cause: error,
          }),
          dispatch,
        });
      });
  };

export const exportIsoXmlAsset =
  ({ applicationMaps, equationMaps, archiveName }) =>
  (dispatch) => {
    const filePostfix = getExportFilePostfix();
    dispatch(
      processingNotify({
        message: i18n.t('export.notifications.downloadable-created'),
      }),
    );

    return exportIsoXmlDataAPI({
      applicationMaps,
      equationMaps,
      filePostfix,
      archiveName,
    })
      .then((archiveUrl) => {
        dispatch(closeProcessingNotification());
        if (isValidUrl(archiveUrl)) {
          window.location.href = archiveUrl;
        } else {
          errorNotify({
            error: new CustomError(
              `[ExportData] archiveUrl is invalid: ${archiveUrl}`,
            ),
            dispatch,
          });
        }
      })
      .catch((error) => {
        dispatch(closeProcessingNotification());
        errorNotify({
          error: new CustomError(
            '[Export Data] Unable to export ISOXML asset.',
            {
              cause: error,
            },
          ),
          dispatch,
        });
      });
  };

export const openExportEquationMapsPopup =
  ({ equationMaps = [] /* EquationMapEntity[] */, archiveName = '' }) =>
  (dispatch) => {
    const isoXmlStatus = equationMaps.every(
      (equationMap) =>
        calculateEquationMapIsoXmlStatus(equationMap) === ISOXML_STATUSES.VALID,
    )
      ? ISOXML_STATUSES.VALID
      : ISOXML_STATUSES.INVALID;

    dispatch(
      openPopup({
        type: POPUPS.exportEquationMap,
        isoXmlStatus,
        title: 'my custom title',
        onConfirm: (type) => {
          if (type === ExportType.isoxml) {
            const equationMapsByFieldUuid = equationMaps.reduce(
              (acc, equationMap) => {
                if (acc[equationMap.fieldUuid]) {
                  acc[equationMap.fieldUuid].push(equationMap);
                } else {
                  acc[equationMap.fieldUuid] = [equationMap];
                }

                return acc;
              },
              {},
            );
            const equationMapsUuids = Object.keys(equationMapsByFieldUuid).map(
              (fieldUuid) => ({
                fieldUuid,
                uuids: equationMapsByFieldUuid[fieldUuid].map(
                  ({ uuid }) => uuid,
                ),
              }),
            );

            dispatch(
              exportIsoXmlAsset({
                equationMaps: equationMapsUuids,
                archiveName,
              }),
            );
          } else {
            const equationMapsUuids = equationMaps.map(
              ({ uuid, fieldUuid }) => ({
                uuid,
                fieldUuid,
              }),
            );

            dispatch(
              exportAsset({
                equationMaps: equationMapsUuids,
                asMultiGeometry: type === ExportType.multipolygons,
                archiveName,
              }),
            );
          }
        },
      }),
    );
  };

export const openExportZonesMapPopup =
  ({
    zonesMaps = [],
    fieldBoundariesShpAssets = [],
    pinsGroups = [],
    archiveName,
    onExport = () => {},
  }) =>
  (dispatch) => {
    const preparedZonesMaps = zonesMaps.map(
      ({ zonesMapGeojson, ...zonesMap }) => {
        if (zonesMapGeojson) {
          return {
            ...zonesMap,
            attributes: zonesMapGeojson,
          };
        }

        return zonesMap;
      },
    );
    const { status: isoxmlStatus, validAssets: isoxmlZonesMapsAssets } =
      calculateIsoxmlStatusValidAssets(preparedZonesMaps);

    if (fieldBoundariesShpAssets.length !== 0 || pinsGroups.length !== 0) {
      isoxmlStatus.add(ISOXML_STATUSES.UNSUPPORTED_ASSET);
    }

    dispatch(
      openPopup({
        type: 'export-zones-map',
        isoxmlStatus,
        itemsCount: preparedZonesMaps.length,
        preparedZonesMaps,
        onExport,
        onConfirm: (type, ratesOnly) => {
          if (
            type === ExportType.multipolygons ||
            type === ExportType.polygons
          ) {
            const zonesMapsShpAssets = preparedZonesMaps.map(
              ({ uuid, fieldUuid }) => ({
                uuid,
                fieldUuid,
              }),
            );

            dispatch(
              exportAsset({
                vectorAnalysisMaps: zonesMapsShpAssets,
                fieldBoundaries: fieldBoundariesShpAssets,
                pinsGroups,
                asMultiGeometry: type === ExportType.multipolygons,
                onlyProductColumns: ratesOnly,
                archiveName,
              }),
            );
          } else if (type === ExportType.isoxml) {
            const assetsByFieldUuids = isoxmlZonesMapsAssets.reduce(
              (acc, zonesMap) => {
                if (acc[zonesMap.fieldUuid]) {
                  acc[zonesMap.fieldUuid].push(zonesMap.uuid);
                } else {
                  acc[zonesMap.fieldUuid] = [zonesMap.uuid];
                }

                return acc;
              },
              [],
            );
            const preparedAssets = Object.keys(assetsByFieldUuids).map(
              (fieldUuid) => ({
                fieldUuid,
                uuids: assetsByFieldUuids[fieldUuid],
              }),
            );

            dispatch(
              exportIsoXmlAsset({
                applicationMaps: preparedAssets,
                archiveName,
              }),
            );
          }
        },
      }),
    );
  };

export const exportDataSlice = createSlice({
  name: 'exportData',
  initialState,
  reducers: {
    setFarms(state, action) {
      const preparedFarms = action.payload.map((farm) => ({
        loading: false,
        loaded: false,
        ...farm,
      }));

      exportDataAdapter.setAll(state.farms, preparedFarms);
    },
    reset() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFarmFields.pending, (state, action) => {
        exportDataAdapter.updateOne(state.farms, {
          id: action.meta.arg.uuid,
          changes: {
            loading: true,
          },
        });
      })
      .addCase(fetchFarmFields.fulfilled, (state, action) => {
        const preparedFields = action.payload.map((field) => ({
          loading: false,
          loaded: false,
          ...field,
        }));

        exportDataAdapter.updateOne(state.farms, {
          id: action.meta.arg.uuid,
          changes: {
            loading: false,
            loaded: true,
          },
        });
        exportDataAdapter.addMany(state.fields, preparedFields);
      })
      .addCase(fetchFarmFields.rejected, (state, action) => {
        exportDataAdapter.updateOne(state.farms, {
          id: action.meta.arg.uuid,
          changes: {
            loading: false,
            loaded: true,
          },
        });
      })
      .addCase(fetchFieldWithExportAssets.pending, (state, action) => {
        exportDataAdapter.updateOne(state.fields, {
          id: action.meta.arg.fieldUuid,
          changes: {
            loading: true,
          },
        });
      })
      .addCase(fetchFieldWithExportAssets.fulfilled, (state, action) => {
        const { pinsGroups, vectorAnalysisMaps, ...field } = action.payload;
        exportDataAdapter.updateOne(state.fields, {
          id: action.meta.arg.fieldUuid,
          changes: {
            ...field,
            loading: false,
            loaded: true,
          },
        });
        exportDataAdapter.addMany(state.vectorAnalysisMaps, vectorAnalysisMaps);
        exportDataAdapter.addMany(state.pinsGroups, pinsGroups);
      })
      .addCase(fetchFieldWithExportAssets.rejected, (state, action) => {
        exportDataAdapter.updateOne(state.fields, {
          id: action.meta.arg.fieldUuid,
          changes: {
            loading: false,
            loaded: true,
          },
        });
      })
      .addCase(fetchZonesMapAttributesJson.fulfilled, (state, action) => {
        exportDataAdapter.updateOne(state.vectorAnalysisMaps, {
          id: action.meta.arg.uuid,
          changes: {
            attributes: action.payload,
          },
        });
      })
      .addCase(setupLegend.fulfilled, (state, action) => {
        exportDataAdapter.updateOne(state.fields, {
          id: action.meta.arg.fieldUuid,
          changes: action.payload,
        });
      });
  },
});

export const { setFarms, reset } = exportDataSlice.actions;

export default exportDataSlice.reducer;
