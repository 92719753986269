import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import UnhandledErrorIcon from './unhandledError.svg';
import { isApplicationShellReportIssueEnabled } from '../../../../../helpers/functions/utils/appConfig';

import './index.scss';

export default function ErrorBoundaryFallback({
  onReportIssueClick,
}: {
  onReportIssueClick: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div className="error-boundary-fallback">
      <Typography variant="h2" className="error-boundary-fallback__title">
        {t('general.notifications.oops-wrong')}
      </Typography>
      <p className="error-boundary-fallback__description">
        {isApplicationShellReportIssueEnabled() ? (
          <Trans i18nKey="error-fallback.description-with-report">
            Text
            <Link
              component="button"
              className="link"
              onClick={onReportIssueClick}
            >
              button
            </Link>
            text
          </Trans>
        ) : (
          t('error-fallback.description')
        )}
      </p>
      <UnhandledErrorIcon className="error-boundary-fallback__icon" />
    </div>
  );
}
