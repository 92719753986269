import { useDispatch, useSelector } from 'react-redux';

import { generateEquationMap } from '../../../createAnalysis/createAnalysisSlice';
import {
  selectName,
  selectGridXSize,
  selectGridYSize,
  selectType,
  selectDataVariables,
} from '../createEquationBasedAnalysisSelectors';
import { selectCurrentEquation } from '../../../equations/equationsSelectors';

export default function useGenerateEquationMap() {
  const dispatch = useDispatch();
  const name = useSelector(selectName);
  const gridXSize = useSelector(selectGridXSize);
  const gridYSize = useSelector(selectGridYSize);
  const type = useSelector(selectType);
  const currentEquation = useSelector(selectCurrentEquation);
  const dataVariables = useSelector(selectDataVariables);

  return () =>
    dispatch(
      generateEquationMap({
        name,
        gridXSize,
        gridYSize,
        type,
        equation: currentEquation.equationAsText,
        equationUuid: currentEquation.uuid,
        resultVariable: currentEquation.equationResultVariable,
        dataVariables,
        productUnit: currentEquation.productUnit || null,
        useNumpy: currentEquation.useNumpy,
      }),
    );
}
