import React from 'react';

import { selectApiKey, selectAreaUnit } from '../../../../user/userSelectors';
import { useGetFarmsQuery } from '../../../../farms/farmsAPI';
import { getFieldsTableRows } from '../../../fieldsList/helpers/functions/tableRows';
import FieldProfilerTable from '../../../fieldsList/components/Table';
import { getBaseTableColumns } from '../../../fieldProfiler/helpers/functions/tableColumns';
import { selectSelectedFieldsUuids } from '../../batchAnalyticsSelectors';
import { getRowsSelection } from '../../../tableView/helpers/functions/rows';
import {
  setFieldsSelection,
  toggleFieldSelection,
} from '../../batchAnalyticsSlice';
import { TransformedField } from '../../../../field/types/field';
import { DefaultFieldTableRow } from '../../../fieldsList/types/tableRow';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../app/store/helpers/functions';
import useInfiniteFieldsList from '../../../../fields/hooks/useInfiniteFieldsList';
import useFieldsListParams from '../../../fieldsList/hooks/useFieldsListParams';

export default function FieldsListTable() {
  const dispatch = useAppDispatch();
  const { data: farms, isFetching: farmsFetching } = useGetFarmsQuery();

  const selectedFieldsUuids = useAppSelector(selectSelectedFieldsUuids);
  const apiKey = useAppSelector(selectApiKey);
  const areaUnit = useAppSelector(selectAreaUnit);
  const fieldsListParams = useFieldsListParams();

  const { fields, fieldsLoading, fieldsLoadComplete, triggerNextPage } =
    useInfiniteFieldsList({
      farmUuid: fieldsListParams?.farmUuid,
      fieldName: fieldsListParams?.fieldName,
      labels: fieldsListParams?.labels,
      skip: !fieldsListParams,
    });

  const handleLoadMoreRows = () => {
    triggerNextPage();
  };

  const handleHeadCheckboxClick = (selected: boolean) => {
    const fieldsData = fields.map((field: TransformedField) => ({
      fieldUuid: field.uuid,
      farmUuid: field.farmUuid || '',
      area: field.area || 0,
    }));

    dispatch(
      setFieldsSelection({
        fields: fieldsData,
        selected,
      }),
    );
  };

  const handleRowCheckboxClick = (row: DefaultFieldTableRow) => {
    const field: TransformedField | undefined = fields.find(
      ({ uuid }) => uuid === row.id,
    );
    dispatch(
      toggleFieldSelection({
        fieldUuid: row.id,
        farmUuid: row.farmUuid || '',
        area: field?.area || 0,
      }),
    );
  };

  const rows = getFieldsTableRows({
    fields,
    farms: farms || [],
    apiKey,
    checkedUuids: selectedFieldsUuids,
  });
  const rowsSelection = getRowsSelection(rows);
  const columns = getBaseTableColumns({
    selectable: true,
    areaUnit,
    rowsSelection,
    onHeadCheckboxClick: handleHeadCheckboxClick,
    onRowCheckboxClick: handleRowCheckboxClick,
  });

  return (
    <FieldProfilerTable
      rows={rows}
      columns={columns}
      labelsExpanded={fieldsListParams?.labelsExpanded}
      loadComplete={fieldsLoadComplete}
      loading={fieldsLoading || farmsFetching}
      onLoadMoreRows={handleLoadMoreRows}
    />
  );
}
