import React, { useEffect, useState, ChangeEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import clsx from 'clsx';

import PopupHeader from '../PopupHeader';
import Button from '../../Button';
import TextField from '../../TextField';
import useCurrentPage from '../../../hooks/useCurrentPage';
import AmplitudeAnalytics, {
  EntityType,
} from '../../../helpers/classes/amplitudeAnalytics';

import './index.scss';

const DeleteEntity = ({
  i18nKey,
  i18nDescriptionKey,
  entityType,
  entityName = '',
  shouldDisplayPinsWarning,
  onCancel = () => {},
  onConfirm = () => {},
}: {
  i18nKey: string;
  i18nDescriptionKey?: string;
  entityType: EntityType;
  entityName: string;
  shouldDisplayPinsWarning: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}) => {
  const { t } = useTranslation();
  const confirmWord = t('general.popups.delete-confirm-word');
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(true);
  const page = useCurrentPage();

  useEffect(() => {
    if (page) {
      AmplitudeAnalytics.trackDeleteEntityPopupOpened({
        entityType,
        page,
      });
    }
  }, [page, entityType]);

  const handleConfirmInputChange = (e: ChangeEvent<{ value: string }>) => {
    setConfirmButtonDisabled(e.target.value !== confirmWord);
  };

  const handleConfirm = () => {
    AmplitudeAnalytics.trackEntityDeleted({
      entityType,
      page,
    });
    onConfirm();
  };

  return (
    <Dialog open>
      <PopupHeader
        title={t(`general.popups.delete-entity.title.${i18nKey}`)}
        onCancel={onCancel}
      />
      <DialogContent>
        <DialogContentText
          classes={{
            root: clsx('delete-entity-popup__content-text', {
              'delete-entity-popup__content-text_first_line':
                shouldDisplayPinsWarning,
            }),
          }}
        >
          <Trans
            i18nKey={`general.popups.delete-entity.description.${i18nDescriptionKey || 'default'}`}
          >
            Text{' '}
            <span className="delete-entity-popup__content-text_highlighted">
              {{ entityName }}
            </span>{' '}
            {{ confirmWord }} text
          </Trans>
        </DialogContentText>

        {shouldDisplayPinsWarning && (
          <DialogContentText
            classes={{
              root: 'delete-entity-popup__content-text',
            }}
          >
            {t('general.popups.delete-entity.pins-warning')}
          </DialogContentText>
        )}
        <TextField
          placeholder={confirmWord}
          onChange={handleConfirmInputChange}
        />
      </DialogContent>
      <DialogActions
        classes={{
          root: 'delete-entity-popup__actions',
        }}
      >
        <Button variant="outlined" onClick={onCancel}>
          {t('general.controls.cancel')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={confirmButtonDisabled}
          onClick={handleConfirm}
        >
          {t('general.controls.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteEntity;
