export const selectSelectedUuids = ({ createStabilityZones }) =>
  createStabilityZones.selectedUuids;

export const selectActiveUuid = ({ createStabilityZones }) =>
  createStabilityZones.activeUuid;

export const selectSettings = ({ createStabilityZones }) =>
  createStabilityZones.settings;

export const selectChanged = ({ createStabilityZones }) =>
  createStabilityZones.changed;

export const selectCloned = ({ createStabilityZones }) =>
  createStabilityZones.cloned;
