import {
  readFederatedSignInReturnUrl,
  readSsoAppUrl,
} from '../../../../../helpers/functions/utils/vendorConfigs/sso';
import {
  getRootLink,
  getSearchParam,
  PAGES_ROOTS,
} from '../../../../../helpers/navigation';
import { Provider, View } from '../constants/authentication';
import SearchParam from '../constants/searchParam';

export const getDefaultRoute = () => getRootLink(PAGES_ROOTS.homepage);

export const getCode = (search: string) =>
  getSearchParam(search, SearchParam.code);

export const getEmail = (search: string) =>
  getSearchParam(search, SearchParam.email);

export const getRedirectedFrom = (search: string) =>
  getSearchParam(search, SearchParam.redirectedFrom);

export const buildRedirectedFrom = (value: string) =>
  `?${SearchParam.redirectedFrom}=${value}`;

export const isSignUpRelatedView = (view: View) =>
  view === View.signUp ||
  view === View.signUpSent ||
  view === View.signUpConfirm;

export const getFederatedSignInUrl = (provider: Provider) => {
  const url = new URL(`${readSsoAppUrl()}/federated`);
  url.searchParams.set('provider', provider);
  url.searchParams.set('returnUrl', readFederatedSignInReturnUrl());

  return url.toString();
};
