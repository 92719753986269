import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';

import getCommonSteps from '../../../zonesOps/helpers/functions/getCommonSteps';
import DataLayers from '../../containers/Stepper/Steps/DataLayers';
import Settings from '../../containers/Stepper/Steps/Settings';
import Tooltip from '../../../../../components/Tooltip';
import SatelliteIndexesTooltip from '../../../zonesOps/components/Tooltips/SatelliteIndexesTooltip';
import { Step } from '../../../../../components/Stepper';

const getSteps = (withFieldSelection = false) => {
  let firstSteps: Step[] = [];

  if (withFieldSelection) {
    firstSteps = getCommonSteps();
  }

  return [
    ...firstSteps,
    {
      name: i18n.t('zones-ops.common.stepper.data-layers'),
      description: (
        <Trans i18nKey="zones-ops.field-potential.steps.3.description">
          text
          <Tooltip tooltip={<SatelliteIndexesTooltip withGFPI />}>
            tooltip
          </Tooltip>
          text
        </Trans>
      ),
      id: 'dataLayers',
      component: DataLayers,
    },
    {
      name: i18n.t('general.shared.settings'),
      description: (
        <>
          {i18n.t('zones-ops.common.processing-description')}{' '}
          {i18n.t('zones-ops.common.charge-description')}
        </>
      ),
      id: 'settings',
      component: Settings,
    },
  ];
};

export default getSteps;
