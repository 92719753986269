import { VectorAnalysisMapType } from '../../../../../helpers/constants/entities/vectorAnalysisMap';
import { AssetToExport } from '../../types/workPlan';
import { WorkPlanType } from '../constants/workPlan';

const getDefaultWorkPlanType = (asset?: AssetToExport | null) =>
  asset?.type === VectorAnalysisMapType.seeding
    ? WorkPlanType.seeding
    : WorkPlanType.application;

export default getDefaultWorkPlanType;
