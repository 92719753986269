import React from 'react';
import clsx from 'clsx';
import Radio from '@material-ui/core/Radio';
import FormControlLabel, {
  FormControlLabelProps,
} from '@material-ui/core/FormControlLabel';

import './index.scss';

interface EnhancedRadioProps extends Omit<FormControlLabelProps, 'control'> {
  classes?: {
    radioRoot?: string;
    root?: string;
    label?: string;
  };
}

export default function EnhancedRadio({
  value,
  label,
  disabled,
  classes,
  ...otherProps
}: EnhancedRadioProps) {
  return (
    <FormControlLabel
      value={value}
      disabled={disabled}
      control={
        <Radio
          color="primary"
          classes={{
            root: clsx('radio__root', classes?.radioRoot),
          }}
        />
      }
      label={label}
      classes={{
        root: clsx('radio', classes?.root),
        label: clsx('radio__label', classes?.label),
      }}
      {...otherProps}
    />
  );
}
