import { API, graphqlOperation } from '@aws-amplify/api';

import getUserActionsDataQuery from './graphql/queries/getUserActionsData.gql';
import { GetUserActionsDataResponse, UserActionsFilter } from './types/api';
import { AreaUnit } from '../../user/helpers/constants/user';

export const getUserActionsData = async ({
  filter,
  areaUnit,
}: {
  filter: UserActionsFilter;
  areaUnit: AreaUnit;
}) => {
  const { data } = (await API.graphql(
    graphqlOperation(getUserActionsDataQuery, {
      filter,
      areaUnit,
    }),
  )) as GetUserActionsDataResponse;

  const { actions, totalCount } = data?.getUserData?.actionsData ?? {};

  return {
    actions,
    totalCount,
  };
};

export default { getUserActionsData };
