import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { area } from '@turf/turf';
import i18n from 'i18next';

import { extendToLength } from '../../../helpers/analysis';
import { normalizeAndSortZonesMapGeojson } from '../../../helpers/functions/entities/geojson';
import { getUserUnitArea } from '../../../helpers';
import { selectFieldFeatureRecord } from '../../field/fieldSelectors';
import { selectAreaUnit } from '../../user/userSelectors';
import { DEFAULT_POLYGONS_TRANSPARENCY } from './helpers/constants/controls';
import { DEFAULT_COLOR } from './helpers/constants/colors';
import { selectSelectedField } from '../zonesOps/zonesOpsSelectors';
import { fetchAllAssets, fetchBoundaryFeature } from '../../field/fieldSlice';
import {
  selectClonePolygonsAssetType,
  selectClonePolygonsUuid,
  selectFeature,
} from './drawManuallyWorkflowSelectors';
import { errorNotify } from '../../notifications/helpers/functions/notify';
import { CustomError } from '../../../helpers/functions/utils/errorHandling';
import { fetchAssetFeatures, fetchVamapGeojson } from '../../field/fieldAPI';
import { GeoFormat } from '../../../helpers/constants/api';
import { AssetType } from '../../../helpers/constants/entities/asset';

const initialState = {
  clonePolygons: {
    uuid: null,
    assetType: null,
    loading: false,
  },
  polygonsTransparency: DEFAULT_POLYGONS_TRANSPARENCY,
  fieldFeature: null,
  feature: null,
  colors: [],
  instrument: '',
  priceCalculator: {
    perAreaPrice: '',
    perSamplePrice: '',
  },
};

export const finalizeInstrument =
  ({ feature, colors }) =>
  (dispatch, getState) => {
    const areaUnit = selectAreaUnit(getState());

    dispatch(
      finishInstrument({
        feature: normalizeAndSortZonesMapGeojson(feature, areaUnit),
        colors,
      }),
    );
  };

export const clonePolygons = () => async (dispatch, getState) => {
  const state = getState();

  const { farmUuid, fieldUuid } = selectSelectedField(state);
  const layerUuid = selectClonePolygonsUuid(state);
  const layerAssetType = selectClonePolygonsAssetType(state);
  const areaUnit = selectAreaUnit(state);

  let result = null;

  dispatch(setClonePolygonsLoading(true));

  try {
    let featureCollection;
    const colors = [];

    if (layerAssetType === AssetType.vectorAnalysisMap) {
      featureCollection = await fetchVamapGeojson({
        farmUuid,
        fieldUuid,
        uuid: layerUuid,
        areaUnit,
      });
    } else {
      const response = await fetchAssetFeatures({
        farmUuid,
        fieldUuid,
        assetType: layerAssetType,
        uuid: layerUuid,
        format: GeoFormat.geojson,
        urlType: 'originDataUrl',
      });
      featureCollection = await response.json();
    }

    featureCollection = {
      ...featureCollection,
      features: featureCollection.features.map((feature, index) => {
        colors.push(DEFAULT_COLOR);

        return {
          ...feature,
          properties: {
            zone: index + 1,
            attributes: {
              area: getUserUnitArea(area(feature), areaUnit),
              rates: [],
              ratesHeaders: [],
              ratesPrices: [],
              ratesUnits: [],
            },
          },
        };
      }),
    };

    result = {
      featureCollection,
      colors,
    };
  } catch (error) {
    errorNotify({
      error: new CustomError(
        '[UI Draw Manually Workflow] Unable to clone polygons.',
        {
          cause: error,
        },
      ),
      message: i18n.t('zones-ops.draw-manually.steps.3.clone-polygons.error'),
      dispatch,
    });
  }

  dispatch(setClonePolygonsLoading(false));

  return result;
};

export const initializeWorkflow = createAsyncThunk(
  'drawManuallyWorkflow/initializeWorkflow',
  (_payload, { getState, dispatch }) => {
    const { farmUuid, fieldUuid } = selectSelectedField(getState());

    return dispatch(
      fetchAllAssets({
        farmUuid,
        fieldUuid,
      }),
    )
      .then(() => dispatch(fetchBoundaryFeature()))
      .then(() => {
        const state = getState();
        const areaUnit = selectAreaUnit(state);
        const feature = selectFieldFeatureRecord(state);

        return {
          feature,
          area: getUserUnitArea(area(feature), areaUnit),
        };
      });
  },
  {
    condition: (_payload, { getState }) => {
      const state = getState();
      const feature = selectFeature(state);

      if (feature) {
        return false;
      }
    },
  },
);

export const drawManuallyWorkflowSlice = createSlice({
  name: 'drawManuallyWorkflow',
  initialState,
  reducers: {
    updateFeature(state, action) {
      state.feature = action.payload;
    },
    setInstrument(state, action) {
      state.instrument = action.payload;
    },
    finishInstrument(state, action) {
      let { feature, colors } = state;

      if (action.payload.feature) {
        feature = action.payload.feature;
      }

      if (action.payload.colors) {
        colors = action.payload.colors;
      }

      const colorsLength = Math.max(
        ...feature.features.map((f) => f.properties.zone),
      );

      state.instrument = initialState.instrument;
      state.feature = feature;
      state.colors = extendToLength(colors, colorsLength, DEFAULT_COLOR);
    },
    updatePolygonsTransparency(state, action) {
      state.polygonsTransparency = action.payload;
    },
    reset() {
      return initialState;
    },
    cloneWorkflow(state, action) {
      state.feature = action.payload.feature;
      state.colors = action.payload.colors;
    },
    setPriceCalculatorPrice(state, action) {
      state.priceCalculator[action.payload.priceType] =
        action.payload.priceValue;
    },
    setClonePolygonsLoading(state, action) {
      state.clonePolygons.loading = action.payload;
    },
    setClonePolygonsLayer(state, action) {
      state.clonePolygons.uuid = action.payload.uuid;
      state.clonePolygons.assetType = action.payload.assetType;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initializeWorkflow.fulfilled, (state, action) => {
      state.colors = [DEFAULT_COLOR];
      state.fieldFeature = action.payload.feature;
      state.feature = {
        ...action.payload.feature,
        features: action.payload.feature.features.map((feature) => ({
          ...feature,
          properties: {
            zone: 1,
            attributes: {
              area: action.payload.area,
              rates: [],
              ratesHeaders: [],
              ratesPrices: [],
              ratesUnits: [],
            },
          },
        })),
      };
    });
  },
});

const { setClonePolygonsLoading } = drawManuallyWorkflowSlice.actions;

export const {
  updateFeature,
  setInstrument,
  finishInstrument,
  updatePolygonsTransparency,
  reset,
  cloneWorkflow,
  setPriceCalculatorPrice,
  setClonePolygonsLayer,
} = drawManuallyWorkflowSlice.actions;

export default drawManuallyWorkflowSlice.reducer;
