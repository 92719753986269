import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import Button from '../../Button';
import PopupHeader from '../PopupHeader';

import './index.scss';

const MessagePopup = ({
  className,
  title,
  message,
  cancelLabel,
  confirmLabel,
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open
      classes={{
        paper: `message-popup ${className}`,
      }}
    >
      <PopupHeader title={title} onCancel={onCancel} />
      <DialogContent>
        <DialogContentText className="message-popup__content-text">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="message-popup__actions">
        <Button variant="outlined" onClick={onCancel}>
          {cancelLabel || t('general.controls.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="confirm-button"
          onClick={onConfirm}
        >
          {confirmLabel || t('general.popups.done')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MessagePopup;
