import React from 'react';

import './index.scss';

export default function StatusTabLabel({
  name,
  count,
  className = '',
}: {
  name: string;
  count: number;
  className?: string;
}) {
  return (
    <div className={`status-tab-label ${className}`}>
      <div className="status-tab-label__name">{name}</div>
      <div className="status-tab-label__count">{count}</div>
    </div>
  );
}
