import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getOrganizationsPage } from './jdOrganizationsAPI';
import {
  selectOrganizationsOrgName,
  selectOrganizationsPageSize,
} from './jdOrganizationsSelectors';
import {
  JOHN_DEERE_FIRST_PAGE_PAGINATION,
  JOHN_DEERE_DEFAULT_PAGE_SIZE_PAGINATION,
} from './helpers/constants';
import { JDOrganization } from './types/jdOrganization';
import { RootState } from '../../app/store/helpers/types';

export interface JDOrganizationsState {
  orgs: JDOrganization[];
  totalCount: number;
  page: number;
  pageSize: number;
  orgName: string;
  isLoading: boolean;
}

const initialState: JDOrganizationsState = {
  orgs: [],
  totalCount: 0,
  page: JOHN_DEERE_FIRST_PAGE_PAGINATION,
  pageSize: JOHN_DEERE_DEFAULT_PAGE_SIZE_PAGINATION,
  orgName: '',
  isLoading: false,
};

export const fetchOrganizationsJohnDeereWithNewPage = createAsyncThunk(
  'jdOrganizations/getOrganizationsWithNewPage',
  ({ page }: { page: number }, { getState }) => {
    const state = getState() as RootState;
    const pageSize = selectOrganizationsPageSize(state);
    const orgName = selectOrganizationsOrgName(state);

    return getOrganizationsPage(page, pageSize, orgName);
  },
);

export const fetchOrganizationsJohnDeereWithNewPageSize = createAsyncThunk(
  'jdOrganizations/getOrganizationsWithNewPageSize',
  ({ pageSize }: { pageSize: number }, { getState }) => {
    const orgName = selectOrganizationsOrgName(getState() as RootState);

    return getOrganizationsPage(
      JOHN_DEERE_FIRST_PAGE_PAGINATION,
      pageSize,
      orgName,
    );
  },
);

export const fetchOrganizationsJohnDeereWithNewOrgName = createAsyncThunk(
  'jdOrganizations/getOrganizationsWithNewOrgName',
  ({ orgName }: { orgName: string }, { getState }) => {
    const pageSize = selectOrganizationsPageSize(getState() as RootState);

    return getOrganizationsPage(
      JOHN_DEERE_FIRST_PAGE_PAGINATION,
      pageSize,
      orgName,
    );
  },
);

export const jdOrganizationsSlice = createSlice({
  name: 'jdOrganizations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        ({ type }) =>
          type === fetchOrganizationsJohnDeereWithNewPage.pending.type ||
          type === fetchOrganizationsJohnDeereWithNewPageSize.pending.type ||
          type === fetchOrganizationsJohnDeereWithNewOrgName.pending.type,
        (state) => {
          state.isLoading = true;
          state.orgs = [];
          state.totalCount = 0;
        },
      )
      .addMatcher(
        ({ type }) =>
          type === fetchOrganizationsJohnDeereWithNewPage.fulfilled.type ||
          type === fetchOrganizationsJohnDeereWithNewPageSize.fulfilled.type ||
          type === fetchOrganizationsJohnDeereWithNewOrgName.fulfilled.type,
        (state, action) => {
          state.isLoading = false;
          state.orgs = action.payload.orgs;
          state.totalCount = action.payload.totalCount;
          state.page = action.payload.page;
          state.pageSize = action.payload.pageSize;
          state.orgName = action.payload.orgName;
        },
      );
  },
});

export default jdOrganizationsSlice.reducer;
