import { TransformedVectorAnalysisMap } from '../../../../../helpers/types/vectorAnalysisMap';
import { ZonesMapGeoJson } from '../../../../../helpers/types/vectorAnalysisMap/geojson';
import { Product } from '../../types/product';

export const hasGeometriesUpdate = (
  vamap: TransformedVectorAnalysisMap | undefined,
  update: ZonesMapGeoJson | null,
) => {
  if (!update) {
    return false;
  }

  return (
    update.features.length !== vamap?.zonesMapGeojson?.features.length ||
    JSON.stringify(update) !== JSON.stringify(vamap?.zonesMapGeojson)
  );
};

export const applyRatesToZonesMapGeojson = (
  zonesMapGeojson: ZonesMapGeoJson | undefined,
  ratesPerProduct: Record<number, string[]>,
  products: Product[],
): ZonesMapGeoJson<string> | null => {
  if (!zonesMapGeojson) {
    return null;
  }

  // Extract product data only for existing products
  const ratesHeaders = products.map((product) => product.name);
  const ratesUnits = products.map((product) => product.unit);
  const ratesPrices = products.map((product) => product.price);

  const updatedZonesMapGeojson = {
    ...zonesMapGeojson,
    features: zonesMapGeojson.features.map((feature) => {
      const zoneId = feature.properties.zone;
      const { attributes } = feature.properties;

      // Create rates array from existing products
      const updatedRates = products.map((_, productIndex) => {
        const productRates = ratesPerProduct[productIndex];
        return productRates && productRates.length >= zoneId
          ? productRates[zoneId - 1]
          : '0';
      });

      return {
        ...feature,
        properties: {
          ...feature.properties,
          attributes: {
            ...attributes,
            rates: updatedRates,
            ratesHeaders,
            ratesUnits,
            ratesPrices,
          },
        },
      };
    }),
  };

  return updatedZonesMapGeojson;
};
