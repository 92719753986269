import { Sequence } from '../../../../../helpers/constants/ui';
import CalibrateTabContent from '../../Calibrate/Content';
import CleanTabContent from '../../Clean/Content';
import CalibrateCleanTabSequenceStepContent from './Steps/Sequence';

const CONFIG = {
  [Sequence.calibrateFirst]: [
    CalibrateCleanTabSequenceStepContent,
    CalibrateTabContent,
    CleanTabContent,
  ],
  [Sequence.cleanFirst]: [
    CalibrateCleanTabSequenceStepContent,
    CleanTabContent,
    CalibrateTabContent,
  ],
} as const;

export default CONFIG;
