import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from '../../../../../../../../components/Tooltip';

import './index.scss';

export default function CleanCalibrateTooltip({
  children,
}: {
  children: ReactElement;
}) {
  const { t } = useTranslation();

  return (
    <Tooltip
      classes={{
        label: 'clean-calibrate-tooltip__label',
        tooltip: 'clean-calibrate-tooltip__tooltip',
      }}
      placement="top"
      tooltip={
        <p className="clean-calibrate-tooltip__tooltip-line">
          {t('field.assets.dataset-items.clean-calibrate-tooltip')}
        </p>
      }
    >
      {children}
    </Tooltip>
  );
}
