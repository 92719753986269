import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import Button from '../../../../../../../components/Button';
import { traverseTree } from '../../../../helpers/functions/tree';
import { SelectedDataLayersData } from '../../../../types/dataLayersTree';
import DataLayersTreeContext from '../../context';

import './index.scss';

export default function LayersLimitPanel({
  classes,
  limit,
  checked,
  descriptionI18nKey,
  confirmButtonTitleI18nKey,
  onCancelClick,
  onConfirmClick,
}: {
  classes?: {
    panel?: string;
  };
  limit: number;
  checked: Record<string, number>;
  descriptionI18nKey: string;
  confirmButtonTitleI18nKey: string;
  onCancelClick: () => void;
  onConfirmClick: (d: SelectedDataLayersData) => void;
}) {
  const { t } = useTranslation();
  const dataLayersTree = useContext(DataLayersTreeContext);

  const checkedNodes = useMemo(() => {
    const nodes: SelectedDataLayersData = [];

    for (const node of traverseTree(dataLayersTree)) {
      if (
        (node.type === 'asset' || node.type === 'attribute') &&
        checked[node.id]
      ) {
        if (node.type === 'asset' && node.uuid) {
          nodes.push({
            uuid: node.uuid,
            assetGroupType: node.assetGroupType,
          });
        }

        if (
          node.type === 'attribute' &&
          node.attributes &&
          nodes[nodes.length - 1]
        ) {
          nodes[nodes.length - 1].attributes = [
            ...(nodes[nodes.length - 1].attributes || []),
            node.attributes,
          ];
        }
      }
    }

    return nodes;
  }, [checked, dataLayersTree]);

  const checkedItemsAmount = useMemo(
    () =>
      checkedNodes.reduce(
        (acc, { attributes }) =>
          attributes?.length ? acc + attributes.length : acc + 1,
        0,
      ),
    [checkedNodes],
  );

  const isLimitExceeded = checkedItemsAmount > limit;

  const handleConfirmClick = () => {
    onConfirmClick(checkedNodes);
  };

  return (
    <div className={clsx('layers-limit-panel', classes?.panel)}>
      <Typography
        className={clsx('layers-limit-panel__label', {
          text_error: isLimitExceeded,
        })}
      >
        {t(descriptionI18nKey, { limit })}
        {isLimitExceeded && (
          <span className="layers-limit-panel__total">
            {t('field.assets.total-selected', { total: checkedItemsAmount })}
          </span>
        )}
      </Typography>
      <div className="layers-limit-panel__buttons">
        <Button
          className="layers-limit-panel__button"
          variant="outlined"
          color="primary"
          onClick={onCancelClick}
        >
          {t('general.controls.cancel')}
        </Button>
        <Button
          className="layers-limit-panel__button"
          variant="contained"
          color="primary"
          disabled={!checkedNodes.length || isLimitExceeded}
          onClick={handleConfirmClick}
        >
          {t(confirmButtonTitleI18nKey)}
        </Button>
      </div>
    </div>
  );
}
