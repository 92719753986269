import React, {
  ReactElement,
  ReactNode,
  createContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import useListView from './useListView';
import DataLayersList from '../components/DataLayersList';
import {
  AssignableAsset,
  DataLayersListFilters,
  DataLayersListNode,
  Mode,
} from '../types/dataLayersList';
import { TransformedSatelliteImage } from '../../../satelliteImages/types/satelliteImage';
import SatelliteImageNode from '../components/DataLayersList/Nodes/SatelliteImageNode';
import generateDataLayersList from '../helpers/functions/generateDataLayersList';
import ListHeader from '../components/DataLayersList/ListHeader';
import DatasetNode from '../components/DataLayersList/Nodes/DatasetNode';
import {
  TransformedAsAppliedDataset,
  TransformedSoilDataset,
  TransformedTopographyMap,
  TransformedYieldDataset,
} from '../../../../helpers/types/dataset';
import TopographyNode from '../components/DataLayersList/Nodes/TopographyNode';
import filterDataLayersList from '../helpers/functions/filterDataLayersList';
import { AssetType } from '../../../../helpers/constants/entities/asset';
import { DEFAULT_CREATE_ANALYSIS_SATELLITE_FILTER as DEFAULT_SAT_IMAGES_FILTER } from '../../filters/helpers/constants/satelliteImage';
import { DEFAULT_ASSET_GROUP_FILTER } from '../../filters/helpers/constants/assetGroup';
import isDatasetItemDisabled from '../helpers/functions/isDatasetItemDisabled';
import { TransformedVectorAnalysisMap } from '../../../../helpers/types/vectorAnalysisMap';
import ZonesMapNode from '../components/DataLayersList/Nodes/ZonesMapNode';
import { DEFAULT_VAMAPS_FILTER } from '../../filters/helpers/constants/analysis';

interface BaseProps {
  assets: AssignableAsset[];
  selectedUuid: string;
  collapsible?: boolean;
  panels?: ReactElement[];
  mode: Mode;
  onAssetClick: (a: AssignableAsset) => void;
}

interface DefaultListProps extends BaseProps {
  mode: 'default';
}

interface MultiSelectListProps extends BaseProps {
  mode: 'multiSelect';
  checkedUuids: string[];
  recommendedImagesPicker?: ReactNode;
  onAssetCheckboxClick: (v: boolean, u: string) => void;
  onAllCheckboxClick: (c: boolean, u: string[]) => void;
}

export const DataLayersListContext = createContext<{
  assets: AssignableAsset[];
  filteredAssets: AssignableAsset[];
  checkedUuids?: string[];
  selectedUuid: string;
}>({
  assets: [],
  filteredAssets: [],
  checkedUuids: [],
  selectedUuid: '',
});

export default function useDataLayersList(
  props: DefaultListProps | MultiSelectListProps,
) {
  const {
    assets,
    selectedUuid,
    collapsible,
    panels = [],
    mode,
    onAssetClick,
  } = props;
  const multiSelectMode = mode === 'multiSelect';

  const [filteredAssets, setFilteredAssets] = useState<AssignableAsset[]>([]);
  const [filters, setFilters] = useState<DataLayersListFilters>({
    [AssetType.satelliteImage]: DEFAULT_SAT_IMAGES_FILTER,
    [AssetType.soilDataset]: DEFAULT_ASSET_GROUP_FILTER,
    [AssetType.yieldDataset]: DEFAULT_ASSET_GROUP_FILTER,
    [AssetType.asAppliedDataset]: DEFAULT_ASSET_GROUP_FILTER,
    [AssetType.topographyMap]: DEFAULT_ASSET_GROUP_FILTER,
    [AssetType.vectorAnalysisMap]: DEFAULT_VAMAPS_FILTER,
  });
  const [assetClicked, setAssetClicked] = useState(false);
  const prevSelectedUuidRef = useRef<string>(selectedUuid);

  const handleNodeCheckboxClick = (
    value: boolean,
    item: DataLayersListNode,
  ) => {
    if (multiSelectMode) {
      props.onAssetCheckboxClick(value, item.uuid);
    }
  };

  const handleAssetClick = (asset: AssignableAsset) => {
    onAssetClick(asset);
    setAssetClicked(true);
  };

  const handleFiltersChange = (newFilters: Partial<DataLayersListFilters>) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  const satelliteImageNodeRenderer = (
    node: DataLayersListNode,
    image: TransformedSatelliteImage,
  ) => {
    if (multiSelectMode) {
      return (
        <SatelliteImageNode
          listNode={node}
          image={image}
          mode={mode}
          selectedItemUuid={selectedUuid}
          checkedItemUuids={props.checkedUuids}
          onClick={() => handleAssetClick(image)}
          onCheckboxClick={handleNodeCheckboxClick}
        />
      );
    }

    return (
      <SatelliteImageNode
        listNode={node}
        image={image}
        mode={mode}
        selectedItemUuid={selectedUuid}
        onClick={() => handleAssetClick(image)}
      />
    );
  };

  const soilDatasetNodeRenderer = (
    node: DataLayersListNode,
    dataset: TransformedSoilDataset,
  ) => (
    <DatasetNode
      listNode={node}
      dataset={dataset}
      selectedItemUuid={selectedUuid}
      disabled={isDatasetItemDisabled(dataset)}
      onClick={() => handleAssetClick(dataset)}
    />
  );

  const yieldDatasetNodeRenderer = (
    node: DataLayersListNode,
    dataset: TransformedYieldDataset,
  ) => (
    <DatasetNode
      listNode={node}
      dataset={dataset}
      selectedItemUuid={selectedUuid}
      disabled={isDatasetItemDisabled(dataset)}
      onClick={() => handleAssetClick(dataset)}
    />
  );

  const asAppliedDatasetNodeRenderer = (
    node: DataLayersListNode,
    dataset: TransformedAsAppliedDataset,
  ) => (
    <DatasetNode
      listNode={node}
      dataset={dataset}
      selectedItemUuid={selectedUuid}
      disabled={isDatasetItemDisabled(dataset)}
      onClick={() => handleAssetClick(dataset)}
    />
  );

  const topographyMapNodeRenderer = (
    node: DataLayersListNode,
    topographyMap: TransformedTopographyMap,
  ) => (
    <TopographyNode
      listNode={node}
      topographyMap={topographyMap}
      selectedItemUuid={selectedUuid}
      onClick={() => handleAssetClick(topographyMap)}
    />
  );

  const vectorAnalysisMapNodeRenderer = (
    node: DataLayersListNode,
    vamap: TransformedVectorAnalysisMap,
  ) => (
    <ZonesMapNode
      listNode={node}
      zonesMap={vamap}
      selectedItemUuid={selectedUuid}
      onClick={() => handleAssetClick(vamap)}
    />
  );

  const dataLayersList = generateDataLayersList({
    assets: filteredAssets,
    selectedUuid,
    satelliteImageNodeRenderer,
    soilDatasetNodeRenderer,
    yieldDatasetNodeRenderer,
    asAppliedDatasetNodeRenderer,
    topographyMapNodeRenderer,
    vectorAnalysisMapNodeRenderer,
  });
  const { listView, ref, getNodeIndexById } = useListView({
    nodes: dataLayersList,
  });

  useEffect(() => {
    setFilteredAssets(filterDataLayersList(assets, filters));
  }, [assets, filters]);

  useEffect(() => {
    const nodeIndex = getNodeIndexById(selectedUuid);

    if (nodeIndex === -1) {
      return;
    }

    if (prevSelectedUuidRef.current !== selectedUuid) {
      setAssetClicked(false);

      if (!assetClicked && selectedUuid) {
        ref.current?.scrollToIndex({
          index: nodeIndex,
          align: 'center',
        });
      }
    }

    prevSelectedUuidRef.current = selectedUuid;
  }, [ref, selectedUuid, getNodeIndexById, assetClicked]);

  let listHeaderComponent;
  let dataLayersListComponent;

  if (multiSelectMode) {
    listHeaderComponent = (
      <ListHeader
        key="list-header"
        mode={mode}
        assets={assets}
        filteredAssets={filteredAssets}
        filters={filters}
        checkedUuids={props.checkedUuids}
        recommendedImagesPicker={props.recommendedImagesPicker}
        onFiltersChange={handleFiltersChange}
        onSelectAllClick={props.onAllCheckboxClick}
      />
    );
  } else {
    listHeaderComponent = (
      <ListHeader
        key="list-header"
        mode={mode}
        assets={assets}
        filteredAssets={filteredAssets}
        filters={filters}
        onFiltersChange={handleFiltersChange}
      />
    );
  }

  if (assets.length === 0) {
    dataLayersListComponent = null;
  } else {
    dataLayersListComponent = (
      <DataLayersListContext.Provider
        value={{
          assets,
          filteredAssets,
          checkedUuids: multiSelectMode ? props.checkedUuids : undefined,
          selectedUuid,
        }}
      >
        <DataLayersList listView={listView} collapsible={collapsible}>
          {[...panels, listHeaderComponent]}
        </DataLayersList>
      </DataLayersListContext.Provider>
    );
  }

  return {
    dataLayersListComponent,
  };
}
