import { useEffect, useRef } from 'react';

import getSteps from '../../helpers/functions/getSteps';
import { selectWorkflow } from '../../cloneZonesMapSelectors';
import { useAppSelector } from '../../../../../app/store/helpers/functions';
import useStepComponent from '../../../zonesOps/hooks/useStepComponent';
import { selectAssetType } from '../../../createSingleLayerAnalysis/createSingleLayerAnalysisSelectors';
import useResetZonesMapWorkflow from '../../../zonesOps/hooks/useResetZonesMapWorkflow';

export default function StepperCloneZonesMap() {
  const workflow = useAppSelector(selectWorkflow);
  const singleLayerAssetType = useAppSelector(selectAssetType);
  const workflowRef = useRef(workflow);

  const resetZonesMapWorkflow = useResetZonesMapWorkflow();

  useEffect(() => {
    workflowRef.current = workflow;
  }, [workflow]);

  const stepComponent = useStepComponent({
    getStepsFn: getSteps(workflow, singleLayerAssetType),
    resetFn: resetZonesMapWorkflow,
  });

  return stepComponent;
}
