import i18n from 'i18next';

import { SelectOption } from '../../../../../components/Select';
import BatchOperationWorkflow from '../constants/workflows';

const getWorkflowOptions = (): SelectOption<BatchOperationWorkflow>[] => [
  {
    value: BatchOperationWorkflow.equationBased,
    title: i18n.t('zones-ops.common.steps.select-field.options.equation-based'),
    description: i18n.t(
      'zones-ops.common.steps.select-field.options.equation-based-description',
    ),
  },
];

export default getWorkflowOptions;
