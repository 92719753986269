import i18n from 'i18next';

import {
  ATTRIBUTE_UNIT_TO_I18N_LABEL,
  UNTRANSLATABLE_ATTRIBUTE_UNITS,
} from '../../constants/units/attributeUnit';
import { AttributeUnit } from '../../types/dataset';

export const getAttributeUnitLabel = (unit?: AttributeUnit | null): string => {
  if (!unit || UNTRANSLATABLE_ATTRIBUTE_UNITS.includes(unit)) {
    return '';
  }

  const i18nLabel =
    ATTRIBUTE_UNIT_TO_I18N_LABEL[
      unit as keyof typeof ATTRIBUTE_UNIT_TO_I18N_LABEL
    ];

  return i18nLabel ? i18n.t(i18nLabel) : unit;
};

export const convertAttributeUnitToAbsoluteUnitLabel = (
  unit?: AttributeUnit | null,
): string => {
  if (!unit) return '';

  const unitLabel = getAttributeUnitLabel(unit);
  const [absoluteUnit] = unitLabel.split('/');

  return absoluteUnit ?? '';
};
