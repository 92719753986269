import React from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import PinsTable from '../common/PinsTable';

import './index.scss';

const PinsView = ({ pinsGroup, details, onClose }) => {
  const { t } = useTranslation();

  return (
    <div className="pins-group-view">
      <div className="card-header">
        <div className="card-header__container">
          <span className="card-header__text">
            {t('general.controls.pins.pins')}
          </span>
          <span className="card-header__text_secondary">{pinsGroup.name}</span>
        </div>
        <IconButton
          size="small"
          className="btn"
          onClick={() => onClose(pinsGroup.uuid)}
        >
          <CloseIcon fontSize="inherit" className="icon" />
        </IconButton>
      </div>
      <div className="pins-group-view__container">
        {details}
        <PinsTable pins={pinsGroup.pins} />
      </div>
    </div>
  );
};

export default PinsView;
