import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import TextFieldPopup from '../TextFieldPopup';

const EditTextField = ({
  i18nKey = '',
  entityName = '',
  textValue = '',
  confirmText,
  validator,
  onCancel = () => {},
  onConfirm = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <TextFieldPopup
      title={t(`general.popups.edit-text-field.title.${i18nKey}`)}
      textValue={textValue}
      description={
        <Trans
          i18nKey={`general.popups.edit-text-field.description.${i18nKey}`}
        >
          Text{' '}
          <span className="text-field-popup__content-text_highlighted">
            {{ entityName }}
          </span>
        </Trans>
      }
      validator={validator}
      placeholder={t(`general.popups.edit-text-field.placeholder.${i18nKey}`)}
      confirmText={confirmText || t('general.controls.update')}
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  );
};

export default EditTextField;
