import React, { ComponentType } from 'react';

import type { DefaultTableRow, TableRow } from '../../types/row';
import type { CellPropsMap, TableColumn } from '../../types/column';
import config from '../Cells/config';

export default function TableRowContent<
  R extends TableRow,
  C extends DefaultTableRow,
>({
  columns,
  row,
  editMode = false,
  isSelected = false,
  isExpanded = false,
  onRowExpandedChange,
}: {
  columns: TableColumn<C>[];
  row: R;
  editMode?: boolean;
  isSelected?: boolean;
  isExpanded?: boolean;
  onRowExpandedChange?: (id: string, expanded: boolean) => void;
}) {
  let result;

  if (row.rowType === 'nested') {
    const NestedRow = config[row.cellType];

    result = (
      <NestedRow
        editMode={editMode}
        row={row}
        isSelected={isSelected}
        isExpanded={isExpanded}
        colSpan={columns.length}
        onRowExpandedChange={onRowExpandedChange}
      />
    );
  } else {
    result = (
      <>
        {columns.map((column) => {
          const Cell = config[column.element] as ComponentType<
            CellPropsMap[typeof column.element]
          >;

          result = (
            <Cell
              key={column.id}
              headCell={column}
              row={row}
              editMode={editMode}
            />
          );

          return result;
        })}
      </>
    );
  }

  return result;
}
