import React, { Suspense } from 'react';
import { connect } from 'react-redux';

import Loading from '../../../../../../../components/Loading';
import Stepper from '../../../../../../../components/Stepper';
import BackdropLoading from '../../../../../../../components/Loading/BackdropLoading';
import Message from '../../../../../../../components/Messages/Message';
import Panel from '../../../Panel/ZonesMap';
import { goToPrevStep } from '../../../../../zonesOps/zonesOpsSlice';
import { setupCloneWorkflow } from '../../../../cloneZonesMapSlice';
import {
  selectIsFieldAndZonesMapsLoaded,
  selectVectorAnalysisMaps,
} from '../../../../../../field/fieldSelectors';
import { selectSelectedField } from '../../../../../zonesOps/zonesOpsSelectors';
import {
  selectSelectedVamapUuid,
  selectSelectedZonesMap,
} from '../../../../cloneZonesMapSelectors';
import { fetchVamapsWithFieldAndAssets } from '../../../../../../field/fieldSlice';
import useDidMount from '../../../../../../../hooks/useDidMount';

import './index.scss';

const Map = React.lazy(() => import('../../../Map'));

const ZonesMapsStepper = ({
  nextDisabled,
  isVamapsLoaded,
  vectorAnalysisMaps,
  steps,
  stepIndex,
  step,
  onClickBack,
  onClickNext,
  requestVamaps,
}) => {
  useDidMount(requestVamaps);

  let content;

  if (isVamapsLoaded) {
    if (vectorAnalysisMaps.length === 0) {
      content = (
        <Message
          key="panel"
          messageTextLocation="zones-ops.common.no-assets-message"
        />
      );
    } else {
      content = [
        <Suspense key="map" fallback={<Loading />}>
          <Map step={step} />
        </Suspense>,
        <Panel key="panel" step={step} />,
      ];
    }
  } else {
    content = <BackdropLoading key="loader" />;
  }

  return (
    <Stepper
      activeStep={stepIndex}
      steps={steps}
      onClickNext={onClickNext}
      onClickBack={onClickBack}
      nextDisabled={nextDisabled}
    >
      {content}
    </Stepper>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { farmUuid, fieldUuid } = selectSelectedField(state);

  return {
    ...ownProps,
    isVamapsLoaded: selectIsFieldAndZonesMapsLoaded(state),
    vectorAnalysisMaps: selectVectorAnalysisMaps(state),
    nextDisabled: !selectSelectedVamapUuid(state),
    farmUuid,
    fieldUuid,
    selectedVamap: selectSelectedZonesMap(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClickBack: () => {
    dispatch(goToPrevStep());
  },
  onClickNext: (vamap, farmUuid, fieldUuid) => {
    dispatch(
      setupCloneWorkflow({
        vamap,
        farmUuid,
        fieldUuid,
      }),
    );
  },
  requestVamaps: (farmUuid, fieldUuid) => {
    dispatch(
      fetchVamapsWithFieldAndAssets({
        farmUuid,
        fieldUuid,
      }),
    );
  },
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,
  requestVamaps: () =>
    propsFromDispatch.requestVamaps(
      propsFromState.farmUuid,
      propsFromState.fieldUuid,
    ),
  onClickNext: () =>
    propsFromDispatch.onClickNext(
      propsFromState.selectedVamap,
      propsFromState.farmUuid,
      propsFromState.fieldUuid,
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(ZonesMapsStepper);
