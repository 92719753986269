import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import Button from '../../../../../components/Button';
import { getAuthLink } from '../../../../../helpers/navigation';
import { View } from '../../helpers/constants/authentication';

import '../common/index.scss';

const RecoverPasswordConfirmPanel = () => (
  <div className="authentication-panel">
    <Typography
      classes={{
        root: 'authentication-panel__header',
      }}
      variant="h1"
    >
      Password Recovery
    </Typography>
    <Typography
      classes={{
        root: 'authentication-panel__tip',
      }}
    >
      Your password was reset successfully.
    </Typography>
    <Button
      className="authentication-panel__button"
      variant="contained"
      color="primary"
      component={Link}
      to={getAuthLink(View.logIn)}
    >
      Back to Log in
    </Button>
  </div>
);

export default RecoverPasswordConfirmPanel;
