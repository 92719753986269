import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../../app/store/helpers/types';
import { EquationCategory } from './helpers/constants/equations';

export const selectFilterSearch = ({ equations }: RootState) =>
  equations.filter.search;

export const selectEquationsLoading = ({ equations }: RootState) =>
  equations.equations.loading;

export const selectFilterCategory = ({ equations }: RootState) =>
  equations.filter.category;

export const selectCustomUserEquations = ({ equations }: RootState) =>
  equations.equations.customUser;

export const selectCustomOrganizationEquations = ({ equations }: RootState) =>
  equations.equations.customOrganization;

export const selectMasterEquations = ({ equations }: RootState) =>
  equations.equations.master;

export const selectCurrentCategoryEquations = createSelector(
  selectCustomUserEquations,
  selectCustomOrganizationEquations,
  selectMasterEquations,
  selectFilterCategory,
  (
    customUserEquations,
    customOrganizationEquations,
    masterEquations,
    category,
  ) => {
    let result;

    if (category === EquationCategory.customUser) {
      result = customUserEquations;
    } else if (category === EquationCategory.customOrganization) {
      result = customOrganizationEquations;
    } else {
      result = masterEquations;
    }

    return result;
  },
);

export const selectCurrentEquation = ({ equations }: RootState) =>
  equations.currentEquation;

export const selectEquationTemplateUuid = ({ equations }: RootState) =>
  equations.equationTemplateUuid;

export const selectIsEquationTemplateSelected = ({ equations }: RootState) =>
  equations.equationTemplateUuid != null;

export const selectVerifyEquation = ({ equations }: RootState) =>
  equations.verifyEquation;

export const selectIsCurrentEquationValid = createSelector(
  selectCurrentEquation,
  selectIsEquationTemplateSelected,
  selectVerifyEquation,
  (currentEquation, equationTemplateSelected, verifyEquation) =>
    equationTemplateSelected &&
    !!currentEquation.equationAsText &&
    !verifyEquation.errorMessage &&
    !verifyEquation.inProgress,
);
