import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import PopupHeader from '../PopupHeader';
import Button from '../../Button';
import BackdropLoading from '../../Loading/BackdropLoading';
import TextField from '../../TextField';

import './index.scss';

const TextFieldPopup = ({
  textValue = '',
  title,
  description,
  placeholder,
  confirmText,
  classes = {},
  processing = false,
  validator = (v) => !!v.trim(),
  formatValue = (v) => v,
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { dialogPaper } = classes;
  const [value, setValue] = useState(textValue);
  const confirmButtonDisabled = !validator(value);

  const handleTextFieldChange = (e) => {
    const formattedValue = formatValue(e.target.value);
    setValue(formattedValue);
  };

  return (
    <Dialog
      open
      classes={{
        paper: clsx('text-field-popup__paper', dialogPaper),
      }}
    >
      <PopupHeader title={title} onCancel={onCancel} />
      <DialogContent>
        <DialogContentText
          classes={{
            root: 'text-field-popup__content-text',
          }}
        >
          {description}
        </DialogContentText>
        <TextField
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          placeholder={placeholder}
          value={value}
          onChange={handleTextFieldChange}
        />
      </DialogContent>
      <DialogActions
        classes={{
          root: 'text-field-popup__actions',
        }}
      >
        <Button variant="outlined" onClick={onCancel}>
          {t('general.controls.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={confirmButtonDisabled}
          onClick={() => onConfirm(value.trim())}
        >
          {confirmText}
        </Button>
      </DialogActions>
      {processing && <BackdropLoading />}
    </Dialog>
  );
};

export default TextFieldPopup;
