import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import './index.scss';

const NoProductsToExport = ({
  title,
  description,
  productManagerLink,
  onCLick,
}: {
  title: string;
  description: string;
  productManagerLink: string;
  onCLick: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="no-products-to-export">
      <Typography variant="subtitle2">{title}</Typography>
      <Typography variant="body1">{description}</Typography>
      <a
        className="link no-products-to-export__products-link"
        href={productManagerLink}
        target="_blank"
        onClick={onCLick}
        rel="noreferrer"
      >
        {t('john-deere.go-to-products-manager')}
      </a>
    </div>
  );
};

export default NoProductsToExport;
