import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import SourceAssetsList from '../SourceAssetsList';
import Label from '../../../Label';
import { getAssetTypeLabel } from '../../../../helpers/functions/map/legend';

import './index.scss';

const EquationDetails = ({ useNumpy, equation, result, variables, apiKey }) => {
  const { t } = useTranslation();

  return (
    <div className="equation-details">
      <Typography
        variant="subtitle2"
        classes={{
          root: 'equation-details__title',
        }}
      >
        {t('general.controls.map-legend.equation-details.title')}
      </Typography>
      <div className="equation-details__subtitle">
        <Typography
          variant="subtitle2"
          className="equation-details__subtitle-text"
        >
          {t('general.controls.map-legend.equation-details.equation')}
        </Typography>
        {useNumpy && (
          <Label
            text={t('zones-ops.equation-based.steps.4.panel.numpy')}
            color="primary"
          />
        )}
      </div>
      <div className="equation-details__equation">{equation}</div>
      <Typography
        variant="subtitle2"
        classes={{
          root: 'equation-details__subtitle equation-details__subtitle-text',
        }}
      >
        {t('general.controls.map-legend.equation-details.result')}
      </Typography>
      <div className="equation-details__result">{result}</div>
      <Typography
        variant="subtitle2"
        classes={{
          root: 'equation-details__subtitle equation-details__subtitle-text',
        }}
      >
        {t('general.controls.map-legend.equation-details.variables')}
      </Typography>
      <SourceAssetsList
        assets={variables}
        apiKey={apiKey}
        assetHeaderRenderer={(anAsset) => (
          <>
            <Typography className="an-asset__title">
              {anAsset.variable}
            </Typography>
            <Typography className="an-asset__type">
              {getAssetTypeLabel(anAsset.type)}
            </Typography>
          </>
        )}
      />
    </div>
  );
};

export default EquationDetails;
