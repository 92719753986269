import { API, graphqlOperation, GraphQLSubscription } from '@aws-amplify/api';
import { Observable } from 'zen-observable-ts';

import subscriptionGQL from './graphql/subscriptions/subscription.gql';
import { SubscriptionEvent } from './types/api';
import {
  captureException,
  CustomError,
} from '../../helpers/functions/utils/errorHandling';

export const subscribe = async (
  userUuid: string,
): Promise<Observable<GraphQLSubscription<SubscriptionEvent> | void>> => {
  if (!userUuid) {
    captureException({
      error: new CustomError(
        '[Subscription] Unable to subscribe: userUuid is empty',
      ),
    });

    return Observable.of<void>();
  }

  // FIXME: double assertion is required until https://github.com/aws-amplify/amplify-js/issues/7589 is fixed
  return API.graphql(
    graphqlOperation(subscriptionGQL, {
      userUuid,
    }),
  ) as unknown as Observable<GraphQLSubscription<SubscriptionEvent>>;
};

export default { subscribe };
