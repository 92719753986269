import i18n from 'i18next';
import { convertDateToDisplayFormat } from './functions/utils/date';

export const SATELLITE_INDEXES = [
  { value: 'EVI2', title: 'EVI2' },
  { value: 'NDVI', title: 'NDVI' },
  { value: 'OSAVI', title: 'OSAVI' },
  { value: 'SAVI', title: 'SAVI' },
  { value: 'LAI', title: 'LAI' },
  { value: 'GNDVI', title: 'GNDVI' },
  { value: 'IPVI', title: 'IPVI' },
  { value: 'GCI', title: 'GCI' },
  { value: 'WDRVI', title: 'WDRVI' },
  { value: 'RCI', title: 'RCI' },
  { value: 'SBI', title: 'SBI' },
  { value: 'MCARI1', title: 'MCARI1' },
  { value: 'NDMI', title: 'NDMI' },
  { value: 'MSI', title: 'MSI' },
  { value: 'CCCI', title: 'CCCI' },
  { value: 'MCARI_OSAVI', title: 'MCARI/OSAVI' },
  { value: 'TCARI_OSAVI', title: 'TCARI/OSAVI' },
  { value: 'MCARI', title: 'MCARI' },
  { value: 'TCARI', title: 'TCARI' },
];

export const DEFAULT_PREVIEW_INDEX = 'NDVI';

export const RVI_INDEX = {
  value: 'RVI',
  title: 'RVI',
};

export const GFPI_INDEX = {
  value: 'GFPI',
  title: 'GFPI',
  beta: true,
};

export const formatAcquisitionDate = (acquisitionDate: Date | string = '') => {
  const result = convertDateToDisplayFormat(acquisitionDate);
  return result || i18n.t('general.date.invalid-date');
};

/**
 * Convert index to back-end readable format
 * @param {string} index index to transform
 * @returns Index
 */
export const convertToGenerateMapIndex = (index: string) => index.toUpperCase();
