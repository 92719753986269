import { useMemo } from 'react';

import { useAppSelector } from '../../../../app/store/helpers/functions';
import { selectCurrentEquation } from '../../../equations/equationsSelectors';
import { selectEquationMapData } from '../createBatchEquationBasedAnalysisSelectors';

export default function useVerifyEquationMapData({
  fieldUuid,
}: {
  fieldUuid: string;
}) {
  const equationMapData = useAppSelector((state) =>
    selectEquationMapData(state, fieldUuid),
  );
  const { dataVariables } = useAppSelector(selectCurrentEquation);

  const hasMissingVariable = useMemo(
    () =>
      dataVariables.some(
        (dataVariable) => !equationMapData?.assignedVariables?.[dataVariable],
      ),
    [dataVariables, equationMapData?.assignedVariables],
  );

  return {
    hasMissingVariable,
  };
}
