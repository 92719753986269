import DEFAULT_LEGEND from '../constants/legend';
import { GeoMap } from '../../../../helpers/types/api';
import Cloud12Icon from '../../components/Icons/cloud12';
import Cloud13Icon from '../../components/Icons/cloud13';
import Cloud23Icon from '../../components/Icons/cloud23';
import CloudEmptyIcon from '../../components/Icons/cloudEmpty';
import CloudFullIcon from '../../components/Icons/cloudFull';
import { prettifyNumber } from '../../../../helpers/markup';

export const getCloudIcon = (cloudFreeLand: number) => {
  let icon = null;

  if (cloudFreeLand === 1) {
    icon = CloudEmptyIcon;
  }

  if (cloudFreeLand < 1) {
    icon = Cloud13Icon;
  }

  if (cloudFreeLand < 0.67) {
    icon = Cloud12Icon;
  }

  if (cloudFreeLand < 0.5) {
    icon = Cloud23Icon;
  }

  if (cloudFreeLand < 0.33) {
    icon = CloudFullIcon;
  }

  return icon;
};

export const getSatelliteImageLegendColors = (
  geoMap?: GeoMap,
  index?: string,
):
  | {
      color: string;
      quantity: string;
    }[]
  | null => {
  if (!geoMap || !index) {
    return null;
  }

  let result;

  if (geoMap.classes?.length && geoMap.hexColorCodes?.length) {
    result = geoMap.classes.map((classValue, i) => ({
      color: geoMap.hexColorCodes?.[i] || '',
      quantity: prettifyNumber(classValue),
    }));
  } else {
    result = DEFAULT_LEGEND[index as keyof typeof DEFAULT_LEGEND];
  }

  return result;
};
