query getEquationMaps(
  $farmUuids: [ID]
  $fieldUuids: [ID]
  $equationMapUuids: [ID]
  $areaUnit: AreaUnit!
) {
  getFarms(farmUuids: $farmUuids) {
    uuid
    fields(fieldUuids: $fieldUuids) {
      uuid
      equationMaps(
        equationMapUuids: $equationMapUuids
        statuses: [EXECUTED, SAVED]
      ) {
        uuid
        fieldUuid
        name
        type
        status
        createdDate
        updatedDate
        variabilityFactor
        parameters {
          gridPixelSizeX
          gridPixelSizeY
        }
        geoMaps {
          shortName
          hexColorCodes
          classes
          url
        }
        equationResultVariable
        equationAsText
        dataVariables {
          variable
          yieldDataset {
            uuid
          }
          yieldAttribute
          soilDataset {
            uuid
          }
          soilAttribute
          asAppliedDataset {
            uuid
          }
          asAppliedAttribute
          topographyMap {
            uuid
          }
          topographyAttribute
          satelliteImages {
            satelliteImage {
              uuid
            }
          }
          index
          equationMap {
            uuid
          }
          vectorAnalysisMap {
            uuid
          }
          vectorAnalysisMapZoneTypeAttribute
        }
        statistics {
          min
          max
          avg
          mdn
          var
          std
          cnt
          sum
        }
        productUnit
        useNumpy
        area(unit: $areaUnit)
      }
    }
  }
}
