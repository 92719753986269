export enum PinsGroupsFilterKey {
  typeFilterValue = 'typeFilterValue',
  nameFilterValue = 'nameFilterValue',
}

export const PINS_GROUPS_FILTER_KEYS_I18N_MAP = {
  [PinsGroupsFilterKey.typeFilterValue]:
    'general.controls.zones-map-filters.type-label',
  [PinsGroupsFilterKey.nameFilterValue]: 'general.controls.map-legend.name',
};

export const DEFAULT_PINS_GROUPS_FILTER = {
  [PinsGroupsFilterKey.typeFilterValue]: 'all',
  [PinsGroupsFilterKey.nameFilterValue]: '',
};
