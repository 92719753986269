import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getOrdersAndAdditionalUserData } from '../../../user/userSlice';
import useInterval from '../../../../hooks/useInterval';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../app/store/helpers/functions';
import {
  getPlanParam,
  getStripeCustomerIdParam,
} from '../helpers/functions/plan';
import {
  selectAdditionalUserDataProvided,
  selectLastOrder,
} from '../../../user/userSelectors';

export default function useUserDataPoller() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const plan = getPlanParam(location.search);
  const stripeCustomerId = getStripeCustomerIdParam(location.search);
  const lastOrder = useAppSelector(selectLastOrder);
  const additionalUserDataProvided = useAppSelector(
    selectAdditionalUserDataProvided,
  );
  const [loaded, setLoaded] = useState(false);

  // User data doesn't updated immediately, so we need to poll BE
  // until retrieve order with acquired plan and Stripe customer object.
  const poller = useCallback(
    (id) => {
      if (plan !== lastOrder?.plan || !additionalUserDataProvided) {
        // @ts-expect-error
        void dispatch(getOrdersAndAdditionalUserData({ stripeCustomerId }));
      } else {
        clearInterval(id);
        setLoaded(true);
      }
    },
    [dispatch, lastOrder, plan, stripeCustomerId, additionalUserDataProvided],
  );

  useInterval(poller, 2000);

  return loaded;
}
