mutation exportToJohnDeereAsMapsLayers(
  $input: ExportToJohnDeereAsMapsLayersInput!
) {
  exportToJohnDeereAsMapsLayers(input: $input) {
    status
    details {
      name
    }
  }
}
