import React, { ReactNode } from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import './index.scss';

export default function PopupHeader({
  title,
  classes,
  actions,
  onCancel = () => {},
}: {
  title: string;
  classes?: {
    root?: string;
  };
  actions?: ReactNode;
  onCancel: () => void;
}) {
  return (
    <Typography
      className={clsx('popup-header', classes?.root)}
      variant="h6"
      component="div"
    >
      <span>{title}</span>
      <div>
        {actions}
        <IconButton size="small" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
    </Typography>
  );
}
