import React, { Component } from 'react';
import i18n from 'i18next';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { getDatasetAttributeNameHandler } from '../../../helpers/functions/entities/dataset';
import Radio from '../../Radio';

import './index.scss';

const parseAttribute = (value) => {
  const result = value.split(/_(.+)/);

  return {
    datasetUuid: result[0],
    attribute: result[1],
  };
};

class DatasetList extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      JSON.stringify(nextProps.dataset) !==
        JSON.stringify(this.props.dataset) ||
      JSON.stringify(nextProps.selectedAttribute) !==
        JSON.stringify(this.props.selectedAttribute) ||
      JSON.stringify(nextProps.selectedDatasetUuid) !==
        JSON.stringify(this.props.selectedDatasetUuid) ||
      nextProps.onSelectedAttributeChange !==
        this.props.onSelectedAttributeChange
    );
  }

  render() {
    const {
      defaultExpanded = true,
      selectedAttribute = '',
      selectedDatasetUuid = '',
      onSelectedAttributeChange = () => {},
      datasets = [],
      attributeNameHandler = getDatasetAttributeNameHandler,
    } = this.props;

    return (
      <div className="dataset-list">
        <RadioGroup
          value={
            selectedDatasetUuid && selectedAttribute
              ? `${selectedDatasetUuid}_${selectedAttribute}`
              : ''
          }
          onChange={(e) =>
            onSelectedAttributeChange(parseAttribute(e.target.value))
          }
        >
          {datasets.map((datasetItem, index) => {
            const datasetAttributeNameHandler = attributeNameHandler(
              datasetItem.fullAttributes,
            );
            const attributeItems = datasetItem.attributes.map(
              (attribute, ind) => (
                <Radio
                  key={ind}
                  value={`${datasetItem.uuid}_${attribute}`}
                  label={datasetAttributeNameHandler(attribute)}
                />
              ),
            );

            return (
              <Accordion
                key={index}
                defaultExpanded={defaultExpanded}
                TransitionProps={{
                  unmountOnExit: true,
                }}
                classes={{
                  root: 'accordion-root',
                  expanded: 'accordion-root_expanded',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  classes={{
                    root: 'accordion-summary',
                    expanded: 'accordion-summary_expanded',
                    expandIcon: 'accordion-summary__icon',
                    content: 'accordion-summary__content',
                  }}
                >
                  <Typography className="accordion-summary__content__text">
                    {datasetItem.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  classes={{
                    root: 'accordion-details',
                  }}
                >
                  {attributeItems.length === 0 ? (
                    <div className="no-data">
                      {i18n.t('general.controls.no-data')}
                    </div>
                  ) : (
                    attributeItems
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </RadioGroup>
      </div>
    );
  }
}

export default DatasetList;
