import React, { useState } from 'react';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';

import './index.scss';

export default function EnhancedSlider({
  title,
  value,
  min,
  max,
  step,
  helperText,
  onChangeCommitted,
}: {
  title?: string;
  value: number;
  min?: number;
  max?: number;
  step?: number;
  helperText?: {
    type: 'info';
    text: string;
  };
  onChangeCommitted: (value: number | number[]) => void;
}) {
  const [internalValue, setInternalValue] = useState(value);

  return (
    <div className="slider">
      {title && (
        <Typography className="slider__title" variant="body2">
          {title}
        </Typography>
      )}
      <Slider
        value={internalValue}
        min={min}
        max={max}
        step={step}
        valueLabelDisplay="auto"
        onChange={(_event, newValue) => {
          setInternalValue(newValue as number);
        }}
        onChangeCommitted={(_event, newValue) => {
          onChangeCommitted(newValue);
        }}
      />
      {helperText && <FormHelperText>{helperText.text}</FormHelperText>}
    </div>
  );
}
