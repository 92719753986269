mutation generateMultiLayersVectorAnalysisMapAsync(
  $input: GenerateMultiLayersVectorAnalysisMapInput!
) {
  generateMultiLayersVectorAnalysisMapAsync(input: $input) {
    uuid
    fieldUuid
    statusCode
    statusMessage
  }
}
