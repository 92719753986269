query getVamapWithGeojsonAndPins(
  $farmUuid: ID!
  $fieldUuid: ID!
  $vectorAnalysisMapUuid: ID!
  $areaUnit: AreaUnit!
  $withGeojson: Boolean!
) {
  getFarms(farmUuids: [$farmUuid]) {
    uuid
    fields(fieldUuids: [$fieldUuid]) {
      uuid
      farmUuid
      name
      status
      area(unit: $areaUnit)
      labels {
        name
        value
      }
      centroid {
        latitude
        longitude
      }
      boundingBox
      boundaryUrl
      geoMaps {
        shortName
        url
      }
      vectorAnalysisMaps(
        vectorAnalysisMapUuids: [$vectorAnalysisMapUuid]
        statuses: [EXECUTED, SAVED]
      ) {
        uuid
        fieldUuid
        name
        type
        analysisType
        zonesMapGeojson(unit: $areaUnit) @include(if: $withGeojson)
        area(unit: $areaUnit)
        createdDate
        updatedDate
        status
        geoMaps {
          shortName
          url
          classes
          hexColorCodes
        }
        satelliteImages {
          satelliteImage {
            uuid
          }
        }
        soilDataset {
          uuid
        }
        yieldDataset {
          uuid
        }
        asAppliedDataset {
          uuid
        }
        topographyMap {
          uuid
        }
        dataLayers {
          factor
          index
          satelliteImages {
            satelliteImage {
              uuid
            }
          }
          soilDataset {
            uuid
          }
          soilAttribute
          yieldDataset {
            uuid
          }
          yieldAttribute
          asAppliedDataset {
            uuid
          }
          asAppliedAttribute
          topographyMap {
            uuid
          }
          topographyAttribute
        }
        zonesOperationMaps {
          map {
            uuid
          }
          zoneNumbers
        }
        parameters {
          index
          numberOfZones
          soilAttribute
          yieldAttribute
          asAppliedAttribute
          topographyAttribute
          zonesOperation
          polygonMinArea
          dataClassificationType
        }
        variabilityFactor
      }
      notes(vectorAnalysisMapUuid: $vectorAnalysisMapUuid) {
        uuid
        fieldUuid
        centroid {
          latitude
          longitude
        }
        createdDate
        updatedDate
        type
        title
        completed
        comment
        photos {
          uuid
          noteUuid
          commentUuid
          url
          createdDate
          updatedDate
        }
        comments {
          uuid
          createdDate
          text
          photos {
            uuid
            noteUuid
            commentUuid
            url
            createdDate
            updatedDate
          }
        }
        vectorAnalysisMap {
          uuid
        }
        soilDataset {
          uuid
        }
      }
    }
  }
}
