import { API, graphqlOperation } from '@aws-amplify/api';

import {
  JOHN_DEERE_FIRST_PAGE_PAGINATION,
  JOHN_DEERE_DEFAULT_PAGE_SIZE_PAGINATION,
} from './helpers/constants';
import getFieldsJohnDeereQuery from './graphql/queries/getFieldsJohnDeere.gql';
import getJohnDeereFieldsIdsQuery from './graphql/queries/getJohnDeereFieldsIds.gql';

export const getFieldsJohnDeere = async (
  page = JOHN_DEERE_FIRST_PAGE_PAGINATION,
  pageSize = JOHN_DEERE_DEFAULT_PAGE_SIZE_PAGINATION,
  organizationsIds = [],
  fieldName = '',
) => {
  const { data } = await API.graphql(
    graphqlOperation(getFieldsJohnDeereQuery, {
      fieldFilter: {
        page,
        pageSize,
        organizationsIds,
        fieldName,
      },
    }),
  );

  return {
    ...data.getFieldsJohnDeere,
    page,
    pageSize,
    organizationsIds,
    fieldName,
  };
};

export const getJohnDeereFieldsIds = async ({
  fieldUuid,
  page,
  pageSize,
  organizationsIds,
}) => {
  const { data } = await API.graphql(
    graphqlOperation(getJohnDeereFieldsIdsQuery, {
      fieldFilter: {
        fieldUuid,
        page,
        pageSize,
        organizationsIds,
      },
    }),
  );

  return data.getFieldsJohnDeere;
};
