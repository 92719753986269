import { API, graphqlOperation } from '@aws-amplify/api';

import { fetchAllSatelliteImages } from '../satelliteImages/satelliteImagesAPI';

import getFarmFields from './graphql/queries/getFarmFields.gql';
import getFieldWithExportAssets from './graphql/queries/getFieldWithExportAssets.gql';
import getFieldLegendData from './graphql/queries/getFieldLegendData.gql';
import exportDataMutation from './graphql/mutations/exportData.gql';
import exportIsoXmlMutation from './graphql/mutations/exportIsoXml.gql';

export const fetchFarmFields = async (uuid) =>
  API.graphql(
    graphqlOperation(getFarmFields, {
      farmUuids: [uuid],
    }),
  ).then(({ data: { getFarms } }) => getFarms[0].fields);

export const fetchFieldWithExportAssets = async (
  farmUuid,
  fieldUuid,
  areaUnit,
) =>
  API.graphql(
    graphqlOperation(getFieldWithExportAssets, {
      farmUuids: [farmUuid],
      fieldUuids: [fieldUuid],
      areaUnit,
    }),
  ).then(({ data: { getFarms } }) => getFarms[0].fields[0]);

export const fetchFieldLegendData = async (farmUuid, fieldUuid) =>
  Promise.all([
    API.graphql(
      graphqlOperation(getFieldLegendData, {
        farmUuids: [farmUuid],
        fieldUuids: [fieldUuid],
      }),
    ),
    fetchAllSatelliteImages(farmUuid, fieldUuid),
  ]).then(([fieldAssets, satelliteImages]) => ({
    satelliteImages,
    ...fieldAssets.data.getFarms[0].fields[0],
  }));

export const exportData = async ({
  filePostfix,
  vectorAnalysisMaps,
  fieldBoundaries,
  notes,
  equationMaps,
  asMultiGeometry,
  onlyProductColumns,
  archiveName,
}) =>
  API.graphql(
    graphqlOperation(exportDataMutation, {
      input: {
        filePostfix,
        vectorAnalysisMaps,
        fieldBoundaries,
        notes,
        equationMaps,
        asMultiGeometry,
        onlyProductColumns,
        archiveName,
      },
    }),
  ).then(
    ({
      data: {
        exportData: { archiveUrl },
      },
    }) => archiveUrl,
  );

export const exportIsoXmlData = async ({
  applicationMaps,
  equationMaps,
  filePostfix,
  archiveName,
}) =>
  API.graphql(
    graphqlOperation(exportIsoXmlMutation, {
      input: {
        applicationMaps,
        equationMaps,
        filePostfix,
        archiveName,
      },
    }),
  ).then(
    ({
      data: {
        exportIsoXml: { archiveUrl },
      },
    }) => archiveUrl,
  );
