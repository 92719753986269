import { RVI_INDEX, SATELLITE_INDEXES } from '../../../../../helpers/satellite';
import { VectorAnalysisMapZoneType } from '../../types/dataVariableAttribute';

export const DEFAULT_VAMAP_ZONE_TYPE = 'ZONE_ID';

export const VAMAP_VARIABLE_ZONE_TYPE_TO_I18N_KEY: Record<
  VectorAnalysisMapZoneType,
  string
> = {
  ZONE_VALUE: 'zones-ops.equation-based.vamap-variable-zone-type.zone-value',
  ZONE_ID: 'zones-ops.equation-based.vamap-variable-zone-type.zone-id',
};

export const EQUATION_BASED_ANALYSIS_SATELLITE_INDEXES = [
  ...SATELLITE_INDEXES,
  RVI_INDEX,
];
