#import "../fragments/topographyMap.gql"

query getTopographyMaps(
  $farmUuids: [ID]
  $fieldUuids: [ID]
  $datasetUuids: [ID]
) {
  getFarms(farmUuids: $farmUuids) {
    uuid
    fields(fieldUuids: $fieldUuids) {
      uuid
      topographyMaps(topographyMapUuids: $datasetUuids, statuses: [EXECUTED]) {
        ...topographyMapFields
      }
    }
  }
}
