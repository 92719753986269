import { prepareZonesMapJson } from '../../../../helpers/analysis';
import getAssetByUuid from '../../../../helpers/functions/entities/getAssetByUuid';
import { AssetType } from '../../../../helpers/constants/entities/asset';
import { prepareEquationMapJson } from '../../../../helpers/functions/entities/equationMap';
import { createPinsGroup } from '../../../pins/helpers/functions/pinsGroup';
import {
  remapUuid as remapSatelliteImageUuid,
  transform as transformSatelliteImages,
} from '../../../satelliteImages/helpers/functions/satelliteImages';
import {
  Dataset,
  TopographyMap,
  TransformedAsAppliedDataset,
  TransformedSoilDataset,
  TransformedTopographyMap,
  TransformedYieldDataset,
} from '../../../../helpers/types/dataset';
import {
  VectorAnalysisMap,
  TransformedVectorAnalysisMap,
} from '../../../../helpers/types/vectorAnalysisMap';
import {
  EquationMap,
  TransformedEquationMap,
} from '../../../../helpers/types/equationMap';
import {
  ThreeDimensionalMap,
  TransformedThreeDimensionalMap,
} from '../../../../helpers/types/threeDimensionalMap';
import { Pin, PinsGroup } from '../../../pins/types';
import { Field, TransformedField } from '../../types/field';
import { Farm, TransformedFarm } from '../../../farms/types/farm';

export const transformFarms = (farms: Farm[] = []): TransformedFarm[] =>
  farms.map((farm) => {
    const { usersEmails, ...cleanedFarm } = farm;

    const result: TransformedFarm = {
      ...cleanedFarm,
      assetType: AssetType.farm,
      fields: transformFields(farm.fields),
    };

    if (usersEmails) {
      result.usersEmails = [...new Set(usersEmails)];
    }

    return result;
  });

export const transformFields = (fields: Field[] = []): TransformedField[] =>
  fields.map((field) => {
    const {
      satelliteImages,
      soilDatasets,
      yieldDatasets,
      asAppliedDatasets,
      topographyMaps,
      vectorAnalysisMaps,
      threeDimensionalMaps,
      equationMaps,
      notes: pins,
      farmUuid,
      ...cleanedField
    } = field;
    const result: TransformedField = {
      assetType: AssetType.field,
      ...cleanedField,
    };

    if (farmUuid) {
      result.farmUuid = farmUuid;
    }

    if (Array.isArray(satelliteImages)) {
      result.satelliteImages = transformSatelliteImages(satelliteImages);
    }

    if (Array.isArray(soilDatasets)) {
      result.soilDatasets = transformSoilDatasets(soilDatasets);
    }

    if (Array.isArray(vectorAnalysisMaps)) {
      result.vectorAnalysisMaps =
        transformVectorAnalysisMaps(vectorAnalysisMaps);
    }

    if (Array.isArray(equationMaps)) {
      result.equationMaps = transformEquationMaps(equationMaps);
    }

    if (Array.isArray(threeDimensionalMaps)) {
      result.threeDimensionalMaps =
        transformThreeDimensionalMaps(threeDimensionalMaps);
    }

    if (Array.isArray(yieldDatasets)) {
      result.yieldDatasets = transformYieldDatasets(yieldDatasets);
    }

    if (Array.isArray(asAppliedDatasets)) {
      result.asAppliedDatasets = transformAsAppliedDatasets(asAppliedDatasets);
    }

    if (Array.isArray(topographyMaps)) {
      result.topographyMaps = transformTopographyMaps(topographyMaps);
    }

    if (Array.isArray(pins)) {
      result.pinsGroups = groupPins(pins, result);
    }

    return result;
  });

export const transformTopographyMaps = (
  topographyMaps?: TopographyMap[],
): TransformedTopographyMap[] =>
  (topographyMaps || []).map((topographyMap) => ({
    assetType: AssetType.topographyMap,
    ...topographyMap,
  }));

export const transformAsAppliedDatasets = (
  asAppliedDatasets?: Dataset[],
): TransformedAsAppliedDataset[] =>
  (asAppliedDatasets || []).map((dataset) => ({
    assetType: AssetType.asAppliedDataset,
    ...dataset,
  }));

export const transformYieldDatasets = (
  yieldDatasets?: Dataset[],
): TransformedYieldDataset[] =>
  (yieldDatasets || []).map((dataset) => ({
    assetType: AssetType.yieldDataset,
    ...dataset,
  }));

export const transformSoilDatasets = (
  soilDatasets?: Dataset[],
): TransformedSoilDataset[] =>
  (soilDatasets || []).map((dataset) => ({
    assetType: AssetType.soilDataset,
    ...dataset,
  }));

export const transformThreeDimensionalMaps = (
  threeDMaps?: ThreeDimensionalMap[],
): TransformedThreeDimensionalMap[] =>
  (threeDMaps || []).map((threeDMap) => ({
    assetType: AssetType.threeDimensionalMap,
    pointsStored: false,
    ...threeDMap,
  }));

export const transformEquationMaps = (
  equationMaps?: EquationMap[],
): TransformedEquationMap[] =>
  (equationMaps || []).map((equationMap) => {
    const {
      dataVariables,
      equationMapGeojson,
      attributesJson,
      ...cleanedEquationMap
    } = equationMap;

    return {
      assetType: AssetType.equationMap,
      ...cleanedEquationMap,
      ...(equationMapGeojson
        ? {
            equationMapGeojson: prepareEquationMapJson(
              JSON.parse(equationMapGeojson),
            ),
          }
        : null),
      ...(attributesJson
        ? { attributes: prepareEquationMapJson(JSON.parse(attributesJson)) }
        : null),
      ...(dataVariables
        ? {
            dataVariables: dataVariables.map((dataVariable) => {
              const {
                satelliteImages: dataVariableSatelliteImages,
                ...cleanedDataVariable
              } = dataVariable;

              return {
                ...cleanedDataVariable,
                ...(dataVariableSatelliteImages
                  ? {
                      satelliteImages: remapSatelliteImageUuid(
                        dataVariableSatelliteImages,
                      ),
                    }
                  : null),
              };
            }),
          }
        : null),
    };
  });

export const transformVectorAnalysisMaps = (
  vectorAnalysisMaps?: VectorAnalysisMap[],
): TransformedVectorAnalysisMap[] =>
  (vectorAnalysisMaps || []).map((vamap) => {
    const {
      satelliteImages,
      dataLayers,
      zonesMapGeojson,
      attributesJson,
      ...cleanedVamap
    } = vamap;

    return {
      assetType: AssetType.vectorAnalysisMap,
      ...cleanedVamap,
      ...(satelliteImages
        ? { satelliteImages: remapSatelliteImageUuid(satelliteImages) }
        : null),
      ...(dataLayers
        ? {
            dataLayers: dataLayers.map((dataLayer) => {
              const {
                satelliteImages: dataLayerSatelliteImages,
                ...cleanedDataLayer
              } = dataLayer;

              return {
                ...cleanedDataLayer,
                ...(dataLayerSatelliteImages
                  ? {
                      satelliteImages: remapSatelliteImageUuid(
                        dataLayerSatelliteImages,
                      ),
                    }
                  : null),
              };
            }),
          }
        : null),
      ...(zonesMapGeojson
        ? { zonesMapGeojson: prepareZonesMapJson(JSON.parse(zonesMapGeojson)) }
        : null),
      ...(attributesJson
        ? { attributes: prepareZonesMapJson(JSON.parse(attributesJson)) }
        : null),
    };
  });

export const groupPins = (
  pins: Pin[],
  field: TransformedField,
): PinsGroup[] => {
  const pinsGroups: PinsGroup[] = [];

  (pins || []).forEach((pin) => {
    const { fieldUuid, vectorAnalysisMap, soilDataset } = pin;
    const subAssetUuid =
      vectorAnalysisMap?.uuid || soilDataset?.uuid || fieldUuid;

    let pinsGroup = pinsGroups.find(
      (pGroup) =>
        pGroup.fieldUuid === fieldUuid && pGroup.uuid === subAssetUuid,
    );

    if (!pinsGroup) {
      const asset = getAssetByUuid(subAssetUuid, field);

      if (!asset) {
        // skip points without asset (case when asset was deleted)
        return;
      }

      pinsGroup = createPinsGroup(asset, fieldUuid);
      pinsGroups.push(pinsGroup);
    }

    pinsGroup.pins.push(pin);
  });

  return pinsGroups;
};

/**
 * Merges two arrays of objects with uuid property, updating existing items and adding new ones.
 * @param existingItems Current items in the array
 * @param newItems New items to merge in
 * @returns Merged array with no duplicates
 */
export const mergeAssetsByUuid = <T extends { uuid: string }>(
  existingItems: T[],
  newItems: T[],
): T[] => {
  const existingItemsMap = new Map<string, T>();

  existingItems.forEach((item) => {
    existingItemsMap.set(item.uuid, item);
  });

  // Process all new items, either updating existing ones or adding them
  newItems.forEach((newItem) => {
    const existingItem = existingItemsMap.get(newItem.uuid);

    if (existingItem) {
      // Update existing item with new properties
      Object.assign(existingItem, newItem);
    } else {
      // Add new item
      existingItemsMap.set(newItem.uuid, newItem);
    }
  });

  // Convert map back to array
  return Array.from(existingItemsMap.values());
};
