import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import List from '@material-ui/core/List';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListItem from '@material-ui/core/ListItem';

import PopupHeader from '../../../../../components/Popups/PopupHeader';
import Button from '../../../../../components/Button';
import Checkbox from '../../../../../components/Checkbox';
import TextField from '../../../../../components/TextField';
import BackdropLoading from '../../../../../components/Loading/BackdropLoading';
import { filterByKey } from '../../../../../helpers';

import './index.scss';

const getSelectedItemsArray = (selectedMap) =>
  Object.entries(selectedMap).reduce((acc, [key, value]) => {
    if (value) {
      acc.push(key);
    }

    return acc;
  }, []);

const hasSelectedItem = (initialItemsIds, selectedMap) =>
  Object.entries(selectedMap).some(
    ([key, value]) => value && initialItemsIds.indexOf(key) === -1,
  );

const SelectableListPopup = ({
  initialItemsIds = [],
  items = [],
  title,
  description,
  filter: { hide: hideFilter = false, placeholder: filterPlaceholder = '' },
  confirm: { label: confirmLabel, color: confirmColor = 'primary' },
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(false);
  const [filter, setFilter] = useState('');
  const [selectedItemsMap, setSelectedItemsMap] = useState(
    items.reduce((acc, item) => {
      acc[item.id] = initialItemsIds.indexOf(item.id) !== -1;

      return acc;
    }, {}),
  );

  const handleFilterChange = (value) => {
    setFilter(value);
  };

  const handleItemCheckboxClick = (item, e) => {
    setSelectedItemsMap({
      ...selectedItemsMap,
      [item.id]: e.target.checked,
    });
  };

  const handleConfirmClick = () => {
    const selectedItemsArray = getSelectedItemsArray(selectedItemsMap);

    setProcessing(true);
    onConfirm(selectedItemsArray);
  };

  const filteredItems = filterByKey('label', items, filter);

  return (
    <Dialog
      open
      classes={{
        paper: 'selectable-list-popup',
      }}
    >
      <PopupHeader title={title} onCancel={onCancel} />
      <DialogContent>
        <DialogContentText
          classes={{
            root: 'selectable-list-popup__content-text',
          }}
        >
          {description}
        </DialogContentText>
        {!hideFilter && (
          <TextField
            type="search"
            value={filter}
            placeholder={filterPlaceholder}
            onChange={(e) => handleFilterChange(e.target.value)}
            onEndAdornmentClick={() => handleFilterChange('')}
          />
        )}
        {filteredItems.length === 0 ? (
          <Typography
            variant="body2"
            classes={{
              root: 'selectable-list-popup__no-results-label',
            }}
          >
            {t('general.controls.no-results')}
          </Typography>
        ) : (
          <List>
            {filteredItems.map((item) => (
              <ListItem
                key={item.id}
                classes={{
                  root: 'selectable-list-popup__list-item',
                }}
              >
                <FormControlLabel
                  disabled={initialItemsIds.indexOf(item.id) !== -1}
                  label={item.label}
                  classes={{
                    label: 'selectable-list-popup__list-item-label',
                  }}
                  control={
                    <Checkbox
                      value={selectedItemsMap[item.id] ? 2 : 0}
                      name={item.label}
                      onChange={(e) => handleItemCheckboxClick(item, e)}
                    />
                  }
                />
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions
        classes={{
          root: 'selectable-list-popup__actions',
        }}
      >
        <Button variant="outlined" onClick={onCancel}>
          {t('general.controls.cancel')}
        </Button>
        <Button
          variant="contained"
          color={confirmColor}
          disabled={!hasSelectedItem(initialItemsIds, selectedItemsMap)}
          onClick={handleConfirmClick}
        >
          {confirmLabel}
        </Button>
      </DialogActions>
      {processing && <BackdropLoading />}
    </Dialog>
  );
};

export default SelectableListPopup;
