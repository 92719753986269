query getSubscriptionsJohnDeere {
  getSubscriptionsJohnDeere {
    subscriptions {
      id
      name
      type
      status
      orgIds
      fieldOperationTypes
    }
  }
}
