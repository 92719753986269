const DEFAULT_LEGEND = {
  evi2: [
    { color: '#a50026', quantity: '0' },
    { color: '#d73027', quantity: '0.5' },
    { color: '#ffffbf', quantity: '1.7' },
    { color: '#66bd63', quantity: '2.1' },
    { color: '#006837', quantity: '2.4' },
  ],
  lai: [
    { color: '#a50026', quantity: '0' },
    { color: '#d73027', quantity: '0.7' },
    { color: '#ffffbf', quantity: '3.0' },
    { color: '#66bd63', quantity: '4.1' },
    { color: '#006837', quantity: '5.86' },
  ],
  ndvi: [
    { color: '#a50026', quantity: '0' },
    { color: '#d73027', quantity: '0.3' },
    { color: '#ffffbf', quantity: '0.6' },
    { color: '#66bd63', quantity: '0.8' },
    { color: '#006837', quantity: '1' },
  ],
  gndvi: [
    { color: '#a50026', quantity: '0' },
    { color: '#d73027', quantity: '0.3' },
    { color: '#ffffbf', quantity: '0.6' },
    { color: '#66bd63', quantity: '0.8' },
    { color: '#006837', quantity: '1' },
  ],
  ipvi: [
    { color: '#a50026', quantity: '0' },
    { color: '#d73027', quantity: '0.65' },
    { color: '#ffffbf', quantity: '0.85' },
    { color: '#66bd63', quantity: '0.92' },
    { color: '#006837', quantity: '1' },
  ],
  gci: [
    { color: '#e5f5e0', quantity: '0' },
    { color: '#74c476', quantity: '2.5' },
    { color: '#005a32', quantity: '7' },
  ],
  savi: [
    { color: '#a50026', quantity: '0' },
    { color: '#d73027', quantity: '0.3' },
    { color: '#ffffbf', quantity: '0.8' },
    { color: '#66bd63', quantity: '1.2' },
    { color: '#006837', quantity: '1.5' },
  ],
  osavi: [
    { color: '#a50026', quantity: '0' },
    { color: '#d73027', quantity: '0.3' },
    { color: '#ffffbf', quantity: '0.62' },
    { color: '#66bd63', quantity: '0.75' },
    { color: '#006837', quantity: '1' },
  ],
  ndwi: [{ color: '#4292c6', quantity: 'Water' }],
  wdrvi: [
    { color: '#a50026', quantity: '-0.59' },
    { color: '#d73027', quantity: '-0.3' },
    { color: '#ffffbf', quantity: '0' },
    { color: '#66bd63', quantity: '0.3' },
    { color: '#006837', quantity: '0.64' },
  ],
  mcari1: [
    { color: '#a50026', quantity: '0' },
    { color: '#d73027', quantity: '2000' },
    { color: '#ffffbf', quantity: '4500' },
    { color: '#66bd63', quantity: '7000' },
    { color: '#006837', quantity: '9500' },
  ],
};

export default DEFAULT_LEGEND;
