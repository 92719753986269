import isValid from 'date-fns/isValid';

const formatCommentDate = (d: string) => {
  const date = new Date(d);
  if (!isValid(date)) {
    return '';
  }

  return (
    `${date.toLocaleDateString('default', { day: '2-digit' })}.` +
    `${date.toLocaleDateString('default', { month: '2-digit' })}.` +
    `${date.toLocaleDateString('default', { year: 'numeric' })} | ` +
    `${date.toLocaleTimeString('default', { hour: '2-digit', minute: '2-digit' })}`
  );
};

export default formatCommentDate;
