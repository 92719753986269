import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Amplify } from '@aws-amplify/core';

import './i18n';
import { init } from './features/ui/applicationShell/helpers/functions/sentry';
import { getAWSConfig } from './helpers/functions/utils/vendorConfigs/aws';
import Amplitude from './helpers/classes/amplitude';
import App from './App';

init();
Amplify.configure(getAWSConfig());
Amplitude.configure();

ReactDOM.render(
  <>
    <CssBaseline />
    <App />
  </>,
  document.getElementById('root'),
);
