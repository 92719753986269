import { createSelector } from '@reduxjs/toolkit';
import { selectVectorAnalysisMaps } from '../../field/fieldSelectors';

export const selectLegend = ({ crossLayer }) => crossLayer.legend;

export const selectSelectedVamaps = ({ crossLayer }) =>
  crossLayer.selectedVamaps;

export const selectSelectedVectorAnalysisMaps = createSelector(
  selectVectorAnalysisMaps,
  selectSelectedVamaps,
  (vamaps, selectedVamaps) =>
    vamaps.filter(({ uuid }) => {
      const selectedZones = selectedVamaps[uuid];

      return !!selectedZones;
    }),
);

export const selectNotEmptySelectedVectorAnalysisMaps = createSelector(
  selectVectorAnalysisMaps,
  selectSelectedVamaps,
  (vamaps, selectedVamaps) =>
    vamaps.filter(({ uuid }) => {
      const selectedZones = selectedVamaps[uuid];

      return Array.isArray(selectedZones) && selectedZones.length > 0;
    }),
);

export const selectVamapsFilter = ({ crossLayer }) => crossLayer.vamapsFilter;

export const selectSettings = ({ crossLayer }) => crossLayer.settings;

export const selectChanged = ({ crossLayer }) => crossLayer.changed;

export const selectCloned = ({ crossLayer }) => crossLayer.cloned;
