import app from '../../../configs/app.json';

export const getBrandName = () => app.brand.shortName;

export const getBrandFullName = () => app.brand.fullName;

export const getHelpLink = () => app.links.help;

export const getPolicyLink = () => app.links.policy;

export const getTermsLink = () => app.links.terms;

export const getPalette = () => app.palette;

export const isPricingEnabled = () => app.features.pricing.enabled;

export const isJohnDeereImportWorkflowEnabled = () =>
  app.features.jd.import.enabled;

export const isJohnDeereExportWorkflowEnabled = () =>
  app.features.jd.export.enabled;

export const isJohnDeereWorkflowEnabled = () =>
  isJohnDeereImportWorkflowEnabled() && isJohnDeereExportWorkflowEnabled();

export const isJohnDeereQuickStartEnabled = () =>
  app.features.jd.quickStart.enabled;

export const getFieldStabilityBlogPostLink = () =>
  app.links.fieldStabilityBlogPost;

export const getDataCompatibilityBlogPostLink = () =>
  app.links.dataCompatibilityBlogPost;

export const getDemoFormLink = () => app.links.demoForm;

export const getAuthenticationRequiredGroups = () =>
  app.features.authentication.requiredGroups;

export const isApplicationShellReportIssueEnabled = () =>
  app.features.applicationShell.withReportIssue;

export const isSignUpEnabled = () => app.features.authentication.withSignUp;

export const getExportFilePostfix = () => app.features.export.filePostfix;

export const getExportRatesOnlyDefaultValue = () =>
  app.features.export.ratesOnly.defaultValue;

export const getExportRatesOnlyShowUI = () =>
  app.features.export.ratesOnly.showUI;

export const getKeyValueDBName = () => app.storage.keyValueDBName;

export const getAvailableLanguages = () => app.availableLanguages;

export const getEquationsAdditionalCategories = () =>
  app.features.equations.additionalCategories;

export const isPdfReportsEnabled = () => app.features.reports.pdf.enabled;

export const isFieldWorkflowAssetsGroupingEnabled = () =>
  app.features.fieldWorkflow.assetsGrouping.enabled;

export const isBingMapsEnabled = () => app.features.maps.bing.enabled;
