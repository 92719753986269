import React, { useContext } from 'react';

import { DataLayersListContext } from '../../../hooks/useDataLayersList';
import { TransformedSatelliteImage } from '../../../../../satelliteImages/types/satelliteImage';
import RecommendedSatelliteImagesPicker from '../../../components/RecommendedSatelliteImagesPicker';

export default function CreateAnalysisRecommendedImagesPicker({
  onRecommendedSelect,
}: {
  onRecommendedSelect: (uuids: string[]) => void;
}) {
  const context = useContext(DataLayersListContext);

  const images = context.assets as TransformedSatelliteImage[];
  const filteredImages = context.filteredAssets as TransformedSatelliteImage[];

  return (
    <RecommendedSatelliteImagesPicker
      dropdownView
      allImages={images}
      filteredImages={filteredImages}
      onSelect={onRecommendedSelect}
    />
  );
}
