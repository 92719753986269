import React, { ReactNode } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  grid: {
    maxWidth: '400px',
    [theme.breakpoints.up('md')]: {
      maxWidth: '736px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '736px',
    },
  },
}));

const TwoSidePanel = ({
  left,
  right,
}: {
  left: ReactNode;
  right: ReactNode;
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={4} className={classes.grid}>
      <Grid item xs={12} md={6}>
        {left}
      </Grid>
      <Grid item xs={12} md={6}>
        {right}
      </Grid>
    </Grid>
  );
};

export default TwoSidePanel;
