import i18n from 'i18next';

import { TYPE_I18N_KEY_LABEL_MAP } from '../../../../../helpers/analysis';
import { AnalysisMapType } from '../../../../../helpers/constants/entities/vectorAnalysisMap';
import { comparator } from '../../../../../helpers/functions/utils/string';
import { VectorAnalysisFilters } from '../../types/analysis';
import { DEFAULT_VAMAPS_FILTER } from '../constants/analysis';
import { TransformedVectorAnalysisMap } from '../../../../../helpers/types/vectorAnalysisMap';
import { isVamapInvalid } from '../../../../../helpers/functions/entities/vectorAnalysisMap';

export const getVamapTypeFilterOptions = (
  vamaps: TransformedVectorAnalysisMap[] = [],
  allValue = '',
) => {
  const vamapsTypes = vamaps.reduce(
    (acc, { analysisType }) => (analysisType ? [...acc, analysisType] : acc),
    [] as AnalysisMapType[],
  );
  const availableTypes = new Set(vamapsTypes);
  const options = [...availableTypes].map((type) => ({
    title: i18n.t(TYPE_I18N_KEY_LABEL_MAP[type]),
    value: type,
  }));

  return [
    {
      title: i18n.t('general.controls.zones-map-filters.all'),
      value: allValue,
    },
    ...options.sort((aOption, bOption) =>
      comparator(aOption.title, bOption.title),
    ),
  ];
};

export const filterVamaps = (
  filter: VectorAnalysisFilters,
  vamaps: TransformedVectorAnalysisMap[] = [],
) => {
  const { typeFilterValue, nameFilterValue = '' } = filter;
  const preparedNameFilterValue = nameFilterValue.toLowerCase();

  return vamaps.filter((vamap) => {
    const nameMatched = (vamap.name || '')
      .toLowerCase()
      .includes(preparedNameFilterValue);

    if (isVamapInvalid(vamap)) {
      return false;
    }

    return typeFilterValue === DEFAULT_VAMAPS_FILTER.typeFilterValue
      ? nameMatched
      : vamap.analysisType === typeFilterValue && nameMatched;
  });
};
