import React from 'react';
import clsx from 'clsx';
import TableCell from '@material-ui/core/TableCell';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import ButtonMenu from '../../../../../../components/ButtonMenu';
import getCellClasses from '../../../helpers/functions/getCellClasses';

import './index.scss';

function MenuCell({ row = {}, headCell = {} }) {
  return (
    <TableCell
      align={headCell.horizontalAlign || 'left'}
      className={clsx(getCellClasses('menu-cell', headCell, row))}
    >
      <ButtonMenu
        isIconButton
        size="small"
        items={headCell.menuItems}
        onItemClick={(item) => headCell.onMenuItemClick(item, row)}
      >
        <MoreHorizIcon fontSize="small" />
      </ButtonMenu>
    </TableCell>
  );
}

export default MenuCell;
