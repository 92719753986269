import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import InputsListPopup from '../../../../../components/Popups/InputsList';
import { useShareFarmsMutation } from '../../../../farms/farmsAPI';

const ShareFarmsExternalPopup = ({ farmUuids, onConfirm, ...popup }) => {
  const { t } = useTranslation();
  const [shareFarms] = useShareFarmsMutation();

  const handleConfirmClick = async (emails) => {
    await shareFarms({
      emails,
      farmUuids,
    });
    onConfirm();
  };

  return (
    <InputsListPopup
      {...popup}
      inputType="email"
      title={t('general.popups.share-farms.title')}
      description={
        <Trans i18nKey={t('general.popups.share-farms-external.description')} />
      }
      placeholder={t('general.popups.invite-user.placeholder')}
      addItemLabel={t('general.popups.invite-user.add-email')}
      confirmLabel={t('general.controls.share')}
      onConfirm={handleConfirmClick}
    />
  );
};

export default ShareFarmsExternalPopup;
