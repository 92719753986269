import clsx from 'clsx';
import { DefaultTableRow } from '../../types/row';
import { DefaultTableColumn } from '../../types/column';

const getCellClasses = (
  className: string,
  column: DefaultTableColumn & {
    horizontalAlign?: 'right' | 'left';
    verticalAlign?: 'middle';
  },
  row: DefaultTableRow,
) =>
  clsx(className, {
    [`${className}_align-right`]: column.horizontalAlign === 'right',
    [`${className}_align-top`]: column.verticalAlign !== 'middle',
    [`${className}_leading`]: column.leading,
    [`${className}_disabled`]: row.disabled,
    [`${className}_with-nested-row`]: row.hasNestedRow && !column.rowSpan,
    text_secondary: column.secondary,
  });

export default getCellClasses;
