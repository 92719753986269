import {
  DatasetStatus,
  FullAttribute,
  TransformedDataset,
} from '../../types/dataset';
import { comparator } from '../utils/string';
import { DatasetViewType } from '../../constants/entities/dataset';
import { GeoMap } from '../../types/api';
import { prettifyNumber } from '../../markup';
import { findAttributeToPreselect } from './attribute';
import { isAsAppliedDataset, isYieldDataset } from './assets';
import { isGeoMapVersionTwoEnabled } from '../utils/vendorConfigs/geopard';

/**
 * Extracts the attributes from a dataset that are associated with geo maps.
 *
 * @param {TransformedDataset} dataset The transformed dataset with attributes and geo maps.
 * @returns {string[]} An array of attributes associated with geo maps.
 */
export const extractDatasetGeoMapAttributes = ({
  attributes = [],
  geoMaps = [],
}: TransformedDataset): string[] => {
  const geoMapsNames = geoMaps.map(({ shortName }) => shortName);

  return attributes.filter((attribute) =>
    geoMapsNames.some((name) => name.indexOf(attribute) !== -1),
  );
};

export const addAttributesAndSortDatasets = (datasets: TransformedDataset[]) =>
  (datasets || [])
    .map((dataset) => ({
      ...dataset,
      attributes: extractDatasetGeoMapAttributes(dataset),
    }))
    .sort((a, b) => comparator(a.name || '', b.name || ''));

export const getDatasetViewTypes = (
  dataset: TransformedDataset,
): DatasetViewType[] => {
  const result = [DatasetViewType.raw];
  const hasProcessedViews = (dataset.geoMaps || []).some((geoMap) =>
    geoMap.shortName.startsWith(DatasetViewType.processed),
  );

  if (hasProcessedViews) {
    result.push(DatasetViewType.processed);
  }

  return result;
};

export const getDatasetGeoMapName = ({
  view,
  attribute,
  isThumbnail,
  useGeoMapVersionTwo,
}: {
  view: DatasetViewType;
  attribute: string;
  isThumbnail?: boolean;
  useGeoMapVersionTwo?: boolean;
}) => {
  const prefix = isThumbnail ? 'thumbnail_' : '';
  const postfix =
    useGeoMapVersionTwo && isGeoMapVersionTwoEnabled() ? '_2' : '';

  return `${prefix}${view}_${attribute}${postfix}`;
};

export const getDatasetViewProps = (
  dataset: TransformedDataset,
  attribute?: string | null,
  view?: DatasetViewType | null,
) => {
  const views = getDatasetViewTypes(dataset);
  const geoMapByShortName = Object.fromEntries(
    (dataset.geoMaps || []).map((geoMap) => [geoMap.shortName, geoMap]),
  );
  const selectedView = view || DatasetViewType.raw;
  const useGeoMapVersionTwo =
    isYieldDataset(dataset) || isAsAppliedDataset(dataset);
  const attributesWithGeoMaps = (dataset.attributes || []).filter(
    (attr) =>
      !!geoMapByShortName[
        getDatasetGeoMapName({
          view: selectedView,
          attribute: attr,
          useGeoMapVersionTwo,
        })
      ],
  );
  const selectedGeoMapName = getDatasetGeoMapName({
    view: selectedView,
    attribute: attribute || '',
    useGeoMapVersionTwo,
  });
  const selectedGeoMapExist = !!geoMapByShortName[selectedGeoMapName];
  const selectedAttribute =
    selectedGeoMapExist && attribute
      ? attribute
      : findAttributeToPreselect(dataset) || attributesWithGeoMaps[0] || '';

  return {
    attributes: attributesWithGeoMaps,
    attribute: selectedAttribute,
    views,
    view: selectedView,
  };
};

export const getFullAttribute = (
  attribute: string,
  fullAttributes?: FullAttribute[] | null,
) =>
  (fullAttributes || []).find(
    (fullAttribute) => fullAttribute.transliteratedName === attribute,
  );

export const getDatasetAttributeFullName = (
  attribute: string,
  fullAttributes?: FullAttribute[] | null,
) => getFullAttribute(attribute, fullAttributes)?.fullName || attribute;

export const getDatasetAttributeNameHandler =
  (fullAttributes?: FullAttribute[] | null) => (attribute: string) =>
    getDatasetAttributeFullName(attribute, fullAttributes);

export const getDatasetThumbnailGeoMap = ({
  geoMaps,
  view,
  attribute,
  useGeoMapVersionTwo,
}: {
  geoMaps?: GeoMap[];
  view: DatasetViewType;
  attribute: string;
  useGeoMapVersionTwo?: boolean;
}): GeoMap | null => {
  const geoMapName = getDatasetGeoMapName({
    view,
    attribute,
    isThumbnail: true,
    useGeoMapVersionTwo,
  });
  const geoMap = (geoMaps || []).find(
    ({ shortName }) => shortName === geoMapName,
  );

  return geoMap || null;
};

export const getDatasetLegendColors = (
  geoMap?: GeoMap,
):
  | {
      color: string;
      quantity: string;
    }[]
  | null => {
  if (!geoMap) {
    return null;
  }

  return (geoMap.classes || []).map((classValue, index) => ({
    color: geoMap.hexColorCodes?.[index] || '',
    quantity: prettifyNumber(classValue),
  }));
};

export const isCleanedStatus = (status?: DatasetStatus) => status === 'CLEANED';

export const isProcessedStatus = (status?: DatasetStatus) =>
  status === 'PROCESSED';

export const isPublishedStatus = (status?: DatasetStatus) =>
  status === 'PUBLISHED';

export const isInvalidStatus = (status?: DatasetStatus) => status === 'INVALID';

export const isInteractableStatus = (status?: DatasetStatus) =>
  isCleanedStatus(status) || isProcessedStatus(status);
