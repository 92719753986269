mutation addUsersToOrganization($input: BatchUserOrganizationInput!) {
  addUsersToOrganization(input: $input) {
    userUuid
    email
    allFarms
    farms {
      uuid
      farmOwnership
    }
  }
}
