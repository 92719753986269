import type Fuse from 'fuse.js';

import getPatternsMatch from '../../../../../helpers/functions/utils/getPatternsMatch';
import type { FieldDatasetEntity } from '../../../../assets/types/state';
import type {
  AssignedVariables,
  AssetVariableData,
  DatasetVariableData,
  SatelliteImageVariableData,
  VectorAnalysisMapVariableData,
  EquationMapVariableData,
} from '../../types/variables';
import { AssetType } from '../../../../../helpers/constants/entities/asset';
import { FullAttribute } from '../../../../../helpers/types/dataset';
import { TransformedAsset } from '../../../../../helpers/types';
import {
  isVectorAnalysis,
  isDataset,
} from '../../../../../helpers/functions/entities/assets';
import { findAttributeToPreselect } from '../../../../../helpers/functions/entities/attribute';
import { DEFAULT_VAMAP_ZONE_TYPE } from '../../../dataVariable/helpers/constants/dataVariableAttribute';
import { getVamapVariableZoneTypeAttributeName } from '../../../dataVariable/helpers/functions/dataVariableAttribute';
import { isInteractableStatus } from '../../../../../helpers/functions/entities/dataset';

/**
 * Sorts (in place) an array of matches objects based on
 * their scores, appliedCorrections, and operationStartDate.
 * @param matches - The array of FuseResult objects to be sorted.
 * @returns The sorted array of FuseResult objects.
 */
const sortMatches = (matches: Fuse.FuseResult<DatasetVariableData>[]) =>
  matches.sort((a, b) => {
    // If scores are not equal, sort by score
    if (a.score !== b.score) {
      return (a.score ?? 0) - (b.score ?? 0);
    }

    // If scores are equal, sort by appliedCorrections and operationStartDate
    let result = 0;

    if (a.item.appliedCorrections && !b.item.appliedCorrections) {
      result = -1;
    } else if (b.item.appliedCorrections && !a.item.appliedCorrections) {
      result = 1;
    } else if (a.item.appliedCorrections && b.item.appliedCorrections) {
      const aDate = new Date(a.item.operationStartDate || 0);
      const bDate = new Date(b.item.operationStartDate || 0);

      result = bDate.getTime() - aDate.getTime();
    }

    return result;
  });

const matchAttributesToVariables = (
  dataVariables: string[],
  variablesData: DatasetVariableData[],
) =>
  dataVariables.reduce<Record<string, DatasetVariableData>>((acc, variable) => {
    const matches = getPatternsMatch(variablesData, [variable], {
      keys: ['attribute', 'attribute.fullName', 'attribute.transliteratedName'],
      threshold: 0.6,
      ignoreLocation: true,
    });
    const matchesArray = sortMatches([...matches]);
    const [match] = matchesArray;

    acc[variable] = match?.item;

    return acc;
  }, {});

const extractDatasetVariableData = (fieldAssets: FieldDatasetEntity[]) =>
  fieldAssets.reduce<DatasetVariableData[]>((acc, fieldAsset) => {
    if (isDataset(fieldAsset) && !isInteractableStatus(fieldAsset.status)) {
      return acc;
    }

    const fieldAttributes =
      fieldAsset.fullAttributes || fieldAsset.attributes || [];
    const assetVariableData = fieldAttributes.map((attribute) => {
      let result: DatasetVariableData = {
        uuid: fieldAsset.uuid,
        name: fieldAsset.name || '',
        type: fieldAsset.assetType,
        attribute,
      };

      if ('operationStartDate' in fieldAsset) {
        result = {
          ...result,
          operationStartDate: fieldAsset.operationStartDate,
        };
      }

      if ('appliedCorrections' in fieldAsset) {
        result = {
          ...result,
          appliedCorrections: fieldAsset.appliedCorrections,
        };
      }

      return result;
    });

    acc.push(...assetVariableData);

    return acc;
  }, []);

/**
 * Finds matches across fields datasets and assigns data variables
 *
 * @param fieldsDatasetsEntities - Record mapping field UUIDs to their corresponding field dataset entities
 * @param dataVariables - Record mapping field UUIDs to arrays of variable strings
 * @returns Record mapping field UUIDs to their assigned variables
 */
export const assignFieldsVariables = (
  fieldsDatasetsEntities: Record<string, FieldDatasetEntity[]>,
  dataVariables: Record<string, string[]>,
) => {
  const result: Record<string, AssignedVariables> = {};

  for (const [fieldUuid, fieldDatasetEntities] of Object.entries(
    fieldsDatasetsEntities,
  )) {
    const datasetsVariablesData =
      extractDatasetVariableData(fieldDatasetEntities);
    const assignedVariables = matchAttributesToVariables(
      dataVariables[fieldUuid],
      datasetsVariablesData,
    );

    result[fieldUuid] = assignedVariables;
  }

  return result;
};

export const isAssignedVariablesValid = ({
  dataVariables,
  assignedVariables,
}: {
  dataVariables: string[];
  assignedVariables?: AssignedVariables;
}) => dataVariables.every((variable) => !!assignedVariables?.[variable]);

export const getAssetVariableDataAttributeName = (
  variableData: AssetVariableData,
) => {
  if (isEquationMapVariableData(variableData)) {
    return '';
  }

  if (isVectorAnalysisMapVariableData(variableData)) {
    return getVamapVariableZoneTypeAttributeName(variableData.attribute);
  }

  const { attribute } = variableData;

  return typeof attribute === 'string'
    ? attribute
    : attribute?.fullName || attribute?.transliteratedName || '';
};

export const getAssetVariableDataAttributeId = (
  attribute?: FullAttribute | string,
) =>
  typeof attribute === 'string'
    ? attribute
    : attribute?.transliteratedName || '';

const isSatelliteImageVariableData = (
  variable: AssetVariableData,
): variable is SatelliteImageVariableData =>
  variable && variable.type === AssetType.satelliteImage;

const isVectorAnalysisMapVariableData = (
  variable: AssetVariableData,
): variable is VectorAnalysisMapVariableData =>
  variable && variable.type === AssetType.vectorAnalysisMap;

const isEquationMapVariableData = (
  variable: AssetVariableData,
): variable is EquationMapVariableData =>
  variable && variable.type === AssetType.equationMap;

export const getAssetVariableUuid = (variable?: AssetVariableData) =>
  variable && !isSatelliteImageVariableData(variable) ? variable.uuid : null;

export const getSatelliteImageVariableUuids = (variable?: AssetVariableData) =>
  variable && isSatelliteImageVariableData(variable)
    ? variable.satelliteImageUuids
    : null;

export const findVariableAttributeToPreselect = (
  asset: TransformedAsset | null,
): string => {
  if (isVectorAnalysis(asset)) {
    return DEFAULT_VAMAP_ZONE_TYPE;
  }

  return findAttributeToPreselect(asset);
};
