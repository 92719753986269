import { useDispatch, useSelector } from 'react-redux';

import { generateSatelliteVectorAnalysisMap } from '../../../createAnalysis/createAnalysisSlice';
import {
  selectIndex,
  selectSelectedUuids,
  selectSettings,
} from '../createFieldPotentialZonesSelectors';

export default function useGenerateFieldPotentialVamap() {
  const dispatch = useDispatch();
  const satelliteImageUuids = useSelector(selectSelectedUuids);
  const index = useSelector(selectIndex);
  const settings = useSelector(selectSettings);

  return () =>
    dispatch(
      generateSatelliteVectorAnalysisMap({
        index,
        satelliteImageUuids,
        ...settings,
      }),
    );
}
