mutation generateTopographyVectorAnalysisMap(
  $input: GenerateTopographyVectorAnalysisMapInput
) {
  generateTopographyVectorAnalysisMapAsync(input: $input) {
    uuid
    fieldUuid
    statusCode
    statusMessage
  }
}
