import React, { useState, useMemo, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

import PopupHeader from '../../../../components/Popups/PopupHeader';
import Button from '../../../../components/Button';
import TextField from '../../../../components/TextField';
import BackdropLoading from '../../../../components/Loading/BackdropLoading';
import { fetchLabels, deleteLabel } from '../../labelsSlice';
import { selectLabels, selectLoaded } from '../../labelsSelectors';
import { openPopup } from '../../../ui/popups/popupsSlice';
import ItemsPicker from '../../../../components/ItemsPicker';
import AmplitudeAnalytics from '../../../../helpers/classes/amplitudeAnalytics';
import useCurrentPage from '../../../../hooks/useCurrentPage';

import './index.scss';

const createItemTitle = (label) => `${label.name}: ${label.value}`;

const ManageLabelsPopup = ({
  fieldLabels,
  fieldName = '',
  onCancel = () => {},
  onConfirm = () => {},
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const labels = useSelector(selectLabels);
  const loaded = useSelector(selectLoaded);
  const page = useCurrentPage();
  const [query, setQuery] = useState('');
  const [addedLabelsValues, setAddedLabelsValues] = useState(
    new Set([...(fieldLabels || []).map((label) => createItemTitle(label))]),
  );

  useEffect(() => {
    if (page) {
      dispatch(fetchLabels());
      AmplitudeAnalytics.trackLabelsPopupOpened({
        page,
        type: 'manageFieldLabels',
      });
    }
  }, [dispatch, page]);

  const handleCreateLabelClick = () => {
    dispatch(
      openPopup({
        type: 'create-label',
      }),
    );
  };

  const handleFilterChange = (e) => {
    const value = e.target.value.toLowerCase();
    setQuery(value);
  };

  const handleAvailableLabelMenuItemClick = ({ id }, item) => {
    const { name, value } = labels.find((label) => {
      const labelValue = createItemTitle(label);

      return item.value === labelValue;
    });

    if (id === 'delete-label') {
      dispatch(
        deleteLabel({
          name,
          value,
        }),
      );
    }
  };

  const handleAddedLabelsChange = (values) => {
    setAddedLabelsValues(values);
  };

  const handleConfirmClick = () => {
    const addedLabels = labels.filter((label) => {
      const value = createItemTitle(label);

      return addedLabelsValues.has(value);
    });

    AmplitudeAnalytics.trackFieldLabelsAdded({
      page,
      labelsAmount: addedLabels.length,
    });
    onConfirm(addedLabels);
  };

  const filteredItems = useMemo(
    () =>
      labels
        .map((label) => {
          const title = createItemTitle(label);

          return {
            value: title,
            title,
          };
        })
        .filter(({ title }) => title.toLowerCase().includes(query)),
    [labels, query],
  );

  if (!loaded) {
    return <BackdropLoading />;
  }

  return (
    <Dialog
      open
      classes={{
        root: 'manage-labels-popup',
      }}
    >
      <PopupHeader
        title={t('general.popups.manage-labels.title')}
        onCancel={onCancel}
      />
      <DialogContent>
        <DialogContentText
          classes={{
            root: 'manage-labels-popup__content-text',
          }}
        >
          <Trans i18nKey="general.popups.manage-labels.description">
            Text{' '}
            <span className="manage-labels-popup__content-text_highlighted">
              {{ fieldName }}
            </span>{' '}
            text
          </Trans>
        </DialogContentText>
        <TextField
          placeholder={t('general.popups.manage-labels.filter-placeholder')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className="manage-labels-popup__filter-input-icon" />
              </InputAdornment>
            ),
          }}
          value={query}
          onChange={handleFilterChange}
        />
        <ItemsPicker
          availableItemsTitle={t('general.popups.manage-labels.labels-to-add')}
          addedItemsTitle={t('general.popups.manage-labels.fields-labels')}
          addAvailableItemButtonLabel={t(
            'general.popups.manage-labels.create-new-label',
          )}
          emptyAddedItemsLabel={t(
            'general.popups.manage-labels.empty-added-labels',
          )}
          items={filteredItems}
          addedItemsValues={addedLabelsValues}
          availableItemMenuItems={[
            {
              id: 'delete-label',
              label: t('general.popups.manage-labels.delete-label'),
            },
          ]}
          onAddItemClick={handleCreateLabelClick}
          onAvailableItemMenuItemClick={handleAvailableLabelMenuItemClick}
          onAddedItemsChange={handleAddedLabelsChange}
        />
      </DialogContent>
      <DialogActions
        classes={{
          root: 'manage-labels-popup__actions',
        }}
      >
        <Button onClick={onCancel} variant="outlined">
          {t('general.controls.cancel')}
        </Button>
        <Button
          onClick={() => handleConfirmClick()}
          variant="contained"
          color="primary"
        >
          {t('general.popups.done')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManageLabelsPopup;
