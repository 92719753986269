import { FileStatus, UploadStep } from '../constants/upload';
import { DatasetStatus } from '../../../../../helpers/types/dataset';
import { FieldStatus } from '../../../../field/types/field';
import { UploadingFile } from '../../types/file';

interface FileRecord {
  id: string;
  file: File;
}

export const getFileUploadStatus = (status: DatasetStatus | FieldStatus) =>
  status === 'INVALID' ? FileStatus.failed : FileStatus.verified;

export const getUploadStep = (files: UploadingFile[] = []) => {
  const uploadFailed = files.some(({ status }) => status === FileStatus.failed);

  if (uploadFailed) {
    return UploadStep.failed;
  }

  const filesVerified = files.every(
    ({ status }) => status === FileStatus.verified,
  );

  return filesVerified ? UploadStep.complete : UploadStep.uploading;
};

export const zipFieldBoundaries = async (fileRecords: FileRecord[] = []) => {
  const batches = fileRecords.reduce<Record<string, FileRecord[]>>(
    (acc, fileRecord) => {
      const name = fileRecord.file.name.substring(
        0,
        fileRecord.file.name.lastIndexOf('.'),
      );

      if (acc[name]) {
        acc[name].push(fileRecord);
      } else {
        acc[name] = [fileRecord];
      }

      return acc;
    },
    {},
  );

  const JSZip = (await import('jszip')).default;
  const zippedFiles = Object.keys(batches).map((batchName) => {
    const zip = new JSZip();
    const fileIds: string[] = [];

    batches[batchName].forEach((fileRecord) => {
      fileIds.push(fileRecord.id);
      zip.file(fileRecord.file.name, fileRecord.file);
    });

    return {
      name: batchName,
      fileIds,
      zipFilePromise: zip.generateAsync({
        type: 'arraybuffer',
        compression: 'DEFLATE',
      }),
    };
  });

  const arrayBuffers = await Promise.all(
    zippedFiles.map(({ zipFilePromise }) => zipFilePromise),
  );

  return zippedFiles.map((zippedFile, index) => ({
    name: zippedFile.name,
    fileIds: zippedFile.fileIds,
    arraybuffer: arrayBuffers[index],
  }));
};
