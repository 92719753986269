import i18n from 'i18next';

import { isShared, isOwned } from '../../../../../helpers/data';
import { ACTIONS } from '../constants/ui';

export const getOwnFarmsTableRows = (farms, selectedFarmsUuids) => {
  const selectedUserFarmsUuidsSet = new Set(selectedFarmsUuids);

  return farms.reduce((acc, farm) => {
    if (isOwned(farm.farmOwnership)) {
      const actions = [
        {
          label: i18n.t('general.controls.share'),
          value: ACTIONS.share,
        },
      ];

      if (farm.usersEmails.length !== 0) {
        actions.push({
          label: i18n.t('general.controls.stop-sharing'),
          value: ACTIONS.stopSharing,
        });
      }

      acc.push({
        id: farm.uuid,
        rowType: 'default',
        name: farm.name,
        users: farm.usersEmails.join(', '),
        actions,
        selected: selectedUserFarmsUuidsSet.has(farm.uuid),
      });
    }

    return acc;
  }, []);
};

export const getUserSharedTableRows = (farms, userEmail) =>
  farms.reduce((acc, farm) => {
    if (isShared(farm.farmOwnership)) {
      const usersEmails = farm.usersEmails.filter(
        (email) => email && email !== userEmail,
      );
      const actions = [
        {
          label: i18n.t('general.controls.share'),
          value: ACTIONS.share,
        },
      ];

      if (usersEmails.length !== 0) {
        actions.push({
          label: i18n.t('general.controls.stop-sharing'),
          value: ACTIONS.stopSharing,
        });
      }

      acc.push({
        id: farm.uuid,
        rowType: 'default',
        name: farm.name,
        owner: farm.ownerEmail,
        users: usersEmails.join(', '),
        actions,
      });
    }

    return acc;
  }, []);
