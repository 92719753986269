import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DatasetList from '../../../../../../components/Lists/DatasetList';
import { formatAcquisitionDate } from '../../../../../../helpers/satellite';
import { setShapeData } from '../../../createThreeDMapSlice';
import { selectCreateAnalysisAssets } from '../../../../../field/fieldSelectors';
import {
  selectAssetType,
  selectShape,
} from '../../../createThreeDMapSelectors';
import TopographyMapList from '../../../../../../components/Lists/TopographyMapList';
import { AssetType } from '../../../../../../helpers/constants/entities/asset';
import { addAttributesAndSortDatasets } from '../../../../../../helpers/functions/entities/dataset';
import { addAttributesAndSortTopographyMaps } from '../../../../../../helpers/functions/entities/topographyMap';

import './index.scss';

const SATELLITE_ATTRIBUTES = ['red', 'green', 'blue', 'nir'];

const ShapeDataSourcePanel = () => {
  const dispatch = useDispatch();
  const assetType = useSelector(selectAssetType);
  const { uuid: selectedUuid, attribute: selectedAttribute } =
    useSelector(selectShape);
  const { satelliteImages, soilDatasets, yieldDatasets, topographyMaps } =
    useSelector(selectCreateAnalysisAssets);

  const handleAttributeChange = ({ datasetUuid, attribute }) => {
    dispatch(
      setShapeData({
        uuid: datasetUuid,
        attribute,
      }),
    );
  };

  let Component = DatasetList;
  let datasets = [];

  if (assetType === AssetType.satelliteImage) {
    // TODO: create separate component for satellite images, maybe SatellitePanel component
    datasets = satelliteImages.map((image) => ({
      uuid: image.uuid,
      name: formatAcquisitionDate(image.satelliteImage.acquisitionDate),
      attributes: SATELLITE_ATTRIBUTES,
    }));
  } else if (assetType === AssetType.soilDataset) {
    datasets = addAttributesAndSortDatasets(soilDatasets);
  } else if (assetType === AssetType.yieldDataset) {
    datasets = addAttributesAndSortDatasets(yieldDatasets);
  } else if (assetType === AssetType.topographyMap) {
    Component = TopographyMapList;
    datasets = addAttributesAndSortTopographyMaps(topographyMaps);
  }

  const defaultExpanded = datasets.length < 10;

  return (
    <div className="create-3d-shape-datasource-panel">
      <Component
        defaultExpanded={defaultExpanded}
        datasets={datasets}
        selectedDatasetUuid={selectedUuid}
        selectedAttribute={selectedAttribute}
        onSelectedAttributeChange={handleAttributeChange}
      />
    </div>
  );
};

export default ShapeDataSourcePanel;
