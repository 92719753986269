import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../app/store/helpers/functions';
import {
  selectCurrentEquation,
  selectEquationTemplateUuid,
  selectIsEquationTemplateSelected,
  selectVerifyEquation,
} from '../../../../equations/equationsSelectors';
import EquationPreview from '../../components/EquationPreview';
import EquationMapSettings from '../../components/EquationMapSettings';
import { ProductUnit } from '../../../../../helpers/constants/units/productUnit';
import {
  selectEditMode,
  selectFullScreenEditor,
  selectGridSize,
  selectType,
} from '../../createBatchEquationBasedAnalysisSelectors';
import {
  toggleFullScreenEditor,
  setGridXSize,
  setGridYSize,
  setType,
  setEditMode,
} from '../../createBatchEquationBasedAnalysisSlice';
import type { VectorAnalysisMapType } from '../../../../../helpers/constants/entities/vectorAnalysisMap';
import {
  resetVerifyEquation,
  updateCurrentEquation,
  updateSavedEquation,
  verifyEquation,
} from '../../../../equations/equationsSlice';
import EquationPlaceholder from '../../components/EquationPlaceholder';
import ScrollContainer from '../../../../../components/ScrollContainer';
import FormulaEditor from '../../components/FormulaEditor';
import Button from '../../../../../components/Button';
import {
  filterEmptyVariables,
  isNewEquation,
} from '../../../../equations/helpers/functions/equations';
import VariablesEditor from '../../components/VariablesEditor';
import getDebouncer from '../../../../../helpers/functions/utils/getDebouncer';
import EquationTitle from '../../components/EquationTitle';
import { openPopup } from '../../../popups/popupsSlice';
import POPUPS from '../../../popups/helpers/constants/popups';
import { EMPTY_EQUATION } from '../../../../equations/helpers/constants/equations';
import { captureException } from '../../../../../helpers/functions/utils/errorHandling';

import './index.scss';

const TIMEOUT = 500; // ms
const verificationDebouncer = getDebouncer(TIMEOUT);

export default function EquationPanel() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const equation = useAppSelector(selectCurrentEquation);
  const {
    errorMessage: verifyEquationErrorMessage,
    inProgress: verifyEquationLoading,
  } = useAppSelector(selectVerifyEquation);
  const equationTemplateSelected = useAppSelector(
    selectIsEquationTemplateSelected,
  );
  const equationTemplateUuid = useAppSelector(selectEquationTemplateUuid);
  const type = useAppSelector(selectType);
  const gridSize = useAppSelector(selectGridSize);
  const fullScreenEditor = useAppSelector(selectFullScreenEditor);
  const editMode = useAppSelector(selectEditMode);

  const [equationUpdate, setEquationUpdate] = useState(equation);

  const saveDisabled =
    !equationUpdate.equationAsText ||
    verifyEquationLoading ||
    !!verifyEquationErrorMessage;

  useEffect(() => {
    // Enable editMode if a new equation is creating, otherwise disable it
    dispatch(setEditMode(isNewEquation(equationTemplateUuid)));

    // Reset equation update when another equation is selected
    setEquationUpdate({
      ...EMPTY_EQUATION,
      productUnit: EMPTY_EQUATION.productUnit || '',
    });
  }, [dispatch, equationTemplateUuid]);

  const orderEquationVerification = (params: {
    equationAsText?: string;
    dataVariables?: string[];
    equationResultVariable?: string;
    useNumpy?: boolean;
  }) => {
    const defaultParams = {
      equationAsText: equationUpdate.equationAsText,
      dataVariables: equationUpdate.dataVariables,
      equationResultVariable: equationUpdate.equationResultVariable,
      useNumpy: equationUpdate.useNumpy,
    };

    verificationDebouncer(() => {
      dispatch(
        verifyEquation({
          ...defaultParams,
          ...params,
        }),
      ).catch((error) => {
        captureException(error);
      });
    });
  };

  const handleProductUnitChange = (productUnit: ProductUnit | '' | null) => {
    if (productUnit == null) {
      return;
    }

    dispatch(
      updateCurrentEquation({
        productUnit,
      }),
    );
    setEquationUpdate({
      ...equationUpdate,
      productUnit,
    });
  };

  const handlePurposeChange = (purpose: VectorAnalysisMapType | null) => {
    if (purpose == null) {
      return;
    }

    dispatch(setType(purpose));
  };

  const handleGridXChange = (size: number) => {
    dispatch(setGridXSize(size));
  };

  const handleGridYChange = (size: number) => {
    dispatch(setGridYSize(size));
  };

  const handleAdjustEquationClick = () => {
    dispatch(setEditMode(true));
    setEquationUpdate(equation);
  };

  const handleUseNumpyChange = () => {
    setEquationUpdate({
      ...equationUpdate,
      useNumpy: !equationUpdate.useNumpy,
    });
    orderEquationVerification({
      useNumpy: !equationUpdate.useNumpy,
    });
  };

  const handleFullScreenModeChange = () => {
    dispatch(toggleFullScreenEditor());
  };

  const handleEquationChange = (equationAsText: string) => {
    setEquationUpdate({
      ...equationUpdate,
      equationAsText,
    });
    orderEquationVerification({
      equationAsText,
    });
  };

  const handleTestRunClick = () => {
    dispatch(
      openPopup({
        type: POPUPS.equationTestRun,
        equationAsText: equationUpdate.equationAsText,
        equationResultVariable: equationUpdate.equationResultVariable,
        dataVariables: filterEmptyVariables(equationUpdate.dataVariables),
        useNumpy: equationUpdate.useNumpy,
      }),
    );
  };

  const handleDataVariablesUpdate = (variables: string[]) => {
    setEquationUpdate({
      ...equationUpdate,
      dataVariables: variables,
    });
    orderEquationVerification({
      dataVariables: variables,
    });
  };

  const handleOutputVariableChange = (value: string) => {
    setEquationUpdate({
      ...equationUpdate,
      equationResultVariable: value,
    });
    orderEquationVerification({
      equationResultVariable: value,
    });
  };

  const handleSaveEquationClick = () => {
    const equationData = {
      ...equationUpdate,
      dataVariables: equationUpdate.dataVariables
        ? filterEmptyVariables(equationUpdate.dataVariables)
        : equationUpdate.dataVariables,
    };
    dispatch(
      openPopup({
        type: POPUPS.createEquation,
        equation: equationData,
        onConfirm: (update: {
          uuid: string;
          title: string;
          description: string;
          sourceUrl: string;
        }) => {
          dispatch(setEditMode(false));
          dispatch(
            updateSavedEquation({
              ...equationData,
              ...update,
            }),
          );
        },
      }),
    );
  };

  const handleCancelClick = () => {
    dispatch(setEditMode(false));
    dispatch(resetVerifyEquation());
  };

  const handleSaveClick = () => {
    dispatch(setEditMode(false));
    dispatch(updateCurrentEquation(equationUpdate));
  };

  return (
    <div className="equation-panel">
      {equationTemplateSelected ? (
        <>
          <EquationTitle
            uuid={equationTemplateUuid || ''}
            equationTitle={equation.title}
            editMode={editMode}
          />
          <ScrollContainer
            classes={{ root: 'equation-panel__scroll-container' }}
          >
            <EquationMapSettings
              productUnit={equation.productUnit}
              purpose={type}
              gridX={gridSize.x}
              gridY={gridSize.y}
              onProductUnitChange={handleProductUnitChange}
              onPurposeChange={handlePurposeChange}
              onGridXChange={handleGridXChange}
              onGridYChange={handleGridYChange}
            />
            {editMode ? (
              <>
                <FormulaEditor
                  uuid={equationTemplateUuid || ''}
                  equationAsText={equationUpdate.equationAsText}
                  useNumpy={equationUpdate.useNumpy}
                  fullScreenMode={fullScreenEditor}
                  errorMessage={verifyEquationErrorMessage}
                  errorsLoading={verifyEquationLoading}
                  onUseNumpyChange={handleUseNumpyChange}
                  onFullScreenModeChange={handleFullScreenModeChange}
                  onEquationChange={handleEquationChange}
                  onTestRunClick={handleTestRunClick}
                />
                <VariablesEditor
                  dataVariables={equationUpdate.dataVariables}
                  resultVariable={equationUpdate.equationResultVariable}
                  onDataVariablesUpdate={handleDataVariablesUpdate}
                  onOutputVariableChange={handleOutputVariableChange}
                />
              </>
            ) : (
              <EquationPreview
                equationAsText={equation.equationAsText}
                dataVariables={equation.dataVariables}
                equationResultVariable={equation.equationResultVariable}
                onAdjustEquationClick={handleAdjustEquationClick}
              />
            )}
          </ScrollContainer>
          {editMode && (
            <div className="equation-panel__actions">
              <Button
                className="equation-panel__action_left"
                variant="outlined"
                color="primary"
                disabled={saveDisabled}
                onClick={handleSaveEquationClick}
              >
                {t('general.popups.create-equation.title')}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleCancelClick}
              >
                {t('general.controls.cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={saveDisabled}
                onClick={handleSaveClick}
              >
                {t('general.controls.save')}
              </Button>
            </div>
          )}
        </>
      ) : (
        <EquationPlaceholder />
      )}
    </div>
  );
}
