import React, { Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import BackdropLoading from '../../../../../../../components/Loading/BackdropLoading';
import Loading from '../../../../../../../components/Loading';
import Stepper from '../../../../../../../components/Stepper';
import ToolsPanel from '../../../../components/ToolsPanel';
import Panel from '../../../Panel/Results';
import { goToPrevStep } from '../../../../../zonesOps/zonesOpsSlice';
import {
  setName,
  setType,
  setGridXSize,
  setGridYSize,
} from '../../../../createEquationBasedAnalysisSlice';
import {
  selectSaveInProgress,
  selectGeneratedEquationMap,
} from '../../../../../../createAnalysis/createAnalysisSelectors';
import {
  selectName,
  selectType,
  selectGridXSize,
  selectGridYSize,
} from '../../../../createEquationBasedAnalysisSelectors';
import { updateEquationMap } from '../../../../../../createAnalysis/createAnalysisSlice';
import useGenerateEquationMap from '../../../../hooks/useGenerateEquationMap';

const Map = React.lazy(() => import('../../../Map'));

const StepperResults = ({ steps, stepIndex, step }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const type = useSelector(selectType);
  const name = useSelector(selectName);
  const generatedEquationMap = useSelector(selectGeneratedEquationMap);
  const saveInProgress = useSelector(selectSaveInProgress);
  const gridXSize = useSelector(selectGridXSize);
  const gridYSize = useSelector(selectGridYSize);
  const generateEquationMap = useGenerateEquationMap();

  const onClickBack = () => {
    dispatch(goToPrevStep());
  };

  const handlePropChange = (prop, value) => {
    switch (prop) {
      case 'type':
        dispatch(setType(value));
        break;
      case 'name':
        dispatch(setName(value));
        break;
      case 'gridX':
        dispatch(setGridXSize(value));
        break;
      case 'gridY':
        dispatch(setGridYSize(value));
        break;
      default:
        break;
    }
  };

  const handleRunAnalysis = () => {
    generateEquationMap();
  };

  const onClickNext = () => {
    dispatch(
      updateEquationMap({
        name,
        type,
      }),
    );
  };

  return (
    <>
      <Stepper
        activeStep={stepIndex}
        steps={steps}
        nextLabel={t('zones-ops.common.finish')}
        nextDisabled={!generatedEquationMap}
        onClickNext={onClickNext}
        onClickBack={onClickBack}
      >
        <ToolsPanel
          key="tools-panel"
          name={name}
          purpose={type}
          gridXSize={gridXSize}
          gridYSize={gridYSize}
          onPropChange={handlePropChange}
          onRunAnalysis={handleRunAnalysis}
        />
        <Panel key="panel" step={step} />
        <Suspense key="map" fallback={<Loading />}>
          <Map step={step} />
        </Suspense>
      </Stepper>
      {saveInProgress && <BackdropLoading />}
    </>
  );
};

export default StepperResults;
