import React from 'react';

import { getEquationMapTableData } from '../../../../helpers/functions/entities/equationMap';
import LegendTable from '../Table';

import './index.scss';

const EquationMapLegendTable = ({ geoMap }) => {
  if (!geoMap) {
    return null;
  }

  const { headers, rows } = getEquationMapTableData({
    geoMap,
  });

  return (
    <div className="equation-map-legend-table">
      <LegendTable columns={headers} rows={rows} />
    </div>
  );
};

export default EquationMapLegendTable;
