export const selectSelectionMode = ({ userFarms }) => userFarms.selectionMode;

export const selectSelectedFarms = ({ userFarms }) => userFarms.selectedFarms;

export const selectActiveTab = ({ userFarms }) => userFarms.activeTab;

export const selectOwnFarmsQuery = ({ userFarms }) => userFarms.ownFarmsQuery;

export const selectSharedFarmsQuery = ({ userFarms }) =>
  userFarms.sharedFarmsQuery;
