import FieldPipelineStatus from './fieldPipelineStatus';

export const PINS_GROUPS_EXCEPTION_TO_STATUS_MAP = {
  FieldInvalidException: FieldPipelineStatus.fieldInvalid,
  SatimageNotFoundException: FieldPipelineStatus.satImageNotFound,
  AlreadyExistException: FieldPipelineStatus.pinsGroupsExist,
  VectorAnalysisMapNotFoundException: FieldPipelineStatus.vamapsOrdering,
  EmptyAnalysisMapExecutionException: FieldPipelineStatus.noIntersections,
} as const;

export default { PINS_GROUPS_EXCEPTION_TO_STATUS_MAP };
