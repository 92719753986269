import React, { useEffect, useMemo } from 'react';

import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../app/store/helpers/functions';
import DataLayersPreviewsComponent from '../../../zonesOps/components/DataLayersPreviews';
import {
  selectActiveDataLayer,
  selectActiveDataLayerIndex,
  selectActiveImageUuid,
  selectDataLayers,
} from '../../createMultiLayerAnalysisSelectors';
import { selectCreateAnalysisAssets } from '../../../../field/fieldSelectors';
import { AssetType } from '../../../../../helpers/constants/entities/asset';
import { selectApiKey } from '../../../../user/userSelectors';
import { AssetsLayer } from '../../../zonesOps/types/previews';
import { fetchSatelliteImagesGeoMaps } from '../../../../field/fieldSlice';
import { GeoMapTypeOption } from '../../../../satelliteImages/helpers/constants/geoMapType';
import {
  activateImage,
  changeActiveDataLayer,
  updateDataLayer,
} from '../../createMultiLayerAnalysisSlice';
import useDataLayersPreviewExpanded from '../../../zonesOps/hooks/useDataLayersPreviewExpanded';

export default function DataLayersPreviews() {
  const dispatch = useAppDispatch();

  const apiKey = useAppSelector(selectApiKey);
  const dataLayers: {
    type: AssetType;
    satelliteImageUuids: string[];
    index: string;
    datasetUuid: string;
    attribute: string;
  }[] = useAppSelector(selectDataLayers);
  const {
    satelliteImages,
    soilDatasets,
    yieldDatasets,
    asAppliedDatasets,
    topographyMaps,
  } = useAppSelector(selectCreateAnalysisAssets);
  const activeDataLayerIndex = useAppSelector(selectActiveDataLayerIndex);
  const activeDataLayer = useAppSelector(selectActiveDataLayer);
  const activeImageUuid = useAppSelector(selectActiveImageUuid);

  const { previewExpanded, handlePreviewExpandedChange } =
    useDataLayersPreviewExpanded();

  useEffect(() => {
    const satelliteImagesUuids = dataLayers.reduce((acc, layer) => {
      if (layer.type === AssetType.satelliteImage) {
        return new Set([...acc, ...layer.satelliteImageUuids]);
      }

      return acc;
    }, new Set<string>());

    void dispatch(
      fetchSatelliteImagesGeoMaps({
        uuids: [...satelliteImagesUuids],
        geoMapTypes: [GeoMapTypeOption.contrast, GeoMapTypeOption.crop],
      }),
    );
  }, [dispatch, dataLayers]);

  const selectedUuid = useMemo(() => {
    if (activeDataLayer?.type === AssetType.satelliteImage) {
      return activeImageUuid;
    }

    return activeDataLayer?.datasetUuid;
  }, [activeDataLayer?.type, activeDataLayer?.datasetUuid, activeImageUuid]);

  const handleItemSelect = (uuid: string, layerIndex: number) => {
    const layer = dataLayers[layerIndex];
    dispatch(changeActiveDataLayer(layerIndex));

    if (layer.type === AssetType.satelliteImage) {
      dispatch(activateImage(uuid));
    } else {
      dispatch(
        updateDataLayer({
          dataLayerIndex: layerIndex,
          updates: {
            datasetUuid: uuid,
          },
        }),
      );
    }
  };

  const handleItemRemove = (uuidToRemove: string, layerIndex: number) => {
    const layer = dataLayers[layerIndex];
    if (layer.type === AssetType.satelliteImage) {
      const updatedUuids = layer.satelliteImageUuids.filter(
        (uuid) => uuid !== uuidToRemove,
      );

      dispatch(
        updateDataLayer({
          dataLayerIndex: layerIndex,
          updates: {
            satelliteImageUuids: updatedUuids,
          },
        }),
      );
    } else {
      dispatch(
        updateDataLayer({
          dataLayerIndex: layerIndex,
          updates: {
            datasetUuid: '',
          },
        }),
      );
    }
  };

  const previewsLayers: AssetsLayer[] = dataLayers.map((layer) => {
    switch (layer.type) {
      case AssetType.satelliteImage:
        return {
          assetType: AssetType.satelliteImage,
          assets: satelliteImages.filter((image) =>
            layer.satelliteImageUuids.includes(image.uuid),
          ),
          index: layer.index,
        };
      case AssetType.soilDataset:
        return {
          assetType: AssetType.soilDataset,
          asset: soilDatasets.find(
            (dataset) => layer.datasetUuid === dataset.uuid,
          ),
          attribute: layer.attribute,
        };
      case AssetType.yieldDataset:
        return {
          assetType: AssetType.yieldDataset,
          asset: yieldDatasets.find(
            (dataset) => layer.datasetUuid === dataset.uuid,
          ),
          attribute: layer.attribute,
        };
      case AssetType.asAppliedDataset:
        return {
          assetType: AssetType.asAppliedDataset,
          asset: asAppliedDatasets.find(
            (dataset) => layer.datasetUuid === dataset.uuid,
          ),
          attribute: layer.attribute,
        };
      case AssetType.topographyMap:
        return {
          assetType: AssetType.topographyMap,
          asset: topographyMaps.find((map) => layer.datasetUuid === map.uuid),
          attribute: layer.attribute,
        };
      default:
        throw new Error(
          `[DataLayersPreviews] Unknown layer type: ${layer.type}`,
        );
    }
  });

  return (
    <DataLayersPreviewsComponent
      apiKey={apiKey}
      expanded={previewExpanded}
      layers={previewsLayers}
      selectedUuid={selectedUuid}
      selectedLayerIndex={activeDataLayerIndex}
      onItemSelect={handleItemSelect}
      onItemRemove={handleItemRemove}
      onExpandedChange={handlePreviewExpandedChange}
    />
  );
}
