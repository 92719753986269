import { API, graphqlOperation } from '@aws-amplify/api';

import shareFarmsMutation from '../../farms/graphql/mutations/shareFarms.gql';
import addUsersToOrganizationMutation from './graphql/mutations/addUsersToOrganization.gql';
import deleteUsersFromOrganizationMutation from './graphql/mutations/deleteUsersFromOrganization.gql';

export const shareFarms = async ({ userUuid, organizationUuid, isAllFarms }) =>
  API.graphql(
    graphqlOperation(shareFarmsMutation, {
      input: {
        userUuids: [userUuid],
        organizationUuid,
        allFarms: isAllFarms,
      },
    }),
  ).then(({ data }) => data.shareFarms);

export const addUsersToOrganization = async ({ emails, organizationUuid }) =>
  API.graphql(
    graphqlOperation(addUsersToOrganizationMutation, {
      input: {
        emails,
        organizationUuid,
      },
    }),
  ).then(({ data }) => data.addUsersToOrganization);

export const deleteUsersFromOrganization = async ({
  userUuids,
  organizationUuid,
}) =>
  API.graphql(
    graphqlOperation(deleteUsersFromOrganizationMutation, {
      input: {
        userUuids,
        organizationUuid,
      },
    }),
  ).then(({ data }) => data.deleteUsersFromOrganization);
