import { useSelector } from 'react-redux';

import {
  selectIsDisconnectedBySystem,
  selectIsJdHealth,
  selectProfileLoaded,
  selectProfileUpdateAcceptUrl,
} from '../../../jdProfile/jdProfileSelectors';
import type { NotificationType } from '../types/notification';

interface DrawerNotification {
  type: NotificationType;
  subMenus: string[];
}

interface DrawerNotifications {
  user?: DrawerNotification;
}

export default function useDrawerNotifications(): DrawerNotifications {
  const jdHealth = useSelector(selectIsJdHealth);
  const jdDisconnectedBySystem = useSelector(selectIsDisconnectedBySystem);
  const jdProfileLoaded = useSelector(selectProfileLoaded);
  const jdUpdateAcceptUrl = useSelector(selectProfileUpdateAcceptUrl);

  let userNotification;

  if (
    jdProfileLoaded &&
    (!jdHealth || jdDisconnectedBySystem || jdUpdateAcceptUrl)
  ) {
    userNotification = {
      type: 'error' as const,
      subMenus: ['integrations'],
    };
  }

  return {
    user: userNotification,
  };
}
