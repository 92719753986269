import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import SourceAssetsList from '../SourceAssetsList';
import { getAssetTypeLabel } from '../../../../helpers/functions/map/legend';

import './index.scss';

// TODO: inline into place of use
const DataLayersLegendList = ({ layers = [], apiKey }) => {
  const { t } = useTranslation();

  return (
    <div className="data-layers-legend-list">
      <Typography
        variant="subtitle2"
        classes={{
          root: 'data-layers-legend-list__title',
        }}
      >
        {t('general.controls.map-legend.data-layers.title')}
      </Typography>
      <SourceAssetsList
        assets={layers}
        apiKey={apiKey}
        assetHeaderRenderer={(anAsset) => (
          <>
            <Typography
              classes={{
                root: 'layer__title',
              }}
            >
              {getAssetTypeLabel(anAsset.type)}
            </Typography>
            <Typography
              classes={{
                root: 'layer__weight',
              }}
            >
              {t('general.controls.map-legend.data-layers.weight')}
              <span className="layer__factor">{anAsset.factor}</span>
            </Typography>
          </>
        )}
      />
    </div>
  );
};

export default DataLayersLegendList;
