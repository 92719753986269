query getPlatformContext($input: PlatformContextEventFilter) {
  getPlatformContext(input: $input) {
    events {
      operationId
      executionDate
      fieldUuid
      userUuid
      subFieldType
      subFieldUuid
      status
      messageKey
    }
    queryId
  }
}
