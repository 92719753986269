query getUserActionsData($filter: UserActionFilter!, $areaUnit: AreaUnit) {
  getUserData {
    actionsData(filter: $filter) {
      totalCount
      actions {
        operationDate
        operation
        description
        unitsAmount
        currency
        userUuid
        area(unit: $areaUnit)
        farmUuid
        fieldUuid
        organizationUuid
      }
    }
  }
}
