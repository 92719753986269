import { createSelector } from '@reduxjs/toolkit';

export const selectZonesMaps = ({ zonesMaps }) => zonesMaps.zonesMaps;

export const selectSelectedZonesMaps = createSelector(
  selectZonesMaps,
  (zonesMaps) => zonesMaps.filter(({ selected }) => selected),
);

export const selectSelectionMode = ({ zonesMaps }) => zonesMaps.selectionMode;

export const selectIsLoaded = ({ zonesMaps }) => zonesMaps.isLoaded;

export const selectLastEvaluatedKey = ({ zonesMaps }) =>
  zonesMaps.lastEvaluatedKey;

export const selectFilter = ({ zonesMaps }) => zonesMaps.filter;

export const selectLabelsExpanded = ({ zonesMaps }) => zonesMaps.labelsExpanded;
