import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectApiKey } from '../../../user/userSelectors';
import getZonesMapsData from '../helpers/functions/getZonesMapsData';
import { useGetHomepageDataQuery } from '../homepageAPI';
import type { ZonesMapData } from '../types/ui';

export default function useHomepageData(): {
  isFetching: boolean;
  zonesMaps: ZonesMapData[];
} {
  const apiKey = useSelector(selectApiKey);
  const { data, isFetching } = useGetHomepageDataQuery();
  const zonesMaps = useMemo(
    () => getZonesMapsData(apiKey, data),
    [apiKey, data],
  );

  return {
    isFetching,
    zonesMaps,
  };
}
