import React, { useCallback, useMemo, useRef } from 'react';
import { VirtuosoHandle } from 'react-virtuoso';

import { flattenTree } from '../helpers/functions/tree';
import { TreeNode } from '../types/node';
import TreeView from '../components/TreeView';

export default function useTreeView({
  nodes,
  offset,
  expanded,
}: {
  nodes: TreeNode[];
  offset: number;
  expanded: Record<string, boolean>;
}) {
  const ref = useRef<VirtuosoHandle>(null);
  const flattenedTree = useMemo(
    () => flattenTree(nodes, expanded),
    [nodes, expanded],
  );

  const getNodeIndexById = useCallback(
    (id: string) => flattenedTree.findIndex((node) => node.id === id),
    [flattenedTree],
  );

  return {
    treeView: <TreeView nodes={flattenedTree} offset={offset} ref={ref} />,
    ref,
    getNodeIndexById,
  };
}
