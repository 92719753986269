import { useState, useEffect } from 'react';

import store from '../app/store/stores/keyValueBrowserStore';

export default function useKeyValueBrowserStore(storeName, key, condition) {
  const [value, setValue] = useState(null);

  useEffect(() => {
    const getter = async () => {
      if (!condition) {
        setValue(null);

        return;
      }

      const result = await store.getItem(storeName, key);

      setValue(result);
    };

    void getter();
  }, [storeName, key, condition]);

  return value;
}
