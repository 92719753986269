mutation generateAsAppliedVectorAnalysisMap(
  $input: GenerateAsAppliedVectorAnalysisMapInput!
) {
  generateAsAppliedVectorAnalysisMapAsync(input: $input) {
    uuid
    fieldUuid
    statusCode
    statusMessage
  }
}
