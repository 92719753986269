import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';

import type { TableColumn } from '../../types/column';
import type { DefaultTableRow, TableRow as ITableRow } from '../../types/row';
import TableHeadContent from '../TableHeadContent';
import TableRowContent from '../TableRowContent';
import { isDefaultTableRow } from '../../helpers/functions/rows';

import './index.scss';

const DEFAULT_PAGE_SIZES = [10, 25, 50, 100];

interface TableProps<R, C> {
  showPagination: false;
  editMode: boolean;
  classes?: {
    root?: string;
    tablePaper?: string;
    row?: string;
  };
  columns: TableColumn<C>[];
  rows: R[];
  selectedRowId?: string;
  onRowClick?: (r: R) => void;
}

interface TablePaginationProps<R, C>
  extends Omit<TableProps<R, C>, 'showPagination'> {
  showPagination: true;
  pageSize: number;
  page: number;
  totalCount: number;
  hasNext: boolean;
  getDisplayedRowsLabel: () => void;
  onPageChange: (page: number) => void;
  onPageSizeChange: (size: number) => void;
}

type Props<R, C> = TablePaginationProps<R, C> | TableProps<R, C>;

export default function EnhancedTable<
  R extends ITableRow,
  C extends DefaultTableRow,
>({
  classes = {},
  columns = [],
  rows = [],
  selectedRowId = '',
  onRowClick,
  ...props
}: Props<R, C>) {
  const { t } = useTranslation();

  let slicedRows = rows;

  if (props.showPagination) {
    const { page, pageSize } = props;
    slicedRows = rows.slice(page * pageSize, page * pageSize + pageSize);
  }

  const handleRowClick = (row: R) => {
    if (!row.disabled) {
      onRowClick?.(row);
    }
  };

  return (
    <div className={clsx('table-component', classes?.root)}>
      <Paper className={clsx('table-component__paper', classes?.tablePaper)}>
        <TableContainer className="table-component__container">
          <Table stickyHeader className="table" size="medium">
            <TableHead>
              <TableHeadContent columns={columns} />
            </TableHead>
            <TableBody>
              {slicedRows.map((row, index) => {
                const rowSelected =
                  isDefaultTableRow(row) && row.id === selectedRowId;
                const rowClickable = !!onRowClick && !row.disabled;

                return (
                  <TableRow
                    key={index}
                    className={clsx('table-component__row', {
                      'table-component__row_disabled':
                        isDefaultTableRow(row) && row.disabled,
                      'table-component__row_selected': rowSelected,
                      'table-component__row_clickable': rowClickable,
                    })}
                    onClick={() => handleRowClick(row)}
                  >
                    <TableRowContent
                      columns={columns}
                      row={row}
                      isSelected={rowSelected}
                    />
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {slicedRows.length === 0 && (
            <p className="table-component__no-data">
              {t('general.controls.no-results')}
            </p>
          )}
        </TableContainer>
        {props.showPagination && (
          <TablePagination
            classes={{
              root: 'table-component__pagination',
              toolbar: 'table-component__pagination-toolbar',
              spacer: 'table-component__pagination-spacer',
              selectRoot: 'table-component__pagination-select',
            }}
            labelRowsPerPage={t('general.controls.pagination.rows-per-page')}
            nextIconButtonText={t('general.controls.pagination.next-page')}
            nextIconButtonProps={
              props.hasNext === null ? undefined : { disabled: !props.hasNext }
            }
            backIconButtonText={t('general.controls.pagination.prev-page')}
            labelDisplayedRows={props.getDisplayedRowsLabel}
            rowsPerPageOptions={DEFAULT_PAGE_SIZES}
            component="div"
            count={props.totalCount}
            rowsPerPage={props.pageSize}
            page={props.page}
            onChangePage={(_e, newPage) => props.onPageChange(newPage)}
            onChangeRowsPerPage={(e) =>
              props.onPageSizeChange(parseInt(e.target.value, 10))
            }
          />
        )}
      </Paper>
    </div>
  );
}
