import React from 'react';

import { getZonesMapData } from '../../../../helpers/analysis';
import LegendTable from '../Table';

import './index.scss';

const LegendZonesMapTable = ({ areaUnit, attributes, colors }) => {
  if (!attributes?.features?.length) {
    return null;
  }

  const { headers, rows } = getZonesMapData({
    features: attributes.features,
    colors,
    areaUnit,
  });
  const [fixedCol, ...otherCols] = headers;

  return (
    <div className="zones-map-legend-table">
      <div className="fixed-container">
        <LegendTable columns={[fixedCol]} rows={rows} />
      </div>
      <div className="scroll-container">
        <LegendTable columns={otherCols} rows={rows} />
      </div>
    </div>
  );
};

export default LegendZonesMapTable;
