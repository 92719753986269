import React from 'react';
import Typography from '@material-ui/core/Typography';

import './index.scss';

type OperationLabelType = 'john-deere';

const OPERATION_LABEL_TEXT_MAPPER = {
  'john-deere': 'John Deere',
};

export default function OperationLabel({ type }: { type: OperationLabelType }) {
  return (
    <Typography
      variant="body2"
      className={`operation-label operation-label_${type}`}
    >
      {OPERATION_LABEL_TEXT_MAPPER[type]}
    </Typography>
  );
}
