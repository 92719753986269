const getDebouncer = <T = void>(timeout = 0) => {
  let id: NodeJS.Timeout | null = null;

  return (fn: () => T) => {
    if (id) {
      clearTimeout(id);
    }

    id = setTimeout(() => {
      fn();
      id = null;
    }, timeout);
  };
};

export default getDebouncer;
