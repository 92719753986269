import React from 'react';
import { useTranslation } from 'react-i18next';

import AuthenticationRedirector from '../router/redirectors/Authentication';
import UserDataRedirector from '../router/redirectors/UserData';
import PricingRedirector from '../router/redirectors/Pricing';
import SmartSamplingRedirector from '../router/redirectors/SmartSampling';
import Table from '../features/ui/smartSampling/containers/Table';
import ToolsPanel from '../features/ui/smartSampling/containers/ToolsPanel';
import BackdropLoading from '../components/Loading/BackdropLoading';
import { getDocumentTitle } from '../helpers';
import BaseTemplate from './BaseTemplate';
import { useGetFarmsQuery } from '../features/farms/farmsAPI';
import useDidMount from '../hooks/useDidMount';

const SmartSampling = () => {
  const { t } = useTranslation();

  const { isFetching: isFarmsFetching } = useGetFarmsQuery();

  useDidMount(() => {
    document.title = getDocumentTitle(t('general.navigation.smart-sampling'));
  });

  return (
    <AuthenticationRedirector>
      <UserDataRedirector>
        <SmartSamplingRedirector>
          <PricingRedirector>
            <BaseTemplate
              headerProps={{
                text: t('general.navigation.smart-sampling'),
              }}
            >
              <ToolsPanel key="tools-panel" />
              <Table key="panel" />
              {isFarmsFetching && <BackdropLoading key="loading" />}
            </BaseTemplate>
          </PricingRedirector>
        </SmartSamplingRedirector>
      </UserDataRedirector>
    </AuthenticationRedirector>
  );
};

export default SmartSampling;
