import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';

import EnhancedLink from '../../../../../../../components/Link';
import Button from '../../../../../../../components/Button';
import { AssetGroupType } from '../../../../../../../helpers/constants/entities/asset';
import {
  PAGES_ROOTS,
  getRootLink,
  getZonesOpsLink,
} from '../../../../../../../helpers/navigation';
import ZonesOpsWorkflow from '../../../../../zonesOps/helpers/constants/zonesOpsWorkflow';
import ButtonMenu from '../../../../../../../components/ButtonMenu';
import { getWorkflowOptions } from '../../../../../zonesOps/helpers/functions/workflowOptions';
import { ButtonMenuItem } from '../../../../../../../components/ButtonMenu/types';

import './index.scss';

type MapType<T> = {
  [Key in AssetGroupType]?: T;
};

export default function GroupPlaceholderEmptyItem({
  farmUuid,
  fieldUuid,
  assetGroupType,
  withAction,
  onActionClick,
}: {
  farmUuid: string;
  fieldUuid: string;
  assetGroupType: AssetGroupType;
  withAction: boolean;
  onActionClick: <T>(type: AssetGroupType, action?: T) => void;
}) {
  const { t } = useTranslation();

  const vectorAnalysisMapWorkflowOptions = useMemo(
    () =>
      getWorkflowOptions({
        farmUuid,
        fieldUuid,
        options: {
          omit: [ZonesOpsWorkflow.equationBased, ZonesOpsWorkflow.threeDMap],
          fromField: true,
        },
      }),
    [farmUuid, fieldUuid],
  );

  const DESCRIPTIONS: MapType<string> = {
    [AssetGroupType.satelliteImages]: t(
      'field.assets.placeholder.satellite-images-description',
    ),
    [AssetGroupType.vectorAnalysisMaps]: t(
      'field.assets.placeholder.zones-map-description-p1',
    ),
    [AssetGroupType.soilDatasets]: t(
      'field.assets.placeholder.soil-data-description',
    ),
    [AssetGroupType.yieldDatasets]: t(
      'field.assets.placeholder.yield-data-description',
    ),
    [AssetGroupType.asAppliedDatasets]: t(
      'field.assets.placeholder.as-applied-data-description',
    ),
    [AssetGroupType.topographyMaps]: t(
      'field.assets.placeholder.topography-description',
    ),
    [AssetGroupType.threeDimensionalMaps]: t(
      'field.assets.placeholder.3d-map-description-p1',
    ),
    [AssetGroupType.equationMaps]: t(
      'field.assets.placeholder.equation-map-description-p1',
    ),
    [AssetGroupType.pinsGroups]: t(
      'field.assets.placeholder.pins-groups-description',
    ),
  };

  const ACTION: MapType<string> = {
    [AssetGroupType.vectorAnalysisMaps]: t(
      'field.assets.placeholder.create-first-description',
    ),
    [AssetGroupType.threeDimensionalMaps]: t(
      'field.assets.placeholder.create-first-description',
    ),
    [AssetGroupType.equationMaps]: t(
      'field.assets.placeholder.create-first-description',
    ),
  };

  const LINKS: MapType<string> = {
    [AssetGroupType.soilDatasets]: getRootLink(PAGES_ROOTS.uploadSoilData),
    [AssetGroupType.yieldDatasets]: getRootLink(PAGES_ROOTS.uploadYieldData),
    [AssetGroupType.asAppliedDatasets]: getRootLink(
      PAGES_ROOTS.uploadAsApplied,
    ),
  };

  const BUTTON_LINKS: MapType<string> = {
    [AssetGroupType.threeDimensionalMaps]: getZonesOpsLink(
      farmUuid,
      fieldUuid,
      ZonesOpsWorkflow.threeDMap,
      true,
    ),
    [AssetGroupType.equationMaps]: getZonesOpsLink(
      farmUuid,
      fieldUuid,
      ZonesOpsWorkflow.equationBased,
      true,
    ),
  };

  const BUTTON_MENUS: MapType<ButtonMenuItem<Link>[]> = {
    [AssetGroupType.vectorAnalysisMaps]: vectorAnalysisMapWorkflowOptions,
  };

  const LABELS: MapType<string> = {
    [AssetGroupType.vectorAnalysisMaps]: t(
      'field.tools-panel.create-map-items.create-zones-map',
    ),
    [AssetGroupType.soilDatasets]: t('field.assets.placeholder.link-label'),
    [AssetGroupType.yieldDatasets]: t('field.assets.placeholder.link-label'),
    [AssetGroupType.asAppliedDatasets]: t(
      'field.assets.placeholder.link-label',
    ),
    [AssetGroupType.threeDimensionalMaps]: t(
      'field.tools-panel.create-map-items.create-3d',
    ),
    [AssetGroupType.equationMaps]: t(
      'field.assets.placeholder.create-equation-map',
    ),
  };

  return (
    <div className="group-placeholder-empty-item">
      <Typography
        classes={{
          root: 'group-placeholder-empty-item__description',
        }}
      >
        {DESCRIPTIONS[assetGroupType]}
        <br />
        {withAction && ACTION[assetGroupType]}
      </Typography>
      {withAction && LINKS[assetGroupType] && (
        <EnhancedLink
          className="group-placeholder-empty-item__link"
          to={LINKS[assetGroupType]}
          onClick={() => onActionClick(assetGroupType)}
        >
          {LABELS[assetGroupType]}
        </EnhancedLink>
      )}
      {withAction && BUTTON_LINKS[assetGroupType] && (
        <Button
          classes={{
            root: 'group-placeholder-empty-item__button-link',
          }}
          startIcon={<AddIcon />}
          component={Link}
          to={BUTTON_LINKS[assetGroupType] || ''}
          onClick={() => onActionClick(assetGroupType)}
        >
          {LABELS[assetGroupType]}
        </Button>
      )}
      {withAction && BUTTON_MENUS[assetGroupType] && (
        <ButtonMenu
          startIcon={<AddIcon />}
          items={BUTTON_MENUS[assetGroupType] || []}
          onItemClick={(item) => onActionClick(assetGroupType, item?.id)}
        >
          {LABELS[assetGroupType]}
        </ButtonMenu>
      )}
    </div>
  );
}
