query getCombinedUser {
  getUserData {
    uuid
    email
    identity
    totalArea
    language
    acceptedTermsAndConditions
    areaUnit
    maxArea
    reachedAreaLimit
    apiKey
    orders {
      plan
      currency
      billingPeriodInMonths
      paymentMethod
      orderDate
      uuid
      status
    }
    packages {
      orderUuid
      startDate
      endDate
    }
    organizations {
      uuid
      name
      surname
      givenName
      email
      phone
      users {
        userUuid
        email
        allFarms
      }
      ownerUuid
    }
    country
    companyType
  }

  getStripeCustomer {
    id
    phone
  }
}
