import { KeyboardEvent } from 'react';

import EventKey from '../../constants/eventKey';

export const isEnterKeyPressed = (event: KeyboardEvent): boolean =>
  event?.key === EventKey.Enter;

export const isEnterKeyPressedOnCurrentTarget = (
  event: KeyboardEvent,
): boolean =>
  isEnterKeyPressed(event) && event?.target === event?.currentTarget;
