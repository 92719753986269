import { useDispatch } from 'react-redux';

import { resetStepIndex as resetZonesOpsStepIndex } from '../zonesOpsSlice';
import { reset as resetCreateAnalysis } from '../../../createAnalysis/createAnalysisSlice';
import { resetOverlayLayers } from '../../overlayLayers/overlayLayersSlice';
import useResetZonesMapWorkflow from './useResetZonesMapWorkflow';
import { useAppSelector } from '../../../../app/store/helpers/functions';
import { selectWorkflow } from '../zonesOpsSelectors';
import { initializeWorkflow as initializeDrawManuallyWorkflow } from '../../drawManuallyWorkflow/drawManuallyWorkflowSlice';
import ZonesOpsWorkflow from '../helpers/constants/zonesOpsWorkflow';

export default function useCreateAnotherAnalysis() {
  const dispatch = useDispatch();
  const resetZonesMapWorkflow = useResetZonesMapWorkflow();
  const workflow = useAppSelector(selectWorkflow);

  return () => {
    dispatch(resetZonesOpsStepIndex());
    dispatch(resetCreateAnalysis());
    dispatch(resetOverlayLayers());
    resetZonesMapWorkflow();

    if (workflow === ZonesOpsWorkflow.drawManually) {
      dispatch(initializeDrawManuallyWorkflow());
    }
  };
}
