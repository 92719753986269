import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ErrorBoundary } from '@sentry/react';

import useErrorBoundaryFallback from '../features/ui/applicationShell/hooks/useErrorBoundaryFallback';
import Header from '../features/ui/applicationShell/containers/Header';
import { subscription as subscriptionUploadDataWorkflow } from '../features/ui/uploadData/uploadDataSlice';
import Drawer from '../features/ui/applicationShell/containers/Drawer';
import PopupsContainer from '../features/ui/popups/containers/PopupsContainer';
import { getComponent } from '../helpers/markup';
import { subscription as subscriptionField } from '../features/field/fieldSlice';
import { subscriptionPlanetMetadata } from '../features/planetMetadata/planetMetadataSlice';
import { subscription as subscriptionCreateAnalysis } from '../features/createAnalysis/createAnalysisSlice';
import { subscription as subscriptionAssets } from '../features/assets/assetsSlice';
import { subscription as subscriptionSmartSampling } from '../features/ui/smartSampling/smartSamplingSlice';
import useSubscription from '../features/subscription/hooks/useSubscription';
import { selectProcessingNotificationId } from '../features/notifications/notificationsSelectors';
import { closeProcessingNotification } from '../features/notifications/notificationsSlice';
import { useAppSelector } from '../app/store/helpers/functions';
import BackdropLoading from '../components/Loading/BackdropLoading';

import '../configs/variables.scss';
import './BaseTemplate.scss';

const BaseTemplate = ({ children, headerProps = {} }) => {
  const dispatch = useDispatch();
  const processingNotificationId = useAppSelector(
    selectProcessingNotificationId,
  );
  const loader = processingNotificationId ? (
    <BackdropLoading />
  ) : (
    getComponent('loading', children)
  );

  const { fallback, reportIssuePopup } = useErrorBoundaryFallback();

  const useSubscriptionCallback = useCallback(
    (parsedEvent) => {
      dispatch(subscriptionField(parsedEvent));
      dispatch(subscriptionUploadDataWorkflow(parsedEvent));
      dispatch(subscriptionPlanetMetadata(parsedEvent));
      dispatch(subscriptionCreateAnalysis(parsedEvent));
      dispatch(subscriptionAssets(parsedEvent));
      dispatch(subscriptionSmartSampling(parsedEvent));
    },
    [dispatch],
  );
  useSubscription(useSubscriptionCallback);

  useEffect(
    () => () => {
      dispatch(closeProcessingNotification());
    },
    [dispatch],
  );

  return (
    <>
      <Drawer />
      <div className="manager">
        <ErrorBoundary fallback={fallback}>
          <Header {...headerProps} />
          <div className="manager__overflow-container">
            {getComponent('legend', children)}
            {loader}
          </div>
          <div className="manager__top-panel">
            {getComponent('tools-panel', children)}
          </div>
          {getComponent('instrument-panel', children)}
          <div className="manager__content">
            {getComponent('panel', children)}
            {getComponent('map', children)}
          </div>
          <PopupsContainer />
        </ErrorBoundary>
        {reportIssuePopup}
      </div>
    </>
  );
};

export default BaseTemplate;
