import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Select from '../../../../../../../components/Select';
import { DataLayersListContext } from '../../../../hooks/useDataLayersList';
import { TransformedDataset } from '../../../../../../../helpers/types/dataset';
import {
  extractDatasetGeoMapAttributes,
  getDatasetAttributeNameHandler,
} from '../../../../../../../helpers/functions/entities/dataset';
import { AssetType } from '../../../../../../../helpers/constants/entities/asset';
import CreateAnalysisDataLayerTypeSelector from '../CreateAnalysisDataLayerTypeSelector';

import './index.scss';

interface BaseProps {
  selectedAttribute: string;
  onAttributeChange: (attribute: string | null) => void;
}

interface WithDataLayerTypeProps extends BaseProps {
  selectedDataLayerType: AssetType;
  dataLayersOptions: { value: AssetType; title: string }[];
  onDataLayerTypeChange: (value: AssetType | null) => void;
}

export default function CreateAnalysisDatasetsPanel(
  props: BaseProps | WithDataLayerTypeProps,
) {
  const { t } = useTranslation();

  const withDataLayerTypeControl = 'dataLayersOptions' in props;

  const context = useContext(DataLayersListContext);
  const datasets = context.assets as TransformedDataset[];
  const { selectedUuid } = context;
  const selectedDataset = datasets.find(
    (dataset) => dataset.uuid === selectedUuid,
  );

  const options = useMemo(() => {
    if (!selectedDataset) {
      return [];
    }

    const attributes = extractDatasetGeoMapAttributes(selectedDataset);
    const nameHandler = getDatasetAttributeNameHandler(
      selectedDataset.fullAttributes,
    );
    return attributes.map((attribute) => ({
      value: attribute,
      title: nameHandler(attribute),
    }));
  }, [selectedDataset]);

  const selectedValue = useMemo(
    () =>
      options.find((option) => option.value === props.selectedAttribute)
        ?.value ?? '',
    [options, props.selectedAttribute],
  );

  return (
    <div className="create-analysis-datasets-panel">
      {withDataLayerTypeControl && (
        <CreateAnalysisDataLayerTypeSelector
          selectedType={props.selectedDataLayerType}
          dataLayersOptions={props.dataLayersOptions}
          onDataLayerTypeChange={props.onDataLayerTypeChange}
        />
      )}
      <Select
        id="dataset-attribute-selector"
        required
        disabled={!selectedDataset}
        labelPosition="left"
        classes={{
          root: 'create-analysis-datasets-panel__select',
          selectInput: clsx({
            'create-analysis-datasets-panel__strict-input-width':
              withDataLayerTypeControl,
          }),
        }}
        label={t('zones-ops.multi-layer.steps.4.settings-table.attribute')}
        value={selectedValue}
        options={options}
        onChange={props.onAttributeChange}
      />
    </div>
  );
}
