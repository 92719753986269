mutation saveEquation($input: SaveEquationInput!) {
  saveEquation(input: $input) {
    uuid
    userUuid
    organizationUuid
    title
    description
    sourceUrl
    dataVariables
    equationResultVariable
    equationAsText
    productUnit
    useNumpy
  }
}
