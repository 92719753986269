export const selectAssetType = ({ createThreeDMap }) =>
  createThreeDMap.assetType;

export const selectShape = ({ createThreeDMap }) => createThreeDMap.shape;

export const selectZonesMapUuid = ({ createThreeDMap }) =>
  createThreeDMap.zonesMapUuid;

export const selectLoading = ({ createThreeDMap }) => createThreeDMap.loading;

export const selectSettings = ({ createThreeDMap }) => createThreeDMap.settings;
