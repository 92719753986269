import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function OperationsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_15681_352592)">
        <path d="M16.7934 3.905C15.4367 2.79583 13.7592 2.0625 11.9167 1.87917V3.73083C13.255 3.89583 14.4742 4.4275 15.4917 5.21583L16.7934 3.905ZM18.2692 10.0833H20.1209C19.9375 8.24083 19.2042 6.56333 18.095 5.20667L16.7842 6.50833C17.5725 7.52583 18.1042 8.745 18.2692 10.0833ZM16.7842 15.4917L18.095 16.8025C19.2042 15.4458 19.9375 13.7592 20.1209 11.9258H18.2692C18.1042 13.255 17.5725 14.4742 16.7842 15.4917ZM11.9167 18.2692V20.1208C13.7592 19.9375 15.4367 19.2042 16.7934 18.095L15.4825 16.7842C14.4742 17.5725 13.255 18.1042 11.9167 18.2692ZM10.0834 18.2692V20.1208C5.45421 19.6625 1.83337 15.7575 1.83337 11C1.83337 6.2425 5.45421 2.3375 10.0834 1.87917V3.73083C6.46254 4.18 3.66671 7.26 3.66671 11C3.66671 14.74 6.46254 17.82 10.0834 18.2692Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.59509 8.66864L8.10634 8.65105C8.26867 8.45133 8.45127 8.26873 8.651 8.1064L8.66858 6.59516C8.94274 6.44975 9.23209 6.32919 9.53364 6.23648L10.6148 7.29278C10.7414 7.27978 10.8699 7.27312 11 7.27312C11.1301 7.27312 11.2586 7.27978 11.3853 7.29279L12.4664 6.23654C12.768 6.32928 13.0574 6.44986 13.3316 6.5953L13.3492 8.10656C13.5488 8.26885 13.7313 8.45139 13.8936 8.65105L15.4048 8.66864C15.5502 8.94285 15.6708 9.23228 15.7635 9.5339L14.7072 10.6151C14.7202 10.7416 14.7269 10.87 14.7269 11C14.7269 11.1301 14.7202 11.2586 14.7072 11.3852L15.7635 12.4663C15.6707 12.7679 15.5501 13.0574 15.4047 13.3316L13.8934 13.3492C13.7312 13.5488 13.5487 13.7313 13.3492 13.8935L13.3316 15.4047C13.0573 15.5502 12.7679 15.6708 12.4663 15.7635L11.3851 14.7073C11.2585 14.7203 11.13 14.7269 11 14.7269C10.87 14.7269 10.7416 14.7203 10.615 14.7073L9.53383 15.7636C9.23221 15.6709 8.9428 15.5503 8.66858 15.4049L8.651 13.8937C8.45134 13.7314 8.2688 13.5489 8.1065 13.3492L6.59522 13.3316C6.44979 13.0574 6.3292 12.7681 6.23647 12.4665L7.29274 11.3854C7.27973 11.2587 7.27306 11.1301 7.27306 11C7.27306 10.87 7.27972 10.7415 7.29272 10.6149L6.23642 9.53369C6.32913 9.23214 6.44969 8.94279 6.59509 8.66864ZM10.9998 12.9116C12.0555 12.9116 12.9114 12.0557 12.9114 11C12.9114 9.94424 12.0555 9.08839 10.9998 9.08839C9.94402 9.08839 9.08817 9.94424 9.08817 11C9.08817 12.0557 9.94402 12.9116 10.9998 12.9116Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_15681_352592">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
