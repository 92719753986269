import React from 'react';

import LayersLimitPanel from '../../../dataLayersView/components/DataLayersTree/Panels/LayersLimitPanel';
import { SelectedDataLayersData } from '../../../dataLayersView/types/dataLayersTree';
import { COMPARE_LAYERS_LIMIT } from '../../helpers/constants';

export default function LayersLimitDataLayersTreePanel(props: {
  classes?: {
    panel?: string;
  };
  checked: Record<string, number>;
  onCancelClick: () => void;
  onConfirmClick: (d: SelectedDataLayersData) => void;
}) {
  return (
    <LayersLimitPanel
      {...props}
      limit={COMPARE_LAYERS_LIMIT}
      confirmButtonTitleI18nKey="compare-layers.confirm-button"
      descriptionI18nKey="compare-layers.compare-limit"
    />
  );
}
