import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import Typography from '@material-ui/core/Typography';

import Button from '../../../../../../../components/Button';
import { getDemoFormLink } from '../../../../../../../helpers/functions/utils/appConfig';
import { prepareEmailAttribute } from '../../../../helpers/functions/authentication';
import { captureException } from '../../../../../../../helpers/functions/utils/errorHandling';

import '../../../../components/common/index.scss';

const SignUpSentPanel = () => {
  const { state: locationState = {} } = useLocation();
  const [resendClicked, setResendClicked] = useState(false);
  const { email } = locationState as { email: string };

  const onActionClick = () => {
    const preparedEmail = prepareEmailAttribute(email);

    setResendClicked(true);
    Auth.resendSignUp(preparedEmail).catch((error) => {
      captureException({ error });
    });
  };

  return (
    <div className="authentication-panel">
      <Typography
        classes={{
          root: 'authentication-panel__header',
        }}
        variant="h1"
      >
        Confirm Registration
      </Typography>
      <Typography
        classes={{
          root: 'authentication-panel__tip',
        }}
      >
        We&apos;ve sent you a confirmation instructions to {email}. Please click
        it to confirm your email address.
      </Typography>
      <Typography
        classes={{
          root: 'authentication-panel__tip',
        }}
      >
        Haven&apos;t received our email? It might be in your spam/junk folder.
      </Typography>
      {resendClicked && (
        <Typography
          classes={{
            root: 'authentication-panel__tip',
          }}
        >
          If you still haven&apos;t received the email, please{' '}
          <a
            className="link"
            target="_blank"
            href={getDemoFormLink()}
            rel="noreferrer"
          >
            contact us
          </a>
          .
        </Typography>
      )}
      <Button
        className="authentication-panel__button"
        variant="contained"
        color="primary"
        onClick={onActionClick}
      >
        Send instructions again
      </Button>
    </div>
  );
};

export default SignUpSentPanel;
