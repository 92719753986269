enum ZonesOpsWorkflow {
  createSingleLayerAnalysis = 'createSingleLayerAnalysis',
  createMultiLayerAnalysis = 'createMultiLayerAnalysis',
  createFieldPotentialZones = 'createFieldPotentialZones',
  createStabilityZones = 'createStabilityZones',
  crossLayer = 'crossLayer',
  drawManually = 'drawManually',
  cloneZonesMap = 'cloneZonesMap',
  equationBased = 'equationBased',
  threeDMap = '3dMap',
}

export default ZonesOpsWorkflow;
