import { useAppDispatch } from '../../../../app/store/helpers/functions';
import { getZonesHexColorCodes } from '../../../../helpers/functions/entities/vectorAnalysisMap';
import useVamap from '../../../field/hooks/useVamap';
import { resetUpdates } from '../zonesMapSlice';

/**
 * Reset the all updates and distribution data to the initial vamap state.
 */
export default function useResetUpdates({
  farmUuid,
  fieldUuid,
  uuid,
}: {
  farmUuid: string;
  fieldUuid: string;
  uuid: string;
}) {
  const dispatch = useAppDispatch();
  const { vamap } = useVamap(farmUuid, fieldUuid, uuid);

  return () => {
    if (!vamap?.zonesMapGeojson) return;

    const { features } = vamap.zonesMapGeojson;
    const zoneColors = getZonesHexColorCodes(vamap.geoMaps);

    dispatch(
      resetUpdates({
        features,
        zoneColors,
      }),
    );
  };
}
