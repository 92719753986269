enum TreeNodeEntity {
  farm = 'Farm',
  field = 'Field',
  fieldSatelliteImage = 'FieldSatelliteImage',
  vectorAnalysisMap = 'VectorAnalysisMap',
  soilDataset = 'SoilDataset',
  yieldDataset = 'YieldDataset',
  asAppliedDataset = 'AsAppliedDataset',
  elevation = 'Elevation',
  equationMap = 'EquationMap',
  rasterAnalysisMap = 'RasterAnalysisMap',
  note = 'Note',
  comment = 'Comment',
  photo = 'Photo',
  threeDimensionalMap = 'ThreeDimensionalMap',
  johnDeereFile = 'JohnDeereFile',
  johnDeereField = 'JohnDeereField',
  johnDeereMapLayers = 'JohnDeereMapLayers',
  planetArchive = 'PlanetArchive',
  planetMask = 'PlanetMask',
  planetImage = 'PlanetImage',
  planetSubscription = 'PlanetSubscription',
  report = 'Report',
  unknown = 'UNKNOWN',
}

export default TreeNodeEntity;
