import React from 'react';
import { connect } from 'react-redux';

import Stepper from '../../../../../../../components/Stepper';
import BackdropLoading from '../../../../../../../components/Loading/BackdropLoading';
import Panel from '../../../Panel/Zones';
import { goToPrevStep } from '../../../../../zonesOps/zonesOpsSlice';
import {
  navigateToSettings,
  setVamapsFilter,
} from '../../../../crossLayerSlice';
import { fetchAllAssets } from '../../../../../../field/fieldSlice';
import Message from '../../../../../../../components/Messages/Message';
import {
  selectIsAllAssetsLoaded,
  selectVectorAnalysisMaps,
} from '../../../../../../field/fieldSelectors';
import {
  selectNotEmptySelectedVectorAnalysisMaps,
  selectVamapsFilter,
} from '../../../../crossLayerSelectors';
import { selectSelectedField } from '../../../../../zonesOps/zonesOpsSelectors';
import useDidMount from '../../../../../../../hooks/useDidMount';
import { assetGroupFilterRenderer } from '../../../../../filters/helpers/functions';
import { AssetGroupType } from '../../../../../../../helpers/constants/entities/asset';

import './index.scss';

const ZonesStepper = ({
  nextDisabled,
  isLoaded,
  vamapsFilter,
  vectorAnalysisMaps,
  steps,
  stepIndex,
  step,
  onClickBack,
  onClickNext,
  onVamapsFilterChange,
  requestVamaps,
}) => {
  useDidMount(requestVamaps);

  const handleVamapsFilterChange = (filter) => {
    onVamapsFilterChange(filter[AssetGroupType.vectorAnalysisMaps]);
  };

  let content;

  if (isLoaded) {
    if (vectorAnalysisMaps.length === 0) {
      content = (
        <Message
          key="panel"
          messageTextLocation="zones-ops.common.no-assets-message"
        />
      );
    } else {
      content = [
        <div key="tools-panel">
          {assetGroupFilterRenderer({
            type: AssetGroupType.vectorAnalysisMaps,
            filtersValue: {
              [AssetGroupType.vectorAnalysisMaps]: vamapsFilter,
            },
            assets: {
              vectorAnalysisMaps,
            },
            classes: {
              [AssetGroupType.vectorAnalysisMaps]: {
                root: 'cross-layer__filter',
                nameFilter: 'name-filter',
                typeFilter: 'type-filter',
              },
            },
            onFiltersUpdate: handleVamapsFilterChange,
          })}
        </div>,
        <Panel key="panel" step={step} />,
      ];
    }
  } else {
    content = <BackdropLoading key="loader" />;
  }

  return (
    <Stepper
      activeStep={stepIndex}
      steps={steps}
      onClickNext={onClickNext}
      onClickBack={onClickBack}
      nextDisabled={nextDisabled}
    >
      {content}
    </Stepper>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { farmUuid, fieldUuid } = selectSelectedField(state);
  const selectedMaps = selectNotEmptySelectedVectorAnalysisMaps(state);

  return {
    ...ownProps,
    isLoaded: selectIsAllAssetsLoaded(state),
    nextDisabled: selectedMaps.length < 2,
    farmUuid,
    fieldUuid,
    vamapsFilter: selectVamapsFilter(state),
    vectorAnalysisMaps: selectVectorAnalysisMaps(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onVamapsFilterChange: (filter) => {
    dispatch(setVamapsFilter(filter));
  },
  onClickBack: () => {
    dispatch(goToPrevStep());
  },
  onClickNext: () => {
    dispatch(navigateToSettings());
  },
  requestVamaps: (farmUuid, fieldUuid) => {
    dispatch(
      fetchAllAssets({
        farmUuid,
        fieldUuid,
      }),
    );
  },
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,
  requestVamaps: () =>
    propsFromDispatch.requestVamaps(
      propsFromState.farmUuid,
      propsFromState.fieldUuid,
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(ZonesStepper);
