import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '../../../../../components/TextField';

import './index.scss';

export default function GridSizeAdjustment({
  gridX,
  gridY,
  onGridXChange,
  onGridYChange,
}: {
  gridX: number;
  gridY: number;
  onGridXChange: (s: number) => void;
  onGridYChange: (s: number) => void;
}) {
  const { t } = useTranslation();

  return (
    <div className="grid-size-adjustment">
      <TextField
        required
        type="number"
        title={`${t('zones-ops.equation-based.steps.5.grid-size-x')}, ${t('zones-ops.equation-based.steps.5.postfix.m')}`}
        value={gridX}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onGridXChange(+e.target.value)
        }
      />
      <span className="grid-size-adjustment__delimiter">X</span>
      <TextField
        required
        type="number"
        title={`${t('zones-ops.equation-based.steps.5.grid-size-y')}, ${t('zones-ops.equation-based.steps.5.postfix.m')}`}
        value={gridY}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onGridYChange(+e.target.value)
        }
      />
    </div>
  );
}
