import React from 'react';

import ImageListItem from './ImageListItem';

import './index.scss';

const ImageList = ({
  images = [],
  onImageClick = () => {},
  className = '',
}) => (
  <div role="listbox" className={`image-list ${className}`}>
    {images.map((image, i) => (
      <ImageListItem
        key={`img_${i}`}
        url={image.url}
        onItemClick={() => onImageClick(i)}
      />
    ))}
  </div>
);

export default ImageList;
