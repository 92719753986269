import React, { ChangeEvent, MouseEvent } from 'react';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';
import FormHelperText from '@material-ui/core/FormHelperText';

import {
  selectZones,
  selectProducts,
  selectRatesPerProduct,
  selectSelectedZone,
} from '../../../../zonesMapSelectors';
import { selectAreaUnit } from '../../../../../../user/userSelectors';
import { PRODUCT_UNIT_TO_I18N_LABEL } from '../../../../../../../helpers/constants/units/productUnit';
import { prettifyNumber } from '../../../../../../../helpers/markup';
import { getI18nAreaUnit } from '../../../../../../../helpers';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../app/store/helpers/functions';
import { convertProductUnitToAbsoluteUnit } from '../../../../../../../helpers/functions/units/productUnit';
import { ABSOLUTE_UNIT_TO_I18N_LABEL } from '../../../../../../../helpers/constants/units/absoluteUnit';
import TextField from '../../../../../../../components/TextField';
import SavingsRow from '../SavingsRow';
import { setProductRate, setSelectedZone } from '../../../../zonesMapSlice';
import { getView, updateView } from '../../../../helpers/functions/viewType';
import PanelView from '../../../../helpers/constants/view';

import './index.scss';

export default function ZonesTable() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();

  const { productIndex: activeProductIndex } = getView(location.search);
  const zones = useAppSelector(selectZones);
  const products = useAppSelector(selectProducts);
  const ratesPerProduct = useAppSelector(selectRatesPerProduct);
  const areaUnit = useAppSelector(selectAreaUnit);
  const selectedZone = useAppSelector(selectSelectedZone);

  const activateProduct = (productIndex: number) => {
    updateView(location.search, history, {
      type: PanelView.product,
      productIndex,
    });
  };

  const activateZone = (zoneId: number) => {
    dispatch(setSelectedZone(zoneId));
  };

  const activateRateCell = (productIndex: number, zoneId: number) => {
    activateZone(zoneId);
    activateProduct(productIndex);
  };

  const handleRateChange = (
    productIndex: number,
    zoneId: number,
    value: string,
  ) => {
    dispatch(
      setProductRate({
        productIndex,
        zoneId,
        rate: value,
      }),
    );
  };

  if (!zones?.length || !products?.length || activeProductIndex == null) {
    return null;
  }

  const areaUnitLabel = t(`general.area-unit.${getI18nAreaUnit(areaUnit)}`);
  const totalZonesArea = zones.reduce((sum, zone) => sum + zone.area, 0);

  // Calculate product-specific values
  const productSummaries = products.map((product, productIndex) => {
    const productRates = ratesPerProduct[productIndex] || [];
    const productUnitLabel = t(PRODUCT_UNIT_TO_I18N_LABEL[product.unit]);
    const absoluteUnitLabel = t(
      ABSOLUTE_UNIT_TO_I18N_LABEL[
        convertProductUnitToAbsoluteUnit(product.unit)
      ],
    );

    const totalWeightedProduct = zones.reduce((sum, zone, index) => {
      const rate = parseFloat(productRates[index]) || 0;
      return sum + (rate * zone.area || 0);
    }, 0);

    const averageWeightedRate = totalWeightedProduct / totalZonesArea;

    return {
      product,
      productUnitLabel,
      absoluteUnitLabel,
      totalWeightedProduct,
      averageWeightedRate,
    };
  });

  return (
    <TableContainer component={Paper} className="zones-table">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t('zones-map.rates.zone')}</TableCell>

            <TableCell>
              {t('general.shared.area-label')}{' '}
              <span className="text_secondary">
                ({getI18nAreaUnit(areaUnit)})
              </span>
            </TableCell>

            {products.map((product, productIndex) => (
              <TableCell
                key={productIndex}
                className={clsx('product-column', {
                  'active-column': productIndex === activeProductIndex,
                })}
                onClick={() => activateProduct(productIndex)}
              >
                {product.name}{' '}
                <span className="text_secondary">
                  ({t(PRODUCT_UNIT_TO_I18N_LABEL[product.unit])})
                </span>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {zones.map((zone, zoneIndex) => (
            <TableRow
              key={zone.id}
              className={clsx('zone-row', {
                'zone-row_selected': zone.id === selectedZone,
              })}
              onClick={() => activateZone(zone.id)}
            >
              <TableCell>
                <div className="zone-cell-content">
                  <span>{zone.id}</span>
                  <div
                    className="zones-table__color-box"
                    style={{ backgroundColor: zone.color || 'transparent' }}
                  ></div>
                </div>
              </TableCell>

              <TableCell>
                {prettifyNumber(zone.area, 1)} (
                {prettifyNumber(zone.percentage, 1)}
                %)
              </TableCell>

              {products.map((_product, productIndex) => {
                const productRates = ratesPerProduct[productIndex] || [];
                const rate = productRates[zoneIndex];
                const summary = productSummaries[productIndex];

                return (
                  <TableCell
                    key={productIndex}
                    className={clsx('product-column', {
                      'active-column': productIndex === activeProductIndex,
                    })}
                    onClick={() => activateRateCell(productIndex, zone.id)}
                  >
                    <TextField
                      type="number"
                      classes={{ wrapper: 'zones-table__rate-input' }}
                      variant="small"
                      value={rate || ''}
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      onChange={(e: ChangeEvent<{ value: string }>) =>
                        handleRateChange(productIndex, zone.id, e.target.value)
                      }
                      onFocus={() => activateRateCell(productIndex, zone.id)}
                      onClick={(e: MouseEvent) => e.stopPropagation()}
                    />
                    <FormHelperText
                      className={clsx('zones-table__rate-helper-text', {
                        'zones-table__rate-helper-text_hidden': !rate,
                      })}
                    >
                      {prettifyNumber(parseFloat(rate) * zone.area, 1)}{' '}
                      {summary.absoluteUnitLabel}
                    </FormHelperText>
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>

        <TableFooter>
          <TableRow className="summary-row">
            <TableCell colSpan={2}>
              <span className="summary-row__text">
                {t('zones-map.rates.total-area')}:{' '}
                {prettifyNumber(totalZonesArea, 1)} {areaUnitLabel}
              </span>
            </TableCell>

            {productSummaries.map((summary, productIndex) => (
              <TableCell
                key={productIndex}
                className={clsx('product-column', {
                  'active-column': productIndex === activeProductIndex,
                })}
                onClick={() => activateProduct(productIndex)}
              >
                <div className="summary-row__cell-content">
                  <span className="summary-row__text">
                    {t('zones-map.rates.total')}:{' '}
                    <span className="summary-row__text-value">
                      {prettifyNumber(summary.totalWeightedProduct, 1)}{' '}
                      {summary.absoluteUnitLabel}
                    </span>
                  </span>
                  <span className="summary-row__text">
                    {t('zones-map.rates.average')}:{' '}
                    <span className="summary-row__text-value">
                      {prettifyNumber(summary.averageWeightedRate, 1)}{' '}
                      {summary.productUnitLabel}
                    </span>
                  </span>
                </div>
              </TableCell>
            ))}
          </TableRow>
          <SavingsRow
            activeProductIndex={activeProductIndex}
            onProductCellClick={activateProduct}
          />
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
