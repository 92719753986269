import i18n from 'i18next';

export enum CompanyType {
  AgCooperative = 'Ag Cooperative',
  AgConsultancy = 'Ag Consultancy',
  AgtechSoftware = 'Agtech Software',
  Agroholding = 'Agroholding',
  FoodProducer = 'Food Producer',
  AgMachineryProducer = 'Ag Machinery producer',
  AgMachineryEquipmentDealer = 'Ag Machinery or Equipment dealer',
  AgInputsProducer = 'Ag Inputs producer',
  AgInputsDistributor = 'Ag Inputs distributor',
  UniversityResearch = 'University / Research',
  Farmer = 'Farmer',
  Consulting = 'Consulting / Services for GeoPard',
}

export const getCompanyTypeOptions = () => [
  {
    title: i18n.t('authentication.additional-user-data.company-type.farmer'),
    value: CompanyType.Farmer,
  },
  {
    title: i18n.t(
      'authentication.additional-user-data.company-type.ag-cooperative',
    ),
    value: CompanyType.AgCooperative,
  },
  {
    title: i18n.t(
      'authentication.additional-user-data.company-type.ag-consultancy',
    ),
    value: CompanyType.AgConsultancy,
  },
  {
    title: i18n.t(
      'authentication.additional-user-data.company-type.agtech-software',
    ),
    value: CompanyType.AgtechSoftware,
  },
  {
    title: i18n.t(
      'authentication.additional-user-data.company-type.agroholding',
    ),
    value: CompanyType.Agroholding,
  },
  {
    title: i18n.t(
      'authentication.additional-user-data.company-type.food-producer',
    ),
    value: CompanyType.FoodProducer,
  },
  {
    title: i18n.t(
      'authentication.additional-user-data.company-type.ag-machinery-producer',
    ),
    value: CompanyType.AgMachineryProducer,
  },
  {
    title: i18n.t(
      'authentication.additional-user-data.company-type.ag-machinery-equipment-dealer',
    ),
    value: CompanyType.AgMachineryEquipmentDealer,
  },
  {
    title: i18n.t(
      'authentication.additional-user-data.company-type.ag-inputs-producer',
    ),
    value: CompanyType.AgInputsProducer,
  },
  {
    title: i18n.t(
      'authentication.additional-user-data.company-type.ag-inputs-distributor',
    ),
    value: CompanyType.AgInputsDistributor,
  },
  {
    title: i18n.t(
      'authentication.additional-user-data.company-type.university-research',
    ),
    value: CompanyType.UniversityResearch,
  },
  {
    title: i18n.t(
      'authentication.additional-user-data.company-type.consulting',
    ),
    value: CompanyType.Consulting,
  },
];
