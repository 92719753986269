import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Button from '../../../../../components/Button';
import Link from '../../../../../components/Link';
import NewVamapIcon from '../Icons/new-vamap.svg';
import NewFieldIcon from '../Icons/new-field.svg';
import {
  getFieldLink,
  getRootLink,
  getZonesMapLink,
  PAGES_ROOTS,
} from '../../../../../helpers/navigation';
import { ZonesMapData } from '../../types/ui';
import { formatDate } from '../../../../../helpers/functions/utils/date';

import './index.scss';

export default function LastUpdatedZonesMaps({
  zonesMaps = [],
}: {
  zonesMaps: ZonesMapData[];
}) {
  const { t } = useTranslation();

  return (
    <div className="last-updated-zones-maps">
      <Typography variant="h3" className="last-updated-zones-maps__title">
        {t('homepage.last-updated-zones-maps')}
      </Typography>
      {zonesMaps.length === 0 ? (
        <div className="last-updated-zones-maps__no-results">
          <Typography>{t('homepage.no-fields-assumption-p1')}</Typography>
          <Button
            to={getRootLink(PAGES_ROOTS.createField)}
            startIcon={<NewFieldIcon />}
            classes={{
              root: 'action',
              label: 'action__label',
              startIcon: 'action__icon',
            }}
            component={RouterLink}
          >
            <Trans i18nKey={t('homepage.quick-actions.create-field')} />
          </Button>
          <Typography>{t('homepage.no-fields-assumption-p2')}</Typography>
          <Button
            to={getRootLink(PAGES_ROOTS.zonesOps)}
            startIcon={<NewVamapIcon />}
            classes={{
              root: 'action',
              label: 'action__label',
              startIcon: 'action__icon',
            }}
            component={RouterLink}
          >
            <Trans i18nKey={t('homepage.quick-actions.create-zones-map')} />
          </Button>
        </div>
      ) : (
        <div className="last-updated-zones-maps__items">
          {zonesMaps.map((zonesMap) => {
            const fieldLink = getFieldLink(
              zonesMap.farmUuid,
              zonesMap.fieldUuid,
            );
            const zonesMapLink = getZonesMapLink(
              zonesMap.farmUuid,
              zonesMap.fieldUuid,
              zonesMap.uuid,
            );
            const updatedDate = formatDate(zonesMap.updatedDate);

            return (
              <div
                key={zonesMap.uuid}
                className="last-updated-zones-maps__item"
              >
                <div className="last-updated-zones-maps__item-content">
                  <img
                    className="last-updated-zones-maps__item-preview"
                    src={zonesMap.preview}
                    alt={t('general.shared.preview')}
                  />
                  <Typography className="last-updated-zones-maps__item-name">
                    {zonesMap.name}
                  </Typography>
                  {fieldLink && zonesMap.fieldName && (
                    <Link
                      className="last-updated-zones-maps__item-link"
                      to={fieldLink}
                    >
                      {zonesMap.fieldName}
                    </Link>
                  )}
                  <Typography className="last-updated-zones-maps__item-info">
                    {t('zones-maps.zones-list.columns.updated')}: {updatedDate}
                  </Typography>
                </div>
                {zonesMapLink && (
                  <div className="last-updated-zones-maps__item-footer">
                    <Link to={zonesMapLink}>{t('homepage.view-details')}</Link>
                  </div>
                )}
              </div>
            );
          })}
          <div className="last-updated-zones-maps__view-all">
            <Link
              className="last-updated-zones-maps__view-all-link"
              to={getRootLink(PAGES_ROOTS.zonesMaps)}
            >
              <ArrowForwardIcon fontSize="small" />
              {t('homepage.view-all')}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
