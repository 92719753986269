import {
  useAppDispatch,
  useAppSelector,
} from '../../../../app/store/helpers/functions';
import { selectWorkflow } from '../batchAnalyticsSelectors';
import BatchOperationWorkflow from '../helpers/constants/workflows';
import { resetBatchEquationBasedAnalysis } from '../../createBatchEquationBasedAnalysis/createBatchEquationBasedAnalysisSlice';

export default function useResetWorkflow() {
  const dispatch = useAppDispatch();
  const workflow = useAppSelector(selectWorkflow);

  const resetWorkflow = () => {
    if (workflow === BatchOperationWorkflow.equationBased) {
      dispatch(resetBatchEquationBasedAnalysis());
    }
  };

  return {
    resetWorkflow,
  };
}
