#import "../fragments/yieldDataset.gql"

query getYieldDatasets(
  $farmUuids: [ID]
  $fieldUuids: [ID]
  $datasetUuids: [ID]
  $areaUnit: AreaUnit
) {
  getFarms(farmUuids: $farmUuids) {
    uuid
    fields(fieldUuids: $fieldUuids) {
      uuid
      yieldDatasets(yieldDatasetUuids: $datasetUuids) {
        ...yieldDatasetFields
      }
    }
  }
}
