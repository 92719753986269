import React, { Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../app/store/helpers/functions';
import CloneIcon from '../../components/Icons/clone';
import ExportIcon from '../../../../../components/Icons/export';
import RenameIcon from '../../../../../components/Icons/rename';
import Button from '../../../../../components/Button';
import ButtonMenu from '../../../../../components/ButtonMenu';
import ToolsPanel from '../../../../../components/ToolsPanel';
import { prepareCloneWorkflow } from '../../../cloneZonesMap/cloneZonesMapSlice';
import {
  getRootLink,
  getZonesOpsLink,
  PAGES_ROOTS,
} from '../../../../../helpers/navigation';
import { openPopup } from '../../../popups/popupsSlice';
import { selectItemPins } from '../../../../field/fieldSelectors';
import { deleteAsset, renameAsset } from '../../../../field/fieldSlice';
import {
  selectInstrument,
  selectZonesMapChanged,
} from '../../zonesMapSelectors';
import { openExportZonesMapPopup } from '../../../../exportData/exportDataSlice';
import ZonesOpsWorkflow from '../../../zonesOps/helpers/constants/zonesOpsWorkflow';
import useVamap from '../../../../field/hooks/useVamap';
import useSaveVamapUpdates from '../../hooks/useSaveVamapUpdates';
import POPUPS from '../../../popups/helpers/constants/popups';
import useResetUpdates from '../../hooks/useResetUpdates';

import './index.scss';

export default function ZonesMapToolsPanel() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { farmUuid, fieldUuid, uuid } = useParams<{
    farmUuid: string;
    fieldUuid: string;
    uuid: string;
  }>();
  const { saveUpdates } = useSaveVamapUpdates({ fieldUuid, uuid });
  const resetUpdates = useResetUpdates({ farmUuid, fieldUuid, uuid });
  const { vamap } = useVamap(farmUuid, fieldUuid, uuid);

  const selectedInstrument = useAppSelector(selectInstrument);
  const vamapPins = useAppSelector((state) => selectItemPins(state, uuid));
  const zonesMapChanged = useAppSelector(selectZonesMapChanged);
  const isInstrumentActive = !!selectedInstrument;

  const handleMoreItemClick = ({ id }: { id: string }) => {
    const name = vamap?.name;

    switch (id) {
      case 'rename': {
        dispatch(
          openPopup({
            type: 'rename-zones-map',
            entityName: vamap?.name,
            onConfirm: async (newValue: string) => {
              await dispatch(
                renameAsset({
                  name: newValue,
                  uuid,
                }),
              );
            },
          }),
        );
        break;
      }

      case 'delete': {
        dispatch(
          openPopup({
            type: POPUPS.deleteZonesMap,
            entityName: name,
            shouldDisplayPinsWarning: vamapPins.length > 0,
            onConfirm: async () => {
              await dispatch(deleteAsset(uuid));
              history.replace(getRootLink(PAGES_ROOTS.zonesMaps));
            },
          }),
        );
        break;
      }

      default:
        break;
    }
  };

  const handleExportClick = () => {
    dispatch(
      openExportZonesMapPopup({
        zonesMaps: [
          {
            ...vamap,
            fieldUuid,
          },
        ],
        archiveName: vamap?.name,
      }),
    );
  };

  const handleCloneClick = () => {
    dispatch(
      prepareCloneWorkflow({
        vamap,
        farmUuid,
        fieldUuid,
      }),
    );
    history.push(
      getZonesOpsLink(
        farmUuid,
        fieldUuid,
        ZonesOpsWorkflow.cloneZonesMap,
        true,
      ),
    );
  };

  return (
    <ToolsPanel
      classes={{
        root: 'zones-map-tools-panel',
      }}
      actionsLeft={
        <Fragment>
          <Button startIcon={<CloneIcon />} onClick={handleCloneClick}>
            {t('zones-map.tools-panel.clone')}
          </Button>
          <Button startIcon={<ExportIcon />} onClick={handleExportClick}>
            {t('export.export')}
          </Button>
          <ButtonMenu
            isIconButton
            size="small"
            key="more-button"
            items={[
              {
                id: 'rename',
                label: t('general.controls.rename'),
                IconComponent: RenameIcon,
              },
              {
                id: 'delete',
                label: t('general.controls.delete'),
                IconComponent: DeleteIcon,
              },
            ]}
            onItemClick={handleMoreItemClick}
          />
        </Fragment>
      }
      actionsRight={
        zonesMapChanged && (
          <>
            <Button
              disabled={isInstrumentActive}
              variant="outlined"
              onClick={resetUpdates}
            >
              {t('general.controls.cancel')}
            </Button>
            <Button
              disabled={isInstrumentActive}
              variant="contained"
              color="primary"
              onClick={saveUpdates}
            >
              {t('general.controls.save')}
            </Button>
          </>
        )
      }
    />
  );
}
