import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, Typography } from '@material-ui/core';
import clsx from 'clsx';

import Checkbox from '../../../../../components/Checkbox';
import ComboBox, { Option } from '../../../../../components/ComboBox';

import './index.scss';

const STANDARD_DEVIATION_MAX_VALUE = 5;

const getStandardDeviationItems = () =>
  new Array(STANDARD_DEVIATION_MAX_VALUE).fill(null).map((_, index) => ({
    value: index + 1,
    title: (index + 1).toString(),
  }));

export default function StandardDeviation({
  value,
  disabled,
  onChange,
  onChecked,
}: {
  value?: number | null;
  disabled?: boolean;
  onChange: (value: number | null) => void;
  onChecked: (value: boolean) => void;
}) {
  const { t } = useTranslation();

  const checked = value !== undefined;

  const standardDeviationOptions = useMemo(
    () => getStandardDeviationItems(),
    [],
  );
  const selectedStandardDeviationOption = useMemo(
    () =>
      standardDeviationOptions.find((option) => option.value === value) ?? null,
    [value, standardDeviationOptions],
  );

  const handleStandardDeviationChange = (
    _e: unknown,
    item: Option<number> | null,
  ) => {
    onChange(item?.value ?? null);
  };

  const handleChecked = () => {
    onChecked(!checked);
  };

  return (
    <div className="standard-deviation">
      <FormControlLabel
        label={t(
          'clean-calibrate.yield-popup.tabs.clean.configure.standard-deviation.title',
        )}
        control={
          <Checkbox
            disabled={disabled}
            value={checked ? 2 : 0}
            onChange={handleChecked}
          />
        }
      />
      <ComboBox
        classes={{
          root: clsx('standard-deviation__selector', {
            'standard-deviation__selector-disabled': !checked,
          }),
          outline: 'standard-deviation__selector-outline',
        }}
        disabled={disabled || !checked}
        placeholder={t(
          'clean-calibrate.yield-popup.tabs.clean.configure.standard-deviation.placeholder',
        )}
        options={standardDeviationOptions}
        value={selectedStandardDeviationOption}
        disableCloseOnSelect={false}
        getOptionSelected={(option, selectedOption) =>
          option.value === selectedOption?.value
        }
        onChange={handleStandardDeviationChange}
      />
      <Typography className="standard-deviation__description">
        {t(
          'clean-calibrate.yield-popup.tabs.clean.configure.standard-deviation.description',
        )}
      </Typography>
    </div>
  );
}
