const getZonesMapsFilter = ({
  pageSize,
  lastEvaluatedKey,
  farm,
  field,
  type,
  purpose,
  labels,
  withRates,
  areaUnit,
}) => ({
  filter: {
    pageSize,
    lastEvaluatedKey,
    farmUuid: (farm || {}).uuid,
    fieldUuid: (field || {}).uuid,
    type,
    purpose,
    labels: (labels || []).map(({ name, value }) => `${name}=${value}`),
    withRatesOnly: withRates,
  },
  areaUnit,
});

export default getZonesMapsFilter;
