import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import './index.scss';

export default function ExpandableText({
  shortTextI18nKey,
  additionalTextI18nKey,
}: {
  shortTextI18nKey: string;
  additionalTextI18nKey: string;
}) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Typography className="expandable-text">
      {expanded ? (
        <>
          {t(shortTextI18nKey)} {t(additionalTextI18nKey)}{' '}
          <Link component="button" onClick={handleToggleExpandClick}>
            {t('general.shared.show-less')}
          </Link>
        </>
      ) : (
        <>
          {t(shortTextI18nKey)}{' '}
          <Link component="button" onClick={handleToggleExpandClick}>
            {t('general.shared.show-more')}
          </Link>
        </>
      )}
    </Typography>
  );
}
