import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';

const getSteps = (organization) => [
  {
    name: i18n.t('export-data-john-deere.steps.selectOrganization.title'),
    description: i18n.t(
      'export-data-john-deere.steps.selectOrganization.description',
    ),
    id: 'selectOrganization',
  },
  {
    name: i18n.t('export-data-john-deere.steps.selectZonesMaps.title'),
    description: (
      <Trans
        i18nKey="export-data-john-deere.steps.selectZonesMaps.description"
        values={{ organization }}
      >
        <b />
      </Trans>
    ),
    id: 'selectZonesMaps',
  },
];

export default getSteps;
