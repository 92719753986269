import i18n from 'i18next';

import { HelperTextType } from '../constants/helperTexts';

const getCountryHelperTexts = (
  freeTrialCounties: Record<string, boolean>,
  country: string,
) => {
  if (country && !freeTrialCounties[country]) {
    return [
      {
        label: i18n.t(
          'authentication.additional-user-data.validation.free-trial-not-available',
        ),
        type: HelperTextType.info,
      },
    ];
  }

  return [];
};

export default getCountryHelperTexts;
