import StripeProductKey from '../../../../stripePriceDataConfig/helpers/constants/stripeProductKey';

export enum ActivityLogAction {
  SINGLE_PLANET_IMAGE_ORDER = 'SINGLE_PLANET_IMAGE_ORDER',
  ISOXML_EXPORT = 'ISOXML_EXPORT',
  SHP_EXPORT = 'SHP_EXPORT',
  SOIL_SENSOR_DATASET = 'SOIL_SENSOR_DATASET',
  SOIL_DATASET = 'SOIL_DATASET',
  YIELD_DATASET = 'YIELD_DATASET',
  YIELD_CALIBRATION = 'YIELD_CALIBRATION',
  AS_APPLIED_DATASET = 'AS_APPLIED_DATASET',
  SINGLE_LAYER_ZONES_MAPS = 'SINGLE_LAYER_ZONES_MAPS',
  MULTI_YEAR_ZONES_MAPS = 'MULTI_YEAR_ZONES_MAPS',
  STABILITY_ZONES_MAPS = 'STABILITY_ZONES_MAPS',
  MULTI_LAYER_ZONES_MAPS = 'MULTI_LAYER_ZONES_MAPS',
  CROSS_LAYER_ZONES_MAPS = 'CROSS_LAYER_ZONES_MAPS',
  EQUATION_PIXELS_MAPS = 'EQUATION_PIXELS_MAPS',
  EQUATION_ZONES_MAPS = 'EQUATION_ZONES_MAPS',
  GEO_MAP = 'GEO_MAP',
  GEO_FEATURES = 'GEO_FEATURES',
  USERS_ADDITIONAL_ACCOUNT = 'USERS_ADDITIONAL_ACCOUNT',
  FIELD_PIN = 'FIELD_NOTE',
  PIN_COMMENT = 'NOTE_COMMENT',
  PIN_PHOTO = 'NOTE_PHOTO',
  COMMENT_PHOTO = 'COMMENT_PHOTO',
  FIELD_ACTIVATION = 'FIELD_ACTIVATION',
  FIELD_DELETION = 'FIELD_DELETION',
  MAP_3D = 'MAP_3D',
  SOURCE_TOPOGRAPHY_MAP = 'SOURCE_TOPOGRAPHY_MAP',
  SOURCE_FIELD_SATELLITE_IMAGE = 'SOURCE_FIELD_SATELLITE_IMAGE',
  SOURCE_VECTOR_ANALYSIS_MAP = 'SOURCE_VECTOR_ANALYSIS_MAP',
  SOURCE_YIELD_DATASET = 'SOURCE_YIELD_DATASET',
  SOURCE_SOIL_DATASET = 'SOURCE_SOIL_DATASET',
  SOURCE_AS_APPLIED_DATASET = 'SOURCE_AS_APPLIED_DATASET',
  PDF_REPORT = 'PDF_REPORT',
  DRAWN_ZONES_MAPS = 'DRAWN_ZONES_MAPS',
  FIELD_STORAGE = 'FIELD_STORAGE',
  BASE_MONTHLY_FEE = 'BASE_MONTHLY_FEE',
  YIELD_SYNTHETIC = 'YIELD_SYNTHETIC',
}

export const ACTION_TO_I18N_KEY_MAP = {
  [ActivityLogAction.SINGLE_PLANET_IMAGE_ORDER]:
    'activity-log.operations.single-planet-image-order',
  [ActivityLogAction.ISOXML_EXPORT]: 'activity-log.operations.isoxml-export',
  [ActivityLogAction.SHP_EXPORT]: 'activity-log.operations.shp-export',
  [ActivityLogAction.SOIL_SENSOR_DATASET]:
    'activity-log.operations.soil-sensor-dataset',
  [ActivityLogAction.SOIL_DATASET]: 'activity-log.operations.soil-dataset',
  [ActivityLogAction.YIELD_DATASET]: 'activity-log.operations.yield-dataset',
  [ActivityLogAction.YIELD_CALIBRATION]:
    'activity-log.operations.yield-calibration',
  [ActivityLogAction.AS_APPLIED_DATASET]:
    'activity-log.operations.as-applied-dataset',
  [ActivityLogAction.SINGLE_LAYER_ZONES_MAPS]:
    'activity-log.operations.single-layer-zones-maps',
  [ActivityLogAction.MULTI_YEAR_ZONES_MAPS]:
    'activity-log.operations.multi-year-zones-maps',
  [ActivityLogAction.STABILITY_ZONES_MAPS]:
    'activity-log.operations.stability-zones-maps',
  [ActivityLogAction.MULTI_LAYER_ZONES_MAPS]:
    'activity-log.operations.multi-layer-zones-maps',
  [ActivityLogAction.CROSS_LAYER_ZONES_MAPS]:
    'activity-log.operations.cross-layer-zones-maps',
  [ActivityLogAction.EQUATION_PIXELS_MAPS]:
    'activity-log.operations.equation-pixels-maps',
  [ActivityLogAction.EQUATION_ZONES_MAPS]:
    'activity-log.operations.equation-zones-maps',
  [ActivityLogAction.GEO_MAP]: 'activity-log.operations.geo-map',
  [ActivityLogAction.GEO_FEATURES]: 'activity-log.operations.geo-features',
  [ActivityLogAction.FIELD_PIN]: 'activity-log.operations.scouting-pin',
  [ActivityLogAction.PIN_COMMENT]: 'activity-log.operations.scouting-comment',
  [ActivityLogAction.PIN_PHOTO]: 'activity-log.operations.scouting-photo',
  [ActivityLogAction.COMMENT_PHOTO]: 'activity-log.operations.scouting-photo',
  [ActivityLogAction.USERS_ADDITIONAL_ACCOUNT]:
    'activity-log.operations.users-additional-account',
  [ActivityLogAction.FIELD_ACTIVATION]:
    'activity-log.operations.field-activation',
  [ActivityLogAction.FIELD_DELETION]: 'activity-log.operations.field-deletion',
  [ActivityLogAction.MAP_3D]: 'activity-log.operations.map-3d',
  [ActivityLogAction.SOURCE_TOPOGRAPHY_MAP]:
    'activity-log.operations.source-topography-map',
  [ActivityLogAction.SOURCE_FIELD_SATELLITE_IMAGE]:
    'activity-log.operations.source-field-satellite-image',
  [ActivityLogAction.SOURCE_VECTOR_ANALYSIS_MAP]:
    'activity-log.operations.source-vector-analysis-map',
  [ActivityLogAction.SOURCE_YIELD_DATASET]:
    'activity-log.operations.source-yield-dataset',
  [ActivityLogAction.SOURCE_SOIL_DATASET]:
    'activity-log.operations.source-soil-dataset',
  [ActivityLogAction.SOURCE_AS_APPLIED_DATASET]:
    'activity-log.operations.source-as-applied-dataset',
  [ActivityLogAction.PDF_REPORT]: 'activity-log.operations.pdf-report',
  [ActivityLogAction.DRAWN_ZONES_MAPS]:
    'activity-log.operations.drawn-zones-maps',
  [ActivityLogAction.FIELD_STORAGE]: 'activity-log.operations.field-storage',
  [ActivityLogAction.BASE_MONTHLY_FEE]:
    'activity-log.operations.base-monthly-fee',
  [ActivityLogAction.YIELD_SYNTHETIC]:
    'activity-log.operations.yield-synthetic',
};

export const ACTIVITY_LOG_ACTION_TO_STRIPE_PRODUCT_KEY_MAP: Partial<
  Record<ActivityLogAction, StripeProductKey>
> = {
  [ActivityLogAction.USERS_ADDITIONAL_ACCOUNT]:
    StripeProductKey.AdditionalUserSeat,
  [ActivityLogAction.SOIL_SENSOR_DATASET]: StripeProductKey.DatasetImport,
  [ActivityLogAction.SOIL_DATASET]: StripeProductKey.DatasetImport,
  [ActivityLogAction.YIELD_DATASET]: StripeProductKey.DatasetImport,
  [ActivityLogAction.AS_APPLIED_DATASET]: StripeProductKey.DatasetImport,
  [ActivityLogAction.YIELD_CALIBRATION]:
    StripeProductKey.YieldCleaningAndCalibration,
  [ActivityLogAction.EQUATION_PIXELS_MAPS]:
    StripeProductKey.EquationMapsCreation,
  // TODO: Uncomment the following line once the EQUATION_ZONES_MAPS action is linked to the Stripe product key
  // [ActivityLogAction.EQUATION_ZONES_MAPS]: StripeProductKey.ZonesVraMapsCreation,
  [ActivityLogAction.SINGLE_LAYER_ZONES_MAPS]:
    StripeProductKey.ZonesVraMapsCreation,
  [ActivityLogAction.MULTI_YEAR_ZONES_MAPS]:
    StripeProductKey.ZonesVraMapsCreation,
  [ActivityLogAction.STABILITY_ZONES_MAPS]:
    StripeProductKey.ZonesVraMapsCreation,
  [ActivityLogAction.MULTI_LAYER_ZONES_MAPS]:
    StripeProductKey.ZonesVraMapsCreation,
  [ActivityLogAction.CROSS_LAYER_ZONES_MAPS]:
    StripeProductKey.ZonesVraMapsCreation,
  [ActivityLogAction.DRAWN_ZONES_MAPS]: StripeProductKey.ZonesVraMapsCreation,
  // TODO: Uncomment the following line once the MAP_3D action is linked to the Stripe product key
  // [ActivityLogAction.MAP_3D]: StripeProductKey.ZonesVraMapsCreation,
  [ActivityLogAction.ISOXML_EXPORT]: StripeProductKey.Export,
  [ActivityLogAction.SHP_EXPORT]: StripeProductKey.Export,
  [ActivityLogAction.PDF_REPORT]: StripeProductKey.Export,
  [ActivityLogAction.SOURCE_TOPOGRAPHY_MAP]: StripeProductKey.Export,
  [ActivityLogAction.SOURCE_FIELD_SATELLITE_IMAGE]: StripeProductKey.Export,
  [ActivityLogAction.SOURCE_VECTOR_ANALYSIS_MAP]: StripeProductKey.Export,
  [ActivityLogAction.SOURCE_YIELD_DATASET]: StripeProductKey.Export,
  [ActivityLogAction.SOURCE_SOIL_DATASET]: StripeProductKey.Export,
  [ActivityLogAction.SOURCE_AS_APPLIED_DATASET]: StripeProductKey.Export,
  [ActivityLogAction.SINGLE_PLANET_IMAGE_ORDER]: StripeProductKey.PlanetImagery,
  [ActivityLogAction.FIELD_ACTIVATION]: StripeProductKey.FieldRegistration,
  [ActivityLogAction.FIELD_STORAGE]: StripeProductKey.FieldStorage,
  [ActivityLogAction.BASE_MONTHLY_FEE]: StripeProductKey.BaseMonthlyFee,
  [ActivityLogAction.YIELD_SYNTHETIC]:
    StripeProductKey.YieldSyntheticMapCreation,
};

export const ACTIONS_PAGE_SIZE = 500;

export const MAX_ACTIONS_PAGE_SIZE = 1000;
