import React, { ChangeEvent, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import EmailSharpIcon from '@material-ui/icons/EmailSharp';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';

import Button from '../../../../../../../../components/Button';
import GoogleLogoIcon from '../../../../../components/Icons/googleLogo.svg';
import AppleLogoIcon from '../../../../../components/Icons/appleLogo.svg';
import JDLogoIcon from '../../../../../components/Icons/jdLogo.svg';
import TextField from '../../../../../../../../components/TextField';
import { Provider } from '../../../../../helpers/constants/authentication';
import { matchEmailToProvider } from '../../../../../helpers/functions/authentication';
import { HELPER_TEXTS } from '../../../../../helpers/constants/helperTexts';
import { getFederatedSignInUrl } from '../../../../../helpers/functions/navigation';

import '../../../../../components/common/index.scss';
import './index.scss';

export default function FederatedLogInPanel() {
  const [emailState, setEmailState] = useState({
    value: '',
    valid: true,
    errorMessage: '',
  });

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailState({
      ...emailState,
      value: e.target.value,
    });
  };

  const handleEmailProceed = () => {
    const provider = matchEmailToProvider(emailState.value);

    if (provider) {
      setEmailState({
        ...emailState,
        valid: true,
        errorMessage: '',
      });

      window.location.href = getFederatedSignInUrl(provider);
    } else {
      setEmailState({
        ...emailState,
        valid: false,
        errorMessage: HELPER_TEXTS.invalidEmail,
      });
    }
  };

  const handleSocialLoginClick = (provider: Provider) => {
    window.location.href = getFederatedSignInUrl(provider);
  };

  return (
    <div className="federated-log-in-panel">
      <div>
        <Typography className="federated-log-in-panel__tip">
          Continue with your John Deere Ops Center account
        </Typography>
        <Button
          className="federated-log-in-panel__jd-button"
          disableRipple
          variant="outlined"
          onClick={() => handleSocialLoginClick(Provider.johnDeere)}
        >
          <JDLogoIcon />
        </Button>
      </div>

      <div>
        <Typography className="federated-log-in-panel__tip">
          Continue with Microsoft Active Directory
        </Typography>
        <TextField
          value={emailState.value}
          placeholder="Enter email"
          error={!emailState.valid}
          helperText={emailState.errorMessage}
          InputProps={{
            classes: {
              root: 'authentication-panel__input federated-log-in-panel__email-input',
              error: 'textfield__input_error',
            },
            startAdornment: (
              <EmailSharpIcon
                fontSize="small"
                className={clsx(
                  'authentication-panel__input-icon',
                  'authentication-panel__input-icon_start',
                  'federated-log-in-panel__email-icon',
                )}
              />
            ),
            endAdornment: (
              <IconButton
                color="primary"
                className="federated-log-in-panel__forward-icon"
                onClick={handleEmailProceed}
              >
                <ArrowForwardIcon />
              </IconButton>
            ),
          }}
          FormHelperTextProps={{
            classes: {
              root: 'authentication-panel__input-helper-text',
            },
          }}
          onChange={handleEmailChange}
        />
      </div>
      <div>
        <Typography className="federated-log-in-panel__tip">
          Continue with Google or Apple account
        </Typography>
        <div className="federated-log-in-panel__social-buttons">
          <IconButton
            className="federated-log-in-panel__social-button"
            onClick={() => handleSocialLoginClick(Provider.google)}
          >
            <GoogleLogoIcon />
          </IconButton>
          <IconButton
            className="federated-log-in-panel__social-button"
            onClick={() => handleSocialLoginClick(Provider.apple)}
          >
            <AppleLogoIcon />
          </IconButton>
        </div>
        <Typography className="federated-log-in-panel__legal">
          We won&apos;t post to any of your accounts without asking first
        </Typography>
      </div>
    </div>
  );
}
