mutation SaveUserData($input: SaveUserDataInput!) {
  saveUserData(input: $input) {
    identity
    language
    acceptedTermsAndConditions
    areaUnit
    totalArea
    maxArea
    country
    companyType
    email
  }
}
