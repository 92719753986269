query getSatelliteImages(
  $farmUuids: [ID]
  $fieldUuids: [ID]
  $before: AWSDate
  $after: AWSDate
  $imagesUuids: [ID]
) {
  getFarms(farmUuids: $farmUuids) {
    fields(fieldUuids: $fieldUuids) {
      satelliteImages(
        threshold: 0
        geoMapType: ORIGIN
        before: $before
        after: $after
        satelliteImageUuids: $imagesUuids
      ) {
        satelliteImage {
          uuid
          provider
          acquisitionDate
        }
        geoMaps {
          shortName
          url
          classes
          hexColorCodes
        }
        ndviAverage
        cloudFreeLand
        recomendedForMutliYearAnalytics
        status
        statistics {
          index
          min
          max
          avg
          mdn
          var
          std
        }
      }
    }
  }
}
