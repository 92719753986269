import React, { Component, Fragment } from 'react';
import i18n from 'i18next';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';

import Label from '../Label';
import {
  PREDEFINED_PREFIXES,
  PREDEFINED_VALUES,
} from '../../helpers/components/autocomplete';

import './index.scss';

const filter = createFilterOptions();

const getOptionSelected = (option, value) =>
  option.prefix === value.prefix && option.value === value.value;

class AppAutocomplete extends Component {
  state = {
    prefixValue: '',
    value: this.props.value || [],
    inputValue: '',
    open: false,
  };

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value)) {
      this.setState({
        value: this.props.value || [],
      });
    }
  }

  handleInputChange = (event) => {
    const { value } = event.target;
    const { prefixValue } = this.state;

    if (prefixValue && value.indexOf(prefixValue) !== 0) {
      this.setState({
        prefixValue: '',
      });
    }

    this.setState({
      inputValue: value,
    });
  };

  handleChange = (_event, newValue, reason) => {
    const { value: oldValue } = this.state;
    const { searchLabel, disableCustomLabel = false, onChange } = this.props;

    if (reason === 'clear') {
      const emptyValue = [];

      this.setState({
        inputValue: '',
        prefixValue: '',
        value: emptyValue,
      });
      onChange(emptyValue);

      return;
    }

    if (newValue.length < oldValue.length) {
      this.setState({
        value: newValue,
      });
      onChange(newValue);

      return;
    }

    const newPart = newValue[newValue.length - 1];
    let value;

    if (typeof newPart === 'string') {
      if (this.state.prefixValue || disableCustomLabel) {
        return;
      }

      const item = {
        title: `${searchLabel}: ${newPart}`,
        prefix: PREDEFINED_PREFIXES.search,
        value: newPart,
      };

      value = [...oldValue, item];
      this.setState({
        prefixValue: '',
        inputValue: '',
      });
    } else if (newPart) {
      if (newPart.prefix) {
        this.setState({
          inputValue: `${newPart.title}: `,
          prefixValue: newPart.title,
        });
      } else {
        value = [
          ...oldValue,
          {
            title: `${this.state.prefixValue}: ${newPart.title}`,
            prefix: this.state.prefixValue,
            value: newPart.value || newPart.title,
          },
        ];
        this.setState({
          inputValue: '',
          prefixValue: '',
        });
      }
    }

    if (value) {
      this.setState({
        value,
      });
      onChange(value);
    }
  };

  handleOpen = () => {
    this.setState({
      open: true,
    });
    if (!this.props.options) {
      this.props.onGetOptions();
    }
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { prefixValue, value, inputValue, open } = this.state;
    const {
      disabled = false,
      classes = {},
      limitTags,
      placeholder,
      placeholderWithValue,
      allValuesLabel,
      options,
      'data-test': dataTest,
      ListboxComponent,
      renderOption,
    } = this.props;
    const optionsLoading = !options && open;

    let activeOptions;

    if (prefixValue === '') {
      activeOptions = Object.keys(options || {}).map((optionKey) => ({
        title: optionKey,
        prefix: true,
      }));
    } else {
      activeOptions = options[prefixValue].map((option) => ({
        title: option,
      }));
    }

    const defaultRenderOption = (option) => (
      <Fragment>
        {option.title}
        {option.withDivider && <Divider absolute />}
      </Fragment>
    );

    return (
      <Autocomplete
        disabled={disabled}
        freeSolo
        multiple
        disableCloseOnSelect
        value={value}
        inputValue={inputValue}
        limitTags={limitTags}
        options={activeOptions}
        classes={{
          root: `autocomplete ${classes.root || ''}`,
          endAdornment: 'autocomplete__clear-icon',
          noOptions: 'autocomplete__no-options',
          option: 'autocomplete__option',
        }}
        data-test={dataTest}
        loading={optionsLoading}
        loadingText={i18n.t('general.controls.loading')}
        getOptionSelected={getOptionSelected}
        getOptionLabel={(option) => option.title}
        {...(ListboxComponent ? { ListboxComponent } : null)}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        onChange={this.handleChange}
        popupIcon={<Fragment />}
        renderInput={(params) => (
          <TextField
            {...params}
            classes={{
              root: 'autocomplete__text-field-root',
            }}
            InputProps={{
              ...params.InputProps,
              'data-test': `${dataTest}-input`,
              classes: {
                root: 'autocomplete__input-root',
                input: 'autocomplete__input-input',
                focused: 'autocomplete__input_focused',
                notchedOutline: 'autocomplete__input-outline',
              },
              startAdornment: (
                <Fragment>
                  <SearchIcon className="autocomplete__search-icon" />
                  {params.InputProps.startAdornment}
                </Fragment>
              ),
              ...(this.props.loading
                ? {
                    endAdornment: (
                      <CircularProgress
                        className="autocomplete__loader"
                        size={15}
                      />
                    ),
                  }
                : {}),
            }}
            placeholder={
              value.length === 0 ? placeholder : placeholderWithValue
            }
            onChange={this.handleInputChange}
            size="small"
            variant="outlined"
          />
        )}
        renderOption={renderOption ?? defaultRenderOption}
        filterOptions={(opts, params) => {
          const { maxTags = -1 } = this.props;

          if (value.length === maxTags) {
            return [];
          }

          if (!prefixValue) {
            return filter(opts, params);
          }

          let filtered = filter(opts, {
            ...params,
            // + 2 because of ': '
            inputValue: params.inputValue.slice(prefixValue.length + 2),
          });

          filtered = allValuesLabel
            ? [
                {
                  title: allValuesLabel,
                  value: PREDEFINED_VALUES.allValues,
                  withDivider: true,
                },
                ...filtered,
              ]
            : filtered;

          return filtered;
        }}
        renderTags={(tags, getCustomizedTagProps) =>
          tags.map((tag, index) => {
            const props = getCustomizedTagProps({ index });

            return (
              <Label
                classes={{
                  label: 'autocomplete__label',
                }}
                key={index}
                text={tag.title}
                {...props}
              />
            );
          })
        }
      />
    );
  }
}

export default AppAutocomplete;
