import React, { Suspense, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import AuthenticationRedirector from '../router/redirectors/Authentication';
import UserDataRedirector from '../router/redirectors/UserData';
import PricingRedirector from '../router/redirectors/Pricing';
import Loading from '../components/Loading';
import BackdropLoading from '../components/Loading/BackdropLoading';
import NotFound from '../components/NotFound';
import Link from '../components/Link';
import MapLegend from '../features/ui/fieldWorkflow/containers/Legend/Map';
import View3dLegend from '../features/ui/fieldWorkflow/containers/Legend/View3d';
import ToolsPanel from '../features/ui/fieldWorkflow/containers/ToolsPanel';
import DataLayersPanel from '../features/ui/fieldWorkflow/containers/DataLayersPanel';
import FieldDetails from '../features/ui/fieldWorkflow/containers/FieldDetailsPanel';
import { reset } from '../features/ui/fieldWorkflow/fieldWorkflowSlice';
import { getDocumentTitle } from '../helpers';
import { getRootLink, getFieldsLink, PAGES_ROOTS } from '../helpers/navigation';
import { prepareFieldsListSearchParams } from '../features/ui/fieldsList/helpers/functions/url';
import BaseTemplate from './BaseTemplate';
import FieldTitle from '../features/field/containers/FieldTitle';
import {
  selectNotFound,
  selectName,
  selectArea,
  selectIsFieldVamapsDatasetsPinsGroupsLoaded,
  selectIsSatelliteImagesLoaded,
  selectField,
  selectIsFieldHasNoAssets,
  selectIsInUnsupportedRegion,
  selectThreeDimensionalMap,
  selectFieldUuid,
  selectFarmUuid,
} from '../features/field/fieldSelectors';
import { selectLoading } from '../features/ui/fieldWorkflow/fieldWorkflowSelectors';
import {
  errorNotify,
  warningNotify,
  infoNotify,
} from '../features/notifications/helpers/functions/notify';
import { CustomError } from '../helpers/functions/utils/errorHandling';
import { AssetGroupType } from '../helpers/constants/entities/asset';
import getAssetByUuid from '../helpers/functions/entities/getAssetByUuid';
import useDidMount from '../hooks/useDidMount';
import FieldTab from '../features/ui/fieldWorkflow/helpers/constants/fieldTab';
import isEdit from '../helpers/functions/pages/isEdit';
import useNotify from '../features/notifications/hooks/useNotify';
import { isSameField } from '../features/field/helpers/functions/field';
import { useGetFarmQuery } from '../features/farms/farmsAPI';
import { resetOverlayLayers } from '../features/ui/overlayLayers/overlayLayersSlice';
import { resetFieldsJohnDeere } from '../features/jdFields/jdFieldsSlice';
import {
  selectFilter,
  selectLabelsExpanded,
} from '../features/ui/fieldsList/fieldsListSelectors';
import { resetStateExportJohnDeere } from '../features/ui/jdExport/jdExportSlice';
import useRouterParamsAsset from '../features/ui/fieldWorkflow/hooks/useRouterParamsAsset';
import NoSatelliteMonitoringMessage from '../features/ui/fieldWorkflow/containers/NoSatelliteMonitoringMessage';

const DataLayersMap = React.lazy(
  () => import('../features/ui/fieldWorkflow/containers/DataLayersMap'),
);
const View3D = React.lazy(
  () => import('../features/ui/fieldWorkflow/containers/View3D'),
);

const Field = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { fieldUuid: routerFieldUuid, itemUuid, assetGroup } = useParams();
  const [notified, setNotified] = useState(false);
  const [tab, setTab] = useState(FieldTab.dataLayers);
  const isDatasetsLoaded = useSelector(
    selectIsFieldVamapsDatasetsPinsGroupsLoaded,
  );
  const isSatellitesLoaded = useSelector(selectIsSatelliteImagesLoaded);
  const isItemExists = getAssetByUuid(itemUuid, useSelector(selectField));
  const notFound = useSelector(selectNotFound);
  const fieldUuid = useSelector(selectFieldUuid);
  const loading = useSelector(selectLoading);
  const fieldName = useSelector(selectName);
  const fieldArea = useSelector(selectArea);
  const farmUuid = useSelector(selectFarmUuid);
  const filter = useSelector(selectFilter);
  const labelsExpanded = useSelector(selectLabelsExpanded);
  const { data: farm } = useGetFarmQuery({ farmUuid });
  const isFieldHasNoAssets = useSelector(selectIsFieldHasNoAssets);
  const isInUnsupportedRegion = useSelector(selectIsInUnsupportedRegion);
  const is3DMapSelected = !!useSelector((state) =>
    selectThreeDimensionalMap(state, itemUuid),
  );

  useRouterParamsAsset();

  useEffect(() => {
    document.title = getDocumentTitle(fieldName);
  }, [fieldName]);

  useNotify({
    condition: isFieldHasNoAssets,
    notifyOnce: true,
    notifier: () =>
      infoNotify({
        message: t('create-field.notifications.field-saved', {
          name: fieldName,
        }),
      }),
  });

  useNotify({
    condition: isInUnsupportedRegion && routerFieldUuid === fieldUuid,
    notifyOnce: true,
    notifier: () =>
      warningNotify({
        message: t('field.notifications.no-satellite-monitoring-title'),
        messageElement: <NoSatelliteMonitoringMessage />,
      }),
  });

  useDidMount(() => () => {
    dispatch(reset());
    dispatch(resetOverlayLayers());
    dispatch(resetFieldsJohnDeere());
    dispatch(resetStateExportJohnDeere());
  });

  useEffect(() => {
    if (notified) {
      return;
    }

    if (!itemUuid) {
      return;
    }

    const datasetCondition =
      assetGroup !== AssetGroupType.satelliteImages && isDatasetsLoaded;
    const satelliteCondition =
      assetGroup === AssetGroupType.satelliteImages && isSatellitesLoaded;

    if (!isItemExists && (datasetCondition || satelliteCondition)) {
      setNotified(true);
      errorNotify({
        error: new CustomError('[Field] Resource not found'),
        message: t('field.not-found-resource'),
        dispatch,
      });
    }

    if (isDatasetsLoaded && isSatellitesLoaded) {
      setNotified(true);
    }
  }, [
    notified,
    assetGroup,
    itemUuid,
    isDatasetsLoaded,
    isSatellitesLoaded,
    isItemExists,
    dispatch,
    t,
  ]);

  const onBackButtonClick = () => {
    const searchParams = prepareFieldsListSearchParams({
      farmUuid: filter.farmUuid,
      autocomplete: filter.autocompleteValue,
      labelsExpanded,
    });

    history.push(getFieldsLink(searchParams));
  };

  let content = [
    <ToolsPanel key="tools-panel" tab={tab} onTabChange={setTab} />,
    <Fragment key="loading">{loading && <BackdropLoading />}</Fragment>,
  ];
  let headerProps = {
    text: <FieldTitle fieldArea={fieldArea} fieldName={fieldName} />,
    secondaryText: farm?.name,
    withBackAction: true,
  };

  if (isSameField({ uuid: fieldUuid }, { uuid: routerFieldUuid }) && notFound) {
    headerProps = {};
    content = [
      <NotFound
        key="panel"
        description={t('field.not-found-description')}
        link={
          <Link to={getRootLink(PAGES_ROOTS.fields)}>
            {t('field.not-found-link')}
          </Link>
        }
      />,
    ];
  } else if (tab === FieldTab.dataLayers || isEdit(location.search)) {
    content.push(
      <DataLayersPanel key="panel" />,
      <Suspense key="map" fallback={<Loading />}>
        {is3DMapSelected ? <View3D /> : <DataLayersMap />}
      </Suspense>,
      is3DMapSelected ? (
        <View3dLegend key="legend" />
      ) : (
        <MapLegend key="legend" />
      ),
    );
  } else if (tab === FieldTab.fieldDetails) {
    content.push(<FieldDetails key="panel" />);
  }

  return (
    <AuthenticationRedirector>
      <UserDataRedirector>
        <PricingRedirector>
          <BaseTemplate
            headerProps={{
              ...headerProps,
              onBackButtonClick,
            }}
          >
            {content}
          </BaseTemplate>
        </PricingRedirector>
      </UserDataRedirector>
    </AuthenticationRedirector>
  );
};

export default Field;
