import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import PopupHeader from '../../../../../../components/Popups/PopupHeader';
import Button from '../../../../../../components/Button';
import { get3dMapLink } from '../../../../../../helpers/navigation';
import { reset } from '../../../createThreeDMapSlice';

import './index.scss';

const Save3dMap = ({ name, map3dUuid, onCancel = () => {} }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { farmUuid, fieldUuid } = useParams();

  const onConfirm = (action) => {
    if (action === 'create-another') {
      dispatch(reset());
    } else if (action === 'go-3d-map') {
      history.push(get3dMapLink(farmUuid, fieldUuid, map3dUuid));
    }

    onCancel();
  };

  return (
    <Dialog
      open
      classes={{
        root: 'save-3d-map-dialog',
        paper: 'save-3d-map-dialog__paper',
      }}
    >
      <PopupHeader
        title={t('general.popups.save-3d-map.title')}
        onCancel={onCancel}
      />
      <DialogContent
        classes={{
          root: 'save-3d-map-dialog__content',
        }}
      >
        <DialogContentText component="span" color="textPrimary" variant="body2">
          <Trans i18nKey="general.popups.save-3d-map.content-text" name={name}>
            text{' '}
            <span className="save-3d-map-dialog__text_highlighted">
              {{ name }}
            </span>{' '}
            text
          </Trans>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="save-3d-map-dialog__actions">
        <Button onClick={() => onConfirm('create-another')} variant="outlined">
          {t('general.popups.create-another-one')}
        </Button>
        <Button
          onClick={() => onConfirm('go-3d-map')}
          variant="contained"
          color="primary"
        >
          {t('general.popups.save-3d-map.actions.go-to-3d-map')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Save3dMap;
