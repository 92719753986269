import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import AuthLogo from './authLogo.svg';

const useStyles = makeStyles((theme) => ({
  link: {
    width: '240px',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
  logo: {
    width: '100%',
  },
}));

const AuthenticationHeader = () => {
  const classes = useStyles();

  return (
    <Link className={classes.link} to="/">
      <AuthLogo className={classes.logo} />
    </Link>
  );
};

export default AuthenticationHeader;
