export const hasConnectionLinkToOrganization = (organization) =>
  !!(organization.links || []).find((link) => link.rel === 'connections');

export const prepareConnectionLinkToOrganization = (organization) => {
  const connectionLink = (organization.links || []).find(
    (link) => link.rel === 'connections',
  );
  return connectionLink
    ? `${connectionLink.uri}&redirect_uri=${encodeURIComponent(window.location.href)}`
    : '';
};
