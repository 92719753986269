import { API, graphqlOperation } from '@aws-amplify/api';

import getColorSchemasQuery from './graphql/queries/getColorSchemas.gql';
import addSchemaMutation from './graphql/mutations/addColorSchemas.gql';
import saveColorSchemaMutation from './graphql/mutations/saveColorSchema.gql';
import deleteColorSchemaMutation from './graphql/mutations/deleteColorSchemas.gql';

export const getAll = async () =>
  API.graphql(graphqlOperation(getColorSchemasQuery)).then(
    ({ data }) => data.getUserData.colorSchemas,
  );

export const add = async (schema) =>
  API.graphql(
    graphqlOperation(addSchemaMutation, {
      input: {
        schemas: [schema],
      },
    }),
  ).then(({ data }) => data.addColorSchemas[0]);

export const update = async (schema) =>
  API.graphql(
    graphqlOperation(saveColorSchemaMutation, {
      input: schema,
    }),
  ).then(({ data }) => data.saveColorSchema);

export const remove = async (uuid) =>
  API.graphql(
    graphqlOperation(deleteColorSchemaMutation, {
      input: {
        schemas: [{ uuid }],
      },
    }),
  ).then(({ data }) => data.deleteColorSchemas);
