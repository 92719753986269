import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import InputsListPopup from '../../../../../components/Popups/InputsList';
import { addUsersToOrganization } from '../../userOrganizationsSlice';

const InviteUserPopup = ({ name, uuid, onConfirm, ...popup }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleConfirmClick = (emails) => {
    dispatch(
      addUsersToOrganization({
        organizationUuid: uuid,
        emails,
      }),
    ).then(() => {
      onConfirm();
    });
  };

  return (
    <InputsListPopup
      {...popup}
      inputType="email"
      title={t('general.popups.invite-user.title', { name })}
      description={t('general.popups.invite-user.description')}
      placeholder={t('general.popups.invite-user.placeholder')}
      addItemLabel={t('general.popups.invite-user.add-email')}
      confirmLabel={t('general.popups.invite-user.invite')}
      onConfirm={handleConfirmClick}
    />
  );
};

export default InviteUserPopup;
