import isBefore from 'date-fns/isBefore';

import ApplicationStatus from '../constants/applicationStatus';
import { getOrderEndDate } from '../../../../user/helpers/functions/order';
import { UserOrder } from '../../../../user/types/order';

const calculateApplicationStatus = (
  paidOrder: UserOrder,
  hasGroupPermission: boolean,
) => {
  const paidOrderEndDate = getOrderEndDate(paidOrder);
  const today = new Date();

  if (
    !paidOrderEndDate ||
    !hasGroupPermission ||
    !isBefore(today, paidOrderEndDate)
  ) {
    return ApplicationStatus.blocked;
  }

  return ApplicationStatus.available;
};

export default calculateApplicationStatus;
