query getMinifiedSatelliteImages(
  $farmUuids: [ID]
  $fieldUuids: [ID]
  $before: AWSDate
  $after: AWSDate
) {
  getFarms(farmUuids: $farmUuids) {
    fields(fieldUuids: $fieldUuids) {
      satelliteImages(
        threshold: 0
        geoMapType: ORIGIN
        before: $before
        after: $after
      ) {
        satelliteImage {
          uuid
          provider
          acquisitionDate
        }
        ndviAverage
      }
    }
  }
}
