import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Stepper from '@material-ui/core/Stepper';
import StepComponent from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Typography from '@material-ui/core/Typography';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { getComponent } from '../../helpers/markup';
import Button from '../Button';
import Tooltip from '../Tooltip';

import './index.scss';

export interface Step {
  name: string;
  description?: ReactNode;
  id: string;
  component: FunctionComponent<{
    steps: Step[];
    stepIndex: number;
    step: string;
  }>;
}

enum ComponentKey {
  topRight = 'top-right',
  toolsPanel = 'tools-panel',
  tabs = 'tabs',
  loader = 'loader',
  panel = 'panel',
  map = 'map',
  mapTitle = 'map-title',
  previews = 'previews',
}

type ElementWithKey = ReactElement<{ key: ComponentKey }>;

export default function EnhancedStepper({
  steps,
  activeStep,
  children,
  classes,
  backDisabled,
  nextDisabled,
  nextLabel,
  onClickNext = () => {},
  onClickBack = () => {},
}: {
  steps: Step[];
  activeStep: number;
  children: ElementWithKey | ElementWithKey[];
  classes?: {
    root?: string;
    backButton?: string;
    nextButton?: string;
    map?: string;
  };
  backDisabled?: boolean;
  nextDisabled?: boolean;
  nextLabel?: string;
  onClickNext: () => void;
  onClickBack?: () => void;
}) {
  const { t } = useTranslation();

  const topRight = getComponent(ComponentKey.topRight, children);
  const toolsPanel = getComponent(ComponentKey.toolsPanel, children);
  const tabs = getComponent(ComponentKey.tabs, children);
  const loader = getComponent(ComponentKey.loader, children);
  const panel = getComponent(ComponentKey.panel, children);
  const map = getComponent(ComponentKey.map, children);
  const mapTitle = getComponent(ComponentKey.mapTitle, children);
  const previews = getComponent(ComponentKey.previews, children);

  const backDisabledDefault = activeStep - 1 < 0;

  return (
    <div className={clsx('stepper', classes?.root)}>
      <div className="stepper__header">
        <Button
          className={classes?.backButton}
          variant="outlined"
          disabled={backDisabledDefault || backDisabled}
          onClick={onClickBack}
        >
          {t('general.stepper.back')}
        </Button>
        <div className="stepper__steps-container">
          <Stepper
            className="stepper__steps"
            activeStep={activeStep}
            connector={<StepConnector className="stepper__connector" />}
          >
            {steps.map((step) => (
              <StepComponent key={step.id}>
                <StepLabel
                  classes={{
                    label: 'stepper__step-label-label',
                  }}
                >
                  <span>{step.name}</span>
                  {step.description && (
                    <Tooltip
                      classes={{
                        tooltip: 'stepper__step-tooltip',
                        label: 'stepper__step-tooltip-label',
                      }}
                      interactive
                      placement="bottom"
                      tooltip={step.description}
                    >
                      <InfoOutlinedIcon fontSize="small" />
                    </Tooltip>
                  )}
                </StepLabel>
              </StepComponent>
            ))}
          </Stepper>
        </div>
        {topRight || (
          <Button
            className={classes?.nextButton}
            variant="contained"
            color="primary"
            onClick={onClickNext}
            disabled={nextDisabled}
          >
            {nextLabel || t('general.stepper.next')}
          </Button>
        )}
      </div>
      {toolsPanel}
      {tabs}
      <div className="step__content">
        {loader && <div className="step__content-loader">{loader}</div>}
        {panel}
        {map && (
          <div className={clsx('step__content-map', classes?.map)}>
            {mapTitle && (
              <Typography className="step__content-map-title">
                {mapTitle}
              </Typography>
            )}
            {map}
          </div>
        )}
      </div>
      {previews}
    </div>
  );
}
