import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';

import SatelliteProviderLabel from '../../../../features/satelliteImages/components/SatelliteProviderLabel';
import Link from '../../../Link';
import { prettifyNumber } from '../../../../helpers/markup';

import './index.scss';

const SatelliteGallery = ({ images = [], classes = {} }) => {
  const { t } = useTranslation();

  return (
    <div className={clsx('satellite-gallery', classes.root)}>
      <Typography
        variant="subtitle2"
        classes={{
          root: 'title',
        }}
      >
        {t('general.controls.map-legend.satellite-images')}
      </Typography>
      <div className="container">
        <span className="block__header text_secondary">
          {t('general.controls.map-legend.data-layers.satellite-images', {
            amount: images.length,
          })}
          :
        </span>
        <ul className="block__list">
          {images.map((image, index) => (
            <li key={index} className="block__list-item">
              <Link to={image.link} className="text" target="_blank">
                {image.date}
              </Link>
              {image.ndvi && (
                <span className="text_secondary">
                  {' '}
                  (ndvi {prettifyNumber(image.ndvi, 3)})
                </span>
              )}
              {image.provider && (
                <span>
                  {' '}
                  <SatelliteProviderLabel provider={image.provider} />
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SatelliteGallery;
