import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import Tooltip from '../../../../../../components/Tooltip';
import Link from '../../../../../../components/Link';
import { PAGES_ROOTS, getRootLink } from '../../../../../../helpers/navigation';

import './index.scss';

interface ExportToJohnDeereTooltipProps {
  defaultMessage?: string;
  jdProfileIsHealth: boolean;
  jdProfileIsAuthorized: boolean;
  isSynchronizedJohnDeereField: boolean;
  jdWorkPlanExportAvailable?: boolean;
  assetHasNoRatesAssigned?: boolean;
}

const getJDTooltipMessage = ({
  defaultMessage,
  jdProfileIsHealth,
  jdProfileIsAuthorized,
  isSynchronizedJohnDeereField,
  jdWorkPlanExportAvailable = true,
  assetHasNoRatesAssigned = false,
}: ExportToJohnDeereTooltipProps) => {
  if (!jdProfileIsAuthorized) {
    return (
      <Trans i18nKey={'integrations.jd.not-authorized'}>
        Text <Link to={getRootLink(PAGES_ROOTS.userIntegrationsJD)}>text</Link>
      </Trans>
    );
  }
  if (!jdProfileIsHealth) {
    return i18n.t('integrations.jd.notifications.health-check-failed');
  }
  if (!isSynchronizedJohnDeereField) {
    return i18n.t('integrations.jd.field-not-synchronized');
  }
  if (!jdWorkPlanExportAvailable) {
    return (
      <Trans i18nKey={'integrations.jd.update-access-message'}>
        Text <Link to={getRootLink(PAGES_ROOTS.userIntegrationsJD)}>text</Link>
      </Trans>
    );
  }
  if (assetHasNoRatesAssigned) {
    return i18n.t('integrations.jd.map-has-no-rates');
  }

  return defaultMessage || null;
};

const ExportToJohnDeereTooltip = (props: ExportToJohnDeereTooltipProps) => {
  const message = getJDTooltipMessage(props);

  return message ? (
    <Tooltip
      interactive={true}
      classes={{
        tooltip: 'export-to-john-deere-tooltip',
        label: 'export-to-john-deere-tooltip__label',
      }}
      tooltip={message}
    >
      <InfoIcon className="export-to-john-deere-tooltip__icon" />
    </Tooltip>
  ) : null;
};

export default ExportToJohnDeereTooltip;
