import React, { ReactElement, Ref, forwardRef } from 'react';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';

import Button from '../../../../components/Button';
import NotificationType from '../../helpers/constants/types';
import { NotificationAction } from '../../types/action';

import './index.scss';

interface NotificationProps extends CustomContentProps {
  message: string;
  messageElement?: ReactElement;
  action?: NotificationAction[];
  onClose?: () => void;
}

const Notification = (props: NotificationProps, ref: Ref<HTMLDivElement>) => {
  const { variant, message, messageElement, action, className, id, onClose } =
    props;
  const { closeSnackbar } = useSnackbar();

  let icon = null;

  switch (variant) {
    case NotificationType.success:
      icon = (
        <CheckIcon
          className={clsx(
            'notification__icon',
            `notification__icon_${variant}`,
          )}
        />
      );
      break;
    case NotificationType.error:
      icon = (
        <HighlightOffIcon
          className={clsx(
            'notification__icon',
            `notification__icon_${variant}`,
          )}
        />
      );
      break;
    case NotificationType.warning:
      icon = (
        <ErrorOutlineIcon
          className={clsx(
            'notification__icon',
            `notification__icon_${variant}`,
          )}
        />
      );
      break;
    case NotificationType.info:
    case NotificationType.processing:
      icon = (
        <InfoIcon
          className={clsx(
            'notification__icon',
            `notification__icon_${variant}`,
          )}
        />
      );
      break;
    default:
      break;
  }

  const handleClose = () => {
    onClose?.();
    closeSnackbar(id);
  };

  return (
    <>
      <SnackbarContent
        ref={ref}
        role="alert"
        className={clsx('notification', `notification_${variant}`, className)}
      >
        {icon}
        <div className="notification__content">
          <Typography
            variant="body1"
            classes={{
              root: 'notification__text',
            }}
            title={message}
          >
            {messageElement ?? message}
          </Typography>
          {!!action?.length && (
            <div className="notification__actions">
              {action.map(({ href, endIcon, handler, title }, ind) => (
                <Button
                  key={ind}
                  classes={{
                    root: 'notification__action-btn',
                  }}
                  variant="outlined"
                  href={href}
                  endIcon={endIcon}
                  onClick={handler}
                  {...(href ? { target: '_blank' } : {})}
                >
                  {title}
                </Button>
              ))}
            </div>
          )}
        </div>
        {variant !== NotificationType.processing && (
          <IconButton
            classes={{
              sizeSmall: clsx(
                'notification__close-btn',
                `notification__close-btn_${variant}`,
              ),
            }}
            size="small"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </SnackbarContent>
    </>
  );
};

export default forwardRef(Notification);
