import React, { Fragment } from 'react';

import ZonesMapTable from '../../Controls/ZonesMapTable';
import Overview from '../../Controls/Overview';

const Map3DLegendGroup = ({ zonesMapTable, overview, shapeParameters }) => (
  <Fragment>
    <ZonesMapTable {...zonesMapTable} />
    <Overview {...overview} />
    <Overview {...shapeParameters} />
  </Fragment>
);

export default Map3DLegendGroup;
