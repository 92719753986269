import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import ButtonMenu from '../../../../../../components/ButtonMenu';
import Link from '../../../../../../components/Link';
import { getI18nAreaUnit } from '../../../../../../helpers';
import { getFieldLink } from '../../../../../../helpers/navigation';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../app/store/helpers/functions';
import {
  selectFarmEntity,
  selectFieldEntity,
} from '../../../../../assets/assetsSelectors';
import { selectAreaUnit } from '../../../../../user/userSelectors';
import { prettifyNumber } from '../../../../../../helpers/markup';
import { ButtonMenuItem } from '../../../../../../components/ButtonMenu/types';
import { unselectField } from '../../../../batchAnalytics/batchAnalyticsSlice';

import './index.scss';

export default function PreviewTileHeader({
  fieldUuid,
}: {
  fieldUuid: string;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const areaUnit = useAppSelector(selectAreaUnit);
  const fieldEntity = useAppSelector((state) =>
    selectFieldEntity(state, fieldUuid),
  );
  const farmEntity = useAppSelector((state) =>
    selectFarmEntity(state, fieldEntity?.farmUuid || ''),
  );

  const formattedArea = prettifyNumber(fieldEntity?.area);

  const handleMenuItemClick = (item: ButtonMenuItem) => {
    if (item.id === 'delete') {
      dispatch(unselectField(fieldUuid));
    }
  };

  return (
    <div className="preview-tile-header-wrapper">
      <div className="preview-tile-header">
        <Link
          className="preview-tile-header__field"
          target="_blank"
          title={fieldEntity?.name}
          to={getFieldLink(farmEntity?.uuid, fieldEntity?.uuid)}
        >
          {fieldEntity?.name}
        </Link>
        <span className="preview-tile-header__farm" title={farmEntity?.name}>
          | {farmEntity?.name}
        </span>
        <ButtonMenu
          classes={{
            iconButton: {
              root: 'preview-tile-header__button-menu',
            },
          }}
          isIconButton
          size="small"
          items={[{ id: 'delete', label: t('general.controls.delete') }]}
          onItemClick={(item) => handleMenuItemClick(item)}
        >
          <MoreVertIcon fontSize="small" />
        </ButtonMenu>
      </div>
      <Typography className="preview-tile-subheader">
        <span className="preview-tile-subheader__label">
          {t('general.shared.area-label')}:
        </span>
        <span className="preview-tile-subheader__value">
          {formattedArea} {getI18nAreaUnit(areaUnit)}
        </span>
      </Typography>
    </div>
  );
}
