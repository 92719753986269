import { useEffect } from 'react';

import { useAppDispatch } from '../../../../app/store/helpers/functions';
import useVamap from '../../../field/hooks/useVamap';
import { getZonesHexColorCodes } from '../../../../helpers/functions/entities/vectorAnalysisMap';
import { initDistributionData } from '../zonesMapSlice';

/**
 * Initialize distribution data based on zones map features and geo maps.
 */
export default function useVamapDistributionData({
  farmUuid,
  fieldUuid,
  uuid,
}: {
  farmUuid: string;
  fieldUuid: string;
  uuid: string;
}) {
  const dispatch = useAppDispatch();
  const { vamap } = useVamap(farmUuid, fieldUuid, uuid);

  useEffect(() => {
    if (!vamap?.zonesMapGeojson) return;

    const { features } = vamap.zonesMapGeojson;
    const zoneColors = getZonesHexColorCodes(vamap.geoMaps);

    dispatch(initDistributionData({ features, zoneColors }));
  }, [dispatch, vamap?.zonesMapGeojson, vamap?.geoMaps]);
}
