import i18n from 'i18next';

import CommonSteps from '../constants/commonSteps';
import { Step } from '../../../../../components/Stepper';
import SelectFieldWorkflow from '../../containers/Stepper/Steps/SelectField';

const getCommonSteps = (): Step[] => [
  {
    name: i18n.t('zones-ops.common.steps.select-field.title'),
    description: i18n.t('zones-ops.common.steps.select-field.description'),
    id: CommonSteps.selectField,
    component: SelectFieldWorkflow,
  },
];

export default getCommonSteps;
