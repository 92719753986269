import { MessageKey } from '../../../../asyncOperations/types/messageKey';

export const MESSAGE_KEY_TO_I18N_KEY_MAP: Record<MessageKey, string> = {
  AllPixelsAreCloudyOrCorrupted:
    'operations.operation-item.messages.all-pixels-are-cloudy-or-corrupted',
  DatasetContainsCorruptedData:
    'operations.operation-item.messages.dataset-contains-corrupted-data',
  DatasetContainsCorruptedGeometry:
    'operations.operation-item.messages.dataset-contains-corrupted-geometry',
  DatasetIsEmpty: 'operations.operation-item.messages.dataset-is-empty',
  DatasetWasAlreadyMappedToField:
    'operations.operation-item.messages.dataset-was-already-mapped-to-field',
  DatasetWasNotMappedToField:
    'operations.operation-item.messages.dataset-was-not-mapped-to-field',
  DatasetWasUploadedAutomatically:
    'operations.operation-item.messages.dataset-was-uploaded-automatically',
  EquationExecutionError:
    'operations.operation-item.messages.equation-execution-error',
  ElevationDataDoesNotExist:
    'operations.operation-item.messages.elevation-data-does-not-exist',
  FieldDoesNotExist: 'operations.operation-item.messages.field-does-not-exist',
  FieldRegistrationWasNotFullyCompleted:
    'operations.operation-item.messages.field-registration-was-not-fully-completed',
  FileIsNotReadable: 'operations.operation-item.messages.file-is-not-readable',
  FileIsNotZipArchive:
    'operations.operation-item.messages.file-is-not-zip-archive',
  InfrastructureError:
    'operations.operation-item.messages.infrastructure-error',
  MetadataJsonIsCorrupted:
    'operations.operation-item.messages.metadata-json-is-corrupted',
  MetadataJsonIsNotUtf8Compatible:
    'operations.operation-item.messages.metadata-json-is-not-utf8-compatible',
  PrecisionError: 'operations.operation-item.messages.precision-error',
  StatisticsWasNotCalculated:
    'operations.operation-item.messages.statistics-was-not-calculated',
  UnprocessedAttribute:
    'operations.operation-item.messages.unprocessed-attribute',
  UnsupportedDateFormat:
    'operations.operation-item.messages.unsupported-date-format',
  FarmHasNoFields: 'operations.operation-item.messages.farm-has-no-fields',
  DatasetWasUploadedManually:
    'operations.operation-item.messages.dataset-was-uploaded-manually',
  Unknown: 'operations.operation-item.messages.unknown',
};

export default { MESSAGE_KEY_TO_I18N_KEY_MAP };
