import React from 'react';
import { useSelector } from 'react-redux';

import EquationMapTable from '../../../../../../components/Views/common/EquationMapTable';
import ZonesMapResultsPlaceholder from '../../../../../../components/ZonesMapResultsPlaceholder';
import { getPixelsGeoMap } from '../../../../../../helpers/functions/entities/equationMap';
import { selectGeneratedEquationMap } from '../../../../../createAnalysis/createAnalysisSelectors';

import './index.scss';

const Body = () => {
  const generatedEquationMap = useSelector(selectGeneratedEquationMap);
  const pixels = getPixelsGeoMap(generatedEquationMap);

  return (
    <div className="equation-based-results-panel">
      {pixels ? (
        <EquationMapTable pixels={pixels} />
      ) : (
        <ZonesMapResultsPlaceholder />
      )}
    </div>
  );
};

export default Body;
