import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import Button from '../../../../../components/Button';
import PopupHeader from '../../../../../components/Popups/PopupHeader';
import useCurrentPage from '../../../../../hooks/useCurrentPage';
import AmplitudeAnalytics from '../../../../../helpers/classes/amplitudeAnalytics';

import './index.scss';

const ExportFieldsToJohnDeerePopup = ({
  fieldNames = [],
  onCancel = () => {},
  onConfirm = () => {},
}: {
  fieldNames: string[];
  onCancel: () => void;
  onConfirm: () => void;
}) => {
  const { t } = useTranslation();
  const page = useCurrentPage();

  useEffect(() => {
    if (page) {
      AmplitudeAnalytics.trackExportToJDPopupOpened({
        page,
        type: 'asFiles',
      });
    }
  }, [page]);

  const handleConfirm = () => {
    AmplitudeAnalytics.trackBatchEntitiesExportedToJD({
      page,
      entityType: 'field',
      exportType: 'asFiles',
      entitiesAmount: fieldNames.length,
    });
    onConfirm();
  };

  return (
    <Dialog open>
      <PopupHeader
        title={t('field-profiler.fields-list.table-panel.export-to-JD')}
        onCancel={onCancel}
      />
      <DialogContent>
        <DialogContentText
          component="div"
          classes={{
            root: 'export-fields-to-JD-popup__content-text',
          }}
        >
          <span>
            {t('general.popups.export-fields-to-john-deere.description')}
          </span>
          <ul className="export-fields-to-JD-popup__fields-list">
            {fieldNames.map((name, fieldIndex) => (
              <li key={fieldIndex}>{`- ${name}`}</li>
            ))}
          </ul>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        classes={{
          root: 'export-fields-to-JD-popup__actions',
        }}
      >
        <Button variant="outlined" onClick={onCancel}>
          {t('general.controls.cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={handleConfirm}>
          {t('general.controls.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportFieldsToJohnDeerePopup;
