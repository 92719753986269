import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import Button from '../../../../../../../components/Button';
import RadioGroup from '../../../../../../../components/RadioGroup';
import ImportType from '../../../../helpers/constants/importType';
import AreaLimitPopupPanel from '../../../../components/AreaLimitPopupPanel';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../app/store/helpers/functions';
import { getUserUnitArea } from '../../../../../../../helpers';
import { sumBigNumbers } from '../../../../../../../helpers/functions/utils/number';
import isValidSynchronizedFieldJohnDeereAccount from '../../../../../../jdFields/helpers/functions/isValidSynchronizedFieldJohnDeereAccount';
import {
  selectAreaUnit,
  selectMaxArea,
  selectTotalArea,
} from '../../../../../../user/userSelectors';
import {
  selectSelectedFields,
  selectImportType,
} from '../../../../jdImportSelectors';
import { setImportType } from '../../../../jdImportSlice';

import './index.scss';

export default function SelectTypeStep({
  onCancel,
  onNext,
  onConfirm,
}: {
  onCancel: () => void;
  onNext: () => void;
  onConfirm: () => void;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const areaUnit = useAppSelector(selectAreaUnit);
  const maxArea = useAppSelector(selectMaxArea);
  const totalArea = useAppSelector(selectTotalArea);
  const selectedFields = useAppSelector(selectSelectedFields);
  const importType = useAppSelector(selectImportType);

  const fieldsAreaToImport = useMemo(() => {
    const notImportedFields = selectedFields.filter(
      // @ts-expect-error
      (field) => !isValidSynchronizedFieldJohnDeereAccount(field.synchronized),
    );

    return notImportedFields.reduce(
      // @ts-expect-error
      (acc, field) =>
        sumBigNumbers(
          acc,
          getUserUnitArea(field.area, areaUnit, field.areaUnit),
        ),
      0,
    );
  }, [areaUnit, selectedFields]);

  const usedArea = totalArea + fieldsAreaToImport;
  const availableArea = maxArea - totalArea > 0 ? maxArea - totalArea : 0;
  const areaLimitReached = usedArea > maxArea;
  const importWithFields = importType !== ImportType.fieldOperationFiles;
  const importDisabled = areaLimitReached && importWithFields;

  const handleImportTypeChange = (value: ImportType) => {
    dispatch(setImportType({ importType: value }));
  };

  let nextLabel = t('general.stepper.next');
  let nextHandler = onNext;

  if (importType === ImportType.fieldBoundaries) {
    nextLabel = t('general.popups.done');
    nextHandler = onConfirm;
  }

  return (
    <Fragment>
      <DialogContent
        classes={{
          root: clsx('jd-import-settings-select-filters', 'select-type-popup'),
        }}
      >
        {importWithFields && (
          <AreaLimitPopupPanel
            areaToImport={fieldsAreaToImport}
            availableArea={availableArea}
            areaLimitReached={areaLimitReached}
            areaUnit={areaUnit}
          />
        )}
        <Typography variant="body2">
          {t('upload-data-john-deere.import-settings.select-type.description')}
        </Typography>
        <RadioGroup
          options={[
            {
              value: ImportType.fieldBoundaries,
              title: t(
                'upload-data-john-deere.import-settings.select-type.types.field-boundaries.label',
              ),
              description: t(
                'upload-data-john-deere.import-settings.select-type.types.field-boundaries.description',
              ),
            },
            {
              value: ImportType.dataFiles,
              title: t(
                'upload-data-john-deere.import-settings.select-type.types.data-files.label',
              ),
              description: t(
                'upload-data-john-deere.import-settings.select-type.types.data-files.description',
              ),
            },
            {
              value: ImportType.fieldOperationFiles,
              title: t(
                'upload-data-john-deere.import-settings.select-type.types.field-operation-files.label',
              ),
              description: t(
                'upload-data-john-deere.import-settings.select-type.types.field-operation-files.description',
              ),
            },
          ]}
          value={importType}
          onChange={handleImportTypeChange}
        />
      </DialogContent>
      <DialogActions className="jd-import-settings-popup__actions">
        <Button variant="outlined" onClick={onCancel}>
          {t('general.controls.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={importDisabled}
          onClick={nextHandler}
        >
          {nextLabel}
        </Button>
      </DialogActions>
    </Fragment>
  );
}
