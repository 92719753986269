import React from 'react';

import VamapTypeLabel from '../../../../../../components/VamapTypeLabel';
import type { PinsGroup } from '../../../../../pins/types';

import './index.scss';

export default function PinsGroupItem({ pinsGroup }: { pinsGroup: PinsGroup }) {
  return (
    <div className="pins-group-item">
      <span className="pins-group-item__name">{pinsGroup.name}</span>
      {pinsGroup.entity && <VamapTypeLabel entity={pinsGroup.entity} />}
    </div>
  );
}
