import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';

import { selectProcessingPanelFiles } from '../../../uploadDataSelectors';
import UploadFilesPopupFileItem from '../UploadFilesPopupFileItem';

import './index.scss';

export default function UploadFilesPopupTable() {
  const { t } = useTranslation();
  const files = useSelector(selectProcessingPanelFiles);

  return (
    <Table className="upload-files-popup-table">
      <TableHead className="upload-files-popup-table__header">
        <TableRow>
          <TableCell className="upload-files-popup-table__cell">
            {t('upload-data.data-table.file-name')}
          </TableCell>
          <TableCell colSpan={2} className="upload-files-popup-table__cell">
            {t('upload-data.data-table.data-type')}
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {files.map((file, index) => (
          <UploadFilesPopupFileItem key={index} file={file} />
        ))}
      </TableBody>
    </Table>
  );
}
