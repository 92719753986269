query getVarietiesJohnDeere($varietyFilter: ProductFilterJohnDeere) {
  getVarietiesJohnDeere(varietyFilter: $varietyFilter) {
    varieties {
      id
      name
      cropName
    }
    totalCount
  }
}
