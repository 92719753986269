import React from 'react';
import { useSelector } from 'react-redux';

import { selectPermissionedOrganizationAdmin } from '../../../../user/userSelectors';
import AdminPanel from './Admin';
import UserPanel from './User';

const UserOrganizationPanel = () => {
  const isAdmin = useSelector(selectPermissionedOrganizationAdmin);

  const Component = isAdmin ? AdminPanel : UserPanel;

  return <Component />;
};

export default UserOrganizationPanel;
