import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { BaseTextFieldProps } from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

import './index.scss';

export default function EndAdornment({
  type,
  endAdornment,
  value,
  onEndAdornmentClick,
}: {
  type?: BaseTextFieldProps['type'];
  endAdornment?: ReactNode;
  value?: string;
  onEndAdornmentClick?: () => void;
}) {
  if (type === 'search') {
    return (
      <InputAdornment
        position="end"
        classes={{
          root: clsx('textfield-end-adornment__clear-icon', {
            'textfield-end-adornment__clear-icon_visible': value?.length,
          }),
        }}
      >
        <IconButton size="small" onClick={onEndAdornmentClick}>
          <ClearIcon fontSize="small" />
        </IconButton>
      </InputAdornment>
    );
  }

  if (endAdornment) {
    return <InputAdornment position="end">{endAdornment}</InputAdornment>;
  }

  return null;
}
