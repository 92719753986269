import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import Typography from '@material-ui/core/Typography';

import Button from '../../../../../../../components/Button';
import { getAuthLink } from '../../../../../../../helpers/navigation';
import { View } from '../../../../helpers/constants/authentication';
import { getDemoFormLink } from '../../../../../../../helpers/functions/utils/appConfig';
import { prepareEmailAttribute } from '../../../../helpers/functions/authentication';
import { captureException } from '../../../../../../../helpers/functions/utils/errorHandling';

import '../../../../components/common/index.scss';

const ForgotPasswordSentPanel = () => {
  const { state: locationState } = useLocation();
  const [resendClicked, setResendClicked] = useState(false);
  const { email } = locationState as { email: string };

  const onActionClick = () => {
    const preparedEmail = prepareEmailAttribute(email);

    setResendClicked(true);
    Auth.forgotPassword(preparedEmail).catch((error) => {
      captureException({ error });
    });
  };

  return (
    <div className="authentication-panel">
      <Typography
        classes={{
          root: 'authentication-panel__header',
        }}
        variant="h1"
      >
        Password Recovery
      </Typography>
      <Typography
        classes={{
          root: 'authentication-panel__tip',
        }}
      >
        We&apos;ve sent instructions to {email}. If you didn&apos;t get the
        email, ask to resend it.
      </Typography>
      <Typography
        classes={{
          root: 'authentication-panel__tip',
        }}
      >
        Haven&apos;t received our email? It might be in your spam/junk folder.
      </Typography>
      <Button
        className="authentication-panel__button"
        variant="contained"
        color="primary"
        component={Link}
        to={getAuthLink(View.logIn)}
      >
        Back to Log in
      </Button>
      {resendClicked && (
        <Typography
          classes={{
            root: 'authentication-panel__tip',
          }}
        >
          If you still haven&apos;t received the email, please
          <a
            className="sign-up-sent-panel__link link"
            target="_blank"
            href={getDemoFormLink()}
            rel="noreferrer"
          >
            contact us
          </a>
          .
        </Typography>
      )}
      <Button className="link" onClick={onActionClick}>
        Resend the recovery instructions again
      </Button>
    </div>
  );
};

export default ForgotPasswordSentPanel;
