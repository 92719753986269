import React, { KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

import Textarea from '../Textarea';
import AntSwitch from '../AntSwitch';
import Button from '../Button';

import './index.scss';

export default function CodeEditor({
  title,
  placeholder,
  value = '',
  testRunDisabled,
  fullScreen,
  numpy,
  onChange,
  onTestRunClick,
}: {
  title: string;
  placeholder: string;
  value: string;
  testRunDisabled: boolean;
  fullScreen: {
    mode: boolean;
    onClick: () => void;
  };
  numpy: {
    mode: boolean;
    onClick: () => void;
  };
  onChange: (v: string) => void;
  onTestRunClick: () => void;
}) {
  const { t } = useTranslation();

  const handleChange = (equation: string) => {
    const formattedValue = equation.replace(/"/g, "'");
    onChange(formattedValue);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Tab') {
      event.preventDefault();

      const target = event.target as HTMLTextAreaElement;
      const start = target.selectionStart;
      const end = target.selectionEnd;
      const TAB = '    ';
      const newStart = start + TAB.length;

      // put caret at right position again
      target.selectionStart = newStart;
      target.selectionEnd = newStart;
      onChange(
        `${target.value.substring(0, start)}${TAB}${target.value.substring(end)}`,
      );
    }
  };

  const headerContent = (
    <div className="code-editor__header_right">
      <Button
        className="code-editor__header-button"
        variant="text"
        color="primary"
        size="small"
        disabled={testRunDisabled}
        onClick={onTestRunClick}
      >
        {t('general.popups.equation-test-run.title')}
      </Button>
      <div className="code-editor__header-delimiter"></div>
      <AntSwitch
        isChecked={numpy.mode}
        label={`${t('zones-ops.equation-based.steps.4.panel.numpy')}:`}
        labelPlacement="start"
        onValueChange={numpy.onClick}
      />
    </div>
  );

  const topRightTextareaContent = (
    <IconButton
      title={
        fullScreen.mode
          ? t(
              'zones-ops.equation-based.steps.4.panel.full-screen-button-title.reduce',
            )
          : t(
              'zones-ops.equation-based.steps.4.panel.full-screen-button-title.enlarge',
            )
      }
      size="small"
      onClick={fullScreen.onClick}
    >
      {fullScreen.mode ? <FullscreenExitIcon /> : <FullscreenIcon />}
    </IconButton>
  );

  return (
    <Textarea
      classes={{
        wrapper: 'code-editor',
        element: 'code-editor__element',
      }}
      header={{
        title,
        content: headerContent,
      }}
      placeholder={placeholder}
      value={value}
      content={{
        topRight: topRightTextareaContent,
      }}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />
  );
}
