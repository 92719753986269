import { CleaningType } from '../../../../../helpers/constants/ui';
import CleanTabSmartContent from './Types/Smart';
import CleanTabUSDAContent from './Types/USDA';

const CONFIG = {
  [CleaningType.smart]: CleanTabSmartContent,
  [CleaningType.usda]: CleanTabUSDAContent,
} as const;

export default CONFIG;
