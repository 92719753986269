import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import { selectProcessingPanelFiles } from '../../../uploadDataSelectors';
import { FileStatus } from '../../../helpers/constants/upload';

import './index.scss';

export default function UploadFilesPopupHeader() {
  const { t } = useTranslation();
  const files = useSelector(selectProcessingPanelFiles);

  const finishedCount = files.filter(
    ({ status }) =>
      status !== FileStatus.uploading && status !== FileStatus.complete,
  ).length;

  return (
    <Typography key="header" className="upload-files-popup-header">
      {t('general.popups.upload-data.steps.uploading')}: {finishedCount}/
      <span className="upload-files-popup-header__total-amount">
        {files.length}
      </span>
    </Typography>
  );
}
