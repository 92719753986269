query getFieldLegendData($farmUuids: [ID], $fieldUuids: [ID]) {
  getFarms(farmUuids: $farmUuids) {
    fields(fieldUuids: $fieldUuids) {
      soilDatasets {
        uuid
        name
        createdDate
        geoMaps {
          shortName
          url
          classes
          hexColorCodes
        }
        fullAttributes {
          fullName
          unit
          transliteratedName
        }
      }
      yieldDatasets {
        uuid
        name
        createdDate
        geoMaps {
          shortName
          url
          classes
          hexColorCodes
        }
        fullAttributes {
          fullName
          unit
          transliteratedName
        }
      }
      asAppliedDatasets {
        uuid
        name
        createdDate
        geoMaps {
          shortName
          url
          classes
          hexColorCodes
        }
        fullAttributes {
          fullName
          unit
          transliteratedName
        }
      }
      topographyMaps(statuses: [EXECUTED]) {
        uuid
        name
        createdDate
        geoMaps {
          shortName
          url
          classes
          hexColorCodes
        }
        fullAttributes {
          fullName
          unit
          transliteratedName
        }
      }
    }
  }
}
