import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import Tooltip from '../../../../../../components/Tooltip';
import { getRootLink, PAGES_ROOTS } from '../../../../../../helpers/navigation';
import { getUserOrganizationRole } from '../../../../../user/helpers/functions/user';
import {
  selectOrganizations,
  selectUuid,
} from '../../../../../user/userSelectors';
import { getBrandName } from '../../../../../../helpers/functions/utils/appConfig';

import './index.scss';

const UserOrganizationsUserPanel = () => {
  const { t } = useTranslation();
  const organizations = useSelector(selectOrganizations) || [];
  const userUuid = useSelector(selectUuid);
  const brandName = getBrandName();

  if (organizations.length === 0) {
    return <Redirect to={getRootLink(PAGES_ROOTS.fields)} />;
  }

  return (
    <div className="user-organizations-user">
      <div className="user-organizations-user__header">
        <Typography
          className="user-organizations-user__header-description"
          variant="body2"
        >
          {t('user-organizations.user.description.p1')}
        </Typography>
        <Typography
          className="user-organizations-user__header-description"
          variant="body2"
        >
          <Trans i18nKey="user-organizations.user.description.p2">
            Text
            <span className="user-organizations-user__tag">tag</span>
            text
          </Trans>
        </Typography>
        <Typography
          className="user-organizations-user__header-description"
          variant="body2"
        >
          {t('user-organizations.user.description.p3')}
        </Typography>
      </div>
      <div className="user-organizations-user__panels-wrapper">
        {organizations.map((organization, index) => {
          const billingOrg = index === 0;
          const userRole = getUserOrganizationRole(organization, userUuid);

          return (
            <div key={organization.uuid} className="org-panel">
              <div className="org-panel__header">
                <Typography variant="h6">{organization.name}</Typography>
                {billingOrg && (
                  <span className="user-organizations-user__tag">
                    {t('user-organizations.user.billing')}
                  </span>
                )}
              </div>
              <div className="org-panel__content">
                <div className="org-panel__line org-panel__line_divider">
                  <Typography className="org-panel__key">
                    {t('user-organizations.user.your-role')}
                  </Typography>
                  <Typography className="org-panel__value org-panel_bold">
                    {t(`user-organizations.admin.users-list.${userRole}`)}
                    <Tooltip
                      tooltip={
                        <Trans
                          i18nKey={`user-organizations.user.tooltips.${userRole}`}
                          brandName={brandName}
                        >
                          {{ brandName }}
                        </Trans>
                      }
                    >
                      <InfoIcon className="org-panel__icon" />
                    </Tooltip>
                  </Typography>
                </div>
                <div className="org-panel__line">
                  <Typography className="org-panel__key">
                    {t('user-organizations.user.administrator')}
                  </Typography>
                  <Typography className="org-panel__value">
                    {organization.givenName} {organization.surname}
                  </Typography>
                </div>
                <div className="org-panel__line">
                  <Typography className="org-panel__key">
                    {t('user-organizations.user.phone')}
                  </Typography>
                  <Typography className="org-panel__value">
                    {organization.phone}
                  </Typography>
                </div>
                <div className="org-panel__line">
                  <Typography className="org-panel__key">
                    {t('user-organizations.user.email')}
                  </Typography>
                  <Typography className="org-panel__value">
                    {organization.email}
                  </Typography>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UserOrganizationsUserPanel;
