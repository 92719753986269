import JDProductType from '../../../../jdProducts/helpers/constants/productType';

const PRODUCT_MANAGER_PATH = {
  [JDProductType.variety]: 'varieties',
  [JDProductType.fertilizer]: 'fertilizers',
  [JDProductType.chemical]: 'chemicals',
  [JDProductType.tankMix]: 'tankmixes',
  [JDProductType.dryBlend]: 'dryblends',
};

export const getJohnDeereProductsManagerLink = ({
  orgId = '',
  productType,
}: {
  orgId?: string;
  productType?: JDProductType | null;
}) =>
  `https://products-manager.deere.com/#/${orgId}/${productType ? PRODUCT_MANAGER_PATH[productType] : ''}`;

export const getJohnDeereWorkPlanLink = ({
  id = '',
  orgId = '',
}: {
  id?: string;
  orgId?: string;
}) => `https://work-planner.deere.com/${orgId}/edit/${id}/work`;
