import React, { ReactNode } from 'react';

import CheckboxItem from '../../../TreeView/CheckboxItem';
import SimpleItem from '../../../TreeView/SimpleItem';
import AssetGroupItem from '../../Items/AssetGroupItem';
import type {
  Mode,
  DataLayersTreeNode,
} from '../../../../types/dataLayersTree';
import { AssetGroupType } from '../../../../../../../helpers/constants/entities/asset';

import './index.scss';

export default function AssetGroupNode<T>({
  id,
  assetGroupType,
  mode,
  assetGroup,
  checked,
  expanded,
  offset,
  children,
  satelliteImagesLoading,
  treeNodeGetter,
  onCheckboxClick,
  onClick,
}: {
  id: string;
  mode: Mode;
  assetGroupType: AssetGroupType;
  assetGroup?: T[];
  checked: Record<string, number>;
  expanded: Record<string, boolean>;
  offset: number;
  children?: ReactNode;
  satelliteImagesLoading: boolean;
  treeNodeGetter: () => DataLayersTreeNode | null;
  onCheckboxClick: (
    v: Record<string, number>,
    item: DataLayersTreeNode,
  ) => void;
  onClick: () => void;
}) {
  let result;
  const item = (
    <AssetGroupItem
      assetGroup={assetGroup}
      assetGroupType={assetGroupType}
      satelliteImagesLoading={satelliteImagesLoading}
    >
      {children}
    </AssetGroupItem>
  );

  if (mode === 'multiSelect') {
    result = (
      <CheckboxItem
        itemId={id}
        treeNodeGetter={treeNodeGetter}
        checked={checked}
        isParent
        isExpanded={expanded[assetGroupType]}
        offset={offset}
        checkboxDisabled={!assetGroup?.length}
        classes={{
          button: 'asset-group-node__button',
          icon: 'asset-group-node__icon',
          checkbox: 'asset-group-node__checkbox',
        }}
        onCheckboxClick={onCheckboxClick}
        onClick={onClick}
      >
        {item}
      </CheckboxItem>
    );
  } else {
    result = (
      <SimpleItem
        isParent
        isExpanded={expanded[assetGroupType]}
        classes={{
          button: 'asset-group-node__button',
          icon: 'asset-group-node__icon',
        }}
        offset={offset}
        onClick={onClick}
      >
        {item}
      </SimpleItem>
    );
  }

  return result;
}
