mutation generatePinsGroups(
  $input: GeneratePfeiferLangenVectorAnalysisMapsInput!
) {
  generatePfeiferLangenNotes(input: $input) {
    uuid
    fieldUuid
    createdDate
    updatedDate
    type
    comment
    vectorAnalysisMap {
      uuid
      name
    }
    soilDataset {
      uuid
      name
    }
  }
}
