import { createAsyncThunk } from '@reduxjs/toolkit';
import i18n from 'i18next';

import {
  successNotify,
  errorNotify,
} from '../notifications/helpers/functions/notify';
import {
  captureException,
  CustomError,
} from '../../helpers/functions/utils/errorHandling';
import preparePhotos from './helpers/functions/preparePhotos';
import {
  uploadPhotos,
  saveComment,
  savePin as savePinAPI,
  savePins as savePinsAPI,
  deletePhotos,
  deletePin as deletePinAPI,
} from './pinsAPI';

export const deletePin = createAsyncThunk(
  'pins/deletePin',
  async ({ uuid, fieldUuid }, { dispatch }) => {
    let result = null;

    try {
      const { uuid: responseUuid } = await deletePinAPI(uuid, fieldUuid);

      result = responseUuid;
    } catch (error) {
      errorNotify({
        error: new CustomError('[Pins] Unable to delete pin.', {
          cause: error,
        }),
        dispatch,
      });
    }

    return result;
  },
);

export const savePinComment = createAsyncThunk(
  'pins/savePinComment',
  async ({ pinUuid, fieldUuid, comment, photos, farmUuid }) => {
    let result = null;

    try {
      const savedComment = await saveComment(pinUuid, fieldUuid, comment);
      const savedCommentUuid = savedComment?.uuid;
      let addedPhotos = [];

      if (photos?.length && savedCommentUuid) {
        addedPhotos = await uploadPhotos(
          fieldUuid,
          pinUuid,
          photos,
          farmUuid,
          savedCommentUuid,
        );
      }

      result = {
        ...savedComment,
        photos: preparePhotos({
          addedPhotos,
        }),
      };
    } catch (error) {
      captureException({
        message: '[Action] Unable to save pin comment',
        error,
      });
    }

    return result;
  },
);

export const savePin = createAsyncThunk(
  'pins/savePin',
  async ({ pin, farmUuid }, { dispatch }) => {
    const { photos, photosToAdd, photosToDelete, ...restPin } = pin;
    let result = null;
    try {
      const { uuid } = await savePinAPI(restPin);

      if (!pin.uuid) {
        restPin.uuid = uuid;
      }

      let deletedPhotos = [];
      let addedPhotos = [];

      if (photosToDelete?.length) {
        try {
          deletedPhotos = await deletePhotos(photosToDelete);
        } catch (error) {
          errorNotify({
            error: new CustomError('[Pins] Unable to delete photos from pin.', {
              cause: error,
            }),
            dispatch,
          });
        }
      }

      if (photosToAdd?.length) {
        try {
          addedPhotos = await uploadPhotos(
            restPin.fieldUuid,
            restPin.uuid,
            photosToAdd,
            farmUuid,
          );
        } catch (error) {
          errorNotify({
            error: new CustomError('[Pins] Unable to upload photos to pin.', {
              cause: error,
            }),
            dispatch,
          });
        }
      }

      result = {
        ...restPin,
        photos: preparePhotos({
          addedPhotos,
          photos,
          deletedPhotos,
        }),
      };
    } catch (e) {
      errorNotify({
        error: new CustomError('[Pins] Unable to save pin.', {
          cause: e,
        }),
        dispatch,
      });
    }

    return result;
  },
);

export const savePins = createAsyncThunk(
  'pins/savePins',
  async ({ pins }, { dispatch }) => {
    let result = null;

    try {
      result = await savePinsAPI(pins);
      successNotify({
        message: i18n.t('general.controls.pins.notifications.save-success'),
      });
    } catch (error) {
      errorNotify({
        error: new CustomError('[Pins] Unable to save pins.', {
          cause: error,
        }),
        message: i18n.t('general.controls.pins.notifications.save-error'),
        dispatch,
      });
    }

    return result;
  },
);
