import {
  PreloadedState,
  configureStore,
  Reducer,
  AnyAction,
} from '@reduxjs/toolkit';

import type { RootState } from './store/helpers/types';
import applicationReducer from './store/reducer';
import keyValueBrowserStoreMiddleware from './store/middleware/keyValueBrowserStore';
import { emptyAPI } from '../features/emptyApi/emptyAPI';

const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: applicationReducer as Reducer<RootState, AnyAction>,
    preloadedState,
    middleware(getDefaultMiddleware) {
      return getDefaultMiddleware().concat(
        emptyAPI.middleware,
        keyValueBrowserStoreMiddleware,
      );
    },
  });

export default setupStore;
