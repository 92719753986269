#import "../../../field/graphql/fragments/asAppliedDataset.gql"
#import "../../../field/graphql/fragments/soilDataset.gql"
#import "../../../field/graphql/fragments/topographyMap.gql"
#import "../../../field/graphql/fragments/yieldDataset.gql"
#import "../../../field/graphql/fragments/vectorAnalysisMap.gql"
#import "../../../field/graphql/fragments/equationMap.gql"

query getFarmsFieldsDatasetsAnalyses(
  $farmUuids: [ID]
  $fieldUuids: [ID]
  $areaUnit: AreaUnit
) {
  getFarms(farmUuids: $farmUuids) {
    name
    uuid
    fields(fieldUuids: $fieldUuids) {
      uuid
      name
      farmUuid
      status
      boundingBox
      area(unit: $areaUnit)
      geoMaps {
        shortName
        url
      }
      centroid {
        latitude
        longitude
      }
      soilDatasets {
        ...soilDatasetFields
      }
      yieldDatasets {
        ...yieldDatasetFields
      }
      asAppliedDatasets {
        ...asAppliedDatasetFields
      }
      topographyMaps(statuses: [EXECUTED]) {
        ...topographyMapFields
      }
      vectorAnalysisMaps(statuses: [EXECUTED, SAVED]) {
        ...vectorAnalysisMapFields
      }
      equationMaps {
        ...equationMapFields
      }
    }
  }
}
