import React, { ReactNode, useMemo, useRef, useState } from 'react';

import { readSsoAppUrl } from '../../../../../helpers/functions/utils/vendorConfigs/sso';
import TokensBrokerContext from './context';

/**
 * Provides an iframe for the integration with SSO application.
 */
export default function TokensBroker({ children }: { children: ReactNode }) {
  const ref = useRef(null);
  const src = useMemo(() => readSsoAppUrl(), []);
  const [iframeLoaded, setIframeLoaded] = useState(false);

  return (
    <>
      <iframe
        ref={ref}
        title="sso"
        id="ssoIframe"
        src={src}
        style={{ display: 'none' }}
        onLoad={() => setIframeLoaded(true)}
      ></iframe>
      {iframeLoaded && (
        <TokensBrokerContext.Provider value={{ ref }}>
          {children}
        </TokensBrokerContext.Provider>
      )}
    </>
  );
}
