import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import { getRootLink, PAGES_ROOTS } from '../../../../../helpers/navigation';
import { selectReachedAreaLimit } from '../../../../user/userSelectors';
import { selectTab } from '../../uploadDataSelectors';
import getRoutesConfig from './config';
import Base from './Base';

const UploadDataPanel = ({ activeRoute, routes }) => (
  <Switch>
    <Route exact path={getRootLink(PAGES_ROOTS.upload)}>
      <Redirect to={activeRoute} />
    </Route>
    {routes.map((route, i) => (
      <Route {...route} key={i} component={Base} />
    ))}
  </Switch>
);

const mapStateToProps = (state) => {
  const tab = selectTab(state);
  const routes = getRoutesConfig({
    isAreaLimitReached: selectReachedAreaLimit(state),
  });
  const entry = routes.find(({ value }) => value === tab);

  return {
    activeRoute: entry.path,
    routes,
  };
};

export default connect(mapStateToProps)(UploadDataPanel);
