import React, { Fragment } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import FailedJohnDeereComponent from './Panels/00-Failed';
import NotAuthorizedJohnDeereComponent from './Panels/01-NotAuthorized';

import './index.scss';

const JohnDeerePanel = ({
  hasError,
  isLoading,
  isReadyToAuthorization,
  isAuthorizedAndReady,
  content,
}) => (
  <section className="stepper-john-deere-panel">
    {hasError && <FailedJohnDeereComponent />}
    {!hasError && isLoading && (
      <div className="stepper-john-deere-panel__loader">
        <CircularProgress />
      </div>
    )}
    {!hasError && !isLoading && (
      <Fragment>
        {isReadyToAuthorization && <NotAuthorizedJohnDeereComponent />}
        {isAuthorizedAndReady && content}
      </Fragment>
    )}
  </section>
);

export default JohnDeerePanel;
