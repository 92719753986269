import { createEntityAdapter } from '@reduxjs/toolkit';

import {
  AsAppliedDatasetEntity,
  EquationMapEntity,
  FarmEntity,
  FieldEntity,
  SatelliteImageEntity,
  SoilDatasetEntity,
  TopographyMapEntity,
  VectorAnalysisMapEntity,
  YieldDatasetEntity,
} from './types/state';
import { datasetsComparator } from '../ui/dataLayersView/helpers/functions/comparator';

const selectAssetId = (asset: { uuid: string }) => asset.uuid;

export const farmsAdapter = createEntityAdapter<FarmEntity>({
  selectId: selectAssetId,
});

export const fieldsAdapter = createEntityAdapter<FieldEntity>({
  selectId: selectAssetId,
});

export const soilDatasetsAdapter = createEntityAdapter<SoilDatasetEntity>({
  selectId: selectAssetId,
  sortComparer: datasetsComparator,
});

export const yieldDatasetsAdapter = createEntityAdapter<YieldDatasetEntity>({
  selectId: selectAssetId,
  sortComparer: datasetsComparator,
});

export const asAppliedDatasetsAdapter =
  createEntityAdapter<AsAppliedDatasetEntity>({
    selectId: selectAssetId,
    sortComparer: datasetsComparator,
  });

export const topographyMapsAdapter = createEntityAdapter<TopographyMapEntity>({
  selectId: selectAssetId,
});

export const vectorAnalysisMapsAdapter =
  createEntityAdapter<VectorAnalysisMapEntity>({
    selectId: selectAssetId,
  });

export const equationMapsAdapter = createEntityAdapter<EquationMapEntity>({
  selectId: selectAssetId,
});

export const satelliteImagesAdapter = createEntityAdapter<SatelliteImageEntity>(
  {
    selectId: selectAssetId,
  },
);
