import { API, graphqlOperation } from '@aws-amplify/api';

import generatePinsGroupsMutation from './graphql/mutations/generatePinsGroups.gql';
import generateZonesMapsAsyncMutation from './graphql/mutations/generateZonesMapsAsync.gql';

export const generatePinsGroups = async (fieldUuid) =>
  API.graphql(
    graphqlOperation(generatePinsGroupsMutation, {
      input: {
        fieldUuid,
      },
    }),
  );

export const generateZonesMaps = async (fieldUuid) =>
  API.graphql(
    graphqlOperation(generateZonesMapsAsyncMutation, {
      input: {
        fieldUuid,
      },
    }),
  ).then(({ data }) => data.generatePfeiferLangenZonesMapsAsync);
