import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import { ColorRange } from '../../../../../helpers/types/map';
import BackdropLoading from '../../../../Loading/BackdropLoading';
import { prettifyNumber } from '../../../../../helpers/markup';

import './index.scss';

const Histogram = ({
  ranges = [],
  loading,
}: {
  ranges?: ColorRange[];
  loading?: boolean;
}) => {
  const { t } = useTranslation();

  const maxValue = useMemo(
    () => Math.max(...ranges.map((range) => Number(range.total) || 0)),
    [ranges],
  );

  if (loading) {
    return (
      <div className="histogram__loader-wrapper">
        <BackdropLoading size={25} />
      </div>
    );
  }

  return (
    <div className="histogram">
      {ranges?.map(({ avg, total }, index) => {
        const percentage = total ? (Number(total) / maxValue) * 100 : 0;

        return (
          <div key={index} className="histogram__row">
            <div className="histogram__bar-wrapper">
              <div
                className="histogram__bar"
                style={{
                  width: `${Math.round(percentage)}%`,
                }}
              />
            </div>
            <div className="histogram__statistics">
              <div className="histogram__statistics-item">
                <Typography className="histogram__statistics-label">
                  {t('general.controls.map-legend.range.avg')}:
                </Typography>
                {prettifyNumber(avg ?? 0)}
              </div>
              <div className="histogram__statistics-item">
                <Typography className="histogram__statistics-label">
                  {t('general.controls.map-legend.range.sum')}:
                </Typography>
                {prettifyNumber(total ?? 0)}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Histogram;
