import React, { useState, useEffect, useRef, Suspense, useMemo } from 'react';
import i18n from 'i18next';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import TargetIcon from '@material-ui/icons/FilterCenterFocusSharp';
import D3Icon from '@material-ui/icons/ThreeDRotation';
import AddIcon from '@material-ui/icons/Add';
import MinusIcon from '@material-ui/icons/Remove';
import { point } from '@turf/turf';

import Button from '../../../../../components/Button';
import Loading from '../../../../../components/Loading';
import D2Icon from '../../../../../components/Icons/2d';
import BingLogo from '../../../../../components/Icons/bing-logo.svg';
import MapboxLogo from '../../../../../components/Icons/mapbox-logo.svg';
import MapControlsGroup from '../../components/MapControlsGroup';
import {
  removeLayer,
  setMapIs3d,
  setMapProvider,
} from '../../compareLayersSlice';
import { openPopup } from '../../../popups/popupsSlice';
import { selectBoundingBox } from '../../../../field/fieldSelectors';
import {
  selectIs3d,
  selectLayers,
  selectMapProvider,
} from '../../compareLayersSelectors';
import { getPaddingBounds } from '../../../../../helpers/functions/map/mapLayerConfig';
import AmplitudeAnalytics from '../../../../../helpers/classes/amplitudeAnalytics';
import POPUPS from '../../../popups/helpers/constants/popups';
import { isBingMapsEnabled } from '../../../../../helpers/functions/utils/appConfig';
import { fetchAllAssets } from '../../../../field/fieldSlice';
import { MapProvider } from '../../../../../helpers/constants/map';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../app/store/helpers/functions';

import './index.scss';

const MapCompareCard = React.lazy(() => import('../Map'));

const CompareLayersPanel = () => {
  const dispatch = useAppDispatch();
  const { farmUuid, fieldUuid } = useParams();
  const is3d = useAppSelector(selectIs3d);
  const mapProvider = useAppSelector(selectMapProvider);
  const layers = useAppSelector(selectLayers);
  const boundingBox = useAppSelector(selectBoundingBox);
  const width = window.innerWidth / (layers.length > 1 ? 2 : 1);

  const fitBounds = useMemo(
    () => [
      [boundingBox[0], boundingBox[1]],
      [boundingBox[2], boundingBox[3]],
    ],
    [boundingBox],
  );

  const fitBoundsOptions = useMemo(
    () => ({
      padding: getPaddingBounds('field', width),
    }),
    [width],
  );

  const [maps, setMaps] = useState([]);
  const [center, setCenter] = useState(null);
  const [zoom, setZoom] = useState(null);
  const syncRef = useRef(false);

  useEffect(() => {
    void dispatch(
      fetchAllAssets({
        farmUuid,
        fieldUuid,
      }),
    );
  }, [farmUuid, fieldUuid, dispatch]);

  useEffect(() => {
    if (layers.length) {
      AmplitudeAnalytics.trackLayersCompared({
        layers: layers.length,
      });
    }
  }, [layers.length]);

  const handleDimensionClick = (is3dMap) => {
    dispatch(setMapIs3d(!is3dMap));
  };

  const handleMapProviderClick = (provider) => {
    if (provider === MapProvider.mapbox) {
      dispatch(setMapProvider(MapProvider.bing));
    } else if (provider === MapProvider.bing) {
      dispatch(setMapProvider(MapProvider.mapbox));
    }
  };

  const handleAddLayerClick = () => {
    dispatch(
      openPopup({
        type: POPUPS.addDataLayer,
      }),
    );
  };

  const handleRemoveLayerClick = (layerId) => {
    dispatch(removeLayer(layerId));
  };

  const handleLoad = (event) => {
    const { target: map } = event;
    setMaps((prev) => [...prev, map]);

    if (!center || !zoom) {
      handleTarget();
      calculateData();
    }
  };

  const handleDragAndZoom = (event) => {
    if (syncRef.current) {
      return;
    }

    const { target: targetMap } = event;

    syncRef.current = true;
    maps.forEach((map) => {
      if (!targetMap.getStyle() || targetMap === map) {
        return;
      }

      map.setCenter(targetMap.getCenter());
      map.setZoom(targetMap.getZoom());
    });
    syncRef.current = false;
    cleanupMaps();
  };

  const handleRotate = (event) => {
    if (syncRef.current) {
      return;
    }

    const { target: targetMap } = event;

    syncRef.current = true;
    maps.forEach((map) => {
      if (!targetMap.getStyle() || targetMap === map) {
        return;
      }

      map.setBearing(targetMap.getBearing());
      map.setPitch(targetMap.getPitch());
    });
    syncRef.current = false;
    cleanupMaps();
  };

  const handleZoomIn = () => {
    maps.forEach((map) => {
      if (!map.getStyle()) {
        return;
      }

      map.zoomIn();
    });
    cleanupMaps();
  };

  const handleZoomOut = () => {
    maps.forEach((map) => {
      if (!map.getStyle()) {
        return;
      }

      map.zoomOut();
    });
    cleanupMaps();
  };

  const handleDimensions = () => {
    maps.forEach((map) => {
      if (!map.getStyle()) {
        return;
      }

      if (is3d) {
        map.setPitch([0]);
      } else {
        map.setPitch([70]);
      }
    });
    handleDimensionClick(is3d);
    cleanupMaps();
  };

  const handleTarget = () => {
    maps.forEach((map) => {
      if (!map.getStyle()) {
        return;
      }

      map.fitBounds(fitBounds, fitBoundsOptions);
    });
    cleanupMaps();
  };

  const handleMouseMove = (event) => {
    const { lng, lat } = event.lngLat;

    maps.forEach((map) => {
      if (!map.getStyle() || event.target === map) {
        return;
      }

      const source = map.getSource('pointer-source');

      if (source) {
        source.setData(point([lng, lat]));
      }
    });
  };

  const handleAddDataLayerClick = () => {
    calculateData();
    handleAddLayerClick();
  };

  const handleCardRemove = (layerId) => {
    calculateData();
    handleRemoveLayerClick(layerId);
  };

  const cleanupMaps = () => {
    const cleanedMaps = maps.filter((map) => map.getStyle());

    if (maps.length !== cleanedMaps.length) {
      setMaps(cleanedMaps);
    }
  };

  const calculateData = () => {
    const map = maps.find((m) => !!m.getStyle());
    let newCenter = null;
    let newZoom = null;

    if (map) {
      newCenter = map.getCenter();
      newZoom = map.getZoom();
    }

    setCenter(newCenter);
    setZoom(newZoom);
  };

  return (
    <div className="compare-layers-panel">
      <div className="maps-grid__wrapper">
        {layers.length < 3 && (
          <div className="add-layer__wrapper">
            <Button startIcon={<AddIcon />} onClick={handleAddDataLayerClick}>
              {i18n.t('zones-ops.multi-layer.steps.3.add-data-layer')}
            </Button>
          </div>
        )}
        <div
          className={clsx('maps-grid', {
            'maps-grid_2-cols': layers.length >= 2,
            'maps-grid_4-cells': layers.length > 2,
          })}
        >
          {layers.map(({ layerId, uuid }) => (
            <Suspense key={layerId} fallback={<Loading />}>
              <MapCompareCard
                uuid={uuid}
                layerId={layerId}
                mapProvider={mapProvider}
                center={center}
                zoom={zoom}
                onLoad={handleLoad}
                onDrag={handleDragAndZoom}
                onDragEnd={handleDragAndZoom}
                onZoom={handleDragAndZoom}
                onZoomEnd={handleDragAndZoom}
                onRotate={handleRotate}
                onRotateEnd={handleRotate}
                onMouseMove={handleMouseMove}
                onRemove={handleCardRemove}
              />
            </Suspense>
          ))}
          {layers.length === 3 && (
            <div className="add-layer__grid-wrapper">
              <Button startIcon={<AddIcon />} onClick={handleAddDataLayerClick}>
                {i18n.t('zones-ops.multi-layer.steps.3.add-data-layer')}
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="compare-layers-panel__map-instruments-panel">
        <MapControlsGroup
          buttons={[
            {
              icon: <TargetIcon />,
              onClick: handleTarget,
            },
          ]}
        />
        <MapControlsGroup
          buttons={[
            {
              icon: is3d ? <D2Icon className="MuiSvgIcon-root" /> : <D3Icon />,
              onClick: handleDimensions,
            },
          ]}
        />
        <MapControlsGroup
          buttons={[
            {
              icon: <AddIcon />,
              onClick: handleZoomIn,
            },
            {
              icon: <MinusIcon />,
              onClick: handleZoomOut,
            },
          ]}
        />
        {isBingMapsEnabled() && (
          <MapControlsGroup
            buttons={[
              {
                icon:
                  mapProvider === MapProvider.bing ? (
                    <MapboxLogo className="MuiSvgIcon-root" />
                  ) : (
                    <BingLogo className="MuiSvgIcon-root" />
                  ),
                onClick: () => handleMapProviderClick(mapProvider),
              },
            ]}
          />
        )}
      </div>
    </div>
  );
};

export default CompareLayersPanel;
