import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import PopupHeader from '../../../../components/Popups/PopupHeader';
import Button from '../../../../components/Button';
import Link from '../../../../components/Link';
import BackdropLoading from '../../../../components/Loading/BackdropLoading';
import { selectLabelsMap, selectLoaded } from '../../labelsSelectors';
import { fetchLabels } from '../../labelsSlice';
import LabelsSelect from '../../../../components/LabelsSelect';
import AmplitudeAnalytics from '../../../../helpers/classes/amplitudeAnalytics';
import useCurrentPage from '../../../../hooks/useCurrentPage';

import './index.scss';

const AddLabelsPopup = ({
  onCancel = () => {},
  onConfirm = () => {},
  onLinkClick = () => {},
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const labelsMap = useSelector(selectLabelsMap);
  const loaded = useSelector(selectLoaded);
  const page = useCurrentPage();
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(true);
  const [labelsToAdd, setLabelsToAdd] = useState([]);

  useEffect(() => {
    if (page) {
      dispatch(fetchLabels());
      AmplitudeAnalytics.trackLabelsPopupOpened({
        page,
        type: 'addFieldsLabels',
      });
    }
  }, [dispatch, page]);

  const handleLabelsChange = (labels) => {
    setConfirmButtonDisabled(labels.length === 0);
    setLabelsToAdd(labels);
  };

  if (!loaded) {
    return <BackdropLoading />;
  }

  return (
    <Dialog open>
      <PopupHeader
        title={t('general.popups.add-labels.add-labels')}
        onCancel={onCancel}
      />
      <DialogContent>
        <DialogContentText
          classes={{
            root: 'add-labels-popup__content-text',
          }}
        >
          <Trans i18nKey="general.popups.add-labels.description">
            text{' '}
            <Link to="#" onClick={onLinkClick}>
              link
            </Link>{' '}
            text
          </Trans>
        </DialogContentText>
        <LabelsSelect
          limitTags={5}
          disableCustomLabel
          placeholder={t('general.popups.add-labels.placeholder')}
          placeholderWithValue={t('general.popups.add-labels.placeholder')}
          options={labelsMap}
          classes={{ list: 'add-labels-popup__labels-list' }}
          onChange={handleLabelsChange}
        />
      </DialogContent>
      <DialogActions
        classes={{
          root: 'add-labels-popup__actions',
        }}
      >
        <Button variant="outlined" onClick={onCancel}>
          {t('general.controls.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={confirmButtonDisabled}
          onClick={() => onConfirm(labelsToAdd)}
        >
          {t('general.popups.add-labels.add-labels')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddLabelsPopup;
