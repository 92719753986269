import React, { useEffect } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

import Button from '../../../../../components/Button';
import PopupHeader from '../../../../../components/Popups/PopupHeader';
import RadioGroup from '../../../../../components/RadioGroup';
import SeedingWorkPlanPanel from '../SeedingWorkPlanPanel';
import ApplicationsWorkPlanPanel from '../ApplicationsWorkPlanPanel';
import {
  exportToJohnDeereAsApplicationWorkPlan,
  exportToJohnDeereAsSeedingWorkPlan,
  resetWorkPlan,
  setWorkPlanType,
} from '../../jdWorkPlanSlice';
import {
  getSeedingWorkPlanPayload,
  getApplicationsPayload,
} from '../../helpers/functions/api';
import {
  selectFieldUuid,
  selectJDField,
} from '../../../../field/fieldSelectors';
import { getJDFieldOrgId } from '../../../../field/helpers/functions/field';
import { WorkPlanType } from '../../helpers/constants/workPlan';
import {
  ApplicationSettings,
  AssetToExport,
  SeedingWorkPlanSettings,
} from '../../types/workPlan';
import useDidMount from '../../../../../hooks/useDidMount';
import {
  selectJDSeedingWorkPlanSettings,
  selectJDWorkPlanType,
  selectApplications,
} from '../../jdWorkPlanSelectors';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../app/store/helpers/functions';
import useCurrentPage from '../../../../../hooks/useCurrentPage';
import AmplitudeAnalytics from '../../../../../helpers/classes/amplitudeAnalytics';

import './index.scss';

const getWorkPlanTypeOptions = () => [
  {
    value: WorkPlanType.seeding,
    title: i18n.t('general.popups.export-work-plan.types.seeding'),
  },
  {
    value: WorkPlanType.application,
    title: i18n.t('general.popups.export-work-plan.types.application'),
  },
];

const isApplicationsFullDataProvided = (applications: ApplicationSettings[]) =>
  applications.every(
    (application) =>
      application.selectedAsset &&
      application.productType &&
      application.productId &&
      application.unit,
  );

const isSeedingWorkPlanExportDisabled = (settings: SeedingWorkPlanSettings) =>
  !settings.varietyId ||
  !settings.selectedAsset ||
  !settings.unit ||
  !isApplicationsFullDataProvided(settings.applications);

const isApplicationWorkPlanExportDisabled = (
  applications: ApplicationSettings[],
) => !applications.length || !isApplicationsFullDataProvided(applications);

const ExportAsWorkPlanPopup = ({
  selectedAsset = null,
  onCancel = () => {},
  onConfirm = () => {},
}: {
  selectedAsset?: AssetToExport | null;
  onCancel: () => void;
  onConfirm: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const page = useCurrentPage();
  const fieldUuid = useAppSelector(selectFieldUuid) ?? '';
  const jdField = useAppSelector(selectJDField);
  const workPlanType = useAppSelector(selectJDWorkPlanType);
  const seedingWorkPlanSettings = useAppSelector(
    selectJDSeedingWorkPlanSettings,
  );
  const applicationsSettings = useAppSelector(selectApplications);
  const orgId = getJDFieldOrgId(jdField);

  useEffect(() => {
    if (page) {
      AmplitudeAnalytics.trackExportToJDPopupOpened({
        page,
        type: 'asWorkPlan',
      });
    }
  }, [page]);

  useDidMount(() => () => {
    dispatch(resetWorkPlan());
  });

  const handleWorkPlanTypeChange = (newType: WorkPlanType) => {
    dispatch(setWorkPlanType(newType));
  };

  const handleConfirm = () => {
    let exportData;
    if (workPlanType === WorkPlanType.seeding) {
      exportData = getSeedingWorkPlanPayload(seedingWorkPlanSettings);
      void dispatch(
        exportToJohnDeereAsSeedingWorkPlan({
          exportData: [exportData],
          orgId,
          fieldUuid,
        }),
      );
    } else if (workPlanType === WorkPlanType.application) {
      exportData = getApplicationsPayload(applicationsSettings);
      void dispatch(
        exportToJohnDeereAsApplicationWorkPlan({
          exportData: [exportData],
          orgId,
          fieldUuid,
        }),
      );
    }

    if (exportData) {
      AmplitudeAnalytics.trackWorkPlanExportedToJD({
        page,
        workPlanType,
        chemical: 'chemicalId' in exportData,
        fertilizer: 'fertilizerId' in exportData,
        tankMix: 'tankMixId' in exportData,
        dryBlend: 'dryBlendId' in exportData,
        variety: 'varietyId' in exportData,
      });
    }

    onConfirm();
  };

  const isExportButtonDisabled =
    workPlanType === WorkPlanType.seeding
      ? isSeedingWorkPlanExportDisabled(seedingWorkPlanSettings)
      : isApplicationWorkPlanExportDisabled(applicationsSettings);

  return (
    <Dialog open>
      <PopupHeader
        classes={{
          root: 'export-as-work-plan-popup__header',
        }}
        title={t('general.popups.export-work-plan.title')}
        onCancel={onCancel}
      />
      <DialogContent classes={{ root: 'export-as-work-plan-popup__body' }}>
        <Typography
          variant="body1"
          className="export-as-work-plan-popup__description"
        >
          {t('general.popups.export-work-plan.description')}
        </Typography>
        <Typography
          variant="body1"
          className="export-as-work-plan-popup__subtitle"
        >
          {t('general.popups.export-work-plan.work-plan-type')}
        </Typography>
        <RadioGroup
          horizontal
          value={workPlanType}
          options={getWorkPlanTypeOptions()}
          onChange={handleWorkPlanTypeChange}
        />
        <div className="export-as-work-plan-popup__settings">
          {workPlanType === WorkPlanType.seeding && <SeedingWorkPlanPanel />}
          {workPlanType === WorkPlanType.application && (
            <ApplicationsWorkPlanPanel defaultAsset={selectedAsset} />
          )}
        </div>
      </DialogContent>
      <DialogActions classes={{ root: 'export-as-work-plan-popup__actions' }}>
        <Button variant="outlined" onClick={onCancel}>
          {t('general.controls.cancel')}
        </Button>
        <Button
          disabled={isExportButtonDisabled}
          variant="contained"
          color="primary"
          onClick={handleConfirm}
        >
          {t('general.controls.export')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportAsWorkPlanPopup;
