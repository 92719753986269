import { FeatureCollection } from 'geojson';

const extractThreeDMapMinMax = (
  points: FeatureCollection,
  attribute: string,
) => {
  let min: number | null = null;
  let max: number | null = null;

  if (points) {
    min = Infinity;
    max = -Infinity;
    points.features.forEach((point) => {
      const value = point?.properties?.[attribute];

      if (value == null) {
        return;
      }

      if (min != null && value < min) {
        min = value;
      } else if (max != null && value > max) {
        max = value;
      }
    });
  }

  return {
    min,
    max,
  };
};

export default extractThreeDMapMinMax;
