mutation createFieldOperationSubscriptionJohnDeere(
  $input: CreateFieldOperationSubscriptionJohnDeereInput!
) {
  createFieldOperationSubscriptionJohnDeere(input: $input) {
    id
    name
    type
    status
    orgIds
    fieldOperationTypes
  }
}
