import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import ApplicationCard from '../ApplicationCard';
import Button from '../../../../../components/Button';
import { ApplicationProductType, AssetToExport } from '../../types/workPlan';
import JDProductType from '../../../../jdProducts/helpers/constants/productType';
import {
  selectApplications,
  selectJDWorkPlanType,
} from '../../jdWorkPlanSelectors';
import { addApplication } from '../../jdWorkPlanSlice';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../app/store/helpers/functions';

import './index.scss';

const MAX_APPLICATIONS_AMOUNT = 4;

const getProductTypeOptions = (): {
  value: ApplicationProductType;
  title: string;
}[] => [
  {
    value: JDProductType.chemical,
    title: i18n.t('general.popups.export-work-plan.product-type.chemical'),
  },
  {
    value: JDProductType.fertilizer,
    title: i18n.t('general.popups.export-work-plan.product-type.fertilizer'),
  },
  {
    value: JDProductType.tankMix,
    title: i18n.t('general.popups.export-work-plan.product-type.tank-mix'),
  },
  {
    value: JDProductType.dryBlend,
    title: i18n.t('general.popups.export-work-plan.product-type.dry-blend'),
  },
];

const getAvailableProductTypeOptions = (
  currentType: ApplicationProductType | null,
  selectedTypes: (ApplicationProductType | null)[],
) => {
  const options = getProductTypeOptions();
  return options.filter(
    ({ value }) => !selectedTypes.includes(value) || value === currentType,
  );
};

const ApplicationsWorkPlanPanel = ({
  defaultAsset,
}: {
  defaultAsset?: AssetToExport | null;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const workPlanType = useAppSelector(selectJDWorkPlanType);
  const applications = useAppSelector(selectApplications);

  const selectedProductTypes = useMemo(
    () =>
      applications.reduce<ApplicationProductType[]>(
        (acc, { productType }) => (productType ? [...acc, productType] : acc),
        [],
      ),
    [applications],
  );

  const handleAddApplication = () => {
    dispatch(
      addApplication({
        application: {
          selectedAsset: defaultAsset ?? applications[0]?.selectedAsset ?? null,
          productType: null,
          productId: null,
          unit: null,
        },
        workPlanType,
      }),
    );
  };

  return (
    <>
      {applications.map((application) => (
        <ApplicationCard
          key={application.id}
          applicationId={application.id}
          typeOptions={getAvailableProductTypeOptions(
            application.productType,
            selectedProductTypes,
          )}
        />
      ))}
      {applications.length < MAX_APPLICATIONS_AMOUNT && (
        <Button
          classes={{
            root: 'applications-work-plan-panel__add-button',
          }}
          variant="text"
          onClick={() => handleAddApplication()}
        >
          {`+ ${t('general.popups.export-work-plan.application-work-plan.add-application')}`}
        </Button>
      )}
    </>
  );
};

export default ApplicationsWorkPlanPanel;
