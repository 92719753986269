import { encode, encodeCharacter, repl1 } from '../../mapbox/source';

export const appendApiKey = (url: string, apiKey: string) => {
  const u = new URL(url);
  u.searchParams.append('geopardApiKey', apiKey);

  return u.toString();
};

export const isValidUrl = (url: string) => {
  try {
    const isValid = new URL(url);

    return !!isValid;
  } catch (error) {
    return false;
  }
};

/**
 * Prepare thumbnail GeoMap's URL to be used as a src for an image.
 * "thumbnail_" urls should be used, where it's possible.
 * @param {Object} options.thumbnail - The thumbnail object containing the URL.
 * @param {number} options.size - The size of the thumbnail.
 * @param {string} options.apiKey - The API key.
 * @returns {string} The prepared URL with the specified size and API key.
 */
export const getThumbnailUrl = ({
  thumbnail,
  size,
  apiKey,
}: {
  thumbnail?: { url: string; shortName: string } | null;
  size: number;
  apiKey: string;
}): string => {
  if (!thumbnail) {
    return '';
  }

  // Thumbnail URL's second parameter is adjusted automatically.
  const replacedString = thumbnail.url.includes('WIDTH')
    ? repl1(thumbnail.url, 'WIDTH', size.toString())
    : repl1(thumbnail.url, 'HEIGHT', size.toString());
  const result = encodeCharacter(encode([replacedString], 'env'), ';');

  return appendApiKey(result[0], apiKey);
};
