import React from 'react';
import TableCell from '@material-ui/core/TableCell';

import TextField from '../../../../../../components/TextField';
import getCellClasses from '../../../helpers/functions/getCellClasses';

import './index.scss';

const InputCell = ({ row = {}, headCell = {} }) => (
  <TableCell
    align={headCell.horizontalAlign || 'left'}
    className={getCellClasses('input-cell', headCell, row)}
  >
    <TextField
      className="input-cell__input"
      variant={headCell.variant}
      type={headCell.type}
      value={row[headCell.id]}
      onChange={(e) => headCell.onInputChange(e, row.id)}
    />
  </TableCell>
);

export default InputCell;
