import React, { Fragment } from 'react';

import ZonesMapTable from '../../Controls/ZonesMapTable';
import Overview from '../../Controls/Overview';
import SatelliteGallery from '../../Controls/SatelliteGallery';
import LabelsList from '../../Controls/LabelsList';
import Header from '../../Controls/Header';
import RatesTable from '../../Controls/RatesTable';

const SatelliteZonesMapLegendGroup = ({
  TableComponent = ZonesMapTable,
  indexHeader,
  zonesMapTable,
  ratesTable,
  overview,
  labels,
  satelliteGallery,
}) => (
  <Fragment>
    <Header {...indexHeader} />
    <TableComponent {...zonesMapTable} />
    {ratesTable && <RatesTable {...ratesTable} />}
    <Overview {...overview} />
    <SatelliteGallery {...satelliteGallery} />
    <LabelsList {...labels} />
  </Fragment>
);

export default SatelliteZonesMapLegendGroup;
