import { useDispatch, useSelector } from 'react-redux';

import { fetchAllAssets } from '../fieldSlice';
import { selectField } from '../fieldSelectors';
import useDidMount from '../../../hooks/useDidMount';

export default function useAllFieldAssets({
  farmUuid,
  fieldUuid,
  requiredSatImages,
}: {
  farmUuid: string;
  fieldUuid: string;
  requiredSatImages?: string[];
}) {
  const dispatch = useDispatch();
  const field = useSelector(selectField);

  useDidMount(() => {
    dispatch(
      fetchAllAssets({
        farmUuid,
        fieldUuid,
        requiredSatImages,
      }),
    );
  });

  return field;
}
