import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import GeneralPopup from '../../../../../components/Popups/GeneralPopup';
import Loading from '../../../../../components/Loading';
import ToolsPanel from './ToolsPanel';
import Panel from './Panel';
import { selectBackgroundLayer } from '../../overlayLayersSelectors';
import { setBackgroundLayer } from '../../overlayLayersSlice';
import { BackgroundLayer } from '../../types/backgroundLayer';
import { useAppSelector } from '../../../../../app/store/helpers/functions';

const Map = React.lazy(() => import('./Map'));

const ChangeBackgroundLayerPopup = ({
  onCancel,
  onConfirm,
}: {
  onCancel?: () => void;
  onConfirm?: () => void;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const backgroundLayer = useAppSelector(selectBackgroundLayer);
  const [background, setBackground] = useState(backgroundLayer);

  const handleConfirm = () => {
    dispatch(setBackgroundLayer(background));
    onConfirm?.();
  };

  const handleBackgroundChange = (backgroundUpdates: BackgroundLayer) => {
    setBackground((prevState) => ({ ...prevState, ...backgroundUpdates }));
  };

  return (
    <GeneralPopup
      title={t('change-background.title')}
      onCancel={onCancel}
      onConfirm={handleConfirm}
    >
      <ToolsPanel key="tools-panel" />
      <Panel
        backgroundUuid={background?.uuid}
        onBackgroundChange={handleBackgroundChange}
        key="panel"
      />
      <Suspense key="map" fallback={<Loading />}>
        <Map
          background={background}
          onBackgroundChange={handleBackgroundChange}
          onCancel={onCancel}
        />
      </Suspense>
    </GeneralPopup>
  );
};

export default ChangeBackgroundLayerPopup;
