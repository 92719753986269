import React, { useMemo } from 'react';

import CheckboxItem from '../../../TreeView/CheckboxItem';
import ZonesMapItem from '../../../Items/ZonesMapItem';
import SimpleItem from '../../../TreeView/SimpleItem';
import ItemActions from '../../../ItemActions';
import type {
  Mode,
  DataLayersTreeNode,
} from '../../../../types/dataLayersTree';
import { getVectorAnalysisMapActions } from '../../../../helpers/functions/dataLayersTreeNodesActions';
import { AssetGroupType } from '../../../../../../../helpers/constants/entities/asset';
import { TransformedVectorAnalysisMap } from '../../../../../../../helpers/types/vectorAnalysisMap';

export default function ZonesMapNode({
  id,
  vamap,
  mode,
  checked,
  treeNodeGetter,
  jdProfileIsHealth,
  jdProfileIsAuthorized,
  jdWorkPlanExportAvailable,
  isSynchronizedJohnDeereField,
  showItemMenu,
  offset,
  selectedItemUuid,
  selectedItemGroupType,
  onCheckboxClick,
  onMenuItemClick,
  onClick,
}: {
  id: string;
  vamap: TransformedVectorAnalysisMap;
  mode: Mode;
  checked: Record<string, number>;
  treeNodeGetter: () => DataLayersTreeNode | null;
  jdProfileIsHealth: boolean;
  jdProfileIsAuthorized: boolean;
  jdWorkPlanExportAvailable: boolean;
  isSynchronizedJohnDeereField: boolean;
  showItemMenu: boolean;
  offset: number;
  selectedItemUuid?: string;
  selectedItemGroupType?: string | null;
  onCheckboxClick: (
    v: Record<string, number>,
    item: DataLayersTreeNode,
  ) => void;
  onMenuItemClick: (v: string) => void;
  onClick: () => void;
}) {
  let result;
  const menuItems = useMemo(
    () =>
      getVectorAnalysisMapActions(vamap, {
        jdProfileIsHealth,
        jdProfileIsAuthorized,
        jdWorkPlanExportAvailable,
        isSynchronizedJohnDeereField,
      }),
    [
      vamap,
      jdProfileIsHealth,
      jdProfileIsAuthorized,
      jdWorkPlanExportAvailable,
      isSynchronizedJohnDeereField,
    ],
  );
  const item = (
    <>
      <ZonesMapItem zonesMap={vamap} />
      {showItemMenu && (
        <ItemActions menuItems={menuItems} onMenuItemClick={onMenuItemClick} />
      )}
    </>
  );
  const isItemSelected =
    vamap.uuid === selectedItemUuid &&
    selectedItemGroupType === AssetGroupType.vectorAnalysisMaps;

  if (mode === 'multiSelect') {
    result = (
      <CheckboxItem
        itemId={id}
        treeNodeGetter={treeNodeGetter}
        checked={checked}
        isSelected={isItemSelected}
        offset={offset}
        onCheckboxClick={onCheckboxClick}
        onClick={onClick}
      >
        {item}
      </CheckboxItem>
    );
  } else {
    result = (
      <SimpleItem isSelected={isItemSelected} offset={offset} onClick={onClick}>
        {item}
      </SimpleItem>
    );
  }

  return result;
}
