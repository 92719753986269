import { createSlice } from '@reduxjs/toolkit';
import { enqueueSnackbar, closeSnackbar } from 'notistack';

import NotificationType from './helpers/constants/types';
import { AppDispatch } from '../../app/store/helpers/types';
import { selectProcessingNotificationId } from './notificationsSelectors';
import { NotificationAction } from './types/action';
import type { RootState } from '../../app/store/helpers/types';

export interface NotificationsState {
  processingNotificationId: string | null;
}

const initialState: NotificationsState = {
  processingNotificationId: null,
};

export const processingNotify =
  ({
    message = '',
    actions,
  }: {
    message?: string;
    actions?: NotificationAction[];
  }) =>
  (dispatch: AppDispatch) => {
    const snackbarId = enqueueSnackbar(message, {
      variant: NotificationType.processing,
      action: actions,
    });

    dispatch(setProcessingNotificationId(snackbarId));
  };

export const closeProcessingNotification =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const processingNotificationId = selectProcessingNotificationId(state);

    if (processingNotificationId) {
      closeSnackbar(processingNotificationId);
      dispatch(clearProcessingNotificationId());
    }
  };

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setProcessingNotificationId(state, action) {
      state.processingNotificationId = action.payload;
    },
    clearProcessingNotificationId(state) {
      state.processingNotificationId = null;
    },
  },
});

export const { setProcessingNotificationId, clearProcessingNotificationId } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;
