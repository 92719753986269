import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import ComboBox, { Option } from '../../../../../components/ComboBox';
import { JohnDeereProduct } from '../../../../jdProducts/types/products';
import {
  getComboBoxOptionSelected,
  getCreateProductsProps,
} from '../../helpers/functions/ui';
import JDProductType from '../../../../jdProducts/helpers/constants/productType';

import './index.scss';

const ProductSelector = ({
  products = [],
  selectedProductId,
  isProductsLoading,
  isProductsLoaded,
  productManagerLink,
  productType,
  noOptionsText,
  onProductChange,
  onProductsLinkClick,
}: {
  products?: JohnDeereProduct[];
  selectedProductId?: string | null;
  isProductsLoading: boolean;
  isProductsLoaded: boolean;
  isDisabled?: boolean;
  productManagerLink: string;
  productType?: JDProductType | null;
  noOptionsText?: string;
  onProductChange: (productId: string | null) => void;
  onProductsLinkClick: () => void;
}) => {
  const { t } = useTranslation();
  const [isProductsCacheInvalid, setIsProductsCacheInvalid] = useState(false);

  useEffect(
    () => () => {
      if (isProductsCacheInvalid) {
        onProductsLinkClick();
      }
    },
    [isProductsCacheInvalid, onProductsLinkClick],
  );

  const getProductDescription = useCallback(
    (product: JohnDeereProduct) =>
      [
        ...('cropName' in product ? [product.cropName] : []),
        ...('companyName' in product ? [product.companyName] : []),
      ].join(' | '),
    [],
  );

  const productOptions = useMemo(
    () =>
      products.map((product) => {
        const description = getProductDescription(product);
        return {
          value: product,
          title: [product.name, ...(description ? [description] : [])].join(
            ' | ',
          ),
        };
      }),
    [products, getProductDescription],
  );

  const selectedProduct = useMemo(
    () =>
      productOptions.find((option) => option.value.id === selectedProductId) ??
      null,
    [productOptions, selectedProductId],
  );

  const handleProductChange = (
    _e: React.ChangeEvent<unknown>,
    item: Option<JohnDeereProduct> | null,
  ) => {
    onProductChange(item?.value?.id ?? null);
  };

  const handleProductManagerLinkClick = () => {
    setIsProductsCacheInvalid(true);
  };

  const renderOption = ({ value }: Option<JohnDeereProduct>) => {
    const description = getProductDescription(value);
    return (
      <div>
        <span className="product-selector__option-name">{value.name}</span>
        {description && (
          <span className="product-selector__option-description">
            {description}
          </span>
        )}
      </div>
    );
  };

  return (
    <ComboBox
      classes={{
        root: 'product-selector__product-select',
        noOptions: clsx({
          'product-selector__no-options': !noOptionsText,
        }),
      }}
      loading={isProductsLoading}
      title={
        productType
          ? t(`general.popups.export-work-plan.product-type.${productType}`)
          : t('general.popups.export-work-plan.product')
      }
      placeholder={
        t('general.controls.select') +
        (productType
          ? ` ${t(`general.popups.export-work-plan.product-type.${productType}`)}`
          : '')
      }
      options={productOptions}
      value={selectedProduct}
      getOptionSelected={getComboBoxOptionSelected}
      disableCloseOnSelect={false}
      onChange={handleProductChange}
      noOptionsText={noOptionsText}
      renderOption={renderOption}
      {...(isProductsLoaded && !products?.length
        ? getCreateProductsProps({
            productManagerLink,
            onProductManagerLinkClick: handleProductManagerLinkClick,
          })
        : {})}
    />
  );
};

export default ProductSelector;
