import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';

import type { FullAttributeItem } from '../../../../../../../types/ui';
import ItemsPicker from '../../../../../../../../../../components/ItemsPicker';
import ToggleAdvancedConfigButton from '../../../../../../../components/ToggleAdvancedConfigButton';
import CalibrationBasis from '../../../../../../../components/CalibrationBasis';
import WarningPanel from '../../../../../../../components/WarningPanel';
import { createFullAttributeItem } from '../../../../../../../helpers/functions/ui';
import {
  selectCalibrateAvgTotalConditions,
  selectCalibrateCalibrationAttributes,
  selectCalibratePathwiseCalibrationBasis,
  selectCalibratePathwiseSyntheticMachinePath,
  selectDatasetUuid,
} from '../../../../../../../cleanCalibrateSelectors';
import { useAppSelector } from '../../../../../../../../../../app/store/helpers/functions';
import { selectYieldDataset } from '../../../../../../../../../field/fieldSelectors';
import {
  resetCalibrateAvgTotalConditions,
  setCalibrateCalibrationAttributes,
  setCalibratePathwiseCalibrationBasis,
  toggleCalibratePathwiseSyntheticMachinePath,
} from '../../../../../../../cleanCalibrateSlice';
import AvgTotalCalibrateConditions from '../../../../../../AvgTotalCalibrateConditions';

import './index.scss';

export default function CalibrateTabPathwiseContent() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const datasetUuid = useAppSelector(selectDatasetUuid);
  const dataset = useAppSelector((state) =>
    selectYieldDataset(state, datasetUuid),
  );
  const calibrationAttributes = useAppSelector(
    selectCalibrateCalibrationAttributes,
  );
  const pathwiseCalibrationBasis = useAppSelector(
    selectCalibratePathwiseCalibrationBasis,
  );
  const pathwiseSyntheticMachinePath = useAppSelector(
    selectCalibratePathwiseSyntheticMachinePath,
  );
  const avgTotalConditions = useSelector(selectCalibrateAvgTotalConditions);
  const [configExpanded, setConfigExpanded] = useState(
    avgTotalConditions.length !== 0,
  );

  const allAttributesItems = useMemo<FullAttributeItem[]>(
    () =>
      (dataset?.fullAttributes || []).map((fullAttribute) =>
        createFullAttributeItem(fullAttribute),
      ),
    [dataset],
  );

  const calibrateAttributesValues = useMemo<Set<string>>(
    () => new Set(calibrationAttributes),
    [calibrationAttributes],
  );

  const calibrationBasisAttributeItem = useMemo<FullAttributeItem | undefined>(
    () =>
      allAttributesItems.find(
        (item) => item.value === pathwiseCalibrationBasis,
      ),
    [allAttributesItems, pathwiseCalibrationBasis],
  );

  const handleCalibrationAttributesChange = (itemsValues: Set<string>) => {
    dispatch(setCalibrateCalibrationAttributes([...itemsValues]));
  };

  const handleCalibrationBasisChange = (item: FullAttributeItem) => {
    dispatch(setCalibratePathwiseCalibrationBasis(item.value));
  };

  const handleSyntheticBasisUsedChange = (value: boolean) => {
    dispatch(
      toggleCalibratePathwiseSyntheticMachinePath({
        calibrationAttributes: value ? calibrationAttributes : [],
      }),
    );
  };

  const handleToggleConfigClick = () => {
    setConfigExpanded(!configExpanded);

    if (configExpanded) {
      dispatch(resetCalibrateAvgTotalConditions());
    }
  };

  return (
    <>
      <ItemsPicker
        availableItemsTitle={t('clean-calibrate.yield-popup.attributes')}
        addedItemsTitle={t(
          'clean-calibrate.yield-popup.tabs.fast.configure.selected-attributes',
        )}
        required
        items={allAttributesItems}
        addedItemsValues={calibrateAttributesValues}
        emptyAddedItemsLabel={t(
          'clean-calibrate.yield-popup.no-added-attributes',
        )}
        onAddedItemsChange={handleCalibrationAttributesChange}
      />
      {!calibrationAttributes?.length && (
        <WarningPanel
          text={t(
            'clean-calibrate.yield-popup.tabs.calibrate.configure.no-calibration-attributes-warning',
          )}
        />
      )}
      <Divider className="calibrate-tab-pathwise-content__content-divider" />
      <DialogContentText>
        {t(
          'clean-calibrate.yield-popup.tabs.calibrate.configure.parameters-instruction',
        )}
      </DialogContentText>
      <CalibrationBasis
        syntheticUsed={pathwiseSyntheticMachinePath}
        syntheticUsedDisabled={!calibrationAttributes?.length}
        options={allAttributesItems}
        basisValue={calibrationBasisAttributeItem}
        basisDisabled={pathwiseSyntheticMachinePath}
        label={t(
          'clean-calibrate.yield-popup.tabs.calibrate.configure.calibration-basis',
        )}
        onBasisChange={handleCalibrationBasisChange}
        onSyntheticUsedChange={handleSyntheticBasisUsedChange}
      />
      {!pathwiseSyntheticMachinePath && !calibrationBasisAttributeItem && (
        <WarningPanel
          text={t(
            'clean-calibrate.yield-popup.tabs.calibrate.configure.no-machine-id-warning',
          )}
        />
      )}
      {configExpanded ? (
        <>
          <Divider className="calibrate-tab-pathwise-content__content-divider" />
          <AvgTotalCalibrateConditions
            title={t(
              'clean-calibrate.yield-popup.tabs.calibrate.configure.avg-total-conditions.title',
            )}
            typeSelectorTitle={t(
              'clean-calibrate.yield-popup.tabs.calibrate.configure.avg-total-conditions.types.title-pathwise',
            )}
            availableAttributes={calibrationAttributes}
          />
        </>
      ) : (
        <></>
      )}
      <ToggleAdvancedConfigButton
        value={configExpanded}
        showLabelI18nKey="clean-calibrate.yield-popup.tabs.calibrate.configure.show-post-calibration-config"
        hideLabelI18nKey="clean-calibrate.yield-popup.tabs.calibrate.configure.hide-post-calibration-config"
        onClick={handleToggleConfigClick}
      />
    </>
  );
}
