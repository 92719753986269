import { createSelector } from '@reduxjs/toolkit';

export const selectFieldBoundaryMap = ({ mapView }) => mapView.fieldBoundaryMap;

export const selectFieldsBoundaries = createSelector(
  selectFieldBoundaryMap,
  (fieldBoundaryMap) =>
    Object.values(fieldBoundaryMap)
      .filter(({ data }) => !!data)
      .map(({ data }) => data),
);

const selectSatelliteImagesMap = ({ mapView }) => mapView.satelliteImagesMap;

const selectFieldSatelliteImagesEntity = createSelector(
  selectSatelliteImagesMap,
  (_, fieldUuid) => fieldUuid,
  (satelliteImagesMap, fieldUuid) => satelliteImagesMap[fieldUuid],
);

export const selectFieldSatelliteImagesData = (state, fieldUuid) =>
  selectFieldSatelliteImagesEntity(state, fieldUuid)?.data;

export const selectFieldSatelliteImagesStatus = (state, fieldUuid) =>
  selectFieldSatelliteImagesEntity(state, fieldUuid)?.status;

export const selectLegend = ({ mapView }) => mapView.legend;

export const selectChartScale = ({ mapView }) => mapView.chartScale;
