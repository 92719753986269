import React, { Suspense } from 'react';

import Loading from '../../../Loading';
import FieldPreview from '../../Controls/FieldPreview';
import Overview from '../../Controls/Overview';
import LabelsList from '../../Controls/LabelsList';

import './index.scss';

const LineChart = React.lazy(() => import('../../Controls/LineChart'));

const FieldLegendGroup = ({
  preview,
  overview,
  labels,
  lineChart,
  onChartScaleChange = () => {},
}) => (
  <div className="field-group">
    <FieldPreview {...preview} />
    <Overview {...overview} />
    <LabelsList labels={labels} />
    <Suspense fallback={<Loading />}>
      <LineChart {...lineChart} onScaleChange={onChartScaleChange} />
    </Suspense>
  </div>
);

export default FieldLegendGroup;
