import React, { CSSProperties, useState } from 'react';
import i18n from 'i18next';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import { Operation } from '../../types/operation';
import OperationSummaryIcons from '../OperationSummaryIcons';
import SubOperationItem from '../SubOperationItem';

import './index.scss';

const getOperationItemTitle = (operation: Operation): string => {
  const { type, subOperations } = operation;

  return `${i18n.t(`operations.types.${type}`)} ${i18n.t('operations.summary', {
    amount: subOperations.length,
  })}`;
};

export default function OperationsListItem({
  style,
  operation,
}: {
  style?: CSSProperties;
  operation: Operation;
}) {
  const [expanded, setExpanded] = useState(true);

  const handleExpandedChange = () => {
    setExpanded(!expanded);
  };

  const { subOperations } = operation;

  const subOperationItems = subOperations.map((subOperation, index) => (
    <SubOperationItem key={index} subOperation={subOperation} />
  ));

  return (
    <div style={style} className="operations-list-item">
      {subOperations.length > 1 ? (
        <Accordion
          elevation={0}
          expanded={expanded}
          onChange={handleExpandedChange}
        >
          <AccordionSummary
            classes={{
              root: 'operations-list-item__summary',
              content: 'operations-list-item__summary-content',
            }}
            aria-controls="panel1-content"
          >
            <div className="operations-list-item__title">
              <Typography
                className="operations-list-item__title-content"
                variant="subtitle2"
              >
                {getOperationItemTitle(operation)}
              </Typography>
              <ExpandLessIcon
                className={clsx('operations-list-item__title-icon', {
                  'operations-list-item__title-icon_collapsed': !expanded,
                })}
              />
            </div>
            {!expanded && <OperationSummaryIcons operation={operation} />}
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: 'operations-list-item__details',
            }}
          >
            {subOperationItems}
          </AccordionDetails>
        </Accordion>
      ) : (
        subOperationItems
      )}
    </div>
  );
}
