import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '../../../../../components/TextField';

import './index.scss';

export default function ToolsPanel({
  name,
  onNameChange = () => {},
}: {
  name: string;
  onNameChange: (v: string) => void;
}) {
  const { t } = useTranslation();

  return (
    <div className="create-three-d-map-tools-panel">
      <TextField
        required
        value={name}
        className="create-3d-panel__title"
        title={t('zones-ops.common.title')}
        onChange={(e: ChangeEvent<{ value: string }>) =>
          onNameChange(e.target.value)
        }
      />
    </div>
  );
}
