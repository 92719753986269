import React, { ReactElement, useState } from 'react';
import clsx from 'clsx';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import type { DataLayersTreeNode } from '../../types/dataLayersTree';
import { triggerMapResize } from '../../../../../helpers/mapbox/map';
import { getComponent } from '../../../../../helpers/markup';
import DataLayersTreeContext from './context';

import './index.scss';

export default function DataLayersTree({
  dataLayersTree,
  treeView,
  collapsible = false,
  children = [],
}: {
  dataLayersTree: DataLayersTreeNode[];
  treeView: ReactElement;
  collapsible?: boolean;
  children?: ReactElement | ReactElement[];
}) {
  const [treeCollapsed, setTreeCollapsed] = useState<boolean>(false);

  const handleCollapseClick = () => {
    triggerMapResize();
    setTreeCollapsed(!treeCollapsed);
  };

  return (
    <DataLayersTreeContext.Provider value={dataLayersTree}>
      <div
        className={clsx('data-layers-tree', {
          'data-layers-tree_collapsed': treeCollapsed,
        })}
      >
        {getComponent('top-panel', children)}
        <div className="data-layers-tree__tree-wrapper">
          {treeView}
          {collapsible && (
            <button
              className="data-layers-tree__collapse-button"
              onClick={handleCollapseClick}
            >
              {treeCollapsed ? (
                <ChevronRightIcon fontSize="small" />
              ) : (
                <ChevronLeftIcon fontSize="small" />
              )}
            </button>
          )}
        </div>
        <div className="data-layers-tree__bottom-panel">
          {getComponent('bottom-panel', children)}
        </div>
      </div>
    </DataLayersTreeContext.Provider>
  );
}
