import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { FullAttributeItem } from '../../../../../../../types/ui';
import {
  createFullAttributeItem,
  transformCleanMinMaxUSDAConditions,
  transformCleanMinMaxConditions,
} from '../../../../../../../helpers/functions/ui';
import {
  selectCleanMinMaxConditions,
  selectCleanMinMaxUSDAConditions,
  selectDatasetUuid,
} from '../../../../../../../cleanCalibrateSelectors';
import { useAppSelector } from '../../../../../../../../../../app/store/helpers/functions';
import { selectYieldDataset } from '../../../../../../../../../field/fieldSelectors';
import {
  addEmptyCleanMinMaxCondition,
  updateCleanMinMaxCondition,
  removeCleanMinMaxCondition,
  setCleanMinMaxUSDAConditions,
  updateCleanMinMaxUSDACondition,
  resetCleanUSDAConditionStdNumber,
} from '../../../../../../../cleanCalibrateSlice';
import MinMaxConditions from '../../../../../../../components/MinMaxConditions';
import useDidMount from '../../../../../../../../../../hooks/useDidMount';
import { NullableMinMaxCleanUSDACondition } from '../../../../../../../types/actions';
import { USDACleaningAttribute } from '../../../../../../../helpers/constants/ui';
import {
  findDelayAttribute,
  findSwathWidthAttribute,
  findVelocityAttribute,
  getAttributeString,
} from '../../../../../../../helpers/functions/attributes';
import { findYieldAttribute } from '../../../../../../../../../../helpers/functions/entities/attribute';
import MinMaxUSDAConditions from '../../../../../../../components/MinMaxUSDAConditions';
import { populateMinMaxCleanConditions } from '../../../../../../../helpers/functions/conditions';

import './index.scss';

const MAP_ATTRIBUTE_TYPE_TO_TITLE_I18N_KEY = {
  [USDACleaningAttribute.yield]:
    'clean-calibrate.yield-popup.tabs.clean.configure.usda-attributes.yield.title',
  [USDACleaningAttribute.delay]:
    'clean-calibrate.yield-popup.tabs.clean.configure.usda-attributes.delay.title',
  [USDACleaningAttribute.velocity]:
    'clean-calibrate.yield-popup.tabs.clean.configure.usda-attributes.velocity.title',
  [USDACleaningAttribute.swathWidth]:
    'clean-calibrate.yield-popup.tabs.clean.configure.usda-attributes.swath-width.title',
};

const MAP_ATTRIBUTE_TYPE_TO_DESCRIPTION_I18N_KEY = {
  [USDACleaningAttribute.yield]:
    'clean-calibrate.yield-popup.tabs.clean.configure.usda-attributes.yield.description',
  [USDACleaningAttribute.delay]:
    'clean-calibrate.yield-popup.tabs.clean.configure.usda-attributes.delay.description',
  [USDACleaningAttribute.velocity]:
    'clean-calibrate.yield-popup.tabs.clean.configure.usda-attributes.velocity.description',
  [USDACleaningAttribute.swathWidth]:
    'clean-calibrate.yield-popup.tabs.clean.configure.usda-attributes.swath-width.description',
};

export default function CleanTabUSDAContent() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const datasetUuid = useAppSelector(selectDatasetUuid);
  const dataset = useAppSelector((state) =>
    selectYieldDataset(state, datasetUuid),
  );
  const minMaxUsdaConditions = useAppSelector(selectCleanMinMaxUSDAConditions);
  const minMaxConditions = useAppSelector(selectCleanMinMaxConditions);

  useDidMount(() => {
    const fullAttributes = dataset?.fullAttributes ?? [];
    const yieldCleanAttribute = findYieldAttribute(fullAttributes);
    const delayAttribute = findDelayAttribute(fullAttributes);
    const velocityAttribute = findVelocityAttribute(fullAttributes);
    const swathWidthAttribute = findSwathWidthAttribute(fullAttributes);

    const yieldCleanAttributeValue = yieldCleanAttribute
      ? getAttributeString(yieldCleanAttribute)
      : null;
    const delayAttributeValue = delayAttribute
      ? getAttributeString(delayAttribute)
      : null;
    const velocityAttributeValue = velocityAttribute
      ? getAttributeString(velocityAttribute)
      : null;
    const swathWidthAttributeValue = swathWidthAttribute
      ? getAttributeString(swathWidthAttribute)
      : null;

    const defaultUSDAConditions = [
      {
        cleanAttribute: yieldCleanAttributeValue,
        type: USDACleaningAttribute.yield,
        selected: !!yieldCleanAttributeValue,
      },
      {
        cleanAttribute: delayAttributeValue,
        type: USDACleaningAttribute.delay,
        selected: !!delayAttributeValue,
      },
      {
        cleanAttribute: velocityAttributeValue,
        type: USDACleaningAttribute.velocity,
        selected: !!velocityAttributeValue,
      },
      {
        cleanAttribute: swathWidthAttributeValue,
        type: USDACleaningAttribute.swathWidth,
        selected: !!swathWidthAttributeValue,
      },
    ];

    const defaultUSDAConditionsWithMinMaxValues = defaultUSDAConditions.map(
      (condition) =>
        populateMinMaxCleanConditions(condition, dataset?.statistics),
    );

    dispatch(
      setCleanMinMaxUSDAConditions(defaultUSDAConditionsWithMinMaxValues),
    );
  });

  const usdaConditions = useMemo(
    () =>
      transformCleanMinMaxUSDAConditions({
        conditions: minMaxUsdaConditions,
        titleI18nKeys: MAP_ATTRIBUTE_TYPE_TO_TITLE_I18N_KEY,
        descriptionI18nKeys: MAP_ATTRIBUTE_TYPE_TO_DESCRIPTION_I18N_KEY,
      }),
    [minMaxUsdaConditions],
  );

  const additionalConditions = useMemo(
    () => transformCleanMinMaxConditions(minMaxConditions),
    [minMaxConditions],
  );

  const datasetAttributesItems = useMemo(
    () =>
      (dataset?.fullAttributes || []).map((fullAttribute) =>
        createFullAttributeItem(fullAttribute),
      ),
    [dataset],
  );

  const handleUSDAConditionAttributeChange = (
    attributeItem: FullAttributeItem,
    index: number,
  ) => {
    const condition = populateMinMaxCleanConditions(
      { cleanAttribute: attributeItem?.value ?? null },
      dataset?.statistics,
    );

    dispatch(
      updateCleanMinMaxUSDACondition({
        index,
        condition,
      }),
    );
  };

  const handleUSDAConditionMinChange = (min: number | null, index: number) => {
    dispatch(
      updateCleanMinMaxUSDACondition({
        index,
        condition: { min },
      }),
    );
  };

  const handleUSDAConditionMaxChange = (max: number | null, index: number) => {
    dispatch(
      updateCleanMinMaxUSDACondition({
        index,
        condition: { max },
      }),
    );
  };

  const handleUSDAConditionSelected = (checked: boolean, index: number) => {
    const update: Partial<NullableMinMaxCleanUSDACondition> = {
      selected: checked,
    };

    dispatch(
      updateCleanMinMaxUSDACondition({
        index,
        condition: update,
      }),
    );
  };

  const handleStandardDeviationChange = (
    value: number | null,
    index: number,
  ) => {
    const update: Partial<NullableMinMaxCleanUSDACondition> = {
      stdNumber: value,
    };

    dispatch(
      updateCleanMinMaxUSDACondition({
        index,
        condition: update,
      }),
    );
  };

  const handleStandardDeviationChecked = (checked: boolean, index: number) => {
    if (checked) {
      dispatch(
        updateCleanMinMaxUSDACondition({
          index,
          condition: { stdNumber: null },
        }),
      );
    } else {
      dispatch(resetCleanUSDAConditionStdNumber(index));
    }
  };

  const handleAdditionalConditionAttributeChange = (
    attributeItem: FullAttributeItem,
    index: number,
  ) => {
    const condition = populateMinMaxCleanConditions(
      { cleanAttribute: attributeItem?.value ?? null },
      dataset?.statistics,
    );

    dispatch(
      updateCleanMinMaxCondition({
        index,
        condition,
      }),
    );
  };

  const handleAdditionalConditionMinChange = (
    min: number | null,
    index: number,
  ) => {
    dispatch(
      updateCleanMinMaxCondition({
        index,
        condition: { min },
      }),
    );
  };

  const handleAdditionalConditionMaxChange = (
    max: number | null,
    index: number,
  ) => {
    dispatch(
      updateCleanMinMaxCondition({
        index,
        condition: { max },
      }),
    );
  };

  const handleAdditionalConditionAdd = () => {
    dispatch(addEmptyCleanMinMaxCondition());
  };

  const handleAdditionalConditionDelete = (index: number) => {
    dispatch(removeCleanMinMaxCondition(index));
  };

  return (
    <div className="clean-tab-usda-content">
      <MinMaxUSDAConditions
        attributesItems={datasetAttributesItems}
        conditions={usdaConditions}
        statistics={dataset?.statistics || []}
        onConditionAttributeChange={handleUSDAConditionAttributeChange}
        onConditionMinChange={handleUSDAConditionMinChange}
        onConditionMaxChange={handleUSDAConditionMaxChange}
        onConditionSelected={handleUSDAConditionSelected}
        onStandardDeviationChange={handleStandardDeviationChange}
        onStandardDeviationChecked={handleStandardDeviationChecked}
      />
      <MinMaxConditions
        classes={{
          label: 'clean-tab-usda-content__condition-label',
        }}
        title={t(
          'clean-calibrate.yield-popup.tabs.clean.configure.additional-attributes',
        )}
        attributesItems={datasetAttributesItems}
        conditions={additionalConditions}
        statistics={dataset?.statistics || []}
        onConditionAttributeChange={handleAdditionalConditionAttributeChange}
        onConditionMinChange={handleAdditionalConditionMinChange}
        onConditionMaxChange={handleAdditionalConditionMaxChange}
        onAddConditionClick={handleAdditionalConditionAdd}
        onConditionDelete={handleAdditionalConditionDelete}
      />
    </div>
  );
}
