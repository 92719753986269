import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isWithinInterval from 'date-fns/isWithinInterval';
import i18n from 'i18next';

import POPUPS from '../ui/popups/helpers/constants/popups';
import {
  comparator,
  isPlanetImage,
} from '../../helpers/functions/entities/satelliteImage';
import {
  errorNotify,
  warningNotify,
} from '../notifications/helpers/functions/notify';
import { CustomError } from '../../helpers/functions/utils/errorHandling';
import { openPopup } from '../ui/popups/popupsSlice';
import { fetchAssetsGroup } from '../field/fieldAPI';
import { getMetadata } from './planetMetadataAPI';
import { selectRequests } from './planetMetadataSelectors';
import { AssetGroupType } from '../../helpers/constants/entities/asset';

const initialState = {
  /**
   * @type {{
   *   field: { uuid: string, farmUuid: string, name: string},
   *   totalCount: number,
   *   startDate: Date,
   *   endDate: Date,
   *   satelliteImages: { uuid: string }[],
   * }[]}
   */
  requests: [],
};

export const requestMetadata = createAsyncThunk(
  'planetMetadata/requestMetadata',
  /**
   *
   * @param {Object} payload
   * @param {Object} payload.field
   * @param {string} payload.field.uuid
   * @param {string} payload.field.farmUuid
   * @param {string} payload.field.name
   * @param {Date} payload.startDate
   * @param {Date} payload.endDate
   * @returns {Promise<number>}
   */
  async ({ field, startDate, endDate }, { dispatch }) => {
    const response = await getMetadata({
      fieldUuid: field.uuid,
      startDate,
      endDate,
    });
    const { errors, amount, statusCode } = response;

    if (errors) {
      errorNotify({
        error: new CustomError(
          '[Planet Metadata] Unable to request metadata.',
          {
            cause: errors,
          },
        ),
        dispatch,
      });

      return 0;
    }

    if (statusCode !== 202) {
      errorNotify({
        error: new CustomError('[Planet Metadata] Unexpected status code.', {
          cause: response,
        }),
        dispatch,
      });

      return 0;
    }

    if (amount === 0) {
      warningNotify({
        message: i18n.t(
          'general.popups.planet-metadata-status.notifications.no-data',
        ),
      });
    } else {
      dispatch(
        openPopup({
          type: POPUPS.planetMetadataStatus,
        }),
      );
    }

    return amount;
  },
);

const checkSatelliteImageData =
  ({ farmUuid, fieldUuid, satelliteImageUuid, retryCount }) =>
  (dispatch) => {
    if (retryCount > 3) {
      const errorMesssage = `Unable to fetch satellite image for find Planet images workflow.
      FarmUuid: ${farmUuid},
      FieldUuid: ${fieldUuid},
      SatelliteImageUuid: ${satelliteImageUuid}.
    `;

      errorNotify({
        error: new CustomError(errorMesssage),
        dispatch,
      });

      return;
    }

    void new Promise((resolve) => {
      const DEFAULT_TIMEOUT = 750;

      setTimeout(resolve, DEFAULT_TIMEOUT * retryCount);
    }).then(() => {
      fetchAssetsGroup({
        assetsGroupType: AssetGroupType.satelliteImages,
        farmUuid,
        fieldUuid,
        uuids: [satelliteImageUuid],
      })
        .then(([image]) => {
          if (!image) {
            dispatch(
              checkSatelliteImageData({
                farmUuid,
                fieldUuid,
                satelliteImageUuid,
                retryCount: retryCount + 1,
              }),
            );

            return;
          }

          if (!isPlanetImage(image)) {
            return;
          }

          dispatch(
            addImage({
              fieldUuid,
              image,
            }),
          );
        })
        .catch(() => {
          dispatch(
            checkSatelliteImageData({
              farmUuid,
              fieldUuid,
              satelliteImageUuid,
              retryCount: retryCount + 1,
            }),
          );
        });
    });
  };

export const subscriptionPlanetMetadata =
  (parsedEvent) => (dispatch, getState) => {
    const { action, pathLength, farmUuid, fieldUuid, satelliteImageUuid } =
      parsedEvent;
    const requests = selectRequests(getState());
    const isSatelliteEvent = pathLength === 3 && satelliteImageUuid !== '';
    const matchSomeRequests = requests.some(
      (request) =>
        request.field.uuid === fieldUuid && request.field.farmUuid === farmUuid,
    );

    if (!matchSomeRequests || action !== 'MODIFY' || !isSatelliteEvent) {
      return;
    }

    dispatch(
      checkSatelliteImageData({
        farmUuid,
        fieldUuid,
        satelliteImageUuid,
        retryCount: 0,
      }),
    );
  };

export const planetMetadataSlice = createSlice({
  name: 'planetMetadata',
  initialState,
  reducers: {
    addImage(state, action) {
      const { fieldUuid, image } = action.payload;

      state.requests = state.requests.map((request) => {
        const imageDate = new Date(image.satelliteImage.acquisitionDate);
        const requestInterval = {
          start: request.startDate,
          end: request.endDate,
        };

        if (
          request.field.uuid !== fieldUuid ||
          !isWithinInterval(imageDate, requestInterval)
        ) {
          return request;
        }

        const newSatImages = [...request.satelliteImages, image];

        return {
          ...request,
          satelliteImages: newSatImages.sort(comparator),
        };
      });
    },
    close(state) {
      state.requests = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestMetadata.fulfilled, (state, action) => {
      if (action.payload !== 0) {
        const { field, startDate, endDate } = action.meta.arg;

        state.requests.push({
          field,
          startDate,
          endDate,
          totalCount: action.payload,
          satelliteImages: [],
        });
      }
    });
  },
});

export default planetMetadataSlice.reducer;

export const { close, addImage } = planetMetadataSlice.actions;
