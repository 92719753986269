import CreateMultiLayerAnalysis from '../../../createMultiLayerAnalysis/containers/Stepper';
import CreateSingleLayerAnalysis from '../../../createSingleLayerAnalysis/containers/Stepper';
import CreateFieldPotentialZones from '../../../createFieldPotentialZones/containers/Stepper';
import CreateStabilityZones from '../../../createStabilityZones/containers/Stepper';
import CrossLayer from '../../../crossLayer/containers/Stepper';
import DrawManually from '../../../drawManuallyWorkflow/containers/Stepper';
import CloneZonesMap from '../../../cloneZonesMap/containers/Stepper';
import EquationBased from '../../../createEquationBasedAnalysis/containers/Stepper';
import CreateThreeDMap from '../../../createThreeDMap/containers/Stepper';
import ZonesOpsWorkflow from '../../helpers/constants/zonesOpsWorkflow';

const config = {
  [ZonesOpsWorkflow.createMultiLayerAnalysis]: CreateMultiLayerAnalysis,
  [ZonesOpsWorkflow.createSingleLayerAnalysis]: CreateSingleLayerAnalysis,
  [ZonesOpsWorkflow.createFieldPotentialZones]: CreateFieldPotentialZones,
  [ZonesOpsWorkflow.createStabilityZones]: CreateStabilityZones,
  [ZonesOpsWorkflow.crossLayer]: CrossLayer,
  [ZonesOpsWorkflow.drawManually]: DrawManually,
  [ZonesOpsWorkflow.cloneZonesMap]: CloneZonesMap,
  [ZonesOpsWorkflow.equationBased]: EquationBased,
  [ZonesOpsWorkflow.threeDMap]: CreateThreeDMap,
};

const getStepper = (workflow: ZonesOpsWorkflow) => config[workflow];

export default getStepper;
