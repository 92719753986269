import { reset } from '../../createSingleLayerAnalysisSlice';
import getSteps from '../../helpers/functions/getSteps';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../app/store/helpers/functions';
import useStepComponent from '../../../zonesOps/hooks/useStepComponent';
import { selectAssetType } from '../../createSingleLayerAnalysisSelectors';

export default function StepperCreateSingleLayerAnalysis() {
  const dispatch = useAppDispatch();
  const assetType = useAppSelector(selectAssetType);

  const stepComponent = useStepComponent({
    getStepsFn: getSteps(assetType, true),
    resetFn: () => {
      dispatch(reset());
    },
  });

  return stepComponent;
}
