import React from 'react';
import i18n from 'i18next';

import { EXPORT_ASSET_TYPE } from '../constants/assets';
import ZonesMapItem from '../../../dataLayersView/components/Items/ZonesMapItem';
import PinListItem from '../../../dataLayersView/components/Items/PinsGroupItem';

const generateTree = (
  selectedAssets,
  { farms = [], fields = {}, vectorAnalysisMaps = {}, pinsGroups = {} },
) => {
  const fieldBoundariesSet = new Set(
    selectedAssets[EXPORT_ASSET_TYPE.fieldBoundary],
  );
  const zonesMapsSet = new Set(selectedAssets[EXPORT_ASSET_TYPE.zonesMap]);
  const pinsGroupsSet = new Set(selectedAssets[EXPORT_ASSET_TYPE.pinsGroup]);

  return farms.map(
    ({
      uuid: farmUuid,
      name: farmName,
      loading: farmLoading,
      loaded: farmLoaded,
    }) => ({
      isFarm: true,
      loading: farmLoading,
      loaded: farmLoaded,
      title: farmName,
      uuid: farmUuid,
      items: (fields[farmUuid] || []).map(
        ({
          name: fieldName,
          uuid: fieldUuid,
          loading: fieldLoading,
          loaded: fieldLoaded,
        }) => ({
          isField: true,
          loading: fieldLoading,
          loaded: fieldLoaded,
          title: fieldName,
          uuid: fieldUuid,
          farmUuid,
          items: [
            ...(fieldLoaded
              ? [
                  {
                    uuid: fieldUuid,
                    fieldUuid,
                    farmUuid,
                    hasCheckbox: true,
                    value: fieldBoundariesSet.has(fieldUuid) ? 2 : 0,
                    content: i18n.t('export.field-boundary'),
                    isLeaf: true,
                    type: EXPORT_ASSET_TYPE.fieldBoundary,
                  },
                ]
              : []),
            ...(vectorAnalysisMaps[fieldUuid] &&
            vectorAnalysisMaps[fieldUuid].length > 0
              ? [
                  {
                    uuid: `${fieldUuid}-zones-maps`,
                    title: i18n.t('general.navigation.zones-maps'),
                    items: vectorAnalysisMaps[fieldUuid].map((vamap) => ({
                      hasCheckbox: true,
                      content: <ZonesMapItem zonesMap={vamap} />,
                      title: vamap.name,
                      uuid: vamap.uuid,
                      fieldUuid,
                      farmUuid,
                      value: zonesMapsSet.has(vamap.uuid) ? 2 : 0,
                      isLeaf: true,
                      type: EXPORT_ASSET_TYPE.zonesMap,
                    })),
                  },
                ]
              : []),
            ...(pinsGroups[fieldUuid] && pinsGroups[fieldUuid].length > 0
              ? [
                  {
                    uuid: `${fieldUuid}-scouting-pins`,
                    title: i18n.t('export.scouting-pins'),
                    items: pinsGroups[fieldUuid].map((pinsGroup) => ({
                      hasCheckbox: true,
                      title: pinsGroup.name,
                      uuid: pinsGroup.uuid,
                      content: <PinListItem pinsGroup={pinsGroup} />,
                      fieldUuid,
                      farmUuid,
                      value: pinsGroupsSet.has(pinsGroup.uuid) ? 2 : 0,
                      isLeaf: true,
                      type: EXPORT_ASSET_TYPE.pinsGroup,
                    })),
                  },
                ]
              : []),
          ],
        }),
      ),
    }),
  );
};

export default generateTree;
