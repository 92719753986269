import {
  selectAllLoading,
  selectFieldFeatureSaving,
  selectJDField,
} from '../../field/fieldSelectors';
import { selectExportIsExecutingExport } from '../jdExport/jdExportSelectors';
import { selectJDWorkPlanLoading } from '../jdWorkPlan/jdWorkPlanSelectors';

export const selectSelectedItemUuid = ({ fieldWorkflow }) =>
  fieldWorkflow.selectedItemUuid;

export const selectSelectedItemGroupType = ({ fieldWorkflow }) =>
  fieldWorkflow.selectedItemGroupType;

export const selectLegend = ({ fieldWorkflow }) => fieldWorkflow.legend;

export const selectLoading = (state) =>
  selectAllLoading(state) ||
  state.fieldWorkflow.exportAttributesLoading ||
  selectFieldFeatureSaving(state) ||
  selectExportIsExecutingExport(state) ||
  selectJDWorkPlanLoading(state) ||
  selectJDField(state) === null;

export const selectPinsInstrument = ({ fieldWorkflow }) =>
  fieldWorkflow.pinsInstrument;

export const selectMapAttributes = ({ fieldWorkflow }) =>
  fieldWorkflow.mapAttributes;

export const selectDataLayersTreeViewType = ({ fieldWorkflow }) =>
  fieldWorkflow.dataLayersTree.viewType;
