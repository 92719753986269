import React from 'react';
import TableCell from '@material-ui/core/TableCell';

import Checkbox from '../../../../../../components/Checkbox';
import getCellClasses from '../../../helpers/functions/getCellClasses';

import './index.scss';

function CheckboxCell({ row = {}, headCell = {} }) {
  return (
    <TableCell
      padding="checkbox"
      rowSpan={
        row.hasNestedRow && headCell.rowSpan ? headCell.rowSpan : undefined
      }
      align={headCell.horizontalAlign || 'left'}
      className={getCellClasses('checkbox-cell', headCell, row)}
    >
      <Checkbox
        onChange={() => headCell.onCheckboxClick(row)}
        value={row.selected ? 2 : 0}
        disabled={row.disabled}
      />
    </TableCell>
  );
}

export default CheckboxCell;
