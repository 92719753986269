import React, {
  KeyboardEvent,
  MouseEvent,
  Ref,
  forwardRef,
  useMemo,
} from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Skeleton } from '@material-ui/lab';

import { isEnterKeyPressedOnCurrentTarget } from '../../../../../helpers/functions/utils/navigation';
import {
  TransformedDataset,
  TransformedTopographyMap,
} from '../../../../../helpers/types/dataset';
import { TransformedSatelliteImage } from '../../../../satelliteImages/types/satelliteImage';
import {
  AssetLoadStatus,
  AssetType,
} from '../../../../../helpers/constants/entities/asset';
import { getSatelliteImageGeoMap } from '../../../../satelliteImages/helpers/functions/satelliteImages';
import { getDatasetThumbnailGeoMap } from '../../../../../helpers/functions/entities/dataset';
import { DatasetViewType } from '../../../../../helpers/constants/entities/dataset';
import { getTopographyMapThumbnailGeoMap } from '../../../../../helpers/functions/entities/topographyMap';
import { getThumbnailUrl } from '../../../../../helpers/functions/utils/url';
import { formatAcquisitionDate } from '../../../../../helpers/satellite';
import SatelliteImageLegendTooltip from './SatelliteImageLegendTooltip';
import DatasetLegendTooltip from './DatasetLegendTooltip';
import TopographyMapLegendTooltip from './TopographyMapLegendTooltip';
import SoilDatasetLegendTooltip from './SoilDatasetLegendTooltip';
import { isSoilDataset } from '../../../../../helpers/functions/entities/assets';

import './index.scss';

interface BaseProps {
  apiKey: string;
  selected: boolean;
  onItemRemove: (uuid: string) => void;
  onItemSelect: (uuid: string) => void;
}

interface SatelliteImageProps extends BaseProps {
  assetType: AssetType.satelliteImage;
  asset: TransformedSatelliteImage;
  index: string;
  attribute?: undefined;
}

interface DatasetProps extends BaseProps {
  assetType:
    | AssetType.soilDataset
    | AssetType.yieldDataset
    | AssetType.asAppliedDataset;
  asset?: TransformedDataset;
  attribute: string;
  index?: undefined;
}

interface TopographyMapProps extends BaseProps {
  assetType: AssetType.topographyMap;
  asset?: TransformedTopographyMap;
  attribute: string;
  index?: undefined;
}

const DataLayersPreviewItem = (
  props: SatelliteImageProps | DatasetProps | TopographyMapProps,
  ref: Ref<HTMLDivElement>,
) => {
  const { t } = useTranslation();
  const { apiKey, onItemSelect, onItemRemove } = props;
  const geoMap = useMemo(() => {
    let result;

    if (props.assetType === AssetType.satelliteImage) {
      const contrastGeoMap = getSatelliteImageGeoMap(
        props.asset.geoMaps,
        'CONTRAST',
        props.index,
      );
      result =
        contrastGeoMap ||
        getSatelliteImageGeoMap(props.asset.geoMaps, 'CROPPED', props.index);
    } else if (props.assetType === AssetType.topographyMap) {
      result = getTopographyMapThumbnailGeoMap({
        geoMaps: props.asset?.geoMaps,
        attribute: props.attribute,
      });
    } else {
      result = getDatasetThumbnailGeoMap({
        geoMaps: props.asset?.geoMaps,
        view: DatasetViewType.processed,
        attribute: props.attribute,
        useGeoMapVersionTwo:
          props.assetType === AssetType.yieldDataset ||
          props.assetType === AssetType.asAppliedDataset,
      });
    }

    return result;
  }, [props.assetType, props.asset?.geoMaps, props.attribute, props.index]);

  const url = useMemo(
    () => getThumbnailUrl({ thumbnail: geoMap, size: 300, apiKey }),
    [geoMap, apiKey],
  );

  const title = useMemo(() => {
    let result;

    if (props.assetType === AssetType.satelliteImage) {
      result = formatAcquisitionDate(
        props.asset.satelliteImage.acquisitionDate,
      );
    } else {
      result = props.asset?.name;
    }

    return result;
  }, [props.assetType, props.asset]);

  const handleItemClick = (event: MouseEvent, uuid?: string) => {
    event.stopPropagation();
    onItemSelect(uuid || '');
  };

  const handleKeyDown = (event: KeyboardEvent, uuid?: string) => {
    if (isEnterKeyPressedOnCurrentTarget(event)) {
      onItemSelect(uuid || '');
    }
  };

  const handleItemRemove = (event: MouseEvent, uuid?: string) => {
    event.stopPropagation();
    onItemRemove(uuid || '');
  };

  let imageComponent;
  let tooltipComponent;

  if (url) {
    imageComponent = (
      <img className="data-layers-previews-item__image" src={url} alt={title} />
    );
  } else if (
    props.assetType === AssetType.satelliteImage &&
    props.asset.contrastStatus !== AssetLoadStatus.loading
  ) {
    imageComponent = (
      <div
        className={clsx('data-layers-previews-item__image-missing', {
          'data-layers-previews-item__image_selected': props.selected,
        })}
      >
        <span>{t('data-layers-previews.not-available')}</span>
      </div>
    );
  } else {
    imageComponent = (
      <Skeleton
        className="data-layers-previews-item__image-placeholder"
        variant="rect"
      />
    );
  }

  if (geoMap) {
    if (props.assetType === AssetType.satelliteImage) {
      tooltipComponent = (
        <SatelliteImageLegendTooltip
          image={props.asset}
          index={props.index}
          geoMap={geoMap}
        >
          <InfoOutlinedIcon className="data-layers-previews-item__title-icon" />
        </SatelliteImageLegendTooltip>
      );
    } else if (
      props.asset &&
      (props.assetType === AssetType.yieldDataset ||
        props.assetType === AssetType.asAppliedDataset)
    ) {
      tooltipComponent = (
        <DatasetLegendTooltip
          dataset={props.asset}
          attribute={props.attribute}
          geoMap={geoMap}
        >
          <InfoOutlinedIcon className="data-layers-previews-item__title-icon" />
        </DatasetLegendTooltip>
      );
    } else if (props.asset && isSoilDataset(props.asset)) {
      tooltipComponent = (
        <SoilDatasetLegendTooltip
          dataset={props.asset}
          attribute={props.attribute}
          geoMap={geoMap}
        >
          <InfoOutlinedIcon className="data-layers-previews-item__title-icon" />
        </SoilDatasetLegendTooltip>
      );
    } else if (props.asset && props.assetType === AssetType.topographyMap) {
      tooltipComponent = (
        <TopographyMapLegendTooltip
          topographyMap={props.asset}
          attribute={props.attribute}
          geoMap={geoMap}
        >
          <InfoOutlinedIcon className="data-layers-previews-item__title-icon" />
        </TopographyMapLegendTooltip>
      );
    }
  }

  return (
    <div
      ref={ref}
      className="data-layers-previews-item"
      role="button"
      tabIndex={0}
      onClick={(e) => handleItemClick(e, props.asset?.uuid)}
      onKeyDown={(e) => handleKeyDown(e, props.asset?.uuid)}
    >
      <div
        className={clsx('data-layers-previews-item__image-container', {
          'data-layers-previews-item__image-container_selected': props.selected,
        })}
      >
        {imageComponent}
      </div>
      <IconButton
        className="data-layers-previews-item__close-button"
        size="small"
        onClick={(e) => handleItemRemove(e, props.asset?.uuid)}
      >
        <CloseIcon
          className="data-layers-previews-item__close-button-icon"
          fontSize="small"
        />
      </IconButton>
      <div className="data-layers-previews-item__title-container">
        <Typography className="data-layers-previews-item__title" title={title}>
          {title}
        </Typography>
        {tooltipComponent}
      </div>
    </div>
  );
};

export default forwardRef(DataLayersPreviewItem);
